import { memo, useState } from 'preact/compat';
import { highlightText } from 'utils/helpers';
import { Menu, MenuItem, MenuButton, FocusableItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import _ from 'underscore';
import { JSXInternal } from 'preact/src/jsx';

type ProjectSelectionPropsType = {
  updateProjectFilterValue?: (value: string) => void;
  allProjects: ProjectObjectType[];
  hasAssignee: boolean;
  isSubtask: boolean;
  showProjectId: boolean;
  itemProject?: ProjectObjectType | null;
  projectNameWithTaskNumber?: string;
  renderProjectNameWithTaskNumber: JSXInternal.Element | null;
  isDisabled: boolean;
  updateProject: (projectId: string | null) => void;
  stateType: StateType | null;
  overlayClass?: string;
};
function ProjectSelection(props: ProjectSelectionPropsType) {
  const {
    updateProjectFilterValue,
    allProjects,
    hasAssignee,
    isSubtask,
    showProjectId,
    itemProject,
    projectNameWithTaskNumber,
    renderProjectNameWithTaskNumber,
    isDisabled,
    updateProject,
    stateType,
    overlayClass,
  } = props;

  const [filter, setFilter] = useState('');

  const updateFilterValue = (value: string) => {
    setFilter(value);
    if (updateProjectFilterValue) updateProjectFilterValue(value);
  };

  const renderRemoveItem = () => {
    if (!hasAssignee || !itemProject) return <MenuItem className='hidden'>hidden</MenuItem>;

    return (
      <MenuItem key='remove-item' onClick={() => updateProject(null)} className='remove-item'>
        Remove Project
      </MenuItem>
    );
  };

  // log('render components/TaskItems/IndividualItem/ProjectSelection');

  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };

  return (
    <div className='item-container project-container filter-control' key='project-filter'>
      <Menu
        style={{ width: '50px' }}
        className='item-menu'
        menuButton={
          <MenuButton
            disabled={isDisabled || isSubtask || stateType === 'TEMPLATE'}
            className={`item-btn project-btn ${!showProjectId ? 'hide-id' : ''}`}
          >
            <span className='item-selected' title={projectNameWithTaskNumber}>
              {renderProjectNameWithTaskNumber}
            </span>
          </MenuButton>
        }
      >
        <FocusableItem className='item-input'>
          {({ ref }) => (
            <input
              ref={ref}
              type='text'
              placeholder='Project'
              autoComplete='off'
              data-lpignore='true'
              data-form-type='other'
              value={filter}
              onChange={(e) => updateFilterValue((e.target as HTMLInputElement).value)}
              onKeyDown={highlightText}
            />
          )}
        </FocusableItem>
        {allProjects.map((project, index) => (
          <MenuItem
            key={project.id + index}
            onClick={() => updateProject(project.id)}
            disabled={project.id === itemProject?.id}
            value={project.id}
          >
            {project.name}
          </MenuItem>
        ))}
        {renderRemoveItem()}
      </Menu>
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: ProjectSelectionPropsType, nextProps: ProjectSelectionPropsType) {
  if (prevProps.showProjectId !== nextProps.showProjectId) return false;

  if (prevProps.itemProject !== nextProps.itemProject) return false;

  if (prevProps.isSubtask !== nextProps.isSubtask) return false;

  if (prevProps.hasAssignee !== nextProps.hasAssignee) return false;

  if (prevProps.projectNameWithTaskNumber !== nextProps.projectNameWithTaskNumber) return false;

  if (prevProps.renderProjectNameWithTaskNumber !== nextProps.renderProjectNameWithTaskNumber)
    return false;

  if (prevProps.isDisabled !== nextProps.isDisabled) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  return _.isEqual(prevProps.allProjects, nextProps.allProjects);
}

export default memo(ProjectSelection, areEqual);
