export default {
  auth: {
    login: 'auth',
    forgot_password: 'password/reset',
    reset_password: 'password/update',
    change_password: 'change-user-password',
    chart_login: 'auth/charts',
  },
  user: {
    upload_image: 'api/v1/user/profile-image',
    get_users_with_count: 'api/v1/user/counts',
    create_user: 'api/v1/user',
    invite_mail: 'api/v1/user/invite',
    update_invite_status: 'api/v1/user/update-invite-status/user',
    save_user_settings: 'api/v1/user-setting',
    get_user_settings: 'api/v1/user-setting/user',
    get_invited_members: 'api/v1/user/invited-members',
    switch_user_workspace: 'api/v1/user/switch-workspace',
    update_integration_id: 'api/v1/Users/IntegrationId',
    get_user_session: 'api/v1/user/session',
    update_user_role: 'api/v1/user/update-role',
    activate_deactivate_user: 'api/v1/user',
    upload_workspace_image: 'api/v1/user/workspace-image',
    rename_workspace: 'api/v1/workspace',
    leave_workspace: 'api/v1/workspace',
    add_workspace_and_image: 'api/v1/workspace',
    update_workspace_sequences: 'api/v1/user',
    delete_workspace: 'api/v1/workspace',
    list_owned_workspace: 'api/v1/workspaces',
    accountDelete: 'api/v1/account/delete',
    changeOwner: 'api/v1/workspace/change-owner',
  },
  tasks: {
    get_my_tasks: 'api/v1/task/filter-tasks/status/OPEN',
    add_task: 'api/v1/task',
    update_task_project: 'api/v1/project',
    update_task_size: 'api/v1/task',
    bulk_update_task_size: 'api/v1/task/update-size/size',
    update_task_value: 'api/v1/task',
    update_task_sequence: 'api/v1/task/update-sequence',
    delete_task: 'api/v1/task',
    update_task_status: 'api/v1/task',
    update_task_assignee: 'api/v1/task',
    update_task_dates: 'api/v1/task/date',
    bulk_update_dates: 'api/v1/task/update-dates',
    get_completed_tasks: 'api/v1/task/filter-tasks/status/COMPLETED',
    add_comment: 'api/v1/task',
    delete_comment: 'api/v1/task/', // {taskId}/comment/{commentId}
    add_comment_attachment: 'api/v1/task/comment',
    add_recurring_task: 'api/v1/recurring-task',
    get_recurring_tasks: 'api/v1/filtered-recurring-tasks',
    update_recurring_task: 'api/v1/recurring-task',
    activate_deactivate_recurring_task: '/api/v1/recurring-task/activate',
    collapse_task: 'api/v1/task',
    update_parent: 'api/v1/task/update-parent',
  },
  websocket: {
    user: 'topic/data/user',
    project: 'topic/data/project',
    meeting: 'topic/data/meeting',
    templates: 'topic/data/templates',
  },
  projects: {
    get_projects: 'api/v1/project',
    create_new_project: 'api/v1/project',
    update_project: 'api/v1/project',
    archive_project: 'api/v1/project',
    add_members_to_project: 'api/v1/project',
    remove_members_from_project: 'api/v1/project',
    create_folder: 'api/v1/folder',
    get_user_folders: 'api/v1/folder/user',
    update_project_folder: 'api/v1/project',
    set_folder_open_status: 'api/v1/folder',
    update_folder: 'api/v1/folder',
    delete_folder: 'api/v1/folder',
    get_user_projects: 'api/v1/project/user',
    create_form: 'api/v1/form',
    delete_form: 'api/v1/form', // api/v1/form/{id}
    get_project_name: 'api/form', // http://localhost:8383/magictask-api/api/form/65bb322f9ecd5766de89a797/project-name
    create_form_task: 'api/task-with-form',
  },
  tags: {
    get_tags: 'api/v1/tags/user',
    add_new_tag: 'api/v1/tag',
    update_task_tags: 'api/v1/tag/update',
  },
  notifications: {
    get_notifications: 'api/v1/notification/all',
    update_notification: 'api/v1/notification/update-status/true',
    mark_all_read: 'api/v1/notification/mark-all-read',
  },
  search: {
    get_search_results: 'api/v1/task/search',
    get_recent_search: 'api/v1/search/history',
  },
  feedback: {
    submit: 'api/v1/feedback',
  },
  theme: {
    get_all_themes: 'api/v1/theme-management/list',
    get_purchased_themes: 'api/v1/theme-instance-management/list',
    active_deactivate_theme: 'api/v1/theme-management/',
    create_theme: 'api/v1/theme-management/create',
    equip_theme: 'api/v1/theme-instance-management/equip',
    get_stripe_id: 'api/v1/stripe/purchase-theme',
    purchase_theme: 'api/v1/theme-instance-management/purchase',
    cancel_payment: 'api/v1/stripe/cancel-intent',
    get_active_triggers: 'api/v1/theme-instance-management/active-triggers',
    get_all_tiggers: 'api/v1/theme-management/all-triggers',
  },
  whats_new: {
    add: 'api/v1/whats-new',
    get: 'api/v1/whats-new',
    latest_features: 'api/v1/whats-new/commits/order',
  },
  super_admin: {
    get_workspace_user: 'api/v1/user/workspaces',
    get_workspaces: 'api/v1/user/workspaces',
    activate_deactivate_workspace: 'api/v1/user/workspace', // api/v1/user/workspace/{workspace}/status/{activate}
    export_companies: 'api/v1/export-companies',
  },
  tutorial: {
    get_tutorial: 'api/v1/user-tutorial',
    update_tutorial: 'api/v1/user-tutorial', // api/v1/user-tutorial/{userTutorialId}/step/{step}
    skip_tutorial: 'api/v1/user-tutorial', // api/v1/user-tutorial/status/{status}
    complete_tutorial: 'api/v1/user-tutorial', // api/v1/user-tutorial/{userTutorialId}/complete/{status}
  },
  mailchimp: {
    upload_user: 'api/v1/mailchimp',
  },
  template: {
    create_project_template: 'api/v1/project-template',
    update_project_template: 'api/v1/project-template/null',
    get_project_templates: 'api/v1/project-templates',
    get_tasks: 'api/v1/filter-template-tasks/status/OPEN',
    add_task: 'api/v1/project-template/task',
    get_tags: 'api/v1/project-template/tags',
    update_task: 'api/v1/project-template/task',
    update_task_assignee: 'api/v1/task-template', // api/v1/task-template/{taskId}/user/{userId}
    delete_task: 'api/v1/project-template/task-template', // api/v1/project-template/task-template/{id}
    delete_recurring_task: 'api/v1/project-template/recurring-task', // api/v1/project-template/recurring-task/{id}
    add_new_tag: 'api/v1/project-template/tag',
    add_recurring_task: 'api/v1/project-template/recurring-task',
    update_recurring_task: 'api/v1/project-template/recurring-task',
    update_task_tags: 'api/v1/tag-template/update',
    delete_project_template: 'api/v1/project-template', // api/v1/project-template/{templateId}
  },
  leaderboard: {
    enroll_user: 'api/v1/leaderboard/enroll/user/true/id/',
    individual_board_list: 'api/v1/leaderboard/user/true',
    workspace_board_list: 'api/v1/leaderboard/user/false',
  },
  subscription: {
    intent_setup: 'api/v1/stripe/setup',
    subscribe: 'api/v1/stripe/subscribe',
    get: 'api/v1/stripe/subscriptions',
    cancel: 'api/v1/stripe/cancel-subscription',
  },
};
