import { useEffect, Fragment, useState, Suspense, useRef, useCallback } from 'preact/compat';
import { lazyWithRetry, highlightText } from 'utils/helpers';
import { log } from 'utils';
import SmallButton from 'components/UI/SmallButton';
import { getItem, setItem } from 'utils/storage';
import { getUserId, saveUserAccountSettings, getOwnedWorkspaceList } from 'services/AuthService';
import { useAlert } from 'react-alert';
import { Strings } from 'resources';
import Switch from 'react-switch';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, FocusableItem, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { initializeTriggers } from 'triggers';
import { useRecoilState, useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';
import { accountDataSelector, accountSettingsState } from 'recoil/AccountState';
import { getAccountSettings } from 'recoil/AccountState/update';
import { allSizes } from 'utils/size';
const DeleteAccount = lazyWithRetry(() => import('modals/DeleteAccount'));
const fieldsToHideData = [
  { id: 'size', name: 'Size' },
  { id: 'projectId', name: 'Project ID' },
  { id: 'dueDate', name: 'Due Date' },
  { id: 'startAndEnd', name: 'Start & End Date' },
  { id: 'filterVisiblity', name: 'Filter Visiblity' },
];

export default function Settings() {
  const alert = useAlert();
  const [taskSize, setTaskSize] = useState('');
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [activeWorkspaceList, setActiveWorkspaceList] = useState([]);

  const navigationData = useRecoilValue(navigationDataSelector);
  const accountData = useRecoilValue(accountDataSelector);

  const userId = getUserId();

  const workspaces = accountData?.account?.workspaces;

  const [spellCheck, setSpellCheck] = useState(true);
  const [assignDefaultToSelf, setAssignDefaultToSelf] = useState<undefined | boolean>(false);
  const [closeCommentOnAdd, setCloseCommentOnAdd] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState<AccountSettingsOptionsType[]>([]);
  const [rollUpOptions, setRollUpOptions] = useState<AccountSettingsOptionsType[]>([]);
  const [rollUpFrequency, setRollUpFrequency] = useState('Daily');

  const [accountSettings, setAccountSettings] = useRecoilState(accountSettingsState);

  const [rollupWorkspace, setRollupWorkspace] = useState(
    getItem('rollupWorkspace') ? getItem('rollupWorkspace').data : null,
  );

  const rollUpFrequencyList: RecurrenceRepeatType[] = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

  let userDetailFullName = '';
  let userDetailEmail = '';
  if (accountData && accountData.account) {
    userDetailFullName = accountData.account.name ?? '';
    userDetailEmail = accountData.account.email ? accountData.account.email : '';
  }

  const workspaceFilterRef = useRef(null);
  const skipWorkspaceClick = useRef(false);
  const [isWorkspaceFilterOpen, setWorkspaceFilterOpen] = useState(false);
  const [workspaceFilterValue, setWorkspaceFilterValue] = useState('');

  useEffect(() => {
    const notifSetting = accountData.accountSettings.notificationSetting;
    setNotificationOptions([
      {
        title: Strings.pushNotification,
        key: 'pushNotifications',
        value: notifSetting.pushNotifications,
      },
      {
        title: Strings.emailNotification,
        key: 'emailNotifications',
        value: notifSetting.emailNotifications,
      },
      {
        title: Strings.inAppNotifications,
        key: 'inAppNotifications',
        value: notifSetting.inAppNotifications,
      },
      {
        title: Strings.new_member_notif,
        key: 'newMemberAddedToProject',
        value: notifSetting.newMemberAddedToProject,
      },
      {
        title: Strings.project_at_risk,
        key: 'projectAtRiskOrOffTrack',
        value: notifSetting.projectAtRiskOrOffTrack,
      },
      {
        title: Strings.assignee_notif,
        key: 'taskAssigned',
        value: notifSetting.taskAssigned,
      },
      {
        title: Strings.completed_notif,
        key: 'taskCompleted',
        value: notifSetting.taskCompleted,
      },
      {
        title: Strings.comment_notif,
        key: 'taskCommented',
        value: notifSetting.taskCommented,
      },
      {
        title: Strings.task_modif_notif,
        key: 'taskModified',
        value: notifSetting.taskModified,
      },
    ]);
  }, [accountData.accountSettings.notificationSetting]);

  useEffect(() => {
    const notifSetting = accountData.accountSettings.notificationSetting;
    setRollUpOptions([
      {
        title: Strings.completedTasks,
        key: 'rollupCompleted',
        value: notifSetting.rollupCompleted,
      },
      {
        title: Strings.recentlyCreatedTasks,
        key: 'rollupCreated',
        value: notifSetting.rollupCreated,
      },
      {
        title: Strings.taskNeedAttention,
        key: 'rollUpNeedsAttention',
        value: notifSetting.rollUpNeedsAttention,
      },
      {
        title: Strings.upcomingDueDate,
        key: 'rollupUpcoming',
        value: notifSetting.rollupUpcoming,
      },
      {
        title: Strings.overdueDueDate,
        key: 'rollupOverDue',
        value: notifSetting.rollupOverDue,
      },
    ]);
  }, [accountData.accountSettings.notificationSetting]);

  useEffect(() => {
    const accountSettings =
      accountData && accountData.accountSettings ? accountData.accountSettings : null;
    if (accountSettings && accountSettings.notificationSetting) {
      setRollUpFrequency(accountSettings.notificationSetting.rollupFrequency);
    }
  }, [accountData]);

  // component did mount
  useEffect(() => {
    getAccountSettings(userId);
  }, [userId]);

  useEffect(() => {
    const accountSettings =
      accountData && accountData.accountSettings ? accountData.accountSettings : null;
    if (accountSettings && accountSettings.defaultPreferredSize) {
      setTaskSize(accountSettings.defaultPreferredSize);
    }
  }, [accountData]);

  useEffect(() => {
    const accountSettings =
      accountData && accountData.accountSettings ? accountData.accountSettings : null;
    if (accountSettings) {
      setSpellCheck(accountSettings.spellChecker);
      setCloseCommentOnAdd(accountSettings.closeCommentOnAdd);
    }
  }, [accountData]);

  useEffect(() => {
    const accountSettings =
      accountData && accountData.accountSettings ? accountData.accountSettings : null;
    if (accountSettings) {
      setAssignDefaultToSelf(accountSettings.assignDefaultToSelf);
    }
  }, [accountData]);

  const renderSizeInput = () => {
    const inputSizes = allSizes;
    const inputSizeDetails = inputSizes.map((item, index) => {
      return (
        <button
          className='size-button'
          onClick={() => {
            saveDefaultTaskSize(item.id);
          }}
          key={index}
        >
          <div className={`tick-icon ${taskSize === item.id ? '' : 'hide'}`} alt='tick' />
          {item.display}
        </button>
      );
    });
    return inputSizeDetails;
  };

  const saveDefaultTaskSize = async (selectedSize: SizeOptionsType) => {
    setTaskSize(selectedSize);
    const payload = {
      id: accountData.accountSettings.id,
      defaultPreferredSize: selectedSize,
    };
    const { data, success, message } = await saveUserAccountSettings(payload);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return onSettingUpdateSuccess(data);
    }
  };

  const saveNotificationSettings = async (
    key: string,
    status: boolean | string,
    oldWorkspaceList?: string,
  ) => {
    const payload = {
      id: accountData.accountSettings.id,
    };

    payload[key] = status;
    const { data, success, message } = await saveUserAccountSettings(payload);
    if (!success) {
      alert.error(message);
      if (key === 'rollupWorkspace' && oldWorkspaceList) {
        setItem('rollupWorkspace', oldWorkspaceList);
        setRollupWorkspace(oldWorkspaceList);
      }
      return log(message);
    } else {
      return onSettingUpdateSuccess(data);
    }
  };

  const addAccountSettings = (data: AccountSettingsType) => {
    if (accountSettings.notificationSetting?.rollupWorkspace)
      setItem('rollupWorkspace', accountSettings.notificationSetting.rollupWorkspace);
    setAccountSettings(data);
  };

  const onSettingUpdateSuccess = (data: AccountSettingsType) => {
    addAccountSettings(data);
  };

  const renderSeparator = () => {
    return <div className='separator'></div>;
  };

  const renderSeparatorWithoutPadding = () => {
    return <div className='separator-without-padding'></div>;
  };

  const renderOptions = (option: AccountSettingsOptionsType, index: number) => {
    return (
      <div key={index}>
        <div className='size-selector'>
          <div className='title'>{option.title}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={(nextChecked) => saveNotificationSettings(option.key, nextChecked)}
            checked={option.value}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparator()}
      </div>
    );
  };

  const renderShowHide = () => {
    return (
      <div className='notif-form-group'>
        <span className='section-subhead'>Show/Hide Fields</span>
        {renderSeparator()}
        {fieldsToHideData.map((option, index) => {
          return renderShowHideOption(option.id, option.name, index);
        })}
      </div>
    );
  };

  const toggleShowHideOption = (id: string) => {
    const newValue = !accountSettings[id];
    const newSettings = { ...accountSettings, [id]: newValue };
    addAccountSettings(newSettings);

    const payload = { [id]: newValue, id: accountSettings.id };

    saveUserAccountSettings(payload);
  };

  const renderShowHideOption = (id: string, name: string, index: number) => {
    return (
      <div key={index}>
        <div className='size-selector'>
          <div className='title'>{name}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={() => toggleShowHideOption(id)}
            checked={accountSettings[id] === undefined ? false : accountSettings[id]}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparator()}
      </div>
    );
  };

  const renderUIFilters = () => {
    return (
      <div className='notif-form-group'>
        <span className='section-subhead'>UI</span>
        {renderSeparator()}
        {renderShowHideOptionCustom('projectName', 'Project Name')}
        {renderShowHideOptionCustom('assigneeName', 'Assignee Name')}
      </div>
    );
  };

  const renderShowHideOptionCustom = (id: string, name: string) => {
    return (
      <>
        <div className='size-selector'>
          <div className='title'>{name}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={() => toggleShowHideOption(id)}
            checked={accountSettings[id] === undefined ? false : accountSettings[id]}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  paddingLeft: 2,
                  color: 'white',
                  fontWeight: 700,
                }}
              >
                L
              </div>
            }
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  paddingRight: 2,
                  fontWeight: 700,
                }}
              >
                R
              </div>
            }
          />
        </div>
        {renderSeparator()}
      </>
    );
  };

  const updateSpellCheckSetting = async (checked: boolean) => {
    setSpellCheck(checked);
    const payload = {
      id: accountData.accountSettings.id,
      spellChecker: checked,
    };
    const { data, success, message } = await saveUserAccountSettings(payload);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return onSettingUpdateSuccess(data);
    }
  };

  const updateAssignToSelfSetting = async (checked: boolean) => {
    setAssignDefaultToSelf(checked);
    const payload = {
      id: accountData.accountSettings.id,
      assignDefaultToSelf: checked,
    };
    const { data, success, message } = await saveUserAccountSettings(payload);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return onSettingUpdateSuccess(data);
    }
  };

  const updateCloseCommentBoxSetting = async (checked: boolean) => {
    setCloseCommentOnAdd(checked);
    const payload = {
      id: accountSettings.id,
      closeCommentOnAdd: checked,
    };
    const { data, success, message } = await saveUserAccountSettings(payload);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return onSettingUpdateSuccess(data);
    }
  };

  const renderAnimationSection = () => {
    const animationList = accountData.accountSettings.userAnimationSettings;
    return (
      <div className='animation-form-group'>
        <div className='animation-effect-con'>
          <span className='section-subhead'>{Strings.animations}</span>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => handleAnimationStateChange('animation', checked)}
            checked={animationList.animation}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparatorWithoutPadding()}
        {animationList &&
          animationList.animation &&
          Object.keys(animationList).map((key, index) => {
            if (key !== 'animation') return renderAnimationOption(key, animationList[key], index);
          })}
      </div>
    );
  };

  const renderAnimationOption = (key: string, value: boolean, index: number) => {
    return (
      <div key={index}>
        <div className='animation-selector'>
          <div className='title'>{unCamelCase(key)}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => handleAnimationStateChange(key, checked)}
            checked={value}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparatorWithoutPadding()}
      </div>
    );
  };

  function unCamelCase(str: string) {
    return (
      str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  }

  const updateAccountAnimationSetting = (key: string, value: boolean) => {
    let tempData: AccountSettingsType;
    if (key === 'animation') {
      tempData = {
        ...accountSettings,
        userAnimationSettings: {
          animation: value,
          bgAnimationHigh: value,
          bgAnimationLow: value,
          bgAnimationMedium: value,
          completeTaskAnimation: value,
          menuNavigationAnimation: value,
          reorderTaskAnimation: value,
          subtaskCreationAnimation: value,
          taskCreationAnimation: value,
        },
      };
    } else
      tempData = {
        ...accountSettings,
        userAnimationSettings: {
          ...accountSettings.userAnimationSettings,
          [key]: value,
        },
      };
    setAccountSettings(tempData);
  };

  const handleAnimationStateChange = async (key: string, value: boolean) => {
    initializeTriggers(true);

    updateAccountAnimationSetting(key, value);
    handleAPIChange(key, value);
  };

  const renderSoundSection = () => {
    const soundList = accountData.accountSettings.userSoundEffectSetting;
    return (
      <div className='sound-effect-form-group'>
        <div className='sound-effect-con'>
          <span className='section-subhead'>{Strings.sound_effects}</span>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => handleSoundStateChange('soundEffects', checked)}
            checked={soundList.soundEffects}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>

        {renderSeparatorWithoutPadding()}
        {soundList &&
          soundList.soundEffects &&
          Object.keys(soundList).map((key, index) => {
            if (key !== 'soundEffects') return renderSoundOption(key, soundList[key], index);
          })}
      </div>
    );
  };

  const renderSoundOption = (key: string, value: boolean, index: number) => {
    return (
      <div key={index}>
        <div className='sound-selector'>
          <div className='title'>{unCamelCase(key)}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => handleSoundStateChange(key, checked)}
            checked={value}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparatorWithoutPadding()}
      </div>
    );
  };

  const updateAccountSoundSetting = (key: string, value: boolean) => {
    let tempData: AccountSettingsType;
    if (key === 'soundEffects') {
      tempData = {
        ...accountSettings,
        userSoundEffectSetting: {
          bgMusic: value,
          completeTaskSound: value,
          menuNavigationSound: value,
          reorderTaskSound: value,
          soundEffects: value,
          subtaskCreationSound: value,
          taskCreationSound: value,
        },
      };
    } else
      tempData = {
        ...accountSettings,
        userSoundEffectSetting: {
          ...accountSettings.userSoundEffectSetting,
          [key]: value,
        },
      };
    setAccountSettings(tempData);
  };

  const handleSoundStateChange = async (key: string, value: boolean) => {
    if (key === 'soundEffects' || key === 'bgMusic') {
      initializeTriggers(true);
    }

    updateAccountSoundSetting(key, value);
    handleAPIChange(key, value);
  };

  const handleAPIChange = async (key, value) => {
    const payload = {
      id: accountData.accountSettings.id,
    };

    payload[key] = value.toString();
    const { data, success, message } = await saveUserAccountSettings(payload);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return onSettingUpdateSuccess(data);
    }
  };

  const renderGeneralSection = () => {
    return (
      <div className='size-form-group'>
        <span className='section-subhead'>{Strings.general}</span>
        {renderSeparator()}

        <div className='size-selector'>
          <div className='title'>{Strings.choose_size}</div>
          <div className='options'>{renderSizeInput()}</div>
        </div>
        {renderSeparator()}

        <div className='size-selector'>
          <div className='title'>{Strings.spellcheck}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => updateSpellCheckSetting(checked)}
            checked={spellCheck}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparator()}

        <div className='size-selector'>
          <div className='title'>{Strings.assignDefaultToSelf}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => updateAssignToSelfSetting(checked)}
            checked={assignDefaultToSelf ? assignDefaultToSelf : false}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparator()}

        <div className='size-selector'>
          <div className='title'>{Strings.closeCommentOnAdd}</div>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => updateCloseCommentBoxSetting(checked)}
            checked={closeCommentOnAdd}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparator()}
      </div>
    );
  };

  const showDeleteAccount = async () => {
    setIsLoading(true);
    const { data, success } = await getOwnedWorkspaceList();

    if (success && data) {
      setActiveWorkspaceList(data);
      setShowDeleteAccountModal(true);
    }

    setIsLoading(false);
  };

  const renderNotificationSection = () => {
    return (
      <div className='notif-form-group'>
        <span className='section-subhead'>{Strings.notifications}</span>
        {renderSeparator()}
        {notificationOptions.map((option, index) => {
          return renderOptions(option, index);
        })}
      </div>
    );
  };

  const renderSelectedWorkspace = (item: string, index: number) => {
    if (workspaces && workspaces.length > 0 && rollupWorkspace) {
      const sel = workspaces.filter((workspace) => workspace.id === item);
      if (sel && sel.length > 0) {
        return (
          <div key={index} className='selected-workspace-container'>
            <span className='workspace-entry'>
              <span className='workspace-text'>{sel[0].name}</span>
              {rollupWorkspace.split(',').length > 1 ? (
                <span className='close-icon' onClick={(_e) => selectWorkspace(item)}>
                  x
                </span>
              ) : null}
            </span>
          </div>
        );
      }
    }
    return null;
  };

  const renderRollUpSection = () => {
    const notificationSetting = accountData.accountSettings.notificationSetting;
    return (
      <div className='rollup-reports-form-group'>
        <div className='rollup-effect-con'>
          <span className='section-subhead'>{Strings.rollup_reports}</span>
          {/** @ts-ignore */}
          <Switch
            onChange={(checked) => handleRollUpStateChange('rollUps', checked)}
            checked={notificationSetting.rollUps}
            height={24}
            handleDiameter={12}
            onColor='#332a7c'
            offColor='#ffffff'
            offHandleColor='#aaaaaa'
            className='react-switch'
          />
        </div>
        {renderSeparator()}
        {notificationSetting && notificationSetting.rollUps && (
          <Fragment>
            <div className='workspace-selector'>
              <div className='options'>{renderWorkpaceDropdown()}</div>
            </div>
            <div className='workspace-list-con'>
              {rollupWorkspace &&
                rollupWorkspace
                  .split(',')
                  .map((item, index) => renderSelectedWorkspace(item, index))}
            </div>
            {renderSeparator()}
          </Fragment>
        )}
        {notificationSetting && notificationSetting.rollUps && (
          <Fragment>
            <div className='size-selector'>
              <div className='title'>{Strings.frequency}</div>
              <div className='options'>{renderFrequencyInput()}</div>
            </div>
            {renderSeparator()}
          </Fragment>
        )}
        {notificationSetting &&
          notificationSetting.rollUps &&
          rollUpOptions.map((option, index) => {
            return renderOptions(option, index);
          })}
      </div>
    );
  };

  const filteredWorkspaces = useCallback(() => {
    const filteredList: WorkspaceType[] = [];
    const filter = workspaceFilterValue.trim().toUpperCase();
    if (workspaces && workspaces.length > 0) {
      workspaces.forEach((workspace) => {
        const name = workspace.name.toUpperCase();

        if (name.includes(filter)) filteredList.push(workspace);
      });
    }
    return filteredList;
  }, [workspaceFilterValue, workspaces]);

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = (item: WorkspaceType, filter: string[]) => {
    return (
      <div className='checkbox-con' key={`checkbox-con-${item.id}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          checked={filter && filter.length > 0 ? filter.includes(item.id) : false}
          className='inp-cbx'
          id={`checkbox-${item.id}`}
          type='checkbox'
          onChange={() => {}}
          style={{ display: 'none' }}
        />

        <label className='cbx'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    );
  };

  const selectWorkspace = (workspaceId: string) => {
    const filter = rollupWorkspace ? rollupWorkspace.split(',') : [];
    let list = rollupWorkspace ? rollupWorkspace.split(',') : [];
    if (filter && filter.length > 0) {
      const idx = filter.indexOf(workspaceId);
      if (idx !== -1) {
        if (filter.length === 1) {
          alert.error(Strings.rollUpWorkspaceWarning);
          return;
        }
        list.splice(idx, 1);
      } else {
        list = [...list, workspaceId];
      }
    } else {
      list = [workspaceId];
    }
    setRollupWorkspace(list.toString().replace(/(^,)|(,$)/g, ''));
    setItem('rollupWorkspace', list.toString().replace(/(^,)|(,$)/g, ''));
    saveNotificationSettings('rollupWorkspace', list.toString().replace(/(^,)|(,$)/g, '')),
      filter.toString().replace(/(^,)|(,$)/g, '');
  };

  const renderWorkpaceDropdown = () => {
    return (
      <div
        id='workspace-filter-con'
        className='workspace-container filter-control'
        key='workspace-filter'
      >
        <button
          id='workspace-filter-menu-button'
          className='item-btn workspace-btn'
          ref={workspaceFilterRef}
          onClick={() => !skipWorkspaceClick.current && setWorkspaceFilterOpen(true)}
          key='workspace-filter-btn'
        >
          <span className='item-selected'>{Strings.select_workspace}</span>
          {!isWorkspaceFilterOpen ? (
            <FontAwesomeIcon
              id='workspace-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='workspace-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={workspaceFilterRef}
          state={isWorkspaceFilterOpen ? 'open' : 'closed'}
          key='workspace-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipWorkspaceClick.current = true;
              setTimeout(() => (skipWorkspaceClick.current = false), 300);
            }
            if (reason !== 'click') {
              setWorkspaceFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='workspace-filter-menu-item-input-con' className='item-input'>
            {({ _ref }) => (
              <input
                id='workspace-filter-menu-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Workspace'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={workspaceFilterValue}
                onChange={(e) => setWorkspaceFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredWorkspaces().map((workspace, _index) => (
            <MenuItem
              id={`workspace-filter-menu-item-${workspace.id}`}
              className='workspace-filter-menu-item'
              key={`workspace-filter-menu-item-${workspace.id}`}
              onClick={() => selectWorkspace(workspace.id)}
              // disabled={projectList.id === projectFilter}
              value={workspace.id}
            >
              {renderCheckbox(workspace, rollupWorkspace ? rollupWorkspace.split(',') : [])}
              {workspace.name}
            </MenuItem>
          ))}
        </ControlledMenu>
      </div>
    );
  };

  const renderFrequencyInput = () => {
    const rollUpFrequencyDetails = rollUpFrequencyList.map((item, index) => {
      return (
        <button
          className='size-button'
          onClick={() => {
            setRollUpFrequency(item);
            saveNotificationSettings('rollupFrequency', item);
          }}
          key={index}
        >
          <div className={`tick-icon ${rollUpFrequency === item ? '' : 'hide'}`} alt='tick' />
          {item}
        </button>
      );
    });
    return rollUpFrequencyDetails;
  };

  const updateAccountRollUpSetting = (key: string, value: boolean | string) => {
    let tempData: AccountSettingsType;
    if (key === 'rollUps' && typeof value === 'boolean') {
      tempData = {
        ...accountSettings,
        notificationSetting: {
          ...accountSettings.notificationSetting,
          rollUps: value,
          rollupCompleted: value,
          rollupCreated: value,
          rollUpNeedsAttention: value,
          rollupOverDue: value,
          rollupUpcoming: value,
        },
      };
    } else {
      if (key === 'rollupWorkspace' && typeof value === 'string') {
        setItem('rollupWorkspace', value);
      }
      tempData = {
        ...accountSettings,
        notificationSetting: {
          ...accountSettings.notificationSetting,
          [key]: value,
        },
      };
    }
    setAccountSettings(tempData);
  };

  const handleRollUpStateChange = async (key: string, value: boolean) => {
    updateAccountRollUpSetting(key, value);
    handleAPIChange(key, value);
  };

  return (
    <Fragment>
      <div
        className={`account-settings ${
          navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
        } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
      >
        <div className='mySettings'>
          <h2 className='section-heading'>{userDetailFullName}</h2>

          <div className='form-group'>
            <span className='fieldTitle'>{`${Strings.email}: `}</span>
            <span>{userDetailEmail}</span>
          </div>

          <div className='delete-account-button'>
            <SmallButton clicked={() => showDeleteAccount()} loading={isLoading}>
              {Strings.delete_account}
            </SmallButton>
          </div>

          {renderAnimationSection()}
          {renderSoundSection()}
          {renderGeneralSection()}
          {renderNotificationSection()}
          {renderRollUpSection()}
          {renderShowHide()}
          {renderUIFilters()}
          <Suspense fallback={null}>
            {showDeleteAccountModal ? (
              <DeleteAccount
                activeWorkspaceList={activeWorkspaceList}
                closeModal={() => setShowDeleteAccountModal(false)}
              />
            ) : null}
          </Suspense>
        </div>
      </div>
    </Fragment>
  );
}
