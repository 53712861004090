import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  commentSearchAbortControllerState,
  commentSearchDataState,
  commentSearchHasMoreState,
  commentSearchLoadingState,
  commentSearchPageState,
  commentSearchSizeState,
  projectSearchAbortControllerState,
  projectSearchDataState,
  projectSearchHasMoreState,
  projectSearchLoadingState,
  projectSearchPageState,
  projectSearchSizeState,
  searchDataSelector,
  searchTaskAbortControllerState,
  searchTaskDataState,
  searchTaskHasMoreState,
  searchTaskLoadingState,
  searchTaskPageState,
  searchTaskSizeState,
  tagSearchAbortControllerState,
  tagSearchDataState,
  tagSearchHasMoreState,
  tagSearchLoadingState,
  tagSearchPageState,
  tagSearchSizeState,
} from '.';
import { getSearchResults as getSearchResultsFromAPI } from 'services/SearchService';
import { parseTask } from 'utils/task';
function getSearchDataAtom(searchType: SearchTabType) {
  switch (searchType) {
    case 'comment':
      return commentSearchDataState;
    case 'project':
      return projectSearchDataState;
    case 'tag':
      return tagSearchDataState;
    case 'task':
      return searchTaskDataState;
  }
}

function getSearchLoadingAtom(searchType: SearchTabType) {
  switch (searchType) {
    case 'comment':
      return commentSearchLoadingState;
    case 'project':
      return projectSearchLoadingState;
    case 'tag':
      return tagSearchLoadingState;
    case 'task':
      return searchTaskLoadingState;
  }
}

function getSearchHasMoreAtom(searchType: SearchTabType) {
  switch (searchType) {
    case 'comment':
      return commentSearchHasMoreState;
    case 'project':
      return projectSearchHasMoreState;
    case 'tag':
      return tagSearchHasMoreState;
    case 'task':
      return searchTaskHasMoreState;
  }
}
function getSearchCountAtom(searchType: SearchTabType) {
  switch (searchType) {
    case 'comment':
      return commentSearchSizeState;
    case 'project':
      return projectSearchSizeState;
    case 'tag':
      return tagSearchSizeState;
    case 'task':
      return searchTaskSizeState;
  }
}

function getSearchPageAtom(searchType: SearchTabType) {
  switch (searchType) {
    case 'comment':
      return commentSearchPageState;
    case 'project':
      return projectSearchPageState;
    case 'tag':
      return tagSearchPageState;
    case 'task':
      return searchTaskPageState;
  }
}

function getSearchAbortCAtom(searchType: SearchTabType) {
  switch (searchType) {
    case 'comment':
      return commentSearchAbortControllerState;
    case 'project':
      return projectSearchAbortControllerState;
    case 'tag':
      return tagSearchAbortControllerState;
    case 'task':
      return searchTaskAbortControllerState;
  }
}

export function setSearchDataAbortSignal(searchType: SearchTabType, abortC: AbortController) {
  const targetAtom = getSearchAbortCAtom(searchType);
  setRecoil(targetAtom, abortC);
}

export function addSearchData(
  searchType: SearchTabType,
  data: ProjectObjectType[] | TaskObjectType[] | CommentType[],
  hasMore: boolean,
  page: number,
  count: number,
) {
  const targetDataAtom = getSearchDataAtom(searchType);
  const targetHasMoreAtom = getSearchHasMoreAtom(searchType);
  const targetPageAtom = getSearchPageAtom(searchType);
  const targetCountAtom = getSearchCountAtom(searchType);
  setRecoil(targetDataAtom, data);
  setRecoil(targetHasMoreAtom, hasMore);
  setRecoil(targetPageAtom, page);
  setRecoil(targetCountAtom, count);
}

export function loadingSearchData(searchType: SearchTabType, isLoading: boolean) {
  const targetAtom = getSearchLoadingAtom(searchType);
  setRecoil(targetAtom, isLoading);
}

export function searchDataHasNoMore(searchType: SearchTabType) {
  const targetAtom = getSearchHasMoreAtom(searchType);
  setRecoil(targetAtom, false);
}

/*
 * Merge old data with new data, after checking if id does not exist already
 * oldData Array
 * newData Array
 *
 * return Array
 */
function mergeSearchData(oldData: TaskObjectType[], newData: ResponseTaskInterface[]) {
  let tempData: TaskObjectType[] = [...oldData];
  newData.forEach((newDat, _index) => {
    if (newDat.id) {
      const index = oldData.findIndex((data) => data.id === newDat.id);

      if (index === -1)
        tempData = [
          ...tempData,
          parseTask(newDat, null, newDat.status === 'COMPLETED' ? true : false, true),
        ];
    }
  });
  return tempData;
}

export async function getSearchData(searchType: SearchTabType, reset: boolean, searchText: string) {
  const searchData = getRecoil(searchDataSelector);
  let { page } = searchData[searchType];
  const { limit, abortController } = searchData[searchType];

  if (reset) {
    if (abortController) {
      abortController.abort();
    }
    const newAbort = new AbortController();
    setSearchDataAbortSignal(searchType, newAbort);
    addSearchData(searchType, [], true, 0, 0);
  }
  loadingSearchData(searchType, true);

  if (reset) page = 0;

  const payload = {
    ascending: false,
    page: page,
    searchKey: searchText.replaceAll('-slash-', '/'),
    size: limit,
    searchIn: searchType,
  };

  const { data, success } = await getSearchResultsFromAPI(payload);

  if (success && data) {
    const list = searchType === 'project' ? data.tags : data.tasks;

    if (list && Array.isArray(list) && list.length) {
      let mergedData: TaskObjectType[] | CommentType[] | ProjectObjectType[] = [];

      if (searchType === 'comment') {
        let comments: CommentType[] = [];
        list.forEach((item) => {
          const filteredComments = item.comments.filter((com) =>
            com.name.toLowerCase().includes(searchText.toLowerCase()),
          );
          comments = [...comments, ...filteredComments];
        });
        mergedData = reset
          ? comments
          : [...(searchData[searchType].data as CommentType[]), ...comments];
      } else if (searchType === 'project') {
        mergedData = reset ? list : [...searchData[searchType].data, ...list];
      } else {
        mergedData = reset
          ? mergeSearchData([], list)
          : mergeSearchData(searchData[searchType].data as TaskObjectType[], list);
      }

      addSearchData(
        searchType,
        mergedData,
        list.length === 0 ? false : true,
        page + 1,
        data.totalCount ? data.totalCount : mergedData.length,
      );
    } else {
      searchDataHasNoMore(searchType);
    }
  } else {
    searchDataHasNoMore(searchType);
  }

  loadingSearchData(searchType, false);
}
