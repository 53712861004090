import { getCurrentTheme } from 'utils/theme';
import { removeStylesheet } from 'triggers/actions/css';
import { log } from 'utils';
import { getRecoil } from 'recoil-nexus';
import { accountDataSelector } from 'recoil/AccountState';
import { combinedSelector } from 'recoil/CombinedState';

export const resetAllCSSTriggers = async () => {
  try {
    const currentTheme = getCurrentTheme();
    const allTriggers = await import(`./../themes/${currentTheme.id}/triggers.json`);

    resetCSSTriggers(allTriggers.default);
  } catch (error) {
    log(error);
  }
};

const resetCSSTriggers = (allTriggers) => {
  allTriggers.forEach((trigger) => {
    const actions = trigger.action.split('|');

    actions.forEach((action) => {
      switch (action) {
        case 'CSS':
          removeStylesheet(trigger.id);
          break;
        default:
          break;
      }
    });
  });
};

export const canBeAdded = (triggerId) => {
  const prop = camelCase(triggerId);

  const accountData = getRecoil(accountDataSelector);

  if (
    accountData.accountSettings.userAnimationSettings.animation &&
    accountData.accountSettings.userAnimationSettings[prop]
  )
    return true;

  return false;
};

const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};

export const getTaskCreationDelay = () => {
  const { accountData, triggerData } = getRecoil(combinedSelector);

  if (
    accountData.accountSettings.userAnimationSettings.animation &&
    accountData.accountSettings.userAnimationSettings.taskCreationAnimation
  ) {
    return triggerData.createTaskDelay;
  } else return 0;
};

export const getSubtaskCreationDelay = () => {
  const { accountData, triggerData } = getRecoil(combinedSelector);

  if (
    accountData.accountSettings.userAnimationSettings.animation &&
    accountData.accountSettings.userAnimationSettings.subtaskCreationAnimation
  ) {
    return triggerData.createSubtaskDelay;
  } else return 0;
};

export const getCompleteTaskDelay = () => {
  const { accountData, triggerData } = getRecoil(combinedSelector);

  if (
    accountData.accountSettings.userAnimationSettings.animation &&
    accountData.accountSettings.userAnimationSettings.completeTaskAnimation
  ) {
    return triggerData.completeTaskDelay;
  } else return 300;
};
