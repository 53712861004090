import { Suspense, useEffect, Fragment, useState } from 'preact/compat';
import { log, setPageTitle } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import Spinner from 'components/UI/Spinner';

import { useParams, useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import TutorialTopTitleBar from 'components/TutorialTopTitleBar';
import BottomBar from 'components/BottomBar';
import { useRecoilValue } from 'recoil';
import { allProjectsState, projectDataSelector } from 'recoil/ProjectState';
import { allUsersState } from 'recoil/AccountState';
import { navigationDataSelector } from 'recoil/NavigationState';
const TutorialTaskList = lazyWithRetry(() => import('./TutorialTaskList'));

function TutorialTasks() {
  const props: TasksPropsType = useOutletContext();

  const { stateType, readOnly } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const { projectId, userId } = useParams();
  const projectData = useRecoilValue(projectDataSelector);
  const allProjects = useRecoilValue(allProjectsState);
  const allUsers = useRecoilValue(allUsersState);
  const navigationData = useRecoilValue(navigationDataSelector);
  const [taskId, setTaskId] = useState(null);

  const getUserName = () => {
    if (allUsers === null) return '';

    const index = allUsers.findIndex((item) => item.id === userId);

    if (index === -1) return '';

    const user = allUsers[index];
    return user.display ? user.display : '';
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'INDIVIDUAL') setPageTitle(getUserName());
  }, [getUserName()]);

  const getProjectName = () => {
    if (allProjects === null) return '';

    const index = allProjects.findIndex((item) => item.id === projectId);

    if (index === -1) return '';

    const project = allProjects[index];
    return project.display ? project.display : '';
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'PROJECT') setPageTitle(getProjectName());
  }, [getProjectName()]);

  // log('render Tasks/index.js');

  const findElementAndScroll = (task: string) => {
    const ele = document.getElementById(task);
    if (ele) {
      log('found ele');
      ele.scrollIntoView({ block: 'center' });
      ele.className = 'search-item';

      setTimeout(() => {
        document.addEventListener('scroll', function () {
          navigate('', { replace: true, state: '' });
          if (ele) ele.className = '';
          navigate('', { replace: true, state: '' });
          document.removeEventListener('scroll', function () {});
        });
      }, 2000);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.taskId) {
      log('task received in Tasks');
      setTaskId(location.state.taskId);
      setTimeout(() => {
        if (!findElementAndScroll(location.state.taskId)) {
          setTimeout(() => {
            findElementAndScroll(location.state.taskId);
          }, 7000);
        }
      }, 5000);
    } else {
      setTaskId(null);
    }
  }, [location]);

  const renderContent = () => {
    if (stateType === 'PROJECT' && projectData && projectData.error) {
      return (
        <div className='task-fetch-error' id='task-fetch-error'>
          <h2>{projectData.error}</h2>
        </div>
      );
    }
    return (
      <Fragment>
        <TutorialTopTitleBar stateType={stateType} idName='tasks-top-title' readOnly={false} />
        <Suspense
          fallback={
            <div className='tasks-loader-con' id='tasks-loader-con'>
              <Spinner />
            </div>
          }
        >
          <TutorialTaskList
            taskId={taskId}
            searchDataType={
              location && location.state && location.state.dataType ? location.state.dataType : null
            }
            stateType={stateType}
            readOnly={readOnly}
          />
        </Suspense>
        <BottomBar idName='tasks-bottom-bar' />
      </Fragment>
    );
  };

  const renderOverlay = (styleClass?: string) => {
    return <div className={`tutorial-overlay ${styleClass}`}></div>;
  };

  /*
   * Render the component
   *
   * returns React.DOM
   */
  return (
    <div
      className={`tasks-screen ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
      id='tasks-screen'
    >
      {renderContent()}
      {renderOverlay()}
    </div>
  );
}

export default TutorialTasks;
