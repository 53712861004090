import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import { getUserId, isAdmin } from 'services/AuthService';
import apiConfig from 'config/api';
import { formatDateForAPI } from 'utils/task';
import { checkIfItsACustomFilter } from 'utils/helpers';
import moment from 'moment';

// const checkIfItsACustomFilter= (newFilter) => {
//   let newCustom= false;

//   const data= ['today', 'yesterday', 'currentMonth', 'lastMonth',  'yearToDate',  'previousYear'];
//   const index= data.findIndex(dat => dat === newFilter);

//   if(index === -1){
//     newCustom= true;
//     newFilter = newFilter.split('~');
// }

// if(index === -1){
//     newCustom= true;
// }

//   return {newCustom, newFilter};
// }
/*
 * Get tasks
 * dataType string
 * cancelRequest axios.CancelToken
 *
 * returns Object {data, error, status}
 */
export async function getTasks(
  dataType: TaskType,
  abortController: AbortController,
  id: string | undefined,
  taskFilters: TaskFiltersObjectType,
  stateType?: StateType,
) {
  let payload: any = {
    taskType: dataType.toUpperCase(),
    paginated: false,
  };

  if (taskFilters.sizeFilter) payload = { ...payload, taskSize: taskFilters.sizeFilter };
  if (taskFilters.tagFilter) {
    payload.tagIds = taskFilters.tagFilter;
  }
  if (taskFilters.searchTextFilter) payload.searchKey = taskFilters.searchTextFilter;

  if (taskFilters.dateFilter) {
    const { newCustom, newFilterArr } = checkIfItsACustomFilter(taskFilters.dateFilter);

    if (newCustom && newFilterArr) {
      payload.filter = 'CUSTOM';
      payload.start = formatDateForAPI(moment(newFilterArr[0], 'MM-DD-YYYY'));
      payload.end = formatDateForAPI(moment(newFilterArr[1], 'MM-DD-YYYY'));
    } else payload.filter = taskFilters.dateFilter;
  }

  if (stateType === 'PROJECT' || stateType === 'TEMPLATE') {
    payload.projectId = [id];

    if (taskFilters.assigneeFilter)
      payload = { ...payload, assigneeId: taskFilters.assigneeFilter };
  } else {
    if (typeof id === 'undefined') id = getUserId();

    payload.assigneeId = [id];

    if (taskFilters.projectFilter) payload = { ...payload, projectId: taskFilters.projectFilter };
  }

  let apiUrl = apiConfig.tasks.get_my_tasks;
  if (stateType === 'TEMPLATE') apiUrl = apiConfig.template.get_tasks;

  try {
    const response = await appClient.post(apiUrl, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Create new task
 * payload
 *
 * returns Object {data, error, status}
 */
export async function createNewTask(payload, isTemplateTask) {
  let url = apiConfig.tasks.add_task;
  if (isTemplateTask) url = apiConfig.template.add_task;
  try {
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Create new recurring task
 * payload
 *
 * returns Object {data, error, status}
 */
export async function createNewRecurringTask(payload) {
  try {
    const response = await appClient.post(apiConfig.tasks.add_recurring_task, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Create new recurring task
 * payload
 *
 * returns Object {data, error, status}
 */
export async function updateRecurringTask(payload) {
  try {
    const response = await appClient.post(apiConfig.tasks.update_recurring_task, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get recurring tasks
 * payload
 *
 * returns Object {data, error, status}
 */
export async function getRecurringTasks(filters: TaskFiltersObjectType) {
  const isUserAdmin = isAdmin();
  const payload: any = {
    paginated: false,
    sortBy: 'created',
    ascending: false,
    onlyActive: true,
    // created: isUserAdmin ? false : true,
    // // created: true,
    // // userId: getUserId(),
    // requestedByAdmin: isUserAdmin,
  };

  if (filters.searchTextFilter) payload.searchKey = filters.searchTextFilter;

  if (filters.sizeFilter) payload.taskSize = filters.sizeFilter;

  if (filters.projectFilter) payload.projectId = filters.projectFilter;

  if (filters.tagFilter) payload.labelIds = filters.tagFilter;

  if (filters.assigneeFilter) payload.assigneeId = filters.assigneeFilter;

  if (isUserAdmin) {
    if (filters.createdByFilter) payload.createdByIds = filters.createdByFilter;
  } else payload.createdByIds = [getUserId()];

  try {
    const response = await appClient.post(apiConfig.tasks.get_recurring_tasks, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Add task

 * taskValue string
 * assigneeId integer
 * parentTaskId integer
 * dataType string
 * tempId string
 * tagId integer
 * sizeId integer
 * stateType String
 *
 * returns Object {data, error, status}
 */
export async function addTask(
  taskValue: string,
  sequence: number,
  dataType: TaskType,
  tempId: string,
  projectId: string | null,
  sizeId: SizeOptionsType,
  assigneeId: string | null,
  parentTaskId?: string | null,
  stateType?: StateType,
) {
  const payload = {
    assigneeId: assigneeId,
    name: taskValue,
    [stateType === 'PROJECT' || stateType === 'TEMPLATE' ? 'projectSequence' : 'sequence']:
      sequence,
    parentTaskId: parentTaskId ? parentTaskId : 0,
    taskType: dataType ? dataType.toUpperCase() : 'BACKLOG', // Default is BACKLOG
    [stateType === 'TEMPLATE' ? 'projectTemplateId' : 'projectId']: projectId,
    size: sizeId ? sizeId : 'M',
    tempId,
  };

  let url = apiConfig.tasks.add_task;
  if (stateType === 'TEMPLATE') url = apiConfig.template.add_task;
  else if (stateType !== 'RECURRING') payload.taskStatusId = 1; // fixed value

  try {
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Delete a task
 * taskId integer
 *
 * returns Object {data, error, status}
 */
export async function deleteTask(taskId: string, isFromTemplate = false) {
  let baseurl = apiConfig.tasks.delete_task;
  if (isFromTemplate) baseurl = apiConfig.template.delete_task;
  try {
    const url = `${baseurl}/${taskId}`;
    const response = await appClient.delete(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Activate/Deactivate a task
 * taskId integer
 *
 * returns Object {data, error, status}
 */
export async function activateDeactivateTask(taskId, status) {
  try {
    const url = `${apiConfig.tasks.activate_deactivate_recurring_task}/${taskId}/${status}`;
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update task project
 *
 * returns Object {data, error, status}
 */
export async function updateTaskProject(
  projectId: string | null,
  taskId: string,
  abortController: AbortController,
  taskName?: string,
) {
  try {
    const url = `${apiConfig.tasks.update_task_project}/${projectId}/update/task/${taskId}`;
    const response = await appClient.post(url, taskName !== undefined ? taskName : null, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task size
 * size String
 * taskId integer
 * abortController
 *
 * returns Object {data, error, status}
 */
export async function updateTaskSize(
  size: SizeOptionsType,
  taskId: string,
  abortController: AbortController,
) {
  try {
    const url = `${apiConfig.tasks.update_task_size}/${taskId}/size/${size}`;
    const response = await appClient.put(
      url,
      {},
      {
        signal: abortController.signal,
      },
    );
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task due date
 * taskId String
 * payload Object
 * abortSignal AbortController
 * returns Object {data, error, status}
 */
export async function updateTaskDates(
  taskId: string,
  payload: object,
  abortSignal: AbortController,
) {
  try {
    const url = `${apiConfig.tasks.update_task_dates}/${taskId}`;
    const response = await appClient.put(url, payload, {
      signal: abortSignal.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task assignee
 * assigneeId integer
 * taskId integer
 * cancelRequest axios.CancelToken
 *
 * returns Object {data, error, status}
 */
export async function updateTaskAssignee(
  assigneeId,
  taskId,
  abortController,
  isTemplateTask = false,
  taskName?: string,
) {
  let baseurl = apiConfig.tasks.update_task_assignee;
  if (isTemplateTask) baseurl = apiConfig.template.update_task_assignee;
  try {
    const url = `${baseurl}/${taskId}/user/${assigneeId}`;
    const response = await appClient.put(url, taskName !== undefined ? taskName : null, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task value
 * newValue string
 * taskId integer
 * cancelRequest axios.CancelToken
 *
 * returns Object {data, error, status}
 */
export async function updateTaskValue(newValue, taskId, tempId, currentAbortController) {
  try {
    const payload = {
      id: taskId,
      name: newValue,
      tempId: tempId,
    };
    const response = await appClient.post(apiConfig.tasks.update_task_value, payload, {
      signal: currentAbortController.signal,
    });

    if (currentAbortController.signal.aborted) {
      return;
    }
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task sequence
 * newValue string
 * taskId integer
 * cancelRequest axios.CancelToken
 *
 * returns Object {data, error, status}
 */
export async function updateTaskSequence(
  taskId: string,
  sequence: number,
  taskType: TaskType,
  parentTaskId: string | null,
  abortController: AbortController,
  stateType: StateType,
) {
  try {
    let url = apiConfig.tasks.update_task_sequence;
    if (stateType === 'PROJECT') url += '/false';
    else url += '/true';

    const payload = [
      {
        taskId: taskId,
        sequence: sequence,
        taskType: taskType.toUpperCase(),
        parentTaskId: parentTaskId ? parentTaskId : 0,
      },
    ];
    const response = await appClient.put(url, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update parent of subtask
 * subTaskId: string
 * sequence: number
 * oldParentId: string
 * newParentId: string
 * cancelRequest
 * returns Object {data, error, status}
 */
export async function updateTaskParent(
  subTaskId: string,
  sequence: number,
  oldParentId: string,
  newParentId: string,
  abortController: AbortController,
) {
  try {
    const url = apiConfig.tasks.update_parent;

    const payload = {
      subTaskId,
      sequence: JSON.stringify(sequence),
      newParentId,
      oldParentId,
    };
    const response = await appClient.put(url, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task status
 *
 * returns Object {data, error, status}
 */
export async function updateTaskStatus(taskId: string, status: TaskStatusType) {
  try {
    const url = `${apiConfig.tasks.update_task_status}/${taskId}/update-task-status/${status}`;
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get completed tasks
 * filter string
 * page integer
 * limit integer
 * cancelRequest axios.CancelToken
 *
 * returns Object {data, error, status}
 */
export async function getCompletedTasks(
  filter,
  page,
  limit,
  abortController,
  id,
  custom,
  filter2,
  taskFilters,
  stateType,
) {
  let payload: any = {
    ascending: false,
    pageNumber: page,
    searchKey: '',
    size: limit,
    sortBy: '',
    filter: custom ? 'CUSTOM' : filter,
    paginated: true,
  };

  if (custom) payload = { ...payload, start: filter, end: filter2 };
  if (taskFilters.sizeFilter) payload = { ...payload, taskSize: taskFilters.sizeFilter };
  if (taskFilters.tagFilter) {
    payload.tagIds = taskFilters.tagFilter;
  }

  if (stateType === 'PROJECT') {
    payload.projectId = [id];

    if (taskFilters.assigneeFilter)
      payload = { ...payload, assigneeId: taskFilters.assigneeFilter };
  } else {
    if (typeof id === 'undefined') id = getUserId();

    payload.assigneeId = [id];

    if (taskFilters.projectFilter) payload = { ...payload, projectId: taskFilters.projectFilter };
  }

  try {
    const response = await appClient.post(apiConfig.tasks.get_completed_tasks, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/**
 * Comment On Task
 * @param {string} taskId
 * @param {string} taskComment
 *
 * returns Object {data, error, status}
 */
export async function addComment(taskId, taskComment, taggedUsers, tempId) {
  try {
    const payload = {
      name: taskComment,
      tempId: tempId,
      taggedUsers: taggedUsers,
    };
    const url = `${apiConfig.tasks.add_comment}/${taskId}/comment`;
    const response = await appClient.put(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Edit Comment On Task
 * @param {string} taskId
 * @param {string} taskComment
 *
 * returns Object {data, error, status}
 */
export async function editComment(taskId, taskComment, taggedUsers, commentId) {
  try {
    const payload = {
      name: taskComment,
      id: commentId,
      taggedUsers: taggedUsers,
    };
    const url = `${apiConfig.tasks.add_comment}/${taskId}/comment`;
    const response = await appClient.put(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Delete Comment of a Task
 * @param {string} taskId
 * @param {string} commentId
 *
 * returns Object {data, error, status}
 */
export async function deleteComment(taskId, commentId) {
  try {
    const url = `${apiConfig.tasks.delete_comment}/${taskId}/comment/${commentId}`;
    const response = await appClient.delete(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * add attachment to comment
 * returns Object {data, error, status}
 */
export async function addCommentAttachment(payload) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.tasks.add_comment_attachment, payload, {
      headers,
      timeout: 2000000000,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get completed tasks for tutorial
 */
export async function getTutorialCompletedTasks(abortController) {
  const url = `${apiConfig.tasks.get_completed_tasks}/type/tutorialbacklog`;

  try {
    const response = await appClient.get(url, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Update task collapsible status
 *
 * returns Object {data, error, status}
 */
export async function updateCollapseTaskStatus(
  taskId: string,
  collapseStatus: boolean,
  isProject: boolean,
  abortController: AbortController,
) {
  try {
    const url = `${apiConfig.tasks.collapse_task}/${taskId}/collapse/${collapseStatus}/project/${isProject}`;
    const response = await appClient.put(
      url,
      {},
      {
        signal: abortController.signal,
      },
    );
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

export async function bulkUpdateSize(
  size: SizeOptionsType,
  arrTaskIds: string[],
  abortController: AbortController,
) {
  try {
    const url = `${apiConfig.tasks.bulk_update_task_size}/${size}`;
    const response = await appClient.put(url, arrTaskIds, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

export async function bulkUpdateAssignee(
  assigneeId: string | null,
  arrTaskIds: string[],
  abortController: AbortController,
) {
  try {
    const url = `${apiConfig.tasks.update_task_assignee}/user/${assigneeId}`;
    const response = await appClient.put(url, arrTaskIds, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

export async function bulkUpdateProject(
  projectId: string | null,
  arrTaskIds: string[],
  abortController: AbortController,
) {
  try {
    const url = `${apiConfig.tasks.update_task_project}/${projectId}/update`;
    const response = await appClient.post(url, arrTaskIds, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

export async function bulkUpdateTaskDates(
  dates: {
    startdate?: string | null;
    enddate?: string | null;
    duedate?: string | null;
  },
  arrTaskIds: string[],
  abortController: AbortController,
) {
  const payload = {
    dates: dates,
    taskIds: arrTaskIds,
  };
  try {
    const url = `${apiConfig.tasks.bulk_update_dates}`;
    const response = await appClient.put(url, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Bulk Update task status
 *
 * returns Object {data, error, status}
 */
export async function bulkUpdateTaskStatus(arrTaskIds: string[], status: TaskStatusType) {
  try {
    const url = `${apiConfig.tasks.update_task_status}/update-task-status/${status}`;
    const response = await appClient.put(url, arrTaskIds);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function bulkUpdateTaskSequence(payload, stateType) {
  try {
    let url = apiConfig.tasks.update_task_sequence;
    if (stateType === 'PROJECT') url += '/false';
    else url += '/true';
    const response = await appClient.put(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}
