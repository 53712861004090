import { isUserLoggedIn } from 'services/AuthService';
import axios from 'axios';

export async function getBase64(url: string) {
  const { token } = isUserLoggedIn();

  return axios
    .get(url, {
      responseType: 'arraybuffer',
      headers: { token, responseType: 'arrayBuffer' },
    })
    .then((response) => {
      const image = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
      );
      return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
    });
}

export async function showVideo(url: string) {
  const { token } = isUserLoggedIn();

  return axios.get(url, { headers: { token }, responseType: 'blob' }).then((resp) => {
    return URL.createObjectURL(resp.data);
  });
}

function GetFilename(url: string) {
  const index = url.lastIndexOf('/') + 1;
  return url.substr(index);
}

export async function downloadFile(url: string) {
  const { token } = isUserLoggedIn();

  return axios.get(url, { headers: { token }, responseType: 'arraybuffer' }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = GetFilename(url);
    link.click();
  });
}
