import { Fragment, useEffect, useState } from 'preact/compat';
import { Strings } from 'resources';
import { getThemeSlug } from 'utils/theme';
import commonIcon from 'assets/images/theme-cards/rarity/common.svg';
import distinguishedIcon from 'assets/images/theme-cards/rarity/distinguished.svg';
import epicIcon from 'assets/images/theme-cards/rarity/epic.svg';
import grandmasterIcon from 'assets/images/theme-cards/rarity/grandmaster.svg';
import legendryIcon from 'assets/images/theme-cards/rarity/legendry.svg';
import majesticIcon from 'assets/images/theme-cards/rarity/majestic.svg';
import rareIcon from 'assets/images/theme-cards/rarity/rare.svg';
import equippedIcon from 'assets/images/theme-cards/equipped.svg';
import { useRecoilValue } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import { isThemeInstanceInterface, isThemeObjectInterface } from 'utils/helpers';

const ThemeCardFront = (props: ThemeCardPropsType) => {
  const { data, showThemeCardModal, index, cardType, buyTheme, redeemTheme } = props;

  const accountData = useRecoilValue(accountDataSelector);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [isOwned, setIsOwned] = useState(false);

  const getThemeName = () => {
    if (isThemeObjectInterface(data) && data.name) {
      return data.name;
    }

    if (isThemeInstanceInterface(data) && data.theme && data.theme.name) {
      return data.theme.name;
    }

    return 'Clean';
  };

  const getThemeStyleFolder = () => {
    const themeName = getThemeName();
    return getThemeSlug(themeName);
  };

  useEffect(() => {
    const themes = accountData.purchasedThemes.filter((obj) => obj.themeId === data.id);
    if (isThemeObjectInterface(data) && data.name && themes.length > 0) {
      setIsOwned(true);
    } else {
      setIsOwned(false);
    }
  }, [accountData.purchasedThemes, data]);

  useEffect(() => {
    if (isThemeObjectInterface(data) && data.name && data.remainingQuantity <= 0) {
      setIsSoldOut(true);
    } else {
      setIsSoldOut(false);
    }
  }, [data]);

  const getCurrentPoints = () => {
    let point = 0;
    if (isThemeInstanceInterface(data) && data.points) {
      point = data.points;
    }
    return point;
  };

  const getTotalPoints = () => {
    let totalPoint = '0';
    if (isThemeInstanceInterface(data) && (data.level !== null || data.level !== undefined)) {
      if (data.theme && data.theme.pointLevelsWithInactiveTriggers) {
        if (
          data.theme.pointLevelsWithInactiveTriggers &&
          Object.values(data.theme.pointLevelsWithInactiveTriggers).length > 0
        ) {
          totalPoint =
            data.theme.pointLevelsWithInactiveTriggers[data.level + 1] &&
            data.theme.pointLevelsWithInactiveTriggers[data.level + 1].points
              ? `${data.theme.pointLevelsWithInactiveTriggers[data.level + 1].points}`
              : '0';
        } else {
          totalPoint = 'max';
        }
      }
    }
    return totalPoint;
  };

  const getPointsProgressPercent = () => {
    return isThemeInstanceInterface(data) ? data.progress : 0;
  };

  const getRarityIcon = () => {
    let rarity = 'epic';
    if (isThemeObjectInterface(data) && data.rarity) {
      rarity = data.rarity;
    } else {
      if (isThemeInstanceInterface(data) && data.theme && data.theme.rarity) {
        rarity = data.theme.rarity;
      }
    }

    switch (rarity.toLowerCase()) {
      case 'common':
        return commonIcon;
      case 'distinguished':
        return distinguishedIcon;
      case 'epic':
        return epicIcon;
      case 'grandmaster':
        return grandmasterIcon;
      case 'legendry':
        return legendryIcon;
      case 'majestic':
        return majesticIcon;
      case 'rare':
        return rareIcon;
      default:
        return commonIcon;
    }
  };

  const getThemeMintCount = () => {
    if (isThemeInstanceInterface(data) && data.theme && data.theme.totalQuantity) {
      return (
        <Fragment>
          {data.mintNumber}
          <span className='remaining'>{`/${data.theme.totalQuantity}`}</span>
        </Fragment>
      );
    }

    return '';
  };

  const renderEquippedIcon = () => {
    return (
      <div
        id={`theme-euipped-icon-con-${data.id}`}
        className={`theme-equipped-icon-con-${getThemeStyleFolder()}`}
      >
        <img
          src={equippedIcon}
          alt='equipped'
          className={`theme-equipped-con-${getThemeStyleFolder()}`}
        />
      </div>
    );
  };

  const renderPurchasedThemeCard = () => {
    if (!isThemeInstanceInterface(data)) return;
    return (
      <Fragment>
        {data.equipped && cardType === 'thumbnail' ? renderEquippedIcon() : null}
        <div
          id={`theme-details-con-${data.id}`}
          className={`theme-details-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
        >
          <div
            id={`theme-name-con-${data.id}`}
            className={`theme-name-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
          >
            <div
              id={`theme-title-con-${data.id}`}
              className={`theme-title-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              {getThemeName()}
            </div>
            <div
              id={`theme-subtitle-con-${data.id}`}
              className={`theme-subtitle-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              {Strings.theme}
            </div>
          </div>
          <div
            id={`theme-mint-con-${data.id}`}
            className={`theme-mint-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
          >
            <div
              className={`theme-mint-icon-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            />
            <div
              id={`theme`}
              className={`theme-mint-number-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              {getThemeMintCount()}
            </div>
          </div>
        </div>
        <div
          id={`theme-level-bar-con-${data.id}`}
          className={`theme-level-bar-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
        >
          <div
            id={`theme-level-progress-bar-${data.id}`}
            className={`theme-level-progress-bar-${getThemeStyleFolder()} ${
              cardType ? cardType : ''
            }`}
          >
            <div
              id={`theme-level-progress-${data.id}`}
              className={`theme-level-progress-${getThemeStyleFolder()} ${
                cardType ? cardType : ''
              }`}
              style={{ width: `${getPointsProgressPercent()}%` }}
            />
            <div
              id={`theme-level-name-${data.id}`}
              className={`theme-level-name-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              <span className={`level-progress-bar-title-${getThemeStyleFolder()}`}>
                {`Level ${data.level}`}
              </span>
              {cardType !== 'thumbnail' ? (
                <span className={`level-progress-bar-subtitle-${getThemeStyleFolder()}`}>
                  {`${getCurrentPoints()}/${getTotalPoints()} ${Strings.points}`}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const getThemePriceText = () => {
    if (isOwned) {
      return Strings.owned;
    }
    if (isSoldOut) {
      return Strings.sold_out;
    }
    if (isThemeInstanceInterface(data)) return;
    if (data.price <= 0) {
      return 'Free';
    }
    return `$ ${data.price}`;
  };

  const renderBuyButton = () => {
    if (isThemeInstanceInterface(data)) return;

    return (
      <div
        className={`theme-buy-btn-con-${getThemeStyleFolder()} ${cardType ? cardType : ''} ${
          isOwned || isSoldOut ? 'hidden' : ''
        }`}
      >
        <button
          id={getThemeStyleFolder()}
          className={`theme-buy-button-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
          onClick={data.price <= 0 ? redeemTheme : buyTheme}
        >
          {data.price <= 0 ? Strings.redeem_now : Strings.buy_now}
        </button>
      </div>
    );
  };

  const renderThemeCard = () => {
    if (isThemeInstanceInterface(data)) return;

    return (
      <div
        id={`marketplace-theme-card-details-con-${data.id}`}
        className={`marketplace-theme-card-details-con-${getThemeStyleFolder()} ${
          cardType ? cardType : ''
        }`}
      >
        <div
          id={`theme-card-price-con-${data.id}`}
          className={`theme-card-price-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
        >
          <span>{getThemePriceText()}</span>
        </div>
        <div
          id={`theme-details-con-${data.id}`}
          className={`theme-details-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
        >
          <div
            id={`theme-name-con-${data.id}`}
            className={`theme-name-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
          >
            <div
              id={`theme-title-con-${data.id}`}
              className={`theme-title-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              {getThemeName()}
            </div>
            <div
              id={`theme-subtitle-con-${data.id}`}
              className={`theme-subtitle-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              {Strings.theme}
            </div>
          </div>
          <div
            id={`theme-mint-con-${data.id}`}
            className={`theme-mint-con-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
          >
            <div
              id={`theme`}
              className={`theme-mint-number-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
            >
              {`${data.remainingQuantity} `}
              <span className='remaining'>{Strings.remaining}</span>
            </div>
          </div>
        </div>
        {renderBuyButton()}
      </div>
    );
  };

  const renderThumbnailButton = () => {
    return (
      <div
        id={`${getThemeStyleFolder()}`}
        className='theme-thumbnail'
        onClick={() => showModal()}
      />
    );
  };

  const renderOverlay = () => {
    return (
      <div
        id={`themes-card-front-container-overlay-${data.id}`}
        className={`themes-card-front-container-overlay-${getThemeStyleFolder()}`}
      />
    );
  };

  const showModal = () => {
    if (cardType === 'thumbnail' && !isSoldOut && !isOwned && showThemeCardModal) {
      showThemeCardModal(data);
    }
  };

  return (
    <div
      id={`themes-card-front-${data.id}`}
      className={`theme-card-front-container-${getThemeStyleFolder()} ${cardType ? cardType : ''} ${
        isSoldOut || isOwned ? 'disable' : ''
      }`}
      key={index}
    >
      <div
        id={`theme-rarity-icon-con-${data.id}`}
        className={`theme-rarity-icon-con-main-${getThemeStyleFolder()} ${
          cardType ? cardType : ''
        }`}
      >
        <div
          id={`theme-rarity-icon-con-${data.id}`}
          className={`theme-rarity-icon-con-outer-${getThemeStyleFolder()} ${
            cardType ? cardType : ''
          }`}
        >
          <div
            id={`theme-rarity-icon-con-${data.id}`}
            className={`theme-rarity-icon-con-inner-${getThemeStyleFolder()} ${
              cardType ? cardType : ''
            }`}
          >
            <img
              id={`theme-rarity-icon-${data.id}`}
              className={`theme-rarity-icon-${getThemeStyleFolder()} ${cardType ? cardType : ''}`}
              src={getRarityIcon()}
              alt='rarity'
            />
          </div>
        </div>
      </div>
      {isThemeObjectInterface(data) ? renderThemeCard() : renderPurchasedThemeCard()}
      {cardType && cardType === 'thumbnail' ? renderThumbnailButton() : null}
      {isSoldOut || isOwned ? renderOverlay() : null}
    </div>
  );
};

export default ThemeCardFront;
