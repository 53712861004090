import { atom, DefaultValue, selector } from 'recoil';

export const searchTaskDataState = atom<TaskObjectType[] | ProjectObjectType[] | CommentType[]>({
  key: 'searchTaskData',
  default: [],
});
export const searchTaskHasMoreState = atom<boolean>({
  key: 'searchTaskHasMore',
  default: true,
});
export const searchTaskPageState = atom<number>({
  key: 'searchTaskPage',
  default: 0,
});
export const searchTaskLimitState = atom<number>({
  key: 'searchTaskLimit',
  default: 10,
});

export const searchTaskSizeState = atom<number>({
  key: 'searchTaskSize',
  default: 0,
});
export const searchTaskLoadingState = atom<boolean>({
  key: 'searchTaskLoading',
  default: false,
});
export const searchTaskAbortControllerState = atom<AbortController>({
  key: 'searchTaskAbortController',
  default: new AbortController(),
});

export const taskSearchResultState = selector<SearchResultsType>({
  key: 'taskSearchResult',
  get: ({ get }) => {
    const data = get(searchTaskDataState);
    const page = get(searchTaskPageState);
    const limit = get(searchTaskLimitState);
    const count = get(searchTaskSizeState);
    const isLoading = get(searchTaskLoadingState);
    const hasMore = get(searchTaskHasMoreState);
    const abortController = get(searchTaskAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      count,
      isLoading,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(searchTaskDataState, value);
      set(searchTaskHasMoreState, value);
      set(searchTaskPageState, value);
      set(searchTaskLimitState, value);
      set(searchTaskSizeState, value);
      set(searchTaskLoadingState, value);
      set(searchTaskAbortControllerState, value);
      return;
    }

    set(searchTaskDataState, value.data);
    set(searchTaskHasMoreState, value.hasMore);
    set(searchTaskPageState, value.page);
    set(searchTaskLimitState, value.limit);
    set(searchTaskSizeState, value.count);
    set(searchTaskLoadingState, value.isLoading);
    set(searchTaskAbortControllerState, value.abortController);
  },
});

export const projectSearchDataState = atom<TaskObjectType[] | ProjectObjectType[] | CommentType[]>({
  key: 'projectSearchData',
  default: [],
});
export const projectSearchHasMoreState = atom<boolean>({
  key: 'projectSearchHasMore',
  default: true,
});
export const projectSearchPageState = atom<number>({
  key: 'projectSearchPage',
  default: 0,
});
export const projectSearchLimitState = atom<number>({
  key: 'projectSearchLimit',
  default: 10,
});

export const projectSearchSizeState = atom<number>({
  key: 'projectSearchSize',
  default: 0,
});
export const projectSearchLoadingState = atom<boolean>({
  key: 'projectSearchLoading',
  default: false,
});
export const projectSearchAbortControllerState = atom<AbortController>({
  key: 'projectSearchAbortController',
  default: new AbortController(),
});

export const projectSearchResultState = selector<SearchResultsType>({
  key: 'projectSearchResult',
  get: ({ get }) => {
    const data = get(projectSearchDataState);
    const page = get(projectSearchPageState);
    const limit = get(projectSearchLimitState);
    const count = get(projectSearchSizeState);
    const isLoading = get(projectSearchLoadingState);
    const hasMore = get(projectSearchHasMoreState);
    const abortController = get(projectSearchAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      count,
      isLoading,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(projectSearchDataState, value);
      set(projectSearchHasMoreState, value);
      set(projectSearchPageState, value);
      set(projectSearchLimitState, value);
      set(projectSearchSizeState, value);
      set(projectSearchLoadingState, value);
      set(projectSearchAbortControllerState, value);
      return;
    }

    set(projectSearchDataState, value.data);
    set(projectSearchHasMoreState, value.hasMore);
    set(projectSearchPageState, value.page);
    set(projectSearchLimitState, value.limit);
    set(projectSearchSizeState, value.count);
    set(projectSearchLoadingState, value.isLoading);
    set(projectSearchAbortControllerState, value.abortController);
  },
});

export const commentSearchDataState = atom<TaskObjectType[] | ProjectObjectType[] | CommentType[]>({
  key: 'commentSearchData',
  default: [],
});
export const commentSearchHasMoreState = atom<boolean>({
  key: 'commentSearchHasMore',
  default: true,
});
export const commentSearchPageState = atom<number>({
  key: 'commentSearchPage',
  default: 0,
});
export const commentSearchLimitState = atom<number>({
  key: 'commentSearchLimit',
  default: 10,
});

export const commentSearchSizeState = atom<number>({
  key: 'commentSearchSize',
  default: 0,
});
export const commentSearchLoadingState = atom<boolean>({
  key: 'commentSearchLoading',
  default: false,
});
export const commentSearchAbortControllerState = atom<AbortController>({
  key: 'commentSearchAbortController',
  default: new AbortController(),
});

export const commentSearchResultState = selector<SearchResultsType>({
  key: 'commentSearchResult',
  get: ({ get }) => {
    const data = get(commentSearchDataState);
    const page = get(commentSearchPageState);
    const limit = get(commentSearchLimitState);
    const count = get(commentSearchSizeState);
    const isLoading = get(commentSearchLoadingState);
    const hasMore = get(commentSearchHasMoreState);
    const abortController = get(commentSearchAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      count,
      isLoading,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(commentSearchDataState, value);
      set(commentSearchHasMoreState, value);
      set(commentSearchPageState, value);
      set(commentSearchLimitState, value);
      set(commentSearchSizeState, value);
      set(commentSearchLoadingState, value);
      set(commentSearchAbortControllerState, value);
      return;
    }

    set(commentSearchDataState, value.data);
    set(commentSearchHasMoreState, value.hasMore);
    set(commentSearchPageState, value.page);
    set(commentSearchLimitState, value.limit);
    set(commentSearchSizeState, value.count);
    set(commentSearchLoadingState, value.isLoading);
    set(commentSearchAbortControllerState, value.abortController);
  },
});

export const tagSearchDataState = atom<TaskObjectType[] | ProjectObjectType[] | CommentType[]>({
  key: 'tagSearchData',
  default: [],
});
export const tagSearchHasMoreState = atom<boolean>({
  key: 'tagSearchHasMore',
  default: true,
});
export const tagSearchPageState = atom<number>({
  key: 'tagSearchPage',
  default: 0,
});
export const tagSearchLimitState = atom<number>({
  key: 'tagSearchLimit',
  default: 10,
});

export const tagSearchSizeState = atom<number>({
  key: 'tagSearchSize',
  default: 0,
});
export const tagSearchLoadingState = atom<boolean>({
  key: 'tagSearchLoading',
  default: false,
});
export const tagSearchAbortControllerState = atom<AbortController>({
  key: 'tagSearchAbortController',
  default: new AbortController(),
});

export const tagSearchResultState = selector<SearchResultsType>({
  key: 'tagSearchResult',
  get: ({ get }) => {
    const data = get(tagSearchDataState);
    const page = get(tagSearchPageState);
    const limit = get(tagSearchLimitState);
    const count = get(tagSearchSizeState);
    const isLoading = get(tagSearchLoadingState);
    const hasMore = get(tagSearchHasMoreState);
    const abortController = get(tagSearchAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      count,
      isLoading,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(tagSearchDataState, value);
      set(tagSearchHasMoreState, value);
      set(tagSearchPageState, value);
      set(tagSearchLimitState, value);
      set(tagSearchSizeState, value);
      set(tagSearchLoadingState, value);
      set(tagSearchAbortControllerState, value);
      return;
    }

    set(tagSearchDataState, value.data);
    set(tagSearchHasMoreState, value.hasMore);
    set(tagSearchPageState, value.page);
    set(tagSearchLimitState, value.limit);
    set(tagSearchSizeState, value.count);
    set(tagSearchLoadingState, value.isLoading);
    set(tagSearchAbortControllerState, value.abortController);
  },
});

export const searchDataSelector = selector<SearchDataType>({
  key: 'searchData',
  get: ({ get }) => {
    const task = get(taskSearchResultState);
    const comment = get(commentSearchResultState);
    const tag = get(tagSearchResultState);
    const project = get(projectSearchResultState);
    return { task, comment, tag, project };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(taskSearchResultState, value);
      set(commentSearchResultState, value);
      set(tagSearchResultState, value);
      set(projectSearchResultState, value);
      return;
    }
    set(taskSearchResultState, value.task);
    set(commentSearchResultState, value.comment);
    set(tagSearchResultState, value.tag);
    set(projectSearchResultState, value.project);
  },
});
