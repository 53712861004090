import { useEffect, useState } from 'preact/compat';
import { useRive, useStateMachineInput, Layout, Fit, Alignment } from '@rive-app/react-canvas-lite';

import { getCurrentTheme } from 'utils/theme';
import config from 'config/config';

const STATE_MACHINE = 'state_machine';

function buildRiveUrl(fileName: string, themeId: string) {
  return `/themes/${themeId}/rive/${fileName}.riv?v=${config.version}`;
}

const ProgressIndicator = ({ fileName = '', move = 0, shouldAnimate = false, ...rest }) => {
  const [progress, setProgress] = useState(0);
  const currentTheme = getCurrentTheme();
  const { rive, RiveComponent } = useRive(
    {
      src: buildRiveUrl(fileName, currentTheme.id),
      stateMachines: STATE_MACHINE,
      autoplay: true,
      // animations: shouldAnimate ? ['idle', 'start', 'loop'] : ['idle'],
      layout: new Layout({
        fit: Fit.Fill,
        alignment: Alignment.Center,
      }),
    },
    { shouldResizeCanvasToContainer: true },
  );
  const moveTo = useStateMachineInput(rive, STATE_MACHINE, 'progress');
  const animate = useStateMachineInput(rive, STATE_MACHINE, 'animate');

  useEffect(() => {
    if (animate && shouldAnimate) {
      animate.value = true;
    } else if (animate) {
      animate.value = false;
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (rive && moveTo) {
      moveTo.value = progress + 3;
    }
  }, [progress, rive, moveTo]);

  useEffect(() => {
    if (progress !== move) {
      setProgress(move);
    }
  }, [move]);

  return <RiveComponent {...rest} />;
};

export default ProgressIndicator;
