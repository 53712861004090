import { memo } from 'preact/compat';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
type SizeSelectionPropsType = {
  tempId?: string;
  itemSize: SizeOptionsType;
  showSize: boolean;
  isDisabled: boolean;
  allSizes: SizeObjectType[];
  overlayClass?: string;
  stateType?: StateType;
  updateSize: (size: SizeOptionsType) => void;
};
function SizeSelection(props: SizeSelectionPropsType) {
  const { tempId, itemSize, showSize, isDisabled, updateSize, allSizes, overlayClass, stateType } =
    props;

  // log('render components/TaskItems/IndividualItem/SizeSelection');

  if (!showSize) return null;
  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };
  return (
    <div className='item-container size-container' key={`size-con-${tempId}`}>
      <Menu
        style={{ width: '50px' }}
        className='item-menu'
        menuButton={
          <MenuButton disabled={isDisabled} className={`item-btn size-btn size-${itemSize}`}>
            <span className='item-selected'>{itemSize}</span>
          </MenuButton>
        }
      >
        {allSizes.map((sizeList, _index) => (
          <MenuItem
            key={sizeList.id}
            onClick={() => updateSize(sizeList.id)}
            // disabled={sizeList.id === itemSize}
            value={sizeList.id}
            className={sizeList.id === itemSize ? 'highlight' : ''}
          >
            {sizeList.id}
          </MenuItem>
        ))}
      </Menu>
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: SizeSelectionPropsType, nextProps: SizeSelectionPropsType) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.itemSize !== nextProps.itemSize) return false;

  if (prevProps.showSize !== nextProps.showSize) return false;

  if (prevProps.isDisabled !== nextProps.isDisabled) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  return true;
}

export default memo(SizeSelection, areEqual);
