export function hasSuccess(data: any): AsyncResponseType {
  if (data && typeof data.isException !== 'undefined' && data.isException === true) {
    return {
      data,
      success: false,
      message: data.exceptionMessage
        ? data.exceptionMessage
        : data.responseMessage
          ? data.responseMessage
          : 'Something went wrong!',
      status: data.statusCode || 0,
    };
  }

  return {
    data: data.responseData ? data.responseData : data,
    success: true,
    message: data.responseMessage ? data.responseMessage : '',
    status: 200,
  };
}

export function hasError(err: any): AsyncResponseType {
  if (err === 'Network Error') {
    return {
      success: false,
      message: 'Please check your internet connection.',
      status: 0,
    };
  }

  const error = err?.response ? err?.response : err;

  return {
    success: false,
    message: error.data?.message ? error.data?.message : 'Something went wrong.',
    status: error.status ? error.status : 0,
  };
}
