import { memo, useMemo, useEffect, useCallback } from 'preact/compat';
import { log } from 'utils';
import { hasUpdated } from 'utils/helpers';
import { parseTags } from 'utils/task';
import { getUserId, getUserName } from 'services/AuthService';
import { updateTaskTags as updateTaskTagsInAPI } from 'services/TagService';
import { useNavigate } from 'react-router-dom';
import ProjectSelection from '../../../components/TaskItems/IndividualItem/ProjectSelection';
import AssigneeSelection from '../../../components/TaskItems/IndividualItem/AssigneeSelection';
import StartAndEndDateSelection from '../../../components/TaskItems/IndividualItem/StartAndEndDateSelection';
import DueDateSelection from '../../../components/TaskItems/IndividualItem/DueDateSelection';
import SizeSelection from '../../../components/TaskItems/IndividualItem/SizeSelection';
import TaskCheckbox from '../../../components/TaskItems/IndividualItem/TaskCheckbox';
import TaskTags from '../../../components/TaskItems/IndividualItem/TaskTags';
import InfoIcon from '../../../components/TaskItems/IndividualItem/InfoIcon';
import InputBox from '../../../components/TaskItems/IndividualItem/InputBox';
import CollapseIcon from '../../../components/TaskItems/IndividualItem/CollapseIcon';
import {
  updateTaskTagsAndTagInfo as updateTaskTagsAndTagInfoInRecoil,
  setHighlightedTask as setHighlightedTaskInRecoil,
} from 'recoil/TaskState/update';
import { faDirections } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { allProjectsState } from 'recoil/ProjectState';
import {
  accountState,
  allTagsState as allAccountTagsState,
  accountSettingsState,
  combinedAllUsersSelector,
} from 'recoil/AccountState';
import { allUserTagsState } from 'recoil/UserState';
import { taskDetailModalState, navigationDataSelector } from 'recoil/NavigationState';
import { allSizes } from 'utils/size';
import AvatarIcon from 'assets/images/Avatar.png';

interface SearchTaskItemPropsInterface extends IndividualItemPropsInterface {
  itemValue: string;
  itemTags?: TagObjectType[];
  tagInfo?: TagInfoType[];
  updateSearchTaskTags?: (index: number, tags: TagObjectType[], parentIndex?: number) => void;
  updateTaskTagsAndTagInfo: (
    tags: TagObjectType[],
    tagInfo: TagInfoType[],
    index: number,
    parentIndex?: number,
  ) => void;
  saveSearchTaskName?: (
    index: number,
    taskId: string,
    value: string,
    name: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    dataType: TaskType | undefined,
    task: TaskObjectType,
  ) => void;
  updateSearchTaskSize?: (
    size: SizeOptionsType,
    taskId: string,
    index: number,
    parentIndex?: number,
  ) => void;
  updateSearchTaskProject?: (
    newTag: ProjectObjectType | null,
    taskId: string,
    index: number,
    parentIndex?: number,
  ) => void;
  updateSearchTaskAssignee?: (
    index: number,
    newAssignee: UserFieldType | null,
    parentIndex: number | undefined,
    taskId: string,
  ) => void;
  checkSearchTask?: (
    index: number,
    id: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    checked: boolean,
    item: TaskObjectType,
  ) => void;
}
export function SearchTaskItem(props: SearchTaskItemPropsInterface) {
  const userId = getUserId();
  const userName = getUserName();

  // Component props
  const {
    index,
    item,
    itemId,
    itemValue,
    itemTags,
    tagInfo,
    isTemp,
    tempId,
    parentIndex,
    parentId,
    readOnly,
    updateValue,
    itemChecked,
    itemSize,
    itemProject,
    itemNumber,
    itemStartDate,
    itemEndDate,
    itemDueDate,
    itemAssignee,
    dataType,
    stateType,
    saveSearchTaskName,
    updateSearchTaskProject,
    updateSearchTaskAssignee,
    updateSearchTaskSize,
    addNewTask,
    storeInputRefs,
    checkSearchTask,
    storeDateRefs,
    onChangeDate,
    onChangeDueDate,
    updateSearchTaskTags,
    updateTaskTagsAndTagInfo,
    hasSubtasks,
    updateTaskCollapseStatus,
    collapsedSubTasks,
  } = props;

  const navigationData = useRecoilValue(navigationDataSelector);
  const allProjects = useRecoilValue(allProjectsState);
  const account = useRecoilValue(accountState);
  const accountProfileImage = account?.profileImage;
  const allAccountUserTags = useRecoilValue(allAccountTagsState);
  const allUserTags = useRecoilValue(allUserTagsState);
  const navigate = useNavigate();
  const setTaskInfoModalStatus = useSetRecoilState(taskDetailModalState);

  /*
   * Account settings
   *
   * returns Object
   */
  const accountSettings = useRecoilValue(accountSettingsState);

  /*
   * All users
   *
   * returns Array
   */
  const allUsers = useRecoilValue(combinedAllUsersSelector({ userId: userId, userName: userName }));

  const getTagList = (tagList: TagType[]) => {
    let index = -1;
    if (itemProject) {
      index = tagList.findIndex((data) => data.projectId === itemProject.id);
      return index > -1 ? tagList[index].tags : [];
    } else {
      return tagList.length > 0 ? tagList[tagList.length - 1].tags : [];
    }
  };

  /*
   * Get all tags
   *
   * Based upon stateType and if item project is set or not
   *
   * returns Array
   */
  const allTags = useMemo(() => {
    const data = getTagList(allAccountUserTags);
    return data;
  }, [itemProject, allUserTags, allAccountUserTags]);

  const updateTagInStateAndRedux = (updatedTags: TagObjectType[]) => {
    log('updateTagInStateAndRedux', updatedTags, itemTags);
    updateSearchTaskTags && updateSearchTaskTags(index, updatedTags, parentIndex);
  };

  const updateValueInStateAndRedux = (value: string) => {
    saveSearchTaskName &&
      saveSearchTaskName(
        index,
        itemId,
        value,
        'name',
        parentIndex,
        parentId,
        dataType ? dataType : 'BACKLOG',
        item,
      );
  };

  /*
   * Update task size in backend
   *
   * size String
   *
   * returns null
   */
  const updateSize = (size: SizeOptionsType) => {
    updateSearchTaskSize && updateSearchTaskSize(size, item.id, index, parentIndex);
    log('need to update size in db', size);
  };

  /*
   * Update task project in backend
   *
   * project String
   *
   * returns null
   */
  const updateProject = (projectId: string | null) => {
    if (projectId === null) {
      updateSearchTaskProject && updateSearchTaskProject(projectId, itemId, index, parentIndex);
      return;
    }
    const project = allProjects && allProjects.filter((item) => item.id === projectId);
    if (project && project.length > 0) {
      updateSearchTaskProject && updateSearchTaskProject(project[0], itemId, index, parentIndex);
    }
    log('need to update project in db', project, parentIndex, parentId);
  };

  /*
   * Update task assignee in backend
   *
   * assignee String
   *
   * returns null
   */
  const updateAssignee = (assigneeId: string | null) => {
    if (assigneeId === null) {
      updateSearchTaskAssignee && updateSearchTaskAssignee(index, assigneeId, parentIndex, itemId);
      return;
    }
    const assignee = allUsers.filter((item) => item.id === assigneeId);
    if (assignee.length > 0) {
      updateSearchTaskAssignee && updateSearchTaskAssignee(index, assignee[0], parentIndex, itemId);
    }

    log('need to update assignee in db', assignee, parentIndex, parentId);
  };

  /*
   * Update task collapsible status in backend
   *
   * collapsible status bool
   *
   * returns null
   */
  const updateTaskCollapse = (collapseStatus: boolean) => {
    updateTaskCollapseStatus(index, itemId, collapseStatus, parentIndex, parentId);
  };

  /*
   * Delete the task in backend
   *
   * returns null
   */
  const deleteThetask = () => {
    if (item.isTemp) return;

    log('delete task', index, itemId, parentIndex);
  };

  /*
   * Get selected project name
   *
   * returns String
   */
  const selectedProjectName = useMemo(() => {
    if (!itemProject) return '';
    else {
      const index = allProjects ? allProjects.findIndex((item) => item.id === itemProject.id) : -1;

      if (index === -1) return '';
      else return allProjects ? allProjects[index].name : '';
    }
  }, [itemProject, allProjects]);

  /*
   * Get selected project name
   *
   * returns String
   */
  const selectedProjectUsers = useMemo(() => {
    if (!itemProject) return [];
    else {
      const index = allProjects ? allProjects.findIndex((item) => item.id === itemProject.id) : -1;

      if (index === -1) return [];
      else return allProjects ? allProjects[index].users : [];
    }
  }, [itemProject, allProjects]);

  /*
   * Get Project name with task number
   *
   * returns String
   */
  const getProjectNameWithTaskNumber = useMemo(() => {
    const itemProj = itemProject ? selectedProjectName : 'No Project';

    return `${itemProj} - #${itemNumber}`;
  }, [itemProject, itemNumber]);

  /*
   * Render Project name with task number
   *
   * returns React.DOM
   */
  const renderProjectNameWithTaskNumber = useMemo(() => {
    const itemProj = itemProject ? selectedProjectName : 'My Tasks';

    return (
      <span>
        <span>{itemProj}</span>
        {itemProj !== '' ? `${accountSettings.projectId || readOnly ? ' - ' : ''}` : ''}
        {accountSettings.projectId || readOnly ? '#' + itemNumber : ''}
      </span>
    );
  }, [itemProject, itemNumber, accountSettings.projectId]);

  /*
   * Get selected assignee name
   *
   * returns String
   */
  const selectedAssigneeName = useMemo(() => {
    if (!itemAssignee) return '';
    else {
      const index = allUsers.findIndex((item) => item.id === itemAssignee.id);

      if (index === -1) return '';
      else return allUsers[index].name;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Get selected assignee profile image
   *
   * returns String
   */
  const selectedAssigneeProfileImage = useMemo(() => {
    if (!itemAssignee) return AvatarIcon;
    else {
      if (itemAssignee.id === getUserId()) {
        if (accountProfileImage) return accountProfileImage;
        else return AvatarIcon;
      }

      const index = allUsers.findIndex((item) => item.id === itemAssignee.id);

      if (index !== -1 && allUsers[index].profileImage) return allUsers[index].profileImage;

      return AvatarIcon;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Set task tags in backend
   *
   * tagsArray Array
   * add Boolean
   *
   * returns null
   */
  const setTaskTags = async (tagsArray: string[], add: boolean) => {
    if (isTemp) return;

    const { success, data } = await updateTaskTagsInAPI(itemId, tagsArray, add, tempId);

    if (success && add && data && data.tags && data.tagInfo) {
      updateTaskTagsAndTagInfoInRecoil(
        dataType,
        index,
        parseTags(data.tags ? data.tags : []) as TagObjectType[],
        data.tagInfo ? data.tagInfo : [],
        parentIndex,
        stateType,
      );
      updateTaskTagsAndTagInfo(data.tags, data.tagInfo, index, parentIndex);
    }
  };

  /*
   * Check if task is disabled or not
   *
   * returns Boolean
   */
  const isDisabled = useMemo(() => {
    if (readOnly || itemChecked) {
      return true;
    }

    return false;
  }, [itemChecked]);

  /**
   * Check if task details is currently opened
   * @returns Boolean
   */
  const isSelected = () => {
    if (navigationData.taskDetailModal.taskId === itemId) {
      return true;
    }
    return false;
  };
  /*
   * Check if task is currently highlighted based on stateType for mobile
   *
   * returns Boolean
   */
  const isHighlighted = () => {
    return false;
  };

  /*
   * Show task info
   *
   * returns null
   */
  const showTaskInfo = () => {
    setTaskInfoModalStatus({
      visible: true,
      modalType: 'task',
      taskId: itemId,
      parentId: parentId,
      hideComments: true,
      taskObj: item,
      dataType: dataType,
    });
  };

  /*
   * Show comments modal
   *
   * returns null
   */
  // const showCommentModal = () => {
  //   setTaskInfoModalStatus({
  //     visible: true,
  //     modalType: 'comment',
  //     taskId: itemId,
  //     parentId: parentId,
  //     hideComments: false,
  //     taskObj: null,
  //     dataType: dataType,
  //   });
  // };

  /*
   * Check/uncheck task
   *
   * returns null
   */
  const checkTheTask = useCallback(() => {
    log('check the task', itemValue);

    if (item.isTemp) return;

    if (parentId && readOnly) return;

    if (itemValue.trim() === '') deleteThetask();
    else
      checkSearchTask && checkSearchTask(index, itemId, parentIndex, parentId, !itemChecked, item);
  }, [itemValue, updateValue, itemChecked, itemId]);

  /*
   * Get tag id for name
   *
   * tagName String
   *
   * returns String or null
   */
  const getTagIdForName = (tagName: string) => {
    if (allTags) {
      const index =
        allTags && allTags.findIndex((tag) => tag.name.toUpperCase() === tagName.toUpperCase());

      if (index !== -1) return allTags[index].id;
    }
    return null;
  };

  /*
   * Remove tag from task
   *
   * tagId String
   * tagName String
   *
   * returns String
   */
  const removeTagFromTask = (tagId: string | null, tagName: string) => {
    if (isDisabled) return;

    if (!tagId) tagId = getTagIdForName(tagName);

    if (tagId) {
      setTaskTags([tagId], false);
      removeTagFromTaskTags(tagId);
    }
  };

  const removeTagFromTaskTags = (id: string) => {
    let tags = itemTags;
    tags = tags && tags.filter((tag) => tag.id !== id);

    updateTagInStateAndRedux(tags ? tags : []);
  };

  /*
   * Render info section
   *
   * returns React.DOM
   */
  const renderInfoSection = () => {
    return <InfoIcon tempId={tempId} stateType={stateType} showTaskInfo={showTaskInfo} />;
  };

  const redirectToTaskList = () => {
    let url = '/';
    if (itemProject) {
      url = `/project/${itemProject.id}`;
    } else if (itemAssignee && itemAssignee.id !== userId) {
      url = `/user/${itemAssignee.id}`;
    }
    if (item.checked) {
      url = url + '/completed';
    }
    navigate(url, { state: { taskId: itemId, dataType: dataType } });
  };

  const renderRedirectionSection = () => {
    return (
      <button
        className={`redirect-btn ${item.checked ? 'hide' : ''}`}
        onClick={() => redirectToTaskList()}
      >
        <FontAwesomeIcon className='redirect-icon' icon={faDirections} />
      </button>
    );
  };

  /*
   * Render size Selection
   *
   * returns React.DOM
   */
  const renderSizeSelection = () => {
    return (
      <SizeSelection
        tempId={tempId}
        itemSize={itemSize}
        showSize={accountSettings.size}
        readOnly={readOnly}
        isDisabled={isDisabled}
        updateSize={(size) => updateSize(size)}
        allSizes={allSizes}
      />
    );
  };

  /*
   * Render project Selection
   *
   * returns React.DOM
   */
  const renderProjectSelection = () => {
    return (
      <ProjectSelection
        allProjects={allProjects ?? []}
        showProjectId={accountSettings.projectId}
        isSubtask={parentId ? true : false}
        itemProject={itemProject}
        hasAssignee={itemAssignee ? true : false}
        projectNameWithTaskNumber={getProjectNameWithTaskNumber}
        renderProjectNameWithTaskNumber={renderProjectNameWithTaskNumber}
        isDisabled={isDisabled}
        updateProject={(project) => updateProject(project ? project : '')}
        stateType={stateType}
      />
    );
  };

  /*
   * Render assignee Selection
   *
   * returns React.DOM
   */
  const renderAssigneeSelection = () => {
    return (
      <AssigneeSelection
        tempId={tempId}
        allUsers={allUsers}
        selectedProjectUsers={selectedProjectUsers}
        selectedAssigneeName={selectedAssigneeName}
        selectedAssigneeProfileImage={selectedAssigneeProfileImage}
        stateType={stateType}
        itemAssignee={itemAssignee}
        hasProject={itemProject ? true : false}
        isDisabled={isDisabled}
        updateAssignee={(assignee) => updateAssignee(assignee)}
        userInWorkspace={
          itemAssignee ? allUsers.findIndex((data) => data.id === itemAssignee.id) !== -1 : true
        }
        selectedTemplateUsers={[]}
      />
    );
  };

  const renderCollapsibleComponent = () => {
    return (
      <CollapseIcon
        tempId={tempId}
        hasSubtasks={hasSubtasks}
        collapsedSubTasks={collapsedSubTasks}
        updateTaskCollapse={(status) => updateTaskCollapse(status)}
      />
    );
  };

  /**
   * Render Dates container
   * @returns React.Dom
   */
  const renderDates = () => {
    if (!accountSettings.dueDate && !accountSettings.startAndEnd && !readOnly) return null;

    return (
      <div className='dates-container'>
        {renderDueDate()}
        {renderStartEndDate()}
      </div>
    );
  };
  /*
   * Render start end date
   *
   * returns React.DOM
   */
  const renderStartEndDate = () => {
    return (
      <StartAndEndDateSelection
        tempId={tempId}
        itemStartDate={itemStartDate}
        itemEndDate={itemEndDate}
        readOnly={readOnly}
        showStartAndEnd={accountSettings.startAndEnd}
        isDisabled={isDisabled}
        onChangeDate={(dates) => onChangeDate(index, itemId, dates, tempId, parentIndex)}
        storeDateRefs={(tempId, input) => storeDateRefs(tempId, input)}
      />
    );
  };

  /*
   * Render due date
   *
   * returns React.DOM
   */
  const renderDueDate = () => {
    return (
      <DueDateSelection
        tempId={tempId}
        dataType={dataType}
        itemDueDate={itemDueDate}
        readOnly={readOnly}
        showDueDate={accountSettings.dueDate}
        isDisabled={isDisabled}
        onChangeDueDate={(date) => onChangeDueDate(index, itemId, date, tempId, parentIndex)}
      />
    );
  };

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = () => {
    return (
      <TaskCheckbox
        tempId={tempId}
        itemChecked={itemChecked}
        isDisabled={isDisabled}
        checkTheTask={() => checkTheTask()}
      />
    );
  };

  /**
   * add scroll event listener
   */
  useEffect(() => {
    if (window.innerWidth <= 800) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  /**
   * called on scroll event to set highlight task on top of list
   * @param {Object} _event
   */
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const element = document.getElementById(`task-con-${tempId}`);
    if (element) {
      const elementOffset = element.offsetTop;
      const distance = elementOffset - scrollTop;
      if (distance > 60 && distance < 100) {
        log('scrolling', item.name, scrollTop, distance);
        setHighlightedTaskInRecoil(item, stateType);
      }
    }
  };

  /**
   * set the tapped task as highlighted task in redux
   */
  const setHighlightedTask = () => {
    setHighlightedTaskInRecoil(item, stateType);
  };

  const renderTaskTags = () => {
    return (
      <TaskTags
        tempId={tempId}
        itemTags={itemTags ? itemTags : []}
        tagInfo={tagInfo ? tagInfo : []}
        itemTagsLength={itemTags ? itemTags.length : 0}
        removeTagFromTask={(id, name) => removeTagFromTask(id, name)}
        isMultiSelected={false}
      />
    );
  };

  /*
   * Render Input box
   *
   * returns React.DOM
   */

  const renderInputBox = () => {
    const inputProps = {
      dataType,
      stateType,
      tempId,
      itemId,
      index,
      parentId,
      parentIndex,
      isDisabled,
      itemValue,
      updateValue,
      itemProject,
      itemAssignee,
      itemSize,
      itemTags,
      allTags,
      allProjects,
      selectedProjectUsers,
      allUsers,
      setTaskTags: (id: string, _name: string) => setTaskTags([id], true),
      updateSize,
      updateProject,
      updateAssignee,
      valueUpdated: (value, _instant) => updateValueInStateAndRedux(value),
      addNewTask,
      deleteThetask,
      getTagIdForName,
      removeTagFromTask,
      storeInputRefs,
    };
    return <InputBox {...inputProps} />;
  };

  // log('render components/TaskItems/SearchTaskItem', index);

  /*
   * Render Component
   *
   * returns React.DOM
   */
  return (
    <div
      id={`task-con-${tempId}`}
      className={`search-item-con task-con  ${parentId ? 'is-subtask' : ''} ${
        hasSubtasks && !collapsedSubTasks ? 'has-subtasks' : ''
      } 'task-not-dragging' ${isDisabled ? 'task-readonly' : ''} ${
        isHighlighted() ? 'highlight' : ''
      } ${isSelected() ? 'selected' : ''}`}
      key={`task-con-${tempId}`}
      onClick={setHighlightedTask}
    >
      <div className='task-con-in task-con-left'>
        {parentId ? null : renderCollapsibleComponent()}
        {renderCheckbox()}
        {accountSettings.assigneeName ? renderAssigneeSelection() : null}
        {accountSettings.projectName ? renderProjectSelection() : null}
        {renderInputBox()}
      </div>
      <div className='task-con-in task-con-right'>
        <div className='task-controls'>
          {renderTaskTags()}
          {renderInfoSection()}
          {renderSizeSelection()}
        </div>
        {!accountSettings.projectName ? renderProjectSelection() : null}
        {renderDates()}
        {!accountSettings.assigneeName ? renderAssigneeSelection() : null}
        {renderRedirectionSection()}
      </div>
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps, nextProps) {
  const fields = [
    { name: 'hasSubtasks', type: 'prime' },
    { name: 'updateValue', type: 'prime' },
    { name: 'isTemp', type: 'prime' },
    { name: 'tempId', type: 'prime' },
    { name: 'itemProject', type: 'prime' },
    { name: 'itemSize', type: 'prime' },
    { name: 'itemAssignee', type: 'prime' },
    { name: 'itemStartDate', type: 'prime' },
    { name: 'itemEndDate', type: 'prime' },
    { name: 'itemDueDate', type: 'prime' },
    { name: 'itemChecked', type: 'prime' },
    { name: 'itemNumber', type: 'prime' },
    { name: 'itemTags', type: 'prime' },
    { name: 'tagInfo', type: 'prime' },
    { name: 'itemId', type: 'prime' },
    { name: 'index', type: 'prime' },
    { name: 'collapsedSubTasks', type: 'prime' },
    { name: 'item', type: 'object' },
    { name: 'searchList', type: 'object' },
  ];
  return hasUpdated(prevProps, nextProps, fields);
}

export default memo(SearchTaskItem, areEqual);
