import { atom, selector } from 'recoil';

export const sizeDataState = atom<SizeDataType>({
  key: 'sizeData',
  default: {
    allSizes: [
      { id: 'NA', display: 'NA', name: 'NA', desc: 'NA', points: 0 },
      { id: 'S', display: 'S', name: 'S', desc: 'Small', points: 50 },
      { id: 'M', display: 'M', name: 'M', desc: 'Medium', points: 100 },
      { id: 'L', display: 'L', name: 'L', desc: 'Large', points: 200 },
      { id: 'XL', display: 'XL', name: 'XL', desc: 'Extra Large', points: 500 },
    ],
  },
});

export const allSizesSelector = selector({
  key: 'allSizes',
  get: ({ get }) => {
    const availableSizes = get(sizeDataState).allSizes;
    const newSizes = {};
    availableSizes.forEach((size) => {
      newSizes[size.id] = size;
    });
    return newSizes;
  },
});
