import config from 'config/config';
import Lottie from 'lottie-web';
import { canBeAdded } from 'triggers/utils/css';

export function loadLottie(id: string, themeId: string, duration: number) {
  const div = createDiv(id, true);
  const url = buildLottieUrl(id, themeId);

  Lottie.loadAnimation({
    container: div, // the dom element that will contain the animation
    renderer: 'canvas',
    loop: false,
    autoplay: false,
    path: url,
    rendererSettings: {
      clearCanvas: true, // the canvas context, only support "2d" context
      preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
    }, // the path to the animation json
  });

  setTimeout(() => {
    removeElement(id);
  }, duration);
}

export function playLottie(id: string, themeId: string, duration: number) {
  if (!canBeAdded(id)) return;

  const div = createDiv(id);
  const url = buildLottieUrl(id, themeId);

  const lottie = Lottie.loadAnimation({
    container: div, // the dom element that will contain the animation
    renderer: 'canvas',
    loop: false,
    autoplay: false,
    path: url,
    rendererSettings: {
      clearCanvas: true, // the canvas context, only support "2d" context
      preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
    }, // the path to the animation json
  });

  lottie.play();

  setTimeout(() => {
    removeElement(id);
  }, duration);
}

function createDiv(id: string, isHidden?: boolean) {
  const div = document.createElement('div');
  div.id = getLottieId(id);

  if (isHidden) {
    div.style.cssText = 'position:absolute;width:100%;height:100%;z-index:0;';
  } else div.style.cssText = 'position:fixed;width:100vw;height:100vh;top:0;left:0;z-index:300;';

  document.body.appendChild(div);

  return div;
}

function getLottieId(id: string) {
  return id + '-lottie';
}

function buildLottieUrl(fileName: string, themeId: string) {
  return `/themes/${themeId}/lottie/${fileName}.json?v=${config.version}`;
}

function removeElement(id: string) {
  const link = document.getElementById(getLottieId(id));

  if (link) link.remove();
}
