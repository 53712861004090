import { useEffect, useState } from 'preact/hooks';
import './task-form.scss';
import { Strings } from 'resources';
import { Fragment } from 'preact/jsx-runtime';
import { createFormTaskAPI } from 'services/ProjectService';
import { getProjectFormName } from 'recoil/ProjectState/update';
import { useRecoilValue } from 'recoil';
import {
  projectFormErrorState,
  projectFormNameState,
  projectFormLoadState,
} from 'recoil/ProjectState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

export default function TaskForm() {
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmissionFailedMsg, setFormSubmissionFailedMsg] = useState<undefined | string>(
    undefined,
  );

  // const [invaliEmail, setInvalidEmail] = useState(true);
  const queryParams = new URLSearchParams(location.search);

  const projectName = useRecoilValue(projectFormNameState);
  const formError = useRecoilValue(projectFormErrorState);
  const isFormLoading = useRecoilValue(projectFormLoadState);
  const projectId = queryParams.get('p');
  const formId = queryParams.get('f');
  const embed = queryParams.get('embed');

  // const [email, setEmail] = useState('');
  // const [dueDate, setDueDate] = useState<undefined | string>(undefined);
  const [task, setTaskText] = useState('');
  const [comment, setCommentText] = useState('');
  const [attachment, setAttachment] = useState<null | File>(null);
  const [showEmbed, setShowEmbed] = useState(false);
  const [embedError, setEmbedError] = useState(false);
  // const [selectedSize, setSelectedSize] = useState('NA');

  useEffect(() => {
    if (projectId === null || formId === null) navigate('/');
    if (window.location !== window.parent.location) {
      // The page is in an iFrames
      if (embed) setShowEmbed(true);
      else setEmbedError(true);
    } else {
      // The page is not in an iFrame
    }
  }, [projectId, formId]);

  useEffect(() => {
    const mainElement = document.getElementById('root');
    if (mainElement) mainElement.style.cssText = `display:flex; width: 100%;height: 100%`;
    const datePicker = document.getElementById('datePickerId');
    if (datePicker) (datePicker as HTMLInputElement).min = new Date().toISOString().split('T')[0];
  }, []);

  // const emailFieldUpdated = (text: string) => {
  //   const regex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   if (!regex.test(text)) {
  //     setInvalidEmail(true);
  //   } else {
  //     setInvalidEmail(false);
  //   }
  //   setEmail(text);
  // };

  // const dueDateFieldUpdated = (text: string) => {
  //   setDueDate(text);
  // };

  // const handleSizeChange = (selected: string) => {
  //   setSelectedSize(selected);
  // };

  useEffect(() => {
    if (formId) {
      getProjectFormName(formId);
    } else {
      navigate('/');
    }
  }, []);

  // const sizeOptions = ['NA', 'S', 'M', 'L', 'XL'];

  // const renderSizeSelectionRadioBtn = (value: string) => {
  //   return (
  //     <div className={'radio-btn-con'}>
  //       {' '}
  //       <input
  //         type='radio'
  //         id={value}
  //         value={value}
  //         checked={selectedSize === value}
  //         onChange={() => handleSizeChange(value)}
  //       />
  //       <label htmlFor={value}>{value}</label>
  //     </div>
  //   );
  // };

  // const renderEmailField = () => {
  //   return (
  //     <div className='task-creation-field-row'>
  //       <label className='task-creation-field-row-name'>
  //         Email
  //         <span className='task-creation-field-row-required'>*</span>
  //       </label>
  //       <div className='task-creation-field-row-input'>
  //         <div className='text-input-main-con'>
  //           <div className='text-input-con'>
  //             <input
  //               className='form-input-container'
  //               placeholder={Strings.email_address}
  //               onChange={(event) => emailFieldUpdated((event.target as HTMLInputElement).value)}
  //               onBlur={(event) => emailFieldUpdated((event.target as HTMLInputElement).value)}
  //               value={email}
  //             />
  //           </div>
  //           {email.length === 0 ? (
  //             <div className='error-message'>This field is required</div>
  //           ) : invaliEmail ? (
  //             <div className='error-message'>Email is not valid.</div>
  //           ) : null}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderTaskField = () => {
    return (
      <div className='task-creation-field-row'>
        <label className='task-creation-field-row-name'>
          Subject
          <span className='task-creation-field-row-required'>*</span>
        </label>
        <div className='task-creation-field-row-input'>
          <div className='text-input-main-con'>
            <div className='text-input-con'>
              <textarea
                className='form-input-container long-text'
                value={task}
                onChange={(e) => setTaskText((e.target as HTMLTextAreaElement).value)}
              />
            </div>
            {task.length === 0 ? <div className='error-message'>This field is required</div> : null}
          </div>
        </div>
      </div>
    );
  };

  const renderCommentField = () => {
    return (
      <div className='task-creation-field-row'>
        <label className='task-creation-field-row-name'>Description</label>
        <div className='task-creation-field-row-input'>
          <div className='text-input-main-con'>
            <div className='text-input-con'>
              <textarea
                className='form-input-container long-text'
                value={comment}
                onChange={(e) => setCommentText((e.target as HTMLTextAreaElement).value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderSizeField = () => {
  //   return (
  //     <div className='task-creation-field-row'>
  //       <label className='task-creation-field-row-name'>
  //         Size
  //         <span className='task-creation-field-row-required'>*</span>
  //       </label>
  //       <div className='task-creation-field-row-input'>
  //         <div className='text-input-main-con'>
  //           <div className='text-input-con size-con'>
  //             {sizeOptions.map((item) => renderSizeSelectionRadioBtn(item))}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderDueDateField = () => {
  //   return (
  //     <div className='task-creation-field-row'>
  //       <label className='task-creation-field-row-name'>Due Date</label>
  //       <div className='task-creation-field-row-input'>
  //         <div className='text-input-main-con'>
  //           <div className='text-input-con'>
  //             <input
  //               type='date'
  //               id='datePickerId'
  //               className='form-input-container'
  //               placeholder='Due Date'
  //               onChange={(event) => dueDateFieldUpdated((event.target as HTMLInputElement).value)}
  //               onBlur={(event) => dueDateFieldUpdated((event.target as HTMLInputElement).value)}
  //               value={dueDate}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const submitClicked = async () => {
    if (task.length > 0 && formId && projectId) {
      setLoadingState(true);
      const formData = new FormData();
      formData.append('name', task);
      formData.append('status', 'OPEN');
      formData.append('taskType', 'BACKLOG');
      formData.append('size', 'NA');
      formData.append('projectId', projectId);
      formData.append('formId', formId);

      if (attachment) formData.append('file', attachment);

      if (comment.length > 0) formData.append('comment', comment);
      // const payload: any = {
      //   name: task,
      //   status: 'OPEN',
      //   taskType: 'BACKLOG',
      //   size: 'NA', // selectedSize,
      //   projectId: projectId,
      //   formId: formId,
      //   // email: email,
      // };
      // if (dueDate && dueDate.length > 0) payload.dueDate = dueDate;
      const { success, message } = await createFormTaskAPI(formData);
      setLoadingState(false);
      if (success) {
        setFormSubmitted(true);
      } else {
        setFormSubmissionFailedMsg(message);
      }
    }
  };

  const renderSubmitButton = () => {
    return (
      <div className='task-form-submit-button-con'>
        <button className='task-form-submit-button' onClick={() => submitClicked()}>
          Submit
        </button>
      </div>
    );
  };

  const renderFooterText = () => {
    if (embed !== null) return;
    return (
      <div className={'task-form-footer-con'}>
        Powered by <div className={'task-form-footer-logo'}></div>
      </div>
    );
  };

  const renderLoadingScreen = (initialLoad: boolean = true) => {
    return (
      <div className='task-form-page'>
        <div className='task-form-confirmation-page'>
          <div className='task-form-page-body'>
            <FontAwesomeIcon icon={faSpinner} spin />
            <span className='task-form-text'>
              {initialLoad ? 'Loading your form...' : 'Submitting your form...'}
            </span>
          </div>
        </div>
        {renderFooterText()}
      </div>
    );
  };

  const renderFormError = (submission: boolean) => {
    return (
      <div className='task-form-page'>
        <div className='task-form-confirmation-page'>
          <div className='task-form-page-body'>
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span className='task-form-text'>
              {submission
                ? formSubmissionFailedMsg
                  ? formSubmissionFailedMsg
                  : 'Your submission failed!'
                : formError
                  ? formError
                  : 'Something went wrong'}
            </span>
          </div>
        </div>
        {renderFooterText()}
      </div>
    );
  };

  const renderEmbedFormError = () => {
    return (
      <div className='embed-form-error-page'>
        <div className='embed-form-error-page-body'>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <span className='task-form-text'>Error in loading form!</span>
        </div>
      </div>
    );
  };

  const renderFormSubmittedScreen = () => {
    return (
      <div className='task-form-page'>
        <div className='task-form-confirmation-page'>
          <div className='task-form-page-body'>
            <FontAwesomeIcon icon={faCheckCircle} />
            <span className='task-form-text'>Your submission has been received</span>
          </div>
        </div>
        {renderFooterText()}
      </div>
    );
  };

  const onDrop = (files: File[]) => {
    if (files.length === 0) return;
    const uploadedFile = files[0];
    setAttachment(uploadedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: { 'image/*': ['.jpeg', '.png'] },
  });

  const renderFileUploadSection = () => {
    return (
      <div className={'doc-upload-container'}>
        <div className={'doc-upload-item'}>
          <p>Upload File</p>
          <section className='container'>
            <div {...(getRootProps({ className: 'dropzone' }) as any)} data-cy='fileUpload'>
              <input {...(getInputProps() as any)} />
              {isDragActive ? (
                <p>Drop file here...</p>
              ) : attachment ? (
                <p>File selected: {attachment.name}</p>
              ) : (
                <p>
                  Drop file here or <span>{Strings.browse}</span>
                </p>
              )}
            </div>
          </section>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className='task-form-screen'>
        <div className='task-form-body'>
          <div className='task-form-submission-form'>
            <div className='task-form-header'>
              <div className='task-form-header-card-con'>
                <div className='task-form-header-form-name-container'>
                  <h3>{projectName}</h3>
                </div>
              </div>
            </div>
            <div className='task-form-container'>
              <div className='task-creation-form'>
                {/* {renderEmailField()} */}
                {renderTaskField()}
                {renderCommentField()}
                {renderFileUploadSection()}
                {/* {renderSizeField()}
                {renderDueDateField()} */}
                {renderSubmitButton()}
              </div>
            </div>
          </div>
        </div>
        {renderFooterText()}
      </div>
    );
  };

  const getShareLink = () => {
    return `${window.location.origin}/form?f=${formId}&p=${projectId}`;
  };

  const renderEmbededForm = () => {
    return (
      <div className={'embeded-form'}>
        <div className={'embeded-form-body'}>
          <div className='task-form-header-card-con'>
            <div className={'task-form-header-form-name-container'}>
              <h3>{projectName}</h3>
            </div>
            <div className={'task-form-submit-button-con'}>
              <a href={getShareLink()} className='task-form-submit-button' target='_blank'>
                Create Task
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {isFormLoading
        ? renderLoadingScreen()
        : formError
          ? renderFormError(false)
          : embedError
            ? renderEmbedFormError()
            : showEmbed
              ? renderEmbededForm()
              : isLoading
                ? renderLoadingScreen(false)
                : formSubmitted
                  ? renderFormSubmittedScreen()
                  : formSubmissionFailedMsg
                    ? renderFormError(true)
                    : renderForm()}
    </Fragment>
  );
}
