import { log } from 'utils';
import { getItem, setItem } from 'utils/storage';
import config from 'config/config';
import { initializeTriggers } from 'triggers';
import allThemes from 'config/themes.json';
export const THEME_KEY = 'magic_task_theme';
export const THEME_STYLESHEET_KEY = 'global-theme-style';

const defaultTheme = { name: 'Clean', id: 'clean', type: 'micro' };

export const getThemeSlug = (name) => {
  if (name !== null && name !== undefined && name.length > 0)
    return name.replaceAll(' ', '-').toLowerCase();
  return 'clean';
};

export const getCurrentTheme = () => {
  const { data, error } = getItem(THEME_KEY);

  if (error) return defaultTheme;

  const index = allThemes.findIndex((theme) => theme.id === data);

  if (index === -1) return defaultTheme;

  return allThemes[index];
};

export const getComponentThemeUrl = (fileName: string) => {
  const currentTheme = getCurrentTheme();

  if (currentTheme.type !== 'micro') return null;

  return `styles/${currentTheme.id}/${fileName}`;
};

export const getLayoutThemeUrl = (isSuperAdmin: boolean, isAuth: boolean) => {
  if (isSuperAdmin) return defaultTheme.id;

  if (isAuth) return 'glitch';

  const currentTheme = getCurrentTheme();
  return currentTheme.id;
};

export const setTheme = (themeSlug: string, path: string | null = null) => {
  const { error } = setThemeInLocalStorage(themeSlug);
  if (path) {
    if (path !== 'login') {
      window.location.replace(window.location.origin + path);
    }
  } else {
    window.location.reload();
  }
  return { error };
};

export const setThemeInLocalStorage = (themeSlug: string) => {
  return setItem(THEME_KEY, themeSlug);
};

export const replaceTheme = (newThemeSlug: string) => {
  setThemeInLocalStorage(newThemeSlug);

  const { id } = getCurrentTheme();
  let loaded = false;

  const mainElement = document.getElementById('root');
  if (mainElement) mainElement.style.cssText = `display:none;`;
  log('stylesheet replaceTheme none');

  const oldLink = document.getElementById(THEME_STYLESHEET_KEY);
  if (oldLink) {
    oldLink.remove();
  }

  const link = document.createElement('link');
  link.id = THEME_STYLESHEET_KEY;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = `/themes/${id}/styles/main.css?v=${config.version}`;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);

  link.onload = function () {
    log('stylesheet onload replaceTheme');
    loaded = true;
    const mainElement = document.getElementById('root');
    if (mainElement) mainElement.style.cssText = `display:block;`;
    log('stylesheet onload replaceTheme block');

    // reinit triggers
    initializeTriggers(true);
  };

  // backup
  setTimeout(() => {
    log('stylesheet onload replaceTheme backup', loaded);

    if (!loaded) {
      const mainElement = document.getElementById('root');
      if (mainElement) mainElement.style.cssText = `display:block;`;
      log('stylesheet onload replaceTheme backup block');

      initializeTriggers(true);
    }
  }, 5000);
};

export const loadTheme = (def: boolean, auth: boolean = false) => {
  const layoutTheme = getLayoutThemeUrl(def, auth);
  if (layoutTheme) {
    let loaded = false;
    const mainElement = document.getElementById('root');
    if (mainElement) mainElement.style.cssText = `display:none;`;
    log('stylesheet layoutTheme loadTheme none');
    const url = `/themes/${layoutTheme}/styles/main.css?v=${config.version}`;

    const oldLink = document.getElementById(THEME_STYLESHEET_KEY);

    if (oldLink) {
      oldLink.remove();
    }

    log('themeload yes', url);

    const link = document.createElement('link');
    link.id = THEME_STYLESHEET_KEY;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);

    link.onload = function () {
      log('stylesheet onload');
      loaded = true;
      const mainElement = document.getElementById('root');
      if (mainElement) mainElement.style.cssText = `display:block;`;
      log('stylesheet onload layoutTheme loadTheme block');
    };

    // backup
    setTimeout(() => {
      log('stylesheet onload backup');
      if (!loaded) {
        const mainElement = document.getElementById('root');
        if (mainElement) mainElement.style.cssText = `display:block;`;
        log('stylesheet layoutTheme loadTheme onload backup block');
      }
    }, 5000);
  }
};
