import { useEffect } from 'preact/hooks';
import { Outlet } from 'react-router-dom';
// import rg4js from 'raygun4js';
import { setPageTitle } from 'utils';
import { loadTheme } from 'utils/theme';
// type AuthLayoutWithoutAuthProps = {
//     component: any,
//     pageTitle: string,
//     exact: boolean,
//     path: string,
// }

// export default function AuthLayoutWithoutAuth({ component: Component, pageTitle, ...rest }) {
export default function AuthLayoutWithoutAuth({ pageTitle }) {
  useEffect(() => {
    loadTheme(false, true);
  }, []);

  useEffect(() => {
    setPageTitle(pageTitle ? pageTitle : '');

    // rg4js('trackEvent', {
    //   type: 'pageView',
    //   path: '/' + window.location.pathname,
    // });
  }, [pageTitle]);
  return (
    <div className={`auth-layout-container`}>
      {/** @ts-ignore */}
      <Outlet />
    </div>
  );
}
