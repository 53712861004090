import { DefaultValue, selector } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import { navigationDataSelector } from 'recoil/NavigationState';
import { projectDataSelector } from 'recoil/ProjectState';
import { recurringTaskDataSelector } from 'recoil/RecurringTaskState';
import { searchDataSelector } from 'recoil/SearchState';
import { superAdminAnalyticsFilterSelector } from 'recoil/SuperAdminAnalyticsState';
import { superAdminDataSelector } from 'recoil/SuperAdminState';
import { taskDataStateSelector } from 'recoil/TaskState';
import { templateDataSelector } from 'recoil/TemplateState';
import { triggerDataSelector } from 'recoil/TriggerState';
import { tutorialDataSelector } from 'recoil/TutorialState';
import { userAnalyticsFilterSelector } from 'recoil/UserAnalyticsState';
import { userDataSelector } from 'recoil/UserState';

export const combinedSelector = selector<StateDataObjectType>({
  key: 'combinedData',
  get: ({ get }) => {
    const accountData = get(accountDataSelector);
    const taskData = get(taskDataStateSelector);
    const recurringTaskData = get(recurringTaskDataSelector);
    const navigationData = get(navigationDataSelector);
    const projectData = get(projectDataSelector);
    const templateData = get(templateDataSelector);
    const tutorialData = get(tutorialDataSelector);
    const userData = get(userDataSelector);
    const triggerData = get(triggerDataSelector);
    const searchData = get(searchDataSelector);
    const superAdminData = get(superAdminDataSelector);
    const userAnalyticsData = get(userAnalyticsFilterSelector);
    const superAdminAnalyticsData = get(superAdminAnalyticsFilterSelector);
    return {
      accountData,
      taskData,
      recurringTaskData,
      navigationData,
      projectData,
      templateData,
      tutorialData,
      userData,
      triggerData,
      searchData,
      superAdminData,
      userAnalyticsData,
      superAdminAnalyticsData,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(accountDataSelector, value);
      set(taskDataStateSelector, value);
      set(recurringTaskDataSelector, value);
      set(navigationDataSelector, value);
      set(projectDataSelector, value);
      set(templateDataSelector, value);
      set(tutorialDataSelector, value);
      set(userDataSelector, value);
      set(triggerDataSelector, value);
      set(searchDataSelector, value);
      set(superAdminDataSelector, value);
      set(userAnalyticsFilterSelector, value);
      set(superAdminAnalyticsFilterSelector, value);
      return;
    }
    set(accountDataSelector, value.accountData);
    set(taskDataStateSelector, value.taskData);
    set(recurringTaskDataSelector, value.recurringTaskData);
    set(navigationDataSelector, value.navigationData);
    set(projectDataSelector, value.projectData);
    set(templateDataSelector, value.templateData);
    set(tutorialDataSelector, value.tutorialData);
    set(userDataSelector, value.userData);
    set(triggerDataSelector, value.triggerData);
    set(searchDataSelector, value.searchData);
    set(superAdminDataSelector, value.superAdminData);
    set(userAnalyticsFilterSelector, value.userAnalyticsData);
    set(superAdminAnalyticsFilterSelector, value.superAdminAnalyticsData);
  },
});
