import { atom, DefaultValue, selector, selectorFamily } from 'recoil';

export const accountState = atom<UserFieldType | undefined>({
  key: 'account',
  default: undefined,
});

export const accountSettingsState = atom<AccountSettingsType>({
  key: 'accountSettings',
  default: {
    id: '',
    size: true,
    projectId: true,
    dueDate: true,
    startAndEnd: true,
    filterVisiblity: true,
    projectName: true,
    assigneeName: true,
    defaultPreferredSize: 'M',
    spellChecker: true,
    closeCommentOnAdd: true,
    assignDefaultToSelf: true,
    userAnimationSettings: {
      animation: false,
      bgAnimationHigh: false,
      bgAnimationLow: false,
      bgAnimationMedium: false,
      completeTaskAnimation: false,
      menuNavigationAnimation: false,
      reorderTaskAnimation: false,
      subtaskCreationAnimation: false,
      taskCreationAnimation: false,
    },
    userSoundEffectSetting: {
      bgMusic: false,
      completeTaskSound: false,
      menuNavigationSound: false,
      reorderTaskSound: false,
      soundEffects: false,
      subtaskCreationSound: false,
      taskCreationSound: false,
    },
    notificationSetting: {
      emailNotifications: false,
      inAppNotifications: false,
      newMemberAddedToProject: false,
      projectAtRiskOrOffTrack: false,
      pushNotifications: false,
      rollUpNeedsAttention: false,
      rollUps: false,
      rollupCompleted: false,
      rollupCreated: false,
      rollupFrequency: 'Daily',
      rollupOverDue: false,
      rollupUpcoming: false,
      taskAssigned: false,
      taskCommented: false,
      taskCompleted: false,
      taskModified: false,
      rollupWorkspace: '',
    },
  },
});

export const openCountState = atom({
  key: 'openCount',
  default: 0,
});

export const completedCountState = atom({
  key: 'completedCount',
  default: 0,
});

export const focusPointsState = atom({
  key: 'focusPoints',
  default: 0,
});

export const backlogPointsState = atom({
  key: 'backlogPoints',
  default: 0,
});

export const allUsersState = atom<UserFieldType[] | null>({
  key: 'allUsers',
  default: null,
});

export const allTagsState = atom<TagType[]>({
  key: 'allTags',
  default: [],
});

export const unreadNotificationsState = atom<NotificationObjectType[]>({
  key: 'unreadNotifications',
  default: [],
});
export const currentNotificationUnreadPageState = atom<number>({
  key: 'currentUnreadNotificationPage',
  default: 0,
});

export const isUnreadNotificationLoadingState = atom<boolean>({
  key: 'isUnreadNotificationLoading',
  default: false,
});

export const hasMoreUnreadNotificationState = atom<boolean>({
  key: 'hasMoreUnreadNotification',
  default: false,
});

export const unreadNotificationCountsState = atom<number>({
  key: 'unreadNotificationCounts',
  default: 0,
});
export const allNotificationsState = atom<NotificationObjectType[]>({
  key: 'allNotifications',
  default: [],
});
export const currentAllPageNotificationState = atom<number>({
  key: 'currentAllPageNotification',
  default: 0,
});
export const isAllNotificationLoadingState = atom<boolean>({
  key: 'isAllNotificationLoading',
  default: false,
});

export const hasMoreAllNotificationState = atom<boolean>({
  key: 'hasMoreAllNotification',
  default: false,
});

export const notificationsState = selector<NotificationType>({
  key: 'notifications',
  get: ({ get }) => {
    const unreadNotifications = get(unreadNotificationsState);
    const allNotifications = get(allNotificationsState);
    const currentUnreadPage = get(currentNotificationUnreadPageState);
    const currentAllPage = get(currentAllPageNotificationState);
    const isUnreadLoading = get(isUnreadNotificationLoadingState);
    const isAllLoading = get(isAllNotificationLoadingState);
    const hasMoreUnread = get(hasMoreUnreadNotificationState);
    const hasMoreAll = get(hasMoreAllNotificationState);
    const unreadCounts = get(unreadNotificationCountsState);
    return {
      unreadNotifications,
      allNotifications,
      currentUnreadPage,
      currentAllPage,
      isUnreadLoading,
      isAllLoading,
      hasMoreUnread,
      hasMoreAll,
      unreadCounts,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(unreadNotificationsState, value);
      set(allNotificationsState, value);
      set(currentNotificationUnreadPageState, value);
      set(currentAllPageNotificationState, value);
      set(isUnreadNotificationLoadingState, value);
      set(isAllNotificationLoadingState, value);
      set(hasMoreUnreadNotificationState, value);
      set(hasMoreAllNotificationState, value);
      set(unreadNotificationCountsState, value);
      return;
    }
    set(unreadNotificationsState, value.unreadNotifications);
    set(allNotificationsState, value.allNotifications);
    set(currentNotificationUnreadPageState, value.currentUnreadPage);
    set(currentAllPageNotificationState, value.currentAllPage);
    set(isUnreadNotificationLoadingState, value.isUnreadLoading);
    set(isAllNotificationLoadingState, value.isAllLoading);
    set(hasMoreUnreadNotificationState, value.hasMoreUnread);
    set(hasMoreAllNotificationState, value.hasMoreAll);
    set(unreadNotificationCountsState, value.unreadCounts);
  },
});

export const enableDesktopNotificationState = atom({
  key: 'enableDesktopNotification',
  default: false,
});

export const notificationPayloadItemState = atom<NotificationObjectType | null>({
  key: 'notificationPayloadItem',
  default: null,
});

export const sortByState = atom<SortByType>({
  key: 'sortBy',
  default: 'display',
});

export const sortOrderState = atom<SortOrderType>({
  key: 'sortOrder',
  default: 'asc',
});

export const whatsNewState = atom<WhatsNewDataType>({
  key: 'whatsNew',
  default: {
    id: null,
    title: '',
    headerImage: null,
    date: '',
    features: [],
    gotData: false,
  },
});

export const allTutorialsState = atom<TutorialDBType[]>({
  key: 'allTutorials',
  default: [],
});

export const purchasedThemesState = atom<ThemeInstanceInterface[]>({
  key: 'purchasedThemes',
  default: [],
});

export const allThemesState = atom<ThemeObjectInterface[]>({
  key: 'allThemes',
  default: [],
});
export const invitedMembersState = atom<UserFieldType[]>({
  key: 'invitedMembers',
  default: [],
});

export const invitedMembersPaginatedDataState = atom<UserFieldType[]>({
  key: 'invitedMembersPaginatedData',
  default: [],
});

export const invitedMembersPaginatedHasMoreState = atom<boolean>({
  key: 'invitedMembersPaginatedHasMore',
  default: true,
});

export const invitedMembersPaginatedPageState = atom<number>({
  key: 'invitedMembersPaginatedPage',
  default: 0,
});

export const invitedMembersPaginatedLimitState = atom<number>({
  key: 'invitedMembersPaginatedLimit',
  default: 100,
});

export const invitedMembersPaginatedIsLoadingState = atom<boolean>({
  key: 'invitedMembersPaginatedIsLoading',
  default: false,
});

export const invitedMembersPaginatedSortByState = atom<SortByType>({
  key: 'invitedMembersPaginatedSortBy',
  default: 'display',
});

export const invitedMembersPaginatedSortOrderState = atom<SortOrderType>({
  key: 'invitedMembersPaginatedSortOrder',
  default: 'asc',
});

export const invitedMembersPaginatedActiveState = atom<boolean>({
  key: 'invitedMembersPaginatedActive',
  default: true,
});

export const invitedMembersPaginatedSearchKeyState = atom<string>({
  key: 'invitedMembersPaginatedSearchKey',
  default: '',
});

export const invitedMembersPaginatedAbortControllerState = atom<AbortController>({
  key: 'invitedMembersPaginatedAbortController',
  default: new AbortController(),
});

export const screenSaverState = atom({
  key: 'screenSaver',
  default: false,
});

export const subscriptionState = atom<SubscriptionDataType | null>({
  key: 'subscription',
  default: null,
});

export const invitedMembersPaginatedListState = selector<InvitedMembersPaginatedListType>({
  key: 'invitedMembersPaginatedList',
  get: ({ get }) => {
    const data = get(invitedMembersPaginatedDataState);
    const hasMore = get(invitedMembersPaginatedHasMoreState);
    const page = get(invitedMembersPaginatedPageState);
    const limit = get(invitedMembersPaginatedLimitState);
    const isLoading = get(invitedMembersPaginatedIsLoadingState);
    const sortBy = get(invitedMembersPaginatedSortByState);
    const sortOrder = get(invitedMembersPaginatedSortOrderState);
    const active = get(invitedMembersPaginatedActiveState);
    const searchKey = get(invitedMembersPaginatedSearchKeyState);
    const abortController = get(invitedMembersPaginatedAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      isLoading,
      sortBy,
      sortOrder,
      active,
      searchKey,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(invitedMembersPaginatedDataState, value);
      set(invitedMembersPaginatedHasMoreState, value);
      set(invitedMembersPaginatedPageState, value);
      set(invitedMembersPaginatedLimitState, value);
      set(invitedMembersPaginatedIsLoadingState, value);
      set(invitedMembersPaginatedSortByState, value);
      set(invitedMembersPaginatedSortOrderState, value);
      set(invitedMembersPaginatedActiveState, value);
      set(invitedMembersPaginatedSearchKeyState, value);
      set(invitedMembersPaginatedAbortControllerState, value);
      return;
    }
    set(invitedMembersPaginatedDataState, value.data);
    set(invitedMembersPaginatedHasMoreState, value.hasMore);
    set(invitedMembersPaginatedPageState, value.page);
    set(invitedMembersPaginatedLimitState, value.limit);
    set(invitedMembersPaginatedIsLoadingState, value.isLoading);
    set(invitedMembersPaginatedSortByState, value.sortBy);
    set(invitedMembersPaginatedSortOrderState, value.sortOrder);
    set(invitedMembersPaginatedActiveState, value.active);
    set(invitedMembersPaginatedSearchKeyState, value.searchKey);
    set(invitedMembersPaginatedAbortControllerState, value.abortController);
  },
});

export const accountDataSelector = selector<AccountDataType>({
  key: 'accountData',
  get: ({ get }) => {
    // get values from individual atoms:
    const account = get(accountState);
    const accountSettings = get(accountSettingsState);
    const openCount = get(openCountState);
    const completedCount = get(completedCountState);
    const focusPoints = get(focusPointsState);
    const backlogPoints = get(backlogPointsState);
    const allUsers = get(allUsersState);
    const notifications = get(notificationsState);
    const enableDesktopNotification = get(enableDesktopNotificationState);
    const notificationPayloadItem = get(notificationPayloadItemState);
    const sortBy = get(sortByState);
    const sortOrder = get(sortOrderState);
    const whatsNew = get(whatsNewState);
    const allTutorials = get(allTutorialsState);
    const purchasedThemes = get(purchasedThemesState);
    const allThemes = get(allThemesState);
    const invitedMembers = get(invitedMembersState);
    const invitedMembersPaginatedList = get(invitedMembersPaginatedListState);
    const allTags = get(allTagsState);
    const screenSaver = get(screenSaverState);
    const subscription = get(subscriptionState);
    // then combine into desired shape (object) and return:
    return {
      account,
      accountSettings,
      openCount,
      completedCount,
      focusPoints,
      backlogPoints,
      allUsers,
      notifications,
      enableDesktopNotification,
      notificationPayloadItem,
      sortBy,
      sortOrder,
      whatsNew,
      allTutorials,
      purchasedThemes,
      allThemes,
      invitedMembers,
      invitedMembersPaginatedList,
      allTags,
      screenSaver,
      subscription,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(accountState, value);
      set(accountSettingsState, value);
      set(openCountState, value);
      set(completedCountState, value);
      set(focusPointsState, value);
      set(backlogPointsState, value);
      set(allUsersState, value);
      set(notificationsState, value);
      set(enableDesktopNotificationState, value);
      set(notificationPayloadItemState, value);
      set(sortByState, value);
      set(sortOrderState, value);
      set(whatsNewState, value);
      set(allTutorialsState, value);
      set(purchasedThemesState, value);
      set(allThemesState, value);
      set(invitedMembersState, value);
      set(invitedMembersPaginatedListState, value);
      set(allTagsState, value);
      set(screenSaverState, value);
      set(subscriptionState, value);
      return;
    }
    // otherwise, update individual atoms from new object state:
    set(accountState, value.account);
    set(accountSettingsState, value.accountSettings);
    set(openCountState, value.openCount);
    set(completedCountState, value.completedCount);
    set(focusPointsState, value.focusPoints);
    set(backlogPointsState, value.backlogPoints);
    set(allUsersState, value.allUsers);
    set(notificationsState, value.notifications);
    set(enableDesktopNotificationState, value.enableDesktopNotification);
    set(notificationPayloadItemState, value.notificationPayloadItem);
    set(sortByState, value.sortBy);
    set(sortOrderState, value.sortOrder);
    set(whatsNewState, value.whatsNew);
    set(allTutorialsState, value.allTutorials);
    set(purchasedThemesState, value.purchasedThemes);
    set(allThemesState, value.allThemes);
    set(invitedMembersState, value.invitedMembers);
    set(invitedMembersPaginatedListState, value.invitedMembersPaginatedList);
    set(allTagsState, value.allTags);
    set(screenSaverState, value.screenSaver);
    set(subscriptionState, value.subscription);
  },
});

type CombinedAllUsersParam = {
  userId: string;
  userName: string;
  profileImage?: string | null;
};

export const combinedAllUsersSelector = selectorFamily({
  key: 'combinedAllUsers',
  get:
    ({ userId, userName, profileImage }: CombinedAllUsersParam) =>
    ({ get }) => {
      const allUsers = get(allUsersState);

      if (allUsers) {
        if (profileImage)
          return [{ id: userId, name: userName, profileImage: profileImage }, ...allUsers];
        return [{ id: userId, name: userName }, ...allUsers];
      } else {
        if (profileImage) return [{ id: userId, name: userName, profileImage: profileImage }];
        return [{ id: userId, name: userName }];
      }
    },
});
