import IndividualLeaderboard from 'components/Leaderboard/IndividualLeaderboard';
import WorkspaceLeaderboard from 'components/Leaderboard/WorkspaceLeaderboard';
import LeaderBoardIntro from 'modals/LeaderBoardIntro';

import { Strings } from 'resources';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';
import { accountState } from 'recoil/AccountState';

export default function Leaderboard(_props) {
  const navigate = useNavigate();
  const { page } = useParams();

  const navigationData = useRecoilValue(navigationDataSelector);
  const account = useRecoilValue(accountState);

  const renderMainPage = () => {
    return (
      <div className='leaderboard-main-container'>
        <div className='title-container'>{Strings.global_leaderboard}</div>
        {renderPageControls()}
        {renderSubContainer()}
      </div>
    );
  };

  const renderPageControls = () => {
    return (
      <div className='page-controls-con'>
        <div
          className={`individuals page-controls ${page === 'individuals' ? 'active' : ''}`}
          onClick={() => navigate('/leaderboard/individuals')}
        >
          <svg
            className='case'
            width='18'
            height='17'
            viewBox='0 0 18 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_987_219655)'>
              <path
                d='M12.7429 14.875V13.4583C12.7429 12.7069 12.4444 11.9862 11.913 11.4549C11.3817 10.9235 10.661 10.625 9.90955 10.625H4.24288C3.49143 10.625 2.77076 10.9235 2.23941 11.4549C1.70806 11.9862 1.40955 12.7069 1.40955 13.4583V14.875'
                stroke='#332A7C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M7.07613 7.79167C8.64094 7.79167 9.90947 6.52314 9.90947 4.95833C9.90947 3.39353 8.64094 2.125 7.07613 2.125C5.51132 2.125 4.2428 3.39353 4.2428 4.95833C4.2428 6.52314 5.51132 7.79167 7.07613 7.79167Z'
                stroke='#332A7C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.9928 14.875V13.4583C16.9923 12.8305 16.7834 12.2207 16.3988 11.7245C16.0141 11.2284 15.4756 10.874 14.8678 10.7171'
                stroke='#332A7C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12.0345 2.21707C12.644 2.37312 13.1842 2.72757 13.57 3.22454C13.9557 3.72151 14.1651 4.33274 14.1651 4.96186C14.1651 5.59098 13.9557 6.20221 13.57 6.69918C13.1842 7.19616 12.644 7.55061 12.0345 7.70665'
                stroke='#332A7C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0_987_219655'>
                <rect width='17' height='17' fill='white' transform='translate(0.701172)' />
              </clipPath>
            </defs>
          </svg>
          <span className='page-control-title'>{Strings.individuals}</span>
        </div>
        <div
          className={`workspaces page-controls ${page === 'workspaces' ? 'active' : ''}`}
          onClick={() => navigate('/leaderboard/workspaces')}
        >
          <svg
            className='users'
            width='18'
            height='17'
            viewBox='0 0 18 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.83232 2.125H10.6656C11.4481 2.125 12.0823 2.75926 12.0823 3.54167V4.25H14.9156C15.6981 4.25 16.3323 4.88426 16.3323 5.66667V13.4583C16.3323 14.2407 15.6981 14.875 14.9156 14.875H3.58232C2.79991 14.875 2.16565 14.2407 2.16565 13.4583V5.66667C2.16565 4.88426 2.79991 4.25 3.58232 4.25H6.41565V3.54167C6.41565 2.75926 7.04991 2.125 7.83232 2.125ZM3.58232 5.66667H6.41565H12.0823H14.9156V9.20833H9.95732H8.54065H3.58232V5.66667ZM3.58232 13.4583V10.625H8.54065V11.3333H9.95732V10.625H14.9156V13.4583H3.58232ZM10.6656 3.54167V4.25H7.83232V3.54167H10.6656Z'
              fill='#8E8C8C'
            />
          </svg>

          <span className='page-control-title'>{Strings.workspaces}</span>
        </div>
      </div>
    );
  };

  const renderSubContainer = () => {
    switch (page) {
      case 'individuals':
        return renderIndividual();
      case 'workspaces':
        return renderWorkspace();
      default:
        break;
    }
  };

  const renderIndividual = () => {
    return <IndividualLeaderboard />;
  };

  const renderWorkspace = () => {
    return <WorkspaceLeaderboard />;
  };

  const renderIntroModal = () => {
    return <LeaderBoardIntro />;
  };

  return (
    <div
      className={`leaderboard-screen ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      {!account?.competing && navigationData.showLeaderboardModal ? renderIntroModal() : null}
      {renderMainPage()}
    </div>
  );
}
