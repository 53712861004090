import { useState } from 'preact/compat';
import { downloadFile } from 'services/FileService';
import Spinner from 'components/UI/Spinner';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DownloadLinkPropsType = {
  url: string;
  index: number;
};
export default function DownloadLink(props: DownloadLinkPropsType) {
  const { url, index } = props;

  const [loading, setLoading] = useState(false);

  const openCommentMultimedia = async (e: MouseEvent) => {
    e.preventDefault();

    setLoading(true);
    await downloadFile(url);
    setLoading(false);
  };

  return (
    <div id={`comment-attachments-${index}`} className='comment-attachments' key={index}>
      <a
        id={`comment-attach-btn-${index}`}
        className='comment-attach-btn'
        href={url}
        onClick={(e) => openCommentMultimedia(e)}
      >
        {loading ? (
          <Spinner />
        ) : (
          <FontAwesomeIcon
            id={`comment-attach-btn-link-icon-${index}`}
            className='link-icon'
            icon={faLink}
          />
        )}
      </a>
    </div>
  );
}
