import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import { setItem, getItem, removeAll } from 'utils/storage';
import apiConfig from 'config/api';
import { parseUserObject, createUserInitials, extractUserNameFromUserField } from 'utils/user';
import { log } from 'utils';
import { accountState, notificationsState } from 'recoil/AccountState';
import { taskDataStateSelector } from 'recoil/TaskState';
import { projectDataSelector } from 'recoil/ProjectState';
import { templateDataSelector } from 'recoil/TemplateState';
import { userDataSelector } from 'recoil/UserState';
import {
  goToUrlState,
  showCreateFolderModalState,
  showCreateNewProjectModalState,
  showCreateProjectTemplateModalState,
  showInviteMembersModalState,
  showNotificationPopupState,
} from 'recoil/NavigationState';
import { recurringTaskDataSelector } from 'recoil/RecurringTaskState';
import { setRecoil, resetRecoil } from 'recoil-nexus';
import { superAdminDataSelector } from 'recoil/SuperAdminState';
import { resetCompletedTasks } from 'recoil/TaskState/update';
import { searchDataSelector } from 'recoil/SearchState';
import { userAnalyticsFilterSelector } from 'recoil/UserAnalyticsState';
import { superAdminAnalyticsFilterSelector } from 'recoil/SuperAdminAnalyticsState';

const USER_KEY = 'magic_task_user';

/*
 * Is user logged in
 *
 * returns Object {status, token, user, role, workspace}
 */
export function isUserLoggedIn() {
  const { data, error } = getItem(USER_KEY);

  if (error || !data) {
    const user: UserObjectType = {
      status: false,
    };
    return user;
  }

  return parseUserData(data);
}

/*
 * logout user
 *
 * returns boolean
 */
export function logOutUser() {
  removeAll();
  window.location.reload();
  return true;
}

/*
 * Parse user data
 * data Object
 *
 * returns Object {status, token, user, role, workspace, created, signupDate}
 */
function parseUserData(data: string) {
  let userObj: UserObjectType = {
    status: false,
  };
  try {
    const { token, user, role, workspace, created, signupDate } = JSON.parse(data);
    userObj = {
      status: true,
      token,
      user,
      role,
      workspace,
      created,
      signupDate,
    };
    return userObj;
  } catch (error) {
    return userObj;
  }
}
/*
 * Update User Integration ID
 *
 * returns Object {data, error, status}
 */
export async function updateUserIntegrationId(id: string) {
  const headers = {
    'Content-Type': 'text/plain',
  };

  try {
    const response = await appClient.put(apiConfig.user.update_integration_id, id, { headers });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Login user
 *
 * returns Object {error}
 */
export function loginUserInApp(user: UserObjectType) {
  const { error } = setItem(USER_KEY, JSON.stringify(user));

  return { error };
}

/*
 * User Login
 * payload Object {email, password}
 *
 * returns Object {data, error, status}
 */
export async function userLogin(payload) {
  try {
    const response = await appClient.post(apiConfig.auth.login, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * User Login
 *
 * returns Object {data, error, status}
 */
export async function chartLogin() {
  try {
    const response = await appClient.get(apiConfig.auth.chart_login);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * forgot password
 * email string
 *
 * returns Object {data, error, status}
 */
export async function forgotPassword(payload) {
  try {
    const response = await appClient.post(apiConfig.auth.forgot_password, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get user id
 *
 * returns Object
 */
export const getUserId = () => {
  const { status, user } = isUserLoggedIn();

  if (status && user && user.id) return user.id;
  else {
    log('getUserId');
    removeAll();
    return '';
  }
};

/*
 * Get username
 *
 * returns string
 */
export const getUserName = () => {
  const { status, user } = isUserLoggedIn();

  if (status && user && user.id) return extractUserNameFromUserField(user);
  else {
    logOutUser();
    return '';
  }
};

/*
 * Is current logged in user an admin
 *
 * returns boolean
 */
export const isAdmin = () => {
  const { status, role } = isUserLoggedIn();
  if (!status && role) {
    log('isAdmin');
    return logOutUser();
  }

  // role = role ? role.toLowerCase() : '';

  if (role === 'admin' || role === 'superadmin') return true;

  return false;
};

export const isManager = () => {
  const { status, role } = isUserLoggedIn();
  if (!status && role) {
    log('isManager');
    return logOutUser();
  }

  // role = role ? role.toLowerCase() : '';

  if (role === 'manager') return true;

  return false;
};

/*
 * Get user session
 *
 * returns Object {data, error, status}
 */
export async function getUserSession(token: string) {
  const headers = { token, platform: 'web' };

  try {
    const response = await appClient.get(apiConfig.user.get_user_session, {
      headers,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Refetch user session
 *
 * returns Object {data, error, status}
 */
export async function reFetchUserSession(authToken?: string) {
  const { status, token } = isUserLoggedIn();

  if (!status || !token) return;

  const { data, success } = await getUserSession(authToken ? authToken : token);

  if (success) {
    const userT = parseUserObject(data);
    loginUserInApp(userT);
    const loggedInUser = isUserLoggedIn();
    const { status, user, role } = loggedInUser;
    if (user && !user.profileImage) {
      user.profileImage = createUserInitials(user);
    }

    if (user && role) user.role = role;

    if (!status) return;
    setRecoil(accountState, user);
  }
}

/**
 * Get currect selected user workspace id
 *
 * returns Object
 */
export const getUserWorkspaceId = () => {
  const { status, user, workspace } = isUserLoggedIn();

  if (status && user && user.id && workspace && workspace.id) return workspace.id;
  else {
    removeAll();
    return '';
  }
};

/*
 * Get all users with task count
 *
 * returns Object {data, error, status}
 */
export async function getAllUsersWithCount() {
  try {
    const response = await appClient.get(`${apiConfig.user.get_users_with_count}/all`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Get currect selected user workspace object
 *
 * returns Object{id, name, image}
 */
export const getUserWorkspace = () => {
  const { status, user, workspace } = isUserLoggedIn();

  if (status && user && user.id && workspace) return workspace;
  else {
    log('getUserWorkspace');
    return logOutUser();
  }
};

/**
 * Upload workspace name
 * returns Object {data, error, status}
 */
export async function updateWorkspaceName(workspaceId: string, workspaceName: string) {
  const headers = {
    'Content-Type': 'text/plain',
  };

  try {
    const response = await appClient.put(
      `${apiConfig.user.rename_workspace}/${workspaceId}`,
      workspaceName,
      { headers },
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Get currect selected user workspaces array
 *
 * returns Array[{id, name, image}]
 */
export const getUserWorkspaces = () => {
  const { status, user } = isUserLoggedIn();

  if (status && user && user.id && user.workspaces) return user.workspaces ? user.workspaces : [];
  else {
    logOutUser();
    return [];
  }
};

/*
 * Get user email
 *
 * returns String
 */
export const getUserEmail = () => {
  const { status, user } = isUserLoggedIn();

  if (status && user && user.id) {
    return user.email ? user.email : '';
  } else {
    log('getUserEmail');
    logOutUser();
    return '';
  }
};

/**
 * Upload workspace image
 * returns Object {data, error, status}
 */
export async function uploadWorkspaceImage(payload, token?: string) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  if (token) {
    headers['token'] = token;
  }
  try {
    const response = await appClient.post(apiConfig.user.upload_workspace_image, payload, {
      headers,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Switch Workspace logged in user
 *
 * returns Object {data, error, status}
 */
export async function switchWorkspace(workspaceId: string) {
  const url = `${apiConfig.user.switch_user_workspace}/${workspaceId}`;
  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Is current logged in user an super admin
 *
 * returns Boolean
 */
export const isSuperAdmin = () => {
  const { status, role } = isUserLoggedIn();
  if (!status && role) {
    log('isSuperAdmin');
    return logOutUser();
  }

  // role = role ? role.toLowerCase() : '';

  if (role === 'superadmin') return true;

  return false;
};

/*
 * Set user workspace
 *
 * returns Object {data, error, status}
 */
export function setUserWorkspace(newWorkspace: WorkspaceType, newToken: string) {
  const loggedInUser = isUserLoggedIn();

  if (!loggedInUser.status) return;

  let { token, workspace } = loggedInUser;
  const { role, user, created, signupDate, status } = loggedInUser;
  if (!user) return;
  user.workspace = newWorkspace;
  workspace = newWorkspace;
  token = newToken;

  const newUser: UserObjectType = {
    status,
    role,
    token,
    user,
    workspace,
    signupDate,
    created,
  };

  loginUserInApp(newUser);
}

/*
 * Get user session
 *
 * returns Object {data, error, status}
 */
export async function resetRecoilState() {
  resetRecoil(notificationsState);
  resetRecoil(taskDataStateSelector);
  resetRecoil(userDataSelector);
  resetRecoil(projectDataSelector);
  resetRecoil(templateDataSelector);
  resetRecoil(recurringTaskDataSelector);
  resetRecoil(templateDataSelector);
  resetRecoil(searchDataSelector);
  resetRecoil(superAdminDataSelector);
  resetRecoil(userAnalyticsFilterSelector);
  resetRecoil(superAdminAnalyticsFilterSelector);
  setRecoil(showCreateFolderModalState, false);
  setRecoil(showCreateNewProjectModalState, false);
  setRecoil(showInviteMembersModalState, false);
  setRecoil(showCreateProjectTemplateModalState, false);
  setRecoil(showNotificationPopupState, false);
  resetCompletedTasks(null, null);
}

/**
 * Update User Workspace
 */
export async function updateUserWorkspace(workspace: WorkspaceType, token: string) {
  setUserWorkspace(workspace, token);
  resetRecoilState();

  setTimeout(() => {
    setRecoil(goToUrlState, '/');
  }, 200);
}

/*
 * Invite all given team members email
 *
 * returns Object {data, error, status}
 */
export async function inviteEmail(payload: InviteEmailPayloadType, token: string | null = null) {
  const headers = {};
  if (token) {
    headers['token'] = token;
  }
  try {
    const response = await appClient.post(apiConfig.user.invite_mail, payload, {
      headers,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Create User
 *
 * returns Object {data, error, status}
 */
export async function createUser(payload: UserCreationPayloadType) {
  try {
    const response = await appClient.post(apiConfig.user.create_user, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get logged in user counters
 *
 * returns Object {data, error, status}
 */
export async function getUserCounters() {
  const url = `${apiConfig.user.get_users_with_count}/${getUserId()}`;
  try {
    const response = await appClient.get(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Leave workspace
 * returns Object {data, error, status}
 */
export async function leaveWorkspace(workspaceId: string) {
  // /api/v1/workspace/615e738eb920a92ae8e9ccfb/leave/user/60996f56238c9d39d4b531d6
  try {
    const response = await appClient.put(
      `${apiConfig.user.leave_workspace}/${workspaceId}/leave/user/${getUserId()}`,
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function deleteWorkspace(workspaceId: string) {
  try {
    const response = await appClient.delete(`${apiConfig.user.delete_workspace}/${workspaceId}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function getAllWorkspaceUsers() {
  try {
    const response = await appClient.get(apiConfig.user.get_invited_members);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function createWorkspace(payload) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.user.add_workspace_and_image, payload, {
      headers,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function updateInviteEmailStatus(
  userId: string,
  inviteStatus: string,
  workspaceId: string,
) {
  try {
    const response = await appClient.put(
      apiConfig.user.update_invite_status + '/' + userId + '/' + inviteStatus + '/' + workspaceId,
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function uploadImage(payload) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.user.upload_image, payload, { headers });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export const getUserCreationDate = () => {
  const { status, signupDate } = isUserLoggedIn();

  if (status && signupDate) return signupDate;
  else {
    log('getUserCreationDate');
    logOutUser();
    return null;
  }
};

/**
 * Update Workspace Sequences
 * returns Object {data, error, status}
 **/
export async function updateWorkspaceSequence(payload) {
  try {
    const response = await appClient.put(
      `${apiConfig.user.update_workspace_sequences}/${getUserId()}/update-workspace-sequence`,
      payload,
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function getUserAccountSettings(userId: string): Promise<AsyncResponseType> {
  try {
    const response = await appClient.get(`${apiConfig.user.get_user_settings}/${userId}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get user tutorials array
 * returns Object {data, error, status}
 */
export async function getUserTutorials() {
  try {
    const response = await appClient.get(apiConfig.tutorial.get_tutorial);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
* reset password
* {
  "newPassword": "",
  "passwordResetToken": "",
  "userName": ""
}
*
* returns Object {data, error, status}
*/
export async function resetPassword(payload): Promise<AsyncResponseType> {
  try {
    const response = await appClient.post(apiConfig.auth.reset_password, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update User Info
 *
 * returns Object {data, error, status}
 */
export async function updateUser(payload: any): Promise<AsyncResponseType> {
  try {
    const response = await appClient.put(apiConfig.user.create_user, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Activate deactivate user
 * userId Integer
 * active Boolean
 * cancelRequest AbortController
 *
 * returns Object {data, error, status}
 */
export async function activateDeactivateUser(
  userId: string,
  active: boolean,
  workspaceId: string,
  abortController: AbortController,
) {
  const url = `${apiConfig.user.activate_deactivate_user}/${userId}/workspace/${workspaceId}/activate/${active}`;

  try {
    const response = await appClient.put(
      url,
      {},
      {
        signal: abortController.signal,
      },
    );
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error instanceof Error && error.name !== 'CancelledError') {
      return hasError(error);
    }
    return { success: false };
  }
}

/*
 * Update user role
 * userId Integer
 * role String
 * cancelRequest axios.CancelToken
 *
 * returns Object {data, error, status}
 */
export async function updateUserRole(
  userId: string,
  role: UserRoleType,
  abortController: AbortController,
) {
  const url = `${apiConfig.user.update_user_role}/${userId}/role/${role}`;

  try {
    const response = await appClient.put(
      url,
      {},
      {
        signal: abortController.signal,
      },
    );
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error instanceof Error && error.name !== 'CancelledError') {
      return hasError(error);
    } else {
      return { success: false };
    }
  }
}

/*
 * Get workspace users
 * payload Object {pagenumber, size}
 *
 * returns Object {data, error, status}
 */
export async function getAllWorkspaceUsersList(payload: any) {
  try {
    const response = await appClient.post(apiConfig.user.get_invited_members, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Change owner of workspace
 * returns Object {data, error, status}
 */

export async function changeOwner(payload: any) {
  try {
    const response = await appClient.put(apiConfig.user.changeOwner, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Save user Account Settings
 * payload object
 *
 * returns Object {data, error, status}
 */
export async function saveUserAccountSettings(payload: any) {
  try {
    const response = await appClient.put(apiConfig.user.save_user_settings, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Get owned workspace list
 *
 * returns Object {data, error, status}
 */
export async function getOwnedWorkspaceList() {
  try {
    const response = await appClient.get(`${apiConfig.user.list_owned_workspace}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Delete user account
 *
 * returns Object {data, error, status}
 */
export async function deleteUserAccount() {
  const url = `${apiConfig.user.accountDelete}/${getUserId()}`;

  try {
    const response = await appClient.delete(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * change password
 *
 * returns Object {data, error, status}
 */
export async function changePassword(payload: any): Promise<AsyncResponseType> {
  try {
    const response = await appClient.post(apiConfig.auth.change_password, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * skip the tutorial
 * returns Object {data, error, status}
 */

export async function skipTutorial() {
  try {
    const response = await appClient.put(apiConfig.tutorial.skip_tutorial + '/status/' + false);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function updateTutorialStep(
  stepCount: number,
  id: string,
): Promise<AsyncResponseType> {
  try {
    const response = await appClient.put(
      apiConfig.tutorial.update_tutorial + '/' + id + '/step/' + stepCount,
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function updateTutorialCompleteStatus(
  status: boolean,
  id: string,
): Promise<AsyncResponseType> {
  try {
    const response = await appClient.put(
      apiConfig.tutorial.complete_tutorial + '/' + id + '/complete/' + status,
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 *  api to submit user data to mailchimp
 * @param {"firstname": "imagin-test", "lastname": "7", "email": "imagin-test-7@mail.com"} payload
 */
export async function uploadMailchimpData(payload: any) {
  try {
    const response = await appClient.post(apiConfig.mailchimp.upload_user, payload);
    log('response mailchimp api', response);
  } catch (error) {
    log('error uploadMailchimpData', error);
  }
}
