// @ts-nocheck
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AlertTemplate from 'utils/AlertTemplate';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import Login from 'screens/Auth/Login/index';
import BetaPlan from 'screens/Auth/BetaPlan/index';
import CreateAccount from 'screens/Auth/CreateAccount';
import WorkspaceCreation from 'screens/Auth/WorkspaceCreation';
import ForgotPassword from 'screens/Auth/ForgotPassword/index';
import ResetPassword from 'screens/Auth/ResetPassword';
import Tasks from 'screens/Tasks/index';
import Admin from 'screens/Admin';
import CompletedTasks from 'screens/CompletedTasks';
import PageNotFound from 'screens/PageNotFound/index';
import Settings from 'screens/Account/Settings';
import SearchResults from 'screens/SearchResults';
import TutorialCompletedTasks from 'screens/TutorialCompletedTasks';
import TutorialTasks from 'screens/TutorialTasks';
import ChangePassword from 'screens/ChangePassword';
import Leaderboard from 'screens/Leaderboard';
import TutorialAdmin from 'screens/TutorialAdmin';

// Super admin screens
import CreateTheme from 'screens/SuperAdmin/CreateTheme';
import CreateThemeLevelBuilder from 'screens/SuperAdmin/CreateThemeLevelBuilder';
import WhatsNew from 'screens/SuperAdmin/WhatsNew';
import ManageWorkspaces from 'screens/ManageWorkspaces';
import WorkspaceUsers from 'screens/WorkspaceUsers';
import ThemeList from 'screens/SuperAdmin/ThemesList';

// Markeplace Screens
import PaymentCompletion from 'screens/PaymentCompletion';

import AuthLayout from 'layouts/AuthLayout/index';
import AuthLayoutWithoutAuth from 'layouts/AuthLayout/without-auth';
import UserLayout from 'layouts/UserLayout/index';
import SuperAdminLayout from 'layouts/SuperAdminLayout';
import TutorialLayout from 'layouts/TutorialLayout';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
// import { createBrowserRouter } from 'react-router-dom';
import DebugObserver from 'utils/DebugObserver';
import PersonalInfo from 'screens/Auth/PersonalInfo';
import SuperAdminAnalytics from 'screens/SuperAdmin/SuperAdminAnalytics';

// Web Task Form
import TaskForm from 'screens/TaskForm';

const alertOptions = {
  timeout: 3000,
  position: positions.BOTTOM_RIGHT,
  transition: transitions.FADE,
};

// export const router = createBrowserRouter([
//   {path: "/",
//   element:  <UserLayout path='' pageTitle='Beta Plan' stateType={null} />,
//   errorElement: <PageNotFound />,
//     children: [{
//       path: "",
//       element: <BetaPlan/>
//     }]
//   },
//   {
//     path: "/login",
//     element:  <AuthLayoutWithoutAuth  component={Login} path="" pageTitle='Login'/>,
//     children: [{
//       path: "",
//       element: <Login/>
//     }
//     ]
//   },
//   {
//     path: "/sign-up",
//     element:  <AuthLayout path='' component={BetaPlan} pageTitle='Beta Plan' />,
//     children: [{
//       path: "",
//       element: <BetaPlan/>
//     }
//     ]
//   }
// ])

export default function Routing() {
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <RecoilRoot>
        <DebugObserver />
        <RecoilNexus />
        <Router>
          <Routes>
            <Route path='/form' element={<TaskForm />} />
            <Route path='/login' element={<AuthLayoutWithoutAuth pageTitle={'Login'} />}>
              <Route index element={<Login />} />
            </Route>
            <Route path='/sign-up' element={<AuthLayout pageTitle={'Welcome'} />}>
              <Route index element={<BetaPlan />} />
            </Route>
            <Route
              path='/create-account'
              element={<AuthLayout exact path='/create-account' pageTitle={'Create Account'} />}
            >
              <Route index element={<CreateAccount />} />
            </Route>
            <Route
              path='/workspace-creation'
              element={
                <AuthLayout exact path='/workspace-creation' pageTitle='Workspace Creation' />
              }
            >
              <Route index element={<WorkspaceCreation />} />
            </Route>
            <Route path='/forgot-password' element={<AuthLayout pageTitle={'Forgot Password'} />}>
              <Route index element={<ForgotPassword />} />
            </Route>
            <Route
              path='/reset-password'
              element={<AuthLayout exact path='/reset-password' pageTitle='Reset Password' />}
            >
              <Route index element={<ResetPassword />} />
            </Route>
            <Route
              path='/personal-info/:userId/email/:emailId/company/:workspaceId/workspace/:workspaceName'
              element={
                <AuthLayout
                  exact
                  path='/personal-info/:userId/email/:emailId/company/:workspaceId/workspace/:workspaceName'
                  pageTitle='Personal Information'
                />
              }
            >
              <Route index element={<PersonalInfo />} />
            </Route>
            <Route path='/' element={<UserLayout pageTitle={'Home'} stateType={null} path='/' />}>
              <Route index element={<Tasks />} />
            </Route>
            <Route
              path='/recurring-tasks'
              element={
                <UserLayout
                  exact
                  path='/recurring-tasks'
                  pageTitle='Recurring Tasks'
                  stateType='RECURRING'
                />
              }
            >
              <Route index element={<Tasks />} />
            </Route>
            <Route
              path='/completed'
              element={<UserLayout exact path='/completed' pageTitle='Completed tasks' />}
            >
              <Route index element={<CompletedTasks />} />
            </Route>
            <Route
              path='/user/:userId'
              element={<UserLayout exact path='/user/:userId' stateType='INDIVIDUAL' />}
            >
              <Route index element={<Tasks />} />
            </Route>
            <Route
              path='/user/:userId/completed'
              element={<UserLayout exact path='/user/:userId/completed' stateType='INDIVIDUAL' />}
            >
              <Route index element={<CompletedTasks />} />
            </Route>
            <Route
              path='/project/:projectId'
              element={<UserLayout stateType={'PROJECT'} path='/project/:projectId' />}
            >
              <Route index element={<Tasks />} />
            </Route>
            <Route
              path='/project/:projectId/completed'
              element={
                <UserLayout exact path='/project/:projectId/completed' stateType='PROJECT' />
              }
            >
              <Route index element={<CompletedTasks />} />
            </Route>
            <Route
              path='/project-templates/:templateId'
              element={
                <UserLayout exact path='/project-templates/:templateId' stateType='TEMPLATE' />
              }
            >
              <Route index element={<Tasks />} />
            </Route>
            <Route
              path='/admin/:page'
              element={<UserLayout exact path='/admin/:page' pageTitle='Admin' />}
            >
              <Route index element={<Admin />} />
            </Route>
            <Route
              path='/leaderboard/:page'
              element={<UserLayout exact path='/leaderboard/:page' pageTitle='Leaderboard' />}
            >
              <Route index element={<Leaderboard />} />
            </Route>
            <Route
              path='/settings'
              element={<UserLayout exact path='/settings' pageTitle='My Settings' />}
            >
              <Route index element={<Settings />} />
            </Route>
            <Route
              path='/search/:searchText'
              element={<UserLayout exact path='/search/:searchText' pageTitle='Search Results' />}
            >
              <Route index element={<SearchResults />} />
            </Route>
            <Route
              path='/payment-completion/:themeId'
              element={
                <UserLayout exact path='/payment-completion/:themeId' pageTitle='Payment Success' />
              }
            >
              <Route index element={<PaymentCompletion />} />
            </Route>
            <Route
              path='/change-password'
              element={<UserLayout exact path='/change-password' pageTitle='Change Password' />}
            >
              <Route index element={<ChangePassword />} />
            </Route>
            <Route
              path='/super-admin/themes'
              element={<SuperAdminLayout exact path='/super-admin/themes' pageTitle='Themes' />}
            >
              <Route index element={<ThemeList />} />
            </Route>
            <Route
              path='/super-admin/create-theme'
              element={
                <SuperAdminLayout exact path='/super-admin/create-theme' pageTitle='Create Theme' />
              }
            >
              <Route index element={<CreateTheme />} />
            </Route>
            <Route
              path='/super-admin/create-theme-level'
              element={
                <SuperAdminLayout
                  exact
                  path='/super-admin/create-theme-level'
                  pageTitle='Create New Theme - Level Builder'
                />
              }
            >
              <Route index element={<CreateThemeLevelBuilder />} />
            </Route>
            <Route
              path='/super-admin/whats-new'
              element={
                <SuperAdminLayout exact path='/super-admin/whats-new' pageTitle='Whats New' />
              }
            >
              <Route index element={<WhatsNew />} />
            </Route>
            <Route
              path='/super-admin/workspaces'
              element={
                <SuperAdminLayout
                  exact
                  path='/super-admin/workspaces'
                  pageTitle='Manage Workspaces'
                />
              }
            >
              <Route index element={<ManageWorkspaces />} />
            </Route>
            <Route
              path='/super-admin/analytics'
              element={
                <SuperAdminLayout exact path='/super-admin/analytics' pageTitle='Analytics' />
              }
            >
              <Route index element={<SuperAdminAnalytics />} />
            </Route>
            <Route
              path='/super-admin/workspace/:workspaceName/:workspaceId'
              element={
                <SuperAdminLayout exact path='/super-admin/workspace/:workspaceName/:workspaceId' />
              }
            >
              <Route index element={<WorkspaceUsers />} />
            </Route>
            <Route
              path='/user-tutorial'
              element={<TutorialLayout exact path='/user-tutorial' stateType={'TUTORIAL'} />}
            >
              <Route index element={<TutorialTasks />} />
            </Route>
            <Route
              path='/completed/user-tutorial'
              element={
                <TutorialLayout exact path='/completed/user-tutorial' stateType={'TUTORIAL'} />
              }
            >
              <Route index element={<TutorialCompletedTasks />} />
            </Route>
            <Route
              path='/:projectId/user-tutorial'
              element={
                <TutorialLayout exact path='/:projectId/user-tutorial' stateType={'TUTORIAL'} />
              }
            >
              <Route index element={<TutorialTasks />} />
            </Route>
            <Route
              path='/admin/:page/user-tutorial'
              element={<TutorialLayout exact path='/admin/:page/user-tutorial' pageTitle='Admin' />}
            >
              <Route index element={<TutorialAdmin />} />
            </Route>
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </AlertProvider>
  );
}
