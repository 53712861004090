import { Fragment, useCallback, useState, useRef } from 'preact/compat';
import { faChevronDown, faChevronUp, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { log } from 'utils';
import {
  isTutorialCompletedScreen,
  formatDateForView,
  isTutorialProjectCompletedScreen,
} from 'utils/task';
import { Strings } from 'resources';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getUserId } from 'services/AuthService';
import {
  Menu,
  MenuItem,
  MenuButton,
  FocusableItem,
  ControlledMenu,
  useMenuState,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { accountDataSelector, allTagsState } from 'recoil/AccountState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  navigationDataSelector,
  showEditProjectModalState,
  showUserProfileModalState,
} from 'recoil/NavigationState';
import {
  allTutorialProjectsState,
  tutorialCurrentProjectIdState,
  tutorialDataSelector,
} from 'recoil/TutorialState';
import { allUserTagsState, currentUserIdState } from 'recoil/UserState';
import { allSizes } from 'utils/size';
import { updateFlag } from 'recoil/TutorialState/update';
import { getCompletedTasks, updateTaskFilter } from 'recoil/TaskState/update';
import InfoIcon from 'assets/images/info.png';

const dateFilterData = [
  { id: 'today', name: 'Today' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'currentMonth', name: 'Current Month' },
  { id: 'lastMonth', name: 'Last Month' },
  { id: 'yearToDate', name: 'Year to Date' },
  { id: 'previousYear', name: 'Previous Year' },
];

export default function TopTitleBar(props: TopTitleBarPropsType) {
  const { stateType, readOnly, idName } = props;
  const loggedInUserId = getUserId();

  const accountData = useRecoilValue(accountDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const tutorialData = useRecoilValue(tutorialDataSelector);

  const allAccountUserTags = useRecoilValue(allTagsState);
  const allUserTags = useRecoilValue(allUserTagsState);
  const allProjects = useRecoilValue(allTutorialProjectsState);
  const currentProjectId = useRecoilValue(tutorialCurrentProjectIdState);
  const currentUserId = useRecoilValue(currentUserIdState);
  const navigate = useNavigate();

  const skipFilterClick = useRef(false);
  let isCustomDateTapped = false;

  const { closeMenu, toggleMenu, ...menuProps } = useMenuState();

  const getSizeDescriptionForId = (id: string) => {
    const index = allSizes.findIndex((size) => size.id === id);

    if (index === -1) return '';
    else return allSizes[index].desc;
  };

  /*
   * Account settings
   *
   * returns Object
   */
  const accountSettings = accountData.accountSettings;

  const getTagList = (tagList) => {
    let index = -1;
    if (currentProjectId) {
      index = tagList.findIndex((data) => data.projectId === currentProjectId);
      return index > -1 ? tagList[index].tags : [];
    } else {
      return tagList.length > 0 ? tagList[tagList.length - 1].tags : [];
    }
  };

  const getAllTags = () => {
    let data: TagObjectType[] = [];

    if (stateType === 'INDIVIDUAL') {
      data = getTagList(allUserTags);
    } else {
      data = getTagList(allAccountUserTags);
    }
    return data;
  };

  const sizeFilter = tutorialData.taskFilters?.sizeFilter;

  const dateFilter = tutorialData.taskFilters?.dateFilter;

  const tagFilter = tutorialData?.taskFilters?.tagFilter;

  const assigneeFilter = tutorialData.taskFilters?.assigneeFilter;

  const projectFilter = tutorialData.taskFilters?.projectFilter;

  const completedTasksFilter = tutorialData.COMPLETED?.filter;

  const getProjectIndex = () => {
    if (!currentProjectId) return -1;

    return tutorialData.allProjects.findIndex((item) => item.id === currentProjectId);
  };

  const getProjectUsers = () => {
    const index = getProjectIndex();

    if (index === -1) return [];

    return tutorialData.allProjects[index].users;
  };

  const openMyCompletedTasks = () => {
    updateFlag('showCompletedPage', true);
    navigate('/completed/user-tutorial');
  };

  const openMyTasks = () => {
    navigate(`/`);
  };

  const setUserProfileModalStatus = useSetRecoilState(showUserProfileModalState);
  const openProfileModal = () => {
    setUserProfileModalStatus(true);
  };

  const getProjectOpenCount = () => {
    return tutorialData.FOCUS.data?.length ?? 0;
  };

  const getProjectCompletedCount = () => {
    return tutorialData.BACKLOG.data?.length ?? 0;
  };

  const renderLeftTitle = () => {
    const { currentRoute } = navigationData;
    if (tutorialData.currentProjectId) {
      return (
        <Fragment>
          <div className='profile-name-con'>
            <h3 id='project-title' className='big-title'>
              {getProjectName()}
            </h3>
            {renderOverlay()}
          </div>
          <div className='status-title-con'>
            <span id='project-screen-open-tasks-title' className='status-title'>
              {Strings.open}{' '}
              <span className='status-count open' id='project-screen-open-tasks-count'>
                {getProjectOpenCount()}
              </span>
            </span>
            {renderOverlay()}
          </div>
          <div className='status-title-con'>
            <span
              id='project-screen-completed-tasks-title'
              className={`status-title ${
                isTutorialProjectCompletedScreen(currentRoute) ? 'selected' : ''
              }`}
            >
              {Strings.completed}{' '}
              <span id='project-screen-completed-tasks-count' className='status-count completed'>
                {getProjectCompletedCount()}
              </span>
            </span>
            {renderOverlay()}
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className='profile-name-con'>
          <h3 className='big-title profile' id='profile-title' onClick={openProfileModal}>
            {accountData?.account?.name || ''}
          </h3>
          {renderOverlay()}
        </div>
        <div className='status-title-con'>
          <span id='open-tasks-title' onClick={() => openMyTasks()} className='status-title'>
            {Strings.open}{' '}
            <span id='open-tasks-count' className='status-count open'>
              {`${
                tutorialData && tutorialData.FOCUS.data && tutorialData.BACKLOG.data
                  ? tutorialData.FOCUS.data.length + tutorialData.BACKLOG.data.length
                  : 0
              }`}
            </span>
          </span>
          {renderOverlay()}
        </div>
        <div className='status-title-con'>
          <span
            id='completed-tasks-title'
            onClick={() => openMyCompletedTasks()}
            className={`status-title ${isTutorialCompletedScreen(currentRoute) ? 'selected' : ''}`}
          >
            {Strings.completed}{' '}
            <span id='completed-tasks-count' className='status-count completed'>
              {`${tutorialData ? tutorialData.COMPLETED.data.length : 0}`}
            </span>
          </span>
          {renderOverlay(tutorialData.flags.completeTaskCompletion ? 'hide-overlay' : '')}
        </div>
      </Fragment>
    );
  };

  const updateFilter = (name: TaskFiltersType, value: string | Array<string> | null) => {
    let id;

    if (name === 'assigneeFilter') {
      if (tutorialData.currentProjectId) {
        id = currentProjectId;
      }
      let assigneeList: string[] | null = null;
      if (value !== null && typeof value === 'string') {
        assigneeList = [value];
        if (assigneeFilter && assigneeFilter.length > 0) {
          const index = assigneeFilter.findIndex((item) => item === value);
          if (index === -1) {
            assigneeList = [...assigneeFilter, ...assigneeList];
          } else {
            assigneeList = assigneeFilter.filter((item) => item !== value);
            if (assigneeList.length === 0) {
              assigneeList = null;
            }
          }
        }
      }
      updateTaskFilter(name, assigneeList, stateType, id);
      return;
    } else if (name === 'tagFilter') {
      if (tutorialData.currentProjectId) id = currentProjectId;
      else if (stateType === 'INDIVIDUAL') id = currentUserId;
      let tagList: string[] | null = null;
      if (value !== null && typeof value === 'string') {
        tagList = [value];
        if (tagFilter && tagFilter.length > 0) {
          const index = tagFilter.findIndex((item) => item === value);
          if (index === -1) {
            tagList = [...tagFilter, ...tagList];
          } else {
            tagList = tagFilter.filter((item) => item !== value);
            if (tagList.length === 0) {
              tagList = null;
            }
          }
        }
      }
      updateTaskFilter(name, tagList, stateType, id);
      return;
    } else if (name === 'projectFilter') {
      let projectList: string[] | null = null;
      if (value !== null && typeof value === 'string') {
        projectList = [value];
        if (projectFilter && projectFilter.length > 0) {
          const index = projectFilter.findIndex((item) => item === value);
          if (index === -1) {
            projectList = [...projectFilter, ...projectList];
          } else {
            projectList = projectFilter.filter((item) => item !== value);
            if (projectList.length === 0) {
              projectList = null;
            }
          }
        }
      }
      updateTaskFilter(name, projectList, stateType, id);
      return;
    }

    updateTaskFilter(name, value, stateType, id);
  };

  const dateFilterSelected = (date: any) => {
    closeMenu();
    updateTaskFilter('dateFilter', formatDateForView(date), stateType, null);
  };

  const renderSizeFilter = () => {
    return (
      <div
        id='size-filter-container'
        className='item-container size-container filter-control'
        key='size-filter'
      >
        <Menu
          style={{ width: '50px' }}
          id='size-menu'
          className='item-menu'
          menuButton={
            <MenuButton
              id='size-filter-menu-button'
              className={`item-btn size-btn size-${sizeFilter}`}
            >
              <span className='item-selected'>
                {sizeFilter ? getSizeDescriptionForId(sizeFilter) : 'Filter by Size'}
              </span>
              <FontAwesomeIcon
                id='size-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
              <FontAwesomeIcon
                id='size-filter-up-icon'
                className='drop-icon up'
                icon={faChevronUp}
              />
            </MenuButton>
          }
        >
          <MenuItem
            id='size-filter-item-all'
            key='all'
            onClick={() => updateFilter('sizeFilter', null)}
            value={null}
          >
            <span>All Sizes</span>
            <span className='sub-title'>Filter by all Sizes</span>
          </MenuItem>
          {allSizes.map((sizeList, _index) => (
            <MenuItem
              id={`size-filter-item-${sizeList.id}`}
              key={sizeList.id}
              onClick={() => updateFilter('sizeFilter', sizeList.id)}
              disabled={sizeList.id === sizeFilter}
              value={sizeList.id}
            >
              <span>{sizeList.desc}</span>
              <span className='sub-title'>Filter by size {sizeList.desc}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const [tagFilterValue, setTagFilterValue] = useState('');
  const filteredTags = useCallback(() => {
    const filteredTags: TagObjectType[] = [];
    const allTags = getAllTags();
    const filter = tagFilterValue.trim().toUpperCase();

    allTags.forEach((tag) => {
      const name = tag.name.toUpperCase();

      if (name.includes(filter)) filteredTags.push(tag);
    });
    return filteredTags;
  }, [tagFilterValue, getAllTags()]);

  const renderTagFilter = () => {
    return (
      <div
        id='tag-filter-con'
        className='item-container tag-container filter-control'
        key='tag-filter'
      >
        <Menu
          id='tag-filter-menu'
          style={{ width: '50px' }}
          className='item-menu'
          menuButton={
            <MenuButton id='tag-filter-menu-button' className={`item-btn tag-btn`}>
              <span className='item-selected'>
                {tagFilter ? getTagNameForSelected() : 'Filter by Tag'}
              </span>
              <FontAwesomeIcon
                id='tag-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
              <FontAwesomeIcon
                id='tag-filter-up-icon'
                className='drop-icon up'
                icon={faChevronUp}
              />
            </MenuButton>
          }
        >
          <FocusableItem id='tag-filter-menu-item-input-con' className='item-input'>
            {({ _ref }) => (
              <input
                id='tag-filter-menu-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Tag'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={tagFilterValue}
                onChange={(e) => setTagFilterValue((e.target as HTMLInputElement).value)}
              />
            )}
          </FocusableItem>
          {filteredTags().map((tagList, _index) => (
            <MenuItem
              id={`tag-filter-menu-item-${tagList.name}`}
              key={tagList.name}
              onClick={() => updateFilter('tagFilter', tagList.id)}
              value={tagList.name}
              className='tag-filter-menu-item'
            >
              {renderCheckbox(tagList, tagFilter)}
              {tagList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='tag-filter-menu-item-remove'
            className='remove-item'
            key='all'
            onClick={() => updateFilter('tagFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const getUserNameForId = () => {
    return 'Selected User';
  };

  const getProjectNameForId = () => {
    return 'Selected Project';
  };

  const getProjectName = () => {
    const index = getProjectIndex();

    if (index === -1) return '';

    return allProjects[index].display;
  };

  const [assigneeFilterValue, setAssigneeFilterValue] = useState('');
  const filteredUsers = useCallback(() => {
    const projectUsers = getProjectUsers();
    const filteredUsers: UserFieldType[] = [];
    const filter = assigneeFilterValue.trim().toUpperCase();

    projectUsers &&
      projectUsers.forEach((user) => {
        const name = user.name ? user.name.toUpperCase() : '';

        if (name.includes(filter)) filteredUsers.push(user);
      });
    return filteredUsers;
  }, [assigneeFilterValue, getProjectUsers()]);

  const renderAssigneeFilter = () => {
    return (
      <div
        id='assignee-filter-con'
        className='item-container assignee-container filter-control'
        key='assignee-filter'
      >
        <Menu
          id='assignee-filter-menu'
          style={{ width: '50px' }}
          className='item-menu'
          menuButton={
            <MenuButton id='assignee-filter-menu-button' className={`item-btn assignee-btn`}>
              <span className='item-selected'>
                {assigneeFilter ? getUserNameForId() : 'Filter by Assignee'}
              </span>
              <FontAwesomeIcon
                id='assignee-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
              <FontAwesomeIcon
                id='assignee-filter-up-icon'
                className='drop-icon up'
                icon={faChevronUp}
              />
            </MenuButton>
          }
        >
          <FocusableItem id='assignee-filter-menu-item-input-con' className='item-input'>
            {({ _ref }) => (
              <input
                id='assignee-filter-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Assignee'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={assigneeFilterValue}
                onChange={(e) => setAssigneeFilterValue((e.target as HTMLInputElement).value)}
              />
            )}
          </FocusableItem>
          {filteredUsers().map((userList, _index) => (
            <MenuItem
              id={`assignee-filter-menu-item-${userList.id}`}
              key={userList.id}
              onClick={() => updateFilter('assigneeFilter', userList.id)}
              value={userList.id}
              className='assignee-filter-menu-item'
            >
              {renderCheckbox(userList, assigneeFilter)}
              {userList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='assignee-filter-menu-item-remove'
            className='remove-item'
            key='all'
            onClick={() => updateFilter('assigneeFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const [projectFilterValue, setProjectFilterValue] = useState('');
  const filteredProjects = useCallback(() => {
    const projects = allProjects.filter((proj) => proj.archived !== true);
    const filteredProjects: ProjectObjectType[] = [];
    const filter = projectFilterValue.trim().toUpperCase();

    projects.forEach((proj) => {
      const name = proj.name?.toUpperCase() ?? '';

      if (name.includes(filter)) filteredProjects.push(proj);
    });
    return filteredProjects;
  }, [projectFilterValue, allProjects]);

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = (
    item: UserFieldType | ProjectObjectType | TagObjectType,
    filter?: string[] | null,
  ) => {
    return (
      <div className='checkbox-con' key={`checkbox-con-${item.id}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          checked={filter && filter.length > 0 ? filter.includes(item.id) : false}
          className='inp-cbx'
          id={`checkbox-${item.id}`}
          type='checkbox'
          onChange={() => {}}
          style={{ display: 'none' }}
        />

        <label className='cbx'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    );
  };

  const renderProjectFilter = () => {
    return (
      <div
        id='project-filter-con'
        className='item-container project-container filter-control'
        key='project-filter'
      >
        <Menu
          id='project-filter-menu'
          style={{ width: '50px' }}
          className='item-menu'
          menuButton={
            <MenuButton id='project-filter-menu-button' className={`item-btn assignee-btn`}>
              <span className='item-selected'>
                {projectFilter ? getProjectNameForId() : Strings.filter_project}
              </span>
              <FontAwesomeIcon
                id='project-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
              <FontAwesomeIcon
                id='project-filter-up-icon'
                className='drop-icon up'
                icon={faChevronUp}
              />
            </MenuButton>
          }
        >
          <FocusableItem id='project-filter-menu-item-input-con' className='item-input'>
            {({ _ref }) => (
              <input
                id='project-filter-menu-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Project'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={projectFilterValue}
                onChange={(e) => setProjectFilterValue((e.target as HTMLInputElement).value)}
              />
            )}
          </FocusableItem>
          {filteredProjects().map((projectList, _index) => (
            <MenuItem
              id={`project-filter-menu-item-${projectList.id}`}
              className='project-filter-menu-item'
              key={projectList.id}
              onClick={() => updateFilter('projectFilter', projectList.id)}
              value={projectList.id}
            >
              {renderCheckbox(projectList, projectFilter)}
              {projectList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='project-filter-menu-item-remove'
            className='remove-item'
            key='all'
            onClick={() => updateFilter('projectFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const getDateNameForId = useCallback(() => {
    const index = dateFilterData.findIndex((date) => date.id === dateFilter);

    if (index !== -1) return dateFilterData[index].name;

    const dateInFilter = moment(dateFilter, 'MM-DD-YYYY');

    if (!dateInFilter.isValid()) return 'Filter by Date';

    return dateFilter;
  }, [dateFilter]);

  const getDateNameForIdCompletedTask = useCallback(() => {
    const index = dateFilterData.findIndex((date) => date.id === completedTasksFilter);

    if (index !== -1) return dateFilterData[index].name;

    const dateInFilter = moment(completedTasksFilter, 'MM-DD-YYYY');

    if (!dateInFilter.isValid()) return 'Filter by Date';

    return completedTasksFilter;
  }, [completedTasksFilter]);

  const getTagNameForSelected = useCallback(() => {
    return 'Selected Tag';
  }, [tagFilter]);

  const [isdateFilterOpen, setDateFilterOpen] = useState(false);
  const dateFilterRef = useRef(null);

  const renderDateFilter = () => {
    return (
      <div
        id='date-filter-con'
        className='item-container date-filter-container filter-control'
        key='date-filter'
        onMouseLeave={() => setDateFilterOpen(false)}
      >
        <button
          id='date-filter-btn'
          className='item-btn date-btn'
          ref={dateFilterRef}
          onClick={() => !skipFilterClick.current && toggleMenu()}
          key='date-filter-btn'
        >
          <span id='date-filter-selection' className='item-selected'>
            {dateFilter ? getDateNameForId() : 'Filter by Date'}
          </span>
          {isdateFilterOpen ? (
            <FontAwesomeIcon id='date-filter-up-icon' className='drop-icon up' icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon
              id='date-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          )}
        </button>

        <ControlledMenu
          {...menuProps}
          id='date-filter-controlled-menu'
          className='date-menu'
          anchorRef={dateFilterRef}
          key='date-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipFilterClick.current = true;
              setTimeout(() => (skipFilterClick.current = false), 300);
            }
            if (!isCustomDateTapped) {
              closeMenu();
            }
          }}
        >
          {dateFilterData.map((dateList, _index) => (
            <MenuItem
              id={`date-filter-menu-item-${dateList.id}`}
              key={dateList.id}
              onClick={() => {
                updateFilter('dateFilter', dateList.id);
                setDateFilterOpen(false);
              }}
              disabled={dateList.id === dateFilter}
              value={dateList.id}
            >
              <span>{dateList.name}</span>
              <span className='sub-title'>Filter by {dateList.name}</span>
            </MenuItem>
          ))}

          <MenuItem
            onClick={(_e) => (isCustomDateTapped = true)}
            id='date-filter-menu-item-custom'
            className='custom-item'
            key='custom-date'
            value={null}
          >
            {renderCustomDate()}
          </MenuItem>
          <MenuItem
            id='date-filter-menu-item-remove'
            className='remove-item'
            key='remove-all'
            onClick={() => {
              updateFilter('dateFilter', null);
              setDateFilterOpen(false);
            }}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const renderCustomDate = () => {
    return (
      <div
        id='date-filter-custom-date-con'
        className='item-container due-date-container'
        key='custom-date-con'
      >
        <div id='date-filter-date-picker'>
          <DatePicker
            onSelect={(date) => dateFilterSelected(date)}
            onCalendarClose={() => setDateFilterOpen(false)}
            id='custom-date-id'
            popperPlacement='bottom-end'
            calendarClassName='date-range-calendar-con'
            wrapperClassName='custom-date'
            customInput={
              <div>
                <FontAwesomeIcon size='lg' className='calendar-icon' icon={faCalendarPlus} /> Custom
                Date
              </div>
            }
          />
        </div>
      </div>
    );
  };

  const updateCompletedTaskFilter = (filter: string, custom?: boolean) => {
    log('updateCompletedTaskFilter', filter, custom);

    let id: string;

    if (tutorialData.currentProjectId) id = currentProjectId ? currentProjectId : '';
    else if (stateType === 'INDIVIDUAL') id = currentUserId ? currentUserId : '';
    else id = loggedInUserId;

    getCompletedTasks(filter, true, id, stateType, custom);
  };

  const [isCompletedTaskFilterOpen, setCompletedTaskFilterOpen] = useState(false);
  const completedTaskFilterDateRef = useRef(null);

  const renderCompletedTaskFilter = () => {
    return (
      <div
        id='completed-date-filter-con'
        className='item-container date-filter-container filter-control'
        key='date-filter'
      >
        <button
          id='completed-date-filter-btn'
          className='item-btn completed-date-btn'
          ref={completedTaskFilterDateRef}
          onClick={() => setCompletedTaskFilterOpen(!isCompletedTaskFilterOpen)}
          key='date-filter-btn'
        >
          <span id='completed-date-filter-selection' className='item-selected'>
            {completedTasksFilter ? getDateNameForIdCompletedTask() : 'Filter by Date'}
          </span>
          {!isCompletedTaskFilterOpen ? (
            <FontAwesomeIcon
              id='completed-date-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='completed-date-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>

        <ControlledMenu
          id='completed-date-filter-controlled-menu'
          className='date-menu'
          anchorRef={completedTaskFilterDateRef}
          state={isCompletedTaskFilterOpen ? 'open' : 'closed'}
          key='date-filter-menu'
        >
          {dateFilterData.map((dateList, _index) => (
            <MenuItem
              id={`completed-date-filter-menu-item-${dateList.id}`}
              key={dateList.id}
              onClick={() => {
                updateCompletedTaskFilter(dateList.id);
                setCompletedTaskFilterOpen(false);
              }}
              disabled={dateList.id === dateFilter}
              value={dateList.id}
            >
              <span>{dateList.name}</span>
              <span className='sub-title'>Filter by {dateList.name}</span>
            </MenuItem>
          ))}

          <MenuItem
            id='completed-date-filter-menu-item-custom'
            className='custom-item'
            key='custom-date'
            value={null}
          >
            {renderCustomDateForCompletedTasks()}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const onChangeCompletedFilter = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      setStartDateFilter(null);
      setEndDateFilter(null);
      setCompletedTaskFilterOpen(false);

      const date = `${formatDateForView(start)}~${formatDateForView(end)}`;

      updateCompletedTaskFilter(date, true);
    } else {
      setStartDateFilter(start);
      setEndDateFilter(end);
    }
  };
  const renderCustomDateForCompletedTasks = () => {
    return (
      <div
        id='completed-date-filter-custom-date-con'
        className='item-container due-date-container'
        key='custom-date-con'
      >
        <div id='completed-date-filter-date-picker'>
          <DatePicker
            selected={startDateFilter}
            onChange={onChangeCompletedFilter}
            startDate={startDateFilter}
            endDate={endDateFilter}
            selectsRange
            selectsEnd={true}
            selectsStart={true}
            shouldCloseOnSelect={false}
            id='custom-date-id'
            popperPlacement='bottom-end'
            calendarClassName='date-range-calendar-con'
            wrapperClassName='custom-date'
            customInput={
              <div>
                <FontAwesomeIcon size='lg' className='calendar-icon' icon={faCalendarPlus} /> Custom
                Date
              </div>
            }
          />
        </div>
      </div>
    );
  };

  const renderAllFilters = () => {
    if (readOnly) {
      return (
        <Fragment>
          {stateType === 'PROJECT' ? renderAssigneeFilter() : null}
          {stateType !== 'PROJECT' ? renderProjectFilter() : null}
          {renderTagFilter()}
          {renderSizeFilter()}
          {renderCompletedTaskFilter()}
        </Fragment>
      );
    } else if (!accountSettings.filterVisiblity) return null;

    if (stateType === 'PROJECT')
      return (
        <Fragment>
          {renderAssigneeFilter()}
          {renderDateFilter()}
          {renderTagFilter()}
          {renderSizeFilter()}
        </Fragment>
      );

    return (
      <Fragment>
        {renderProjectFilter()}
        {renderDateFilter()}
        {renderTagFilter()}
        {renderSizeFilter()}
      </Fragment>
    );
  };

  const setEditProjectModalStatus = useSetRecoilState(showEditProjectModalState);

  const showProjectInfo = () => {
    if (tutorialData.flags.invitationCompletion) {
      updateFlag('showProjectModal', true);
    } else if (tutorialData.flags.showProjectInfoIcon) {
      updateFlag('editProjectNameModal', true);
    } else if (tutorialData.flags.infoIconForArchive) {
      updateFlag('showArchiveButton', true);
    }

    setEditProjectModalStatus(true);
  };

  const [showFilters, setShowFilters] = useState(false);
  /**
   * sets the modal status of filter options to true
   */
  const showFilterOptions = () => {
    setShowFilters(true);
  };

  const renderInfoIcon = () => {
    if (tutorialData.currentProjectId) {
      return (
        <div id='info-icon-title-bar' className='item-container info-container' key='info-con'>
          <button id='info-btn' className='info-btn' onClick={showProjectInfo}>
            <img id='info-image' src={InfoIcon} alt='info' className='info-image' />
          </button>
          {renderOverlay(
            tutorialData.flags.invitationCompletion ||
              tutorialData.flags.showProjectInfoIcon ||
              tutorialData.flags.infoIconForArchive
              ? 'hide-overlay'
              : '',
          )}
        </div>
      );
    }
    return null;
  };

  /**
   * renders the filter icon displayed for smaller screens
   * returns DOM element
   */
  const renderFilterIcon = () => {
    return (
      <div id='filter-container' className='item-container filter-container' key='filter-con'>
        <button id='filter-btn' className='filter-btn' onClick={showFilterOptions}></button>
      </div>
    );
  };

  const renderOverlay = (styleClass?: string) => {
    return <div className={`tutorial-overlay ${styleClass}`}></div>;
  };

  return (
    <div
      className={`top-title-bar-container tutorial ${
        navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
      }`}
      id={idName}
    >
      <div className='left-side tutorial' id='left-side'>
        {renderLeftTitle()}
      </div>
      <div className='right-side tutorial' id='right-side'>
        <div
          className={`filter-main-container ${!showFilters ? 'hide' : ''} ${
            tutorialData.currentProjectId ? 'project' : ''
          }`}
          id='filter-main-container'
          onClick={() => setShowFilters(!showFilters)}
        >
          <div className='filter-sub' id='filter-sub' onClick={(e) => e.stopPropagation()}>
            {renderAllFilters()}
          </div>
          {renderOverlay()}
        </div>
        {renderFilterIcon()}
        {renderInfoIcon()}
      </div>
    </div>
  );
}
