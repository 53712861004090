import { atom, DefaultValue, selector } from 'recoil';
import { currentHighlightedTaskState } from 'recoil/ProjectState';

export const userFocusTaskDataState = atom<TaskObjectType[] | null>({
  key: 'userFocusData',
  default: null,
});

export const userFocusTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'userFocusFilteredData',
  default: null,
});

export const userFocusTaskLoadingState = atom<boolean>({
  key: 'userFocusLoading',
  default: false,
});

export const userFocusTaskAbortState = atom<AbortController>({
  key: 'userFocusAbortController',
  default: new AbortController(),
});

export const userFocusTaskSizeState = atom<number>({
  key: 'userFocusTaskSize',
  default: 0,
});

export const userFocusTasksState = selector<TaskListType>({
  key: 'userFocus',
  get: ({ get }) => {
    const data = get(userFocusTaskDataState);
    const filteredData = get(userFocusTaskFilteredDataState);
    const isLoading = get(userFocusTaskLoadingState);
    const abortController = get(userFocusTaskAbortState);
    const size = get(userFocusTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userFocusTaskDataState, value);
      set(userFocusTaskFilteredDataState, value);
      set(userFocusTaskLoadingState, value);
      set(userFocusTaskAbortState, value);
      set(userFocusTaskSizeState, value);
      return;
    }
    set(userFocusTaskDataState, value.data);
    set(userFocusTaskFilteredDataState, value.filteredData);
    set(userFocusTaskLoadingState, value.isLoading);
    set(userFocusTaskAbortState, value.abortController);
    set(userFocusTaskSizeState, value.size);
  },
});

export const userBacklogTaskDataState = atom<TaskObjectType[] | null>({
  key: 'userBacklogData',
  default: null,
});

export const userBacklogTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'userBacklogFilteredData',
  default: null,
});

export const userBacklogTaskLoadingState = atom<boolean>({
  key: 'userBacklogLoading',
  default: false,
});

export const userBacklogTaskAbortState = atom<AbortController>({
  key: 'userBacklogAbortController',
  default: new AbortController(),
});

export const userBacklogTaskSizeState = atom<number>({
  key: 'userBacklogTaskSize',
  default: 0,
});

export const userBacklogTasksState = selector<TaskListType>({
  key: 'userBacklog',
  get: ({ get }) => {
    const data = get(userBacklogTaskDataState);
    const filteredData = get(userBacklogTaskFilteredDataState);
    const isLoading = get(userBacklogTaskLoadingState);
    const abortController = get(userBacklogTaskAbortState);
    const size = get(userBacklogTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userBacklogTaskDataState, value);
      set(userBacklogTaskFilteredDataState, value);
      set(userBacklogTaskLoadingState, value);
      set(userBacklogTaskAbortState, value);
      set(userBacklogTaskSizeState, value);
      return;
    }
    set(userBacklogTaskDataState, value.data);
    set(userBacklogTaskFilteredDataState, value.filteredData);
    set(userBacklogTaskLoadingState, value.isLoading);
    set(userBacklogTaskAbortState, value.abortController);
    set(userBacklogTaskSizeState, value.size);
  },
});

export const currentUserIdState = atom<string | null>({
  key: 'currentUserId',
  default: null,
});

export const userTaskFiltersState = atom<TaskFiltersObjectType>({
  key: 'usersTaskFilters',
  default: {
    sizeFilter: null,
    tagFilter: null,
    assigneeFilter: null,
    dateFilter: null,
    projectFilter: null,
    searchTextFilter: '',
  },
});

export const userCompletedTaskDataState = atom<TaskObjectType[]>({
  key: 'userCompletedTaskData',
  default: [],
});
export const userCompletedTaskHasMoreState = atom<boolean>({
  key: 'userCompletedTaskHasMore',
  default: true,
});
export const userCompletedTaskPageState = atom<number>({
  key: 'userCompletedTaskPage',
  default: 0,
});
export const userCompletedTaskLimitState = atom<number>({
  key: 'userCompletedTaskLimit',
  default: 10,
});
export const userCompletedTaskPointsState = atom<number>({
  key: 'userCompletedTaskPoints',
  default: 0,
});

export const userCompletedTaskSizeState = atom<number | null>({
  key: 'userCompletedTaskSize',
  default: null,
});
export const userCompletedTaskLoadingState = atom<boolean>({
  key: 'userCompletedTaskLoading',
  default: false,
});
export const userCompletedTaskFilterState = atom<string>({
  key: 'userCompletedTaskFilter',
  default: 'today',
});
export const userCompletedTaskAbortControllerState = atom<AbortController>({
  key: 'userCompletedTaskAbortController',
  default: new AbortController(),
});
export const userCompletedTasksState = selector<PaginatedTasksType>({
  key: 'userCompletedTasks',
  get: ({ get }) => {
    const data = get(userCompletedTaskDataState);
    const hasMore = get(userCompletedTaskHasMoreState);
    const page = get(userCompletedTaskPageState);
    const limit = get(userCompletedTaskLimitState);
    const points = get(userCompletedTaskPointsState);
    const size = get(userCompletedTaskSizeState);
    const isLoading = get(userCompletedTaskLoadingState);
    const filter = get(userCompletedTaskFilterState);
    const abortController = get(userCompletedTaskAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      points,
      size,
      isLoading,
      filter,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userCompletedTaskDataState, value);
      set(userCompletedTaskHasMoreState, value);
      set(userCompletedTaskPageState, value);
      set(userCompletedTaskLimitState, value);
      set(userCompletedTaskPointsState, value);
      set(userCompletedTaskSizeState, value);
      set(userCompletedTaskLoadingState, value);
      set(userCompletedTaskFilterState, value);
      set(userCompletedTaskAbortControllerState, value);
      return;
    }

    set(userCompletedTaskDataState, value.data);
    set(userCompletedTaskHasMoreState, value.hasMore);
    set(userCompletedTaskPageState, value.page);
    set(userCompletedTaskLimitState, value.limit);
    set(userCompletedTaskPointsState, value.points);
    set(userCompletedTaskSizeState, value.size);
    set(userCompletedTaskLoadingState, value.isLoading);
    set(userCompletedTaskFilterState, value.filter);
    set(userCompletedTaskAbortControllerState, value.abortController);
  },
});

export const userTaskUnfilteredListState = atom<TaskTagListType>({
  key: 'userTaskUnfilteredList',
  default: {
    FOCUS: [],
    BACKLOG: [],
    COMPLETED: [],
  },
});

export const allUserTagsState = atom<TagType[]>({
  key: 'allUserTags',
  default: [],
});

export const allUserProjectsState = atom<ProjectObjectType[]>({
  key: 'allUserProjects',
  default: [],
});

export const currentHighlightedTask = atom<TaskObjectType | null>({
  key: 'currentHighlightedTaskInUser',
  default: null,
});

export const userWebsocketStatusState = atom<WebsocketStatusType>({
  key: 'userWebsocketStatus',
  default: 'INIT',
});

export const userSelectedFocusTaskState = atom<SelectedTaskIdsType[]>({
  key: 'userSelectedFocusTask',
  default: [],
});

export const userSelectedBacklogTaskState = atom<SelectedTaskIdsType[]>({
  key: 'userSelectedBacklogTask',
  default: [],
});

export const userSelectedCompletedTaskState = atom<SelectedTaskIdsType[]>({
  key: 'userSelectedCompletedTask',
  default: [],
});

export const userSelectedTempIdsTaskState = atom<SelectedTaskIdsType[]>({
  key: 'userSelectedTempIdsTask',
  default: [],
});

export const userSelectedTaskTypeState = atom<TaskType | null>({
  key: 'userSelectedTaskType',
  default: null,
});

export const userSelectedTaskDragIdState = atom<string | null>({
  key: 'userSelectedTaskDragId',
  default: null,
});

export const userSelectedTasksState = selector<SelectedTasksType>({
  key: 'userSelectedTasks',
  get: ({ get }) => {
    const FOCUS = get(userSelectedFocusTaskState);
    const BACKLOG = get(userSelectedBacklogTaskState);
    const COMPLETED = get(userSelectedCompletedTaskState);
    const tempIds = get(userSelectedTempIdsTaskState);
    const lastSelectedTaskType = get(userSelectedTaskTypeState);
    const draggingTaskId = get(userSelectedTaskDragIdState);
    return {
      FOCUS,
      BACKLOG,
      COMPLETED,
      tempIds,
      lastSelectedTaskType,
      draggingTaskId,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userSelectedFocusTaskState, value);
      set(userSelectedBacklogTaskState, value);
      set(userSelectedCompletedTaskState, value);
      set(userSelectedTempIdsTaskState, value);
      set(userSelectedTaskTypeState, value);
      set(userSelectedTaskDragIdState, value);
      return;
    }
    set(userSelectedFocusTaskState, value.FOCUS);
    set(userSelectedBacklogTaskState, value.BACKLOG);
    set(userSelectedCompletedTaskState, value.COMPLETED);
    set(userSelectedTempIdsTaskState, value.tempIds);
    set(userSelectedTaskTypeState, value.lastSelectedTaskType);
    set(userSelectedTaskDragIdState, value.draggingTaskId);
  },
});

export const userAutoFocusState = atom<string | null>({
  key: 'userAutoFocus',
  default: null,
});

export const userTaskFetchErrorState = atom<string | null>({
  key: 'userTaskFetchError',
  default: null,
});

export const selectedUserNotificationTaskIdState = atom<string | null>({
  key: 'selectedUserNotificationTaskId',
  default: null,
});

export const userDataSelector = selector<UserDataType>({
  key: 'userData',
  get: ({ get }) => {
    const currentUserId = get(currentUserIdState);
    const taskFilters = get(userTaskFiltersState);
    const COMPLETED = get(userCompletedTasksState);
    const FOCUS = get(userFocusTasksState);
    const BACKLOG = get(userBacklogTasksState);
    const unFilteredList = get(userTaskUnfilteredListState);
    const allUserTags = get(allUserTagsState);
    const allUserProjects = get(allUserProjectsState);
    const currentHighlightedTask = get(currentHighlightedTaskState);
    const websocketStatus = get(userWebsocketStatusState);
    const selectedTasks = get(userSelectedTasksState);
    const autoFocus = get(userAutoFocusState);
    const error = get(userTaskFetchErrorState);
    const selectedUserNotificationTaskId = get(selectedUserNotificationTaskIdState);

    // then combine into desired shape (object) and return:
    return {
      currentUserId,
      taskFilters,
      COMPLETED,
      FOCUS,
      BACKLOG,
      unFilteredList,
      allUserTags,
      allUserProjects,
      currentHighlightedTask,
      websocketStatus,
      selectedTasks,
      autoFocus,
      error,
      selectedUserNotificationTaskId,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(currentUserIdState, value);
      set(userTaskFiltersState, value);
      set(userCompletedTasksState, value);
      set(userFocusTasksState, value);
      set(userBacklogTasksState, value);
      set(userTaskUnfilteredListState, value);
      set(allUserTagsState, value);
      set(allUserProjectsState, value);
      set(currentHighlightedTaskState, value);
      set(userWebsocketStatusState, value);
      set(userSelectedTasksState, value);
      set(userAutoFocusState, value);
      set(userTaskFetchErrorState, value);
      set(selectedUserNotificationTaskIdState, value);
      return;
    }
    set(currentUserIdState, value.currentUserId);
    set(userTaskFiltersState, value.taskFilters);
    set(userCompletedTasksState, value.COMPLETED);
    set(userFocusTasksState, value.FOCUS);
    set(userBacklogTasksState, value.BACKLOG);
    set(userTaskUnfilteredListState, value.unFilteredList);
    set(allUserTagsState, value.allUserTags);
    set(allUserProjectsState, value.allUserProjects);
    set(currentHighlightedTaskState, value.currentHighlightedTask);
    set(userWebsocketStatusState, value.websocketStatus);
    set(userSelectedTasksState, value.selectedTasks);
    set(userAutoFocusState, value.autoFocus);
    set(userTaskFetchErrorState, value.error);
    set(selectedUserNotificationTaskIdState, value.selectedUserNotificationTaskId);
  },
});
