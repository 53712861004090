import UIfx from 'uifx';
import { canBePlayed } from 'triggers/utils/sound';
import { log } from 'utils';
export const BG_SOUND_NAME = 'bg-music';

export function playSound(fileName: string, themeId: string) {
  if (!canBePlayed(fileName)) return;

  const fileUrl = buildSoundUrl(fileName, themeId);

  if (fileName === BG_SOUND_NAME) loop(fileUrl, fileName);
  else playOnce(fileUrl);
}

function playOnce(url: string) {
  const sound = new UIfx(url);

  sound.play();

  setTimeout(() => {
    removeSoundElement(url);
  }, 5000);
}

function loop(url: string, id: string) {
  removeSound(id);
  loopSound(url, id);
}

function buildSoundUrl(fileName: string, themeId: string) {
  return `/themes/${themeId}/sounds/${fileName}.mp3`;
}

function removeSoundElement(url: string) {
  const id = `uifx-${hash(url)}`;
  const element = document.getElementById(id);

  if (element) element.remove();
}

const hash = (str: string) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

function loopSound(file, id) {
  // hack to force browser
  // to preload audio file
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hash = function hash(str) {
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  };
  const audioElement = document.createElement('audio');

  audioElement.id = getSoundId(id);
  audioElement.src = file;
  audioElement.preload = 'auto';
  audioElement.autoplay = true;
  audioElement.loop = true;
  audioElement.volume = 0.6;

  document.body.appendChild(audioElement);
  // return file;
}

function getSoundId(id) {
  return id + '-sound';
}

export function removeSound(id) {
  log('removeSound', id);
  const link = document.getElementById(getSoundId(id));

  if (link) link.remove();
}
