import {
  getNewSequence,
  getOtherDataType,
  moveSubTasks,
  reorderTasks,
  SEQUENCE_LARGE,
  parseTask,
  setFilterInLocalStorage,
  removeFilterFromLocalStorage,
  setTagFilterListInLocalStorage,
  removeTagFilterListFromLocalStorage,
  formatDateForAPI,
  getStateType,
  createNewTaskComment,
  getReducerTypeMain,
  removeFromOneAddToAnother,
  bulkRemoveFromOneAddToAnother,
  bulkRemoveAddInSame,
  parseToDateObjectFromString,
  parseData,
  createNewTask,
  isHomeScreen,
} from 'utils/task';
import {
  tutorialAutoFocusState,
  tutorialCompletedTasksState,
  tutorialCurrentHighlightedTask,
  tutorialTaskFetchErrorState,
  tutorialTaskFiltersState,
  tutorialTaskUnfilteredListState,
  tutorialCompletedTaskDataState,
  tutorialCompletedTaskHasMoreState,
  tutorialCompletedTaskPageState,
  tutorialCompletedTaskPointsState,
  tutorialCompletedTaskSizeState,
  tutorialCompletedTaskLoadingState,
  tutorialCompletedTaskFilterState,
  tutorialCompletedTaskAbortControllerState,
  tutorialFocusTaskLoadingState,
  tutorialBacklogTaskLoadingState,
  tutorialFocusTaskAbortState,
  tutorialBacklogTaskAbortState,
  tutorialFocusTaskDataState,
  tutorialBacklogTaskDataState,
  tutorialSelectedTaskDragIdState,
  tutorialSelectedTaskTypeState,
  tutorialSelectedTempIdsTaskState,
  tutorialSelectedCompletedTaskState,
  tutorialSelectedFocusTaskState,
  tutorialSelectedBacklogTaskState,
  tutorialFocusTaskSizeState,
  tutorialBacklogTaskSizeState,
  selectedTutorialNotificationTaskIdState,
} from 'recoil/TutorialState';
import { log } from 'utils';
import {
  currentProjectIdState,
  projectAutoFocusState,
  projectBacklogTaskAbortState,
  projectBacklogTaskDataState,
  projectBacklogTaskLoadingState,
  projectCompletedTaskAbortControllerState,
  projectCompletedTaskDataState,
  projectCompletedTaskFilterState,
  projectCompletedTaskHasMoreState,
  projectCompletedTaskLoadingState,
  projectCompletedTaskPageState,
  projectCompletedTaskPointsState,
  projectCompletedTaskSizeState,
  projectCompletedTasksState,
  projectFocusTaskAbortState,
  projectFocusTaskDataState,
  projectFocusTaskLoadingState,
  currentHighlightedTaskState as projectHighlightedTaskState,
  projectSelectedBacklogTaskState,
  projectSelectedCompletedTaskState,
  projectSelectedFocusTaskState,
  projectSelectedTaskDragIdState,
  projectSelectedTaskTypeState,
  projectSelectedTempIdsTaskState,
  projectTaskFiltersState,
  projectTaskUnfilteredListState,
  projectErrorState,
  projectFocusTaskSizeState,
  projectBacklogTaskSizeState,
  selectedProjectNotificationTaskIdState,
} from 'recoil/ProjectState';
import {
  taskFiltersState,
  taskUnfilteredListState,
  completedTasksState,
  taskFetchErrorState,
  autoFocusState,
  currentHighlightedTaskState,
  completedTaskDataState,
  completedTaskHasMoreState,
  completedTaskPageState,
  completedTaskPointsState,
  completedTaskLoadingState,
  completedTaskFilterState,
  completedTaskAbortControllerState,
  focusTaskLoadingState,
  backlogTaskLoadingState,
  focusTaskAbortState,
  backlogTaskAbortState,
  focusTaskDataState,
  backlogTaskDataState,
  selectedTaskDragIdState,
  selectedTaskTypeState,
  selectedTempIdsTaskState,
  selectedCompletedTaskState,
  selectedFocusTaskState,
  selectedBacklogTaskState,
  completedTaskSizeState,
  focusTaskSizeState,
  backlogTaskSizeState,
  selectedNotificationTaskIdState,
} from 'recoil/TaskState';
import {
  currentTemplateIdState,
  templateAutoFocusState,
  currentHighlightedTask as templateHighlightedTaskState,
  templateTaskFiltersState,
  templateTaskFetchErrorState,
  templateTaskUnfilteredListState,
  templateFocusTaskLoadingState,
  templateBacklogTaskLoadingState,
  templateFocusTaskAbortState,
  templateBacklogTaskAbortState,
  templateFocusTaskDataState,
  templateSelectedTaskDragIdState,
  templateSelectedTaskTypeState,
  templateSelectedTempIdsTaskState,
  templateSelectedCompletedTaskState,
  templateSelectedFocusTaskState,
  templateSelectedBacklogTaskState,
  templateBacklogTaskDataState,
  templateFocusTaskSizeState,
  templateBacklogTaskSizeState,
  selectedTemplateNotificationTaskIdState,
} from 'recoil/TemplateState';
import {
  currentUserIdState,
  userAutoFocusState,
  currentHighlightedTask as userHighlightedTaskState,
  userTaskFiltersState,
  userTaskFetchErrorState,
  userCompletedTasksState,
  userTaskUnfilteredListState,
  userCompletedTaskDataState,
  userCompletedTaskHasMoreState,
  userCompletedTaskPageState,
  userCompletedTaskPointsState,
  userCompletedTaskSizeState,
  userCompletedTaskLoadingState,
  userCompletedTaskFilterState,
  userCompletedTaskAbortControllerState,
  userFocusTaskLoadingState,
  userBacklogTaskLoadingState,
  userFocusTaskAbortState,
  userBacklogTaskAbortState,
  userFocusTaskDataState,
  userBacklogTaskDataState,
  userSelectedTaskDragIdState,
  userSelectedTaskTypeState,
  userSelectedTempIdsTaskState,
  userSelectedCompletedTaskState,
  userSelectedFocusTaskState,
  userSelectedBacklogTaskState,
  userFocusTaskSizeState,
  userBacklogTaskSizeState,
  selectedUserNotificationTaskIdState,
} from 'recoil/UserState';
import {
  recurringAutoFocusState,
  recurringBacklogTaskAbortState,
  recurringBacklogTaskDataState,
  recurringBacklogTaskLoadingState,
  recurringBacklogTaskSizeState,
  recurringFocusTaskAbortState,
  recurringFocusTaskDataState,
  recurringFocusTaskLoadingState,
  recurringFocusTaskSizeState,
  currentHighlightedTaskState as recurringHighlightedTaskState,
  recurringSelectedBacklogTaskState,
  recurringSelectedCompletedTaskState,
  recurringSelectedFocusTaskState,
  recurringSelectedTaskDragIdState,
  recurringSelectedTaskTypeState,
  recurringSelectedTempIdsTaskState,
  recurringTaskFiltersState,
  recurringTaskUnfilteredListState,
  selectedRecurringNotificationTaskIdState,
} from 'recoil/RecurringTaskState';
import {
  getTasks,
  updateTaskParent as updateTaskParentAPI,
  updateTaskSequence as updateTaskSequenceFromAPI,
  updateTaskSize as updateTaskSizeFromAPI,
  bulkUpdateSize as bulkUpdateSizeFromAPI,
  createNewTask as createNewTaskAPI,
  getCompletedTasks as getCompletedTasksFromAPI,
  updateTaskDates,
  updateTaskStatus,
  deleteTask as deleteTaskAPI,
  updateTaskValue as updateTaskValueFromAPI,
  updateTaskProject as updateTaskProjectFromAPI,
  bulkUpdateProject as bulkUpdateProjectFromAPI,
  addTask as addTaskAPI,
  addCommentAttachment as addCommentAttachmentAPI,
  addComment as addCommentAPI,
  editComment as editCommentAPI,
  deleteComment as deleteCommentAPI,
  updateTaskAssignee as updateTaskAssigneeAPI,
  updateCollapseTaskStatus as updateCollapseTaskStatusAPI,
  bulkUpdateAssignee as bulkUpdateAssigneeFromAPI,
  bulkUpdateTaskDates as bulkUpdateTaskDatesAPI,
  bulkUpdateTaskStatus as bulkUpdateTaskStatusAPI,
  bulkUpdateTaskSequence as bulkUpdateTaskSequenceAPI,
} from 'services/TaskService';
import {
  updateTemplateTask as updateTemplateTaskAPI,
  updateTemplateRecurringTask as updateTemplateRecurringTaskAPI,
} from 'services/TemplateService';
import {
  sortStringData,
  randomNumber,
  checkIfItsACustomFilter,
  insertOnIndex,
  getRecurringTaskPayload,
  sortData,
  TASK_INPUT_WAIT_INTERVAL,
} from 'utils/helpers';
import { getItem } from 'utils/storage';
import { combinedSelector } from 'recoil/CombinedState';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { getRecurringTasks } from 'recoil/RecurringTaskState/update';
import { getUserId } from 'services/AuthService';
import moment from 'moment';
import _ from 'underscore';
import { RecoilState } from 'recoil';
import { addCompletedTasks } from 'recoil/CompletedTasksState/update';
import { playTheSound } from 'triggers/utils/sound';
import { runCanvasAnimation } from 'triggers/utils/canvas';
import { triggerDataSelector } from 'recoil/TriggerState';
import { playTheLottie } from 'triggers/utils/lottie';
import { getCompleteTaskDelay } from 'triggers/utils/css';
import { itemShouldBeFilteredOutBeforeStoring } from 'websocket/activities';
import { playTheRiveCanvas } from 'triggers/utils/rive';
const filterPrefix = 'magic_task';
let timer: any = null;

/*
 * Loading task data
 *
 * dataType String
 * isLoading Boolean
 * stateType String
 *
 * returns Object
 */
export function loadingTaskData(dataType: TaskType, isLoading: boolean, stateType: StateType) {
  const targetStateAtom = getTaskListLoading(stateType, dataType);
  setRecoil(targetStateAtom, isLoading);
}

export function setTaskAbortController(
  dataType: TaskType,
  abortController: AbortController,
  stateType: StateType,
) {
  const targetStateAtom = getTaskListAbortController(stateType, dataType);
  setRecoil(targetStateAtom, abortController);
}

/*
 * Add task data
 *
 * dataType String
 * data Array
 * stateType String
 *
 * returns Object
 */
export function addTaskData(
  dataType: TaskType,
  taskdata: TaskObjectType[] | null,
  stateType?: StateType,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  setRecoil(targetStateAtom, taskdata);
}

export function addTaskSizeData(dataType: TaskType, size: number, stateType?: StateType) {
  const targetStateAtom = getTaskSizeData(stateType, dataType);
  setRecoil(targetStateAtom, size);
}

export function getSelectedTasksDragIdState(stateType?: StateType) {
  if (stateType === 'PROJECT') {
    return projectSelectedTaskDragIdState;
  } else if (stateType === 'INDIVIDUAL') {
    return userSelectedTaskDragIdState;
  } else if (stateType === 'RECURRING') {
    return recurringSelectedTaskDragIdState;
  } else if (stateType === 'TEMPLATE') {
    return templateSelectedTaskDragIdState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialSelectedTaskDragIdState;
  } else {
    return selectedTaskDragIdState;
  }
}

export function getSelectedTasksLastSelectedTypeState(stateType?: StateType) {
  if (stateType === 'PROJECT') {
    return projectSelectedTaskTypeState;
  } else if (stateType === 'INDIVIDUAL') {
    return userSelectedTaskTypeState;
  } else if (stateType === 'RECURRING') {
    return recurringSelectedTaskTypeState;
  } else if (stateType === 'TEMPLATE') {
    return templateSelectedTaskTypeState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialSelectedTaskTypeState;
  } else {
    return selectedTaskTypeState;
  }
}

export function getSelectedTasksTempIdState(stateType?: StateType) {
  if (stateType === 'PROJECT') {
    return projectSelectedTempIdsTaskState;
  } else if (stateType === 'INDIVIDUAL') {
    return userSelectedTempIdsTaskState;
  } else if (stateType === 'RECURRING') {
    return recurringSelectedTempIdsTaskState;
  } else if (stateType === 'TEMPLATE') {
    return templateSelectedTempIdsTaskState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialSelectedTempIdsTaskState;
  } else {
    return selectedTempIdsTaskState;
  }
}

export function getSelectedNotificationTaskIdState(stateType?: StateType) {
  if (stateType === 'PROJECT') {
    return selectedProjectNotificationTaskIdState;
  } else if (stateType === 'INDIVIDUAL') {
    return selectedUserNotificationTaskIdState;
  } else if (stateType === 'RECURRING') {
    return selectedRecurringNotificationTaskIdState;
  } else if (stateType === 'TEMPLATE') {
    return selectedTemplateNotificationTaskIdState;
  } else if (stateType === 'TUTORIAL') {
    return selectedTutorialNotificationTaskIdState;
  } else {
    return selectedNotificationTaskIdState;
  }
}

export function getSelectedTaskListState(dataType: TaskType, stateType?: StateType) {
  if (stateType === 'PROJECT') {
    return dataType === 'COMPLETED'
      ? projectSelectedCompletedTaskState
      : dataType === 'FOCUS'
        ? projectSelectedFocusTaskState
        : projectSelectedBacklogTaskState;
  } else if (stateType === 'INDIVIDUAL') {
    return dataType === 'COMPLETED'
      ? userSelectedCompletedTaskState
      : dataType === 'FOCUS'
        ? userSelectedFocusTaskState
        : userSelectedBacklogTaskState;
  } else if (stateType === 'RECURRING') {
    return dataType === 'COMPLETED'
      ? recurringSelectedCompletedTaskState
      : dataType === 'FOCUS'
        ? recurringSelectedFocusTaskState
        : recurringSelectedBacklogTaskState;
  } else if (stateType === 'TEMPLATE') {
    return dataType === 'COMPLETED'
      ? templateSelectedCompletedTaskState
      : dataType === 'FOCUS'
        ? templateSelectedFocusTaskState
        : templateSelectedBacklogTaskState;
  } else if (stateType === 'TUTORIAL') {
    return dataType === 'COMPLETED'
      ? tutorialSelectedCompletedTaskState
      : dataType === 'FOCUS'
        ? tutorialSelectedFocusTaskState
        : tutorialSelectedBacklogTaskState;
  } else {
    return dataType === 'COMPLETED'
      ? selectedCompletedTaskState
      : dataType === 'FOCUS'
        ? selectedFocusTaskState
        : selectedBacklogTaskState;
  }
}

export function updateDraggingTaskId(data: string | null, stateType: StateType) {
  const targetStateAtom = getSelectedTasksDragIdState(stateType);
  setRecoil(targetStateAtom, data);
}

export function updateLastSelectedDataType(data: TaskType | null, stateType: StateType) {
  const targetStateAtom = getSelectedTasksLastSelectedTypeState(stateType);
  setRecoil(targetStateAtom, data);
}

export function updateSelectedTasks(
  dataType: TaskType,
  data: SelectedTaskIdsType[],
  tempIds: SelectedTaskIdsType[],
  stateType,
) {
  const tempIdTargetStateAtom = getSelectedTasksTempIdState(stateType);
  setRecoil(tempIdTargetStateAtom, tempIds);
  const targetStateAtom = getSelectedTaskListState(dataType, stateType);
  setRecoil(targetStateAtom, data);
}

export function updateSelectedNotificationTaskId(stateType: StateType, taskId: string | null) {
  const selectedNotificationTaskIdAtom = getSelectedNotificationTaskIdState(stateType);
  setRecoil(selectedNotificationTaskIdAtom, taskId);
}

export function getStateTypeMain(stateType: StateType | null) {
  const mainData: StateDataObjectType = getRecoil(combinedSelector);
  switch (stateType) {
    case 'PROJECT':
      return mainData.projectData;
    case 'INDIVIDUAL':
      return mainData.userData;
    case 'TUTORIAL':
      return mainData.tutorialData;
    case 'TEMPLATE':
      return mainData.templateData;
    default:
      return mainData.taskData;
  }
}

export function addTaskFetchError(error?: string | null, stateType?: StateType | null) {
  if (stateType === undefined || stateType === null || stateType === 'USER') {
    setRecoil(taskFetchErrorState, error ? error : null);
  } else if (stateType === 'INDIVIDUAL') {
    setRecoil(userTaskFetchErrorState, error ? error : null);
  } else if (stateType === 'PROJECT') {
    setRecoil(projectErrorState, error ? error : null);
  } else if (stateType === 'TEMPLATE') {
    setRecoil(templateTaskFetchErrorState, error ? error : null);
  } else if (stateType === 'TUTORIAL') {
    setRecoil(tutorialTaskFetchErrorState, error ? error : null);
  }
}

/*
 * Set tag filter list
 *
 * unFilteredList Array [{id, name}]
 * tokenType String
 * stateType String
 *
 * returns Object
 */
export function storeTagFilterList(
  unFilteredList: TagObjectType[],
  dataType: TaskType,
  stateType: StateType | null,
) {
  const targetStateAtom = getTagUnfilteredListData(stateType);
  const targetData = getRecoil(targetStateAtom);
  const tempData = {
    ...targetData,
    [dataType]: unFilteredList,
  };
  setRecoil(targetStateAtom, tempData);
}

export function getFilterStorageName(name: string, stateType: StateType | '', id?: string) {
  return `${filterPrefix}_${name}${stateType ? stateType : ''}${id ? id : ''}`;
}

export const getTagFilterListFromLocalStorage = (stateType: StateType) => {
  const storageName = getFilterStorageName('tagFilterStorage', stateType);
  return getItem(storageName);
};

/*
 * Get task data
 *
 * dataType String
 * reset Boolean
 * stateType String
 *
 * return null
 */
export async function getTaskData(
  dataType: TaskType,
  _reset: boolean,
  id?: string,
  stateType?: StateType,
) {
  const { taskData } = getRecoil(combinedSelector);
  const { abortController } = taskData[dataType];

  loadingTaskData(dataType, true, stateType);

  if (abortController) {
    abortController.abort();
  }

  const newAbort = new AbortController();
  setTaskAbortController(dataType, newAbort, stateType);

  const { taskFilters } = getStateTypeMain(stateType);

  const response = await getTasks(dataType, newAbort, id, taskFilters, stateType);

  const { projectData, userData, templateData } = getRecoil(combinedSelector);
  const currentId =
    stateType === 'TEMPLATE'
      ? templateData.currentTemplateId
      : stateType === 'PROJECT'
        ? projectData.currentProjectId
        : userData.currentUserId;

  if (currentId === id || !stateType) {
    if (!response?.success) {
      addTaskFetchError(response?.message, stateType);
      loadingTaskData(dataType, false, stateType);
    } else if (
      response.success &&
      response?.data &&
      response.data.tasks &&
      Array.isArray(response.data.tasks)
    ) {
      const finalData = sortData(parseData(response.data.tasks, stateType), 'sequence');

      if (taskFilters.tagFilter === null) {
        storeTagList(finalData, dataType, stateType);
      } else if (!stateType) {
        const { data, error } = getTagFilterListFromLocalStorage(stateType);
        if (data && !error) {
          const tagList: TaskTagListType = JSON.parse(data);
          if (tagList && tagList.FOCUS && tagList.BACKLOG && tagList.COMPLETED) {
            storeTagFilterList(sortStringData(tagList.FOCUS, true), 'FOCUS', stateType);
            storeTagFilterList(sortStringData(tagList.BACKLOG, true), 'BACKLOG', stateType);
            storeTagFilterList(sortStringData(tagList.COMPLETED, true), 'COMPLETED', stateType);
          }
        }
      }

      await addTaskSizeData(dataType, response.data.size, stateType);

      await addTaskData(dataType, finalData, stateType);

      loadingTaskData(dataType, false, stateType);
    }
  } else {
    log('well', currentId, id, stateType, dataType);
  }
}

export function updateTaskAbortControllerToken(
  dataType: TaskType,
  tokenType: AbortTokenType,
  index: number,
  token: AbortController,
  parentIndex?: number,
  stateType?: StateType,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  const targetData = getRecoil(targetStateAtom);
  if (targetData === null) return;
  let data = [...targetData];
  if (parentIndex !== undefined && data[parentIndex].subTasks !== undefined) {
    data = targetData.map((item, ind) => {
      if (ind === parentIndex) {
        return {
          ...item,
          subTasks: item.subTasks
            ? item.subTasks.map((subItem, idx) => {
                if (idx === index) {
                  return {
                    ...subItem,
                    [tokenType]: token,
                  };
                }
                return subItem;
              })
            : [],
        };
      }
      return item;
    });
  } else {
    data = targetData.map((item, ind) => {
      if (ind === index) {
        return {
          ...item,
          [tokenType]: token,
        };
      }
      return item;
    });
  }
  setRecoil(targetStateAtom, data);
}

/*
 * Save sub task sequence
 *
 * dateType String
 * startIndex Integer
 * endIndex Integer
 * parentId Integer
 * stateType String
 *
 * return null
 */
export async function saveSubTaskSequence(
  dataType: TaskType,
  startIndex: number,
  endIndex: number,
  parentId: string,
  stateType: StateType,
  sourceParentId: string,
  sourceDataType: TaskType,
) {
  // log('saveSubTaskSequence is called', dataType, startIndex, endIndex, parentId);
  const mainData = getStateTypeMain(stateType);
  const tempMainData = mainData[dataType];
  if (tempMainData === null) return;
  let data = [...tempMainData.data];
  const parentIndex = data && data.findIndex((item) => item.id === parentId);

  if (parentIndex === -1 || !data) return;

  const { subTasks } = data[parentIndex];
  let reOrderedSubTask: TaskObjectType[] = [];
  let newSequence = 500;

  if (parentId !== sourceParentId) {
    let { data: sourceData } = mainData[sourceDataType];
    const sourceIndex = sourceData && sourceData.findIndex((item) => item.id === sourceParentId);
    if (sourceIndex === -1 || !sourceData || sourceIndex === undefined) return;

    const { subTasks: sourceSubTasks } = sourceData[sourceIndex];

    if (!sourceSubTasks || !subTasks) return;
    const { source, destination } = moveSubTasks(sourceSubTasks, subTasks, startIndex, endIndex);

    reOrderedSubTask = destination;
    // get new sequence
    newSequence = getNewSequence(endIndex, reOrderedSubTask);
    reOrderedSubTask = reOrderedSubTask.map((item, ind) => {
      if (ind === endIndex) {
        return {
          ...item,
          sequence: newSequence,
          animation: 'REORDER_TASK',
        };
      }
      return item;
    });

    data = data.map((item, ind) => {
      if (sourceDataType === dataType && ind === sourceIndex)
        return {
          ...item,
          subTasks: source,
        };
      if (ind === parentIndex)
        return {
          ...item,
          subTasks: destination.map((subItem, sidx) => {
            if (
              sidx === endIndex &&
              ((!subItem.project && data[parentIndex].project) ||
                (subItem.project && !data[parentIndex].project) ||
                (subItem.project &&
                  data[parentIndex].project &&
                  subItem.project.id !== data[parentIndex].project.id))
            ) {
              return {
                ...subItem,
                project: data[parentIndex].project,
              };
            }
            return subItem;
          }),
        };
      return item;
    });
    if (sourceDataType !== dataType) {
      sourceData = sourceData.map((item, ind) => {
        if (ind === sourceIndex)
          return {
            ...item,
            subTasks: source,
          };
        return item;
      });
      addTaskData(sourceDataType, sourceData, stateType);
    }
  } else {
    if (!subTasks) return;

    reOrderedSubTask = reorderTasks(subTasks, startIndex, endIndex);

    // get new sequence
    newSequence = getNewSequence(endIndex, reOrderedSubTask);
    reOrderedSubTask = reOrderedSubTask.map((item, ind) => {
      if (ind === endIndex) {
        return {
          ...item,
          sequence: newSequence,
          animation: 'REORDER_TASK',
        };
      }
      return item;
    });
    data = data.map((item, ind) => {
      if (ind === parentIndex) {
        return {
          ...item,
          subTasks: reOrderedSubTask,
        };
      }
      return item;
    });
  }

  addTaskData(dataType, data, stateType);

  const task = reOrderedSubTask[endIndex];

  if (task.isTemp) return;

  task.sequenceController && task.sequenceController.abort();

  const newAbortSource = new AbortController();

  updateTaskAbortControllerToken(
    dataType,
    'sequenceController',
    endIndex,
    newAbortSource,
    parentIndex,
    stateType,
  );

  if (stateType === 'TEMPLATE') {
    let payload: any = {
      id: task.id,
      sequence: newSequence,
    };
    if (parentId !== sourceParentId) {
      payload = {
        ...payload,
        newParentId: parentId,
        oldParentId: sourceParentId,
      };
    } else {
      payload = {
        ...payload,
        taskType: dataType.toUpperCase(),
      };
    }
    updateTemplateTaskAPI(payload, newAbortSource);
  } else if (parentId !== sourceParentId) {
    await updateTaskParentAPI(task.id, newSequence, sourceParentId, parentId, newAbortSource);
    if (
      (!task.project && data[parentIndex].project) ||
      (task.project && !data[parentIndex].project) ||
      (task.project &&
        data[parentIndex].project &&
        task.project.id !== data[parentIndex].project.id)
    )
      updateTaskProjectFromAPI(
        data[parentIndex].project ? data[parentIndex].project.id : null,
        task.id,
        newAbortSource,
      );
  } else {
    updateTaskSequenceFromAPI(task.id, newSequence, dataType, parentId, newAbortSource, stateType);
  }
}

export function getTaskListData(
  stateType?: StateType,
  dataType?: TaskType,
): RecoilState<TaskObjectType[] | null> {
  if (stateType === 'PROJECT') {
    return dataType === 'FOCUS' ? projectFocusTaskDataState : projectBacklogTaskDataState;
  } else if (stateType === 'INDIVIDUAL') {
    return dataType === 'FOCUS' ? userFocusTaskDataState : userBacklogTaskDataState;
  } else if (stateType === 'TEMPLATE') {
    return dataType === 'FOCUS' ? templateFocusTaskDataState : templateBacklogTaskDataState;
  } else if (stateType === 'TUTORIAL') {
    return dataType === 'FOCUS' ? tutorialFocusTaskDataState : tutorialBacklogTaskDataState;
  } else if (stateType === 'RECURRING') {
    return dataType === 'FOCUS' ? recurringFocusTaskDataState : recurringBacklogTaskDataState;
  } else {
    return dataType === 'FOCUS' ? focusTaskDataState : backlogTaskDataState;
  }
}

export function getTaskSizeData(stateType?: StateType, dataType?: TaskType): RecoilState<number> {
  if (stateType === 'PROJECT') {
    return dataType === 'FOCUS' ? projectFocusTaskSizeState : projectBacklogTaskSizeState;
  } else if (stateType === 'INDIVIDUAL') {
    return dataType === 'FOCUS' ? userFocusTaskSizeState : userBacklogTaskSizeState;
  } else if (stateType === 'TEMPLATE') {
    return dataType === 'FOCUS' ? templateFocusTaskSizeState : templateBacklogTaskSizeState;
  } else if (stateType === 'TUTORIAL') {
    return dataType === 'FOCUS' ? tutorialFocusTaskSizeState : tutorialBacklogTaskSizeState;
  } else if (stateType === 'RECURRING') {
    return dataType === 'FOCUS' ? recurringFocusTaskSizeState : recurringBacklogTaskSizeState;
  } else {
    return dataType === 'FOCUS' ? focusTaskSizeState : backlogTaskSizeState;
  }
}

export function getTaskListLoading(
  stateType?: StateType,
  dataType?: TaskType,
): RecoilState<boolean> {
  if (stateType === 'PROJECT') {
    return dataType === 'FOCUS' ? projectFocusTaskLoadingState : projectBacklogTaskLoadingState;
  } else if (stateType === 'INDIVIDUAL') {
    return dataType === 'FOCUS' ? userFocusTaskLoadingState : userBacklogTaskLoadingState;
  } else if (stateType === 'TEMPLATE') {
    return dataType === 'FOCUS' ? templateFocusTaskLoadingState : templateBacklogTaskLoadingState;
  } else if (stateType === 'TUTORIAL') {
    return dataType === 'FOCUS' ? tutorialFocusTaskLoadingState : tutorialBacklogTaskLoadingState;
  } else if (stateType === 'RECURRING') {
    return dataType === 'FOCUS' ? recurringFocusTaskLoadingState : recurringBacklogTaskLoadingState;
  } else {
    return dataType === 'FOCUS' ? focusTaskLoadingState : backlogTaskLoadingState;
  }
}

export function getTaskListAbortController(
  stateType?: StateType,
  dataType?: TaskType,
): RecoilState<AbortController> {
  if (stateType === 'PROJECT') {
    return dataType === 'FOCUS' ? projectFocusTaskAbortState : projectBacklogTaskAbortState;
  } else if (stateType === 'INDIVIDUAL') {
    return dataType === 'FOCUS' ? userFocusTaskAbortState : userBacklogTaskAbortState;
  } else if (stateType === 'TEMPLATE') {
    return dataType === 'FOCUS' ? templateFocusTaskAbortState : templateBacklogTaskAbortState;
  } else if (stateType === 'TUTORIAL') {
    return dataType === 'FOCUS' ? tutorialFocusTaskAbortState : tutorialBacklogTaskAbortState;
  } else if (stateType === 'RECURRING') {
    return dataType === 'FOCUS' ? recurringFocusTaskAbortState : recurringBacklogTaskAbortState;
  } else {
    return dataType === 'FOCUS' ? focusTaskAbortState : backlogTaskAbortState;
  }
}

export function getTagUnfilteredListData(
  stateType?: StateType | null,
): RecoilState<TaskTagListType> {
  if (stateType === 'PROJECT') {
    return projectTaskUnfilteredListState;
  } else if (stateType === 'INDIVIDUAL') {
    return userTaskUnfilteredListState;
  } else if (stateType === 'TEMPLATE') {
    return templateTaskUnfilteredListState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialTaskUnfilteredListState;
  } else if (stateType === 'RECURRING') {
    return recurringTaskUnfilteredListState;
  } else {
    return taskUnfilteredListState;
  }
}

export function getTaskFilterData(stateType?: StateType) {
  if (stateType === 'PROJECT') {
    return projectTaskFiltersState;
  } else if (stateType === 'INDIVIDUAL') {
    return userTaskFiltersState;
  } else if (stateType === 'TEMPLATE') {
    return templateTaskFiltersState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialTaskFiltersState;
  } else if (stateType === 'RECURRING') {
    return recurringTaskFiltersState;
  } else {
    return taskFiltersState;
  }
}
/*
 * Update task size
 *
 * dataType String
 * index Integer
 * value String
 * stateType String
 *
 * returns Object
 */
export function updateTaskSize(
  index: number,
  value: SizeOptionsType,
  parentIndex: number | undefined,
  dataType: TaskType | undefined,
  stateType?: StateType,
) {
  const updateTaskSize = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index ? { ...task, size: value } : task;
  };

  updateTaskProperties(dataType ? dataType : 'FOCUS', updateTaskSize, parentIndex, stateType);
}

/*
 * Save task size
 *
 * sizeId Integer
 * dataType
 * index Integer
 * stateType String
 *
 * return null
 */
export function saveTaskSize(
  index: number,
  taskId: string,
  size: SizeOptionsType,
  parentIndex?: number,
  parentId?: string,
  dataType?: TaskType,
  stateType?: StateType,
) {
  const data: TaskObjectType[] | null =
    getStateTypeMain(stateType)[dataType ? dataType : 'FOCUS'].data;

  if (!data) return;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1) return;
    if (data[parentIndex].subTasks === undefined) return;
    index = data[parentIndex].subTasks!.findIndex((item) => item.id === taskId);
    if (index === -1)
      index =
        data && data.length > parentIndex && data[parentIndex] && data[parentIndex].subTasks
          ? data[parentIndex].subTasks!.findIndex((item) => item.tempId === taskId)
          : -1;
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === taskId);
    if (index === -1) index = data && data.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  }

  updateTaskSize(index, size, parentIndex, dataType, stateType);

  let task: TaskObjectType;
  // let id;
  let parent;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks &&
    data[parentIndex].subTasks![index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
    // id = parent.subTasks[index].id;
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
    // id = task.id;
  } else return;

  if (task.isTemp) return;

  task.sizeController && task.sizeController.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType ? dataType : 'BACKLOG',
    'sizeController',
    index,
    newAbortSource,
    parentIndex,
    stateType,
  );

  if (stateType === 'TEMPLATE') {
    log('updateTemplateTaskAPI', size, taskId, task.tempId);
    const payload = {
      id: task.id,
      size: size,
      tempId: task.tempId,
    };
    updateTemplateTaskAPI(payload, newAbortSource);
  } else {
    updateTaskSizeFromAPI(size, task.id, newAbortSource);
  }
}

export function saveTasksSize(
  focusArrTaskId: string[],
  backlogArrTaskId: string[],
  size: SizeOptionsType,
  stateType: StateType,
) {
  const arrTaskId = [...focusArrTaskId, ...backlogArrTaskId];
  const focus = getStateTypeMain(stateType).FOCUS.data;
  const backlog = getStateTypeMain(stateType).FOCUS.data;

  const newAbortSource = new AbortController();

  if (focus !== null) {
    focusArrTaskId.forEach((taskId) => {
      let index = focus && focus.findIndex((item) => item.id === taskId);
      if (index === -1) index = focus && focus.findIndex((item) => item.tempId === taskId);
      if (index === -1 || index === null) return;

      updateTaskSize(index, size, undefined, 'FOCUS', stateType);
      const task = focus![index];
      if (task.isTemp) return;

      task.sizeController && task.sizeController.abort();

      updateTaskAbortControllerToken(
        'FOCUS',
        'sizeController',
        index,
        newAbortSource,
        undefined,
        stateType,
      );
    });
  }
  if (backlog !== null) {
    backlogArrTaskId.forEach((taskId) => {
      let index = backlog && backlog.findIndex((item) => item.id === taskId);
      if (index === -1) index = backlog && backlog.findIndex((item) => item.tempId === taskId);
      if (index === -1 || index === null) return;

      updateTaskSize(index, size, undefined, 'BACKLOG', stateType);
      const task = backlog![index];
      if (task.isTemp) return;

      task.sizeController && task.sizeController.abort();

      updateTaskAbortControllerToken(
        'BACKLOG',
        'sizeController',
        index,
        newAbortSource,
        undefined,
        stateType,
      );
    });
  }
  if (stateType === 'TEMPLATE') {
    // log('updateTemplateTaskAPI', size, taskId, task.tempId);
    // let payload = {
    //   id: taskId,
    //   size: size,
    //   tempId: task.tempId,
    // };
    // updateTemplateTaskAPI(payload, newAbortSource);
  } else {
    bulkUpdateSizeFromAPI(size, arrTaskId, newAbortSource);
  }
  resetSelectedTasks(stateType);
}

function resetSelectedTasks(stateType: StateType) {
  updateLastSelectedDataType(null, stateType);
  updateSelectedTasks('FOCUS', [], [], stateType);
  updateSelectedTasks('BACKLOG', [], [], stateType);
}

export async function addTopbarTask(
  taskValue: string,
  dataType: TaskType,
  projectId: string | null,
  sizeId: SizeOptionsType,
  assigneeId: string | null,
  tags: string[],
  stateType?: StateType,
  showUpgradeModal?: () => void,
) {
  log('addTopbarTask', taskValue, dataType, projectId, sizeId, assigneeId, stateType);

  const mainData = getStateTypeMain(stateType);
  let data: TaskObjectType[] | null = mainData[dataType ? dataType : 'FOCUS'].data;
  if (projectId === null) {
    if (stateType === 'PROJECT') projectId = getRecoil(currentProjectIdState);
  }
  const newSequence = data && data.length ? data[0].sequence + SEQUENCE_LARGE : SEQUENCE_LARGE;
  const tempId = randomNumber() + '';

  let newTask = createNewTask(
    taskValue.trim(),
    tempId,
    newSequence,
    dataType,
    projectId,
    sizeId,
    assigneeId,
    stateType,
  );

  const payload = {
    assigneeId: assigneeId,
    name: taskValue.trim(),
    parentTaskId: 0,
    taskType: dataType.toUpperCase(), // Default is BACKLOG
    [stateType === 'TEMPLATE' ? 'projectTemplateId' : 'projectId']: projectId,
    size: sizeId,
    tagIds: tags,
    tempId: tempId,
  };

  if (stateType !== 'TEMPLATE' && stateType !== 'RECURRING') {
    payload.taskStatusId = 1; // fixed value
  }

  if (!itemShouldBeFilteredOutBeforeStoring(mainData, newTask, stateType)) {
    if (stateType === 'PROJECT' && projectId !== getRecoil(currentProjectIdState)) {
      // task is created on a project page but added to a seperate project so no update required here
    } else {
      if (
        (stateType === 'USER' || stateType === undefined || stateType === null) &&
        assigneeId !== getUserId()
      ) {
        // task is created and assigned for some other user so no need to add in list of current user
      } else {
        newTask = { ...newTask, animation: 'ADD_TASK_NEW' };
        if (data) data = [newTask, ...data];
        else data = [newTask];

        const { triggerData, navigationData } = getRecoil(combinedSelector);
        let playSound = false;

        if (!(typeof stateType === 'undefined') && triggerData.soundTriggers.createTask)
          playSound = true;
        else if (
          typeof stateType === 'undefined' &&
          isHomeScreen(navigationData.currentRoute) &&
          triggerData.soundTriggers.createTask
        )
          playSound = true;

        if (playSound && triggerData.soundTriggers.createTask) {
          log('play sound from event', stateType);
          playTheSound(triggerData.soundTriggers.createTask);
        }
        await addTaskData(dataType, data, stateType);
      }
    }
  }

  const response = await createNewTaskAPI(payload, stateType === 'TEMPLATE');
  if (response.success && response.data) {
    if (stateType === 'PROJECT' && projectId !== getRecoil(currentProjectIdState)) {
      hasTaskUpdated(tempId, dataType, response.data, projectId ? undefined : stateType);
    } else {
      hasTaskUpdated(tempId, dataType, response.data, stateType);
    }
  } else if (response.status === 402) {
    removeTask(tempId, dataType, '', stateType);
    showUpgradeModal && showUpgradeModal();
  }
}

/*
 * Has task udpated
 *
 * taskTempId String
 * dataType String
 * data Object
 * stateType String
 *
 * return null
 */
export function hasTaskUpdated(
  taskTempId: string,
  dataType: TaskType,
  data: ResponseTaskInterface,
  stateType: StateType | null,
) {
  const taskData = getStateTypeMain(stateType);
  let newDataType = dataType;

  let index =
    taskData[newDataType].data &&
    taskData[newDataType].data?.findIndex((item) => item.id === taskTempId);
  let task: TaskObjectType | null = null;

  // found in current type
  if (
    index !== -1 &&
    index !== undefined &&
    index !== null &&
    taskData[newDataType] !== null &&
    taskData[newDataType].data !== null
  ) {
    task = taskData[newDataType].data![index];
  } else {
    newDataType = getOtherDataType(newDataType);
    index =
      taskData[newDataType].data &&
      taskData[newDataType].data!.findIndex((item) => item.id === taskTempId);
    if (index && index !== -1)
      task = taskData[newDataType].data ? taskData[newDataType].data![index] : null;
  }

  if (task === null || index === -1 || index === null) return;

  // set task status as live
  setTaskLive(
    newDataType,
    index,
    data.id,
    undefined,
    data.taskNumber,
    stateType,
    parseTask(data, stateType, false),
  );
}

export function hasSubTaskUpdated(parentId, taskTempId, dataType, data, stateType) {
  const taskData = getStateTypeMain(stateType);
  let newDataType = dataType;

  let parentIndex =
    taskData[newDataType].data &&
    taskData[newDataType].data.findIndex((item) => item.id === parentId);
  let parent: TaskObjectType | null = null;

  // found in current type
  if (parentIndex !== -1) {
    parent = taskData[newDataType].data[parentIndex];
  } else {
    newDataType = getOtherDataType(newDataType);
    parentIndex =
      taskData[newDataType].data &&
      taskData[newDataType].data.findIndex((item) => item.id === parentId);
    if (parentIndex !== -1) parent = taskData[newDataType].data[parentIndex];
  }

  if (parent === null || parentIndex === -1) return;

  const index = parent.subTasks && parent.subTasks.findIndex((item) => item.id === taskTempId);

  if (index === -1 || index === undefined) return;
  // let task = parent.subTasks[index];

  // set task status as live
  setTaskLive(
    newDataType,
    index,
    data.id,
    parentIndex,
    data.taskNumber,
    stateType,
    parseTask(data, stateType, false),
  );
}

export function setTaskLive(
  dataType: TaskType,
  index: number,
  id: string,
  parentIndex: number | undefined,
  taskNumber: number | undefined,
  stateType: StateType | null,
  data: TaskObjectType,
) {
  const targetStateAtom = getTaskListData(stateType ? stateType : undefined, dataType);
  const targetData = getRecoil(targetStateAtom);
  if (targetData === null) return;
  let tempData: TaskObjectType[] = [];
  if (parentIndex !== undefined && targetData[parentIndex].subTasks !== undefined) {
    tempData = targetData.map((item, indx) => {
      if (indx === parentIndex && item.subTasks) {
        return {
          ...item,
          subTasks: item.subTasks.map((subItem, idx) => {
            if (idx === index) {
              return {
                ...subItem,
                createdUser: data.createdUser,
                createdDate: data.createdDate,
                assignee: data.assignee,
                project: data.project,
                isTemp: false,
                id: id,
                taskNumber: taskNumber,
                tags: data.tags,
                tagInfo: data.tagInfo,
                sequence: data.sequence,
              };
            } else return subItem;
          }),
        };
      } else return item;
    });
  } else {
    tempData = targetData.map((item, indx) => {
      if (indx === index) {
        return {
          ...item,
          createdUser: data.createdUser,
          createdDate: data.createdDate,
          assignee: data.assignee,
          project: data.project,
          isTemp: false,
          id: id,
          taskNumber: taskNumber,
          tags: data.tags,
          tagInfo: data.tagInfo,
          sequence: data.sequence,
        };
      } else return item;
    });
  }
  setRecoil(targetStateAtom, tempData);
}

export function setTaskFilter(
  key: TaskFiltersType,
  value: string | string[] | null,
  stateType?: StateType | '',
) {
  const targetStateAtom = getTaskFilterData(stateType === '' ? undefined : stateType);
  const targetData = getRecoil(targetStateAtom);
  const tempData = {
    ...targetData,
    [key]: value,
  };
  setRecoil(targetStateAtom, tempData);
}

export function resetOpenTasks(stateType?: StateType) {
  const currentUserId = getRecoil(currentUserIdState);
  const userId = stateType === 'INDIVIDUAL' && currentUserId ? currentUserId : getUserId();
  const projectId = getRecoil(currentProjectIdState);
  const templateId = getRecoil(currentTemplateIdState);
  getTaskData(
    'FOCUS',
    true,
    stateType === 'TEMPLATE' && templateId
      ? templateId
      : stateType === 'PROJECT' && projectId
        ? projectId
        : userId,
    stateType,
  );
  getTaskData(
    'BACKLOG',
    true,
    stateType === 'TEMPLATE' && templateId
      ? templateId
      : stateType === 'PROJECT' && projectId
        ? projectId
        : userId,
    stateType,
  );
}

export function resetCompletedTasks(stateType: StateType | null, id: string | null) {
  const userId = getUserId();
  const projectId = getRecoil(currentProjectIdState);

  getCompletedTasks(
    null,
    true,
    stateType === 'PROJECT' && projectId
      ? projectId
      : stateType === 'INDIVIDUAL' && id
        ? id
        : userId,
    stateType,
  );
}

export function updateTaskFilter(
  key: TaskFiltersType,
  value: string | string[] | null,
  stateType: StateType,
  id: string | null,
) {
  setTaskFilter(key, value, stateType);
  if (stateType === 'RECURRING') {
    getRecurringTasks();
  } else {
    resetOpenTasks(stateType);
    if (stateType !== 'TEMPLATE') {
      resetCompletedTasks(stateType, id);
    }
  }

  if (value && typeof value === 'string') setFilterInLocalStorage(key, value, stateType, id);
  else removeFilterFromLocalStorage(key, stateType, id);
  if (key === 'tagFilter') {
    const unFilteredList = getRecoil(taskUnfilteredListState);
    if (value) setTagFilterListInLocalStorage(stateType, JSON.stringify(unFilteredList));
    else removeTagFilterListFromLocalStorage(stateType);
  }
}

export function getCompletedTaskState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTasksState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTasksState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTasksState;
  } else {
    return completedTasksState;
  }
}

export function getCompletedTaskDataState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskDataState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskDataState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskDataState;
  } else {
    return completedTaskDataState;
  }
}

export function getCompletedTaskHasMoreState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskHasMoreState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskHasMoreState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskHasMoreState;
  } else {
    return completedTaskHasMoreState;
  }
}

export function getCompletedTaskPageState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskPageState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskPageState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskPageState;
  } else {
    return completedTaskPageState;
  }
}

export function getCompletedTaskPointsState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskPointsState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskPointsState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskPointsState;
  } else {
    return completedTaskPointsState;
  }
}

export function getCompletedTaskSizeState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskSizeState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskSizeState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskSizeState;
  } else {
    return completedTaskSizeState;
  }
}

export function getCompletedTaskLoadingState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskLoadingState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskLoadingState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskLoadingState;
  } else {
    return completedTaskLoadingState;
  }
}

export function getCompletedTaskFilterState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskFilterState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskFilterState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskFilterState;
  } else {
    return completedTaskFilterState;
  }
}

export function getCompletedTaskAbortControllerState(stateType?: StateType | null) {
  if (stateType === 'PROJECT') {
    return projectCompletedTaskAbortControllerState;
  } else if (stateType === 'INDIVIDUAL') {
    return userCompletedTaskAbortControllerState;
  } else if (stateType === 'TUTORIAL') {
    return tutorialCompletedTaskAbortControllerState;
  } else {
    return completedTaskAbortControllerState;
  }
}

export function setCompletedTaskFilter(
  filter: DateFilterType | string | null,
  stateType?: StateType | '' | null,
) {
  const targetStateAtom = getCompletedTaskFilterState(stateType === '' ? null : stateType);
  setRecoil(targetStateAtom, filter ? filter : 'today');
}

/*
 * Set completed task abort signal
 * token axios.CancelToken
 *
 * returns Object
 */
export function setCompletedTaskAbortSignal(
  abortSignal: AbortController,
  stateType?: StateType | null,
) {
  const targetStateAtom = getCompletedTaskAbortControllerState(stateType);
  setRecoil(targetStateAtom, abortSignal);
}

/*
 * Set completed task loading state
 * isLoading Boolean
 *
 * returns Object
 */
export function setCompletedTaskLoadingState(isLoading: boolean, stateType?: StateType | null) {
  const targetStateAtom = getCompletedTaskLoadingState(stateType);

  setRecoil(targetStateAtom, isLoading);
}

/*
 * Merge old data with new data, after checking if driver.id does not exist already
 * oldData Array
 * newData Array
 *
 * return Array
 */
function mergeData(
  oldData: TaskObjectType[],
  newData: ResponseTaskInterface[],
  stateType?: StateType | null,
) {
  const tempData = [...oldData];
  newData.forEach((newDat, _index) => {
    if (newDat.id) {
      const index = oldData.findIndex((data) => data.id === newDat.id);

      if (index === -1) tempData.push(parseTask(newDat, stateType, true));
    }
  });
  return tempData;
}

const storeTagList = (
  taskList: TaskObjectType[],
  dataType: TaskStatusType | TaskType,
  stateType?: StateType | null,
) => {
  if (taskList === null || taskList.length === 0) return [];

  const unFilteredList: TagObjectType[] = [];
  taskList.map((item) => {
    if (item.subTasks) {
      item.subTasks.map((subtaskItem) => {
        subtaskItem.tags?.map((tag) => {
          unFilteredList.push(tag);
        });
      });
    }
    item.tags?.map((tag) => {
      unFilteredList.push(tag);
    });
  });
  storeTagFilterList(
    sortStringData(unFilteredList, true),
    dataType === 'OPEN' ? 'BACKLOG' : dataType,
    stateType,
  );
};

export async function getCompletedTasks(
  filter: DateFilterType | string | undefined | null,
  reset: boolean,
  id: string | undefined,
  stateType?: StateType | null,
  custom?: boolean,
) {
  if (stateType === 'TEMPLATE') return;
  let filter2;

  if (id === undefined) {
    if (stateType === 'PROJECT') {
      const currentId = getRecoil(currentProjectIdState);
      id = currentId ? currentId : undefined;
    } else if (stateType === 'INDIVIDUAL') {
      const currentId = getRecoil(currentUserIdState);
      id = currentId ? currentId : undefined;
    }
  }

  // set filter in redux
  if (filter) {
    setFilterInLocalStorage('completedTaskFilter', filter, stateType);
    setCompletedTaskFilter(filter, stateType);
  } else {
    const { data } = getFilterFromLocalStorage(
      'completedTaskFilter',
      stateType ? stateType : undefined,
    );
    if (data) {
      filter = data;
    } else {
      const mainData = getStateTypeMain(stateType) as
        | TaskDataType
        | ProjectDataType
        | UserDataType
        | TutorialDataType;
      filter = mainData.COMPLETED.filter;
    }
  }

  const { newCustom, newFilter, newFilterArr } = checkIfItsACustomFilter(filter);
  custom = newCustom;

  if (custom && newFilterArr) {
    filter = newFilterArr[0];
    filter2 = newFilterArr[1];
  } else filter = newFilter;

  if (reset) {
    const mainData = getStateTypeMain(stateType) as
      | TaskDataType
      | ProjectDataType
      | UserDataType
      | TutorialDataType;
    const { COMPLETED } = mainData;
    const abortController = COMPLETED.abortController;
    abortController.abort();

    const newAbortSource = new AbortController();
    setCompletedTaskAbortSignal(newAbortSource, stateType);

    addCompletedTasks([], true, 0, 0, null, stateType);
  }

  setCompletedTaskLoadingState(true, stateType);

  const { COMPLETED, taskFilters } = getStateTypeMain(stateType) as
    | TaskDataType
    | UserDataType
    | TutorialDataType
    | ProjectDataType;

  let { page } = COMPLETED;
  const { limit, points, size, abortController } = COMPLETED;

  if (reset) page = 0;

  log('okay 890', filter, filter2);

  if (custom) {
    filter = formatDateForAPI(moment(filter, 'MM-DD-YYYY'));
    filter2 = formatDateForAPI(moment(filter2, 'MM-DD-YYYY'));
  }

  const response = await getCompletedTasksFromAPI(
    filter,
    page,
    limit,
    reset ? new AbortController() : abortController,
    id,
    custom,
    filter2,
    taskFilters,
    stateType,
  );

  if (response && response.success && response.data && Array.isArray(response.data.tasks)) {
    const { data } = response;
    const newPoints = data.points ? data.points : 0;
    let mergedData = mergeData(COMPLETED.data, data.tasks, stateType);

    if (page === 0) mergedData = mergeData([], data.tasks, stateType);
    if (taskFilters.projectFilter === null) {
      storeTagList(mergedData, 'COMPLETED', stateType);
    }
    addCompletedTasks(
      mergedData,
      data.tasks.length === 0 ? false : true,
      page + 1,
      page === 0 ? newPoints : points + newPoints,
      data.tasks.length === 0 && page > 0
        ? size
          ? size
          : 0
        : response.data.size
          ? response.data.size
          : 0,
      stateType,
    );
    if (response.data.tasks.length === 0) {
      completedTaskHasNoMore(stateType);
    }
  } else {
    completedTaskHasNoMore(stateType);
  }
  setCompletedTaskLoadingState(false, stateType);
}

/*
 * Set if there is no more data
 *
 * returns Object
 */
export function completedTaskHasNoMore(stateType?: StateType | null) {
  const targetStateAtom = getCompletedTaskHasMoreState(stateType);
  setRecoil(targetStateAtom, false);
}

export function updateDueDate(
  dataType: TaskType,
  index: number,
  dueDateValue: Date,
  parentIndex?: number,
  stateType?: StateType,
) {
  const updateTaskDueDate = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index ? { ...task, dueDate: dueDateValue } : task;
  };
  updateTaskProperties(dataType, updateTaskDueDate, parentIndex, stateType);
}

export function saveDueDate(
  dataType: TaskType,
  index: number,
  taskId: string,
  dueDate: Date,
  parentIndex?: number,
  parentId?: string,
  stateType?: StateType,
) {
  const mainData = getStateTypeMain(stateType);
  const { data } = mainData[dataType];
  if (parentId) {
    parentIndex = data ? data.findIndex((item) => item.id === parentId) : -1;
    if (parentIndex === -1)
      parentIndex = data ? data.findIndex((item) => item.tempId === parentId) : -1;
    if (parentIndex === -1 || parentIndex === null || parentIndex === undefined) return;

    if (data === null || data[parentIndex].subTasks === undefined) return;
    index = data[parentIndex].subTasks!.findIndex((item) => item.id === taskId);
    if (index === -1)
      index = data[parentIndex].subTasks!.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data ? data.findIndex((item) => item.id === taskId) : -1;
    if (index === -1) index = data ? data.findIndex((item) => item.tempId === taskId) : -1;
    if (index === -1) return;
  }
  updateDueDate(dataType, index, dueDate, parentIndex, stateType);

  let task: TaskObjectType;
  let parent;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks &&
    data[parentIndex].subTasks![index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
  } else return;

  if (task.isTemp) return;

  task.dueDateController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'dueDateController',
    index,
    newAbortSource,
    parentIndex,
    stateType,
  );

  updateTaskDates(task.id, { duedate: dueDate ? formatDateForAPI(dueDate) : null }, newAbortSource);
}

export function updateStartEndDates(
  dataType: TaskType,
  index: number,
  startDateValue: Date | null,
  endDateValue: Date | null,
  parentIndex?: number,
  stateType?: StateType,
) {
  const updateTaskStartEndDates = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? { ...task, startDate: startDateValue, endDate: endDateValue }
      : task;
  };

  updateTaskProperties(dataType, updateTaskStartEndDates, parentIndex, stateType);
}

export function saveTaskStartEndDates(
  dataType: TaskType,
  index: number,
  taskId: string,
  startDate: Date | null,
  endDate: Date | null,
  parentIndex?: number,
  parentId?: string,
  stateType?: StateType,
) {
  const mainData = getStateTypeMain(stateType);
  const { data } = mainData[dataType];
  if (data === null) return;
  if (parentId) {
    parentIndex = data ? data.findIndex((item) => item.id === parentId) : -1;
    if (parentIndex === -1)
      parentIndex = data ? data.findIndex((item) => item.tempId === parentId) : -1;
    if (parentIndex === -1 || data[parentIndex].subTasks === undefined) return;

    index = data[parentIndex].subTasks!.findIndex((item) => item.id === taskId);
    if (index === -1)
      index = data[parentIndex].subTasks!.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data ? data.findIndex((item) => item.id === taskId) : -1;
    if (index === -1) index = data ? data.findIndex((item) => item.tempId === taskId) : -1;
    if (index === -1) return;
  }

  updateStartEndDates(dataType, index, startDate, endDate, parentIndex, stateType);

  let task: TaskObjectType;
  let parent;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks &&
    data[parentIndex].subTasks![index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
  } else return;

  if (task.isTemp) return;

  if ((!startDate && endDate) || (startDate && !endDate)) return;

  task.startEndDateController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'startEndDateController',
    index,
    newAbortSource,
    parentIndex,
    stateType,
  );

  updateTaskDates(
    task.id,
    {
      startdate: startDate ? formatDateForAPI(startDate) : null,
      enddate: endDate ? formatDateForAPI(endDate) : null,
    },
    newAbortSource,
  );
}

export function setTaskStatus(
  status: boolean,
  index: number,
  dataType: TaskType,
  task?: TaskObjectType,
  parentIndex?: number,
  stateType?: StateType,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  const targetData = getRecoil(targetStateAtom);

  if (targetData === null) return;
  let tempData: TaskObjectType[];
  if (parentIndex !== undefined && targetData[parentIndex].subTasks !== undefined) {
    tempData = targetData.map((item, indx) => {
      if (indx === parentIndex && item.subTasks) {
        return {
          ...item,
          subTasks: item.subTasks.map((subItem, idx) => {
            if (idx === index) {
              return {
                ...subItem,
                checked: status,
                modifiedDate: task ? task.modifiedDate : subItem.modifiedDate,
                modifiedUser: task ? task.modifiedUser : subItem.modifiedUser,
              };
            } else return subItem;
          }),
        };
      } else return item;
    });
  } else {
    tempData = targetData.map((item, indx) => {
      if (indx === index) {
        if (status) return { ...item, checked: status, animation: 'COMPLETE_TASK' };
        else return { ...item, checked: status };
      } else return item;
    });
  }
  setRecoil(targetStateAtom, tempData);
}

export function checkTask(
  index: number,
  id: string,
  dataType: TaskType,
  parentIndex: number | undefined,
  parentId: string | undefined,
  noApiCall: boolean | undefined,
  stateType: StateType,
  checked?: boolean,
  task?: TaskObjectType,
) {
  log('checkTask', index, id, dataType, parentIndex, parentId, noApiCall, stateType, checked);

  checked = typeof checked !== 'undefined' ? checked : true;
  let tempId = '';

  const mainData = getStateTypeMain(stateType);
  const { data } = mainData[dataType];

  if (data === null) return;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1 || data[parentIndex].subTasks === undefined) return;

    index = data[parentIndex].subTasks!.findIndex((item) => item.id === id);
    if (index === -1) index = data[parentIndex].subTasks!.findIndex((item) => item.tempId === id);
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === id);
    if (index === -1) return;

    tempId = data[index].tempId ? data[index].tempId! : '';
  }

  setTaskStatus(checked, index, dataType, task, parentIndex, stateType);

  const triggerData = getRecoil(triggerDataSelector);

  const isSubtask = parentId ? true : false;
  if (!noApiCall) {
    if (!isSubtask) {
      if (triggerData.soundTriggers.completeTask) {
        playTheSound(triggerData.soundTriggers.completeTask);
      }

      if (triggerData.canvasTriggers.completeTask) {
        runCanvasAnimation(triggerData.canvasTriggers.completeTask, { tempId });
      }

      if (triggerData.riveTriggers.completeTask) {
        playTheRiveCanvas(
          triggerData.riveTriggers.completeTask,
          { tempId },
          triggerData.riveCompleteTaskDelay,
        );
      }

      setTimeout(() => {
        if (triggerData.lottieTriggers.completeTask) {
          playTheLottie(
            triggerData.lottieTriggers.completeTask,
            triggerData.lottieCompleteTaskDelay,
          );
        }
      }, 0);
    }
  }

  setTimeout(
    async () => {
      if (!parentId) removeTask(id, dataType, parentId, stateType);

      if (!noApiCall) {
        await updateTaskStatus(id, checked ? 'COMPLETED' : 'OPEN');

        getCompletedTasks(null, true, undefined, stateType);
      }
    },
    isSubtask ? 0 : getCompleteTaskDelay(),
  );
}

export function removeTask(
  id: string,
  dataType: TaskType,
  parentId?: string,
  stateType?: StateType,
) {
  const mainData = getStateTypeMain(stateType);
  const { data } = mainData[dataType];
  if (data === null) return;
  const tempData = [...data];
  if (parentId) {
    const parentIndex = tempData.findIndex((item) => item.id === parentId);
    if (parentIndex === -1) return;
  }
  if (parentId) {
    let parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1 || data[parentIndex].subTasks === undefined) return;

    let index = data[parentIndex].subTasks!.findIndex((item) => item.id === id);
    if (index === -1) index = data[parentIndex].subTasks!.findIndex((item) => item.tempId === id);
    if (index === -1) return;
    tempData[parentIndex] = {
      ...tempData[parentIndex],
      subTasks: tempData[parentIndex].subTasks.filter((item, ind) => {
        return ind !== index;
      }),
    };
  } else {
    const index = data && data.findIndex((item) => item.id === id);
    if (index === -1) return;

    tempData.splice(index, 1);
  }
  // if (typeof parentIndex !== 'undefined' && tempData[parentIndex].subTasks !== undefined) {
  //   tempData[parentIndex] = {
  //     ...tempData[parentIndex],
  //     subTasks: tempData[parentIndex].subTasks.filter((item, ind) => { return ind !== index })
  //   }
  // } else {
  //   tempData.splice(index, 1);
  // }

  // log('removeTask', index, dataType, parentIndex, stateType, tempData);

  addTaskData(dataType, tempData, stateType);
}

export function deleteTask(
  index: number,
  id: string,
  dataType: TaskType,
  parentIndex?: number,
  parentId?: string,
  stateType?: StateType,
) {
  log('remove task, index, id dataType');

  removeTask(id, dataType, parentId, stateType);

  deleteTaskAPI(id, stateType === 'TEMPLATE');
}

export function updateTaskName(
  dataType: TaskType,
  index: number,
  value: string,
  field: string,
  parentIndex: number | undefined,
  update: boolean,
  stateType?: StateType,
) {
  const updateTaskAssigneeData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? update
        ? { ...task, [field]: value, updateValue: +new Date() }
        : { ...task, [field]: value }
      : task;
  };

  updateTaskProperties(dataType, updateTaskAssigneeData, parentIndex, stateType);
}

export function saveTaskName(
  newName: string,
  dataType: TaskType,
  index: number,
  field: string,
  parentIndex: number | undefined,
  _parentId: string | undefined,
  update: boolean,
  instant: boolean | undefined,
  stateType?: StateType,
) {
  const mainData = getStateTypeMain(stateType);
  const { data } = mainData[dataType];
  let task: TaskObjectType;
  let id;
  let tempId;
  let parent;
  let name;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks &&
    data[parentIndex].subTasks![index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
    id = parent.subTasks[index].id;
    name = parent.subTasks[index].name;
    tempId = parent.subTasks[index].tempId;
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
    id = task.id;
    name = task.name;
    tempId = task.tempId;
  } else return;

  if (task.isTemp || field === 'tempName') return;

  log('saveTaskName', name, newName, name === newName);

  if (name === newName) return;

  updateTaskName(dataType, index, newName, field, parentIndex, update, stateType);

  if (task.abortController) task.abortController.abort();

  const currentAbortController = new AbortController();
  instant
    ? saveTaskNameInBackend(newName, id, tempId, stateType, currentAbortController)
    : saveTaskNameInBackendWithDebounce(newName, id, tempId, stateType, currentAbortController);
}

const saveTaskNameInBackend = (
  name: string,
  id: string,
  tempId: string,
  stateType: StateType | undefined,
  currentAbortController: AbortController,
) => {
  log('saveTaskNameInBackend', name);

  if (stateType === 'TEMPLATE') {
    log('updateTemplateTaskAPI', name, id, tempId);
    const payload = {
      id: id,
      name: name,
      tempId: tempId,
    };
    updateTemplateTaskAPI(payload, currentAbortController);
  } else {
    updateTaskValueFromAPI(name, id, tempId, currentAbortController);
  }
};

const saveTaskNameInBackendWithDebounce = _.debounce(
  (
    name: string,
    id: string,
    tempId: string,
    stateType: StateType | undefined,
    currentAbortController: AbortController,
  ) => {
    log('saveTaskNameInBackendWithDebounce', name);
    if (stateType === 'TEMPLATE') {
      log('updateTemplateTaskAPI', name, id, tempId);
      const payload = {
        id: id,
        name: name,
        tempId: tempId,
      };
      updateTemplateTaskAPI(payload, currentAbortController);
    } else {
      updateTaskValueFromAPI(name, id, tempId, currentAbortController);
    }
  },
  TASK_INPUT_WAIT_INTERVAL,
  false,
);

/*
 * Update task project
 * dataType TaskType
 * index Integer
 * value String
 * stateType String
 * taskNumber Integer
 * returns Object
 */
export function updateTaskProject(
  dataType: TaskType,
  index: number,
  value: string | null,
  parentIndex: number | undefined,
  stateType: StateType,
  taskNumber?: number,
) {
  const updateTaskProjectData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? taskNumber
        ? {
            ...task,
            project: value ? { id: value } : null,
            taskNumber: taskNumber,
          }
        : {
            ...task,
            project: value ? { id: value } : null,
            taskNumber: taskNumber,
          }
      : task;
  };

  updateTaskProperties(dataType, updateTaskProjectData, parentIndex, stateType);
}

/*
 * Save task project
 *
 * projectId Integer
 * dataType
 * index Integer
 * stateType String
 *
 * return null
 */
export async function saveTaskProject(
  index: number,
  taskId: string,
  projectId: string | null,
  parentIndex: number | undefined,
  parentId: string | undefined,
  dataType: TaskType,
  stateType?: StateType,
  taskName?: string,
) {
  const mainData = getStateTypeMain(stateType);
  const { data } = mainData[dataType];

  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1) return;

    index = data[parentIndex].subTasks.findIndex((item) => item.id === taskId);
    if (index === -1)
      index = data[parentIndex].subTasks.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === taskId);
    if (index === -1) index = data && data.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  }

  updateTaskProject(dataType, index, projectId, parentIndex, stateType);

  let task: TaskObjectType;
  // let id;
  let parent;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks[index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
    // id = parent.subTasks[index].id;
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
    // id = task.id;
  } else return;

  if (task.isTemp) return;

  task.projectController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'projectController',
    index,
    newAbortSource,
    parentIndex,
    stateType,
  );

  await updateTaskProjectFromAPI(projectId, task.id, newAbortSource, taskName);
}

export async function saveTasksProject(
  focusArrTaskId: string[],
  backlogArrTaskId: string[],
  projectId: string | null,
  stateType: StateType,
) {
  const arrTaskId = [...focusArrTaskId, ...backlogArrTaskId];

  const mainData = getStateTypeMain(stateType);
  const focus = mainData.FOCUS.data;
  const backlog = mainData.BACKLOG.data;

  const newAbortSource = new AbortController();

  focusArrTaskId.forEach((taskId) => {
    let index = focus && focus.findIndex((item) => item.id === taskId);
    if (index === -1) index = focus && focus.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null) return;

    updateTaskProject('FOCUS', index, projectId, undefined, stateType);

    if (focus === null) return;
    const task = focus[index];
    if (task.isTemp) return;

    task.projectController?.abort();

    updateTaskAbortControllerToken(
      'FOCUS',
      'projectController',
      index,
      newAbortSource,
      undefined,
      stateType,
    );
  });

  backlogArrTaskId.forEach((taskId) => {
    let index = backlog && backlog.findIndex((item) => item.id === taskId);
    if (index === -1) index = backlog && backlog.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null) return;

    updateTaskProject('BACKLOG', index, projectId, undefined, stateType);
    if (backlog === null) return;
    const task = backlog[index];
    if (task.isTemp) return;

    task.projectController?.abort();

    updateTaskAbortControllerToken(
      'BACKLOG',
      'projectController',
      index,
      newAbortSource,
      undefined,
      stateType,
    );
  });

  await bulkUpdateProjectFromAPI(projectId, arrTaskId, newAbortSource);
  resetSelectedTasks(stateType);
}

export function resetTaskData(dataType: TaskType, stateType: StateType) {
  addTaskFetchError(null, stateType);
  loadingTaskData(dataType, true, stateType);
  if (stateType === 'PROJECT' || stateType === 'INDIVIDUAL' || stateType === 'TEMPLATE')
    addTaskData(dataType, [], stateType);
  else addTaskData(dataType, null, stateType);
}

export function setAutoFocusItem(item: string | null, stateType?: StateType) {
  switch (stateType) {
    case 'PROJECT':
      setRecoil(projectAutoFocusState, item);
      break;
    case 'INDIVIDUAL':
      setRecoil(userAutoFocusState, item);
      break;
    case 'TUTORIAL':
      setRecoil(tutorialAutoFocusState, item);
      break;
    case 'RECURRING':
      setRecoil(recurringAutoFocusState, item);
      break;
    case 'TEMPLATE':
      setRecoil(templateAutoFocusState, item);
      break;
    default:
      setRecoil(autoFocusState, item);
      break;
  }
}

export async function addTaskInBetween(
  index: number,
  parentIndex: number | undefined,
  parentTaskId: string | undefined | null,
  dataType: TaskType,
  assigneeId: string | null,
  stateType: StateType,
  taskSize: SizeOptionsType,
  showUpgradeModal?: () => void,
) {
  log('FOCUS INPUT addTaskInBetween is called', index, parentTaskId, parentIndex);

  const state = getRecoil(combinedSelector);
  if (stateType === 'INDIVIDUAL') {
    assigneeId = state.userData.currentUserId;
  } else if (stateType !== 'PROJECT' && stateType !== 'TEMPLATE') {
    assigneeId = getUserId();
  }

  const mainData = getStateTypeMain(stateType);
  let { data } = mainData[dataType];
  if (data === null) return;

  let projectId: string | null = null;
  if (stateType === 'PROJECT') projectId = state.projectData.currentProjectId;
  if (stateType === 'TEMPLATE') projectId = state.templateData.currentTemplateId;
  const tempId = randomNumber() + '';

  const newTask = createNewTask(
    '',
    tempId,
    0,
    dataType,
    projectId,
    taskSize,
    assigneeId,
    stateType,
  );

  let newSequence;

  if (parentTaskId && parentIndex !== undefined && parentIndex !== -1) {
    log('addTaskInBetween is called 1', parentTaskId);

    let parent = data[parentIndex];
    if (parent.isTemp) return;
    if (!index) index = 0;

    // As a subtask, take project from its parent
    projectId = parent.project && parent.project.id ? parent.project.id : null;
    if (stateType === 'TEMPLATE') projectId = state.templateData.currentTemplateId;
    newTask.project = parent.project;
    newTask.animation = 'ADD_SUB_TASK';

    log('addTaskInBetween is called 1.1', parentTaskId);

    if (!parent.subTasks) return;
    // insert task at certain location
    parent = {
      ...parent,
      subTasks: insertOnIndex(parent.subTasks, index, newTask),
    };

    newSequence =
      parent.subTasks.length > 1 ? getNewSequence(index, parent.subTasks) : SEQUENCE_LARGE * 20;

    parent = {
      ...parent,
      subTasks: parent.subTasks.map((item, ind) => {
        if (ind === index)
          return {
            ...item,
            sequence: newSequence,
          };
        return item;
      }),
    };

    data = data.map((item, ind) => {
      if (ind === parentIndex) return parent;
      return item;
    });
    addTaskData(dataType, data, stateType);
  } else {
    newTask.animation = 'ADD_TASK';

    log('addTaskInBetween is called 2', parentTaskId);

    // insert task at certain location
    data = insertOnIndex(data, index, newTask);

    // get new sequence
    newSequence = getNewSequence(index, data);
    data[index].sequence = newSequence;
    addTaskData(dataType, data, stateType);
  }

  const autoFocus = `${dataType}${tempId}`;

  setAutoFocusItem(autoFocus, stateType);

  const response = await addTaskAPI(
    '',
    newSequence,
    dataType,
    tempId,
    projectId,
    taskSize,
    assigneeId,
    parentTaskId,
    stateType,
  );

  if (response.success && response.data) {
    if (!parentTaskId) hasTaskUpdated(tempId, dataType, response.data, stateType);
    else hasSubTaskUpdated(parentTaskId, tempId, dataType, response.data, stateType);
  } else if (response.status === 402) {
    removeTask(tempId, dataType, parentTaskId || '', stateType);
    showUpgradeModal && showUpgradeModal();
  }
}

export function setTaskComment(
  data: CommentType[],
  stateType: StateType,
  dataType: TaskType,
  index: number,
  parentIndex?: number,
) {
  log('setTaskComment', data);
  const targetStateAtom = getTaskListData(stateType, dataType);
  const targetData = getRecoil(targetStateAtom);
  if (targetData === null) return;

  let tempData: TaskObjectType[];
  if (parentIndex !== undefined && targetData[parentIndex].subTasks !== undefined) {
    tempData = targetData.map((item, indx) => {
      if (indx === parentIndex && item.subTasks) {
        return {
          ...item,
          subTasks: item.subTasks.map((subItem, idx) => {
            if (idx === index) {
              return { ...subItem, comments: data };
            } else return subItem;
          }),
        };
      } else return item;
    });
  } else {
    tempData = targetData.map((item, indx) => {
      if (indx === index) {
        return { ...item, comments: data };
      } else return item;
    });
  }
  setRecoil(targetStateAtom, tempData);
}

export async function deleteTaskComment(
  taskId: string,
  commentId: string,
  dataType: TaskType,
  stateType: StateType,
  parentId?: string,
) {
  removeTaskComment(taskId, dataType, stateType, commentId, parentId);

  deleteCommentAPI(taskId, commentId);
}

export function removeTaskComment(
  taskId: string,
  dataType: TaskType,
  stateType: StateType,
  commentId: string,
  parentId?: string,
) {
  const stateData = getRecoil(combinedSelector);
  const allTasksDetails = getStateType(stateData, dataType, stateType);
  const allTasksData = allTasksDetails && allTasksDetails.data ? allTasksDetails.data : [];

  let parentIndex: number | undefined = undefined;
  let index: number | undefined = undefined;
  if (parentId !== null && parentId !== undefined) {
    const tempIndex = allTasksData.findIndex((task) => task.id === parentId);
    if (tempIndex !== -1) {
      parentIndex = tempIndex;
    }
    if (parentIndex === undefined) return;
    if (allTasksData[parentIndex].subTasks) {
      const subTempIndex = allTasksData[parentIndex].subTasks!.findIndex(
        (item) => item.id === taskId,
      );
      if (subTempIndex !== -1) {
        index = subTempIndex;
      }
    }
  } else {
    const tempIndex = allTasksData.findIndex((item) => item.id === taskId);
    if (tempIndex !== -1) {
      index = tempIndex;
    }
  }

  if (index === undefined) return;

  let newComments: CommentType[] = [];
  if (
    parentIndex !== undefined &&
    parentIndex >= 0 &&
    allTasksData[parentIndex].subTasks &&
    allTasksData[parentIndex].subTasks![index].comments
  ) {
    newComments = [...allTasksData[parentIndex].subTasks![index].comments!];
    const commentIndex = newComments.findIndex((comment) => comment.id === commentId);
    newComments.splice(commentIndex, 1);
  } else if (
    index !== undefined &&
    allTasksData !== undefined &&
    allTasksData.length > index &&
    allTasksData[index].comments
  ) {
    newComments = [...allTasksData[index].comments!];
    const commentIndex = newComments.findIndex((comment) => comment.id === commentId);
    newComments.splice(commentIndex, 1);
  }
  setTaskComment(newComments, stateType, dataType, index, parentIndex);
}

export async function editTaskComment(
  taskId: string,
  newTaskComment: CommentType,
  stateType: StateType,
  dataType: TaskType,
  parentId?: string,
  taggedUsers?: CommentTaggedUsersType[],
) {
  const state = getRecoil(combinedSelector);

  log('------------');
  log('---- VAL ---');
  log('------------');
  log(state.userData);
  log(state.accountData.account);

  const allTasksDetails = getStateType(state, dataType, stateType);
  const allTasksData = allTasksDetails && allTasksDetails.data ? allTasksDetails.data : [];

  let parentIndex: number | undefined = undefined;
  let index: number | undefined = undefined;
  if (parentId !== null && parentId !== undefined) {
    const tempIndex = allTasksData.findIndex((item) => item.id === parentId);
    if (tempIndex !== -1) {
      parentIndex = tempIndex;
    }
    if (parentIndex === undefined) return;
    if (allTasksData[parentIndex].subTasks) {
      const subTempIndex = allTasksData[parentIndex].subTasks!.findIndex(
        (item) => item.id === taskId,
      );
      if (subTempIndex !== -1) {
        index = subTempIndex;
      }
    }
  } else {
    const tempIndex = allTasksData.findIndex((item) => item.id === taskId);
    if (tempIndex !== -1) {
      index = tempIndex;
    }
  }

  if (index === undefined) return;

  let newComments = [newTaskComment];

  if (
    parentIndex !== undefined &&
    parentIndex >= 0 &&
    allTasksData[parentIndex].subTasks &&
    allTasksData[parentIndex].subTasks![index].comments
  ) {
    const commentIndex = allTasksData[parentIndex].subTasks![index].comments!.findIndex(
      (comment) => comment.id === newTaskComment.id,
    );
    if (commentIndex !== -1) {
      newComments = [...allTasksData[parentIndex].subTasks![index].comments!];
      newComments[commentIndex] = newTaskComment;
    } else {
      return;
    }
  } else if (
    index !== undefined &&
    allTasksData !== undefined &&
    allTasksData.length > index &&
    allTasksData[index].comments
  ) {
    const commentIndex = allTasksData[index].comments!.findIndex(
      (comment) => comment.id === newTaskComment!.id,
    );
    if (commentIndex !== -1) {
      newComments = [...allTasksData[index].comments!];
      newComments[commentIndex] = newTaskComment;
    } else {
      return;
    }
  }

  log('call setTaskComment', newTaskComment);
  setTaskComment(newComments, stateType, dataType, index, parentIndex);

  const response = await editCommentAPI(
    taskId,
    newTaskComment.name,
    taggedUsers,
    newTaskComment.id,
  );
  const data = response.data;
  const success = response.success;

  if (success && data) {
    hasTaskCommentUpdated(dataType, index, data, stateType, newTaskComment.id, parentIndex);
  }
}

export async function saveTaskComment(
  taskId: string,
  taskComment: string,
  projectId: string | undefined,
  stateType: StateType,
  dataType: TaskType,
  parentId?: string,
  formData?: FormData,
  taggedUsers?: CommentTaggedUsersType[],
) {
  // log('addTaskComment', taskId, taskComment, projectId, userId, stateType, dataType, index);
  const state = getRecoil(combinedSelector);

  log('------------');
  log('---- VAL ---');
  log('------------');
  log(state.userData);
  log(state.accountData.account);

  const assignee = state.accountData.account;
  const allTasksDetails = getStateType(state, dataType, stateType);
  const allTasksData = allTasksDetails && allTasksDetails.data ? allTasksDetails.data : [];

  let parentIndex: number | undefined = undefined;
  let index: number | undefined = undefined;
  if (parentId !== null && parentId !== undefined) {
    const tempIndex = allTasksData.findIndex((item) => item.id === parentId);
    if (tempIndex !== -1) {
      parentIndex = tempIndex;
    }
    if (parentIndex === undefined) return;
    if (allTasksData[parentIndex].subTasks) {
      const subTempIndex = allTasksData[parentIndex].subTasks!.findIndex(
        (item) => item.id === taskId,
      );
      if (subTempIndex !== -1) {
        index = subTempIndex;
      }
    }
  } else {
    const tempIndex = allTasksData.findIndex((item) => item.id === taskId);
    if (tempIndex !== -1) {
      index = tempIndex;
    }
  }
  if (stateType === 'PROJECT' && state.projectData.currentProjectId) {
    projectId = state.projectData.currentProjectId;
  }
  if (index === undefined) return;
  const tempId = randomNumber() + '';
  const newComment = createNewTaskComment(
    taskComment.trim(),
    tempId,
    dataType,
    projectId,
    assignee,
    taggedUsers,
    formData,
  );
  let newComments = [newComment];

  if (
    parentIndex !== undefined &&
    parentIndex >= 0 &&
    allTasksData[parentIndex].subTasks &&
    allTasksData[parentIndex].subTasks![index].comments
  ) {
    newComments = [...newComments, ...allTasksData[parentIndex].subTasks![index].comments!];
  } else if (
    index !== undefined &&
    allTasksData !== undefined &&
    allTasksData.length > index &&
    allTasksData[index].comments
  ) {
    newComments = [...newComments, ...allTasksData[index].comments!];
  }

  log('call setTaskComment', newComment);
  setTaskComment(newComments, stateType, dataType, index, parentIndex);
  let data, success;
  if (formData) {
    const tempFormData = formData;
    tempFormData.append('tempId', tempId);
    const response = await addCommentAttachmentAPI(tempFormData);
    data = response.data;
    success = response.success;
  } else {
    const response = await addCommentAPI(taskId, taskComment, taggedUsers, tempId);
    data = response.data;
    success = response.success;
  }

  if (success && data) {
    hasTaskCommentUpdated(dataType, index, data, stateType, tempId, parentIndex);
  }
}

export function hasTaskCommentUpdated(
  dataType: TaskType,
  index: number,
  commentData: CommentType,
  stateType: StateType,
  tempId: string,
  parentIndex?: number,
) {
  const stateData = getRecoil(combinedSelector);
  const taskData = getReducerTypeMain(stateData, stateType);
  let commentIndex = -1;
  if (parentIndex !== undefined && parentIndex !== null && parentIndex >= 0) {
    commentIndex = taskData[dataType]?.data[parentIndex]?.subTasks[index]?.comments
      ? taskData[dataType].data[parentIndex].subTasks[index].comments.findIndex(
          (item) => item.id === tempId,
        )
      : commentIndex;
  } else {
    commentIndex = taskData[dataType]?.data[index]?.comments
      ? taskData[dataType].data[index].comments.findIndex((item) => item.id === tempId)
      : commentIndex;
  }

  let comment = null;

  // found in current type
  if (commentIndex !== -1) {
    if (parentIndex !== undefined && parentIndex !== null && parentIndex >= 0) {
      comment = taskData[dataType].data[parentIndex].subTasks[index].comments[commentIndex];
    } else {
      comment = taskData[dataType].data[index].comments[commentIndex];
    }
  }

  if (comment === null || commentIndex === -1) return;
  setTaskCommentLive(dataType, index, commentData, stateType, tempId, parentIndex, commentIndex);
}

export function setTaskCommentLive(
  dataType: TaskType,
  index: number,
  data: ResponseCommentType,
  stateType: StateType,
  tempId: string,
  parentIndex: number | undefined,
  commentIndex: number,
) {
  log('setTaskCommentLive', data, tempId);
  const setTaskComment = (comment: CommentType, commentIdx: number) => {
    return commentIdx === commentIndex
      ? {
          ...comment,
          isTemp: false,
          id: data.id,
          thumbnail: data.thumbnail,
          type: data.type,
          url: data.url ? data.url : null,
          taggedUsers: data.taggedUsers,
          created: data.createdString
            ? parseToDateObjectFromString(data.createdString, 'YYYY-MM-DDTHH:mm:ssZ')
            : comment.created,
        }
      : comment;
  };
  const updateTask = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? {
          ...task,
          comments: task.comments?.map((comment, commentIdx) => {
            return setTaskComment(comment, commentIdx);
          }),
        }
      : task;
  };
  updateTaskProperties(dataType, updateTask, parentIndex, stateType);
}

export function updateTaskTags(
  dataType: TaskType,
  index: number,
  data: TagObjectType[] | null,
  parentIndex: number | undefined,
  update: boolean,
  stateType: StateType,
) {
  const updateTaskTagsData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? update
        ? { ...task, tags: data, updateTags: +new Date() }
        : { ...task, tags: data }
      : task;
  };

  updateTaskTagProperties(dataType, updateTaskTagsData, parentIndex, stateType);
}

export function updateTaskTagsAndTagInfo(
  dataType: TaskType,
  index: number,
  tags: TagObjectType[],
  tagInfo: TagInfoType[],
  parentIndex: number | undefined,
  stateType: StateType,
) {
  const updateTaskTagsInfoData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? { ...task, tags: tags, tagInfo: tagInfo, updateTags: +new Date() }
      : task;
  };
  updateTaskTagProperties(dataType, updateTaskTagsInfoData, parentIndex, stateType, index);
}

/*
 * Save task assignee
 * return null
 */
export async function saveTaskAssignee(
  assigneeId: string | null,
  dataType: TaskType,
  taskId: string,
  parentIndex?: number,
  parentId?: string,
  taskName?: string,
  stateType?: StateType,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  const data = getRecoil(targetStateAtom);
  if (data === null) return;
  let index: number | undefined = undefined;
  if (parentId) {
    parentIndex = data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1 || parentIndex === undefined)
      parentIndex = data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1 || parentIndex === undefined) return;

    index = data[parentIndex].subTasks?.findIndex((item) => item.id === taskId);
    if (index === -1 || index === undefined)
      index = data[parentIndex].subTasks?.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === undefined) return;
  } else {
    index = data.findIndex((item) => item.id === taskId);
    if (index === -1 || index === undefined)
      index = data.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === undefined) return;
  }

  updateTaskAssignee(dataType, index, assigneeId, parentIndex, stateType);

  let task: TaskObjectType;
  let parent;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks &&
    data[parentIndex].subTasks![index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
    // id = parent.subTasks[index].id;
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
    // id = task.id;
  } else return;

  if (task.isTemp) return;

  task.assigneeController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'assigneeController',
    index,
    newAbortSource,
    parentIndex,
    stateType,
  );

  await updateTaskAssigneeAPI(
    assigneeId,
    task.id,
    newAbortSource,
    stateType === 'TEMPLATE',
    taskName,
  );
}

export async function bulkSaveTaskAssignee(
  focusArrTaskId: string[],
  backlogArrTaskId: string[],
  newAssigneeId: string | null,
  stateType?: StateType,
) {
  const arrTaskId = [...focusArrTaskId, ...backlogArrTaskId];
  const focusStateAtom = getTaskListData(stateType, 'FOCUS');
  const backlogStateAtom = getTaskListData(stateType, 'BACKLOG');

  const focus = getRecoil(focusStateAtom);
  const backlog = getRecoil(backlogStateAtom);

  const newRequestSource = new AbortController();

  focusArrTaskId.forEach((taskId) => {
    let index = focus && focus.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null)
      index = focus && focus.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null || focus === null) return;

    updateTaskAssignee('FOCUS', index, newAssigneeId, undefined, stateType);
    const task = focus[index];
    if (task.isTemp) return;

    task.assigneeController?.abort();

    updateTaskAbortControllerToken(
      'FOCUS',
      'assigneeController',
      index,
      newRequestSource,
      undefined,
      stateType,
    );
  });

  backlogArrTaskId.forEach((taskId) => {
    let index = backlog && backlog.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null)
      index = backlog && backlog.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null || backlog === null) return;

    updateTaskAssignee('BACKLOG', index, newAssigneeId, undefined, stateType);
    const task = backlog[index];
    if (task.isTemp) return;

    task.assigneeController?.abort();

    updateTaskAbortControllerToken(
      'BACKLOG',
      'assigneeController',
      index,
      newRequestSource,
      undefined,
      stateType,
    );
  });

  await bulkUpdateAssigneeFromAPI(newAssigneeId, arrTaskId, newRequestSource);
  resetSelectedTasks(stateType);
}

export function updateTaskAssignee(
  dataType: TaskType,
  index: number,
  assigneeId: string | null,
  parentIndex?: number,
  stateType?: StateType,
) {
  const updateTaskAssigneeData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? { ...task, assignee: assigneeId ? { id: assigneeId } : null }
      : task;
  };

  updateTaskProperties(dataType, updateTaskAssigneeData, parentIndex, stateType);
}

export function updateTaskProperties(
  dataType: TaskType,
  updateMethod: (task: TaskObjectType, index: number) => TaskObjectType,
  parentIndex?: number,
  stateType?: StateType,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  const targetData = getRecoil(targetStateAtom);
  if (targetData === null) return;
  let tempData: TaskObjectType[];

  if (
    parentIndex !== undefined &&
    parentIndex > -1 &&
    targetData[parentIndex].subTasks !== undefined
  ) {
    tempData = targetData.map((task, taskIndex) => {
      if (taskIndex === parentIndex && task.subTasks) {
        return {
          ...task,
          subTasks: task.subTasks.map((subTask, subTaskIndex) => {
            return updateMethod(subTask, subTaskIndex);
          }),
        };
      } else {
        return task;
      }
    });
  } else {
    tempData = targetData.map((task, taskIndex) => {
      return updateMethod(task, taskIndex);
    });
  }
  setRecoil(targetStateAtom, tempData);
}

export function updateTaskTagProperties(
  dataType: TaskType,
  updateMethod: (task: TaskObjectType, index: number) => TaskObjectType,
  parentIndex?: number,
  stateType?: StateType,
  ind?: number,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  const targetData = getRecoil(targetStateAtom);
  if (targetData === null) return;
  let tempData: TaskObjectType[];
  log('Recoil updateTaskProperties ', targetStateAtom.key, targetData, [], parentIndex, ind);

  if (
    parentIndex !== undefined &&
    parentIndex > -1 &&
    targetData[parentIndex].subTasks !== undefined
  ) {
    tempData = targetData.map((task, taskIndex) => {
      if (taskIndex === parentIndex && task.subTasks) {
        return {
          ...task,
          subTasks: task.subTasks.map((subTask, subTaskIndex) => {
            return updateMethod(subTask, subTaskIndex);
          }),
        };
      } else {
        return task;
      }
    });
  } else {
    tempData = targetData.map((task, taskIndex) => {
      return updateMethod(task, taskIndex);
    });
  }
  setRecoil(targetStateAtom, tempData);
  log('Recoil updateTaskProperties ', targetStateAtom.key, targetData, tempData, parentIndex, ind);
}

export function saveTaskCollapsableState(
  taskId: string,
  collapseStatus: boolean,
  parentIndex: number | undefined,
  parentId: string | undefined,
  dataType: TaskType,
  stateType: StateType,
) {
  log(
    'saveTaskCollapsableState',
    taskId,
    collapseStatus,
    dataType,
    parentIndex,
    parentId,
    stateType,
  );

  const targetStateAtom =
    dataType === 'COMPLETED'
      ? getCompletedTaskDataState(stateType)
      : getTaskListData(stateType, dataType);
  const data = getRecoil(targetStateAtom);
  if (data === null) return;

  let index = data.findIndex((item) => item.id === taskId);
  if (index === -1)
    index =
      parentIndex && data.length > parentIndex && data[parentIndex] && data[parentIndex].subTasks
        ? data[parentIndex].subTasks!.findIndex((item) => item.tempId === taskId)
        : -1;
  if (index === -1) return;

  updateTaskCollapseStatus(index, collapseStatus, parentIndex, dataType, stateType);

  let task: TaskObjectType;
  let parent;
  if (
    typeof parentIndex !== 'undefined' &&
    data &&
    data[parentIndex] &&
    data[parentIndex].subTasks &&
    data[parentIndex].subTasks![index]
  ) {
    parent = data[parentIndex];
    task = parent.subTasks[index];
    // id = parent.subTasks[index].id;
  } else if (typeof parentIndex === 'undefined' && data && data[index]) {
    task = data[index];
    // id = task.id;
  } else return;

  if (task.isTemp) return;

  task.collapseController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'collapseController',
    index,
    newAbortSource,
    parentIndex,
    stateType,
  );

  let isProject = false;
  if (stateType === 'PROJECT') {
    isProject = true;
  }
  if (stateType === 'TEMPLATE') {
    log('updateTemplateTaskAPI', collapseStatus, taskId, task.tempId);
    const payload = {
      id: taskId,
      collapsedSubTasks: collapseStatus,
      tempId: task.tempId,
    };
    updateTemplateTaskAPI(payload, newAbortSource);
  } else {
    updateCollapseTaskStatusAPI(task.id, collapseStatus, isProject, newAbortSource);
  }
}

function updateCompletedTaskCollapseStatus(index: number, value: boolean, stateType: StateType) {
  const targetStateAtom = getCompletedTaskDataState(stateType);
  const targetData = getRecoil(targetStateAtom);
  const tempData: TaskObjectType[] = targetData.map((task, taskIndex) => {
    if (taskIndex === index)
      return {
        ...task,
        collapsedSubTasks: value,
      };
    return task;
  });
  setRecoil(targetStateAtom, tempData);
}

export function updateTaskCollapseStatus(
  index: number,
  value: boolean,
  parentIndex: number | undefined,
  dataType: TaskType,
  stateType: StateType,
) {
  if (dataType === 'COMPLETED') {
    updateCompletedTaskCollapseStatus(index, value, stateType);
    return;
  }
  const updateTaskCollapseStatusData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index ? { ...task, collapsedSubTasks: value } : task;
  };
  updateTaskProperties(dataType, updateTaskCollapseStatusData, parentIndex, stateType);
}

export function setHighlightedTask(data: TaskObjectType, stateType: StateType) {
  switch (stateType) {
    case 'PROJECT':
      setRecoil(projectHighlightedTaskState, data);
      break;
    case 'INDIVIDUAL':
      setRecoil(userHighlightedTaskState, data);
      break;
    case 'TUTORIAL':
      setRecoil(tutorialCurrentHighlightedTask, data);
      break;
    case 'RECURRING':
      setRecoil(recurringHighlightedTaskState, data);
      break;
    case 'TEMPLATE':
      setRecoil(templateHighlightedTaskState, data);
      break;
    default:
      setRecoil(currentHighlightedTaskState, data);
      break;
  }
}

export function updateTaskAnimation(
  dataType: TaskType,
  index: number,
  value: AnimationType | undefined,
  parentIndex: number | undefined,
  stateType: StateType,
) {
  const updateTaskAnimationData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index ? { ...task, animation: value } : task;
  };

  updateTaskProperties(dataType, updateTaskAnimationData, parentIndex, stateType);
}

export function initializeTaskFilters() {
  initializeCompletedTaskFilters();

  allTaskFilters.map((name) => {
    if (name === 'tagFilter') {
      const { data, error } = getFilterFromLocalStorage(name);
      if (!error && data) {
        if (data.includes(',')) setTaskFilter(name, data.split(','));
        else setTaskFilter(name, [data]);
      }
    } else {
      allStates.map((stateTypeName) => {
        const { data, error } = getFilterFromLocalStorage(name, stateTypeName);

        if (!error && data) {
          if (name === 'projectFilter' || name === 'assigneeFilter' || name === 'createdByFilter') {
            if (data.includes(',')) {
              setTaskFilter(name, data.split(','), stateTypeName);
            } else {
              setTaskFilter(name, [data], stateTypeName);
            }
          } else {
            setTaskFilter(name, data, stateTypeName);
          }
        }
      });
    }
  });
}

const allStates: ('' | StateType)[] = [
  'TEMPLATE',
  'COMPLETED_TASK',
  'PROJECT',
  'RECURRING',
  'TUTORIAL',
  'INDIVIDUAL',
  '',
];
const allTaskFilters: TaskFiltersType[] = [
  'assigneeFilter',
  'createdByFilter',
  'dateFilter',
  'projectFilter',
  'sizeFilter',
  'tagFilter',
  'completedTaskFilter',
  'searchTextFilter',
];
export function initializeCompletedTaskFilters() {
  allStates.map((stateTypeName) => {
    const { data, error } = getFilterFromLocalStorage('completedTaskFilter', stateTypeName);

    if (!error) setCompletedTaskFilter(data, stateTypeName);
  });
}

export const getFilterFromLocalStorage = (
  name: TaskFiltersType,
  stateType?: StateType | '',
  id?: string,
) => {
  const storageName = getFilterStorageName(name, stateType, id);
  return getItem(storageName);
};

/*
 * Save task name
 *
 * newName String
 * dataType String
 * field String
 * parentId Intger
 * stateType String
 *
 * return null
 */

export function saveSearchTaskName(newName: string, field: string, task: TaskObjectType) {
  if (task.isTemp || field === 'tempName') return;

  if (task.name === newName) return;

  clearTimeout(timer);

  timer = setTimeout(() => {
    task.updateController?.abort();
    if (task.abortController) task.abortController.abort();

    const currentAbortController = new AbortController();
    updateTaskValueFromAPI(newName, task.id, task.tempId, currentAbortController);
  }, TASK_INPUT_WAIT_INTERVAL);
}

export function initializeTaskFilter(name: TaskFiltersType, stateType: StateType, id: string) {
  log('initializeTaskFilter', name, stateType, id);

  const { data, error } = getFilterFromLocalStorage(name, stateType, id);
  if (!error && (name === 'assigneeFilter' || name === 'projectFilter')) {
    if (data === null) {
      setTaskFilter(name, null, stateType);
    } else if (data.includes(',')) {
      setTaskFilter(name, data.split(','), stateType);
    } else {
      setTaskFilter(name, [data], stateType);
    }
    return;
  }
  setTaskFilter(name, !error ? data : null, stateType);
}

export function addTaskComment(
  data: CommentType,
  stateType: StateType,
  dataType: TaskType,
  index: number,
  parentIndex?: number,
) {
  const addTaskCommentData = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === index
      ? {
          ...task,
          comments: task.comments ? [data, ...task.comments] : [data],
        }
      : task;
  };

  updateTaskProperties(dataType, addTaskCommentData, parentIndex, stateType);
}

export function updateTaskComment(
  data: CommentType,
  stateType: StateType,
  dataType: TaskType,
  index: number,
  commentIndex: number,
  parentIndex?: number,
) {
  const updateTaskCommentData = (task: TaskObjectType, taskIndex: number) => {
    if (taskIndex === index) {
      if (task.comments && task.comments.length > commentIndex) {
        const updatedComments = [...task.comments];
        updatedComments[commentIndex] = data;

        return {
          ...task,
          comments: updatedComments,
        };
      }
    }
    return task;
  };

  updateTaskProperties(dataType, updateTaskCommentData, parentIndex, stateType);
}

export function updateTaskDeletionComment(
  data: CommentType,
  stateType: StateType,
  dataType: TaskType,
  index: number,
  parentIndex?: number,
  commentIndex?: number,
) {
  const updateTaskCommentDeletionData = (task: TaskObjectType, taskIndex: number) => {
    const newComments = [...task.comments!];
    newComments.splice(commentIndex!, 1);
    return taskIndex === index
      ? {
          ...task,
          comments: newComments,
        }
      : task;
  };

  updateTaskProperties(dataType, updateTaskCommentDeletionData, parentIndex, stateType);
}

export async function bulkUpdateStartEndDate(
  focusArrTaskId: string[],
  backlogArrTaskId: string[],
  startDate: Date | null,
  endDate: Date | null,
  stateType: StateType,
) {
  const arrTaskId = [...focusArrTaskId, ...backlogArrTaskId];

  const focusTargetStateAtom = getTaskListData(stateType, 'FOCUS');
  const backlogTargetStateAtom = getTaskListData(stateType, 'BACKLOG');

  const focus = getRecoil(focusTargetStateAtom);
  const backlog = getRecoil(backlogTargetStateAtom);

  const newRequestSource = new AbortController();

  focusArrTaskId.forEach((taskId) => {
    let index = focus && focus.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null)
      index = focus && focus.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null || focus === null) return;

    updateStartEndDates('FOCUS', index, startDate, endDate, undefined, stateType);
    const task = focus[index];
    if (task.isTemp) return;

    task.startEndDateController?.abort();

    updateTaskAbortControllerToken(
      'FOCUS',
      'startEndDateController',
      index,
      newRequestSource,
      undefined,
      stateType,
    );
  });

  backlogArrTaskId.forEach((taskId) => {
    let index = backlog && backlog.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null)
      index = backlog && backlog.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null || backlog === null) return;

    updateStartEndDates('BACKLOG', index, startDate, endDate, undefined, stateType);
    const task = backlog[index];
    if (task.isTemp) return;

    task.startEndDateController?.abort();

    updateTaskAbortControllerToken(
      'BACKLOG',
      'startEndDateController',
      index,
      newRequestSource,
      undefined,
      stateType,
    );
  });

  await bulkUpdateTaskDatesAPI(
    {
      startdate: startDate ? formatDateForAPI(startDate) : null,
      enddate: endDate ? formatDateForAPI(endDate) : null,
    },
    arrTaskId,
    newRequestSource,
  );
  resetSelectedTasks(stateType);
}

export async function bulkUpdateDueDate(
  focusArrTaskId: string[],
  backlogArrTaskId: string[],
  dueDate: Date,
  stateType?: StateType,
) {
  const arrTaskId = [...focusArrTaskId, ...backlogArrTaskId];

  const focusTargetStateAtom = getTaskListData(stateType, 'FOCUS');
  const backlogTargetStateAtom = getTaskListData(stateType, 'BACKLOG');

  const focus = getRecoil(focusTargetStateAtom);
  const backlog = getRecoil(backlogTargetStateAtom);

  const newRequestSource = new AbortController();

  focusArrTaskId.forEach((taskId) => {
    let index = focus && focus.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null)
      index = focus && focus.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null || focus === null) return;

    updateDueDate('FOCUS', index, dueDate, undefined, stateType);
    const task = focus[index];
    if (task.isTemp) return;

    task.dueDateController?.abort();

    updateTaskAbortControllerToken(
      'FOCUS',
      'dueDateController',
      index,
      newRequestSource,
      undefined,
      stateType,
    );
  });

  backlogArrTaskId.forEach((taskId) => {
    let index = backlog && backlog.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null)
      index = backlog && backlog.findIndex((item) => item.tempId === taskId);
    if (index === -1 || index === null || backlog === null) return;

    updateDueDate('BACKLOG', index, dueDate, undefined, stateType);
    const task = backlog[index];
    if (task.isTemp) return;

    task.dueDateController?.abort();

    updateTaskAbortControllerToken(
      'BACKLOG',
      'dueDateController',
      index,
      newRequestSource,
      undefined,
      stateType,
    );
  });

  await bulkUpdateTaskDatesAPI(
    { duedate: dueDate ? formatDateForAPI(dueDate) : null },
    arrTaskId,
    newRequestSource,
  );

  resetSelectedTasks(stateType);
}

export function bulkUpdateCheckStatus(
  focusArrTaskId: string[],
  backlogArrTaskId: string[],
  checked: boolean,
  noApiCall?: boolean,
  stateType?: StateType,
) {
  const arrTaskId = [...focusArrTaskId, ...backlogArrTaskId];

  const focusTargetStateAtom = getTaskListData(stateType, 'FOCUS');
  const backlogTargetStateAtom = getTaskListData(stateType, 'BACKLOG');

  const focus = getRecoil(focusTargetStateAtom);
  const backlog = getRecoil(backlogTargetStateAtom);

  let indexList: number[] = [];
  focusArrTaskId.forEach((taskId) => {
    const index = focus && focus.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null) return;
    indexList = [...indexList, index];
  });
  removeTasksList(indexList, 'FOCUS', undefined, stateType);
  indexList = [];
  backlogArrTaskId.forEach((taskId) => {
    const index = backlog && backlog.findIndex((item) => item.id === taskId);
    if (index === -1 || index === null) return;
    indexList = [...indexList, index];
  });
  removeTasksList(indexList, 'BACKLOG', undefined, stateType);

  setTimeout(async () => {
    if (!noApiCall) {
      await bulkUpdateTaskStatusAPI(arrTaskId, checked ? 'COMPLETED' : 'OPEN');

      getCompletedTasks(undefined, true, undefined, stateType);
    }
  }, getCompleteTaskDelay());
}

export function removeTasksList(
  indexList: number[],
  dataType: TaskType,
  parentIndex?: number,
  stateType?: StateType,
) {
  const targetStateAtom = getTaskListData(stateType, dataType);
  const data = getRecoil(targetStateAtom);

  if (data === null) return;
  let tempData = { ...data };
  if (typeof parentIndex !== 'undefined') {
    const isBadIndex = {};
    indexList.forEach(function (k) {
      isBadIndex[k] = true;
    });

    tempData[parentIndex].subTasks =
      data[parentIndex].subTasks &&
      data[parentIndex].subTasks!.filter(function (x, i) {
        return !isBadIndex[i];
      });
  } else {
    const isBadIndex = {};
    indexList.forEach(function (k) {
      isBadIndex[k] = true;
    });
    tempData = data.filter(function (x, i) {
      return !isBadIndex[i];
    });
  }

  log('removeTasksList', indexList, dataType, parentIndex, stateType, tempData);

  addTaskData(dataType, tempData, stateType);
}

/*
 * Save task sequence
 *
 * dateType String
 * startIndex Integer
 * endIndex Integer
 * movedFromDataType String
 * stateTypeString
 *
 * return null
 */
export function saveTaskSequence(
  dataType: TaskType,
  startIndex: number,
  endIndex: number,
  movedFromDataType: TaskType,
  stateType?: StateType,
) {
  log('saveTaskSequence is called', dataType, startIndex, endIndex, movedFromDataType, stateType);

  const targetStateAtom = getTaskListData(stateType, dataType);
  const data = getRecoil(targetStateAtom);
  if (data === null) return;
  let reOrderedData: TaskObjectType[] = [];

  if (movedFromDataType) {
    const targetFromStateAtom = getTaskListData(stateType, movedFromDataType);
    const dataFrom = getRecoil(targetFromStateAtom);
    if (dataFrom === null) return;
    const { tempData, tempDataFrom } = removeFromOneAddToAnother(
      data,
      dataFrom,
      startIndex,
      endIndex,
    );
    addTaskData(movedFromDataType, tempDataFrom, stateType);
    reOrderedData = tempData;
  } else reOrderedData = reorderTasks(data, startIndex, endIndex);

  // get new sequence
  const newSequence = getNewSequence(endIndex, reOrderedData);
  // log(newSequence, 'newsequcne');
  if (reOrderedData.length <= endIndex || typeof reOrderedData[endIndex] === 'undefined') return;

  reOrderedData = reOrderedData.map((item, ind) => {
    if (ind === endIndex) {
      return {
        ...item,
        sequence: newSequence,
        animation:
          movedFromDataType === 'FOCUS' && dataType === 'BACKLOG'
            ? 'REORDER_TASK_DELAYED'
            : 'REORDER_TASK',
      };
    }
    return item;
  });

  addTaskData(dataType, reOrderedData, stateType);

  const task = reOrderedData[endIndex];

  if (task.isTemp) return;

  task.sequenceController?.abort();

  const newRequestSource = new AbortController();

  updateTaskAbortControllerToken(
    dataType,
    'sequenceController',
    endIndex,
    newRequestSource,
    undefined,
    stateType,
  );
  if (stateType === 'TEMPLATE') {
    log('updateTemplateTaskAPI', task.id);
    const payload = {
      id: task.id,
      sequence: newSequence,
      taskType: dataType,
    };
    if (task.recurringTaskMeta) {
      const recurringPayload = getRecurringTaskPayload(task);
      recurringPayload['sequence'] = newSequence;
      recurringPayload['taskType'] = dataType;
      updateTemplateRecurringTaskAPI(recurringPayload);
    } else {
      updateTemplateTaskAPI(payload, newRequestSource);
    }
  } else {
    updateTaskSequenceFromAPI(task.id, newSequence, dataType, null, newRequestSource, stateType);
  }
}

export async function bulkSaveTaskSequence(
  dataType: TaskType,
  startIndex: number,
  endIndex: number,
  movedFromDataType: TaskType,
  stateType?: StateType,
) {
  log(
    'bulkSaveTaskSequence is called',
    dataType,
    startIndex,
    endIndex,
    movedFromDataType,
    stateType,
  );

  const targetStateAtom = getTaskListData(stateType, dataType);
  const data = getRecoil(targetStateAtom);

  if (data === null) return;

  let reOrderedData: (TaskObjectType[] | number)[] = [];
  const mainData = getStateTypeMain(stateType);

  if (movedFromDataType) {
    const targetFromStateAtom = getTaskListData(stateType, movedFromDataType);
    const dataFrom = getRecoil(targetFromStateAtom);
    if (dataFrom === null) return;
    reOrderedData = bulkRemoveFromOneAddToAnother(
      data,
      dataFrom,
      endIndex,
      movedFromDataType,
      mainData.selectedTasks.FOCUS,
      mainData.selectedTasks.BACKLOG,
      stateType,
    );
  } else {
    reOrderedData = bulkRemoveAddInSame(
      data,
      endIndex,
      dataType === 'FOCUS' ? mainData.selectedTasks.FOCUS : mainData.selectedTasks.BACKLOG,
    );
    endIndex = reOrderedData[2] as number;
  }

  log('bulkSaveTaskSequence', reOrderedData[0], reOrderedData[1]);
  let counter = endIndex;
  let payload: any[] = [];
  let tempArr = (reOrderedData[0] as TaskObjectType[]).filter(
    (item) => !(reOrderedData[1] as TaskObjectType[]).includes(item),
  );
  (reOrderedData[1] as TaskObjectType[]).forEach((item) => {
    tempArr = insertOnIndex(tempArr, counter, item);
    const newSequence = getNewSequence(counter, tempArr);
    tempArr[counter] = {
      ...tempArr[counter],
      sequence: newSequence,
    };
    if (
      (reOrderedData[0] as TaskObjectType[]).length <= counter ||
      typeof reOrderedData[0][counter] === 'undefined'
    )
      return;
    reOrderedData = (reOrderedData as TaskObjectType[][]).map((item, ind) => {
      if (ind === 0) {
        return item.map((subItem, idx) => {
          if (idx === counter) {
            return {
              ...subItem,
              sequence: newSequence,
            };
          }
          return subItem;
        });
      }
      return item;
    });

    const req = {
      taskId: item.id,
      sequence: newSequence,
      taskType: dataType.toUpperCase(),
      parentTaskId: 0,
    };
    payload = [...payload, req];
    counter = counter + 1;
  });
  log('bulkSaveTaskSequence', payload);

  reOrderedData[0][endIndex].animation = 'REORDER_TASK';
  if (movedFromDataType === 'FOCUS' && dataType === 'BACKLOG')
    reOrderedData[0][endIndex].animation = 'REORDER_TASK_DELAYED';
  addTaskData(dataType, reOrderedData[0] as TaskObjectType[], stateType);
  for (let i = 0; i < (reOrderedData[1] as TaskObjectType[]).length; i++) {
    const task = reOrderedData[0][i];

    if (task.isTemp) return;

    task.sequenceController.abort();

    const newRequestSource = new AbortController();
    updateTaskAbortControllerToken(
      dataType,
      'sequenceController',
      i,
      newRequestSource,
      undefined,
      stateType,
    );
  }
  if (stateType === 'TEMPLATE') {
    return;
  }

  await bulkUpdateTaskSequenceAPI(payload, stateType);
}
