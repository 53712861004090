import { memo } from 'preact/compat';

type InfoIconPropsType = {
  tempId: string;
  stateType: StateType;
  showTaskInfo: () => void;
  overlayClass?: string;
};

function InfoIcon(props: InfoIconPropsType) {
  const { tempId, stateType, showTaskInfo, overlayClass } = props;

  // log('render components/TaskItems/IndividualItem/InfoIcon');
  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };
  return (
    <div className='item-container info-container' key={`info-con-${tempId}`}>
      <button className='info-btn' onClick={showTaskInfo} />
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: InfoIconPropsType, nextProps: InfoIconPropsType) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  if (prevProps.showTaskInfo !== nextProps.showTaskInfo) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  return true;
}

export default memo(InfoIcon, areEqual);
