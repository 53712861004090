import { useEffect, useState, Fragment } from 'preact/compat';
import { formatDateForView } from 'utils/task';
import {
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import moment from 'moment';
import { Strings } from 'resources';
import Switch from 'react-switch';
import { useRecoilValue } from 'recoil';
import { tutorialDataSelector } from 'recoil/TutorialState';
import { updateFlag } from 'recoil/TutorialState/update';
import { useNavigate } from 'react-router-dom';

export default function TutorialPortfolio() {
  const tutorialData = useRecoilValue(tutorialDataSelector);
  const [showArchived, setShowArchived] = useState(tutorialData.flags.showAdminPage ? true : false);

  const navigate = useNavigate();
  useEffect(() => {
    if (tutorialData.flags.showAdminPage) {
      setTimeout(() => {
        const ele = document.getElementById('projects-details-con');
        ele?.scrollTo(ele.scrollWidth, 0);
      }, 100);
    } else {
      setShowArchived(false);
      setTimeout(() => {
        const ele = document.getElementById('projects-details-con');
        ele?.scrollTo(0, 0);
      }, 100);
    }
  }, [tutorialData.flags.showAdminPage]);

  const getStatusPriority = (status?: ProjectStatusType) => {
    switch (status) {
      case 'on track':
        return 0;
      case 'at risk':
        return 1;
      case 'off track':
        return 2;
      default:
        return 3;
    }
  };

  const compare = (valueA: any, valueB: any, sortOrder: SortOrderType) => {
    if (sortOrder === 'asc') {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  };

  // const getProjectStatusText = (project: ProjectObjectType) => {
  //   if (project.archived) {
  //     return 'archived';
  //   }
  //   return project.status ? project.status.toLowerCase() : '';
  // };

  // const renderProjectStatus = (project: ProjectObjectType) => {
  //   return (
  //     <Fragment>
  //       <span className='project-item-title'>{Strings.status}</span>
  //       <span className={`project-status ${getProjectStatusClass(project)}`}>
  //         {getProjectStatusText(project)}
  //       </span>
  //     </Fragment>
  //   );
  // };

  // const getProjectStatusClass = (project: ProjectObjectType) => {
  //   const status = getProjectStatusText(project);

  //   switch (status) {
  //     case 'on track':
  //       return 'success';
  //     case 'at risk':
  //       return 'warning';
  //     case 'off track':
  //       return 'danger';
  //     case 'archived':
  //       return 'archived';
  //     default:
  //       return '';
  //   }
  // };

  const renderProjectCompletedPoints = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.completed_points}</span>
        <span className='project-points'>
          <span className='project-points-title'>{`${Strings.completed_points}: `}</span>
          {project.completedPoints}
        </span>
      </Fragment>
    );
  };

  const renderProjectInFocusPoints = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.in_focus_points}</span>
        <span className='project-points'>
          <span className='project-points-title'>{`${Strings.in_focus_points}: `}</span>
          {project.focusPoints}
        </span>
      </Fragment>
    );
  };

  const renderProjectRemainingPoints = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.points}</span>
        <span className='project-points'>
          <span className='project-points-title'>{`${Strings.remaining_points}: `}</span>
          {project.remainingPoints}
        </span>
      </Fragment>
    );
  };

  const getProjectDueDatesText = (project: ProjectObjectType) => {
    const startDate = project.startDate ? formatDateForView(project.startDate) : '';
    const dueDate = project.dueDate ? formatDateForView(project.dueDate) : '';

    if (startDate === '' && dueDate === '') {
      return (
        <Fragment>
          <span className='project-item-title'>{Strings.date}</span>
          <span className='no-dates'>{Strings.no_date}</span>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <span className='project-item-title'>{Strings.date}</span>
        <span className='project-due-dates'>
          {`${startDate}${startDate && dueDate ? ' - ' : ''}${dueDate}`}
        </span>
      </Fragment>
    );
  };

  const getSortIcon = (name: SortByType) => {
    const { sortByProject, sortOrderProject } = tutorialData;
    if (sortByProject === name) {
      if (sortOrderProject === 'asc') return faSortAlphaDown;
      else return faSortAlphaUp;
    }

    return faSortAmountDown;
  };

  const isSelected = (name: SortByType) => {
    const { sortByProject } = tutorialData;

    if (sortByProject === name) return 'selected';

    return '';
  };

  const sortBy = () => {};

  const getFilteredProjects = () => {
    let filteredUsers: ProjectObjectType[] = [];
    if (showArchived) {
      filteredUsers = tutorialData.allProjects.filter((project) => {
        return project.archived === true;
      });
    } else {
      filteredUsers = tutorialData.allProjects.filter((project) => {
        return project.archived !== true;
      });
    }
    return filteredUsers;
  };
  const getSortedData = () => {
    const { sortByProject, sortOrderProject } = tutorialData;
    const allProjects = getFilteredProjects();
    return allProjects.sort(function (a, b) {
      let valueA;
      let valueB;
      if (sortByProject === 'status' && a.status !== '' && b.status !== '') {
        valueA = getStatusPriority(a.status);
        valueB = getStatusPriority(b.status);
      } else {
        valueA = a[sortByProject];
        valueB = b[sortByProject];
      }

      if (sortByProject === 'display') {
        return compare(valueA, valueB, sortOrderProject);
      } else {
        if (sortOrderProject === 'asc') return +(valueA > valueB) || -(valueA < valueB);
        else return +(valueB > valueA) || -(valueB < valueA);
      }
    });
  };

  const projectArchiveUpdated = () => {
    updateFlag('unarchiveCompletion', true);
    navigate('/user-tutorial');
  };

  const renderOverlay = (styleClass?: string) => {
    return <div className={`tutorial-overlay ${styleClass}`}></div>;
  };

  const renderProjectArchived = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.archived}</span>
        <span className='project-archive'>
          {/** @ts-ignore */}
          <Switch
            onChange={() => projectArchiveUpdated()}
            checked={project.archived ? project.archived : false}
            height={24}
            handleDiameter={14}
            onColor='#6666ff'
            offColor='#e0e0ff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </span>
      </Fragment>
    );
  };

  const archiveCheckboxSelected = () => {
    setShowArchived(!showArchived);
    updateFlag('showAdminPage', true);
    navigate('/admin/portfolio/user-tutorial');
  };

  const renderProject = (project: ProjectObjectType, index: number) => {
    return (
      <div
        className={`project-main-container ${
          tutorialData.flags.showAdminPage ? 'hide-overlay' : ''
        }`}
        key={index}
      >
        <div className='project-in-container' id={`project-in-container-${index}`}>
          <div className='project-item project-name-con'>
            <span className='project-name'>{project.display}</span>
          </div>
          <div className='rightCon'>
            {/* <div className='project-item project-name-right'>
              <span className='project-item-title'>{Strings.project_name}</span>
              <span className='project-name'>{project.display}</span>
            </div> */}
            {/* <div className='project-item right-item'>{renderProjectStatus(project)}</div> */}
            <div className='project-item'>{renderProjectCompletedPoints(project)}</div>
            <div className='project-item right-item'>{renderProjectInFocusPoints(project)}</div>
            <div className='project-item'>{renderProjectRemainingPoints(project)}</div>
            <div className='project-item right-item'>{getProjectDueDatesText(project)}</div>
            <div className='project-item'>{renderProjectArchived(project)}</div>
          </div>
        </div>
        {renderOverlay(tutorialData.flags.showAdminPage ? 'hide-overlay' : '')}
      </div>
    );
  };
  return (
    <Fragment>
      <div className='title-container-archive'>
        <div className='checkbox-container'>
          <input
            type='checkbox'
            id='archived'
            name='archived'
            checked={showArchived}
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            onChange={() => archiveCheckboxSelected()}
          />
          <label htmlFor='archived'>{Strings.show_archived}</label>
          {renderOverlay(tutorialData.flags.showArchiveCheckbox ? 'hide-overlay' : '')}
        </div>
        {renderOverlay()}
      </div>
      <div className='projects-details-con' id='projects-details-con'>
        <div
          className={`project-main-container head ${
            tutorialData.flags.showAdminPage ? 'hide-overlay' : ''
          }`}
        >
          <div className='project-container-head'>
            <div className='project-item project-name-con'>
              <span className={`sort-icon ${isSelected('display')}`} onClick={() => sortBy()}>
                <FontAwesomeIcon icon={getSortIcon('display')} />
              </span>
              <span className='project-name'>{` ${Strings.projects}`}</span>
            </div>
            <div className='rightCon'>
              {/* <div className='project-item project-name-right'>
                <span
                  className={`sort-icon ${isSelected('display')}`}
                  onClick={() => sortBy()}
                >
                  <FontAwesomeIcon icon={getSortIcon('display')} />
                </span>
                {` ${Strings.projects}`}
              </div>
              <div className='project-item'>
                <span className='project-status'>
                  <span
                    className={`sort-icon ${isSelected('status')}`}
                    onClick={() => sortBy()}
                  >
                    <FontAwesomeIcon icon={getSortIcon('status')} />
                  </span>
                  {` ${Strings.status}`}
                </span>
              </div> */}
              <div className='project-item'>
                <span className='project-points'>
                  <span
                    className={`sort-icon ${isSelected('completedPoints')}`}
                    onClick={() => sortBy()}
                  >
                    <FontAwesomeIcon icon={getSortIcon('completedPoints')} />
                  </span>
                  {` ${Strings.points_completed}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-points'>
                  <span
                    className={`sort-icon ${isSelected('focusPoints')}`}
                    onClick={() => sortBy()}
                  >
                    <FontAwesomeIcon icon={getSortIcon('focusPoints')} />
                  </span>
                  {` ${Strings.points_in_focus}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-points'>
                  <span
                    className={`sort-icon ${isSelected('remainingPoints')}`}
                    onClick={() => sortBy()}
                  >
                    <FontAwesomeIcon icon={getSortIcon('remainingPoints')} />
                  </span>
                  {` ${Strings.points}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-due-dates'>
                  <span className={`sort-icon ${isSelected('startDate')}`} onClick={() => sortBy()}>
                    <FontAwesomeIcon icon={getSortIcon('startDate')} />
                  </span>
                  {` ${Strings.date}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-archive'>
                  <span className={`sort-icon ${isSelected('archived')}`} onClick={() => sortBy()}>
                    <FontAwesomeIcon icon={getSortIcon('archived')} />
                  </span>
                  {` ${Strings.archived}`}
                </span>
              </div>
            </div>
          </div>
          {renderOverlay(tutorialData.flags.showAdminPage ? 'hide-overlay' : '')}
        </div>
        {getSortedData().map((project, index) => renderProject(project, index))}
        {renderOverlay()}
      </div>
    </Fragment>
  );
}
