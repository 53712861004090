import { atom, DefaultValue, selector } from 'recoil';
import { currentHighlightedTaskState } from 'recoil/ProjectState';

export const templateFocusTaskDataState = atom<TaskObjectType[] | null>({
  key: 'templateFocusData',
  default: null,
});

export const templateFocusTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'templateFocusFilteredData',
  default: null,
});

export const templateFocusTaskLoadingState = atom<boolean>({
  key: 'templateFocusLoading',
  default: false,
});

export const templateFocusTaskAbortState = atom<AbortController>({
  key: 'templateFocusAbortController',
  default: new AbortController(),
});

export const templateFocusTaskSizeState = atom<number>({
  key: 'templateFocusTaskSize',
  default: 0,
});

export const templateFocusTasksState = selector<TaskListType>({
  key: 'templateFocus',
  get: ({ get }) => {
    const data = get(templateFocusTaskDataState);
    const filteredData = get(templateFocusTaskFilteredDataState);
    const isLoading = get(templateFocusTaskLoadingState);
    const abortController = get(templateFocusTaskAbortState);
    const size = get(templateFocusTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(templateFocusTaskDataState, value);
      set(templateFocusTaskFilteredDataState, value);
      set(templateFocusTaskLoadingState, value);
      set(templateFocusTaskAbortState, value);
      set(templateFocusTaskSizeState, value);
      return;
    }
    set(templateFocusTaskDataState, value.data);
    set(templateFocusTaskFilteredDataState, value.filteredData);
    set(templateFocusTaskLoadingState, value.isLoading);
    set(templateFocusTaskAbortState, value.abortController);
    set(templateFocusTaskSizeState, value.size);
  },
});

export const templateBacklogTaskDataState = atom<TaskObjectType[] | null>({
  key: 'templateBacklogData',
  default: null,
});

export const templateBacklogTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'templateBacklogFilteredData',
  default: null,
});

export const templateBacklogTaskLoadingState = atom<boolean>({
  key: 'templateBacklogLoading',
  default: false,
});

export const templateBacklogTaskAbortState = atom<AbortController>({
  key: 'templateBacklogAbortController',
  default: new AbortController(),
});

export const templateBacklogTaskSizeState = atom<number>({
  key: 'templateBacklogTaskSize',
  default: 0,
});

export const templateBacklogTasksState = selector<TaskListType>({
  key: 'templateBacklog',
  get: ({ get }) => {
    const data = get(templateBacklogTaskDataState);
    const filteredData = get(templateBacklogTaskFilteredDataState);
    const isLoading = get(templateBacklogTaskLoadingState);
    const abortController = get(templateBacklogTaskAbortState);
    const size = get(templateBacklogTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(templateBacklogTaskDataState, value);
      set(templateBacklogTaskFilteredDataState, value);
      set(templateBacklogTaskLoadingState, value);
      set(templateBacklogTaskAbortState, value);
      set(templateBacklogTaskSizeState, value);
      return;
    }
    set(templateBacklogTaskDataState, value.data);
    set(templateBacklogTaskFilteredDataState, value.filteredData);
    set(templateBacklogTaskLoadingState, value.isLoading);
    set(templateBacklogTaskAbortState, value.abortController);
    set(templateBacklogTaskSizeState, value.size);
  },
});

export const allTemplatesState = atom<TemplateType[] | null>({
  key: 'allTemplates',
  default: null,
});

export const currentTemplateIdState = atom<string | null>({
  key: 'currentTemplateId',
  default: null,
});

export const templateTaskFiltersState = atom<TaskFiltersObjectType>({
  key: 'templatesTaskFilters',
  default: {
    sizeFilter: null,
    tagFilter: null,
    assigneeFilter: null,
    dateFilter: null,
    projectFilter: null,
    searchTextFilter: '',
  },
});

export const templateTaskUnfilteredListState = atom<TaskTagListType>({
  key: 'templateTaskUnfilteredList',
  default: {
    FOCUS: [],
    BACKLOG: [],
    COMPLETED: [],
  },
});

export const allTemplateTagsState = atom<TagObjectType[]>({
  key: 'allTemplateTags',
  default: [],
});

export const currentHighlightedTask = atom<TaskObjectType | null>({
  key: 'currentHighlightedTaskInTemplate',
  default: null,
});

export const templateWebsocketStatusState = atom<WebsocketStatusType>({
  key: 'templateWbsocketStatus',
  default: 'INIT',
});

export const templateSelectedFocusTaskState = atom<SelectedTaskIdsType[]>({
  key: 'templateSelectedFocusTask',
  default: [],
});

export const templateSelectedBacklogTaskState = atom<SelectedTaskIdsType[]>({
  key: 'templateSelectedBacklogTask',
  default: [],
});

export const templateSelectedCompletedTaskState = atom<SelectedTaskIdsType[]>({
  key: 'templateSelectedCompletedTask',
  default: [],
});

export const templateSelectedTempIdsTaskState = atom<SelectedTaskIdsType[]>({
  key: 'templateSelectedTempIdsTask',
  default: [],
});

export const templateSelectedTaskTypeState = atom<TaskType | null>({
  key: 'templateSelectedTaskType',
  default: null,
});

export const templateSelectedTaskDragIdState = atom<string | null>({
  key: 'templateSelectedTaskDragId',
  default: null,
});

export const templateSelectedTasksState = selector<SelectedTasksType>({
  key: 'templateSelectedTasks',
  get: ({ get }) => {
    const FOCUS = get(templateSelectedFocusTaskState);
    const BACKLOG = get(templateSelectedBacklogTaskState);
    const COMPLETED = get(templateSelectedCompletedTaskState);
    const tempIds = get(templateSelectedTempIdsTaskState);
    const lastSelectedTaskType = get(templateSelectedTaskTypeState);
    const draggingTaskId = get(templateSelectedTaskDragIdState);
    return {
      FOCUS,
      BACKLOG,
      COMPLETED,
      tempIds,
      lastSelectedTaskType,
      draggingTaskId,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(templateSelectedFocusTaskState, value);
      set(templateSelectedBacklogTaskState, value);
      set(templateSelectedCompletedTaskState, value);
      set(templateSelectedTempIdsTaskState, value);
      set(templateSelectedTaskTypeState, value);
      set(templateSelectedTaskDragIdState, value);
      return;
    }
    set(templateSelectedFocusTaskState, value.FOCUS);
    set(templateSelectedBacklogTaskState, value.BACKLOG);
    set(templateSelectedCompletedTaskState, value.COMPLETED);
    set(templateSelectedTempIdsTaskState, value.tempIds);
    set(templateSelectedTaskTypeState, value.lastSelectedTaskType);
    set(templateSelectedTaskDragIdState, value.draggingTaskId);
  },
});

export const templateAutoFocusState = atom<string | null>({
  key: 'templateAutoFocus',
  default: null,
});

export const templateTaskFetchErrorState = atom<string | null>({
  key: 'templateTaskFetchError',
  default: null,
});

export const templateLoadingState = atom<boolean>({
  key: 'isTemplateLoading',
  default: false,
});

export const selectedTemplateNotificationTaskIdState = atom<string | null>({
  key: 'selectedTemplateNotificationTaskId',
  default: null,
});

export const templateDataSelector = selector<TemplateDataType>({
  key: 'templateData',
  get: ({ get }) => {
    const allTemplates = get(allTemplatesState);
    const currentTemplateId = get(currentTemplateIdState);
    const taskFilters = get(templateTaskFiltersState);
    const unFilteredList = get(templateTaskUnfilteredListState);
    const allTemplateTags = get(allTemplateTagsState);
    const currentHighlightedTask = get(currentHighlightedTaskState);
    const FOCUS = get(templateFocusTasksState);
    const BACKLOG = get(templateBacklogTasksState);
    const websocketStatus = get(templateWebsocketStatusState);
    const selectedTasks = get(templateSelectedTasksState);
    const autoFocus = get(templateAutoFocusState);
    const error = get(templateTaskFetchErrorState);
    const isLoading = get(templateLoadingState);
    const selectedTemplateNotificationTaskId = get(selectedTemplateNotificationTaskIdState);

    // then combine into desired shape (object) and return:
    return {
      allTemplates,
      currentTemplateId,
      taskFilters,
      unFilteredList,
      allTemplateTags,
      currentHighlightedTask,
      FOCUS,
      BACKLOG,
      websocketStatus,
      selectedTasks,
      autoFocus,
      error,
      isLoading,
      selectedTemplateNotificationTaskId,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(allTemplatesState, value);
      set(currentTemplateIdState, value);
      set(templateTaskFiltersState, value);
      set(templateTaskUnfilteredListState, value);
      set(allTemplateTagsState, value);
      set(currentHighlightedTaskState, value);
      set(templateFocusTasksState, value);
      set(templateBacklogTasksState, value);
      set(templateWebsocketStatusState, value);
      set(templateSelectedTasksState, value);
      set(templateAutoFocusState, value);
      set(templateTaskFetchErrorState, value);
      set(templateLoadingState, value);
      set(selectedTemplateNotificationTaskIdState, value);
      return;
    }
    set(allTemplatesState, value.allTemplates);
    set(currentTemplateIdState, value.currentTemplateId);
    set(templateTaskFiltersState, value.taskFilters);
    set(templateTaskUnfilteredListState, value.unFilteredList);
    set(allTemplateTagsState, value.allTemplateTags);
    set(currentHighlightedTaskState, value.currentHighlightedTask);
    set(templateFocusTasksState, value.FOCUS);
    set(templateBacklogTasksState, value.BACKLOG);
    set(templateWebsocketStatusState, value.websocketStatus);
    set(templateSelectedTasksState, value.selectedTasks);
    set(templateAutoFocusState, value.autoFocus);
    set(templateTaskFetchErrorState, value.error);
    set(templateLoadingState, value.isLoading);
    set(selectedTemplateNotificationTaskIdState, value.selectedTemplateNotificationTaskId);
  },
});
