import { memo, useEffect, useState } from 'preact/compat';
import { useAlert } from 'react-alert';

function TaskCheckbox(props: TaskCheckboxPropsType) {
  const { tempId, itemChecked, isDisabled, checkTheTask, parentId, parentChecked } = props;
  const [checked, setChecked] = useState(itemChecked);
  const alert = useAlert();

  const handleClick = () => {
    if (parentId && parentChecked && checked) {
      alert.error('Please uncheck the main task to continue.');
      return;
    }
    setChecked(!checked);
    checkTheTask();
  };

  useEffect(() => {
    setChecked(itemChecked);
  }, [itemChecked]);

  // log('render components/TaskItems/IndividualItem/Checkbox');

  return (
    <div className='checkbox-main-con'>
      <div className='checkbox-con' onClick={() => handleClick()} key={`checkbox-con-${tempId}`}>
        <input
          checked={checked}
          className='inp-cbx'
          id={`checkbox-${tempId}`}
          type='checkbox'
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          onChange={() => {}}
          style={{ display: 'none' }}
          disabled={isDisabled}
        />
        <label className='cbx'>
          <span className='checkbox-svg-con'>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: TaskCheckboxPropsType, nextProps: TaskCheckboxPropsType) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.itemChecked !== nextProps.itemChecked) return false;

  if (prevProps.parentChecked !== nextProps.parentChecked) return false;

  if (prevProps.parentId !== nextProps.parentId) return false;

  if (prevProps.isDisabled !== nextProps.isDisabled) return false;

  if (prevProps.checkTheTask !== nextProps.checkTheTask) return false;

  return true;
}

export default memo(TaskCheckbox, areEqual);
