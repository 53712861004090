import { useState, Fragment, useRef, useEffect, TargetedEvent } from 'preact/compat';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/index.css';
import 'react-datepicker/dist/react-datepicker.css';

import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Input from 'components/UI/Input';
import SmallButton from 'components/UI/SmallButton';
import { Validation } from 'utils';
import { Strings } from 'resources';
import { currentDay } from 'utils/task';
import { useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';

export default function CreateTheme() {
  const alert = useAlert();
  const inputFileRef = useRef<HTMLInputElement[] | null[]>([]);
  const navigate = useNavigate();
  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      name: Strings.name,
      key: 'name',
      value: '',
      type: 'text',
      maxLength: 150,
      rules: 'required|max:150',
      title: Strings.enter_name,
    },
    {
      name: Strings.difficulty,
      key: 'difficulty',
      type: 'dropdown',
      value: '',
      rules: 'required_Object',
      title: Strings.choose_difficulty,
      dropDownOptions: [
        Strings.difficulty_easy,
        Strings.difficulty_medium,
        Strings.difficulty_hard,
        Strings.difficulty_helldive,
      ],
    },
    {
      name: Strings.rarity,
      key: 'rarity',
      type: 'dropdown',
      value: '',
      rules: 'required_Object',
      title: Strings.choose_rarity,
      dropDownOptions: [
        Strings.rarity_common,
        Strings.rarity_rare,
        Strings.rarity_epic,
        Strings.rarity_legendary,
        Strings.rarity_majestic,
        Strings.rarity_grandmaster,
      ],
    },
    {
      name: Strings.upload_theme_files,
      key: 'previewWeb',
      type: 'file',
      value: '',
      rules: 'required_Object',
      title: Strings.web_preview,
    },
    {
      name: Strings.upload_theme_files,
      key: 'previewMobile',
      type: 'file',
      value: '',
      rules: 'required_Object',
      title: Strings.mobile_preview,
    },
    {
      name: Strings.cost,
      key: 'price',
      value: '',
      type: 'text',
      rules: 'required|price',
      title: Strings.enter_cost,
    },
    {
      name: Strings.quantity,
      key: 'quantity',
      value: '',
      type: 'text',
      rules: 'required|number',
      title: Strings.enter_quantity,
    },
    {
      name: Strings.release_date_time,
      key: 'timeOfLaunch',
      value: '',
      type: 'date',
      rules: 'required_Object',
      title: Strings.choose_release_date,
    },
    {
      name: Strings.release_date_time,
      key: 'timeOfLaunch',
      value: '',
      type: 'time',
      rules: 'required_Object',
      title: Strings.choose_release_time,
    },
    {
      name: Strings.theme_zip,
      key: 'theme',
      value: '',
      type: 'file',
      rules: 'required_Object',
      title: Strings.upload_theme_zip,
    },
  ]);
  const [isLoading] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const navigationData = useRecoilValue(navigationDataSelector);

  useEffect(() => {
    inputFileRef.current = inputFileRef.current.slice(0, 3);
  }, []);

  const fieldUpdated = (value: string | File | Date | null, index: number, type?: string) => {
    if (type === 'date') {
      let date = moment(value as Date);
      const current = moment(currentDay());

      const sameDay = date.startOf('day').isSame(current.startOf('day'), 'day');

      if (sameDay) {
        date = moment(value as Date);
        const selectedHour = date.hour();
        const current = moment(currentDay());
        const currentHour = current.hour();

        if (selectedHour <= currentHour + 2) {
          date.set('hour', currentHour + 2);
          // @ts-ignore
          value = new Date(date);
        }
      }
    }

    const newFieldArray = [...fields];
    newFieldArray[index].value = value ? (value as string) : '';
    setFields(newFieldArray);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;
    let error = '';

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        error = field.errors[0];
        break;
      }
    }

    return { hasError, error };
  };

  const renderInput = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`input-title-field-${index}`} className='input-title'>
          {field.name}
        </div>
        <Input
          id={`input-${index}`}
          placeholder={field.title ? field.title : ''}
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
          onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
          key={index}
          touched={field.touched}
          value={field.value as string}
          errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
          icon={field.icon}
          secure={field.secure}
        />
      </Fragment>
    );
  };

  const renderFile = (
    field: ValidationFieldType,
    index: number,
    acceptType: string,
    showHeader: boolean = true,
    baseFileType?: string,
  ) => {
    return (
      <Fragment>
        {showHeader && (
          <div id={`file-title-${index}`} className='input-title'>
            {field.name}
          </div>
        )}
        <div id={`theme-file-upload-con-${index}`} className='theme-file-upload'>
          <input
            ref={(el) => (inputFileRef.current[index] = el)}
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            type='file'
            onChange={(event) =>
              fieldUpdated((event.target as HTMLInputElement).files![0], index, baseFileType)
            }
            id={`theme-file-upload-${index}`}
            accept={acceptType}
            hidden
          />
          {field.value === '' || acceptType !== 'image/*' ? (
            <label id={`browse-file-title-${index}`} className='file-title'>
              {field.value === '' ? field.title : field.value}
            </label>
          ) : (
            <img
              className='img-preview'
              src={URL.createObjectURL(field.value as File)}
              alt='test'
            />
          )}
          <label
            id={`browse-file-btn-${index}`}
            className='browse-file-btn'
            onClick={() => {
              if (inputFileRef.current[index]) inputFileRef.current[index]!.click();
            }}
          >
            {Strings.browse}
          </label>
        </div>
      </Fragment>
    );
  };

  const renderDate = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`date-in-con-${index}`} className='date-in-con'>
          <DatePicker
            id={`datepicker-${index}`}
            selected={field.value ? field.value : null}
            className='calender-input'
            placeholderText={field.title}
            calendarClassName='theme-calendar-con'
            onChange={(date) => fieldUpdated(date, index, 'date')}
            dateFormat='MMMM d, yyyy'
            minDate={new Date()}
          />
        </div>
      </Fragment>
    );
  };

  const renderTime = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`time-in-con-${index}`} className='time-in-con'>
          <DatePicker
            id={`datepicker-${index}`}
            selected={field.value ? field.value : null}
            className='calender-input'
            placeholderText={field.title}
            calendarClassName='theme-calendar-con'
            onChange={(date) => fieldUpdated(date, index, 'time')}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption='Time'
            dateFormat='h:mm aa'
            timeFormat='HH:mm'
          />
          <span id={`input-icon-con-${index}`} className='input-icon-con'>
            <FontAwesomeIcon id={`calendar-icon-${index}`} icon={faChevronDown} />
          </span>
        </div>
      </Fragment>
    );
  };

  const submit = (event: TargetedEvent) => {
    event.preventDefault();

    if (isLoading || isMenuOpen) return;

    const fieldsWithError = Validation.validate(fields);
    const { hasError, error } = checkErrors(fieldsWithError);

    if (hasError) {
      alert.error(error);
      return;
    }

    navigate('/super-admin/create-theme-level', {
      state: {
        payload: fields,
      },
    });
  };

  const renderDropDown = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`input-title-field-${index}`} className='input-title'>
          {field.name}
        </div>
        <Menu
          menuButton={
            <MenuButton
              placeholder={field.title}
              onClick={() => setMenuOpen(true)}
              className={field.value !== null && 'text-change-color'}
            >
              {field.value === null ? field.title : field.value}
              <FontAwesomeIcon
                id='size-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
            </MenuButton>
          }
          transition
        >
          {field.dropDownOptions &&
            field.dropDownOptions.map((option, i) => {
              return (
                <MenuItem key={i} value={option} onClick={(e) => dropdownItemSelected(e, index)}>
                  {option}
                </MenuItem>
              );
            })}
        </Menu>
      </Fragment>
    );
  };

  const dropdownItemSelected = (e: any, index: number) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = e.value;
    setFields(newFieldArray);
    setMenuOpen(false);
  };

  const renderRightContainerFields = () => {
    return (
      <div id='create-theme-fields-main-container' className='fields-main-container'>
        <div id='theme-name-input-con' className='input-main-con'>
          {renderInput(fields[5], 5)}
        </div>
        <div id='theme-name-input-con' className='input-main-con'>
          {renderInput(fields[6], 6)}
        </div>
        <div id='create-theme-time-launch-con' className='input-main-con'>
          <div id={`input-title-${7}`} className='input-title'>
            {fields[7].name}
          </div>
          {renderDate(fields[7], 7)}
          {renderTime(fields[8], 8)}
        </div>
        <div id='create-theme-zip-file-con' className='input-main-con'>
          {renderFile(
            fields[9],
            9,
            'zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
            true,
            'zip',
          )}
        </div>
      </div>
    );
  };

  const renderLeftContainerFields = () => {
    return (
      <div id='create-theme-fields-main-container' className='fields-main-container'>
        <div id='theme-name-input-con' className='input-main-con'>
          {renderInput(fields[0], 0)}
        </div>
        <div id='theme-difficulty-input-con' className='input-main-con'>
          {renderDropDown(fields[1], 1)}
        </div>

        <div id='theme-rarity-input-con' className='input-main-con'>
          {renderDropDown(fields[2], 2)}
        </div>
        <div
          id='create-theme-attachments-input-main-con'
          className='input-main-con theme-attachment-con'
        >
          <div id={`file-title-${3}`} className='input-title'>
            {fields[3].name}
          </div>
          <div id='theme-file-con' className='file-con'>
            {renderFile(fields[3], 3, 'image/*', false, 'file')}
            {renderFile(fields[4], 4, 'image/*', false, 'file')}
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form
        id='create-theme-form'
        className='create-theme-form'
        onSubmit={(event) => submit(event)}
      >
        <div className='form-base-container'>
          <div className='left-form-con'>
            <div className='subtitle-container'>
              <div className='list-title'>{Strings.create_new_theme}</div>
            </div>
            {renderLeftContainerFields()}
          </div>
          <div className='right-form-con'>
            <div className='subtitle-container'>
              <div className='list-title'>{Strings.create_new_theme}</div>
            </div>
            {renderRightContainerFields()}
          </div>
        </div>

        <div id='create-theme-btn' className='btn-container'>
          <SmallButton disabled={isLoading} loading={isLoading}>
            {Strings.continue_arrow}
          </SmallButton>
        </div>
      </form>
    );
  };

  return (
    <div
      id='create-theme-screen-container'
      className={`create-theme-screen-container ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div className='title-container'>
        <div className='page-title'>{Strings.super_admin}</div>
      </div>

      {renderForm()}
    </div>
  );
}
