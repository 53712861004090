import { useState, useMemo, useEffect, Fragment } from 'preact/compat';
import { useAlert } from 'react-alert';
import UIfx from 'uifx';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SmallButton from 'components/UI/SmallButton';
import { enrollUser } from 'services/LeaderboardService';
import { reFetchUserSession, getUserId } from 'services/AuthService';
import SudoCharacterGIF from 'assets/images/sudo-character.gif';
import InnominateIcon from 'assets/images/tutorial/innominate.png';
import { Strings } from 'resources';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import {
  popupTabPositionState,
  showLeaderboardModalState,
  userEnrolledState,
  navigationDataSelector,
  showThemesPopupModalState,
} from 'recoil/NavigationState';

export default function LeaderboardIntro() {
  const navigate = useNavigate();
  const userId = getUserId();
  const alert = useAlert();

  const navigationData = useRecoilValue(navigationDataSelector);
  const accountData = useRecoilValue(accountDataSelector);

  // purchasedThemes
  const [displayName, setDisplayName] = useState('');
  const [images, setImages] = useState<ImageListType>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchasedThemeExist, setPurchasedThemeExistValue] = useState(false);

  const setThemePopupModalStatus = useSetRecoilState(showThemesPopupModalState);
  const setThemePopupTabPosition = useSetRecoilState(popupTabPositionState);
  const setLeaderboardModalStatus = useSetRecoilState(showLeaderboardModalState);
  const setEnrollStatus = useSetRecoilState(userEnrolledState);

  useEffect(() => {
    setPurchasedThemeExistValue(
      accountData.purchasedThemes && accountData.purchasedThemes.length > 1,
    );
  }, [accountData.purchasedThemes]);

  /*
   * Check if required data is entered or not
   *
   * returns Boolean
   */
  const isDisabled = useMemo(() => {
    if (displayName && images.length > 0) {
      return false;
    }

    return true;
  }, [displayName, images]);

  useEffect(() => {
    accountData.purchasedThemes && accountData.purchasedThemes.length === 1 && playSound();
  }, []);

  const renderLeaderboardCard = () => {
    return (
      <div className={`leaderboard-card-main-con`}>
        <div className={`leaderboard-card-con`}>
          {renderLeaderboardTopContent()}
          {renderLeaderboardBottomContent()}
        </div>
      </div>
    );
  };

  const renderLeaderboardTopContent = () => {
    return (
      <div className='leaderboard-card-top-con'>
        {renderProfilePic()}
        <h2 className='leaderboard-heading'>{Strings.ready_to_compete}</h2>
        <p className='leaderboard-description'>{Strings.leaderboard_description}</p>
        {isPurchasedThemeExist ? (
          <Fragment>
            <div className='input-main-con'>
              {renderThemeCompeteContainer(true)}
              <p className='leaderboard-input-heading'>{Strings.enter_name_heading}</p>
              {renderDisplayContentContainer()}
              <p className='leaderboard-input-heading'>{Strings.leaderboard_profile_image}</p>
            </div>
            {renderDisplayImage()}
          </Fragment>
        ) : (
          renderThemeCompeteContainer(false)
        )}
      </div>
    );
  };

  const renderThemeCompeteContainer = (hasPurchasedTheme: boolean) => {
    return (
      <div className='theme-compete-con'>
        <div className='theme-compete-inner-con'>
          <div className={`${hasPurchasedTheme ? 'check-icon' : 'check-icon disabled'}`}></div>
          <span className={`${hasPurchasedTheme ? 'theme-max' : 'theme-max red-text'}`}>
            {Strings.theme_max_msg}
          </span>
        </div>
        {!hasPurchasedTheme && <p className='theme-buy'>{Strings.theme_buy_msg}</p>}
        {!hasPurchasedTheme && <p className='theme-buy'>{Strings.theme_buy}</p>}
      </div>
    );
  };

  const renderProfilePic = () => {
    const imgSrc = isPurchasedThemeExist ? InnominateIcon : SudoCharacterGIF;
    return (
      <div className='display-pic'>
        <img src={imgSrc} alt='guide-image' />
      </div>
    );
  };

  const renderDisplayContentContainer = () => {
    return (
      <div className='input-container'>
        <div className='text-input '>
          <input
            className='display-name-input'
            value={displayName}
            onChange={(e) => {
              setDisplayName((e.target as HTMLInputElement).value);
            }}
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            placeholder={Strings.enter_display_name}
          />
        </div>
      </div>
    );
  };

  const renderDisplayImage = () => {
    return (
      <div className='image-uploader'>
        <ImageUploading value={images} onChange={onChangeImage} dataURLKey='data_url'>
          {/* @ts-ignore*/}
          {({ imageList, onImageUpload, isDragging, dragProps }) => (
            // write your building UI
            <div className='upload__image-wrapper'>
              <div
                className='image-upload'
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                {getDisplayImage(imageList) ? (
                  <img src={getDisplayImage(imageList)} alt='display-image' />
                ) : (
                  <div className='add-display-img-con'>
                    <p>{Strings.display_pic}</p>
                    <FontAwesomeIcon
                      id='add-display-image-plus'
                      className='plus-icon'
                      icon={faPlus}
                      size='lg'
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </ImageUploading>
      </div>
    );
  };

  const getDisplayImage = (imageList: ImageListType) => {
    if (imageList.length > 0) {
      return imageList[0].data_url;
    }
    return null;
  };

  const onChangeImage = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const renderLeaderboardBottomContent = () => {
    return (
      <div className='leaderboard-card-bottom-con'>
        {renderGoBackBtn()}
        {renderContinueBtn()}
      </div>
    );
  };

  const renderGoBackBtn = () => {
    return (
      <div className='left-btn-con'>
        <SmallButton clicked={goBack}>{Strings.go_back}</SmallButton>
      </div>
    );
  };

  const goBack = () => {
    navigate('/');
  };

  const renderContinueBtn = () => {
    return (
      <div className='right-btn-con'>
        <SmallButton
          clicked={isPurchasedThemeExist ? continueLeaderboard : buyNow}
          disabled={isPurchasedThemeExist ? isDisabled : false}
          loading={isLoading}
        >
          {isPurchasedThemeExist ? Strings.continue_arrow : Strings.buy_now}
        </SmallButton>
      </div>
    );
  };

  const buyNow = () => {
    setThemePopupModalStatus(true);
    setThemePopupTabPosition(1);
    navigate('/');
  };

  const continueLeaderboard = async () => {
    setIsLoading(true);
    const { success } = await enrollUser(getPayload(), userId);

    if (!success) {
      setIsLoading(false);
      alert.error(Strings.enrollment_failure);
      setImages([]);
    } else {
      alert.success(Strings.enrollment_success);
      await reFetchUserSession();
      setIsLoading(false);
      setLeaderboardModalStatus(false);
      setEnrollStatus(true);
    }
  };

  const getPayload = () => {
    const formData = new FormData();
    if (images[0].file) formData.append('file', images[0].file);
    formData.append('name', displayName);
    return formData;
  };

  /**
   * prevent dimissing leaderboard modal if clicked inside contatiner
   * @param {Object} e
   */
  const innerViewClick = (e) => {
    e.stopPropagation();
  };

  function playSound() {
    const fileUrl = `/themes/sounds/no-theme.mp3`;

    const sound = new UIfx(fileUrl);

    sound.play();

    setTimeout(() => {
      removeSoundElement(fileUrl);
    }, 5000);
  }

  function removeSoundElement(url) {
    const id = `uifx-${hash(url)}`;
    const element = document.getElementById(id);

    if (element) element.remove();
  }

  const hash = (str) => {
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  };

  return (
    <div className={`leaderboard-modal-bg ${!navigationData.showLeaderboardModal ? 'hide' : ''}`}>
      <div
        className={`leaderboard-modal-container ${
          !navigationData.showLeaderboardModal ? 'hide' : ''
        }`}
        onClick={innerViewClick}
      >
        <div className={`leaderboard-inner-container`}>{renderLeaderboardCard()}</div>
      </div>
    </div>
  );
}
