import { memo } from 'preact/compat';

type CommentsIconPropsType = {
  commentCount: number;
  tempId?: string;
  stateType: StateType;
  showCommentModal: () => void;
  overlayClass?: string;
};

function CommentsIcon(props: CommentsIconPropsType) {
  const { commentCount, tempId, stateType, showCommentModal, overlayClass } = props;

  // log('render components/TaskItems/IndividualItem/CommentsIcon');
  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };
  return (
    <div className='item-container comment-container' key={`comment-con-${tempId}`}>
      <button className='comment-btn' onClick={showCommentModal}>
        {commentCount && commentCount > 0 ? (
          <span className='comment-count'>{commentCount > 99 ? '99+' : commentCount}</span>
        ) : null}
      </button>

      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: CommentsIconPropsType, nextProps: CommentsIconPropsType) {
  if (prevProps.commentCount !== nextProps.commentCount) return false;

  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  if (prevProps.showCommentModal !== nextProps.showCommentModal) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  return true;
}

export default memo(CommentsIcon, areEqual);
