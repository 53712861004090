import { TargetedEvent, useState } from 'preact/compat';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Input from 'components/UI/Input';
import ClearInput from 'components/UI/ClearInput';
import Button from 'components/UI/Button';
import { Validation, log } from 'utils';
import { parseUserObject } from 'utils/user';
import { Strings } from 'resources';
import {
  getUserSession,
  inviteEmail,
  loginUserInApp,
  createUser,
  uploadWorkspaceImage as uploadWorkspaceImageAPI,
  uploadMailchimpData as uploadMailchimpDataAPI,
} from 'services/AuthService';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';

export default function WorkspaceCreation() {
  const history = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const [images, setImages] = useState<ImageListType>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      autoCapitalize: 'words',
      name: Strings.workspace_name_placeholder,
      key: 'company',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|max:50',
      touched: false,
      placeholder: Strings.workspace_name_placeholder,
    },
  ]);

  const [teammateFields, setTeammateFields] = useState<ValidationFieldType[]>([]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [hasEmailErrors, setHasEmailErrors] = useState(true);
  const [isAddTeamClicked, setAddTeamClicked] = useState(false);

  /**
   * Render Input fields
   * @param {*} field
   * @param {*} index
   */
  const renderField = (field: ValidationFieldType, index: number) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder ? field.placeholder : ''}
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={field.touched}
        value={field.value as string}
        autoCapitalize={field.autoCapitalize}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        secure={field.secure}
      />
    );
  };

  const fieldUpdated = (text: string, index: number) => {
    const newFieldArray: ValidationFieldType[] = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * Render Workspace Email Input fields
   * @param {*} field
   * @param {*} index
   */
  const renderEmailField = (emailField: ValidationFieldType, index: number) => {
    return (
      <ClearInput
        placeholder={emailField.placeholder ? emailField.placeholder : ''}
        onChange={(event) => emailfieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => emailfieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={emailField.touched}
        value={emailField.value as string}
        errorMessage={emailField.errors && emailField.errors.length ? emailField.errors[0] : ''}
        role={emailField.role}
        roleUpdated={(newRole) => userRoleUpdated(newRole, index)}
        menuClicked={menuClicked}
        secure={emailField.secure}
        onClear={() => clearEmailField(index)}
      />
    );
  };

  const menuClicked = () => {
    log('menu clicked');
    setMenuOpen(true);
  };

  const clearEmailField = (index: number) => {
    let newFieldArray = [...teammateFields];
    if (newFieldArray[index].value === '') {
      newFieldArray = teammateFields.filter((item, ind) => ind !== index);
    } else {
      newFieldArray = teammateFields.map((item, ind) => {
        if (ind === index) {
          const tempItem = {
            ...item,
            value: '',
            touched: true,
          };
          return tempItem;
        }
        return item;
      });
    }

    setTeammateFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasEmailErrors(error);
  };

  const emailfieldUpdated = (text: string, index: number) => {
    const newFieldArray = [...teammateFields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setTeammateFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasEmailErrors(error);
  };

  const userRoleUpdated = (role: string, index: number) => {
    const newFieldArray = [...teammateFields];
    newFieldArray[index].role = role;
    setTeammateFields(newFieldArray);
    setMenuOpen(false);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const submit = (event: TargetedEvent) => {
    event.preventDefault();

    if (
      menuOpen ||
      isLoading ||
      (teammateFields.length > 0 && hasEmailErrors) ||
      hasErrors ||
      typeof location.state === 'undefined'
    )
      return;

    if (areYouInvitingYourself()) {
      alert.error(Strings.cant_invite_yourself);
      return;
    }

    const payload = location.state.payload;
    payload['company'] = fields[0].value;
    payload['origin'] = document.location.origin + '/';
    createAccount(payload);
  };

  /*
   * Account creation by calling backend
   * payload object {name, email, password}
   *
   * returns null
   */
  const createAccount = async (payload: any) => {
    setLoadingState(true);
    const { data, success, message } = await createUser(payload);
    if (!success) {
      alert.error(message);
      setLoadingState(false);
      return log(message);
    } else {
      uploadDataOnMailchimp(payload);
      return accountCreationSuccess(data);
    }
  };

  /**
   * upload user data on mailchimp
   */
  const uploadDataOnMailchimp = (payload) => {
    if (document.location.origin !== 'https://app.magictask.io') return;
    log(Cookies.get('_ga'));
    let clientId = '';
    if (
      Cookies.get('_ga') &&
      Cookies.get('_ga').split('.') &&
      Cookies.get('_ga').split('.').length > 1
    ) {
      const elements = Cookies.get('_ga').split('.');
      clientId = `${elements[elements.length - 2]}.${elements[elements.length - 1]}`;
    }
    const tempPayload = {
      email: payload['email'],
      firstname: payload['firstName'],
      lastname: payload['lastName'],
      clientid: clientId,
    };
    log(tempPayload);
    uploadMailchimpDataAPI(tempPayload);
  };

  /*
   * Account creation was successful
   *
   * returns null
   */
  const accountCreationSuccess = (data: any) => {
    if (images.length > 0) {
      uploadWorkspaceImage(data);
    } else {
      addTeammates(data);
    }
  };

  const addTeammates = (data: any) => {
    if (isAddTeamClicked && teammateFields.length > 0) {
      inviteEmailList(data);
    } else {
      loginUser(getPayload(fields, data));
    }
  };

  const areYouInvitingYourself = () => {
    const userEmail = location.state.payload.email.toLowerCase().trim();
    let matched = false;

    teammateFields.map((item) => {
      const newEmail = (item.value as string).toLowerCase().trim();
      if (userEmail === newEmail) matched = true;
    });

    return matched;
  };

  const inviteEmailList = async (responseData: any) => {
    const { success, message } = await inviteEmail(
      getInviteMailPayload(responseData),
      responseData.token,
    );

    if (!success) {
      alert.error(message);
      log(message);
    }

    loginUser(getPayload(fields, responseData));
  };

  const getInviteMailPayload = (responseData: any): InviteEmailPayloadType => {
    const payload: InviteEmailPayloadType = {};

    const userList = {};
    teammateFields.map((member) => {
      const email = (member.value as string).toLowerCase().trim();
      userList[email] = member.role;
    });

    payload['baseUrl'] = document.location.origin + '/';
    payload['users'] = userList;
    payload['invitedBy'] = location.state.payload.email;
    payload['company'] = responseData?.workspace?.id;
    return payload;
  };

  /*
   * User login by calling backend
   * payload object {email, password}
   *
   * returns null
   */
  const loginUser = async (payload: any) => {
    const token = payload.token; // location.state.token;

    const { data, success, message } = await getUserSession(token);
    setLoadingState(false);

    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return userLoginSuccess(data, token);
    }
  };

  /*
   * Account creation was successful
   * userData
   * token
   *
   * returns null
   */
  const userLoginSuccess = (userData: ResponseUserObjectType, token: string) => {
    const user = parseUserObject({ ...userData, authToken: token });
    loginUserInApp(user);
    routeChange();
  };

  /*
   * Get payload for verify email api
   * fields Array
   *
   * returns Object
   */
  const getPayload = (fields: ValidationFieldType[], data: any) => {
    const payload = {};
    const dataPayload = {};

    fields.forEach((field, _index) => {
      if (field.key) dataPayload[field.key] = field.value;
    });

    log('getPayload', location.state);
    payload['userId'] = parseFloat(data.userId); // parseFloat(location.state.userId);
    payload['company'] = fields[0].value; // location.state.workspace;
    payload['token'] = data.token; // location.state.token;
    // payload["data"] = dataPayload;
    // payload["data"]["workspace"]= location.state.workspace;
    return payload;
  };

  const routeChange = () => {
    history('/');
  };

  /**
   * On tap of plus icon,
   * New teammate email field is added
   *
   */
  const addTeammate = () => {
    const newFieldList = [...teammateFields];
    newFieldList.push({
      name: Strings.teammate_email,
      key: 'username',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|email|max:50',
      touched: false,
      placeholder: Strings.teammate_email,
      role: Strings.standard,
    });
    setTeammateFields(newFieldList);
    const fieldsWithError = Validation.validate(newFieldList);
    const error = checkErrors(fieldsWithError);
    setHasEmailErrors(error);
  };

  const addTeamClicked = () => {
    setAddTeamClicked(true);
    addTeammate();
  };

  const getWorkspaceImage = (imageList: ImageListType) => {
    if (imageList.length > 0) {
      return imageList[0].data_url;
    }
    return null;
  };

  const onChangeImage = (imageList: ImageListType) => {
    setImages(imageList);
  };

  const uploadWorkspaceImage = async (accountData: UserObjectType) => {
    const formData = new FormData();

    // Update the formData object
    if (images[0].file) formData.append('file', images[0].file);
    const { success } = await uploadWorkspaceImageAPI(formData, accountData.token);

    if (!success) {
      alert.error(Strings.image_upload_failed);
      setImages([]);
    }
    addTeammates(accountData);
  };

  const renderAddWorkspaceImage = () => {
    return (
      <div className='image-uploader'>
        <ImageUploading value={images} onChange={onChangeImage} dataURLKey='data_url'>
          {/* @ts-ignore*/}
          {({ imageList, onImageUpload, isDragging, dragProps }) => (
            // write your building UI
            <div className='upload__image-wrapper'>
              <div
                className='image-upload'
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                {getWorkspaceImage(imageList) ? (
                  <img src={getWorkspaceImage(imageList)} alt='workspace-image' />
                ) : (
                  <div className='add-workspace-img-con'>
                    <p>{Strings.workspace}</p>
                    <p>{`${Strings.photo} (${Strings.optional})`}</p>
                    <FontAwesomeIcon
                      id='add-workspace-image-plus'
                      className='plus-icon'
                      icon={faPlus}
                      size='lg'
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </ImageUploading>
      </div>
    );
  };

  return (
    <div className='workspace-screen-container'>
      <div className='form-con'>
        <div className='magic-task-logo' alt='Logo' />
        <h3 className='workspace-title'>{Strings.create_first_workspace}</h3>
        <form onSubmit={(event) => submit(event)}>
          {fields.map((field, index) => renderField(field, index))}
          {renderAddWorkspaceImage()}
          {isAddTeamClicked ? (
            <div className='invite-team-container'>
              {teammateFields.map((emailField, index) => renderEmailField(emailField, index))}
            </div>
          ) : null}
          <p onClick={() => addTeamClicked()} className='add-teammate'>
            {`+ ${Strings.add_team_members} (${Strings.optional})`}
          </p>
          <Button
            idName='workspace-creation-screen-submit-btn'
            disabled={(isAddTeamClicked && hasEmailErrors) || hasErrors}
            loading={isLoading}
          >
            {Strings.submit}
          </Button>
        </form>
      </div>
      <div className='img-con'></div>
    </div>
  );
}
