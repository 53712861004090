import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';
import { Strings } from 'resources';
import {
  isManageWorkspacesScreen,
  isWhatsNewScreen,
  isThemesScreen,
  isSuperAdminAnalyticsScreen,
} from 'utils/task';

export default function SuperAdminLeftBar() {
  const navigationData = useRecoilValue(navigationDataSelector);

  const renderThemesLink = () => {
    return (
      <div
        id='themes-list-link-icon'
        className={`main-heading ${isThemesScreen(navigationData.currentRoute) ? 'selected' : ''}`}
      >
        <Link to='/super-admin/themes'>
          {/* @ts-ignore*/}
          <h6>{Strings.themes}</h6>
        </Link>
      </div>
    );
  };

  const renderWhatsNewLink = () => {
    return (
      <div
        id='whats-new-con'
        className={`main-heading ${
          isWhatsNewScreen(navigationData.currentRoute) ? 'selected' : ''
        }`}
      >
        <Link to='/super-admin/whats-new'>
          {/* @ts-ignore*/}
          <h6>{Strings.whats_new}</h6>
        </Link>
      </div>
    );
  };

  const renderWorkspaces = () => {
    return (
      <div
        id='super-admin-workspaces-list-con'
        key='render-workspaces'
        className={`workspaces-con`}
      >
        <h6
          id='super-admin-workspaces-title'
          className={`main-heading ${
            isManageWorkspacesScreen(navigationData.currentRoute) ? 'selected' : ''
          }`}
        >
          <Link to='/super-admin/workspaces'>
            {/* @ts-ignore */}
            <span>{Strings.workspaces}</span>
          </Link>
        </h6>
      </div>
    );
  };

  const renderSuperAdminAnalytics = () => {
    return (
      <div
        id='super-admin-analytics-con'
        className={`main-heading ${
          isSuperAdminAnalyticsScreen(navigationData.currentRoute) ? 'selected' : ''
        }`}
      >
        <Link to='/super-admin/analytics'>
          {/* @ts-ignore */}
          <h6>{Strings.analytics}</h6>
        </Link>
      </div>
    );
  };

  return (
    <div
      id='super-admin-left-bar'
      className={`left-bar-container ${navigationData.hideLeftSidebar ? 'hide' : ''} ${
        navigationData.hideLeftSidebarDesktop ? 'hide-desktop' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      {renderThemesLink()}
      {renderWorkspaces()}
      {renderSuperAdminAnalytics()}
      {renderWhatsNewLink()}
    </div>
  );
}
