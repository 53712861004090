import { TargetedEvent, useEffect, useState } from 'preact/compat';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { Validation, log } from 'utils';
import { parseUserObject } from 'utils/user';
import { Strings } from 'resources';
import { updateUser, loginUserInApp, updateInviteEmailStatus } from 'services/AuthService';
import appConfig from 'config/config';

// Screen not in use

export default function PersonalInfo() {
  const navigate = useNavigate();
  const alert = useAlert();
  const [isAgreed, setIsAgreed] = useState(false);
  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      name: Strings.first_name_placeholder,
      key: 'firstName',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|max:50',
      touched: false,
      placeholder: Strings.first_name_placeholder,
    },
    {
      name: Strings.last_name_placeholder,
      key: 'lastName',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|max:50',
      touched: false,
      placeholder: Strings.last_name_placeholder,
    },
    {
      name: Strings.position_placeholder,
      key: 'position',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|max:50',
      touched: false,
      placeholder: Strings.position_placeholder,
    },
    {
      name: Strings.workspace_name,
      key: 'workspace',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required',
      touched: false,
      disabled: true,
      placeholder: Strings.workspace_name_placeholder,
    },
    {
      name: Strings.email_address,
      key: 'email',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|email|max:50',
      touched: false,
      disabled: true,
      placeholder: Strings.email_address_placeholder,
    },
    {
      name: Strings.password,
      key: 'password',
      value: '',
      type: 'text',
      maxLength: 15,
      rules: 'required|no_space|min:8|max:256',
      touched: false,
      secure: true,
      placeholder: Strings.password,
    },
    {
      name: Strings.phone,
      key: 'phone',
      value: '',
      type: 'number',
      maxLength: 10,
      rules: 'required|no_space|min:10|max:10',
      touched: false,
      placeholder: Strings.phone_placeholder,
    },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const { userId, emailId, workspaceName, workspaceId } = useParams();

  useEffect(() => {
    const newFieldArray = [...fields];
    newFieldArray[3].value = workspaceName ? workspaceName : '';
    newFieldArray[4].value = emailId ? emailId : '';
    setFields(newFieldArray);
  }, []);

  useEffect(() => {
    updateEmailStatus('Viewed');
  }, []);

  const updateEmailStatus = async (status: InvitationStateType) => {
    if (userId === undefined || workspaceId === undefined) return;
    setLoadingState(true);
    const { data, success, message } = await updateInviteEmailStatus(userId, status, workspaceId);
    setLoadingState(false);

    if (!success) {
      alert.error(message);
      return log(message);
    } else if (data && typeof data.UserExists !== 'undefined' && data.UserExists === true) {
      navigate('/login');
    }
  };

  /**
   * Render Input fields
   * @param {*} field
   * @param {*} index
   */
  const renderField = (field: ValidationFieldType, index: number) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder ? field.placeholder : ''}
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={field.touched}
        value={field.value as string}
        disabled={field.disabled}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        secure={field.secure}
      />
    );
  };

  const fieldUpdated = (text: string, index: number) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const submit = (event: TargetedEvent) => {
    event.preventDefault();

    if (hasErrors || isLoading || !isAgreed) return;

    setAcceptedStatus(getPayload(fields));
  };

  const setAcceptedStatus = async (payload) => {
    if (userId === undefined || workspaceId === undefined) return;
    setLoadingState(true);
    const { success, message } = await updateInviteEmailStatus(userId, 'Accepted', workspaceId);
    if (!success) {
      setLoadingState(false);
      alert.error(message);
      return log(message);
    } else {
      return createAccount(payload);
    }
  };

  /*
   * User created for workspace by calling backend
   * payload object {name,position, email and phone}
   *
   * returns null
   */
  const createAccount = async (payload) => {
    const { data, success, message } = await updateUser(payload);
    setLoadingState(false);

    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return accountCreationSuccess(data);
    }
  };

  /*
   * Account creation was successful
   *
   * returns null
   */
  const accountCreationSuccess = async (userData: ResponseUserObjectType) => {
    const user = parseUserObject(userData);
    loginUserInApp(user);
    routeChange();
  };

  /*
   * Get payload for verify email api
   * fields Array
   *
   * returns Object
   */
  const getPayload = (fields: ValidationFieldType[]) => {
    const payload = {};

    fields.forEach((field, _index) => {
      if (field.key !== 'workspace' && field.key !== undefined) {
        payload[field.key] = field.value;
      }
    });
    payload['workspaceId'] = workspaceId;
    payload['userId'] = userId;
    payload['origin'] = document.location.origin + '/';
    return payload;
  };

  const routeChange = () => {
    navigate('/');
  };

  return (
    <div
      id='personal-info-screen-container'
      className='personal-info-screen-container account-screen-container'
    >
      <h3 id='personal-info-screen-title' className='account-title'>
        {Strings.personal_information}
      </h3>
      <form id='personal-info-screen-form' onSubmit={(event) => submit(event)}>
        {fields.map((field, index) => renderField(field, index))}
        <div id='personal-info-screen-checkbox-con' className='checkbox-con'>
          <input
            id='personal-info-screen-checkbox-input'
            className='checkbox'
            type='checkbox'
            checked={isAgreed}
            onChange={() => setIsAgreed(!isAgreed)}
          />
          <p>
            {Strings.agree_to}
            <span>
              <a href={appConfig.TermsURL} target='_blank' rel='noreferrer'>
                {Strings.terms}
              </a>
            </span>
            {Strings.and}
            <span>
              <a href={appConfig.PrivacyPolicyURL} target='_blank' rel='noreferrer'>
                {Strings.privacy_policy}
              </a>
            </span>
          </p>
        </div>
        <Button
          idName='personal-info-submit-btn'
          disabled={hasErrors || !isAgreed}
          loading={isLoading}
        >
          {Strings.submit}
        </Button>
      </form>
    </div>
  );
}
