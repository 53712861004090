import { forwardRef, ForwardedRef, useImperativeHandle } from 'preact/compat';
import { h, JSX } from 'preact';

interface DragAndDropProps {
  list: any[]; // Replace any with the appropriate type for the list items
  setSortedList: (newList: any[]) => void; // Replace any with the appropriate type for the list items
  children: JSX.Element | JSX.Element[];
}

const DragAndDrop = forwardRef<DragAndDropRef, DragAndDropProps>(
  ({ list: sortedList, setSortedList, children }, ref: ForwardedRef<DragAndDropRef>) => {
    let sourceElement: HTMLElement | null = null;
    const currentSortedList = sortedList;
    useImperativeHandle(
      ref,
      () => ({
        get sortedList() {
          return currentSortedList;
        },
        handleDragStart(event: DragEvent) {
          if (!event.dataTransfer) return;

          event.stopPropagation();
          sourceElement = event.target as HTMLElement;
          event.dataTransfer.effectAllowed = 'move';
        },
        handleDragOver(event: DragEvent) {
          if (!event.dataTransfer) return;

          event.preventDefault();
          event.stopPropagation();
          event.dataTransfer.dropEffect = 'move';
        },
        handleDrop(event: DragEvent) {
          event.stopPropagation();
          if (sourceElement && sourceElement !== event.currentTarget) {
            const newList = currentSortedList;
            const sourceId = parseInt(sourceElement.id, 10);
            const targetId = parseInt((event.currentTarget as HTMLElement).id, 10);
            [newList[sourceId], newList[targetId]] = [newList[targetId], newList[sourceId]];
            setSortedList(newList);
          }
        },
        handleDragEnd(event: DragEvent) {
          (event.currentTarget as HTMLElement).style.opacity = '1';
        },
      }),
      [sortedList],
    );

    return <div className='DragAndDrop-list'>{children}</div>;
  },
);

DragAndDrop.displayName = 'DragAndDrop';

export default DragAndDrop;
// const DragAndDrop = forwardRef(({ list: sortedList, setSortedList, children }, ref) => {
//   let sourceElement = null;
//   useImperativeHandle(
//     ref,
//     () => ({
//       sortedList,
//       handleDragStart(event) {
//         event.stopPropagation();
//         sourceElement = event.target;
//         event.dataTransfer.effectAllowed = 'move';
//       },
//       handleDragOver(event) {
//         event.preventDefault();
//         event.stopPropagation();
//         event.dataTransfer.dropEffect = 'move';
//       },
//       handleDrop(event) {
//         event.stopPropagation();
//         if (sourceElement && sourceElement !== event.currentTarget) {
//           let newList = ref.current.sortedList;
//           [newList[sourceElement.id], newList[event.currentTarget.id]] = [
//             newList[event.currentTarget.id],
//             newList[sourceElement.id],
//           ];
//           setSortedList(newList);
//         }
//       },
//       handleDragEnd(event) {
//         event.currentTarget.style.opacity = 1;
//       },
//     }),
//     [sortedList],
//   );

//   return <div className='DragAndDrop-list'>{children}</div>;
// });

// DragAndDrop.displayName = 'DragAndDrop';

// export default DragAndDrop;
