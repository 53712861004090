import { useState, memo, useMemo, useEffect, useRef, useCallback } from 'preact/compat';
import { log } from 'utils';
import ProjectSelection from 'components/TaskItems/IndividualItem/ProjectSelection';
import AssigneeSelection from 'components/TaskItems/IndividualItem/AssigneeSelection';
import SizeSelection from 'components/TaskItems/IndividualItem/SizeSelection';
import { getUserId, getUserName } from 'services/AuthService';
import { useAlert } from 'react-alert';
import { Strings } from 'resources';
import RecurringIcon from '../TaskItems/IndividualItem/RecurringIcon';
import TutorialInputBox from 'components/TutorialTaskItems/TutorialIndividualItem/TutorialInputBox';
import { useRecoilValue } from 'recoil';
import { accountSettingsState, accountState } from 'recoil/AccountState';
import {
  allTutorialProjectsState,
  allTutorialUsersState,
  tutorialCurrentProjectIdState,
  tutorialDataSelector,
} from 'recoil/TutorialState';
import { allTemplatesState, currentTemplateIdState } from 'recoil/TemplateState';
import { currentUserIdState } from 'recoil/UserState';
import { addTutorialTask } from 'recoil/TutorialState/update';
import { allSizes } from 'utils/size';
import AvatarIcon from 'assets/images/Avatar.png';

const DEFAULT_SIZE = 'M';
type TutorialTopBarInputPropsType = {
  currentRoute: string;
  onEnter: () => void;
  onFocusTaskBox: () => void;
};
function TutorialTopBarInput(props: TutorialTopBarInputPropsType) {
  const { currentRoute, onEnter, onFocusTaskBox } = props;

  // log('render components/TutorialTopBarInput', props);

  const alert = useAlert();
  const userId = getUserId();
  const userName = getUserName();

  const [processing, setProcessing] = useState(false);
  const [itemAssignee, setItemAssignee] = useState<string | null>(null);
  const [itemSize, setItemSize] = useState<SizeOptionsType | undefined>(undefined);
  const [itemTags, setItemTags] = useState<TagObjectType[]>([]);
  const [itemValue, setItemValue] = useState('');
  const [updateValue, setUpdateValue] = useState(0);
  const itemTemplate = null;

  // Recurring task
  const [showRecurringTaskModal, setShowRecurringTaskModal] = useState(false);

  /*
   * Account settings
   *
   * returns Object
   */
  const accountSettings = useRecoilValue(accountSettingsState);

  // Values from redux
  const allProjects = useRecoilValue(allTutorialProjectsState);
  const tutorialData = useRecoilValue(tutorialDataSelector);
  const allTemplates = useRecoilValue(allTemplatesState);

  const [itemProject, setItemProject] = useState(tutorialData.currentProjectId);

  const allUsers = useRecoilValue(allTutorialUsersState);
  const account = useRecoilValue(accountState);

  const spellChecker = accountSettings.spellChecker;
  const assignDefaultToSelf = accountSettings.assignDefaultToSelf;
  const accountProfileImage = account?.profileImage;

  const getAllUsers = useCallback(() => {
    return itemTemplate
      ? getSelectedTemplateUsers(itemTemplate)
      : itemProject
        ? getSelectedProjectUsers(itemProject)
        : [{ id: userId, name: userName }, ...allUsers];
  }, [itemProject, allUsers, allProjects, itemTemplate, allTemplates]);

  const currentProject = useRecoilValue(tutorialCurrentProjectIdState);

  const currentUser = useRecoilValue(currentUserIdState);

  const currentTemplate = useRecoilValue(currentTemplateIdState);

  /*
   * Get all tags
   *
   * Based upon stateType and if item project is set or not
   *
   * returns Array
   */
  const allTags: TagObjectType[] = [];

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      log('currentRoute updated', currentRoute, currentProject, currentUser, currentTemplate);
      updateState();
    }
  }, [currentRoute, currentProject, currentUser, currentTemplate]);

  const updateState = () => {
    if (currentProject) {
      updateProject(currentProject);
    }
  };

  /*
   * Get selected project name
   *
   * returns String
   */
  const getSelectedProjectUsers = (project: string | null) => {
    if (!project) return [];
    else {
      const index = allProjects.findIndex((item) => item.id === project);

      if (index === -1) return [];
      else return allProjects[index].users;
    }
  };

  /*
   * Get selected template users
   *
   * returns List of users
   */
  const getSelectedTemplateUsers = (template: string | null) => {
    if (!template) return [];
    else {
      if (allTemplates === null) return [];
      const index = allTemplates.findIndex((item) => item.id === template);

      if (index === -1) return [];
      else return allTemplates[index].users;
    }
  };

  /*
   * Remove tag from task
   *
   * tagId String
   * tagName String
   *
   * returns String
   */
  const removeTagFromTask = (tagId: string | null, tagName: string) => {
    if (!tagId) tagId = getTagIdForName(tagName);

    if (tagId) {
      removeTagFromTaskTags(tagId);
    }
  };

  const removeTagFromTaskTags = (id: string) => {
    let tags = itemTags;

    tags = tags.filter((tag) => tag.id !== id);

    setItemTags(tags);
  };

  const getTagIdForName = (tagName: string) => {
    const index =
      allTags && allTags.findIndex((tag) => tag.name.toUpperCase() === tagName.toUpperCase());

    if (index !== -1) return allTags[index].id;

    return null;
  };

  const addNewTask = async (value: string) => {
    log('add new task', value);

    if (value.trim() === '') return;

    if (tutorialData.currentProjectId === '01') {
      if (value.trim().toLowerCase() !== 'my first task') {
        alert.error('Please enter the specified text `My first task` as task to continue');
        return;
      }
    } else {
      if (value.trim().toLowerCase() !== 'onboard new client') {
        alert.error('Please enter the specified text `Onboard new client` as task to continue');
        return;
      }
    }

    addTutorialTask(0, undefined, undefined, 'BACKLOG', null, 'TUTORIAL', 'S', value);

    resetState();
    alert.success('Task created Successfully!');
    onEnter();
  };

  const resetState = () => {
    setItemTags([]);
    setItemValue('');
    setUpdateValue(+new Date());
    setProcessing(false);

    // Recurring task
    setShowRecurringTaskModal(false);
  };

  const renderTaskTags = () => {
    return (
      <div className='item-tags-container'>
        {itemTags.map((tag, index) => (
          <span className='tag-entry' key={tag.id + index}>
            <span className='tag-text'>{tag.name}</span>{' '}
            <span className='close-icon' onClick={() => removeTagFromTask(tag.id, tag.name)}>
              x
            </span>
          </span>
        ))}
      </div>
    );
  };

  /*
   * Render size Selection
   *
   * returns React.DOM
   */
  const renderSizeSelection = () => {
    if (!itemSize) return null;

    return (
      <SizeSelection
        tempId={'Create-task-size'}
        itemSize={itemSize}
        showSize={accountSettings.size}
        isDisabled={false}
        updateSize={(size) => setItemSize(size)}
        allSizes={allSizes}
      />
    );
  };

  /*
   * Get selected project name
   *
   * returns String
   */
  const selectedProjectName = useMemo(() => {
    if (!itemProject) return '';
    else {
      const index = allProjects.findIndex((item) => item.id === itemProject);

      if (index === -1) return '';
      else return allProjects[index].name;
    }
  }, [itemProject, allProjects]);

  /*
   * Get Project name
   *
   * returns String
   */
  const renderProjectName = useMemo(() => {
    const itemProj = itemProject ? selectedProjectName : 'No Project';

    return <span className='project-name'>{itemProj}</span>;
  }, [selectedProjectName]);

  /*
   * Render project Selection
   *
   * returns React.DOM
   */
  const renderProjectSelection = () => {
    if (!itemProject) return null;

    return (
      <ProjectSelection
        allProjects={allProjects}
        showProjectId={accountSettings.projectId}
        isSubtask={false}
        itemProject={itemProject ? { id: itemProject } : null}
        hasAssignee={true}
        projectNameWithTaskNumber={selectedProjectName}
        renderProjectNameWithTaskNumber={renderProjectName}
        isDisabled={
          tutorialData &&
          tutorialData.flags &&
          (tutorialData.flags.activateProjectTask || tutorialData.flags.createProjectTask)
            ? true
            : false
        }
        updateProject={(project) => setItemProject(project)}
        stateType='TUTORIAL'
        overlayClass={''}
      />
    );
  };

  /*
   * Get selected assignee name
   *
   * returns String
   */
  const selectedAssigneeName = useMemo(() => {
    if (!itemAssignee) return '';
    else {
      if (itemAssignee === getUserId()) return userName;

      const index = allUsers.findIndex((item) => item.id === itemAssignee);

      if (index === -1) return '';
      else return allUsers[index].name;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Get selected assignee profile image
   *
   * returns String
   */
  const selectedAssigneeProfileImage = useMemo(() => {
    if (!itemAssignee) return AvatarIcon;
    else {
      if (itemAssignee === getUserId()) {
        if (accountProfileImage) return accountProfileImage;
        else return AvatarIcon;
      }

      const index = allUsers.findIndex((item) => item.id === itemAssignee);

      if (index !== -1 && allUsers[index].profileImage) return allUsers[index].profileImage;

      return AvatarIcon;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Render assignee Selection
   *
   * returns React.DOM
   */
  const renderAssigneeSelection = () => {
    if (!itemAssignee) return null;

    return (
      <AssigneeSelection
        tempId='create-task-assignee'
        allUsers={getAllUsers()}
        selectedProjectUsers={getSelectedProjectUsers(itemProject)}
        selectedAssigneeName={selectedAssigneeName}
        selectedAssigneeProfileImage={selectedAssigneeProfileImage}
        stateType='TUTORIAL'
        itemAssignee={itemAssignee ? { id: itemAssignee } : null}
        hasProject={true}
        isDisabled={false}
        onBottom={true}
        updateAssignee={(assignee) => setItemAssignee(assignee)}
        userInWorkspace={true}
        forTopBar={true}
        selectedTemplateUsers={getSelectedTemplateUsers(itemTemplate)}
      />
    );
  };

  /*
   * Set task tags in backend
   *
   * tagsArray Array
   * add Boolean
   *
   * returns null
   */
  const setTaskTags = (id: string, name: string) => {
    log('setTaskTags', id, name);

    const index = itemTags && itemTags.findIndex((tag) => tag.name === name);

    if (index !== -1) return;

    setItemTags([...itemTags, { id, name }]);
  };

  const updateProject = (projectId: string | null) => {
    setItemProject(projectId);

    if (projectId) itemProjectUpdated(projectId);
  };

  const itemProjectUpdated = (projectId: string) => {
    // check if item assignee is part of this project, if not remove assignee

    if (itemAssignee) {
      const users = getSelectedProjectUsers(projectId);

      const index = users?.findIndex((user) => user.id === itemAssignee);

      if (index === -1) setItemAssignee(null);
    }
  };

  /*
   * Render Input box
   *
   * returns React.DOM
   */

  const renderInputBox = () => {
    const inputProps = {
      dataType: 'BACKLOG' as TaskType,
      tempId: 'create-task-input',
      itemId: null,
      index: 0,
      parentId: null,
      parentIndex: 0,
      isDisabled: false,
      itemValue,
      updateValue,
      itemProject: itemProject ? { id: itemProject } : null,
      itemAssignee: itemAssignee ? { id: itemAssignee } : null,
      itemSize,
      itemTags,
      setTaskTags,
      valueUpdated: (value) => setItemValue(value),
      addNewTaskTopBar: (value) => !processing && addNewTask(value),
      deleteThetask: () => {},
      storeInputRefs: () => {},
    };
    return (
      <div className='input-box-in-con'>
        <span className='rotate-box'></span>
        <TutorialInputBox
          {...inputProps}
          key={currentRoute}
          usersData={getAllUsers()}
          sizesData={allSizes}
          projectsData={allProjects.filter((proj) => proj.archived !== true)}
          tagsData={allTags}
          updateSize={(itemId) => setItemSize(itemId)}
          updateProject={(item) => item && item && updateProject(item === 'REMOVE' ? null : item)}
          updateTutorialAssignee={(item) =>
            item && item && setItemAssignee(item === 'REMOVE' ? null : item)
          }
          placeholder={Strings.create_task_placeholder}
          spellChecker={spellChecker}
          forTopBar={true}
          isDisabled={processing}
          maxRows={2}
          showRecurringTaskModal={() => setShowRecurringTaskModal(true)}
          stateType='TUTORIAL'
          itemTemplate={itemTemplate}
          focusEvent={onFocusTaskBox}
        />
      </div>
    );
  };
  const renderRecurringIcon = () => {
    const inputProps = {
      itemValue,
      itemAssignee,
      itemProject,
      itemSize,
      itemTags,
      assignDefaultToSelf,
      itemTemplate,
    };

    return showRecurringTaskModal ? (
      <RecurringIcon
        {...inputProps}
        userId={userId}
        toggleRecurringTaskModalDisplay={() => setShowRecurringTaskModal(!showRecurringTaskModal)}
        resetParentState={() => resetState()}
        showOnlyModal={showRecurringTaskModal}
        defaultSize={
          accountSettings?.defaultPreferredSize
            ? accountSettings?.defaultPreferredSize
            : DEFAULT_SIZE
        }
        stateType='TUTORIAL'
      />
    ) : null;
  };

  return (
    <div className='topbar-input-box-con' key='new-task'>
      {renderAssigneeSelection()}
      {renderProjectSelection()}
      {renderInputBox()}
      {renderSizeSelection()}
      {renderTaskTags()}
      {renderRecurringIcon()}
    </div>
  );
}

export default memo(TutorialTopBarInput);
