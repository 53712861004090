import { useEffect } from 'preact/hooks';
import { useRecoilSnapshot } from 'recoil';
import { log } from './log';

function DebugObserver(): any {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      log(`RecoilState update ${node.key}`, snapshot.getLoadable(node));
    }

    for (const node of snapshot.getNodes_UNSTABLE()) {
      if (node.key === 'combinedData') log(`RecoilState ${node.key}`, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}
export default DebugObserver;
