import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/*
 * Enroll user into leaderboard
 * payload Object {display name, display pic}
 *
 * returns Object {data, error, status}
 */

export async function enrollUser(payload: any, userId: string) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.leaderboard.enroll_user + userId, payload, {
      headers,
      timeout: 2000000000,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * fetch individual board list
 * payload Object {pagenumber, size}
 *
 * returns Object {data, error, status}
 */

export async function getIndividualBoardList(payload: any) {
  try {
    const response = await appClient.post(apiConfig.leaderboard.individual_board_list, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * fetch workspace board list
 * payload Object {pagenumber, size}
 *
 * returns Object {data, error, status}
 */

export async function getWorkspaceBoardList(payload: any) {
  try {
    const response = await appClient.post(apiConfig.leaderboard.workspace_board_list, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
