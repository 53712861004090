import { useState, useEffect, Fragment, Suspense, useRef, TargetedEvent } from 'preact/compat';
import { addWhatsNew as addWhatsNewAPI, getWhatsNewFeatues } from 'services/WhatsNewService';
import Input from 'components/UI/Input';
import SmallButton from 'components/UI/SmallButton';
import { Validation, log } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import { Strings } from 'resources';
import { useAlert } from 'react-alert';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateForView } from 'utils/task';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { faPlus, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/UI/Spinner';
import DragAndDrop from 'components/DragAndDrop';
import { useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';

const WhatsNewModal = lazyWithRetry(() => import('modals/WhatsNewModal'));

const defaultFields: { featureFields: Array<ValidationFieldType[]> } = {
  featureFields: [
    [
      {
        name: 'Title',
        key: 'title',
        value: '',
        type: 'text',
        maxLength: 50,
        rules: 'required|max:50',
        title: 'Title',
      },
      {
        name: 'Features',
        key: 'features',
        value: [''],
        type: 'array',
        rules: 'required',
      },
      {
        name: 'Header Image',
        key: 'headerImage',
        type: 'file',
        value: '',
        rules: 'required_Object',
      },
    ],
  ],
};

export default function WhatsNew() {
  const alert = useAlert();

  const [showWhatsNewModal, setShowWhatsNewModal] = useState(false);
  const [fields, setFields] = useState(defaultFields);
  const [whatsNewFeatures, setWhatsNewFeatures] = useState<WhatsNewFeaturesType[]>([]);
  const [isLoading, setLoadingState] = useState(false);
  const [sortBy, setSortBy] = useState('Latest');
  const [isRecentLoading, setRecentLoadingState] = useState(false);
  const sectionsRef = useRef<DragAndDropRef>(null);
  const featuresRef = useRef<DragAndDropRef>(null);
  const mainIndex = useRef(0);

  const navigationData = useRecoilValue(navigationDataSelector);

  useEffect(() => {
    loadMostRecentFeatures();
  }, [sortBy]);

  const loadMostRecentFeatures = async () => {
    setRecentLoadingState(true);
    const { data, success } = await getWhatsNewFeatues(
      sortBy.toLowerCase() === 'latest' ? false : true,
    );

    if (success && data && data.length) {
      setWhatsNewFeatures(data);
    }
    setRecentLoadingState(false);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;
    let error = '';

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        error = field.errors[0];
        break;
      } else if (field.type === 'array' && Array.isArray(field.value)) {
        const newFieldValue = field.value.filter((el) => {
          return el.trim() !== '';
        });

        if (newFieldValue.length === 0) {
          hasError = true;
          error = 'Atleast one feature is required.';
          break;
        }
      }
    }

    return { hasError, error };
  };

  const renderInput = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div className='input-title'>{field.name}</div>
        <Input
          placeholder={field.title ? field.title : ''}
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
          onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
          key={index}
          touched={field.touched}
          value={field.value as string}
          errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
          icon={field.icon}
          secure={field.secure}
        />
      </Fragment>
    );
  };

  const fileUpdated = (value: File, index: number) => {
    const newFields = { ...fields };
    newFields.featureFields[index][2].value = value;
    setFields(newFields);
  };

  const fieldUpdated = (value: string, index: number) => {
    const newFields = { ...fields };
    newFields.featureFields[index][0].value = value;
    setFields(newFields);
  };

  const featureUpdated = (value: string, index: number, indexMain: number) => {
    const newFields = { ...fields };
    newFields.featureFields[indexMain][1].value[index] = value;
    setFields(newFields);
  };

  const addNewFeature = (e: TargetedEvent | null, value: string, index: number) => {
    e && e.preventDefault();
    const newFields = { ...fields };
    (newFields.featureFields[index][1].value as string[]).push(value);
    setFields(newFields);
  };

  const removeFeature = (e: TargetedEvent, index: number) => {
    e && e.preventDefault();
    const lastIndex = (fields.featureFields[index][1].value as string[]).length - 1;
    const newFields: { featureFields: Array<ValidationFieldType[]> } = {
      ...fields,
      featureFields: fields.featureFields.map((item, ind) => {
        if (ind === index) {
          return item.map((obj, idx) => {
            if (idx === 1) {
              return {
                ...obj,
                value: (obj.value as string[]).filter((val, ind) => ind !== lastIndex),
              };
            }
            return obj;
          });
        }
        return item;
      }),
    };
    setFields(newFields);
  };

  const removeSection = (e: TargetedEvent, index: number) => {
    e && e.preventDefault();
    const newFields = {
      ...fields,
      featureFields: fields.featureFields.filter((item, idx) => idx !== index),
    };
    setFields(newFields);
  };

  const addNewSection = (e: TargetedEvent) => {
    e && e.preventDefault();
    const newFields = {
      ...fields,
      featureFields: [
        ...fields.featureFields,
        [
          {
            name: 'Title',
            key: 'title',
            value: '',
            type: 'text',
            maxLength: 50,
            rules: 'required|max:50',
            title: 'Title',
          },
          {
            name: 'Features',
            key: 'features',
            value: [''],
            type: 'array',
            rules: 'required',
          },
          {
            name: 'Header Image',
            key: 'headerImage',
            type: 'file',
            value: '',
            rules: 'required_Object',
          },
        ],
      ],
    };
    setFields(newFields);
  };

  const setFeaturesList = (list: string[]) => {
    const newFields = { ...fields };
    newFields.featureFields[mainIndex?.current][1].value = list;
    setFields(newFields);
  };

  const changeIndex = (indexMain: number) => {
    mainIndex.current = indexMain;
  };

  const renderFeatures = (field: ValidationFieldType, indexMain: number) =>
    field.value && Array.isArray(field.value) ? (
      field.value.map((feature, index) => (
        <div
          id={`${index}`}
          key={index}
          onDragStart={featuresRef?.current?.handleDragStart}
          onDragOver={featuresRef?.current?.handleDragOver}
          onDrop={featuresRef?.current?.handleDrop}
          onDragEnd={featuresRef?.current?.handleDragEnd}
          draggable
        >
          <textarea
            className='text-area'
            value={feature}
            placeholder={`Feature ${index + 1}`}
            onChange={(event) =>
              featureUpdated((event.target as HTMLTextAreaElement).value, index, indexMain)
            }
            onBlur={(event) =>
              featureUpdated((event.target as HTMLTextAreaElement).value, index, indexMain)
            }
            onFocus={() => changeIndex(indexMain)}
            rows={5}
          />
        </div>
      ))
    ) : (
      <div></div>
    );

  const renderTextarea = (field: ValidationFieldType, indexMain: number) => {
    return (
      <Fragment>
        <div id={`whats-new-text-area-list-con-${indexMain}`} className='input-title'>
          {field.name}
        </div>
        <DragAndDrop
          list={
            fields?.featureFields[mainIndex?.current]
              ? (fields.featureFields[mainIndex.current][1].value as string[])
              : []
          }
          setSortedList={setFeaturesList}
          ref={featuresRef}
        >
          {renderFeatures(field, indexMain)}
        </DragAndDrop>
        <div id={`add-remove-feature-con-${indexMain}`} className='add-remove-feature-con'>
          <div id={`add-feature-btn-con-${indexMain}`} className='add-feature-btn-con'>
            <SmallButton btnType='inverse' clicked={(e) => addNewFeature(e, '', indexMain)}>
              {Strings.add_feature}
            </SmallButton>
          </div>
          {field.value && Array.isArray(field.value) && field.value.length > 1 ? (
            <div id={`add-feature-btn-con-${indexMain}`} className='add-feature-btn-con'>
              <SmallButton btnType='inverse' clicked={(e) => removeFeature(e, indexMain)}>
                {Strings.remove_feature}
              </SmallButton>
            </div>
          ) : null}
        </div>

        {indexMain >= 1 ? (
          <div
            id={`remove-section-btn-con-${indexMain}`}
            className='add-feature-btn-con remove-section-btn-con'
          >
            <SmallButton btnType='inverse' clicked={(e) => removeSection(e, indexMain)}>
              {Strings.remove_section}
            </SmallButton>
          </div>
        ) : null}
      </Fragment>
    );
  };

  const renderFile = (field: ValidationFieldType, index: number, type: string) => {
    return (
      <Fragment>
        <div id={`file-input-con-${index}`} className='input-title'>
          {field.name}
        </div>
        <div id={`file-upload-whats-new-${index}`} className='file-upload'>
          <input
            type='file'
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            onChange={(event) => {
              if ((event.target as HTMLInputElement).files)
                fileUpdated((event.target as HTMLInputElement).files![0], index);
              if (event.target) (event.target as HTMLInputElement).value = '';
            }}
            id={`file-upload-${index}`}
            accept={type}
            hidden
          />
          <label
            id={`browse-file-btn-label-${index}`}
            className='browse-file-btn'
            htmlFor={`file-upload-${index}`}
          >
            {Strings.browse}
          </label>
          <span id={`file-title-whats-new-${index}`} className='file-title'>
            {field.value && (field.value as File).name
              ? (field.value as File).name
              : Strings.browse_file}
          </span>
        </div>
      </Fragment>
    );
  };

  const submit = (event: TargetedEvent) => {
    event.preventDefault();

    if (isLoading) return;
    let fieldArr: ValidationFieldType[] = [];
    fields.featureFields.forEach((fieldSec) => {
      fieldArr = [fieldSec[0], fieldSec[1], fieldSec[2]];
    });

    const fieldsWithError = Validation.validate(fieldArr);
    const { hasError, error } = checkErrors(fieldsWithError);

    if (hasError) {
      alert.error(error);
      return;
    }

    addWhatsNew(getPayload(fields));
  };

  /*
   * Create Theme by calling backend
   * payload object
   *
   * returns null
   */
  const addWhatsNew = async (payload: any) => {
    setLoadingState(true);

    const { success, message } = await addWhatsNewAPI(payload);

    setLoadingState(false);

    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      alert.success('Added successfully!');
      return resetState();
    }
  };

  /*
   * Reset state
   *
   * returns null
   */
  const resetState = () => {
    log('reset state');

    setFields({
      featureFields: [
        [
          {
            name: 'Title',
            key: 'title',
            value: '',
            type: 'text',
            maxLength: 50,
            rules: 'required|max:50',
            title: 'Title',
          },
          {
            name: 'Features',
            key: 'features',
            value: [''],
            type: 'array',
            rules: 'required',
          },
          {
            name: 'Header Image',
            key: 'headerImage',
            type: 'file',
            value: '',
            rules: 'required_Object',
          },
        ],
      ],
    });
  };

  /*
   * Get payload for verify email api
   * fields Array
   *
   * returns Object
   */
  const getPayload = (fields: { featureFields: ValidationFieldType[][] }) => {
    const payload = new FormData();

    fields.featureFields.forEach((fieldArr, indexMain) => {
      payload.append(`features[${indexMain}].title`, fieldArr[0].value as string);
      payload.append(`features[${indexMain}].headerImage`, fieldArr[2].value as File);

      const newFieldValue = (fieldArr[1].value as string[]).filter((el) => {
        return el.trim() !== '';
      });

      newFieldValue &&
        newFieldValue.map((fieldIn, index) => {
          payload.append(`features[${indexMain}].features[${index}]`, fieldIn);
        });
    });

    return payload;
  };

  const updateSectionFields = (list) => {
    const newFields = { ...fields };
    newFields.featureFields = list;
    setFields(newFields);
  };

  const renderSections = () =>
    fields?.featureFields?.length ? (
      fields.featureFields.map((field, index) => (
        <Fragment key={`whats-new-creation-section-con-${index}`}>
          <div
            id='whats-new-input-main-con'
            className={`input-main-con ${index && 'image-container'}`}
          >
            {renderFile(field[2], index, 'image/png, image/jpeg')}
          </div>
          <div
            id={`${index}`}
            className='whats-new-creation-section-con'
            onDragStart={sectionsRef?.current?.handleDragStart}
            onDragOver={sectionsRef?.current?.handleDragOver}
            onDrop={sectionsRef?.current?.handleDrop}
            onDragEnd={sectionsRef?.current?.handleDragEnd}
            draggable
          >
            <div id={`whats-new-input-main-con-${index}`} className='input-main-con'>
              {renderInput(field[0], index)}
            </div>
            <div
              id={`whats-new-textarea-main-con-${index}`}
              className='input-main-con textarea-con'
            >
              {renderTextarea(field[1], index)}
            </div>
          </div>
        </Fragment>
      ))
    ) : (
      <div></div>
    );

  const renderFields = () => {
    return (
      <div id='whats-new-fields-main-container' className='fields-main-container'>
        <DragAndDrop
          list={fields.featureFields}
          setSortedList={updateSectionFields}
          ref={sectionsRef}
        >
          {renderSections()}
        </DragAndDrop>
      </div>
    );
  };

  const filteredSections = () => {
    const sections = fields.featureFields.map((field) => {
      return {
        headerImage: field[2].value ? URL.createObjectURL(field[2].value as File) : null,
        title: field[0].value,
        features: (field[1].value as string[]).filter((el) => {
          return el.trim() !== '';
        }),
      };
    });
    return sections;
  };

  const addWhatsNewFeature = (index: number) => {
    const itemToAdd = whatsNewFeatures[index].message
      ? whatsNewFeatures[index].message!.trim()
      : '';
    const allFields = { ...fields };
    const sectionsArr = allFields.featureFields;
    const sectionsLength = sectionsArr.length - 1;

    const lastSection = sectionsArr[sectionsLength];

    const features = lastSection[1].value as string[];
    const featuresLength = features.length - 1;

    const lastFeature = features[featuresLength].trim();

    log('lastFeature', features, featuresLength, lastFeature);

    if (lastFeature === '') featureUpdated(itemToAdd, featuresLength, sectionsLength);
    else addNewFeature(null, itemToAdd, sectionsLength);
  };

  const renderWhatsNewFeatures = () => {
    return (
      <div id='whats-new-features-con' className='featues-con'>
        <div id='recent-features-top-con' className='recent-features-top-con'>
          <h2 id='features-title' className='features-title'>
            {Strings.most_recent_features}
          </h2>
          <div id='recent-features-top-con-sort-con' className='sort-con'>
            <Menu
              id='recent-features-date-sort-menu'
              style={{ width: '50px' }}
              className='item-menu'
              menuButton={
                <MenuButton
                  id='recent-features-date-sort-menu-button'
                  className={`item-btn assignee-btn`}
                >
                  <span id='recent-features-date-sort-item-selected' className='item-selected'>
                    {`Sort by ${sortBy}`}
                  </span>
                  <FontAwesomeIcon
                    id='recent-features-date-sort-down-icon'
                    className='drop-icon down'
                    icon={faChevronDown}
                  />
                  <FontAwesomeIcon
                    id='recent-features-date-sort-up-icon'
                    className='drop-icon up'
                    icon={faChevronUp}
                  />
                </MenuButton>
              }
            >
              <MenuItem
                id='recent-features-date-sort-menu-item-0'
                key={0}
                onClick={() => setSortBy('Latest')}
                disabled={sortBy.toLowerCase() === 'latest'}
                value='latest'
              >
                Sort by Latest
              </MenuItem>
              <MenuItem
                id='recent-features-date-sort-menu-item-1'
                key={1}
                onClick={() => setSortBy('Oldest')}
                disabled={sortBy.toLowerCase() === 'oldest'}
                value='oldest'
              >
                Sort by Oldest
              </MenuItem>
            </Menu>
          </div>
        </div>
        {isRecentLoading ? (
          <Spinner />
        ) : (
          <ul>
            {whatsNewFeatures &&
              whatsNewFeatures.map((feature, index) => (
                <div id={`whats-new-feature-list-con-${index}`} className='li-con' key={index}>
                  <FontAwesomeIcon
                    id={`whats-new-feature-list-icon-${index}`}
                    icon={faPlus}
                    size='sm'
                    onClick={() => addWhatsNewFeature(index)}
                  />

                  <li id={`whats-new-feature-list-${index}`} className='whats-new-feature-list'>
                    <span
                      id={`whats-new-recent-features-date-${index}`}
                      className='whats-new-recent-features-date'
                    >
                      {feature && feature.date ? `${formatDateForView(feature.date)} ` : ''}
                    </span>
                    {feature && feature.message ? feature.message.trim() : ''}
                  </li>
                </div>
              ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div id='whats-new-screen-container' className='whats-new-screen-container'>
      <div
        id='whats-new-main-con'
        className={`whats-new-main-con ${
          navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
        }
            ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
      >
        <form id='whats-new-form' className='whats-new-form' onSubmit={(event) => submit(event)}>
          <h3 id='whats-new-title' className='big-title'>
            {Strings.whats_new}
          </h3>
          {renderFields()}
          <div
            id='add-new-section-whats-new-btn-con'
            className='btn-container add-new-section-whats-new-btn-con'
          >
            <SmallButton btnType='inverse' clicked={(e) => addNewSection(e)}>
              {Strings.add_section}
            </SmallButton>
          </div>
          <div id='whats-new-hr-line' className='hr-line' />

          <div id='whats-new-create-preview-btn-con' className='btn-main-container'>
            <div id='create-whats-new-btn-con' className='btn-container'>
              <SmallButton disabled={isLoading} loading={isLoading}>
                {Strings.create}
              </SmallButton>
            </div>

            <div id='preview-btn-con' className='btn-container preview-btn-con'>
              <SmallButton
                btnType='inverse'
                disabled={isLoading}
                clicked={(e) => {
                  e.preventDefault();
                  setShowWhatsNewModal(true);
                }}
              >
                {Strings.preview}
              </SmallButton>
            </div>
          </div>
        </form>

        {renderWhatsNewFeatures()}
      </div>

      <Suspense fallback={null}>
        {showWhatsNewModal ? (
          <WhatsNewModal
            features={filteredSections()}
            date={formatDateForView(new Date(), 'MMM D, YYYY')}
            isPreview={true}
            closeModal={() => setShowWhatsNewModal(false)}
          />
        ) : null}
      </Suspense>
    </div>
  );
}
