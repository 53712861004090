import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';
import { log } from 'utils';

/*
 * Get unread notifications
 * pageNum : - the page number for fetching data
 *
 * returns Object {data, error, status}
 */
export async function getNewNotifications(status: NotificationStatusType, pageNum: number) {
  log('inside api');
  try {
    const payload = {
      status: status,
      pageNumber: pageNum,
      size: 10,
    };
    const response = await appClient.post(apiConfig.notifications.get_notifications, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * update the notification status as read
 * @param {int} id : notification ids
 *
 * returns Object {data, error, status}
 */
export async function updateNotificationStatus(idList: string[]) {
  try {
    const url = apiConfig.notifications.update_notification;
    const response = await appClient.put(url, idList);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * update the notification status as read
 *
 * returns Object {data, error, status}
 */
export async function markAllNotificationStatus() {
  try {
    const url = apiConfig.notifications.mark_all_read;
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
