import { Fragment } from 'preact/compat';
import { Strings } from 'resources';
// import { createFormAPI, deleteFormAPI } from 'services/ProjectService';
import Switch from 'react-switch';
import Input from 'components/UI/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAlert } from 'react-alert';

type ProjectFormSettingsPropsType = {
  closeModal: () => void;
  project: ProjectObjectType;
  deleteForm: (formId: string) => void;
  createFormLink: (projectId: string, checked: boolean) => void;
};
export default function ProjectFormSettings(props: ProjectFormSettingsPropsType) {
  const { closeModal, project, deleteForm, createFormLink } = props;
  const alert = useAlert();
  // const [isLoading, setLoadinState] = useState(false);
  // const [projectFormId, setProjectFormId] = useState<undefined | string>(project.formId);

  // const deleteForm = async () => {
  //   if (projectFormId) {
  //     setLoadinState(true);
  //     const { success } = await deleteFormAPI(projectFormId);
  //     setLoadinState(false);

  //     if (success) {
  //       setProjectFormId(undefined);
  //     }
  //   }
  // };
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(getShareLink());
    alert.success('Copied!');
  };

  const copyEmbedCodeToClipboard = () => {
    navigator.clipboard.writeText(getEmbedCode());
    alert.success('Copied!');
  };

  const getShareLink = () => {
    return `${location.origin}/form?f=${project.formId}&p=${project.id}`;
  };

  const getEmbedCode = () => {
    return `<div class="mt-embed-container"><link rel="stylesheet" href="https://magictask-profile.s3.us-east-1.amazonaws.com/mt-form-embed-style.css"/><iframe class="mt-embed-iframe" height="533" width = "800" src="${getShareLink()}&embed=true"></iframe><div class="mt-embed-footer"><a rel="nofollow noopener" target="_blank" class="mt-embed-footer-link" href="https://app.magictask.io"><span class="mt-embed-footer-text">Form powered by</span><div class="mt-embed-footer-logo" role="img" aria-label="Logo of MT"></div></a></div></div>`;
  };

  const renderShareLink = () => {
    return (
      <div className={'share-details'}>
        <div className={'share-link-main'}>
          <span className={'title'}> Share Link</span>
          <div className={'sharelink-con'}>
            <Input
              placeholder='share link'
              onChange={() => {}}
              value={getShareLink()}
              disabled={true}
            />

            <div className={'copy-icon-con'}>
              <span className={'copy-icon'} onClick={copyLinkToClipboard}>
                <FontAwesomeIcon icon={faCopy} />
              </span>
            </div>

            <button
              className='item-container delete-con'
              onClick={() => project.formId && deleteForm(project.formId)}
            ></button>
          </div>
        </div>

        <div className={'embed-code'}>
          <span className={'title'}>Embed Code</span>
          <div className={'embed-con'}>
            <textarea className={'embed-text-area'} value={getEmbedCode()} disabled={true} />
            <div className={'copy-icon-con'}>
              <span className={'copy-icon'} onClick={copyEmbedCodeToClipboard}>
                <FontAwesomeIcon icon={faCopy} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const createFormLink = async (checked: boolean) => {
  //   if (project.id && checked) {
  //     setLoadinState(true);
  //     const { data, success } = await createFormAPI({ projectId: project.id });
  //     setLoadinState(false);

  //     if (success) {
  //       setProjectFormId(data.id);
  //     }
  //   }
  // };

  const renderHeaderContainer = () => {
    return (
      <div className='header-container'>
        <h3>{project.display}</h3>
        <span onClick={closeModal} className='cross-icon'>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  };

  const renderProjectSettings = () => {
    return (
      <div className='project-share-settings'>
        {
          // isLoading ? (
          //   <FontAwesomeIcon icon={faSpinner} spin />
          // ) :
          project.formId ? (
            renderShareLink()
          ) : (
            <Fragment>
              <span className={'form-creation-label'}>{Strings.share}</span>
              {/** @ts-ignore */}
              <Switch
                onChange={(nextChecked) => nextChecked && createFormLink(project.id, nextChecked)}
                checked={project.formId !== undefined}
                height={24}
                handleDiameter={14}
                onColor='#6666ff'
                offColor='#e0e0ff'
                offHandleColor='#aaaaaa'
                className='react-switch'
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </Fragment>
          )
        }
      </div>
    );
  };
  return (
    <Fragment>
      <div className={`project-form-settings-modal-container`}>
        <div className={`project-form-settings-inner-container`}>
          {renderHeaderContainer()}
          <div className='body-container'>{renderProjectSettings()}</div>
        </div>
      </div>
      <div className={`project-form-settings-popup-bg`} onClick={closeModal}></div>
    </Fragment>
  );
}
