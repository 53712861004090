import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

type ButtonPropsType = {
  btnType?: string;
  clicked?: (e: MouseEvent) => void;
  disabled?: boolean;
  loading?: boolean;
  idName: string;
  children: preact.ComponentChildren;
};
const button = (props: ButtonPropsType) => {
  const { btnType, clicked, disabled, loading, idName } = props;

  const renderLoadingIcon = () => {
    if (loading) {
      return (
        <span id={`btn-loading-con-${idName}`} className='loading-con'>
          <FontAwesomeIcon id={`btn-loading-icon-${idName}`} icon={faCircleNotch} spin />
        </span>
      );
    }

    return null;
  };

  return (
    <div id={`btn-container-${idName}`} className='btn-container'>
      <button
        id={`btn--${idName}`}
        disabled={disabled || loading}
        className={['button', btnType].join(' ')}
        onClick={clicked}
      >
        {props.children} {renderLoadingIcon()}
      </button>
    </div>
  );
};

export default button;
