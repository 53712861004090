import { useEffect, useState, Fragment } from 'preact/compat';
import { Strings } from 'resources';
import {
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert } from 'react-alert';
import {
  forgotPassword as forgotPasswordAPI,
  getUserId,
  updateUserRole,
  activateDeactivateUser as activateDeactivateUserAPI,
} from 'services/AuthService';
import { getSuperAdminWorkspaceUsers as getSuperAdminWorkspaceUsersAPI } from 'services/SuperAdminService';
import { useParams } from 'react-router-dom';
import Spinner from 'components/UI/Spinner';
import Switch from 'react-switch';
import { formatDateForMeeting, getName } from 'utils/task';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { log } from 'utils';
import handleViewport from 'react-in-viewport';
import _ from 'underscore';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountDataSelector, sortByState } from 'recoil/AccountState';
import { navigationDataSelector } from 'recoil/NavigationState';
import { currentWorkspaceState } from 'recoil/SuperAdminState';
import { createUserInitials } from 'utils/user';

export default function WorkspaceUsers() {
  const accountData = useRecoilValue(accountDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const [workspaceUsers, setWorkspaceUsers] = useState<UserFieldType[]>([]);
  // const [searchText, setSearchText] = useState('');
  const [refreshUsers, setRefreshUsers] = useState(false);
  // const [showInviteUser, setShowInviteUser] = useState(false);
  // const [inviteEmail, updateInviteEmail] = useState('');
  const [isLoading, setLoadingState] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(0);
  const alert = useAlert();
  const userId = getUserId();
  const { workspaceName, workspaceId } = useParams();
  const setCurrentWorkspace = useSetRecoilState(currentWorkspaceState);
  const setAccountSortBy = useSetRecoilState(sortByState);

  useEffect(() => {
    resetState();
  }, [workspaceName]);

  useEffect(() => {
    getWorkspaceMembers(true);
  }, [refreshUsers, accountData]);

  const resetState = () => {
    setWorkspaceUsers([]);
    setLoadingState(false);
    setPageNum(0);
    setHasMore(true);
    setRefreshUsers(true);
  };

  // Workspace name
  useEffect(() => {
    if (workspaceName !== undefined) {
      setCurrentWorkspace(workspaceName);
    }
    return () => {
      setCurrentWorkspace(null);
    };
  }, [workspaceName]);

  const Block = (props: any) => {
    const { inViewport, forwardedRef } = props;

    if (inViewport) getWorkspaceMembers();
    return (
      <div className='viewport-block' ref={forwardedRef}>
        <div style={{ paddingBottom: '0px' }}>{inViewport ? renderLoading() : ''}</div>
      </div>
    );
  };

  const renderLoading = () => {
    if (isLoading && hasMore)
      return (
        <div style={{ paddingLeft: '30px' }}>
          <Spinner />
        </div>
      );

    return null;
  };

  const getWorkspaceMembers = async (reset?: boolean) => {
    if ((!reset && (!hasMore || isLoading)) || !workspaceId) return;
    const { sortBy, sortOrder } = accountData;
    setLoadingState(true);
    const limit = 50;
    const payload = {
      pageNumber: reset ? 0 : pageNum + 1,
      size: limit,
      sortBy: sortBy,
      ascending: sortOrder === 'asc' ? true : false,
    };

    const { data, success, message } = await getSuperAdminWorkspaceUsersAPI(workspaceId, payload);
    if (!success) {
      log('getWorkspaceMembers error', message);
      alert.error(message);
    } else {
      if (data.length) {
        setHasMore(true);
        if (!reset) setPageNum((prevPageNum) => prevPageNum + 1);
        else setPageNum(0);
      } else {
        setHasMore(false);
      }

      if (reset) setWorkspaceUsers(parseWorkspaceUsers(data));
      else {
        const tempResults = [...workspaceUsers, ...parseWorkspaceUsers(data)];
        setWorkspaceUsers(tempResults);
      }
    }
    setLoadingState(false);
  };

  // const renderLoadingIcon = () => {
  //   if (isLoading) {
  //     return (
  //       <span className='loading-con'>
  //         <FontAwesomeIcon icon={faCircleNotch} spin />
  //       </span>
  //     );
  //   }
  //   return null;
  // };

  const parseWorkspaceUsers = (users: ResponseUserType[]) => {
    const newUsers: UserFieldType[] = [];

    users.forEach((user) => {
      newUsers.push({
        ...user,
        id: user.userId ? user.userId : user.id,
        abortController: new AbortController(),
      });
    });

    return newUsers;
  };

  const getSortIcon = (name: SortByType) => {
    const { sortBy, sortOrder } = accountData;
    if (sortBy === name) {
      if (sortOrder === 'asc') return faSortAlphaDown;
      else return faSortAlphaUp;
    }

    return faSortAmountDown;
  };

  const isSelected = (name: SortByType) => {
    const { sortBy } = accountData;
    if (sortBy === name) return 'selected';

    return '';
  };

  const getInvitationStatusClass = (user: UserFieldType) => {
    if (user.invitationStatus) {
      switch (user.invitationStatus.toLowerCase()) {
        case 'invited':
          return 'success';
        default:
          return 'archived';
      }
    }
    return '';
  };

  const renderInvitationStatus = (user: UserFieldType) => {
    return (
      <Fragment>
        <span
          className={`user-status ${user.invitationStatus ? '' : 'hide'} ${getInvitationStatusClass(
            user,
          )}`}
        >
          {user.invitationStatus}
        </span>
      </Fragment>
    );
  };

  const renderInvitedDate = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.invitedOn ? '' : 'hide'}`}>
          {user.invitedOn ? formatDateForMeeting(user.invitedOn) : ''}
        </span>
      </Fragment>
    );
  };

  const renderJoinedDate = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.joiningDate ? '' : 'hide'}`}>
          {user.joiningDate ? formatDateForMeeting(user.joiningDate) : ''}
        </span>
      </Fragment>
    );
  };

  const renderLastLoginDate = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.lastLogInDate ? '' : 'hide'}`}>
          {user.lastLogInDate ? formatDateForMeeting(user.lastLogInDate) : ''}
        </span>
      </Fragment>
    );
  };

  const renderFirstLoginDate = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.firstLogInDate ? '' : 'hide'}`}>
          {user.firstLogInDate ? formatDateForMeeting(user.firstLogInDate) : ''}
        </span>
      </Fragment>
    );
  };

  const renderTotalLoginTime = (user: UserFieldType) => {
    return (
      <Fragment>
        <div
          className={`user-total-login user-count-con ${
            user.totalLoggedIn === undefined || user.totalLoggedIn === null ? 'hide' : ''
          }`}
        >
          <span className='count'>{user.totalLoggedIn}</span>
        </div>
      </Fragment>
    );
  };

  const renderTotalTasksCompleted = (user: UserFieldType) => {
    return (
      <Fragment>
        <div
          className={`user-total-tasks-completed user-count-con ${
            user.totalCompletedTasks === undefined || user.totalCompletedTasks === null
              ? 'hide'
              : ''
          }`}
        >
          <span className='count'>{user.totalCompletedTasks}</span>
        </div>
      </Fragment>
    );
  };

  const renderTotalCompletedPoints = (user: UserFieldType) => {
    log('renderTotalCompletedPoints', user.totalCompletedPoints);
    return (
      <Fragment>
        <div
          className={`user-total-completed-points user-count-con ${
            user.totalCompletedPoints === undefined || user.totalCompletedPoints === null
              ? 'hide'
              : ''
          }`}
        >
          <span className='count'>{user.totalCompletedPoints}</span>
        </div>
      </Fragment>
    );
  };

  const renderTotalProjectsCreated = (user: UserFieldType) => {
    return (
      <Fragment>
        <div
          className={`user-total-projects-created user-count-con ${
            user.totalProjectsCreated === undefined || user.totalProjectsCreated === null
              ? 'hide'
              : ''
          }`}
        >
          <span className='count'>{user.totalProjectsCreated}</span>
        </div>
      </Fragment>
    );
  };

  const getTutorialProgress = (tutorialCount?: number) => {
    if (tutorialCount === undefined || tutorialCount === null) return 0;
    if (_.isEmpty(tutorialCount)) return 0;
    const steps = Object.values(tutorialCount);
    const totalSteps = steps.reduce((partialSum, a) => partialSum + a, 0);
    return `${totalSteps}/111`;
  };

  const renderTutorialProgress = (user: UserFieldType) => {
    return (
      <Fragment>
        <div
          className={`user-tutorial-progress user-count-con ${
            user.tutorialCounts === undefined || user.tutorialCounts === null ? 'hide' : ''
          }`}
        >
          <span className='count'>{getTutorialProgress(user.tutorialCounts)}</span>
        </div>
      </Fragment>
    );
  };

  const renderTotalTasksCreated = (user: UserFieldType) => {
    return (
      <Fragment>
        <div
          className={`user-total-tasks-created user-count-con ${
            user.totalCreatedTasks === undefined || user.totalCreatedTasks === null ? 'hide' : ''
          }`}
        >
          <span className='count'>{user.totalCreatedTasks}</span>
        </div>
      </Fragment>
    );
  };

  const renderRoleMenuButton = (user: UserFieldType) => {
    return (
      <MenuButton className='user-role-btn'>
        <div className='user-role-con'>
          <span className={`user-role ${user.role ? '' : 'no-role'}`}>
            {user.role ? user.role.toLowerCase() : Strings.no_role}
          </span>
          <span className='edit-role'>{Strings.edit_role}</span>
        </div>
      </MenuButton>
    );
  };

  const userRoleUpdated = async (index: number, userId: string, role: UserRoleType) => {
    const abortController = getAbortController(index);

    if (role === workspaceUsers[index].role) return;

    const { success } = await updateUserRole(userId, role, abortController);

    if (success) updateUserRoleUsingId(userId, role);
  };

  const updateUserRoleUsingId = (userId: string, role: UserRoleType) => {
    const users = [...workspaceUsers];
    const index = users && users.findIndex((item) => item.id === userId);

    if (index !== -1) {
      users[index] = { ...users[index], role };
      setWorkspaceUsers(users);
    }
  };

  const renderUserRole = (user: UserFieldType, index: number) => {
    return (
      <Fragment>
        <Menu className='user-role-menu' menuButton={renderRoleMenuButton(user)}>
          <MenuItem
            disabled={!user.active}
            onClick={() => userRoleUpdated(index, user.id ? user.id : '', 'admin')}
          >
            {Strings.admin}
          </MenuItem>
          <MenuDivider />
          <MenuItem
            disabled={!user.active}
            onClick={() => userRoleUpdated(index, user.id ? user.id : '', 'manager')}
          >
            {Strings.manager}
          </MenuItem>
          <MenuDivider />
          <MenuItem
            disabled={!user.active}
            onClick={() => userRoleUpdated(index, user.id ? user.id : '', 'standard')}
          >
            {Strings.standard}
          </MenuItem>
          <MenuDivider />
          <MenuItem
            disabled={!user.active}
            onClick={() => userRoleUpdated(index, user.id ? user.id : '', 'guest')}
          >
            {Strings.guest}
          </MenuItem>
        </Menu>
      </Fragment>
    );
  };

  const userActivationUpdated = async (
    index: number,
    userId: string,
    active: boolean,
    workspaceId?: string,
  ) => {
    const cancelToken = getAbortController(index);

    return activateDeactivateUser(userId, active, workspaceId, cancelToken);
  };

  const activateDeactivateUser = async (
    userId: string,
    active: boolean,
    workspaceId: string | undefined,
    abortController: AbortController,
  ) => {
    if (!workspaceId) return;
    const { success } = await activateDeactivateUserAPI(
      userId,
      active,
      workspaceId,
      abortController,
    );

    if (success) updateUserActiveStatusUsingId(userId, active);
  };

  const updateUserActiveStatusUsingId = (userId: string, active: boolean) => {
    const users = [...workspaceUsers];
    const index = users && users.findIndex((item) => item.id === userId);

    if (index !== -1) {
      users[index] = { ...users[index], active };
      setWorkspaceUsers(users);
    }
  };

  const getAbortController = (index: number) => {
    const users = workspaceUsers;
    const abortC = users[index].abortController;
    abortC?.abort();

    const newToken = new AbortController();
    users[index].abortController = newToken;
    setWorkspaceUsers(users);

    return newToken;
  };

  const renderUserActive = (user: UserFieldType, index: number) => {
    return (
      <Fragment>
        <span className='user-active'>
          {/** @ts-ignore */}
          <Switch
            onChange={(nextChecked) =>
              userActivationUpdated(index, user.id ? user.id : '', nextChecked, workspaceId)
            }
            checked={user.active ? user.active : false}
            height={24}
            handleDiameter={14}
            onColor='#6666ff'
            offColor='#e0e0ff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={user.id === userId}
          />
        </span>
      </Fragment>
    );
  };

  const getPayload = (user: UserFieldType) => {
    const payload = {};

    payload['origin'] = document.location.origin + '/';
    payload['userName'] = user.email;
    return payload;
  };

  const callForgotPasswordApi = async (payload: any) => {
    setLoadingState(true);
    const { success, message } = await forgotPasswordAPI(payload);
    setLoadingState(false);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      alert.success(Strings.reset_pass_succes);
    }
  };

  const resetPassword = (user: UserFieldType) => {
    callForgotPasswordApi(getPayload(user));
  };

  const renderUsers = (user: UserFieldType, index: number) => {
    return (
      <tr id={`workspace-users-screen-user-row-${index}`} className='user-in-container' key={index}>
        <td id={`workspace-users-screen-name-item-${index}`} className='user-item name-item'>
          <span id={`workspace-users-screen-user-name-${index}`} className='user-name'>
            <img
              src={
                user.profileImage && user.profileImage !== ''
                  ? user.profileImage
                  : createUserInitials(user)
              }
              alt='profile'
            />
            {`${getName(user)} (${user.email})`}
          </span>
        </td>
        <td id={`workspace-users-screen-invitation-status-${index}`} className='user-item'>
          {renderInvitationStatus(user)}
        </td>
        <td id={`workspace-users-screen-invited-date-${index}`} className='user-item'>
          {renderInvitedDate(user)}
        </td>
        <td id={`workspace-users-screen-joined-date-${index}`} className='user-item'>
          {renderJoinedDate(user)}
        </td>
        <td id={`workspace-users-screen-last-login-date-${index}`} className='user-item'>
          {renderLastLoginDate(user)}
        </td>
        <td id={`workspace-users-screen-first-login-date-${index}`} className='user-item'>
          {renderFirstLoginDate(user)}
        </td>
        <td
          id={`workspace-users-screen-total-login-count-${index}`}
          className='user-item total-login-time center-align'
        >
          {renderTotalLoginTime(user)}
        </td>
        <td
          id={`workspace-users-screen-total-tasks-created-${index}`}
          className='user-item total-tasks-created center-align'
        >
          {renderTotalTasksCreated(user)}
        </td>
        <td
          id={`workspace-users-screen-total-completed-tasks-${index}`}
          className='user-item total-tasks-completed center-align'
        >
          {renderTotalTasksCompleted(user)}
        </td>
        <td
          id={`workspace-users-screen-total-completed-points-${index}`}
          className='user-item total-completed-points center-align'
        >
          {renderTotalCompletedPoints(user)}
        </td>
        <td
          id={`workspace-users-screen-total-projects-created-${index}`}
          className='user-item center-align'
        >
          {renderTotalProjectsCreated(user)}
        </td>
        <td
          id={`workspace-users-screen-tutorial-progress-${index}`}
          className='user-item center-align'
        >
          {renderTutorialProgress(user)}
        </td>
        <td id={`workspace-users-screen-user-role-${index}`} className='user-item'>
          {renderUserRole(user, index)}
        </td>
        <td id={`workspace-users-screen-user-active-${index}`} className='user-item'>
          {renderUserActive(user, index)}
        </td>
        <td id={`workspace-users-screen-reset-pass-${index}`} className='user-item'>
          <button className='reset-pass-btn' onClick={() => resetPassword(user)}>
            <FontAwesomeIcon icon={faKey} />
          </button>
        </td>
      </tr>
    );
  };

  const setSortBy = (name: SortByType) => {
    setAccountSortBy(name);
  };

  // const fieldUpdated = (text: string) => {
  //   // setSearchText(text);
  // };

  // const sendInviteClicked = (event: MouseEvent) => {
  //   event.preventDefault();

  //   if (hasEmailErrors() || isLoading) return;

  //   sendInvite();
  // };

  // const hasEmailErrors = () => {
  //   const field = {
  //     name: 'Email',
  //     value: inviteEmail,
  //     type: 'text',
  //     rules: 'required|email|max:50',
  //   };
  //   const fieldsWithError = Validation.validate([field]);
  //   const fieldWithError = fieldsWithError[0];

  //   if (fieldWithError.errors && fieldWithError.errors.length) {
  //     alert.error(fieldWithError.errors[0]);
  //     return true;
  //   } else {
  //     if (areYouInvitingYourself()) {
  //       alert.error(Strings.cant_invite_yourself);
  //       return true;
  //     } else return false;
  //   }
  // };

  // const areYouInvitingYourself = () => {
  //   const userEmail = getUserEmail().toLowerCase().trim();

  //   if (userEmail === inviteEmail) return true;
  //   else return false;
  // };

  // const getInviteMailPayload = (email: string) => {
  //   const userList = {};
  //   const tempEmail = email.toLowerCase().trim();
  //   userList[tempEmail] = 'Standard';

  //   const payload = {
  //     baseUrl: document.location.origin + '/',
  //     users: userList,
  //     invitedBy: getUserEmail(),
  //     company: getUserWorkspaceId(),
  //   };

  //   return payload;
  // };

  // const sendInvite = async () => {
  //   const email = inviteEmail.toLowerCase().trim();
  //   const payload = getInviteMailPayload(email);
  //   updateInviteEmail('');
  //   setLoadingState(true);
  //   const { success, message } = await inviteEmailAPI(payload);
  //   setLoadingState(false);

  //   if (!success) {
  //     alert.error(message);
  //     return log(message);
  //   } else {
  //     setRefreshUsers(true);
  //     // setShowInviteUser(false);
  //     return alert.success(`User: ${email} invited successfully.`);
  //   }
  // };

  const ViewportBlock = handleViewport(Block, { rootMargin: '0px' });

  const renderUsersTable = () => {
    return (
      <table id='workspace-users-screen-users-table'>
        <thead id='workspace-users-screen-users-head'>
          <tr id='workspace-users-screen-user-container-head' className='user-container-head'>
            <th id='workspace-users-screen-name-item-head' className='user-item name-item'>
              <span id='workspace-users-screen-user-name-head-con' className='user-name-con'>
                <span
                  className={`sort-icon ${isSelected('email')}`}
                  onClick={() => setSortBy('email')}
                >
                  <FontAwesomeIcon icon={getSortIcon('email')} />
                </span>
                {` ${Strings.member_invited}`}
              </span>
            </th>
            <th id='workspace-users-screen-user-status-head-item' className='user-item'>
              <span id='workspace-users-screen-user-status-head' className='user-status'>
                <span
                  className={`sort-icon ${isSelected('invitationStatus')}`}
                  onClick={() => setSortBy('invitationStatus')}
                >
                  <FontAwesomeIcon icon={getSortIcon('invitationStatus')} />
                </span>
                {` ${Strings.status}`}
              </span>
            </th>
            <th id='workspace-users-screen-user-dates-head-item' className='user-item'>
              <span id='workspace-users-screen-user-dates-head' className='user-dates'>
                <span
                  className={`sort-icon ${isSelected('invitedOn')}`}
                  onClick={() => setSortBy('invitedOn')}
                >
                  <FontAwesomeIcon icon={getSortIcon('invitedOn')} />
                </span>
                {` ${Strings.invited_date}`}
              </span>
            </th>
            <th id='workspace-users-screen-joining-date-item-head' className='user-item'>
              <span id='workspace-users-screen-joining-date-head' className='user-dates'>
                <span
                  className={`sort-icon ${isSelected('joiningDate')}`}
                  onClick={() => setSortBy('joiningDate')}
                >
                  <FontAwesomeIcon icon={getSortIcon('joiningDate')} />
                </span>
                {` ${Strings.joined_date}`}
              </span>
            </th>
            <th id='workspace-users-screen-last-login-date-item-head' className='user-item'>
              <span id='workspace-users-screen-last-login-date-head' className='user-dates'>
                <span
                  className={`sort-icon ${isSelected('lastLogInDate')}`}
                  onClick={() => setSortBy('lastLogInDate')}
                >
                  <FontAwesomeIcon icon={getSortIcon('lastLogInDate')} />
                </span>
                {` ${Strings.last_login}`}
              </span>
            </th>
            <th id='workspace-users-screen-first-login-date-head-item' className='user-item'>
              <span id='workspace-users-screen-first-login-date-head' className='user-dates'>
                <span
                  className={`sort-icon ${isSelected('firstLogInDate')}`}
                  onClick={() => setSortBy('firstLogInDate')}
                >
                  <FontAwesomeIcon icon={getSortIcon('firstLogInDate')} />
                </span>
                {` ${Strings.first_login}`}
              </span>
            </th>
            <th
              id='workspace-users-screen-total-login-head-item'
              className='user-item center-align'
            >
              <span id='workspace-users-screen-total-login-head' className='user-total-login'>
                <span
                  className={`sort-icon ${isSelected('totalLoggedIn')}`}
                  onClick={() => setSortBy('totalLoggedIn')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalLoggedIn')} />
                </span>
                {` ${Strings.time_logged_in}`}
              </span>
            </th>
            <th
              id='workspace-users-screen-total-created-task-head-item'
              className='user-item center-align'
            >
              <span
                id='workspace-users-screen-total-created-task-head'
                className='user-total-created-tasks'
              >
                <span
                  className={`sort-icon ${isSelected('totalCreatedTasks')}`}
                  onClick={() => setSortBy('totalCreatedTasks')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalCreatedTasks')} />
                </span>
                {` ${Strings.total_tasks_created}`}
              </span>
            </th>
            <th
              id='workspace-users-screen-total-completed-task-head-item'
              className='user-item center-align'
            >
              <span
                id='workspace-users-screen-total-completed-task-head'
                className='user-total-completed-tasks'
              >
                <span
                  className={`sort-icon ${isSelected('totalCompletedTasks')}`}
                  onClick={() => setSortBy('totalCompletedTasks')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalCompletedTasks')} />
                </span>
                {` ${Strings.total_tasks_completed}`}
              </span>
            </th>
            <th
              id='workspace-users-screen-total-completed-points-head-item'
              className='user-item center-align'
            >
              <span
                id='workspace-users-screen-total-completed-points-head'
                className='user-total-completed-points'
              >
                <span
                  className={`sort-icon ${isSelected('totalCompletedPoints')}`}
                  onClick={() => setSortBy('totalCompletedPoints')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalCompletedPoints')} />
                </span>
                {` ${Strings.total_completed_points}`}
              </span>
            </th>
            <th
              id='workspace-users-screen-total-projects-created-head-item'
              className='user-item center-align'
            >
              <span
                id='workspace-users-screen-total-projects-created-head'
                className='user-total-completed-tasks user-total-projects'
              >
                <span
                  className={`sort-icon ${isSelected('totalProjectsCreated')}`}
                  onClick={() => setSortBy('totalProjectsCreated')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalProjectsCreated')} />
                </span>
                {Strings.total_projects_created}
              </span>
            </th>
            <th
              id='workspace-users-screen-tutorial-progress-head-item'
              className='user-item center-align'
            >
              <span
                id='workspace-users-screen-tutorial-progress-head'
                className='user-tutorial-progress'
              >
                {Strings.tutorial_progress}
              </span>
            </th>
            <th id='workspace-users-screen-user-role-head-item' className='user-item'>
              <span id='workspace-users-screen-user-role-head' className='user-role-con'>
                <span
                  className={`sort-icon ${isSelected('role')}`}
                  onClick={() => setSortBy('role')}
                >
                  <FontAwesomeIcon icon={getSortIcon('role')} />
                </span>
                {` ${Strings.role}`}
              </span>
            </th>
            <th id='workspace-users-screen-user-active-head-item' className='user-item'>
              <span id='workspace-users-screen-user-role-active-head' className='user-active'>
                <span
                  className={`sort-icon ${isSelected('active')}`}
                  onClick={() => setSortBy('active')}
                >
                  <FontAwesomeIcon icon={getSortIcon('active')} />
                </span>
                {` ${Strings.active}`}
              </span>
            </th>
            <th id='workspace-users-screen-user-reset-password-head-item' className='user-item' />
          </tr>
        </thead>
        <tbody>{workspaceUsers.map((user, index) => renderUsers(user, index))}</tbody>
      </table>
    );
  };

  return (
    <div
      id='workspace-users-screen'
      className={`manage-workspaces-screen workspace-users-screen projects-screen ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div id='workspace-users-screen-title-container' className='title-container'>
        <div id='workspace-users-screen-page-title' className='page-title'>
          {workspaceName}
        </div>
      </div>
      <div id='workspace-users-screen-subtitle-container' className='subtitle-container'>
        <div id='workspace-users-screen-manage-users-title' className='list-title'>
          {Strings.manage_users}
        </div>
      </div>
      <div id='workspace-users-screen-manage-users-con' className='manage-user-con'>
        {renderUsersTable()}
        <ViewportBlock key='load-next' />
      </div>
    </div>
  );
}
