import { getCurrentTheme, getThemeSlug } from 'utils/theme';
import { attachStylesheet, COMPLETE_TASK_NAME } from 'triggers/actions/css';
import { playSound, removeSound, BG_SOUND_NAME } from 'triggers/actions/sound';
import { loadCanvasAnimations } from 'triggers/actions/canvas';
import { loadLottie } from 'triggers/actions/lottie';
import { getActiveTriggers } from 'services/ThemeService';
import { resetAllCSSTriggers } from 'triggers/utils/css';
import { log } from 'utils';
import { resetRecoil } from 'recoil-nexus';
import { allTriggersState } from 'recoil/TriggerState';
import {
  setCanvasTrigger,
  setLottieTrigger,
  setRiveTrigger,
  setSoundTrigger,
  setTriggerProp,
} from 'recoil/TriggerState/update';

export const BG_ANIMATION_LOW = 'bg-animation-low';

export const initializeTriggers = async (reset?: boolean) => {
  if (reset) resetAllTriggers();

  const { data, success } = await getActiveTriggers();

  if (success && data && data.pointLevels && data.name) {
    const currentTheme = getCurrentTheme();
    if (currentTheme.id !== getThemeSlug(data.name)) return;

    getThemeTriggers(extractTriggersFromLevels(data.pointLevels));
  }
};

export const resetAllTriggers = () => {
  resetRecoil(allTriggersState);
  resetAllCSSTriggers();
  removeSound('bg-music'); // remove bg music
};

const extractTriggersFromLevels = (levels: ThemeLevelType[]) => {
  const allTriggers: TriggersType[] = [];

  Object.keys(levels).forEach((key, _index) => {
    const level = levels[key];
    const triggers: TriggersType[] = level.triggers ? level.triggers : [];

    triggers.forEach((level) => {
      if (!allTriggers.includes(level)) allTriggers.push(level);
    });
  });

  return allTriggers;
};

const getThemeTriggers = async (currentTriggers: TriggersType[]) => {
  try {
    const currentTheme = getCurrentTheme();
    const allTriggers = await import(`./themes/${currentTheme.id}/triggers.json`);

    applyTriggers(allTriggers.default, currentTriggers, currentTheme.id);
  } catch (error) {
    log(error);
  }
};

const applyTriggers = (
  availableTriggers: TriggerActionObjectType[],
  currentTriggers: TriggersType[],
  themeId: string,
) => {
  currentTriggers.forEach((currentTrigger) => {
    const index = availableTriggers.findIndex((aTrigger) => aTrigger.id === currentTrigger);

    if (index !== -1) {
      const trigger = availableTriggers[index];
      const actions = trigger.action.split('|');

      actions.forEach((action) => {
        switch (action) {
          case 'CSS':
            applyCSSTrigger(trigger, themeId);
            break;
          case 'SOUND':
            applySoundTrigger(trigger, themeId);
            break;
          case 'LOTTIE':
            applyLottieTrigger(trigger, themeId);
            break;
          case 'CANVAS':
            applyCanvasTrigger(trigger, themeId);
            applyCSSTrigger(trigger, themeId, true);
            break;
          case 'RIVE':
            applyRiveTrigger(trigger);
            break;
          default:
            break;
        }
      });
    }
  });
};

const mapCSSdelay: CSSDelayObjectType[] = [
  {
    key: 'createTaskDelay',
    id: 'task-creation-animation',
    default: 0,
  },
  {
    key: 'createSubtaskDelay',
    id: 'subtask-creation-animation',
    default: 0,
  },
  {
    key: 'completeTaskDelay',
    id: 'complete-task-animation',
    default: 300,
  },
];

const applyCSSTrigger = (
  trigger: TriggerActionObjectType,
  themeId: string,
  dontApply: boolean = false,
) => {
  const index = mapCSSdelay.findIndex((css) => css.id === trigger.id);

  if (index !== -1) {
    const cssDelay = mapCSSdelay[index];
    setTriggerProp(
      cssDelay.key,
      trigger.cssAnimationDuration ? trigger.cssAnimationDuration : cssDelay.default,
    );
  }

  if (!dontApply) attachStylesheet(trigger.id, themeId);
};

const mapSound: TriggerAnimObjectType[] = [
  {
    key: 'bgSound',
    id: 'bg-music',
  },
  {
    key: 'completeTask',
    id: 'complete-task-sound',
  },
  {
    key: 'createTask',
    id: 'task-creation-sound',
  },
  {
    key: 'reOrderTask',
    id: 're-order-task-sound',
  },
  {
    key: 'createSubTask',
    id: 'subtask-creation-sound',
  },
  {
    key: 'switchScreen',
    id: 'menu-navigation-sound',
  },
  {
    key: 'reOrderTask',
    id: 're-order-task-sound',
  },
  {
    key: 'modalOpen',
    id: 'modal-open-sound',
  },
  {
    key: 'modalClose',
    id: 'modal-close-sound',
  },
];

const applySoundTrigger = (trigger: TriggerActionObjectType, themeId: string) => {
  if (trigger.id === BG_SOUND_NAME) {
    playSound(trigger.id, themeId);
  }

  const index = mapSound.findIndex((sound) => sound.id === trigger.id);

  if (index !== -1) {
    const soundTrigger = mapSound[index];
    setSoundTrigger(soundTrigger.key, soundTrigger.id);
    if (soundTrigger.key === 'switchScreen') {
      setSoundTrigger('modalOpen', 'modal-open-sound');
      setSoundTrigger('modalClose', 'modal-close-sound');
    }
  }
};

const applyLottieTrigger = (trigger: TriggerActionObjectType, themeId: string) => {
  if (trigger.id === COMPLETE_TASK_NAME) {
    loadLottie(trigger.id, themeId, 3000);

    setTriggerProp(
      'lottieCompleteTaskDelay',
      trigger.lottieAnimationDuration ? trigger.lottieAnimationDuration : 0,
    );
    setLottieTrigger('completeTask', trigger.id);
  }
};

const mapTriggers: TriggerAnimObjectType[] = [
  {
    key: 'reOrderTask',
    id: 're-order-task-animation',
  },
  {
    key: 'completeTask',
    id: 'complete-task-animation',
  },
  {
    key: 'createTask',
    id: 'task-creation-animation',
  },
  {
    key: 'bgAnimationLow',
    id: 'bg-animation-low',
  },
  {
    key: 'bgAnimationMedium',
    id: 'bg-animation-medium',
  },
  {
    key: 'bgAnimationHigh',
    id: 'bg-animation-high',
  },
];

const applyCanvasTrigger = (trigger: TriggerActionObjectType, themeId: string) => {
  loadCanvasAnimations(themeId);
  const index = mapTriggers.findIndex((canvas) => canvas.id === trigger.id);

  if (index !== -1) {
    const canvasTrigger = mapTriggers[index];
    setCanvasTrigger(canvasTrigger.key, canvasTrigger.id);
  }
};

const mapRivedelay: CSSDelayObjectType[] = [
  {
    key: 'riveCreateTaskDelay',
    id: 'task-creation-animation',
    default: 0,
  },
  {
    key: 'riveCompleteTaskDelay',
    id: 'complete-task-animation',
    default: 300,
  },
];

const applyRiveTrigger = (trigger: TriggerActionObjectType) => {
  const index = mapTriggers.findIndex((rive) => rive.id === trigger.id);

  if (index !== -1) {
    const riveTrigger = mapTriggers[index];
    setRiveTrigger(riveTrigger.key, riveTrigger.id);

    const delayIndex = mapRivedelay.findIndex((rive) => rive.id === trigger.id);

    if (delayIndex !== -1) {
      const riveDelay = mapRivedelay[delayIndex];
      setTriggerProp(
        riveDelay.key,
        trigger.riveAnimationDuration ? trigger.riveAnimationDuration : riveDelay.default,
      );
    }
  }
};
