/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useMemo, useEffect, useLayoutEffect, useState } from 'preact/compat';
import { log } from 'utils';
import { hasUpdated } from 'utils/helpers';
import { parseTags } from 'utils/task';
import { updateTask as updateTemplateTask } from 'recoil/TemplateState/update';
import { getUserId, getUserName, isAdmin } from 'services/AuthService';
import { updateTaskTags as updateTaskTagsInAPI } from 'services/TagService';
import _ from 'underscore';
import ProjectSelection from './ProjectSelection';
import AssigneeSelection from './AssigneeSelection';
import SizeSelection from './SizeSelection';
import StartAndEndDateSelection from './StartAndEndDateSelection';
import DueDateSelection from './DueDateSelection';
import TaskCheckbox from './TaskCheckbox';
import TaskTags from './TaskTags';
import CommentsIcon from './CommentsIcon';
import InfoIcon from './InfoIcon';
import RecurringIcon from './RecurringIcon';
import InputBox from './InputBox';
import Animations from 'animations';
import { runCanvasAnimation } from 'triggers/utils/canvas';
import { playTheRiveCanvas } from 'triggers/utils/rive';
import { playModalOpenSound, playTheSound } from 'triggers/utils/sound';
import CollapseIcon from './CollapseIcon';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentRouteState, taskDetailModalState } from 'recoil/NavigationState';
import { setPlayRiveTrigger } from 'recoil/TriggerState/update';
import { allUserProjectsState, allUserTagsState, currentUserIdState } from 'recoil/UserState';
import { allProjectsState, currentProjectIdState } from 'recoil/ProjectState';
import {
  allTemplatesState,
  allTemplateTagsState,
  currentTemplateIdState,
} from 'recoil/TemplateState';
import {
  accountDataSelector,
  accountSettingsState,
  accountState,
  allTagsState,
} from 'recoil/AccountState';
import { itemTagsSelector, itemValueSelector } from 'recoil/TaskState';
import { allSizes } from 'utils/size';
import { triggerDataSelector } from 'recoil/TriggerState';
import {
  getStateTypeMain,
  updateTaskAnimation,
  updateTaskTagsAndTagInfo,
  setHighlightedTask as setHighlightedTaskInRecoil,
} from 'recoil/TaskState/update';
import { updateTaskMeta as updateRecurringTaskMetaData } from 'recoil/RecurringTaskState/update';
import AvatarIcon from 'assets/images/Avatar.png';

function IndividualItem(props: IndividualItemPropsInterface) {
  // Component props
  const {
    index,
    item,
    itemId,
    isTemp,
    tempId,
    parentIndex,
    parentId,
    parentTempId,
    provided,
    isDragging,
    readOnly,
    updateValue,
    itemChecked,
    itemSize,
    itemProject,
    itemNumber,
    itemStartDate,
    itemEndDate,
    itemDueDate,
    itemAssignee,
    dataType,
    stateType,
    saveTaskName,
    updateTaskProject,
    updateTaskAssignee,
    updateTaskSize,
    addNewTask,
    storeInputRefs,
    checkTask,
    storeDateRefs,
    onChangeDate,
    onChangeDueDate,
    updateTaskTags,
    hasSubtasks,
    deleteTask,
    itemComments,
    animation,
    recurringTaskMeta,
    itemTemplate,
    updateTaskCollapseStatus,
    collapsedSubTasks,
    selectedCount,
    isMultiSelected,
    bulkUpdateTasksTag,
    hideDragIcon,
    isHighlighted,
    selectedNotificationTaskId,
  } = props;

  const userId = getUserId();
  const userName = getUserName();

  const currentRoute = useRecoilValue(currentRouteState);
  const [taskDetailModalData, setTaskDetailModalData] = useRecoilState(taskDetailModalState);
  const taskDetailId = taskDetailModalData.taskId;
  const triggerData = useRecoilValue(triggerDataSelector);

  const currentUserId = useRecoilValue(currentUserIdState);
  const currentProjectId = useRecoilValue(currentProjectIdState);
  const currentTemplateId = useRecoilValue(currentTemplateIdState);
  const allProjects = useRecoilValue(allProjectsState);
  const allTemplates = useRecoilValue(allTemplatesState);
  const accountData = useRecoilValue(accountDataSelector);
  const account = useRecoilValue(accountState);
  const accountProfileImage = account?.profileImage;
  const allAccountUserTags = useRecoilValue(allTagsState);
  const allUserTags = useRecoilValue(allUserTagsState);
  const allTemplateTags = useRecoilValue(allTemplateTagsState);

  const accountSettings = useRecoilValue(accountSettingsState);
  const spellChecker = accountSettings.spellChecker;

  const allUsers: UserFieldType[] = accountData.allUsers
    ? [
        { id: userId, name: userName, display: userName, profileImage: null },
        ...accountData.allUsers,
      ]
    : [{ id: userId, name: userName, display: userName, profileImage: null }];

  const [createSubTaskAnimation, setCreateSubTaskAnimation] = useState(false);
  const [projectFilterValue, setProjectFilterValue] = useState('');

  const updateProjectFilterValue = (value) => setProjectFilterValue(value);

  // Recurring task
  const [showRecurringTaskModal, setShowRecurringTaskModal] = useState(false);

  useLayoutEffect(() => {
    if (animation === 'ADD_SUB_TASK' && parentId) {
      subTaskCreated();
    }
    if (animation === 'REORDER_TASK') {
      taskReordered();
    } else if (animation === 'REORDER_TASK_DELAYED') {
      setTimeout(() => {
        taskReordered();
      }, 200);
    }
  }, [animation]);

  useEffect(() => {
    if (typeof selectedNotificationTaskId === 'string') {
      const selectedNotificationItem = document.getElementById(selectedNotificationTaskId);
      if (selectedNotificationItem) {
        selectedNotificationItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [selectedNotificationTaskId]);

  const subTaskCreated = () => {
    setTimeout(() => {
      setCreateSubTaskAnimation(false);

      updateTaskAnimation(dataType, index, undefined, parentIndex, stateType);
    }, triggerData.createSubtaskDelay);
  };

  const taskReordered = () => {
    if (triggerData.canvasTriggers.reOrderTask) {
      runCanvasAnimation(triggerData.canvasTriggers.reOrderTask, {
        tempId: tempId,
        parentIndex: parentIndex !== undefined && parentIndex > -1 ? true : false,
      });
    } else if (triggerData.riveTriggers.reOrderTask) {
      playTheRiveCanvas(triggerData.riveTriggers.reOrderTask, {
        tempId: tempId,
      });
    }

    if (triggerData.soundTriggers.reOrderTask) playTheSound(triggerData.soundTriggers.reOrderTask);

    updateTaskAnimation(dataType, index, undefined, parentIndex, stateType);
  };

  const getTagList = (tagList: TagType[] | undefined) => {
    if (!tagList) return [];
    let index = -1;
    if (itemProject) {
      index = tagList.findIndex((data) => data.projectId === itemProject.id);
      return index > -1 && tagList[index].tags ? tagList[index].tags! : [];
    } else {
      return tagList.length > 0 && tagList[tagList.length - 1].tags
        ? tagList[tagList.length - 1].tags!
        : [];
    }
  };

  /*
   * Get all tags
   *
   * Based upon stateType and if item project is set or not
   *
   * returns Array
   */
  const allTags = useMemo(() => {
    let data: TagObjectType[] = [];

    if (stateType === 'INDIVIDUAL') {
      data = getTagList(allUserTags);
    } else if (stateType === 'TEMPLATE') {
      data = allTemplateTags;
    } else {
      data = getTagList(allAccountUserTags);
    }
    return data;
  }, [JSON.stringify(allUserTags), JSON.stringify(allAccountUserTags), allTemplateTags]);

  /*
   * Item Tags
   *
   * returns String
   */

  const updateTagInStateAndRedux = (updatedTags: TagObjectType[], update: boolean) => {
    log('updateTagInStateAndRedux', updatedTags, update, itemTags);
    if (updateTaskTags)
      updateTaskTags(
        index,
        itemId,
        updatedTags,
        parentIndex,
        parentId,
        update,
        item && item.recurringTaskMeta !== null && item.recurringTaskMeta !== undefined,
      );
  };

  const updateValueInStateAndRecoil = (value: string, instant: boolean) => {
    saveTaskName &&
      saveTaskName(index, itemId, value, 'name', parentIndex, parentId, instant, tempId);
  };

  /*
   * Update task size in backend
   *
   * size String
   *
   * returns null
   */
  const updateSize = (size: SizeOptionsType) => {
    updateTaskSize &&
      updateTaskSize(
        index,
        itemId,
        size,
        parentIndex,
        parentId,
        item.recurringTaskMeta !== null && item.recurringTaskMeta !== undefined,
      );
    log('need to update size in db', size, itemId);
  };

  /*
   * Update task project in backend
   *
   * project String
   *
   * returns null
   */
  const updateProject = (project: string | null, taskName?: string) => {
    updateTaskProject && updateTaskProject(index, itemId, project, parentIndex, parentId, taskName);
    log('need to update project in db', project, parentIndex, parentId);
  };
  /*
   * Update task assignee in backend
   *
   * assignee String
   * textValue String
   *
   * returns null
   */
  const updateAssignee = (assignee: string | null, taskName?: string) => {
    updateTaskAssignee &&
      updateTaskAssignee(
        index,
        itemId,
        assignee ? assignee : null,
        parentIndex,
        parentId,
        item.recurringTaskMeta !== null && item.recurringTaskMeta !== undefined,
        taskName,
      );
  };

  const filteredProjects = useMemo(() => {
    const projects = allProjects ? allProjects.filter((proj) => proj.archived !== true) : [];
    const filteredProjectsList: ProjectObjectType[] = [];
    const filter = projectFilterValue.trim().toUpperCase();
    projects.forEach((proj) => {
      const name = proj.name?.toUpperCase();
      if (itemAssignee?.id) {
        const ids = proj.users?.map((o) => o.id);
        const filteredUsers = proj.users?.filter(({ id }, index) => !ids?.includes(id, index + 1));
        filteredUsers?.forEach((user) => {
          if (filter) {
            if (itemAssignee.id === user.id && name?.includes(filter)) {
              filteredProjectsList.push(proj);
            }
          } else if (itemAssignee.id === user.id) {
            filteredProjectsList.push(proj);
          }
        });
      } else {
        if (name?.includes(filter)) filteredProjectsList.push(proj);
      }
    });
    return filteredProjectsList;
  }, [projectFilterValue, allProjects, itemAssignee?.id]);

  /*
   * Update task collapsible status in backend
   *
   * collapsible status bool
   *
   * returns null
   */
  const updateTaskCollapse = (collapseStatus: boolean) => {
    updateTaskCollapseStatus(index, itemId, collapseStatus, parentIndex, parentId);
  };

  /*
   * Delete the task in backend
   *
   * returns null
   */
  const deleteTheTask = (checked?: boolean) => {
    if (item.isTemp) return;

    deleteTask(
      index,
      itemId,
      parentIndex,
      parentId,
      item.recurringTaskMeta !== null && item.recurringTaskMeta !== undefined,
      checked ? checked : false,
    );
    log('delete task', index, itemId, parentIndex);
  };

  /*
   * Get selected project name
   *
   * returns String
   */
  const selectedProjectName = useMemo(() => {
    if (!itemProject) return '';
    else {
      const index = allProjects ? allProjects.findIndex((item) => item.id === itemProject.id) : -1;

      if (index === -1) return '';
      else return allProjects ? allProjects[index].name : '';
    }
  }, [JSON.stringify(itemProject), allProjects]);

  /*
   * Get selected project name
   *
   * returns String
   */
  const selectedProjectUsers = useMemo(() => {
    if (!itemProject) return [];
    else {
      const index = allProjects ? allProjects.findIndex((item) => item.id === itemProject.id) : -1;

      if (index === -1) return [];
      else return allProjects ? allProjects[index].users : [];
    }
  }, [itemProject, allProjects]);

  /*
   * Get selected template users
   *
   * returns String
   */
  const selectedTemplateUsers = useMemo(() => {
    if (!itemTemplate) return [];
    else {
      if (allTemplates === null) return [];
      const index = allTemplates.findIndex((item) => item.id === itemTemplate);

      if (index === -1) return [];
      else return allTemplates[index].users;
    }
  }, [itemTemplate, allTemplates]);

  /*
   * Get Project name with task number
   *
   * returns String
   */
  const getProjectNameWithTaskNumber = useMemo(() => {
    let itemProj = 'No Project';
    if (itemProject) {
      if (selectedProjectName && selectedProjectName.length > 0) {
        itemProj = selectedProjectName;
      } else if (itemProject.name) {
        itemProj = itemProject.name;
      } else if (selectedProjectName) {
        itemProj = selectedProjectName;
      }
    }

    if (itemNumber === 0) return itemProj;

    return `${itemProj} - #${itemNumber}`;
  }, [JSON.stringify(itemProject), allProjects, itemNumber]);

  /*
   * Render Project name with task number
   *
   * returns React.DOM
   */
  const renderProjectNameWithTaskNumber = useMemo(() => {
    if (stateType === 'TEMPLATE') {
      if (itemNumber) return <span className='project-name-num'>{`#${itemNumber}`}</span>;
      else return null;
    }

    let itemProj = 'My Tasks';
    if (itemProject) {
      if (selectedProjectName && selectedProjectName.length > 0) {
        itemProj = selectedProjectName;
      } else if (itemProject.name) {
        itemProj = itemProject.name;
      } else if (selectedProjectName) {
        itemProj = selectedProjectName;
      }
    }
    if (stateType === 'RECURRING') return <span>{itemProj}</span>;

    return (
      <span className='project-name-num'>
        <span className='project-name-text'>{itemProj}</span>
        {itemProj !== '' ? `${accountSettings.projectId ? ' - ' : ''}` : ''}
        {accountSettings.projectId ? (itemNumber ? '#' + itemNumber : '') : ''}
      </span>
    );
  }, [itemProject, allProjects, itemNumber, accountSettings.projectId]);

  /*
   * Get selected assignee name
   *
   * returns String
   */
  const selectedAssigneeName = useMemo(() => {
    if (!itemAssignee) return '';
    else {
      const index = allUsers.findIndex((item) => item.id === itemAssignee.id);

      if (index === -1) return '';
      else return allUsers[index].name;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Get selected assignee profile image
   *
   * returns String
   */
  const selectedAssigneeProfileImage = useMemo(() => {
    if (!itemAssignee) return AvatarIcon;
    else {
      if (itemAssignee.id === getUserId()) {
        if (accountProfileImage) return accountProfileImage;
        else return AvatarIcon;
      }

      const index = allUsers.findIndex((item) => item.id === itemAssignee.id);

      if (
        index !== -1 &&
        allUsers[index] &&
        allUsers[index].hasOwnProperty('profileImage') &&
        allUsers[index].profileImage
      )
        return allUsers[index].profileImage;

      return AvatarIcon;
    }
  }, [itemAssignee, allUsers]);

  /*
   * Set task tags in backend
   *
   * tagsArray Array
   * add Boolean
   *
   * returns null
   */
  const setTaskTags = async (id: string, name: string, add: boolean, taskName?: string) => {
    if (isTemp) return;

    if (stateType === 'RECURRING') {
      const tags = itemTags ? [...itemTags] : [];
      tags.push({ id, name });

      if (updateTaskTags)
        updateTaskTags(
          index,
          itemId,
          tags,
          parentIndex,
          parentId,
          true,
          item.recurringTaskMeta !== null && item.recurringTaskMeta !== undefined,
          taskName,
        );
    } else if (stateType === 'TEMPLATE') {
      let tags = [...itemTags];
      if (add) {
        tags.push({ id, name });
      } else {
        tags = tags.filter((item) => item.id !== id);
      }
      updateTemplateTask(
        'tags',
        tags,
        index,
        itemId,
        parentIndex,
        parentId,
        dataType,
        false,
        taskName ? taskName : itemValue,
        [id],
        add,
        tempId,
      );
    } else {
      const { success, data } = await updateTaskTagsInAPI(
        itemId,
        [id],
        add,
        tempId,
        taskName ? taskName : itemValue,
        false,
      );

      if (success && add && data && data.tags && data.tagInfo) {
        updateTaskTagsAndTagInfo(
          dataType,
          index,
          parseTags(data.tags ? data.tags : []) as TagObjectType[],
          data.tagInfo ? data.tagInfo : [],
          parentIndex,
          stateType,
        );
      }
    }
  };

  /*
   * Check if task is disabled or not
   *
   * returns Boolean
   */
  const isDisabled = useMemo(() => {
    if (readOnly || itemChecked) {
      return true;
    }

    return false;
  }, [itemChecked]);

  /*
   * Check if task is from another user or project based on stateType
   *
   * returns Boolean
   */
  const fromAnotherUserOrProject = () => {
    if (stateType === 'INDIVIDUAL') {
      if (itemAssignee?.id && itemAssignee?.id !== currentUserId) return true;
      else return false;
    } else if (stateType === 'PROJECT') {
      if (itemProject?.id && itemProject?.id !== currentProjectId) return true;
      else return false;
    } else if (stateType === 'TEMPLATE') {
      if (itemTemplate && itemTemplate !== currentTemplateId) return true;
      else return false;
    } else {
      if (userId && itemAssignee?.id && itemAssignee?.id !== userId) return true;
      else return false;
    }
  };

  /**
   * Check if task details is currently opened
   * @returns Boolean
   */
  const isSelected = () => {
    if (taskDetailId === itemId || selectedNotificationTaskId === itemId) {
      return true;
    }
    return false;
  };

  /*
   * Show task info
   *
   * returns null
   */
  const showTaskInfo = () => {
    const taskDetailModal: TaskDetailModalObjectType = {
      visible: true,
      modalType: 'task',
      taskId: itemId,
      parentId: parentId,
      hideComments: false,
      taskObj: null,
      dataType: dataType,
    };
    setTaskDetailModalData(taskDetailModal);
    playModalOpenSound();
  };

  /*
   * Show comments modal
   *
   * returns null
   */
  const showCommentModal = () => {
    const taskDetailModal: TaskDetailModalObjectType = {
      visible: true,
      modalType: 'comment',
      taskId: itemId,
      parentId: parentId,
      hideComments: false,
      taskObj: null,
      dataType: dataType,
    };
    setTaskDetailModalData(taskDetailModal);
    playModalOpenSound();
  };

  const itemValue = useRecoilValue(
    itemValueSelector({ index, dataType, stateType, parentId, parentIndex }),
  );
  const itemTags = useRecoilValue(
    itemTagsSelector({ index, dataType, stateType, parentId, parentIndex }),
  );
  /*
   * Check/uncheck task
   *
   * returns null
   */
  const checkTheTask = () => {
    log('check the task', itemValue);

    if (item.isTemp) return;

    if (itemValue?.trim() === '') deleteTheTask(true);
    else {
      checkTask && checkTask(index, itemId, parentIndex, parentId, !itemChecked);
    }
  }; // , [updateValue, itemChecked, itemId, itemValue]);

  /*
   * Get tag id for name
   *
   * tagName String
   *
   * returns String or null
   */
  const getTagIdForName = (tagName: string) => {
    const index =
      allTags && allTags.findIndex((tag) => tag.name.toUpperCase() === tagName.toUpperCase());

    if (index !== -1) return allTags[index].id;

    return null;
  };

  /*
   * Remove tag from task
   *
   * tagId String
   * tagName String
   *
   * returns String
   */
  const removeTagFromTask = (tagId: string | null, tagName: string) => {
    if (!tagId) tagId = getTagIdForName(tagName);

    if (tagId) {
      setTaskTags(tagId, tagName, false);
      removeTagFromTaskTags(tagId);
    }
  };

  const removeTagFromTaskTags = (id: string) => {
    let tags = itemTags;

    tags = tags.filter((tag) => tag.id !== id);

    updateTagInStateAndRedux(tags, true);
  };

  /*
   * Render info section
   *
   * returns React.DOM
   */
  const renderInfoSection = () => {
    if (stateType === 'RECURRING') return null;

    return <InfoIcon tempId={tempId} stateType={stateType} showTaskInfo={showTaskInfo} />;
  };

  /*
   * Render info section
   *
   * returns React.DOM
   */
  const renderRecurringTaskIcon = () => {
    if (parentId || !recurringTaskMeta) return null;

    return (
      <RecurringIcon
        tempId={tempId}
        itemTemplate={itemTemplate}
        stateType={stateType}
        recurringTaskMeta={recurringTaskMeta}
        updateRecurringTaskMeta={(metaData) => {
          updateRecurringTaskMetaData(itemId, metaData, dataType, stateType);
        }}
      />
    );
  };

  const renderRecurringTaskModal = () => {
    const itemProps = { ...props };
    return showRecurringTaskModal ? (
      <RecurringIcon
        {...itemProps}
        itemProject={itemProject ? itemProject.id : itemProject}
        itemAssignee={itemAssignee ? itemAssignee.id : itemAssignee}
        itemValue={itemValue}
        tempId={tempId}
        userId={userId}
        itemTemplate={itemTemplate}
        stateType={stateType}
        recurringTaskMeta={recurringTaskMeta}
        toggleRecurringTaskModalDisplay={() => setShowRecurringTaskModal(!showRecurringTaskModal)}
        showOnlyModal={showRecurringTaskModal}
        updateRecurringTaskMeta={(metaData) => {
          updateRecurringTaskMetaData(itemId, metaData, dataType, stateType);
        }}
        resetParentState={() => {}}
      />
    ) : null;
  };

  /*
   * Render comment section
   *
   * returns React.DOM
   */
  const renderCommentSection = () => {
    if (stateType === 'RECURRING') return null;

    return (
      <CommentsIcon
        commentCount={itemComments ? itemComments.length : 0}
        tempId={tempId}
        stateType={stateType}
        showCommentModal={showCommentModal}
      />
    );
  };

  /*
   * Render size Selection
   *
   * returns React.DOM
   */
  const renderSizeSelection = () => {
    return (
      <SizeSelection
        tempId={tempId}
        itemSize={itemSize}
        showSize={accountSettings.size}
        isDisabled={isDisabled}
        updateSize={(size) => updateSize(size)}
        allSizes={allSizes}
      />
    );
  };

  /*
   * Render project Selection
   *
   * returns React.DOM
   */
  const renderProjectSelection = () => {
    if (
      stateType === 'TEMPLATE' &&
      (item.recurringTaskMeta || itemProject === null || itemProject === undefined)
    ) {
      return null;
    }

    return (
      <ProjectSelection
        allProjects={filteredProjects}
        updateProjectFilterValue={updateProjectFilterValue}
        showProjectId={accountSettings.projectId}
        isSubtask={parentId ? true : false}
        itemProject={itemProject}
        hasAssignee={itemAssignee ? true : false}
        projectNameWithTaskNumber={getProjectNameWithTaskNumber}
        renderProjectNameWithTaskNumber={renderProjectNameWithTaskNumber}
        isDisabled={isDisabled}
        updateProject={(project) => updateProject(project)}
        stateType={stateType}
        overlayClass={''}
      />
    );
  };

  /*
   * Render assignee Selection
   *
   * returns React.DOM
   */
  const renderAssigneeSelection = () => {
    return (
      <AssigneeSelection
        tempId={tempId}
        allUsers={stateType === 'RECURRING' && !isAdmin() ? [] : allUsers} // For Recurring tasks, normal users can not assign to anybody else
        selectedProjectUsers={selectedProjectUsers}
        selectedAssigneeName={selectedAssigneeName}
        selectedAssigneeProfileImage={selectedAssigneeProfileImage}
        stateType={stateType}
        itemAssignee={itemAssignee}
        hasProject={itemProject ? true : false}
        isDisabled={isDisabled}
        updateAssignee={(assignee) => updateAssignee(assignee)}
        userInWorkspace={true}
        selectedTemplateUsers={selectedTemplateUsers}
      />
    );
  };

  const renderCollapsibleComponent = () => {
    return (
      <CollapseIcon
        tempId={tempId}
        hasSubtasks={hasSubtasks}
        collapsedSubTasks={collapsedSubTasks}
        updateTaskCollapse={(status) => updateTaskCollapse(status)}
      />
    );
  };

  /**
   * Render Dates container
   * @returns React.Dom
   */
  const renderDates = () => {
    if (!accountSettings.dueDate && !accountSettings.startAndEnd) return null;

    return (
      <div className='dates-container'>
        {renderDueDate()}
        {renderStartEndDate()}
      </div>
    );
  };

  /*
   * Render start end date
   *
   * returns React.DOM
   */
  const renderStartEndDate = () => {
    return (
      <StartAndEndDateSelection
        tempId={tempId}
        itemStartDate={itemStartDate}
        itemEndDate={itemEndDate}
        showStartAndEnd={accountSettings.startAndEnd}
        isDisabled={isDisabled}
        onChangeDate={(dates) => onChangeDate(index, itemId, dates, tempId, parentIndex, parentId)}
        storeDateRefs={(tempId, input) => storeDateRefs(tempId, input)}
      />
    );
  };

  /*
   * Render due date
   *
   * returns React.DOM
   */
  const renderDueDate = () => {
    return (
      <DueDateSelection
        tempId={tempId}
        dataType={dataType}
        itemDueDate={itemDueDate}
        readOnly={readOnly || itemChecked}
        showDueDate={accountSettings.dueDate}
        isDisabled={isDisabled}
        onChangeDueDate={(date) =>
          onChangeDueDate(index, itemId, date, tempId, parentIndex, parentId)
        }
      />
    );
  };

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = () => {
    return (
      <TaskCheckbox
        tempId={tempId}
        itemChecked={itemChecked}
        isDisabled={isDisabled}
        checkTheTask={() => checkTheTask()}
        parentId={parentId}
        parentChecked={getParentStatus()}
      />
    );
  };

  /*
   * Render drag icon
   *
   * returns React.DOM
   */
  const renderDragIcon = () => {
    if (stateType === 'RECURRING' && !parentId) return null;

    return (
      <div
        className={`drag-con ${hideDragIcon ? 'hide' : ''}`}
        key={`drag-${tempId}`}
        {...provided.dragHandleProps}
      >
        <div id={`drag-btn`} className={parentId ? 'drag-btn with-subtask' : 'drag-btn'} />
      </div>
    );
  };

  /**
   * add scroll event listener
   */
  useEffect(() => {
    if (window.innerWidth <= 800) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  /**
   * called on scroll event to set highlight task on top of list
   */
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const element = document.getElementById(`task-con-${tempId}`);
    if (element) {
      const elementOffset = element.offsetTop;
      const distance = elementOffset - scrollTop;
      if (distance > 60 && distance < 100) {
        log('scrolling', item.name, scrollTop, distance);
        setHighlightedTaskInRecoil(item, stateType);
      }
    }
  };

  /**
   * set the tapped task as highlighted task in redux
   */
  const setHighlightedTask = () => {
    setHighlightedTaskInRecoil(item, stateType);
  };

  const getParentStatus = () => {
    if (parentId && parentIndex !== undefined) {
      const mainData = getStateTypeMain(stateType);
      const taskData = mainData[dataType];
      if (taskData.data) return taskData.data[parentIndex].checked;
      else return false;
    }
    return false;
  };

  const renderTaskTags = () => {
    let tagInfo: TagInfoType[];
    const mainData = getStateTypeMain(stateType);

    if (parentId && parentIndex !== undefined)
      tagInfo =
        mainData[dataType] &&
        mainData[dataType].data &&
        mainData[dataType].data!.length > parentIndex &&
        mainData[dataType].data![parentIndex].subTasks &&
        mainData[dataType].data![parentIndex].subTasks!.length > index &&
        mainData[dataType].data![parentIndex].subTasks![index] &&
        mainData[dataType].data![parentIndex].subTasks![index].tagInfo
          ? mainData[dataType].data![parentIndex]?.subTasks![index]?.tagInfo
          : [];
    else
      tagInfo =
        mainData[dataType] &&
        mainData[dataType].data &&
        mainData[dataType].data!.length > index &&
        mainData[dataType].data![index].tagInfo
          ? mainData[dataType].data![index].tagInfo!
          : [];
    return (
      <TaskTags
        tempId={tempId}
        itemTags={itemTags}
        tagInfo={tagInfo ? tagInfo : []}
        itemTagsLength={itemTags ? itemTags.length : 0}
        removeTagFromTask={(id, name) => removeTagFromTask(id, name)}
        isDisabled={isDisabled}
        selectedCount={selectedCount}
        isMultiSelected={isMultiSelected && selectedCount !== undefined && selectedCount > 1}
        bulkUpdateTasksTag={(id, name, add) =>
          bulkUpdateTasksTag &&
          bulkUpdateTasksTag(id, name, item.tempId ? item.tempId : '', item.id, add, selectedCount)
        }
      />
    );
  };

  /*
   * Render Input box
   *
   * returns React.DOM
   */

  const renderInputBox = () => {
    const inputProps = {
      dataType,
      tempId,
      itemId,
      index,
      parentId,
      parentIndex,
      isDisabled,
      updateValue,
      itemProject,
      itemAssignee,
      itemSize,
      setTaskTags: (id, name, taskName) => setTaskTags(id, name, true, taskName),
      valueUpdated: (value, instant) => updateValueInStateAndRecoil(value, instant),
      addNewTask,
      deleteTheTask,
      storeInputRefs,
    };
    return (
      <InputBox
        {...inputProps}
        key={currentRoute}
        usersData={
          stateType === 'TEMPLATE'
            ? selectedTemplateUsers
            : stateType === 'PROJECT' || itemProject
              ? selectedProjectUsers
              : stateType === 'RECURRING' && !isAdmin()
                ? []
                : allUsers
        }
        itemValue={itemValue ? itemValue : ''}
        sizesData={allSizes}
        projectsData={filteredProjects}
        tagsData={allTags}
        updateSize={(itemId) => updateSize(itemId)}
        updateProject={(item, taskName) =>
          item && updateProject(item === 'REMOVE' ? null : item, taskName)
        }
        updateAssignee={(item, taskName) =>
          item && updateAssignee(item === 'REMOVE' ? null : item, taskName)
        }
        spellChecker={spellChecker}
        stateType={stateType}
        itemTemplate={itemTemplate}
        showRecurringTaskModal={() => setShowRecurringTaskModal(true)}
        isRecurring={item.recurringTaskMeta !== null && item.recurringTaskMeta !== undefined}
        readOnly={readOnly}
        itemTags={itemTags}
      />
    );
  };

  // log('render components/TaskItems/IndividualItems', index);

  /*
   * Render Component
   *
   * returns React.DOM
   */
  return (
    <div
      id={`task-con-${tempId}`}
      className={`task-con  ${parentId ? `is-subtask` : ''} ${
        createSubTaskAnimation ? `subtask-creation` : ''
      } ${hasSubtasks && !collapsedSubTasks ? 'has-subtasks' : ''} ${
        isDragging ? 'task-dragging' : 'task-not-dragging'
      } ${isDisabled ? 'task-readonly' : ''} ${
        fromAnotherUserOrProject() ? 'from-another-user-or-project' : ''
      } ${isHighlighted ? 'highlight' : ''} ${isSelected() ? 'selected' : ''} ${
        isMultiSelected ? 'multi-select' : ''
      }
      `}
      key={`task-con-${tempId}`}
      onClick={setHighlightedTask}
    >
      {selectedCount && selectedCount > 1 && isDragging ? (
        <span className='multi-drag-count'>{selectedCount}</span>
      ) : null}
      <div className='task-con-in task-con-left'>
        {renderDragIcon()}
        {parentId ? null : renderCollapsibleComponent()}
        {stateType === 'TEMPLATE' || stateType === 'RECURRING' ? null : renderCheckbox()}
        {accountSettings.assigneeName ? renderAssigneeSelection() : null}
        {accountSettings.projectName ? renderProjectSelection() : null}
        {renderInputBox()}
      </div>
      <div className='task-con-in task-con-right'>
        <div className='task-controls'>
          {renderTaskTags()}
          {renderRecurringTaskIcon()}
          {stateType === 'TEMPLATE' ? null : renderInfoSection()}
          {stateType === 'TEMPLATE' ? null : renderCommentSection()}
          {renderSizeSelection()}
        </div>
        {!accountSettings.projectName ? renderProjectSelection() : null}
        {stateType === 'TEMPLATE' ? null : renderDates()}
        {!accountSettings.assigneeName ? renderAssigneeSelection() : null}
      </div>
      {renderRecurringTaskModal()}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps, nextProps) {
  const fields = [
    { name: 'isDragging', type: 'prime' },
    { name: 'hasSubtasks', type: 'prime' },
    { name: 'updateValue', type: 'prime' },
    { name: 'isTemp', type: 'prime' },
    { name: 'tempId', type: 'prime' },
    { name: 'itemProject', type: 'object' },
    { name: 'itemSize', type: 'prime' },
    { name: 'itemAssignee', type: 'prime' },
    { name: 'itemStartDate', type: 'prime' },
    { name: 'itemEndDate', type: 'prime' },
    { name: 'itemDueDate', type: 'prime' },
    { name: 'itemChecked', type: 'prime' },
    { name: 'itemNumber', type: 'prime' },
    { name: 'itemId', type: 'prime' },
    { name: 'index', type: 'prime' },
    { name: 'animation', type: 'prime' },
    { name: 'collapsedSubTasks', type: 'prime' },
    { name: 'item', type: 'object' },
    { name: 'itemComments', type: 'object' },
    { name: 'recurringTaskMeta', type: 'object' },
    { name: 'itemTemplate', type: 'prime' },
    { name: 'stateType', type: 'prime' },
    { name: 'isHighlighted', type: 'prime' },
    { name: 'selectedCount', type: 'prime' },
    { name: 'isMultiSelected', type: 'prime' },
    { name: 'hideDragIcon', type: 'prime' },
    { name: 'selectedNotificationTaskId', type: 'prime' },
  ];
  return hasUpdated(prevProps, nextProps, fields);
}

export default memo(IndividualItem, areEqual);
