import { useState } from 'preact/compat';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Validation, log } from 'utils';
import Button from 'components/UI/Button';
import { Strings } from 'resources';
import Input from 'components/UI/Input';
import { forgotPassword as forgotPasswordApi } from 'services/AuthService';

export default function ForgotPassword() {
  const history = useNavigate();
  const alert = useAlert();
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [fields, setFields] = useState([
    {
      name: Strings.email_address,
      key: 'email',
      value: '',
      type: 'email',
      maxLength: 50,
      rules: 'required|email|max:50',
      touched: false,
      placeholder: Strings.email_address_placeholder,
    },
  ]);

  const submit = (event) => {
    event.preventDefault();

    if (hasErrors || isLoading) return;

    callForgotPasswordApi(getPayload());
  };

  const getPayload = () => {
    const payload = {};

    payload['origin'] = document.location.origin + '/';
    payload['userName'] = fields[0].value;
    return payload;
  };

  const callForgotPasswordApi = async (payload) => {
    setLoadingState(true);
    const { data, success, message } = await forgotPasswordApi(payload);
    setLoadingState(false);
    if (success) {
      alert.error(message);
      return log(message);
    } else {
      return forgotPassSuccess(data);
    }
  };

  const forgotPassSuccess = (data) => {
    log('response data', data);
    alert.success(data.responseMessage);
    setTimeout(() => {
      history('/');
    }, 1000);
  };

  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const renderField = (field, index) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder}
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        type={field.type}
        touched={field.touched}
        value={field.value}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        secure={field.secure}
      />
    );
  };

  return (
    <div className='forgot-pass-screen-container'>
      <div className='forgot-pass-form'>
        <div className='magic-task-logo' alt='Logo' />
        <div className='forgot-pass-form-con'>
          <div className='magic-task-logo-div'></div>
          <h1 className='forgot-pass-header'>{Strings.forgot_password}</h1>
          <p className='forgot-pass-subheader'>{Strings.forgot_pass_msg}</p>
          <form className='forgot-pass-input-form' onSubmit={(event) => submit(event)}>
            {fields.map((field, index) => renderField(field, index))}
            {/* <input className={`reset-btn ${hasErrors || isLoading ? "disabled" : ""}`} type="submit" value={Strings.reset} /> */}

            <div className='btn'>
              <Button
                idName='forgot-pass-screen-submit-btn'
                disabled={hasErrors}
                loading={isLoading}
              >
                {' '}
                {Strings.reset}{' '}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className='forgot-pass-img-con'></div>
    </div>
  );
}
