import { Fragment, Ref, Suspense, useEffect, useRef, useState } from 'preact/compat';
import { faPlus, faChevronUp, faChevronDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
  isProjectTaskScreen,
  isUserScreen,
  isHomeScreen,
  isRecurringTasksScreen,
  isThemesOrShopScreen,
  isProjectTemplatesListScreen,
  getActiveProjects,
} from 'utils/task';
import {
  deleteFolder as deleteFolderAPI,
  setFolderOpenStatus as setFolderOpenStatusInAPI,
  updateFolderName,
  updateProjectName,
} from 'services/ProjectService';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { getUserId, isAdmin } from 'services/AuthService';
import { Strings } from 'resources';
import { log } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  navigationDataSelector,
  showCreateNewProjectModalState,
  folderForCreateNewProjectModalState,
  showWhatsNewModalState,
  hideLeftSidebarState,
  showEditProjectModalState,
  showSearchUsersModalState,
  showCreateFolderModalState,
  showCreateProjectTemplateModalState,
  projectForCreateProjectTemplateModalState,
  showInviteMembersModalState,
} from 'recoil/NavigationState';
import {
  projectBacklogTaskDataState,
  projectDataSelector,
  projectFocusTaskDataState,
  projectCompletedTaskSizeState,
  projectFocusTaskLoadingState,
  projectBacklogTaskLoadingState,
} from 'recoil/ProjectState';
import { accountDataSelector } from 'recoil/AccountState';
import { playTheSound, playModalOpenSound } from 'triggers/utils/sound';
import { runCanvasAnimation } from 'triggers/utils/canvas';
import { triggerDataSelector } from 'recoil/TriggerState';
import {
  currentUserIdState,
  userBacklogTaskDataState,
  userDataSelector,
  userFocusTaskDataState,
  userCompletedTaskSizeState,
  userFocusTaskLoadingState,
  userBacklogTaskLoadingState,
} from 'recoil/UserState';
import {
  getFoldersForUser,
  setFolderName,
  setFolderOpenStatus as setFolderOpenStatusInRecoil,
  setProjectName,
} from 'recoil/ProjectState/update';
import { useAlert } from 'react-alert';
const ConfirmationPopup = lazyWithRetry(() => import('modals/ConfirmationPopup'));

export default function LeftBar() {
  const ref: Ref<HTMLInputElement> | null = useRef(null);

  const accountData = useRecoilValue(accountDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const triggerData = useRecoilValue(triggerDataSelector);
  const projectData = useRecoilValue(projectDataSelector);
  const userData = useRecoilValue(userDataSelector);
  const history = useNavigate();
  const alert = useAlert();
  const [renameFolderId, setRenameFolderId] = useState<string | null>(null);
  const [renameFolderValue, setRenameFolderValue] = useState<string | undefined>('');

  const [renameProjectId, setRenameProjectId] = useState<string | null>(null);
  const [renameProjectValue, setRenameProjectValue] = useState<string | undefined>('');

  const [deleteFolderId, setDeleteFolderId] = useState<string | null>(null);
  const [deleteFolderValue, setDeleteFolderValue] = useState<string | undefined>('');

  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [isLoading, setLoadingState] = useState(false);

  const setCreateNewProjectModalStatus = useSetRecoilState(showCreateNewProjectModalState);
  const setFolderForCreateNewProjectModal = useSetRecoilState(folderForCreateNewProjectModalState);
  const setWhatsNewModalStatus = useSetRecoilState(showWhatsNewModalState);
  const setHideLeftSidebar = useSetRecoilState(hideLeftSidebarState);
  const setEditProjectModalStatus = useSetRecoilState(showEditProjectModalState);
  const setSearchUsersModalStatus = useSetRecoilState(showSearchUsersModalState);
  const setCurrentUserId = useSetRecoilState(currentUserIdState);
  const setCreateFolderModalStatus = useSetRecoilState(showCreateFolderModalState);
  const setUserFocusTaskData = useSetRecoilState(userFocusTaskDataState);
  const setUserBacklogTaskData = useSetRecoilState(userBacklogTaskDataState);
  const setUserFocusLoading = useSetRecoilState(userFocusTaskLoadingState);
  const setUserBacklogLoading = useSetRecoilState(userBacklogTaskLoadingState);
  const setUserCompletedSizeData = useSetRecoilState(userCompletedTaskSizeState);
  const setProjectCompletedSizeData = useSetRecoilState(projectCompletedTaskSizeState);

  const setProjectFocusTaskData = useSetRecoilState(projectFocusTaskDataState);
  const setProjectBacklogTaskData = useSetRecoilState(projectBacklogTaskDataState);
  const setProjectFocusLoading = useSetRecoilState(projectFocusTaskLoadingState);
  const setProjectBacklogLoading = useSetRecoilState(projectBacklogTaskLoadingState);

  const setCreateProjectTemplateModalStatus = useSetRecoilState(
    showCreateProjectTemplateModalState,
  );
  const setProjectForTemplatedModal = useSetRecoilState(projectForCreateProjectTemplateModalState);
  const setInviteMembersModalStatus = useSetRecoilState(showInviteMembersModalState);

  const mounted = useRef<boolean | undefined>();
  const userId = getUserId();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleOutsideTap();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      const currentTag = getCurrentTag();
      if (currentTag.length > 0 && currentTag[0].archived === true) {
        history(`/`);
      }
    }
  });

  const getCurrentTag = () => {
    if (
      projectData.currentProjectId !== null &&
      projectData.allProjects !== null &&
      projectData.allProjects.length > 0
    ) {
      return projectData.allProjects.filter(
        (tag, _index) => tag.id === projectData.currentProjectId,
      );
    } else {
      return [];
    }
  };

  const showWhatsNewModal = () => {
    setWhatsNewModalStatus(true);
  };

  const renderMyTasks = () => {
    return (
      <div
        id='left-bar-my-tasks-heading'
        className={`section-in my-tasks ${
          isHomeScreen(navigationData.currentRoute) ? 'selected menu-navigation-animation' : ''
        } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
        onClick={() => openMyTasks()}
      >
        <span className='arrow-right-sides'></span>
        <span className='arrow-right-side-second'></span>
        <span>{Strings.my_tasks}</span>
      </div>
    );
  };

  const renderRecurringTasks = () => {
    return (
      <div
        id='left-bar-recurring-tasks-heading'
        className={`section-in my-tasks ${
          isRecurringTasksScreen(navigationData.currentRoute)
            ? 'selected menu-navigation-animation'
            : ''
        } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
        onClick={() => openRecurringTasks()}
      >
        <span className='arrow-right-sides'></span>
        <span className='arrow-right-side-second'></span>
        <span>{Strings.recurring_tasks}</span>
      </div>
    );
  };

  const renderTasks = () => {
    return (
      <div id='left-bar-tasks-con' className='section-con' key='render-tasks'>
        <h6 id='left-bar-tasks-heading' className='main-heading tasks'>
          <span>{Strings.tasks}</span>
        </h6>
        <div id='tasks-list' className='section-in-con'>
          {renderMyTasks()}
          {renderRecurringTasks()}
        </div>
      </div>
    );
  };

  const renderChangeLog = () => {
    return (
      <div id='change-log-con' className={`main-heading`} onClick={() => showWhatsNewModal()}>
        <h6>{Strings.change_log}</h6>
      </div>
    );
  };

  const isSelectedProject = (projectId: string) => {
    if (projectData.currentProjectId === projectId) return true;

    return false;
  };

  const isSelectedMember = (memberId: string) => {
    if (userData.currentUserId === memberId) return true;

    return false;
  };

  const toggleLeftSidebar = (status?: boolean) => {
    setHideLeftSidebar(status ? status : !navigationData.hideLeftSidebar);
  };

  const hideLeftSidebar = () => {
    toggleLeftSidebar(true);
  };

  const openProjectTemplates = () => {
    hideLeftSidebar();
    playSwitchScreenSound();
    history(`/admin/project-templates`);
  };

  const renderProjectTemplates = () => {
    if (!isAdmin()) return;
    return (
      <div
        id='left-bar-project-templates-heading'
        className={`section-in project-templates ${
          isProjectTemplatesListScreen() ? 'selected menu-navigation-animation' : ''
        } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
        onClick={() => openProjectTemplates()}
      >
        <span className='arrow-right-sides'></span>
        <span className='arrow-right-side-second'></span>
        <span>{Strings.project_templates}</span>
      </div>
    );
  };

  const renderMyProjects = () => {
    const { folders } = projectData;

    return (
      <div id='left-bar-projects-con' className='section-con' key='render-my-projects'>
        <h6 id='left-bar-my-projects-heading' className='main-heading projects'>
          <span>{Strings.projects}</span>
          {renderAddFolder()}
          {renderAddProject()}
        </h6>

        <div id='projects-list' className='section-in-con'>
          {renderProjectTemplates()}
          {folders.map((item, index) =>
            item.isFolder
              ? renderFolder(item, index)
              : item.archived !== true
                ? renderProject(item as ProjectObjectType, index, () => openProjectScreen(item.id))
                : null,
          )}
        </div>
      </div>
    );
  };

  const renderFolder = (folder: FolderType, index: number) => {
    return (
      <div
        id={`folder-con-${index}`}
        key={index}
        className={`folder-con ${folder.isOpen ? 'open' : ''}`}
      >
        <h6 id='folder-name-con' key='folder-in' className='folder-in'>
          {renderFolderName(folder)}
          <span>
            {renderFolderMenu(folder)}
            <span
              className={`arrow ${folder.isOpen ? 'up' : 'down'}`}
              onClick={() => setFolderOpenStatus(folder.id, !folder.isOpen)}
            >
              <FontAwesomeIcon
                id={`folder-chevron-${index}`}
                className='icon'
                icon={folder.isOpen ? faChevronUp : faChevronDown}
              />
            </span>
          </span>
        </h6>
        {folder.projects &&
          getActiveProjects(folder.projects).map((project, index) =>
            renderProject(project, index, () => openProjectScreen(project.id), true),
          )}
      </div>
    );
  };

  const renderProject = (
    project: ProjectObjectType,
    index: number,
    openProject: (e: MouseEvent) => void,
    inside?: boolean,
  ) => {
    return (
      <div id={`project-name-${index}`} key={project.id} className={`project-con `}>
        <h6
          id='project-name-con'
          key='project-in'
          className={`project-in section-in  ${inside ? 'inside' : ''} ${
            isSelectedProject(project.id) ? 'selected menu-navigation-animation' : ''
          }`}
          onClick={openProject}
        >
          <span className='arrow-right-sides'></span>
          <span className='arrow-right-side-second'></span>
          {renderProjectName(project)}
          {renderProjectMenu(project)}
        </h6>
      </div>
    );
  };

  const renderFolderName = (folder: FolderType) => {
    if (folder.id === renameFolderId) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setNewFolderName(folder.id, renameFolderValue);
          }}
        >
          <span className='rename-folder-input-con'>
            <input
              className='rename-input'
              value={renameFolderValue}
              onChange={(e) =>
                setRenameFolderValue(
                  e.target ? (e.target as HTMLInputElement).value : renameFolderValue,
                )
              }
              autoComplete='off'
              data-lpignore='true'
              data-form-type='other'
              placeholder='Folder name'
              ref={ref}
            />
          </span>
        </form>
      );
    }

    return (
      <span className='folder-name' onClick={() => setFolderOpenStatus(folder.id, !folder.isOpen)}>
        {folder.name}
      </span>
    );
  };

  const renderProjectName = (project: ProjectObjectType) => {
    if (project.id === renameProjectId) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setNewProjectName(project.id, renameProjectValue);
          }}
        >
          <span className='rename-folder-input-con'>
            <input
              className='rename-input'
              value={renameProjectValue}
              onChange={(e) =>
                setRenameProjectValue(
                  e.target ? (e.target as HTMLInputElement).value : renameProjectValue,
                )
              }
              autoComplete='off'
              data-lpignore='true'
              data-form-type='other'
              placeholder='Project name'
              ref={ref}
            />
          </span>
        </form>
      );
    }

    return <span className='project-name'>{project.display}</span>;
  };

  const handleOutsideTap = () => {
    resetRenameFolder();
    resetRenameProject();
  };

  const setFolderOpenStatus = (folderId: string, status: boolean) => {
    setFolderOpenStatusInRecoil(folderId, status);
    setFolderOpenStatusInAPI(folderId, status);
  };

  const openProjectScreen = async (projectId: string) => {
    if (
      projectData.currentProjectId === projectId &&
      isProjectTaskScreen(navigationData.currentRoute)
    )
      return;

    log('one98', 1);

    log('one98', 3);

    setEditProjectModalStatus(false);
    setSearchUsersModalStatus(false);
    setUserCompletedSizeData(null);
    setProjectCompletedSizeData(null);
    hideLeftSidebar();
    playSwitchScreenSoundAndAnimation();
    setProjectFocusTaskData(null);
    setProjectBacklogTaskData(null);
    setProjectFocusLoading(true);
    setProjectBacklogLoading(true);
    history(`/project/${projectId}`);
  };

  const openMemberScreen = (memberId: string) => {
    if (!isAdmin()) return;

    if (userData.currentUserId === memberId && isUserScreen(navigationData.currentRoute)) return;

    setCurrentUserId(memberId);
    hideLeftSidebar();
    playSwitchScreenSoundAndAnimation();
    setUserFocusTaskData([]);
    setUserBacklogTaskData([]);
    setUserCompletedSizeData(null);
    setProjectCompletedSizeData(null);
    setUserFocusLoading(true);
    setUserBacklogLoading(true);
    history(`/user/${memberId}`);
  };

  const openMyTasks = () => {
    hideLeftSidebar();
    playSwitchScreenSoundAndAnimation();
    history(`/`);
    setUserCompletedSizeData(null);
    setProjectCompletedSizeData(null);
  };

  const openRecurringTasks = () => {
    hideLeftSidebar();
    playSwitchScreenSoundAndAnimation();
    history(`/recurring-tasks`);
    setUserCompletedSizeData(null);
    setProjectCompletedSizeData(null);
  };

  const playSwitchScreenSoundAndAnimation = () => {
    playSwitchScreenSound();
    playSwitchScreenAnimation();
  };

  const playSwitchScreenSound = () => {
    if (triggerData.soundTriggers.switchScreen) {
      playTheSound(triggerData.soundTriggers.switchScreen);
    }
  };

  const playSwitchScreenAnimation = () => {
    if (triggerData.canvasTriggers.createTask) {
      runCanvasAnimation(triggerData.canvasTriggers.createTask);
    }
  };

  const showAddProjectModal = (folder: FolderType | null) => {
    log('Add new project');
    hideLeftSidebar();
    setCreateNewProjectModalStatus(true);
    setFolderForCreateNewProjectModal(folder);
    !navigationData.showCreateNewProjectModal && playModalOpenSound();
  };

  const renderAddFolder = () => {
    if (!isAdmin()) return null;

    return (
      <Menu
        className='folder-menu'
        direction='bottom'
        align='end'
        menuButton={
          <MenuButton id='folder-menu-btn' className='folder-menu-btn project-menu-btn'>
            <FontAwesomeIcon id='add-folder-icon' className='icon' icon={faEllipsisV} size='sm' />
          </MenuButton>
        }
      >
        <MenuItem id='create-folder-menu-item' onClick={() => showAddFolder()}>
          {Strings.create_folder}
        </MenuItem>
      </Menu>
    );
  };

  const renderFolderMenu = (folder: FolderType) => {
    return (
      <Menu
        className='folder-menu'
        menuButton={
          <MenuButton id='folder-menu-btn' className='folder-menu-btn'>
            <FontAwesomeIcon
              id='folder-menu-icon'
              className='folder-menu-icon icon'
              icon={faEllipsisV}
              size='sm'
            />
          </MenuButton>
        }
        direction='bottom'
        align='end'
      >
        <MenuItem id='new-project-menu-item' onClick={() => createNewProject(folder)}>
          {Strings.new_project}
        </MenuItem>
        <MenuItem id='rename-folder-menu-item' onClick={() => renameFolder(folder)}>
          {Strings.rename_folder}
        </MenuItem>
        <MenuItem id='delete-folder-menu-item' onClick={(_e) => deleteFolderSelected(folder)}>
          {Strings.delete_folder}
        </MenuItem>
      </Menu>
    );
  };

  const createNewProject = (folder: FolderType) => {
    showAddProjectModal(folder);
  };

  const renameFolder = (folder: FolderType) => {
    setRenameFolderId(folder.id);
    setRenameFolderValue(folder.name);

    document.addEventListener('keydown', keyDown, false);
  };

  const resetRenameFolder = () => {
    setRenameFolderId(null);
    setRenameFolderValue('');

    document.removeEventListener('keydown', keyDown, false);
  };

  const renameProject = (project: ProjectObjectType) => {
    setRenameProjectId(project.id);
    setRenameProjectValue(project.name);

    document.addEventListener('keydown', keyDown, false);
  };

  const resetRenameProject = () => {
    setRenameProjectId(null);
    setRenameProjectValue('');

    document.removeEventListener('keydown', keyDown, false);
  };

  const keyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetRenameFolder();
      resetRenameProject();
    }
  };

  const setNewFolderName = async (id: string, name: string | undefined) => {
    if (name === undefined) return alert.error('Folder name cannot be blank');
    resetRenameFolder();

    // Update name in redux
    setFolderName(id, name);

    // Call api to update in backend
    const { success } = await updateFolderName(id, name);

    if (!success) getFoldersForUser(getUserId());
  };

  const setNewProjectName = async (id: string, name: string | undefined) => {
    if (name === undefined) return alert.error('Project name cannot be blank');

    resetRenameProject();

    // Update name in redux
    setProjectName(id, name);

    // // Call api to update in backend
    await updateProjectName(id, name);

    getFoldersForUser(getUserId());
  };

  const deleteFolderSelected = (folder: FolderType) => {
    setDeleteFolderId(folder.id);
    setDeleteFolderValue(folder.name);

    setConfirmationPopup(true);
  };

  const deleteFolder = async () => {
    if (!deleteFolderId) return;
    setLoadingState(true);

    // call api
    await deleteFolderAPI(deleteFolderId);

    setLoadingState(false);

    // Refetch folders
    getFoldersForUser(getUserId());

    setConfirmationPopup(false);
  };

  const showAddFolder = () => {
    hideLeftSidebar();
    setCreateFolderModalStatus(true);
    !navigationData.showCreateFolderModal && playModalOpenSound();
  };

  const createProjectTemplate = (project: ProjectObjectType) => {
    hideLeftSidebar();
    setCreateProjectTemplateModalStatus(true);
    setProjectForTemplatedModal(project);
    !navigationData.showCreateProjectTemplateModal && playModalOpenSound();
  };

  const renderProjectMenu = (project: ProjectObjectType) => {
    if (!isAdmin()) return;
    return (
      <Menu
        className='project-menu'
        menuButton={
          <MenuButton id='project-menu-btn' className='template-menu-btn'>
            <FontAwesomeIcon
              id='project-menu-icon'
              className='template-menu-icon icon'
              icon={faEllipsisV}
              size='sm'
            />
          </MenuButton>
        }
        direction='bottom'
        align='end'
      >
        <MenuItem id='project-template-menu-item' onClick={() => createProjectTemplate(project)}>
          {Strings.create_template}
        </MenuItem>
        <MenuItem id='project-template-menu-item' onClick={() => renameProject(project)}>
          {Strings.rename_project}
        </MenuItem>
      </Menu>
    );
  };

  const renderTeamMember = (member: UserFieldType, index: number) => {
    if (member.id === userId) return null;

    return (
      <div
        id={`team-member-name-${index}`}
        key={index}
        onClick={() => openMemberScreen(member.id)}
        className={`section-in ${
          isSelectedMember(member.id) ? 'selected menu-navigation-animation' : ''
        }`}
      >
        <span className='arrow-right-sides'></span>
        <span className='arrow-right-side-second'></span>
        <span>{member.display}</span>
      </div>
    );
  };

  const showInviteMembersModal = () => {
    hideLeftSidebar();
    setInviteMembersModalStatus(true);
    !navigationData.showInviteMembersModal && playModalOpenSound();
  };

  const renderAddTeam = () => {
    if (!isAdmin()) return null;

    return (
      <div id='add-item-con' className='section-in add-item' key='add-team-member'>
        <span onClick={() => showInviteMembersModal()}>
          <FontAwesomeIcon id='plus-icon-add-item' className='plus-icon' icon={faPlus} size='sm' />
        </span>
      </div>
    );
  };

  const renderAddProject = () => {
    if (!isAdmin()) return null;

    return (
      <div id='add-project-con' className='section-in add-item' key='add-project'>
        <span onClick={() => showAddProjectModal(null)}>
          <FontAwesomeIcon
            id='plus-icon-add-project'
            className='plus-icon'
            icon={faPlus}
            size='sm'
          />
        </span>
      </div>
    );
  };

  const renderMyTeam = () => {
    if (!isAdmin()) return;
    return (
      <div
        id='left-bar-team-section'
        className='section-con left-menu-team-section-con'
        key='render-my-team'
      >
        <h6 id='left-bar-my-team-heading-con' className='main-heading team'>
          <span>{Strings.team}</span>
          {renderAddTeam()}
        </h6>
        <div id='team-list-con' className='section-in-con'>
          {accountData.allUsers &&
            accountData.allUsers.map((project, index) => renderTeamMember(project, index))}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div
        className={`leftbar-wrapper ${navigationData.hideLeftSidebar ? 'hide' : ''} ${
          navigationData.hideLeftSidebarDesktop ? 'hide-desktop' : ''
        } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
      >
        <div
          id='left-bar-container-user'
          className={`left-bar-container ${navigationData.hideLeftSidebar ? 'hide' : ''} ${
            navigationData.hideLeftSidebarDesktop ? 'hide-desktop' : ''
          } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'} ${
            isThemesOrShopScreen(navigationData.currentRoute) ? 'shop-visible' : ''
          }`}
        >
          <div className='left-bar-child-container'>
            {renderTasks()}
            {renderMyProjects()}
            {renderMyTeam()}
            {renderChangeLog()}
          </div>
        </div>

        <div className='custom-shape-area'>
          <div className='top-border1'></div>
          <div className='top-border2'></div>
          <div className='top-border3'></div>
          <div className='top-border4'></div>
          <div className='top-border5'></div>
          <div className='top-triangle'></div>
          <div className='bottom-shape'></div>
        </div>
      </div>
      <Suspense fallback={null}>
        {showConfirmationPopup ? (
          <ConfirmationPopup
            title={`${Strings.delete_folder_confirm_text} "${deleteFolderValue}"?`}
            noBtnTitle={Strings.cancel}
            yesBtnTitle={Strings.confirm}
            closeModal={() => setConfirmationPopup(false)}
            yesClicked={deleteFolder}
            isLoading={isLoading}
          />
        ) : null}
      </Suspense>
    </Fragment>
  );
}
