import { useEffect, Fragment } from 'preact/compat';
import { Strings } from 'resources';
import {
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activateDeactivateTheme as activateDeactivateThemeAPI } from 'services/ThemeService';
import Switch from 'react-switch';
import SmallButton from 'components/UI/SmallButton';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';
import { superAdminDataSelector, themeSortByState } from 'recoil/SuperAdminState';
import { accountDataSelector } from 'recoil/AccountState';
import { getAllThemes, updateThemeStatus } from 'recoil/AccountState/update';

export default function ThemesList() {
  const navigationData = useRecoilValue(navigationDataSelector);
  const superAdminData = useRecoilValue(superAdminDataSelector);
  const accountData = useRecoilValue(accountDataSelector);
  const navigate = useNavigate();
  const setThemeSortBy = useSetRecoilState(themeSortByState);

  useEffect(() => {
    getAllThemes();
  }, []);

  const getSortIcon = (name: string) => {
    const { themeSortBy, themeSortOrder } = superAdminData;
    if (themeSortBy === name) {
      if (themeSortOrder === 'asc') return faSortAlphaDown;
      else return faSortAlphaUp;
    }
    return faSortAmountDown;
  };

  const isSelected = (name: string) => {
    const { themeSortBy } = superAdminData;
    if (themeSortBy === name) return 'selected';
    return '';
  };

  const renderTheme = (theme: ThemeObjectInterface, index: number) => {
    return (
      <tr className='themes-in-container' key={index}>
        <td className='themes-item themes-name'>
          <span>{theme.name}</span>
        </td>
        <td className='themes-item center-align'>
          <span className={`themes-difficulty ${theme.difficulty ? '' : 'hide'}`}>
            {theme.difficulty}
          </span>
        </td>
        <td className='themes-item'>
          <span className={`themes-rarity ${theme.rarity ? '' : 'hide'}`}>{theme.rarity}</span>
        </td>
        <td className='themes-item center-align'>
          <span className='themes-levels'>
            <span>
              {Strings.level}
              {Object.keys(theme.pointLevels).length}
            </span>
          </span>
        </td>
        <td className='themes-item center-align'>
          <span>{theme.price}</span>
        </td>
        <td className='themes-item center-align'>
          <span>{theme.totalQuantity}</span>
        </td>
        <td className='themes-item center-align'>
          <span>{theme.remainingQuantity}</span>
        </td>
        <td className='themes-item'>
          <span className={`themes-dates ${theme.releaseDate !== null ? '' : 'hide'}`}>
            {theme.releaseDate}
          </span>
        </td>
        <td className='themes-item'>
          <span className={`themes-dates ${theme.creationDate !== null ? '' : 'hide'}`}>
            {theme.creationDate}
          </span>
        </td>
        <td className='themes-item'>{renderThemeActive(theme, index)}</td>
      </tr>
    );
  };

  const themeActivationUpdated = async (index: number, themeId: string, active: boolean) => {
    updateThemeActiveStatus(index, active);

    const { success } = await activateDeactivateThemeAPI(themeId, active);

    if (!success) {
      updateThemeActiveStatus(index, !active);
    }
  };

  const updateThemeActiveStatus = (index: number, active: boolean) => {
    updateThemeStatus(index, active);
  };

  const renderThemeActive = (theme: ThemeObjectInterface, index: number) => {
    return (
      <Fragment>
        <span className='themes-active'>
          {/* @ts-ignore */}
          <Switch
            onChange={(nextChecked) => themeActivationUpdated(index, theme.id, nextChecked)}
            checked={theme.active}
            height={24}
            handleDiameter={14}
            onColor='#6666ff'
            offColor='#e0e0ff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </span>
      </Fragment>
    );
  };

  const compare = (valueA: any, valueB: any, sortOrder: SortOrderType) => {
    if (sortOrder === 'asc') {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  };

  const getSortedData = () => {
    const { themeSortBy, themeSortOrder } = superAdminData;
    const allThemes = [...accountData.allThemes];
    return allThemes.sort(function (a, b) {
      const valueA = a[themeSortBy];
      const valueB = b[themeSortBy];

      if (themeSortBy === 'name') {
        return compare(valueA, valueB, themeSortOrder);
      } else {
        if (themeSortOrder === 'asc') return +(valueA > valueB) || -(valueA < valueB);
        else return +(valueB > valueA) || -(valueB < valueA);
      }
    });
  };

  const sortBy = (name: SortByType) => {
    setThemeSortBy(name);
  };

  const renderThemesTable = () => {
    return (
      <table>
        <thead>
          <tr className='themes-container-head'>
            <th className='themes-item name-item'>
              <span className='themes-name-con'>
                <span className={`sort-icon ${isSelected('name')}`} onClick={() => sortBy('name')}>
                  <FontAwesomeIcon icon={getSortIcon('name')} />
                </span>
                {Strings.name}
              </span>
            </th>
            <th className='themes-item center-align'>
              <span className='themes-difficulty'>
                <span
                  className={`sort-icon ${isSelected('difficulty')}`}
                  onClick={() => sortBy('difficulty')}
                >
                  <FontAwesomeIcon icon={getSortIcon('difficulty')} />
                </span>
                {Strings.difficulty}
              </span>
            </th>
            <th className='themes-item'>
              <span className='themes-rarity'>
                <span
                  className={`sort-icon ${isSelected('rarity')}`}
                  onClick={() => sortBy('rarity')}
                >
                  <FontAwesomeIcon icon={getSortIcon('rarity')} />
                </span>
                {Strings.rarity}
              </span>
            </th>
            <th className='themes-item center-align'>
              <span className='themes-levels'>
                <span
                  className={`sort-icon ${isSelected('levels')}`}
                  onClick={() => sortBy('levels')}
                >
                  <FontAwesomeIcon icon={getSortIcon('levels')} />
                </span>
                {Strings.levels}
              </span>
            </th>
            <th className='themes-item center-align'>
              <span className='themes-cost'>
                <span
                  className={`sort-icon ${isSelected('price')}`}
                  onClick={() => sortBy('price')}
                >
                  <FontAwesomeIcon icon={getSortIcon('price')} />
                </span>
                {Strings.price}
              </span>
            </th>
            <th className='themes-item center-align'>
              <span className='themes-quantity'>
                <span
                  className={`sort-icon ${isSelected('totalQuantity')}`}
                  onClick={() => sortBy('totalQuantity')}
                >
                  <FontAwesomeIcon icon={getSortIcon('totalQuantity')} />
                </span>
                {Strings.total_quantity}
              </span>
            </th>
            <th className='themes-item center-align'>
              <span className='themes-quantity'>
                <span
                  className={`sort-icon ${isSelected('remainingQuantity')}`}
                  onClick={() => sortBy('remainingQuantity')}
                >
                  <FontAwesomeIcon icon={getSortIcon('remainingQuantity')} />
                </span>
                {Strings.remaining_quantity}
              </span>
            </th>
            <th className='themes-item'>
              <span className='themes-dates'>
                <span
                  className={`sort-icon ${isSelected('releaseDate')}`}
                  onClick={() => sortBy('releaseDate')}
                >
                  <FontAwesomeIcon icon={getSortIcon('releaseDate')} />
                </span>
                {Strings.release_date}
              </span>
            </th>
            <th className='themes-item'>
              <span className='themes-dates'>
                <span
                  className={`sort-icon ${isSelected('creationDate')}`}
                  onClick={() => sortBy('creationDate')}
                >
                  <FontAwesomeIcon icon={getSortIcon('creationDate')} />
                </span>
                {Strings.creation_date}
              </span>
            </th>
            <th className='themes-item'>
              <span className='themes-active'>
                <span
                  className={`sort-icon ${isSelected('active')}`}
                  onClick={() => sortBy('active')}
                >
                  <FontAwesomeIcon icon={getSortIcon('active')} />
                </span>
                {Strings.active}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>{getSortedData().map((theme, index) => renderTheme(theme, index))}</tbody>
      </table>
    );
  };

  const createNewTheme = () => {
    navigate('/super-admin/create-theme');
  };

  return (
    <div
      className={`themes-list-screen  ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div className='title-container'>
        <div className='page-title'>{Strings.super_admin}</div>
      </div>
      <div className='subtitle-container'>
        <div className='list-title'>{Strings.themes}</div>
        <div className='create-theme-container'>
          <SmallButton btnType='light' clicked={() => createNewTheme()}>
            {Strings.create_theme_plus}
          </SmallButton>
        </div>
      </div>
      <div className='themes-list-con'>{renderThemesTable()}</div>
    </div>
  );
}
