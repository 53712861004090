import { getRecoil, setRecoil } from 'recoil-nexus';
import { combinedSelector } from 'recoil/CombinedState';
import {
  addTaskData,
  hasSubTaskUpdated,
  hasTaskUpdated,
  removeTask,
  setAutoFocusItem,
  setTaskComment,
  updateDueDate,
  updateStartEndDates,
  updateTaskAbortControllerToken,
  updateTaskAssignee,
  updateTaskProject,
} from 'recoil/TaskState/update';
import { insertOnIndex, randomNumber } from 'utils/helpers';
import { log } from 'utils';
import {
  SEQUENCE_LARGE,
  createNewTaskComment,
  getNewSequence,
  parseTask,
  parseToDateObjectFromTimestamp,
  removeFromOneAddToAnother,
  reorderTasks,
} from 'utils/task';
import {
  allTutorialProjectsState,
  allTutorialUsersState,
  tutorialCompletedTasksState,
  tutorialCurrentProjectIdState,
  tutorialDataSelector,
  tutorialFlagsState,
  tutorialFoldersState,
} from '.';
import { accountDataSelector, allTutorialsState } from 'recoil/AccountState';
import { getUserEmail, getUserId, getUserName } from 'services/AuthService';
import {
  addCompletedTask as addCompletedTaskInCompleted,
  addCompletedTasks,
  hideCompletedTasks,
  removeCompletedTask,
  setCompletedTaskStatus,
} from 'recoil/CompletedTasksState/update';
import { taskDetailModalState } from 'recoil/NavigationState';
import Avatar from 'assets/images/Avatar.png';

export function addAllTutorialUsers(data: UserFieldType[]) {
  setRecoil(allTutorialUsersState, data);
}

export function saveTaskComment(
  taskId: string,
  taskComment: string,
  projectId: string | undefined,
  userId: string,
  dataType: TaskType,
  parentId: string | null | undefined,
  formData: FormData | null,
  taggedUsers: CommentTaggedUsersType[],
) {
  // log('addTaskComment', taskId, taskComment, projectId, userId, stateType, dataType, index);
  const state = getRecoil(combinedSelector);
  log('------------');
  log('---- VAL ---');
  log('------------');
  log(state.userData);
  log(state.accountData.account);

  const assignee = state.accountData.account;
  const allTasksData =
    state.tutorialData && state.tutorialData[dataType] ? state.tutorialData[dataType].data : [];

  if (allTasksData === null) return;
  let parentIndex: number | undefined = undefined;
  let index: number = -1;
  if (parentId !== null && parentId !== undefined) {
    const tempIndex = allTasksData.findIndex((item) => item.id === parentId);
    if (tempIndex !== -1) {
      parentIndex = tempIndex;
    }
    if (parentIndex) {
      if (allTasksData[parentIndex] && allTasksData[parentIndex].subTasks) {
        const subTempIndex = allTasksData[parentIndex].subTasks!.findIndex(
          (item) => item.id === taskId,
        );
        if (subTempIndex !== -1) {
          index = subTempIndex;
        }
      }
    }
  } else {
    const tempIndex = allTasksData.findIndex((item) => item.id === taskId);
    if (tempIndex !== -1) {
      index = tempIndex;
    }
  }

  const tempId = randomNumber() + '';
  const newComment: CommentType = createNewTaskComment(
    taskComment.trim(),
    tempId,
    dataType,
    projectId,
    assignee,
    taggedUsers,
    formData,
  );
  let newComments: CommentType[] = [newComment];

  if (
    index !== undefined &&
    parentIndex !== undefined &&
    parentIndex >= 0 &&
    allTasksData[parentIndex].subTasks &&
    allTasksData[parentIndex].subTasks![index].comments
  ) {
    newComments = [...newComments, ...allTasksData[parentIndex].subTasks![index].comments!];
  } else if (
    index !== undefined &&
    allTasksData !== undefined &&
    allTasksData.length > index &&
    allTasksData[index].comments
  ) {
    newComments = [...newComments, ...allTasksData[index].comments!];
  }

  log('call setTaskComment', newComment);
  setTaskComment(newComments, 'TUTORIAL', dataType, index);
}

export function updateFlag(flag: string, value: boolean) {
  const tempData = {
    [flag]: value,
  };
  setRecoil(tutorialFlagsState, tempData);
}

export function getTutorialFolderFromProject(isArchived: boolean = false) {
  const accountData = getRecoil(accountDataSelector);
  let profileImage = Avatar;
  if (accountData.account && accountData.account.profileImage) {
    profileImage = accountData.account.profileImage;
  }
  const tempUsers = [
    {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      display: getUserName(),
    },
  ];
  const temp: ProjectObjectType = {
    id: '01',
    name: 'MagicTask Tutorial',
    display: 'MagicTask Tutorial',
    archived: isArchived,
    users: tempUsers,
    createdBy: {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      email: getUserEmail(),
    },
    status: 'on track',
    modified: new Date().getTime(),
    remainingPoints: 0,
    completedPoints: 0,
  };
  const folder: FolderType = {
    id: '02',
    name: 'Tutorial',
    isFolder: true,
    isOpen: false,
    archived: isArchived,
    createdBy: {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      email: getUserEmail(),
    },
    modifiedby: {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      email: getUserEmail(),
    },
    created: parseToDateObjectFromTimestamp(new Date().getTime()),
    modified: parseToDateObjectFromTimestamp(new Date().getTime()),
    workspace: null,
    sequence: 0,
    projects: [temp],
  };
  addAllTutorialProjects([temp]);
  addTutorialFolders([folder]);
}

const addAllTutorialProjects = (projects: ProjectObjectType[]) => {
  setRecoil(allTutorialProjectsState, projects);
};

const addTutorialFolders = (folders: FolderType[]) => {
  setRecoil(tutorialFoldersState, folders);
};

export const createNewTaskForTutorial = (
  value: string,
  tempId: string,
  sequence: number,
  taskType: TaskType,
  projectId: string | null,
  sizeId: SizeOptionsType,
  assigneeId: string | null,
  stateType: StateType | null,
) => {
  log(
    'createNewTaskForTutorial',
    value,
    tempId,
    sequence,
    taskType,
    projectId,
    sizeId,
    assigneeId,
    stateType,
  );

  return {
    id: tempId,
    tempId: tempId,
    comments: [],
    isTemp: false,
    name: value,
    tempName: value,
    sequence: sequence,
    size: sizeId ? sizeId : null,
    project: projectId ? { id: projectId } : null,
    tags: [],
    assignee: assigneeId ? { id: assigneeId } : null,
    subTasks: [],
    checked: false,
    startDate: null,
    endDate: null,
    dueDate: null,
    createdUser: '',
    taskNumber: 0,
    createdDate: null,
    points: 0,
    created: '',
    createdBy: { id: '01' },
    taskType: taskType.toUpperCase(),
    sizeController: new AbortController(),
    projectController: new AbortController(),
    assigneeController: new AbortController(),
    updateController: new AbortController(),
    sequenceController: new AbortController(),
    startEndDateController: new AbortController(),
    dueDateController: new AbortController(),
    abortController: new AbortController(),
    collapseController: new AbortController(),
  } as ResponseTaskInterface;
};

export async function addTutorialTask(
  index: number,
  parentIndex: number | undefined,
  parentTaskId: string | undefined,
  dataType: TaskType | undefined,
  assigneeId: string | null,
  stateType: StateType,
  taskSize: SizeOptionsType = 'M',
  taskName: string,
) {
  log('addTutorialTask is called', index, parentTaskId, parentIndex);

  const state = getRecoil(combinedSelector);
  if (stateType === 'INDIVIDUAL') {
    assigneeId = state.userData.currentUserId;
  } else if (state.tutorialData.currentProjectId === null) {
    assigneeId = getUserId();
  }

  if (!dataType) dataType = 'BACKLOG';
  const mainData = state.tutorialData[dataType];
  let data = mainData.data ? [...mainData.data] : null;

  if (data === null) return;
  let projectId: string | null = null;
  if (state.tutorialData.currentProjectId !== null) projectId = state.tutorialData.currentProjectId;
  const tempId = randomNumber() + '';
  const newTask = createNewTaskForTutorial(
    taskName,
    tempId,
    0,
    dataType,
    projectId,
    taskSize,
    assigneeId,
    stateType,
  );

  let newSequence;

  if (parentTaskId && parentIndex !== undefined && parentIndex !== -1) {
    const parent = { ...data[parentIndex] };
    if (parent.isTemp) return;
    if (!index) index = 0;

    // As a subtask, take project from its parent
    projectId = parent.project && parent.project.id ? parent.project.id : null;
    newTask.project = parent.project as ResponseProjectType;

    // insert task at certain location
    if (parent.subTasks === undefined) return;
    parent.subTasks = insertOnIndex(parent.subTasks, index, newTask as TaskObjectType);
    newSequence =
      parent.subTasks.length > 1 ? getNewSequence(index, parent.subTasks) : SEQUENCE_LARGE * 20;
    parent.subTasks[index].sequence = newSequence;

    data = data.map((item, ind) => {
      if (ind === parentIndex) return parent;
      return item;
    });

    await addTaskData(dataType, data, stateType);
  } else {
    // insert task at certain location
    data = insertOnIndex(data, index, newTask as TaskObjectType);

    // get new sequence
    newSequence = getNewSequence(index, data);
    data[index].sequence = newSequence;
    data[index].taskNumber = 1;

    await addTaskData(dataType, data, stateType);
  }

  const autoFocus = `${dataType}${tempId}`;
  setAutoFocusItem(autoFocus, stateType);

  if (!parentTaskId) hasTaskUpdated(tempId, dataType, newTask, stateType);
  else hasSubTaskUpdated(parentTaskId, tempId, dataType, newTask, stateType);
}

export function saveDueDate(
  dataType: TaskType,
  index: number,
  taskId: string,
  dueDate: Date,
  parentIndex?: number,
  parentId?: string,
) {
  const tutorialData = getRecoil(tutorialDataSelector);
  const { data } = tutorialData[dataType];

  if (data === null) return;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1) return;

    const parentData = data[parentIndex];
    if (parentData === null || parentData.subTasks === undefined) return;
    index = parentData.subTasks.findIndex((item) => item.id === taskId);
    if (index === -1) index = parentData.subTasks.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === taskId);
    if (index === -1) return;
  }
  updateDueDate(dataType, index, dueDate, parentIndex, 'TUTORIAL');
}

export function saveTaskStartEndDates(
  dataType: TaskType,
  index: number,
  taskId: string,
  startDate: Date,
  endDate: Date,
  parentIndex?: number | null,
  parentId?: string,
) {
  const tutorialData = getRecoil(tutorialDataSelector);
  const { data } = tutorialData[dataType];

  if (data === null) return;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1 || parentIndex === null) return;

    const parentData = data[parentIndex];
    if (parentData === null || parentData.subTasks === undefined) return;
    index = parentData.subTasks.findIndex((item) => item.id === taskId);
    if (index === -1) index = parentData.subTasks.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === taskId);
    if (index === -1) return;
  }

  updateStartEndDates(
    dataType,
    index,
    startDate,
    endDate,
    parentIndex !== null && parentIndex !== undefined && parentIndex > -1 ? parentIndex : undefined,
    'TUTORIAL',
  );
}

export function saveTaskProject(
  index: number,
  taskId: string,
  projectId: string | null,
  parentIndex: number | undefined,
  parentId: string | undefined,
  dataType: TaskType,
) {
  const tutorialData = getRecoil(tutorialDataSelector);
  const { data } = tutorialData[dataType];

  if (data === null) return;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1) return;

    const parentData = data[parentIndex];
    if (parentData === null || parentData.subTasks === undefined) return;
    index = parentData.subTasks.findIndex((item) => item.id === taskId);
    if (index === -1) index = parentData.subTasks.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === taskId);
    if (index === -1) return;
  }

  updateTaskProject(dataType, index, projectId, parentIndex, 'TUTORIAL');
}

export function saveTaskAssignee(
  assigneeId: string | null,
  dataType: TaskType,
  index: number,
  taskId: string,
  parentIndex?: number,
  parentId?: string,
) {
  const tutorialData = getRecoil(tutorialDataSelector);

  const { data } = tutorialData[dataType];

  if (data === null) return;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1)
      parentIndex = data && data.findIndex((item) => item.tempId === parentId);
    if (parentIndex === -1) return;

    const parentData = data[parentIndex];
    if (parentData === null || parentData.subTasks === undefined) return;
    index = parentData.subTasks.findIndex((item) => item.id === taskId);
    if (index === -1) index = parentData.subTasks.findIndex((item) => item.tempId === taskId);
    if (index === -1) return;
  } else {
    index = data && data.findIndex((item) => item.id === taskId);
    if (index === -1) return;
  }

  let userIndex = tutorialData.allUsers.findIndex((item) => item.id === assigneeId);
  let user: UserFieldType | null = null;
  if (tutorialData.currentProjectId) {
    const projectIndex = tutorialData.allProjects.findIndex(
      (item) => item.id === tutorialData.currentProjectId,
    );
    if (projectIndex === -1 || !tutorialData.allProjects[projectIndex].users) return;
    userIndex = tutorialData.allProjects[projectIndex].users!.findIndex(
      (item) => item.id === assigneeId,
    );
    if (userIndex === -1) return;
    user = tutorialData.allProjects[projectIndex].users![userIndex];
  }
  if (userIndex === -1) return;
  if (user === null) user = tutorialData.allUsers[userIndex];
  updateTaskAssignee(dataType, index, user.id, parentIndex, 'TUTORIAL');
}

export async function unCheckTask(
  index: number,
  id: string,
  parentIndex?: number,
  parentId?: string,
  stateType?: StateType,
) {
  setCompletedTaskStatus(false, index, parentIndex, stateType);

  setTimeout(() => {
    const completedTasks = getRecoil(tutorialCompletedTasksState);
    const { data } = completedTasks;

    if (parentId) {
      parentIndex = data && data.findIndex((item) => item.id === parentId);
      if (parentIndex === -1) return;

      index = data[parentIndex].subTasks
        ? data[parentIndex].subTasks!.findIndex((item) => item.id === id)
        : -1;
      if (index === -1) return;

      hideCompletedTasks(index, parentIndex, stateType);
    } else {
      index = data && data.findIndex((item) => item.id === id);
      if (index === -1) return;

      removeCompletedTask(index, stateType);
    }
  }, 500);
}

export function checkTask(
  index: number,
  id: string,
  dataType: TaskType,
  parentIndex: number | undefined,
  parentId: string | undefined,
  stateType: StateType,
  checked: boolean,
) {
  log('checkTask', index, id, dataType, parentIndex, parentId, stateType, checked);

  checked = typeof checked !== 'undefined' ? checked : true;

  const tutorialData = getRecoil(tutorialDataSelector);
  const { data } = tutorialData[dataType];
  if (data === null) return;
  let task;
  if (parentId) {
    parentIndex = data && data.findIndex((item) => item.id === parentId);
    if (parentIndex === -1) return;

    const parentData = data[parentIndex];
    if (parentData === null || parentData.subTasks === undefined) return;
    index = parentData.subTasks.findIndex((item) => item.id === id);
    if (index === -1) return;

    task = parentData.subTasks[index];
  } else {
    index = data && data.findIndex((item) => item.id === id);
    if (index === -1) return;
    task = data[index];
  }

  if (!parentId) removeTask(id, dataType, parentId, stateType);

  task = parseTask(task, stateType, false, true);

  // 1. Add task to completed list expect tag
  addCompletedTaskInCompleted(task, stateType);
}

export function archiveTutorialProject() {
  const tutorialData = getRecoil(tutorialDataSelector);
  setRecoil(tutorialCurrentProjectIdState, null);
  setRecoil(allTutorialProjectsState, []);
  const folders = tutorialData.folders.map((item, index) => {
    if (index === 0)
      return {
        ...item,
        archived: true,
      };
    return item;
  });
  setRecoil(tutorialFoldersState, folders);
}

export function addTutorialProjectMember(projectId: string, member: UserFieldType) {
  const tutorialData = getRecoil(tutorialDataSelector);
  const { allProjects } = tutorialData;

  const projectIndex = allProjects.findIndex((item) => item.id === projectId);
  if (projectIndex === -1) return;
  member = { ...member, isLoading: true };
  const tempData = allProjects.map((item, index) => {
    if (index === projectIndex)
      return {
        ...item,
        users: item.users ? [...item.users, member] : [member],
      };
    return item;
  });
  setRecoil(allTutorialProjectsState, tempData);

  setTimeout(() => {
    setTutorialProjectMemberLoadingState(projectId, member.id, false);
  }, 100);
}

export function setTutorialProjectMemberLoadingState(
  projectId: string,
  memberId: string,
  status: boolean,
) {
  const tutorialData = getRecoil(tutorialDataSelector);
  const { allProjects } = tutorialData;

  const tempData = allProjects.map((proj) => {
    if (proj.users && proj.id === projectId) {
      return {
        ...proj,
        users: proj.users.map((mem) => {
          if (mem.id === memberId) {
            return {
              ...mem,
              isLoading: status,
            };
          }
          return mem;
        }),
      };
    }
    return proj;
  });

  setRecoil(allTutorialProjectsState, tempData);
}

export function saveSubTaskSequence(
  dataType: TaskType,
  startIndex: number,
  endIndex: number,
  parentId?: string,
  stateType?: StateType,
) {
  const tutorialData = getRecoil(tutorialDataSelector);

  const { data } = tutorialData[dataType];

  if (data === null) return;
  const parentIndex = data.findIndex((item) => item.id === parentId);

  if (parentIndex === -1 || parentIndex === null) return;

  const parentData = data[parentIndex];
  const { subTasks } = parentData;
  if (subTasks === undefined) return;
  let reOrderedSubTask: TaskObjectType[] = [];
  reOrderedSubTask = reorderTasks(subTasks, startIndex, endIndex);

  log(data[parentIndex], 'parent yo');

  // get new sequence
  const newSequence = getNewSequence(endIndex, reOrderedSubTask);
  reOrderedSubTask[endIndex].sequence = newSequence;
  data[parentIndex].subTasks = subTasks;

  addTaskData(dataType, data, stateType);

  const task = reOrderedSubTask[endIndex];

  if (task.isTemp) return;

  task.sequenceController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'sequenceController',
    endIndex,
    newAbortSource,
    parentIndex,
    stateType,
  );
}

export function saveTutorialTaskSequence(
  dataType: TaskType,
  startIndex: number,
  endIndex: number,
  movedFromDataType: TaskType,
  stateType: StateType,
) {
  log('saveTutorialTaskSequence is called', dataType, startIndex, endIndex, stateType);

  const tutorialData = getRecoil(tutorialDataSelector);
  const { data } = tutorialData[dataType];

  if (data === null) return;
  let reOrderedData: TaskObjectType[] = [];

  if (movedFromDataType) {
    const dataFrom = tutorialData[movedFromDataType].data;
    if (dataFrom === null) return;
    const { tempData, tempDataFrom } = removeFromOneAddToAnother(
      data,
      dataFrom,
      startIndex,
      endIndex,
    );
    addTaskData(movedFromDataType, tempDataFrom, stateType);
    reOrderedData = tempData;
  } else reOrderedData = reorderTasks(data, startIndex, endIndex);

  // get new sequence
  const newSequence = getNewSequence(endIndex, reOrderedData);
  reOrderedData = reOrderedData.map((item, ind) => {
    if (ind === endIndex) {
      return {
        ...item,
        sequence: newSequence,
      };
    }
    return item;
  });
  addTaskData(dataType, reOrderedData, stateType);

  const task = reOrderedData[endIndex];

  if (task.isTemp) return;

  task.sequenceController?.abort();

  const newAbortSource = new AbortController();
  updateTaskAbortControllerToken(
    dataType,
    'sequenceController',
    endIndex,
    newAbortSource,
    undefined,
    stateType,
  );
}

export async function addTutorialTaskWithoutFocus(
  index: number,
  parentIndex: number | undefined,
  parentTaskId: string | undefined,
  dataType: TaskType,
  assigneeId: string | null,
  stateType: StateType,
  taskSize: SizeOptionsType | null = null,
  taskName: string,
) {
  log('addTutorialTask is called', index, parentTaskId, parentIndex);

  const state = getRecoil(combinedSelector);
  if (stateType === 'INDIVIDUAL') {
    assigneeId = state.userData.currentUserId;
  } else if (stateType !== 'PROJECT') {
    assigneeId = getUserId();
  }

  let { data } = state.tutorialData[dataType];

  if (data === null) return;
  let projectId: string | null = null;
  if (stateType === 'PROJECT') projectId = state.projectData.currentProjectId;
  const tempId = randomNumber() + '';

  const newTask = createNewTaskForTutorial(
    taskName,
    tempId,
    0,
    dataType,
    projectId,
    taskSize ? taskSize : 'M',
    assigneeId,
    stateType,
  );

  let newSequence;

  if (parentTaskId && parentIndex !== undefined && parentIndex !== -1) {
    const parent = data[parentIndex];
    if (parent.isTemp) return;
    if (!index) index = 0;

    // As a subtask, take project from its parent
    projectId = parent.project && parent.project.id ? parent.project.id : null;
    newTask.project = parent.project as ResponseProjectType;

    if (parent.subTasks === undefined) return;
    // insert task at certain location
    parent.subTasks = insertOnIndex(parent.subTasks, index, newTask as TaskObjectType);

    newSequence =
      parent.subTasks.length > 1 ? getNewSequence(index, parent.subTasks) : SEQUENCE_LARGE * 20;
    parent.subTasks[index].sequence = newSequence;

    data[parentIndex] = parent;

    addTaskData(dataType, data, stateType);
  } else {
    // insert task at certain location
    data = insertOnIndex(data, index, newTask as TaskObjectType);

    // get new sequence
    newSequence = getNewSequence(index, data);
    data[index].sequence = newSequence;
    data[index].taskNumber = 1;

    await addTaskData(dataType, data, stateType);
  }

  if (!parentTaskId) hasTaskUpdated(tempId, dataType, newTask, stateType);
  else hasSubTaskUpdated(parentTaskId, tempId, dataType, newTask, stateType);
}

export async function resetTutorialTask(
  index: number,
  parentIndex: number | undefined,
  parentTaskId: string | undefined,
  dataType: TaskType,
  assigneeId: string | null,
  stateType: StateType | undefined,
  taskSize: SizeOptionsType | null = null,
  taskName: string,
  projectId: string | null = null,
  taskNo: number | null = null,
  tagId: string | null = null,
  dueDate: Date | null = null,
  openComment: boolean = false,
  addComment: boolean = false,
) {
  await addTaskData(dataType, [], 'TUTORIAL');
  log('addTutorialTask is called', index, parentTaskId, parentIndex);
  const state = getRecoil(combinedSelector);
  assigneeId = assigneeId === null ? getUserId() : assigneeId;

  let { data } = state.tutorialData[dataType];

  if (data === null) return;
  const tempId = randomNumber() + '';

  const newTask = createNewTaskForTutorial(
    taskName,
    tempId,
    0,
    dataType,
    projectId,
    taskSize ? taskSize : 'M',
    assigneeId,
    stateType,
  );

  let newSequence;

  if (parentTaskId && parentIndex !== undefined && parentIndex !== -1) {
    const parent = data[parentIndex];
    if (parent.isTemp) return;
    if (!index) index = 0;

    // As a subtask, take project from its parent
    newTask.project = parent.project as ResponseProjectType;

    if (parent.subTasks === undefined) return;
    // insert task at certain location
    parent.subTasks = insertOnIndex(parent.subTasks, index, newTask as TaskObjectType);

    newSequence =
      parent.subTasks.length > 1 ? getNewSequence(index, parent.subTasks) : SEQUENCE_LARGE * 20;
    parent.subTasks[index].sequence = newSequence;

    data[parentIndex] = parent;

    await addTaskData(dataType, data, stateType);
  } else {
    // insert task at certain location
    data = insertOnIndex(data, index, newTask as TaskObjectType);

    // get new sequence
    newSequence = getNewSequence(index, data);
    data[index].sequence = newSequence;
    data[index].taskNumber = taskNo ? taskNo : 1;
    if (tagId && tagId === 'tagid1') {
      data[index].tags = [{ id: tagId, name: 'High Priority' }];
      data[index].tagInfo = [
        {
          addedByEmail: getUserEmail(),
          addedName: getUserName(),
          tagId: tagId,
          addedAt: new Date().getTime(),
          addedAtString: `${new Date()}`,
        },
      ];
    }
    if (dueDate) {
      data[index].dueDate = dueDate;
    }

    await addTaskData(dataType, data, stateType);
  }

  if (!parentTaskId) {
    hasTaskUpdated(tempId, dataType, newTask, stateType);
    if (openComment) {
      setRecoil(taskDetailModalState, {
        visible: true,
        modalType: 'comment',
        taskId: tempId,
        parentId: parentTaskId,
        dataType: dataType,
      });
    }
    if (addComment) {
      saveTaskComment(
        data[index].id,
        'This is a Magic Comment!',
        data[index].project ? data[index].project!.id : undefined,
        getUserId(),
        dataType,
        parentTaskId,
        null,
        [],
      );
    }
  } else hasSubTaskUpdated(parentTaskId, tempId, dataType, newTask, stateType);
}

export async function addTutorialTaskWithSubTask(
  index: number,
  parentIndex: number | undefined,
  parentTaskId: string | undefined,
  dataType: TaskType,
  assigneeId: string | null,
  stateType: StateType,
  taskSize: SizeOptionsType | null = null,
  taskName: string,
) {
  log('addTutorialTaskWithSubTask is called', index, parentTaskId, parentIndex);

  const state = getRecoil(combinedSelector);
  if (stateType !== 'PROJECT') {
    assigneeId = getUserId();
  }

  let { data } = state.tutorialData[dataType];

  if (data === null) return;
  let projectId: string | null = null;
  if (stateType === 'PROJECT') projectId = state.projectData.currentProjectId;
  const tempId = randomNumber() + '';

  const newTask = createNewTaskForTutorial(
    taskName,
    tempId,
    0,
    dataType,
    projectId,
    taskSize ? taskSize : 'M',
    assigneeId,
    stateType,
  );
  if (parentIndex !== undefined && parentIndex > -1) {
    data = insertOnIndex(data, parentIndex, newTask as TaskObjectType);

    data[parentIndex].taskNumber = 1;
    data[parentIndex].sequence = getNewSequence(parentIndex, data);

    const newSubtask = createNewTaskForTutorial(
      'My first sub-task',
      randomNumber() + '',
      0,
      dataType,
      null,
      'S',
      assigneeId,
      stateType,
    );
    newSubtask.taskNumber = 2;
    const parentData = data[parentIndex];
    if (parentData === null || parentData.subTasks === undefined) return;

    parentData.subTasks = insertOnIndex(parentData.subTasks, index, newSubtask as TaskObjectType);
    const newSequence =
      parentData.subTasks.length > 1
        ? getNewSequence(index, parentData.subTasks)
        : SEQUENCE_LARGE * 20;
    parentData.subTasks[index].sequence = newSequence;

    data[parentIndex] = parentData;

    addTaskData(dataType, data, stateType);
  }
}

export function updateTutorialStep(stepCount: number, tutorialId: string) {
  const allTutorials = getRecoil(allTutorialsState);
  const tempData = allTutorials.map((tutorial) => {
    if (tutorial.id === tutorialId)
      return {
        ...tutorial,
        currentStep: stepCount,
      };
    return tutorial;
  });
  setRecoil(allTutorialsState, tempData);
}

export function getFolderFromTag(isArchived: boolean = false) {
  const { accountData } = getRecoil(combinedSelector);
  let profileImage = Avatar;
  if (accountData.account && accountData.account.profileImage) {
    profileImage = accountData.account.profileImage;
  }
  const tempUsers: UserFieldType[] = [
    {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      display: getUserName(),
    },
  ];
  const temp: ProjectObjectType = {
    id: '01',
    name: 'MagicTask Tutorial',
    display: 'MagicTask Tutorial',
    archived: isArchived,
    users: tempUsers,
    createdBy: {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      email: getUserEmail(),
    },
    status: 'on track',
    modified: new Date().getTime(),
    remainingPoints: 0,
    completedPoints: 0,
  };
  const folder: FolderType = {
    id: '02',
    name: 'Tutorial',
    isFolder: true,
    isOpen: false,
    archived: isArchived,
    createdBy: {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      email: getUserEmail(),
    },
    modifiedby: {
      id: getUserId(),
      name: getUserName(),
      profileImage: profileImage,
      email: getUserEmail(),
    },
    created: parseToDateObjectFromTimestamp(new Date().getTime()),
    modified: parseToDateObjectFromTimestamp(new Date().getTime()),
    workspace: null,
    sequence: 0,
    projects: [temp],
  };
  addAllTutorialProjects([temp]);
  addTutorialFolders([folder]);
}

export function addCompletedTask(
  index: number,
  parentIndex: number | undefined,
  parentTaskId: string | undefined,
  dataType: TaskType,
  assigneeId: string | null,
  stateType: StateType,
  taskSize: SizeOptionsType | null = null,
  taskName: string,
) {
  const state = getRecoil(combinedSelector);
  if (stateType === 'INDIVIDUAL') {
    assigneeId = state.userData.currentUserId;
  } else if (stateType !== 'PROJECT') {
    assigneeId = getUserId();
  }

  let { data } = state.tutorialData[dataType];
  if (data === null) return;
  let projectId: string | null = null;
  if (stateType === 'PROJECT') projectId = state.projectData.currentProjectId;
  const tempId = randomNumber() + '';

  const newTask = createNewTaskForTutorial(
    taskName,
    tempId,
    0,
    dataType,
    projectId,
    taskSize ? taskSize : 'S',
    assigneeId,
    stateType,
  );

  let newSequence;
  if (parentTaskId && parentIndex !== undefined && parentIndex !== -1) {
    const parent = data[parentIndex];
    if (parent.isTemp) return;
    if (!index) index = 0;

    // As a subtask, take project from its parent
    projectId = parent.project && parent.project.id ? parent.project.id : null;
    newTask.project = parent.project as ResponseProjectType;

    // insert task at certain location
    if (parent.subTasks === undefined) return;
    parent.subTasks = insertOnIndex(parent.subTasks, index, newTask as TaskObjectType);

    newSequence =
      parent.subTasks.length > 1 ? getNewSequence(index, parent.subTasks) : SEQUENCE_LARGE * 20;
    parent.subTasks[index].sequence = newSequence;
    parent.subTasks[index].checked = true;
    parent.checked = true;
    parent.isTemp = false;
    data[parentIndex] = parent;
  } else {
    // insert task at certain location
    data = insertOnIndex(data, index, newTask as TaskObjectType);

    // get new sequence
    newSequence = getNewSequence(index, data);
    data[index].sequence = newSequence;
    data[index].taskNumber = 1;
    data[index].checked = true;
    data[index].isTemp = false;
  }

  addCompletedTasks(data, false, 0, 10, 1, stateType);
}
