import { getRecoil } from 'recoil-nexus';

import { accountDataSelector } from 'recoil/AccountState';
import { setPlayRiveTrigger } from 'recoil/TriggerState/update';
import { playRiveCanvas } from 'triggers/actions/rive';

export const RE_ORDER_TASK_NAME = 're-order-task-animation';

export const playTheRiveCanvas = (fileName = '', props, duration = 0) => {
  playRiveCanvas(fileName, duration, props);
};

export const playTheRiveAnimation = (fileName = '', duration = 0) => {
  if (!canBePlayed(fileName)) return;
  const prop = triggerKey(fileName) as keyof PlayRiveAnimationType;
  setPlayRiveTrigger(prop, true);

  if (duration) {
    setTimeout(() => {
      setPlayRiveTrigger(prop, false);
    }, duration);
  }
};

export const canBePlayed = (triggerId) => {
  const accountData = getRecoil(accountDataSelector);

  if (
    accountData.accountSettings.userAnimationSettings.animation &&
    accountData.accountSettings.userAnimationSettings[triggerKey(triggerId)]
  )
    return true;

  return false;
};

const triggerKey = (triggerId) =>
  triggerId === RE_ORDER_TASK_NAME ? camelCase('reorder-task-animation') : camelCase(triggerId);

const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};
