import { useEffect } from 'preact/compat';

type InputBoxMenuPropsType = {
  contextMenuPosition: { top: number; left: number };
  data: UserFieldType[] | SizeObjectType[] | TagObjectType[] | ProjectObjectType[];
  onClick: (val: SizeObjectType | ProjectObjectType | UserFieldType | TagObjectType) => void;
  selectedItemIndex: number;
  setSelectedItem: (idx: number) => void;
  goDown: () => void;
  goUp: () => void;
  forTopBar: boolean | undefined;
};

export default function Menu(props: InputBoxMenuPropsType) {
  const {
    contextMenuPosition,
    data,
    onClick,
    selectedItemIndex,
    setSelectedItem,
    goDown,
    goUp,
    forTopBar,
  } = props;

  useEffect(() => {
    const keyDown = (event) => {
      if (event.keyCode === 40) {
        goDown();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.keyCode === 38) {
        goUp();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener('keydown', keyDown, false);

    return () => {
      document.removeEventListener('keydown', keyDown, false);
    };
  });

  useEffect(() => {
    const menuItemId = `context-menu-item-${selectedItemIndex}`;

    const elem = document.getElementById(menuItemId);
    elem &&
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }, [selectedItemIndex]);

  const onHover = (index) => {
    setSelectedItem(index);
  };

  const renderMenuItem = (item, index) => {
    return (
      <div
        id={`context-menu-item-${index}`}
        key={index}
        className='context-menu-item'
        onClick={() => onClick(item)}
        onMouseOver={() => onHover(index)}
      >
        <h3
          className={`${selectedItemIndex === index ? 'selected' : 'cant-select'} ${
            item.id === 'REMOVE' ? 'remove-item' : ''
          }`}
        >
          {item.name}
        </h3>
      </div>
    );
  };

  const getTop = () => {
    const { top } = contextMenuPosition;
    if (forTopBar) {
      return top > 32 ? 32 : top;
    }

    return top;
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: getTop(),
        left: contextMenuPosition.left,
      }}
      className='context-menu'
    >
      {data.map((dat, index) => {
        return renderMenuItem(dat, index);
      })}
    </div>
  );
}
