import { useEffect } from 'preact/compat';
import { useRive, useStateMachineInput, Layout, Alignment, Fit } from '@rive-app/react-canvas-lite';

import usePrefersReducedMotion from 'utils/PrefersReducedMotion';
import { getCurrentTheme } from 'utils/theme';
import config from 'config/config';

const STATE_MACHINE = 'state_machine';

function buildRiveUrl(fileName: string, themeId: string) {
  return `/themes/${themeId}/rive/${fileName}.riv?v=${config.version}`;
}

type AnimationsProps = {
  fileName?: string;
  shouldTrigger?: boolean;
  checked?: boolean;
  animations?: any;
  [x: string]: any;
};

const Animations = ({
  fileName = '',
  shouldTrigger = false,
  checked = false,
  animations,
  ...rest
}: AnimationsProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const currentTheme = getCurrentTheme();
  const { rive, RiveComponent } = useRive(
    {
      src: buildRiveUrl(fileName, currentTheme.id),
      stateMachines: STATE_MACHINE,
      autoplay: true,
      layout: new Layout({
        fit: Fit.Fill,
        alignment: Alignment.Center,
      }),
      ...(animations && { animations }),
    },
    { shouldResizeCanvasToContainer: true },
  );
  const isHoverInput = useStateMachineInput(rive, STATE_MACHINE, 'hover');
  const isTrigger = useStateMachineInput(rive, STATE_MACHINE, 'trigger');
  const isChecked = useStateMachineInput(rive, STATE_MACHINE, 'checked');

  useEffect(() => {
    if (rive && isTrigger && shouldTrigger) {
      isTrigger.value = true;
    }
  }, [isTrigger, rive]);

  useEffect(() => {
    if (rive && isChecked) {
      isChecked.value = checked;
    }
  }, [isChecked, rive, checked]);

  const handleMouseEnter = () => {
    if (rive && isHoverInput) {
      if (prefersReducedMotion) {
        return;
      } else {
        if (isTrigger && shouldTrigger) {
          isTrigger.value = false;
        }
        isHoverInput.value = true;
      }
    }
  };

  const handleMouseLeave = () => {
    if (rive && isHoverInput) {
      if (prefersReducedMotion) {
        return;
      } else {
        isHoverInput.value = false;
      }
    }
  };

  return (
    <RiveComponent {...rest} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
  );
};

export default Animations;
