import { getCurrentTheme } from 'utils/theme';
import { playSound } from 'triggers/actions/sound';
import { getRecoil } from 'recoil-nexus';
import { combinedSelector } from 'recoil/CombinedState';

export const playTheSound = (fileName: string) => {
  const currentTheme = getCurrentTheme();
  playSound(fileName, currentTheme.id);
};

export const playModalCloseSound = () => {
  const state = getRecoil(combinedSelector);
  const { triggerData } = state;
  const modalSound = triggerData.soundTriggers.modalClose;
  if (modalSound && canBePlayed(modalSound)) {
    playTheSound(modalSound);
  }
};

export const playModalOpenSound = () => {
  const state = getRecoil(combinedSelector);
  const { triggerData } = state;

  const modalSound = triggerData.soundTriggers.modalOpen;
  if (modalSound && canBePlayed(modalSound)) {
    playTheSound(modalSound);
  }
};

export const canBePlayed = (soundId: string) => {
  let prop = camelCase(soundId);

  if (soundId === 're-order-task-sound') prop = 'reorderTaskSound';
  else if (soundId === 'modal-open-sound' || soundId === 'modal-close-sound')
    prop = 'menuNavigationSound';
  const state = getRecoil(combinedSelector);
  const { accountData } = state;

  if (
    accountData.accountSettings.userSoundEffectSetting.soundEffects &&
    (prop === 'levelUp' || prop === 'levelComplete')
  ) {
    return true;
  }

  if (
    accountData.accountSettings.userSoundEffectSetting.soundEffects &&
    accountData.accountSettings.userSoundEffectSetting[prop]
  )
    return true;

  return false;
};

const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};
