import { getRecoil } from 'recoil-nexus';
import { accountDataSelector } from 'recoil/AccountState';
import { playAnimation } from 'triggers/actions/canvas';
// import store from 'redux/store';

export const runCanvasAnimation = (triggerId, props?) => {
  if (canBePlayed('re-order-task-animation')) playAnimation(triggerId, props);
};

export const canBePlayed = (triggerId) => {
  let prop = camelCase(triggerId);

  if (triggerId === 're-order-task-animation') prop = 'reorderTaskAnimation';

  const accountData = getRecoil(accountDataSelector);

  if (
    accountData.accountSettings.userAnimationSettings.animation &&
    accountData.accountSettings.userAnimationSettings[prop]
  )
    return true;

  return false;
};

const camelCase = (string) => {
  return string.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};
