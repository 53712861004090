import { Fragment, useState } from 'preact/compat';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Appearance } from '@stripe/stripe-js';
import { useAlert } from 'react-alert';

import SubscribeForm from 'components/SubscribeForm';
import {
  intentSetup as intentSetupAPI,
  cancelSubscription as cancelSubscriptionAPI,
} from 'services/SubscriptionService';
import { showPricingModalState } from 'recoil/NavigationState';
import { accountState, subscriptionState } from 'recoil/AccountState';
import { getSubscriptionDetail } from 'recoil/AccountState/update';

import { pricingPlans } from 'utils/pricingPlans';
import TickIcon from 'assets/images/tick.svg';
import appConfig from 'config/config';
import { log } from 'utils';
import { Strings } from 'resources';

import './_pricing-modal.scss';
import SmallButton from 'components/UI/SmallButton';

const stripePromise = loadStripe(appConfig.stripe_key);

export default function PricingModal() {
  const [clientSecret, setClientSecret] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [isBuying, setIsBuying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const alert = useAlert();
  const setShowPricingModal = useSetRecoilState(showPricingModalState);
  const account = useRecoilValue(accountState);
  const subscriptionDetail = useRecoilValue(subscriptionState);

  const subscribe = async () => {
    setIsLoading(true);
    const { data } = await intentSetupAPI();
    log('stripe response', data);
    if (data && data.client_secret) {
      setClientSecret(data.client_secret);
    }
    if (data && data.customer) {
      setCustomerId(data.customer);
    }
    setIsBuying(true);
    setIsLoading(false);
  };

  const cancelPlan = async () => {
    if (subscriptionDetail?.id) {
      setIsLoading(true);
      const { success, data } = await cancelSubscriptionAPI({
        subscriptionId: subscriptionDetail.id,
      });
      if (success) {
        alert.success(data.message);
        await getSubscriptionDetail();
        setShowPricingModal(false);
      }

      setIsLoading(false);
    }
  };

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#332a7c',
      colorText: '#332a7c',
    },
  };

  const renderCloseButton = () => (
    <div className='pricing-close-con'>
      <button className='close-btn' onClick={() => setShowPricingModal(false)}></button>
    </div>
  );

  const renderCurrentButton = () => {
    return (
      <SmallButton btnType='subscribe-btn subscribe-free' disabled>
        {Strings.current_plan}
      </SmallButton>
    );
  };

  const renderEliteButton = () => {
    return (
      <SmallButton btnType='subscribe-btn subscribe-elite' loading={isLoading} clicked={subscribe}>
        {Strings.select_plan}
      </SmallButton>
    );
  };

  const renderCancelButton = () => {
    return (
      <SmallButton btnType='subscribe-btn subscribe-elite' loading={isLoading} clicked={cancelPlan}>
        {Strings.select_plan}
      </SmallButton>
    );
  };

  const renderPricingTable = () => {
    const isLimitExceeded =
      account?.workspace?.premiumStatus === 'FREE' ||
      account?.workspace?.premiumStatus === 'PENDING_PAYMENT';
    return (
      <div className='pricing-table'>
        {renderCloseButton()}
        <div className='pricing-table-content'>
          <h2 className='pricing-table-title'>
            {Strings.table_title}{' '}
            <span className='pt-highlight'>{Strings.table_highlight_title}</span>
          </h2>
          <p className='pricing-table-description'>{Strings.table_description}</p>
        </div>
        <div className='pricing-table-container'>
          <div className='pt-head'>
            <div className='pt-head-row'>
              <div className='pt-head-content pt-head-feature'>
                <p className='feature-text'>{Strings.feature}</p>
              </div>
              <div className='pt-head-content pt-head-free-plan'>
                {Strings.pleb}
                <p className='pt-text'>{Strings.free_forever}</p>
                {subscriptionDetail?.status !== 'active'
                  ? renderCurrentButton()
                  : renderCancelButton()}
              </div>
              <div className='pt-head-content pt-head-elite-plan'>
                {Strings.elite}
                <p className='pt-text'>{Strings.per_month}</p>
                {!isLimitExceeded && subscriptionDetail?.status === 'active'
                  ? renderCurrentButton()
                  : renderEliteButton()}
              </div>
            </div>
          </div>
          <div className='pt-body'>
            {pricingPlans.map(({ feature, free, elite }, i) => (
              <div className='pt-body-row' key={`pt${i}`}>
                <p className='pt-feature pt-body-content'>{feature}</p>
                <p className='pt-fplan pt-body-content'>
                  {free === 'false' ? (
                    '-'
                  ) : free === 'true' ? (
                    <img className='pt-fplan-tick' src={TickIcon} alt='true' />
                  ) : (
                    free
                  )}
                </p>
                <div className='pt-eplan pt-body-content'>
                  {elite === 'true' ? (
                    <img className='pt-eplan-tick' src={TickIcon} alt='true' />
                  ) : (
                    elite
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Fragment>
      <div className='pricing-modal-container'>
        <div className='main-container'>
          {clientSecret && isBuying ? (
            <div className='payment-form-container'>
              {/* @ts-ignore*/}
              <Elements options={options} stripe={stripePromise}>
                {/* @ts-ignore*/}
                <SubscribeForm
                  setupIntentData={{
                    clientSecret,
                    customerId,
                    appearance,
                  }}
                />
              </Elements>
              {renderCloseButton()}
            </div>
          ) : (
            renderPricingTable()
          )}
        </div>
      </div>

      <div className='pricing-modal-bg' onClick={() => setShowPricingModal(false)}></div>
    </Fragment>
  );
}
