import { TargetedEvent, useState } from 'preact/compat';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Validation, log } from 'utils';
import Button from 'components/UI/Button';
import { Strings } from 'resources';
import Input from 'components/UI/Input';
import { changePassword as changePasswordApi, isUserLoggedIn } from 'services/AuthService';
import Logo from 'assets/images/Logo.png';

export default function ChangePassword() {
  const navigate = useNavigate();
  const alert = useAlert();
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      name: Strings.current_password,
      value: '',
      type: 'text',
      touched: false,
      secure: true,
      maxLength: 256,
      key: 'password',
      rules: 'required|no_space|min:8|max:256',
      placeholder: Strings.current_password,
    },
    {
      name: Strings.new_password,
      value: '',
      type: 'text',
      touched: false,
      secure: true,
      maxLength: 256,
      key: 'newPassword',
      rules: 'required|no_space|min:8|max:256',
      placeholder: Strings.new_password,
    },
    {
      name: Strings.confirm_password,
      value: '',
      type: 'text',
      secure: true,
      touched: false,
      maxLength: 256,
      key: 'confirm-password',
      rules: 'required|no_space|match_index:1|min:8|max:256',
      placeholder: Strings.confirm_password,
    },
  ]);

  const submit = (event: TargetedEvent) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;

    callChangePasswordApi(getPayload());
  };

  const getPayload = () => {
    isUserLoggedIn();
    const payload = {};
    payload['newPassword'] = fields[1].value;
    payload['oldPassword'] = fields[0].value;
    return payload;
  };

  const callChangePasswordApi = async (payload: any) => {
    setLoadingState(true);
    const { data, success, message } = await changePasswordApi(payload);
    setLoadingState(false);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return changePassSuccess(data);
    }
  };

  const changePassSuccess = (data: any) => {
    log('response data', data);
    alert.success(data.responseMessage);
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  const fieldUpdated = (text: string, index: number) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const renderField = (field: ValidationFieldType, index: number) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder ? field.placeholder : ''}
        autoComplete='off'
        data-lpignore='true'
        data-form-type='other'
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={field.touched}
        value={field.value as string}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        secure={field.secure}
      />
    );
  };

  return (
    <div className={`change-pass-main-container`}>
      <div className='forgot-pass-screen'>
        <div className='forgot-pass-form'>
          <div className='magictask-title'>
            <img src={Logo} alt='Logo' />
          </div>
          <form
            className='forgot-pass-form-con reset-pass-container'
            onSubmit={(event) => submit(event)}
          >
            <h1 className='forgot-pass-header'>{Strings.change_password}</h1>
            {fields.map((field, index) => renderField(field, index))}
            <div className='btn'>
              <Button idName='change-password-reset-btn' disabled={hasErrors} loading={isLoading}>
                {' '}
                {Strings.reset}{' '}
              </Button>
            </div>
          </form>
        </div>
        <div className='forgot-pass-img-con'></div>
      </div>
    </div>
  );
}
