type FullScreenLoaderPropsType = {
  title: string;
  hide: boolean;
};
const FullScreenLoader = (props: FullScreenLoaderPropsType) => {
  const { title, hide } = props;

  if (hide) return null;

  return (
    <div className='full-screen-loader-con'>
      <h4 className='title'>{title}</h4>
      <div className='LoaderBalls'>
        <div className='LoaderBalls__item'></div>
        <div className='LoaderBalls__item'></div>
        <div className='LoaderBalls__item'></div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
