import SuperAdminLevelBuilder from 'components/SuperAdminLevelBuilder';
import { useState, useRef, useEffect } from 'preact/compat';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Strings } from 'resources';
import SmallButton from 'components/UI/SmallButton';
import { formatDateForAPI, formatTimeForThemeAPI } from 'utils/task';
import { createNewTheme } from 'services/ThemeService';
import { Validation, log } from 'utils';
import { useRecoilValue } from 'recoil';
import { triggerDataSelector } from 'recoil/TriggerState';
import { navigationDataSelector } from 'recoil/NavigationState';
import { getAllTriggers } from 'recoil/TriggerState/update';
export default function CreateThemeLevelBuilder() {
  const defaultFields: ValidationFieldType[] = [
    {
      name: Strings.points,
      key: 'points',
      value: '',
      type: 'text',
      rules: 'required|number',
      title: Strings.enter_points,
    },
    {
      name: Strings.trigger,
      key: 'triggers',
      type: 'dropdown',
      value: 0,
      rules: 'required_Object',
      title: Strings.choose_trigger,
      options: [],
      selectedOptions: [],
    },
    {
      name: Strings.description,
      key: 'description',
      value: '',
      type: 'text',
      rules: 'required|min:5|max:400',
      title: Strings.enter_description,
    },
  ];
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const triggerData = useRecoilValue(triggerDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);

  const [levelBuilderCount, setLevelBuilderCount] = useState(1);
  const componentRef = useRef<any>([]);

  const [isLoading, setLoadingState] = useState(false);

  const [fields, setFields] = useState<ValidationFieldType[][]>([]);

  useEffect(() => {
    componentRef.current = componentRef.current.slice(0, levelBuilderCount);
  }, [levelBuilderCount]);

  useEffect(() => {
    getAllTriggers();
  }, []);

  useEffect(() => {
    if (location.state === undefined || location.state.payload === null) {
      navigate('/super-admin/create-theme');
    }
  }, [location.state]);

  useEffect(() => {
    const allTriggers = triggerData && triggerData.allTriggers ? triggerData.allTriggers : null;
    const newFieldList = [...fields];

    if (allTriggers && allTriggers.length > 0 && newFieldList.length === 0) {
      defaultFields[1].options = allTriggers;
      newFieldList.push(defaultFields);
      setFields(newFieldList);
    }
  }, [triggerData]);

  const removeLevel = (levelIndex: number) => {
    if (fields.length > 1) {
      const newFieldArray = fields.filter((level, ind) => ind !== levelIndex - 1);
      setFields(newFieldArray);
      setLevelBuilderCount(levelBuilderCount - 1);
    }
  };

  const fieldUpdated = (value: string, index: number, levelIndex: number) => {
    const newFieldArray = [...fields];
    newFieldArray[levelIndex][index].value = value;
    setFields(newFieldArray);
  };

  const checkedFieldUpdated = (checkedStatus: boolean, levelIndex: number, id: string) => {
    const newFieldArray = [...fields];

    checkedStatus
      ? (newFieldArray[levelIndex][1].value = (newFieldArray[levelIndex][1].value as number) + 1)
      : (newFieldArray[levelIndex][1].value = (newFieldArray[levelIndex][1].value as number) - 1);

    newFieldArray[levelIndex][1].options?.map((it) => {
      if (it.id === id) {
        let tempOptions: TriggerObjectType[] | undefined = [];
        if (checkedStatus) {
          tempOptions = newFieldArray[levelIndex][1].selectedOptions?.concat([it]);
        } else {
          tempOptions = newFieldArray[levelIndex][1].selectedOptions?.filter((obj) => {
            return obj.id !== it.id;
          });
        }

        return (newFieldArray[levelIndex][1].selectedOptions = tempOptions);
      } else {
        return null;
      }
    });
    setFields(newFieldArray);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;
    let error = '';
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        error = field.errors[0];
        break;
      }
    }

    return { hasError, error };
  };

  /*
   * Create Theme by calling backend
   * payload object
   *
   * returns null
   */
  const createTheme = async (payload: any) => {
    setLoadingState(true);

    const { success, message } = await createNewTheme(payload);

    setLoadingState(false);

    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      alert.success('Theme Created successfully!');
      resetState();
      navigate('themes', { replace: true });
      return;
    }
  };

  /*
   * Reset state
   *
   * returns null
   */
  const resetState = () => {
    log('reset state');
    const newFieldArray = [...fields];

    newFieldArray.forEach((fields, index) => {
      fields.forEach((field, innerIndex) => {
        if (field.type === 'file') newFieldArray[index][innerIndex].value = '';
        else if (field.type === 'boolean') newFieldArray[index][innerIndex].value = false;
        else newFieldArray[index][innerIndex].value = '';
      });
    });

    setFields(newFieldArray);
  };

  /*
   * Get payload for verify email api
   * fields Array
   *
   * returns Object
   */
  const getPayload = (fields: ValidationFieldType[][]) => {
    const formData = new FormData();

    const payload = location.state.payload;
    const tempPayload = {};
    let date = new Date();
    let time = '';
    payload.forEach((item) => {
      if (item.key === 'rarity') {
        formData.append(item.key, item.value.toUpperCase());
      } else if (item.type === 'date') {
        date = item.value;
      } else if (item.type === 'time') {
        time = item.value;
      } else {
        formData.append(item.key, item.value);
        tempPayload[item.key] = item.value;
      }
    });
    const dateValue = `${formatDateForAPI(date)}T${formatTimeForThemeAPI(time)}`;
    formData.append('timeOfLaunch', dateValue);

    fields.forEach((outerField, index) => {
      outerField.forEach((field) => {
        if (field.key === 'triggers') {
          const levelList: string[] = [];
          field.selectedOptions &&
            field.selectedOptions.map((item) => {
              return levelList.push(item.name);
            });
          formData.append(`pointLevels[${index}].${field.key}`, levelList.toString());
          tempPayload[`pointLevels[${index}].${field.key}`] = levelList;
        } else {
          formData.append(`pointLevels[${index}].${field.key}`, field.value as string | File);
          tempPayload[`pointLevels[${index}].${field.key}`] = field.value;
        }
      });
      formData.append(`pointLevels[${index}].level`, `${index + 1}`);
      tempPayload[`pointLevels[${index}].level`] = index + 1;
    });
    log('tempPayload', tempPayload);
    return formData;
  };

  const renderLevelBody = () => {
    const levelBuilderList: any = [];
    if (fields.length !== 0) {
      for (let index = 0; index < levelBuilderCount; index++) {
        levelBuilderList.push(
          <SuperAdminLevelBuilder
            key={index}
            innerRef={(element) => componentRef.current.push(element)}
            fieldUpdated={fieldUpdated}
            fields={fields[index]}
            removeLevel={removeLevel}
            checkedFieldUpdated={checkedFieldUpdated}
            levelIndex={index + 1}
            loading={isLoading}
          />,
        );
      }
      return levelBuilderList.map((item) => {
        return item;
      });
    } else {
      return null;
    }
  };

  const renderPlusButton = () => {
    return (
      <div
        id='plus-button-con'
        className={`plus-button-con ${isLoading ? 'disabled' : ''}`}
        onClick={() => addLevel()}
      >
        <FontAwesomeIcon id='plus-icon' className='plus-icon' icon={faPlus} />
      </div>
    );
  };

  const addLevel = () => {
    const newFieldList = [...fields];
    newFieldList.push([
      {
        name: Strings.points,
        key: 'points',
        value: '',
        type: 'text',
        rules: 'required|number',
        title: Strings.enter_points,
      },
      {
        name: Strings.trigger,
        key: 'triggers',
        type: 'dropdown',
        value: 0,
        rules: 'required_Object',
        title: Strings.choose_trigger,
        options: [],
        selectedOptions: [],
      },
      {
        name: Strings.description,
        key: 'description',
        value: '',
        type: 'text',
        rules: 'required|min:5|max:400',
        title: Strings.enter_description,
      },
    ]);

    const allTriggers = triggerData && triggerData.allTriggers ? triggerData.allTriggers : null;
    if (allTriggers && allTriggers.length > 0) {
      newFieldList[newFieldList.length - 1][1].options = allTriggers;
      setFields(newFieldList);
      setLevelBuilderCount(levelBuilderCount + 1);
    }
  };

  const handleBackTap = () => {
    resetState();
    navigate(-1);
  };

  const handleSaveTap = () => {
    if (isLoading) return;
    let isError = false;
    fields.forEach((field, _index) => {
      const fieldsWithError = Validation.validate(field);
      const { hasError, error } = checkErrors(fieldsWithError);

      if (hasError) {
        isError = true;
        alert.error(error);
        return;
      }
    });

    if (!isError) createTheme(getPayload(fields));
  };

  return (
    <div
      id='create-theme-level-screen-container'
      className={`create-theme-level-screen-container  ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div className='title-container'>
        <div className='page-title'>{Strings.super_admin}</div>
      </div>
      <div className='subtitle-container'>
        <div className='list-title'>{Strings.create_level_builder}</div>
        <div className='action-container'>
          <SmallButton clicked={handleBackTap} disabled={isLoading}>
            {Strings.go_back_with_icon}
          </SmallButton>
          <SmallButton clicked={handleSaveTap} loading={isLoading}>
            {Strings.save}
          </SmallButton>
        </div>
      </div>
      <div id='level-items-con' className='level-items-con'>
        {renderLevelBody()}
        {renderPlusButton()}
      </div>
    </div>
  );
}
