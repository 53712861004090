import { useState, useEffect } from 'preact/compat';
import { getBase64 } from 'services/FileService';
import Spinner from 'components/UI/Spinner';

type ThumbnailPropsType = {
  thumbnail?: string;
  url: string | null;
  index: number;
};
export default function Thumbnail(props: ThumbnailPropsType) {
  const { thumbnail, url, index } = props;

  const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

  const [fileData, setFileData] = useState<string | null>(null);

  useEffect(() => {
    getBase64Image();
  }, [url, thumbnail]);

  const getBase64Image = async () => {
    const fileUrl = thumbnail ? thumbnail : url ? url : null;

    if (!fileUrl) return;

    const file = await getBase64(fileUrl);
    setFileData(file);
  };

  if (!fileData) return <Spinner />;

  return (
    <img
      id={`comment-attachment-${index}`}
      src={fileData ? fileData : BLANK_IMAGE}
      alt={`comment-attachment-${index}`}
    />
  );
}
