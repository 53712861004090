import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/*
 * Get recent search
 *
 * returns Object {data, error, status}
 */
export async function getRecentSearch() {
  try {
    const response = await appClient.get(apiConfig.search.get_recent_search);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * fetch search results
 * @param {Object} payload 
 * {
    "page": 0,
    "searchByUsers": [
        "string"
    ],
    "searchInTags": [
        "string"
    ],
    "searchKey": "okay",
    "size": 0
    }
 * @returns Object {data, error, status}
 */
export async function getSearchResults(payload) {
  try {
    const response = await appClient.post(apiConfig.search.get_search_results, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
