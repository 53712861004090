import { useEffect, Suspense, Fragment } from 'preact/compat';
import { setPageTitle } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import { Navigate, Outlet } from 'react-router-dom';
import TutorialTopBar from 'components/TutorialTopBar';
import TutorialLeftBar from 'components/TutorialLeftBar';
import { loadTheme } from 'utils/theme';
import {
  getUserId,
  // getUserEmail,
  // getUserName,
  reFetchUserSession,
  isUserLoggedIn,
} from 'services/AuthService';
// import rg4js from 'raygun4js';
import UserTutorial from 'screens/UserTutorial';
import useWindowDimensions from 'utils/WindowDimensions';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tutorialDataSelector } from 'recoil/TutorialState';
import { hideWorkspaceSwitchState, navigationDataSelector } from 'recoil/NavigationState';
import { setItem } from 'utils/storage';
import {
  getAccountSettings,
  getAllTags,
  getAllUsersWithCount,
  getInvitedMembers,
  getNewNotifications,
  getUserCounters,
  getWhatsNew,
} from 'recoil/AccountState/update';
import { initializeTaskFilters } from 'recoil/TaskState/update';

const InviteMembers = lazyWithRetry(() => import('modals/InviteMembers'));
const CreateNewProject = lazyWithRetry(() => import('modals/CreateNewProject'));
const TaskInfo = lazyWithRetry(() => import('modals/TaskInfo'));
const TutorialEditProject = lazyWithRetry(() => import('modals/TutorialEditProject'));
const CreateNewFolder = lazyWithRetry(() => import('modals/CreateNewFolder'));

type TutorialLayoutPropsType = {
  pageTitle?: string;
  stateType: StateType;
  path: string;
};
export default function TutorialLayout(props: TutorialLayoutPropsType) {
  const { pageTitle, stateType } = props;
  const tutorialData = useRecoilValue(tutorialDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const userId = getUserId();
  const setHideWorkspaceSwitch = useSetRecoilState(hideWorkspaceSwitchState);

  const toggleWorkspaceSwitch = (status?: boolean) => {
    setItem(
      'hideWorkspaceSwitch',
      status ? status.toString() : (!navigationData.hideWorkspaceSwitch).toString(),
    );
    setHideWorkspaceSwitch(status ? status : !navigationData.hideWorkspaceSwitch);
  };

  useEffect(() => {
    toggleWorkspaceSwitch(true);
  }, []);

  useEffect(() => {
    loadTheme(true);
  }, []);

  useEffect(() => {
    // rg4js('setUser', {
    //   identifier: getUserId(),
    //   isAnonymous: false,
    //   email: getUserEmail(),
    //   fullName: getUserName(),
    // });

    // sending userid for google analytics
    if (document.location.origin === 'https://app.magictask.io') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: getUserId(),
      });
    }

    getInitialData();
  }, []);

  const getInitialData = async () => {
    reFetchUserSession();

    getWhatsNew();
    getUserCounters();

    // get tags
    getAllTags(userId);

    getAllUsersWithCount();
    getInvitedMembers();

    getNewNotifications('Un-read', 0);

    /* Get Account Settings Details */
    getAccountSettings(userId, true);

    // Initialize all filters from localStorage
    initializeTaskFilters();
  };

  // component did mount
  useEffect(() => {
    if (pageTitle) setPageTitle(pageTitle);

    // rg4js('trackEvent', { type: 'pageView', path: '/' + window.location.pathname });
  }, [pageTitle]);

  const { status } = isUserLoggedIn();

  const { width } = useWindowDimensions();

  return (
    <Fragment>
      {status ? (
        width > 800 ? (
          tutorialData.currentProjectId !== null && props.path !== '/:projectId/user-tutorial' ? (
            <Navigate to={`/${tutorialData.currentProjectId}/user-tutorial`} />
          ) : tutorialData.currentProjectId === null &&
            props.path === '/:projectId/user-tutorial' ? (
            <Navigate to='/user-tutorial' />
          ) : tutorialData.flags.showCompletedPage &&
            !tutorialData.flags.uncompleteTaskCompletion &&
            props.path !== '/completed/user-tutorial' ? (
            <Navigate to='/completed/user-tutorial' />
          ) : !tutorialData.flags.showCompletedPage && props.path === '/completed/user-tutorial' ? (
            <Navigate to='/user-tutorial' />
          ) : (tutorialData.flags.showAdminPage || tutorialData.flags.showArchiveCheckbox) &&
            props.path !== '/admin/:page/user-tutorial' ? (
            <Navigate to='/admin/portfolio/user-tutorial' />
          ) : !tutorialData.flags.showAdminPage &&
            !tutorialData.flags.showArchiveCheckbox &&
            props.path === '/admin/:page/user-tutorial' ? (
            <Navigate to='/user-tutorial' />
          ) : (
            <div className='tutorial-layout-container'>
              <TutorialTopBar currentRoute={props.path} />
              <TutorialLeftBar />
              <Suspense fallback={null}>
                {navigationData.showCreateNewProjectModal ? (
                  <CreateNewProject stateType={stateType} />
                ) : null}
              </Suspense>
              <Suspense fallback={null}>
                {navigationData.showInviteMembersModal ? (
                  <InviteMembers stateType={stateType} />
                ) : null}
              </Suspense>
              <Suspense fallback={null}>
                {navigationData.taskDetailModal.visible ? (
                  <TaskInfo currentRoute={props.path} />
                ) : null}
              </Suspense>
              <Suspense fallback={null}>
                {navigationData.showEditProjectModal ? (
                  <TutorialEditProject stateType={stateType} />
                ) : null}
              </Suspense>
              <Suspense fallback={null}>
                {navigationData.showCreateFolderModal ? (
                  <CreateNewFolder stateType={stateType} />
                ) : null}
              </Suspense>
              <div id='main-page-container-tutorial' className='main-page-container'>
                {/** @ts-ignore */}
                <Outlet context={props} />
              </div>
              <UserTutorial />
            </div>
          )
        ) : (
          <Navigate to='/' />
        )
      ) : (
        <Navigate to='/login' />
      )}
    </Fragment>
  );
}
