import { Fragment, useEffect, useRef, useState, useCallback } from 'preact/compat';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { MenuItem, ControlledMenu, FocusableItem, Menu, MenuButton } from '@szhsin/react-menu';
import { useRecoilState, useRecoilValue } from 'recoil';
import { faChevronDown, faChevronUp, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Strings } from 'resources';
import { highlightText } from 'utils/helpers';
import { accountDataSelector } from 'recoil/AccountState';
import {
  getUserEmail,
  getUserId,
  getUserName as getUserNameFromStorage,
  getUserWorkspaceId,
  chartLogin as chartLoginAPI,
} from 'services/AuthService';
import { userAnalyticsMonthlyCompletedFilterSelector } from 'recoil/UserAnalyticsState';
import { projectDataSelector } from 'recoil/ProjectState';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { formatForAnalytics, formatDateForView } from 'utils/task';
import { log } from 'utils';
import appConfig from 'config/config';
import { monthlyDateFilterData } from 'utils/analytics';

type CompletedMonthlyChartFiltersType = {
  assigneeId?: { $in: string[] };
  projectId?: { $in: string[] };
  status: { $in: string[] };
  modified?: { $gte: { $date: string }; $lt: { $date: string } };
  workspace?: { $in: string[] };
};

export default function CompletedMonthlyChart(props: ChartPropsType) {
  const { tasksChartId, pointsChartId } = props;
  const assigneeFilterRef = useRef(null);
  const skipAssigneeClick = useRef(false);
  const [assigneeFilterValue, setAssigneeFilterValue] = useState('');
  const [isAssigneeFilterOpen, setAssigneeFilterOpen] = useState(false);

  const projectFilterRef = useRef(null);
  const skipProjectClick = useRef(false);
  const [projectFilterValue, setProjectFilterValue] = useState('');
  const [isProjectFilterOpen, setProjectFilterOpen] = useState(false);

  const dateFilterRef = useRef(null);
  const skipDateClick = useRef(false);
  const [isDateFilterOpen, setDateFilterOpen] = useState(false);

  const [monthlyCompletedFilter, setUserAnalyticsMonthlyCompletedFilter] = useRecoilState(
    userAnalyticsMonthlyCompletedFilterSelector,
  );

  const loggedInUserId = getUserId();
  const userName = getUserNameFromStorage();
  const accountData = useRecoilValue(accountDataSelector);
  const projectData = useRecoilValue(projectDataSelector);

  const loggedInUser: UserFieldType = {
    id: loggedInUserId,
    name: userName,
    display: userName,
    email: getUserEmail(),
    openCount: 0,
    completedCount: 0,
    profileImage: accountData.account ? accountData.account?.profileImage : undefined,
  };

  const chartLogin = async () => {
    const { data, success } = await chartLoginAPI();
    if (success) {
      return data;
    }
    return 'invalid';
  };
  const sdk = new ChartsEmbedSDK({
    baseUrl: appConfig.chartUrl,
    getUserToken: async function () {
      return await chartLogin();
    },
  });
  const pointsChartDiv: any = useRef(null);
  const tasksChartDiv: any = useRef(null);

  const [rendered, setRendered] = useState(false);
  const [tasksChart] = useState(
    sdk.createChart({
      chartId: tasksChartId,
      theme: 'light',
      filter: {
        assigneeId: {
          $in: [getUserId()],
        },
        status: {
          $in: ['COMPLETED'],
        },
        workspace: {
          $in: [getUserWorkspaceId()],
        },
      },
      showAttribution: false,
      autoRefresh: false,
    }),
  );

  const [pointsChart] = useState(
    sdk.createChart({
      chartId: pointsChartId,
      theme: 'light',
      filter: {
        assigneeId: {
          $in: [getUserId()],
        },
        status: {
          $in: ['COMPLETED'],
        },
        workspace: {
          $in: [getUserWorkspaceId()],
        },
      },
      showAttribution: false,
    }),
  );

  useEffect(() => {
    if (pointsChartDiv !== null)
      pointsChart
        .render(pointsChartDiv.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during Charts rendering.', err));
  }, [pointsChart]);

  useEffect(() => {
    if (tasksChartDiv !== null)
      tasksChart
        .render(tasksChartDiv.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during Charts rendering.', err));
  }, [tasksChart]);

  useEffect(() => {
    let filters: CompletedMonthlyChartFiltersType = {
      status: {
        $in: ['COMPLETED'],
      },
      workspace: { $in: [getUserWorkspaceId()] },
    };
    if (rendered && monthlyCompletedFilter) {
      filters = {
        ...filters,
        assigneeId: {
          $in: monthlyCompletedFilter.assignee,
        },
      };
      if (monthlyCompletedFilter.project.length > 0) {
        filters = {
          ...filters,
          projectId: {
            $in: monthlyCompletedFilter.project,
          },
        };
      }
      if (monthlyCompletedFilter.modified.start && monthlyCompletedFilter.modified.end) {
        filters = {
          ...filters,
          modified: {
            $gte: {
              $date: monthlyCompletedFilter.modified.start,
            },
            $lt: {
              $date: monthlyCompletedFilter.modified.end,
            },
          },
        };
      }

      tasksChart.setFilter(filters).catch((err) => console.log('Error while filtering.', err));
      pointsChart.setFilter(filters).catch((err) => console.log('Error while filtering.', err));
    }
  }, [tasksChart, pointsChart, monthlyCompletedFilter, rendered]);

  const filteredProjects = useCallback(() => {
    const projects = projectData.allProjects
      ? projectData.allProjects.filter((proj) => proj.archived !== true)
      : [];
    let filteredProjects: ProjectObjectType[] = [];
    const filter = projectFilterValue.trim().toUpperCase().replace('!', '');

    projects.forEach((proj) => {
      const name = proj.name?.toUpperCase();

      if (name?.includes(filter)) filteredProjects.push(proj);
    });
    if (monthlyCompletedFilter.project.length > 0) {
      const seletectedProjects = filteredProjects.filter((item) =>
        monthlyCompletedFilter.project.includes(item.id),
      );
      const unSelectedProjects = filteredProjects.filter(
        (item) => !monthlyCompletedFilter.project.includes(item.id),
      );
      filteredProjects = [...seletectedProjects, ...unSelectedProjects];
    }
    return filteredProjects;
  }, [projectFilterValue, projectData.allProjects, JSON.stringify(monthlyCompletedFilter.project)]);

  const updateFilter = (name: string, value: string | null) => {
    if (name === 'assigneeFilter') {
      let assigneeList: string[] = [getUserId()];
      if (value !== null) {
        assigneeList = [value];
        if (monthlyCompletedFilter && monthlyCompletedFilter.assignee.length > 0) {
          const index = monthlyCompletedFilter.assignee.findIndex((item) => item === value);
          if (index === -1) {
            assigneeList = [...monthlyCompletedFilter.assignee, ...assigneeList];
          } else {
            assigneeList = monthlyCompletedFilter.assignee.filter((item) => item !== value);
            if (assigneeList.length === 0) {
              assigneeList = [getUserId()];
            }
          }
        }
      }
      setUserAnalyticsMonthlyCompletedFilter({ ...monthlyCompletedFilter, assignee: assigneeList });
      return;
    } else if (name === 'projectFilter') {
      let projectList: string[] = [];
      if (value !== null) {
        projectList = [value];
        if (monthlyCompletedFilter && monthlyCompletedFilter.project.length > 0) {
          const index = monthlyCompletedFilter.project.findIndex((item) => item === value);
          if (index === -1) {
            projectList = [...monthlyCompletedFilter.project, ...projectList];
          } else {
            projectList = monthlyCompletedFilter.project.filter((item) => item !== value);
            if (projectList.length === 0) {
              projectList = [];
            }
          }
        }
      }
      setUserAnalyticsMonthlyCompletedFilter({ ...monthlyCompletedFilter, project: projectList });
      return;
    }
  };

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = (item: UserFieldType | ProjectObjectType, filter?: string[] | null) => {
    return (
      <div className='checkbox-con' key={`checkbox-con-${item.name}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          checked={filter && filter.length > 0 ? filter.includes(item.id) : false}
          className='inp-cbx'
          id={`checkbox-${item.name}`}
          type='checkbox'
          onChange={() => {}}
          style={{ display: 'none' }}
        />

        <label className='cbx'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    );
  };

  const filteredUsers = useCallback(() => {
    let filteredUsers: UserFieldType[] = [];
    const filter = assigneeFilterValue.trim().toUpperCase();
    const allProjects = projectData.allProjects;
    if (
      monthlyCompletedFilter.project.length > 0 &&
      allProjects !== null &&
      allProjects.length > 0
    ) {
      monthlyCompletedFilter.project.forEach((proj) => {
        const projDetails = allProjects.filter((item) => item.id === proj);
        if (projDetails.length > 0) {
          const selProjUsers = projDetails[0].users;
          if (selProjUsers) {
            selProjUsers.forEach((user) => {
              const name = user.name?.toUpperCase();

              if (name?.includes(filter)) {
                const contains = filteredUsers.filter((item) => item.id === user.id);
                if (contains.length === 0) filteredUsers.push(user);
              }
            });
          }
        }
      });
      filteredUsers.sort(function (a, b) {
        const textA = a.name ? a.name.toUpperCase() : '';
        const textB = b.name ? b.name.toUpperCase() : '';
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      if (monthlyCompletedFilter.assignee.length > 0) {
        const removeUsers: string[] = [];
        monthlyCompletedFilter.assignee.forEach((user) => {
          const checkInUsers = filteredUsers.filter((item) => item.id === user);
          if (checkInUsers.length === 0) {
            removeUsers.push(user);
          }
        });
        if (removeUsers.length > 0) {
          const assigneeList = monthlyCompletedFilter.assignee.filter(
            (user) => !removeUsers.includes(user),
          );
          setUserAnalyticsMonthlyCompletedFilter({
            ...monthlyCompletedFilter,
            assignee: assigneeList,
          });
        }
      }
    } else {
      const allUsers = accountData.allUsers
        ? [
            loggedInUser,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...accountData.allUsers,
          ]
        : [loggedInUser];
      allUsers?.forEach((user) => {
        const name = user.name?.toUpperCase();

        if (name?.includes(filter)) filteredUsers.push(user);
      });
    }

    if (monthlyCompletedFilter.assignee.length > 0) {
      const seletectedUsers = filteredUsers.filter((item) =>
        monthlyCompletedFilter.assignee.includes(item.id),
      );
      const unSelectedUsers = filteredUsers.filter(
        (item) => !monthlyCompletedFilter.assignee.includes(item.id),
      );
      filteredUsers = [...seletectedUsers, ...unSelectedUsers];
    }
    return filteredUsers;
  }, [
    assigneeFilterValue,
    accountData.allUsers,
    JSON.stringify(monthlyCompletedFilter.assignee),
    JSON.stringify(monthlyCompletedFilter.project),
  ]);
  const renderAssigneeFilter = () => {
    return (
      <div
        id='assignee-filter-con'
        className='analytics-item-container assignee-container filter-control'
        key='assignee-filter'
      >
        <button
          id='assignee-filter-menu-button'
          className='item-btn assignee-btn'
          ref={assigneeFilterRef}
          onClick={() => !skipAssigneeClick.current && setAssigneeFilterOpen(true)}
          key='assignee-filter-btn'
        >
          <span className='item-selected'>
            {monthlyCompletedFilter && monthlyCompletedFilter.assignee.length > 0
              ? Strings.user_selected
              : Strings.filter_assignee}
          </span>
          {!isAssigneeFilterOpen ? (
            <FontAwesomeIcon
              id='assignee-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='assignee-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={assigneeFilterRef}
          state={isAssigneeFilterOpen ? 'open' : 'closed'}
          key='assignee-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipAssigneeClick.current = true;
              setTimeout(() => (skipAssigneeClick.current = false), 300);
            }
            if (reason !== 'click') {
              setAssigneeFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='assignee-filter-menu-item-input-con' className='item-input'>
            {() => (
              <input
                id='assignee-filter-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Assignee'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={assigneeFilterValue}
                onChange={(e) => setAssigneeFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredUsers().map((userList, _index) => (
            <MenuItem
              id={`assignee-filter-menu-item-${userList.id}`}
              key={`assignee-filter-menu-item-${userList.id}`}
              onClick={() => updateFilter('assigneeFilter', userList.id)}
              value={userList.id}
              className='assignee-filter-menu-item'
            >
              {renderCheckbox(userList, monthlyCompletedFilter.assignee)}
              {userList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='assignee-filter-menu-item-remove'
            className='remove-item'
            key='assignee-filter-all'
            onClick={() => updateFilter('assigneeFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };
  const renderProjectFilter = () => {
    return (
      <div
        id='project-filter-con'
        className='analytics-item-container project-container filter-control'
        key='project-filter'
      >
        <button
          id='project-filter-menu-button'
          className='item-btn project-btn'
          ref={projectFilterRef}
          onClick={() => !skipProjectClick.current && setProjectFilterOpen(true)}
          key='project-filter-btn'
        >
          <span className='item-selected'>
            {monthlyCompletedFilter && monthlyCompletedFilter.project.length > 0
              ? Strings.project_selected
              : Strings.filter_project}
          </span>
          {!isProjectFilterOpen ? (
            <FontAwesomeIcon
              id='project-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='project-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={projectFilterRef}
          state={isProjectFilterOpen ? 'open' : 'closed'}
          key='project-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipProjectClick.current = true;
              setTimeout(() => (skipProjectClick.current = false), 300);
            }
            if (reason !== 'click') {
              setProjectFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='project-filter-menu-item-input-con' className='item-input'>
            {() => (
              <input
                id='project-filter-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Project'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={projectFilterValue}
                onChange={(e) => setProjectFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredProjects().map((userList, _index) => (
            <MenuItem
              id={`project-filter-menu-item-${userList.id}`}
              key={`project-filter-menu-item-${userList.id}`}
              onClick={() => updateFilter('projectFilter', userList.id)}
              value={userList.id}
              className='project-filter-menu-item'
            >
              {renderCheckbox(userList, monthlyCompletedFilter.project)}
              {userList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='project-filter-menu-item-remove'
            className='remove-item'
            key='project-filter-all'
            onClick={() => updateFilter('projectFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const updateTypeFilter = (filterType: CompletedFilterType) => {
    const filters: UserAnalyticsMonthlyCompletedFilterType = {
      ...monthlyCompletedFilter,
      filterType: filterType,
    };
    setUserAnalyticsMonthlyCompletedFilter(filters);
  };
  const renderChartType = () => {
    return (
      <div
        id={'analytics-filter-menu-con-tasksCompleted'}
        className='analytics-item-container graph-type-container'
      >
        <Menu
          id={'analytics-filter-menu-tasksCompleted'}
          className='type-menu'
          key='type-filter-menu'
          menuButton={
            <MenuButton
              id={'analytics-filter-menu-button-tasksCompleted'}
              className='item-btn graph-type-btn filter-btn'
            >
              <span
                id={`analytics-filter-btn-titile-tasksCompleted`}
                className='item-selected filter-btn-title'
              >
                {monthlyCompletedFilter && monthlyCompletedFilter.filterType}
              </span>
              <FontAwesomeIcon
                id={`analytics-filter-chevron-icon-tasksCompleted`}
                className='drop-icon'
                icon={faChevronDown}
              />
            </MenuButton>
          }
        >
          <MenuItem
            id={`analytics-filter-item-0`}
            key={'tasksCompleted-tasks'}
            className='chart-type-filter-menu-item'
            onClick={() => {
              updateTypeFilter('task');
            }}
            disabled={monthlyCompletedFilter.filterType === 'task'}
            value='task'
          >
            <div id={`analytics-filter-item-con-0`} className='menu-con'>
              <span id={`analytics-filter-item-title-0`} className='menu-title'>
                {Strings.task}
              </span>
            </div>
          </MenuItem>
          <MenuItem
            id={`analytics-filter-item-1`}
            key={'tasksCompleted-points'}
            className='chart-type-filter-menu-item'
            onClick={() => {
              updateTypeFilter('points');
            }}
            disabled={monthlyCompletedFilter.filterType === 'points'}
            value='task'
          >
            <div id={`analytics-filter-item-con-1`} className='menu-con'>
              <span id={`analytics-filter-item-title-1`} className='menu-title'>
                {Strings.points}
              </span>
            </div>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const getDateNameForId = () => {
    if (monthlyCompletedFilter.modified.value === 'custom') {
      if (monthlyCompletedFilter.modified.start && monthlyCompletedFilter.modified.end) {
        return `${formatDateForView(monthlyCompletedFilter.modified.start)}~${formatDateForView(
          monthlyCompletedFilter.modified.end,
        )}`;
      }
      return 'Custom';
    } else {
      const data = monthlyDateFilterData.find(
        (item) => item.id === monthlyCompletedFilter.modified.value,
      );
      return data === undefined ? Strings.selected_date : data.name;
    }
  };

  const renderDateFilter = () => {
    return (
      <div
        id='completed-date-filter-con'
        className='analytics-item-container date-filter-container filter-control'
        key='completed-date-filter'
      >
        <button
          id='completed-date-filter-btn'
          className='item-btn completed-date-btn'
          ref={dateFilterRef}
          onClick={() => !skipDateClick.current && setDateFilterOpen(true)}
          key='completed-date-filter-btn'
        >
          <span id='completed-date-filter-selection' className='item-selected'>
            {getDateNameForId()}
          </span>
          {!isDateFilterOpen ? (
            <FontAwesomeIcon
              id='completed-date-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='completed-date-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>

        <ControlledMenu
          id='completed-date-filter-controlled-menu'
          className='date-menu'
          anchorRef={dateFilterRef}
          state={isDateFilterOpen ? 'open' : 'closed'}
          key='completed-date-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipDateClick.current = true;
              setTimeout(() => (skipDateClick.current = false), 300);
            }
            if (reason !== 'click') {
              setDateFilterOpen(false);
            }
          }}
        >
          {monthlyDateFilterData.map((dateList, _index) => (
            <MenuItem
              id={`completed-date-filter-menu-item-${dateList.id}`}
              key={`completed-date-filter-menu-item${dateList.id}`}
              className='date-filter-item'
              onClick={() => {
                updateDateFilter(dateList.id);
                setDateFilterOpen(false);
              }}
              disabled={dateList.id === monthlyCompletedFilter.modified.value}
              value={dateList.id}
            >
              <span>{dateList.name}</span>
              <span className='sub-title'>Filter by {dateList.name}</span>
            </MenuItem>
          ))}

          <MenuItem
            id='completed-date-filter-menu-item-custom'
            className='custom-item'
            key='completed-custom-date'
            value={null}
          >
            {renderCustomDateForCompletedTasks()}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const onChangeDateFilter = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateFilterOpen(false);
      updateDateFilter('custom', start, end);
    } else {
      setStartDateFilter(start);
      setEndDateFilter(end);
    }
  };
  const updateDateFilter = (
    dateString: TimePeriodFilterType | 'custom',
    startDate?: Date,
    endDate?: Date,
  ) => {
    let filter: UserAnalyticsMonthlyCompletedFilterType = { ...monthlyCompletedFilter };

    if (dateString === 'last6Months') {
      const firstDay = moment().subtract(5, 'months').startOf('month');
      const lastDay = moment().endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...filter,
        modified: {
          value: 'last6Months',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'last12Months') {
      const firstDay = moment().subtract(11, 'months').startOf('month');
      const lastDay = moment().endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...filter,
        modified: {
          value: 'last12Months',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'custom' && startDate && endDate) {
      log('date', formatForAnalytics(startDate), formatForAnalytics(endDate));
      filter = {
        ...filter,
        modified: {
          value: 'custom',
          start: formatForAnalytics(startDate),
          end: formatForAnalytics(endDate),
        },
      };
    }
    setUserAnalyticsMonthlyCompletedFilter(filter);
  };
  const renderCustomDateForCompletedTasks = () => {
    return (
      <div
        id='completed-date-filter-custom-date-con'
        className='analytics-item-container custom-date-container'
        key='custom-date-con'
      >
        <div id='completed-date-filter-date-picker'>
          <DatePicker
            selected={startDateFilter}
            onChange={onChangeDateFilter}
            startDate={startDateFilter}
            endDate={endDateFilter}
            selectsRange
            selectsEnd={true}
            selectsStart={true}
            shouldCloseOnSelect={false}
            id='custom-date-id'
            popperPlacement='bottom-end'
            calendarClassName='date-range-calendar-con'
            wrapperClassName='custom-date'
            customInput={
              <div>
                <FontAwesomeIcon size='lg' className='calendar-icon' icon={faCalendarPlus} /> Custom
                Date
              </div>
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div id='analytics-task-completed-graph-heading-con' className='graph-heading'>
        {renderAssigneeFilter()}
        {renderProjectFilter()}
        {renderDateFilter()}
        {renderChartType()}
      </div>
      <div id='analytics-task-completed-graph-con' className='graph-con'>
        <div
          className={`chart ${monthlyCompletedFilter.filterType === 'points' ? 'show' : 'hide'}`}
          ref={pointsChartDiv}
        />
        <div
          className={`chart ${monthlyCompletedFilter.filterType === 'task' ? 'show' : 'hide'}`}
          ref={tasksChartDiv}
        />
      </div>
    </Fragment>
  );
}
