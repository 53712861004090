/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useCallback, useState, useRef, Fragment } from 'preact/compat';
import {
  faChevronDown,
  faChevronUp,
  faCalendarPlus,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { log } from 'utils';
import {
  isHomeScreen,
  isHomeCompletedScreen,
  isProjectTaskScreen,
  isProjectCompletedScreen,
  isUserScreen,
  isUserCompletedScreen,
  formatDateForView,
  isRecurringTasksScreen,
  isProjectTemplateScreen,
} from 'utils/task';
import { Strings } from 'resources';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  getUserEmail,
  getUserId,
  getUserName as getUserNameFromStorage,
  isAdmin,
} from 'services/AuthService';
import { Menu, MenuItem, MenuButton, FocusableItem, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import _ from 'underscore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Animations from 'animations';
import { getUniqueTags, highlightText, isArrayIncluded, sortTagFilterData } from 'utils/helpers';
import InfoIcon from 'assets/images/info.png';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import { triggerDataSelector } from 'recoil/TriggerState';
import {
  projectBacklogTasksState,
  projectCompletedTaskDataState,
  projectCompletedTaskSizeState,
  projectDataSelector,
  projectFocusTasksState,
} from 'recoil/ProjectState';
import { templateDataSelector } from 'recoil/TemplateState';
import {
  navigationDataSelector,
  showEditProjectModalState,
  showUserProfileModalState,
} from 'recoil/NavigationState';
import {
  userBacklogTasksState,
  userCompletedTaskDataState,
  userCompletedTaskSizeState,
  userDataSelector,
  userFocusTasksState,
} from 'recoil/UserState';
import { allSizes } from 'utils/size';
import {
  assigneeFilterSelector,
  completedTasksFilterSelector,
  dateFilterSelector,
  openTaskCountSelector,
  projectFilterSelector,
  searchTextFilterSelector,
  sizeFilterSelector,
  tagFilterSelector,
  taskDataStateSelector,
  taskIsLoadingSelector,
} from 'recoil/TaskState';
import { getCompletedTasks, updateTaskFilter } from 'recoil/TaskState/update';
import { recurringTaskDataSelector } from 'recoil/RecurringTaskState';
import { playModalOpenSound } from 'triggers/utils/sound';
import SearchInput from 'components/UI/SearchInput';

const dateFilterData = [
  { id: 'today', name: 'Today' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'currentMonth', name: 'Current Month' },
  { id: 'lastMonth', name: 'Last Month' },
  { id: 'yearToDate', name: 'Year to Date' },
  { id: 'previousYear', name: 'Previous Year' },
  { id: 'all', name: 'All Time' },
];

export default function TopTitleBar(props: TopTitleBarPropsType) {
  const { stateType, readOnly, idName } = props;
  const loggedInUserId = getUserId();
  const userName = getUserNameFromStorage();

  const accountData = useRecoilValue(accountDataSelector);
  const triggerData = useRecoilValue(triggerDataSelector);
  const setUserFocusTasks = useSetRecoilState(userFocusTasksState);
  const setUserBacklogTasks = useSetRecoilState(userBacklogTasksState);
  const setUserCompletedTaskData = useSetRecoilState(userCompletedTaskDataState);
  const setUserCompletedSizeData = useSetRecoilState(userCompletedTaskSizeState);
  const setProjectFocusTasks = useSetRecoilState(projectFocusTasksState);
  const setProjectBacklogTasks = useSetRecoilState(projectBacklogTasksState);
  const setProjectCompletedTaskData = useSetRecoilState(projectCompletedTaskDataState);
  const setProjectCompletedSizeData = useSetRecoilState(projectCompletedTaskSizeState);

  const setShowUserProfileModal = useSetRecoilState(showUserProfileModalState);
  const setShowEditProjectModal = useSetRecoilState(showEditProjectModalState);

  const projectData = useRecoilValue(projectDataSelector);
  const recurringTaskData = useRecoilValue(recurringTaskDataSelector);
  const templateData = useRecoilValue(templateDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const taskData = useRecoilValue(taskDataStateSelector);
  const userData = useRecoilValue(userDataSelector);

  const assigneeFilterRef = useRef(null);
  const skipAssigneeClick = useRef(false);
  const [isAssigneeFilterOpen, setAssigneeFilterOpen] = useState(false);

  const createdByFilterRef = useRef(null);
  const skipCreatedByClick = useRef(false);
  const [isCreatedByFilterOpen, setCreatedByFilterOpen] = useState(false);

  const projectFilterRef = useRef(null);
  const skipProjectClick = useRef(false);
  const [isProjectFilterOpen, setProjectFilterOpen] = useState(false);

  const tagFilterRef = useRef(null);
  const skipTagClick = useRef(false);
  const [isTagFilterOpen, setTagFilterOpen] = useState(false);

  const dateFilterRef = useRef(null);
  const skipDateClick = useRef(false);
  const [isDateFilterOpen, setDateFilterOpen] = useState(false);

  const completedTaskFilterDateRef = useRef(null);
  const skipCompletedDateClick = useRef(false);
  const [isCompletedTaskFilterOpen, setCompletedTaskFilterOpen] = useState(false);

  const getStateData = useCallback(() => {
    if (stateType === 'PROJECT') return projectData;
    else if (stateType === 'INDIVIDUAL') return userData;
    else if (stateType === 'RECURRING') return recurringTaskData;
    else if (stateType === 'TEMPLATE') return templateData;
    else return taskData;
  }, [projectData, taskData, userData, recurringTaskData, templateData]);

  const modifyDataBasedOnReadOnly = (data, readOnly) => {
    if (readOnly) {
      return data;
    }

    return data.slice(0, -1);
  };

  const modifiedDateFilterData = modifyDataBasedOnReadOnly(dateFilterData, readOnly);

  const loggedInUser: UserFieldType = {
    id: loggedInUserId,
    name: userName,
    display: userName,
    email: getUserEmail(),
    openCount: 0,
    completedCount: 0,
    profileImage: accountData.account ? accountData.account?.profileImage : undefined,
  };
  const allUsers = accountData.allUsers
    ? [
        loggedInUser,
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...accountData.allUsers,
      ]
    : [loggedInUser];
  const allProjects =
    isUserScreen(navigationData.currentRoute) || isUserCompletedScreen(navigationData.currentRoute)
      ? userData.allUserProjects
      : projectData.allProjects;
  const projectCompletedSize =
    isUserScreen(navigationData.currentRoute) || isUserCompletedScreen(navigationData.currentRoute)
      ? userData.COMPLETED.size
      : projectData.COMPLETED.size;
  const currentProjectId = projectData.currentProjectId;
  const currentUserId = userData.currentUserId;
  const allTemplates = templateData.allTemplates;
  const currentTemplateId = templateData?.currentTemplateId;
  const navigate = useNavigate();

  const getSizeDescriptionForId = (id: SizeOptionsType) => {
    const index = allSizes.findIndex((size) => size.id === id);

    if (index === -1) return '';
    else return allSizes[index].desc;
  };

  /*
   * Account settings
   *
   * returns Object
   */
  const accountSettings = accountData.accountSettings;

  useEffect(() => {
    let id;
    if (stateType === 'PROJECT') id = currentProjectId;
    else if (stateType === 'INDIVIDUAL') id = currentUserId;
    if (tagFilter && tagFilter.length > 0) {
      updateTaskFilter('tagFilter', null, stateType, id);
    }
  }, [navigationData.currentRoute]);

  const sizeFilter = useRecoilValue(sizeFilterSelector(stateType));

  const dateFilter = useRecoilValue(dateFilterSelector(stateType));

  const tagFilter = useRecoilValue(tagFilterSelector(stateType));

  const assigneeFilter = useRecoilValue(assigneeFilterSelector(stateType));

  const createdByFilter = recurringTaskData?.taskFilters?.createdByFilter;

  const projectFilter = useRecoilValue(projectFilterSelector(stateType));

  const completedTasksFilter = useRecoilValue(completedTasksFilterSelector(stateType));

  const searchTextFilter = useRecoilValue(searchTextFilterSelector(stateType));

  const openCount = useRecoilValue(openTaskCountSelector(stateType));

  const isFocusLoading = useRecoilValue(
    taskIsLoadingSelector({ dataType: 'FOCUS', stateType: stateType }),
  );
  const isBacklogLoading = useRecoilValue(
    taskIsLoadingSelector({ dataType: 'BACKLOG', stateType: stateType }),
  );

  const getProjectName = () => {
    const index = getProjectIndex();

    if (index === -1) return '';

    return allProjects ? allProjects[index].display : '';
  };

  const getUserName = () => {
    const index = getUserIndex();

    if (index === -1) return '';

    return allUsers[index].display;
  };

  const getUserOpenCount = () => {
    const index = getUserIndex();

    if (index === -1) return '';

    return allUsers[index].openCount;
  };

  const getUserCompletedCount = () => {
    const index = getUserIndex();

    if (index === -1) return '';

    return projectCompletedSize; // allUsers[index].completedCount;
  };

  const getUserIndex = () => {
    if (!currentUserId) return -1;

    return allUsers.findIndex((item) => item.id === currentUserId);
  };

  const getTemplateIndex = () => {
    if (!currentTemplateId || allTemplates === null) return -1;

    return allTemplates.findIndex((item) => item.id === currentTemplateId);
  };

  const getTemplateUsers = () => {
    const index = getTemplateIndex();
    if (index === -1 || allTemplates === null) return [];
    return allTemplates[index].users;
  };

  const getProjectIndex = () => {
    if (!currentProjectId) return -1;

    return allProjects ? allProjects.findIndex((item) => item.id === currentProjectId) : -1;
  };

  const getProjectUsers = () => {
    const index = getProjectIndex();

    if (index === -1) return [];

    return allProjects ? allProjects[index].users : [];
  };

  const getProjectOpenCount = () => {
    const index = getProjectIndex();

    if (index === -1) return 0;

    return allProjects ? allProjects[index].openCount : 0;
  };

  const getProjectCompletedCount = () => {
    const index = getProjectIndex();

    if (index === -1) return 0;

    return projectCompletedSize;
    // return allProjects ? allProjects[index].completedCount : 0;
  };

  const getProjectTemplateIndex = () => {
    if (!currentTemplateId || allTemplates === null) return -1;

    return allTemplates.findIndex((item) => item.id === currentTemplateId);
  };

  const getProjectTemplateName = () => {
    const index = getProjectTemplateIndex();

    if (index === -1 || allTemplates === null) return '';

    return allTemplates[index].name;
  };

  const getProjectTemplateOpenCount = () => {
    const index = getProjectTemplateIndex();

    if (index === -1 || allTemplates === null) return 0;

    return allTemplates[index].tasksCount;
  };

  const openMyCompletedTasks = () => {
    navigate(`/completed`);
  };

  const openMyTasks = () => {
    navigate(`/`);
  };

  const openMemberScreen = () => {
    if (!isAdmin()) return;

    if (isUserScreen(navigationData.currentRoute)) return;

    setUserFocusTasks({
      data: [],
      filteredData: [],
      isLoading: true,
      abortController: userData.FOCUS.abortController,
      size: 0,
    });

    setUserBacklogTasks({
      data: [],
      filteredData: [],
      isLoading: true,
      abortController: userData.BACKLOG.abortController,
      size: 0,
    });
    setUserCompletedSizeData(null);
    setProjectCompletedSizeData(null);
    navigate(`/user/${currentUserId}`);
  };

  const openMemberCompletedScreen = () => {
    if (!isAdmin()) return;

    if (isUserCompletedScreen(navigationData.currentRoute)) return;
    setUserCompletedTaskData([]);
    navigate(`/user/${currentUserId}/completed`);
  };

  const openProjectScreen = () => {
    if (isProjectTaskScreen(navigationData.currentRoute)) return;

    setProjectFocusTasks({
      data: [],
      filteredData: [],
      isLoading: true,
      abortController: projectData.FOCUS.abortController,
      size: 0,
    });

    setProjectBacklogTasks({
      data: [],
      filteredData: [],
      isLoading: true,
      abortController: projectData.BACKLOG.abortController,
      size: 0,
    });
    setUserCompletedSizeData(null);
    setProjectCompletedSizeData(null);
    navigate(`/project/${currentProjectId}`);
  };

  const openProjectCompletedScreen = () => {
    if (isProjectCompletedScreen(navigationData.currentRoute)) return;
    setProjectCompletedTaskData([]);
    navigate(`/project/${currentProjectId}/completed`);
  };

  const openProfileModal = () => {
    setShowUserProfileModal(true);
  };

  const renderLeftTitle = () => {
    const { currentRoute } = navigationData;

    if (isRecurringTasksScreen(currentRoute)) {
      return (
        <Fragment>
          <h3 className='big-title profile' id='profile-title'>
            {triggerData?.riveTriggers?.bgAnimationLow && (
              <Animations
                className='rive-big-title'
                fileName='title'
                animations={
                  accountData?.accountSettings?.userAnimationSettings?.bgAnimationLow
                    ? ['loop', 'start']
                    : ['idle']
                }
              />
            )}
            <span className='big-title__title'>Recurring Tasks</span>
          </h3>
        </Fragment>
      );
    } else if (isHomeScreen(currentRoute) || isHomeCompletedScreen(currentRoute)) {
      return (
        <Fragment>
          <h3 className='big-title profile' id='profile-title' onClick={openProfileModal}>
            {accountData?.account?.name && triggerData?.riveTriggers?.bgAnimationLow && (
              <Animations
                className='rive-big-title'
                fileName='title'
                animations={
                  accountData?.accountSettings?.userAnimationSettings?.bgAnimationLow
                    ? ['loop', 'start']
                    : ['idle']
                }
              />
            )}
            <span className='big-title__title'>{accountData?.account?.name || ''}</span>
          </h3>
          <span id='open-tasks-title' onClick={() => openMyTasks()} className='status-title'>
            {Strings.open}{' '}
            <span id='open-tasks-count' className='status-count open'>
              {isFocusLoading || isBacklogLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                openCount
              )}
            </span>
          </span>

          <span
            id='completed-tasks-title'
            onClick={() => openMyCompletedTasks()}
            className={`status-title ${isHomeCompletedScreen(currentRoute) ? 'selected' : ''}`}
          >
            {Strings.completed}{' '}
            <span id='completed-tasks-count' className='status-count completed'>
              {taskData.COMPLETED.size === null ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                `${taskData.COMPLETED.size}`
              )}
            </span>
          </span>
        </Fragment>
      );
    } else if (isUserScreen(currentRoute) || isUserCompletedScreen(currentRoute)) {
      return (
        <Fragment>
          <h3 className='big-title' id='username-title'>
            {getUserName()}
          </h3>
          <span
            onClick={() => openMemberScreen()}
            className='status-title'
            id='user-screen-open-tasks-title'
          >
            {Strings.open}{' '}
            <span className='status-count open' id='user-screen-open-tasks-count'>
              {isFocusLoading || isBacklogLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                openCount
              )}
              {/* {getUserOpenCount()} */}
            </span>
          </span>
          <span
            id='user-screen-completed-tasks-title'
            onClick={() => openMemberCompletedScreen()}
            className={`status-title ${isUserCompletedScreen(currentRoute) ? 'selected' : ''}`}
          >
            {Strings.completed}{' '}
            <span id='user-screen-completed-tasks-count' className='status-count completed'>
              {getUserCompletedCount() === null ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                getUserCompletedCount()
              )}
            </span>
          </span>
        </Fragment>
      );
    } else if (isProjectTaskScreen(currentRoute) || isProjectCompletedScreen(currentRoute)) {
      return (
        <Fragment>
          <h3 id='project-title' className='big-title'>
            {getProjectName() && triggerData?.riveTriggers?.bgAnimationLow && (
              <Animations
                className='rive-big-title'
                fileName='title'
                animations={
                  accountData?.accountSettings?.userAnimationSettings?.bgAnimationLow
                    ? ['loop', 'start']
                    : ['idle']
                }
              />
            )}
            <span className='big-title__title'>{getProjectName()}</span>
          </h3>
          <span
            id='project-screen-open-tasks-title'
            onClick={() => openProjectScreen()}
            className='status-title'
          >
            {Strings.open}{' '}
            <span className='status-count open' id='project-screen-open-tasks-count'>
              {isFocusLoading || isBacklogLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                openCount
              )}
              {/* {getProjectOpenCount()} */}
            </span>
          </span>
          <span
            id='project-screen-completed-tasks-title'
            onClick={() => openProjectCompletedScreen()}
            className={`status-title ${isProjectCompletedScreen(currentRoute) ? 'selected' : ''}`}
          >
            {Strings.completed}{' '}
            <span id='project-screen-completed-tasks-count' className='status-count completed'>
              {getProjectCompletedCount() === null ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                getProjectCompletedCount()
              )}
            </span>
          </span>
        </Fragment>
      );
    } else if (isProjectTemplateScreen(currentRoute)) {
      return (
        <Fragment>
          <h3 id='project-template-title' className='big-title'>
            {getProjectTemplateName() && triggerData?.riveTriggers?.bgAnimationLow && (
              <Animations
                className='rive-big-title'
                fileName='title'
                animations={
                  accountData?.accountSettings?.userAnimationSettings?.bgAnimationLow
                    ? ['loop', 'start']
                    : ['idle']
                }
              />
            )}
            <span className='big-title__title'>{getProjectTemplateName()}</span>
          </h3>
          <span id='project-template-screen-open-tasks-title' className='status-title'>
            {Strings.open}{' '}
            <span className='status-count open' id='project-template-screen-open-tasks-count'>
              {isFocusLoading || isBacklogLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                openCount
              )}{' '}
              {/* {getProjectTemplateOpenCount()} */}
            </span>
          </span>
        </Fragment>
      );
    }
    return null;
  };

  const updateFilter = (name: TaskFiltersType, value: string | Array<string> | null) => {
    let id;

    if (name === 'createdByFilter') {
      let assigneeList: any = null;
      if (value !== null) {
        assigneeList = [value];
        if (createdByFilter && createdByFilter.length > 0) {
          const index = createdByFilter.findIndex((item) => item === value);
          if (index === -1) {
            assigneeList = [...createdByFilter, ...assigneeList];
          } else {
            assigneeList = createdByFilter.filter((item, ind) => item !== value);
            if (assigneeList.length === 0) {
              assigneeList = null;
            }
          }
        }
      }
      updateTaskFilter(name, assigneeList, stateType, id);
      return;
    }
    if (name === 'assigneeFilter') {
      if (stateType === 'PROJECT') {
        id = currentProjectId;
      }

      let assigneeList: any = null;
      if (value !== null) {
        assigneeList = [value];
        if (assigneeFilter && assigneeFilter.length > 0) {
          const index = assigneeFilter.findIndex((item) => item === value);
          if (index === -1) {
            assigneeList = [...assigneeFilter, ...assigneeList];
          } else {
            assigneeList = assigneeFilter.filter((item, ind) => item !== value);
            if (assigneeList.length === 0) {
              assigneeList = null;
            }
          }
        }
      }
      updateTaskFilter(name, assigneeList, stateType, id);
      return;
    } else if (name === 'tagFilter') {
      if (stateType === 'PROJECT') id = currentProjectId;
      else if (stateType === 'INDIVIDUAL') id = currentUserId;
      let tagList: Array<string> | null = null;
      if (value !== null && Array.isArray(value)) {
        tagList = value;
      }
      updateTaskFilter(name, tagList, stateType, id);
      return;
    } else if (name === 'projectFilter') {
      let projectList: any = null;
      if (value !== null) {
        projectList = [value];
        if (projectFilter && projectFilter.length > 0) {
          const index = projectFilter.findIndex((item) => item === value);
          if (index === -1) {
            projectList = [...projectFilter, ...projectList];
          } else {
            const tempList = projectFilter.filter((item) => item !== value);
            projectList = [...tempList];
            if (projectList.length === 0) {
              projectList = null;
            }
          }
        }
      }
      updateTaskFilter(name, projectList, stateType, id);
      return;
    }
    updateTaskFilter(name, value, stateType, id);
  };

  const renderSizeFilter = () => {
    return (
      <div
        id='size-filter-container'
        className='item-container size-container filter-control'
        key='size-filter'
      >
        <Menu
          style={{ width: '50px' }}
          id='size-menu'
          className='item-menu'
          menuButton={
            <MenuButton
              id='size-filter-menu-button'
              className={`item-btn size-btn size-${sizeFilter}`}
            >
              <span className='item-selected'>
                {sizeFilter ? getSizeDescriptionForId(sizeFilter) : 'Filter by Size'}
              </span>
              <FontAwesomeIcon
                id='size-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
              <FontAwesomeIcon
                id='size-filter-up-icon'
                className='drop-icon up'
                icon={faChevronUp}
              />
            </MenuButton>
          }
        >
          <MenuItem
            id='size-filter-item-all'
            key='size-all'
            onClick={() => updateFilter('sizeFilter', null)}
            value={null}
          >
            <span>All Sizes</span>
            <span className='sub-title'>Filter by all Sizes</span>
          </MenuItem>
          {allSizes.map((sizeList, _index) => (
            <MenuItem
              id={`size-filter-item-${sizeList.id}`}
              key={`size-filter-item-${sizeList.id}`}
              onClick={() => updateFilter('sizeFilter', sizeList.id)}
              disabled={sizeList.id === sizeFilter}
              value={sizeList.id}
            >
              <span>{sizeList.desc}</span>
              <span className='sub-title'>Filter by size {sizeList.desc}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const [tagFilterValue, setTagFilterValue] = useState('');
  const filteredTags = useCallback(() => {
    let filteredTags: TagFilterType[] = [];
    const filter = tagFilterValue.trim().toUpperCase();
    let allTags: TagFilterType[] = [];
    const data = getStateData();
    if (readOnly) {
      allTags = getUniqueTags(data.unFilteredList.COMPLETED);
    } else {
      allTags = getUniqueTags([...data.unFilteredList.FOCUS, ...data.unFilteredList.BACKLOG]);
    }
    allTags = sortTagFilterData(allTags, true);
    allTags.forEach((tag) => {
      const name = tag.name.toUpperCase();

      if (name.includes(filter)) filteredTags.push(tag);
    });
    if (tagFilter !== null && tagFilter.length > 0) {
      const seletectedTags = filteredTags.filter((item) => isArrayIncluded(item.ids, tagFilter));
      const unSelectedTags = filteredTags.filter((item) => !isArrayIncluded(item.ids, tagFilter));
      filteredTags = [...seletectedTags, ...unSelectedTags];
    }
    return filteredTags;
  }, [tagFilterValue, getStateData().unFilteredList, tagFilter]);

  const renderTagFilter = () => {
    return (
      <div
        id='tag-filter-con'
        className='item-container tag-container filter-control'
        key='tag-filter'
      >
        <button
          id='tag-filter-menu-button'
          className='item-btn tag-btn'
          ref={tagFilterRef}
          onClick={() => !skipTagClick.current && setTagFilterOpen(true)}
          key='tag-filter-btn'
        >
          <span className='item-selected'>
            {tagFilter && tagFilter.length > 0 ? getTagNameForSelected() : Strings.filter_tag}
          </span>
          {!isTagFilterOpen ? (
            <FontAwesomeIcon
              id='tag-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon id='tag-filter-up-icon' className='drop-icon up' icon={faChevronUp} />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={tagFilterRef}
          state={isTagFilterOpen ? 'open' : 'closed'}
          key='tag-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipTagClick.current = true;
              setTimeout(() => (skipTagClick.current = false), 300);
            }
            if (reason !== 'click') {
              setTagFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='tag-filter-menu-item-input-con' className='item-input'>
            {({ ref }) => (
              <input
                id='tag-filter-menu-input'
                // ref={ref}
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Tag'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={tagFilterValue}
                onChange={(e) => setTagFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredTags().map((tagList, _index) => (
            <MenuItem
              id={`tag-filter-menu-item-${tagList.name}`}
              key={`tag-filter-menu-item-${tagList.name}`}
              onClick={() => updateFilter('tagFilter', getTagFilterIds(tagList))}
              value={tagList.name}
              className='tag-filter-menu-item'
            >
              {renderTagsCheckbox(tagList, tagFilter)}
              {tagList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='tag-filter-menu-item-remove'
            className='remove-item'
            key='tag-filter-all'
            onClick={() => updateFilter('tagFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const getTagFilterIds = (tagList: TagFilterType) => {
    const res: string[] = [];
    let result: string[] | null = [];
    let allTags: TagObjectType[] = [];
    const data = getStateData();
    if (readOnly) {
      allTags = data.unFilteredList.COMPLETED;
    } else {
      allTags = [...data.unFilteredList.FOCUS, ...data.unFilteredList.BACKLOG];
    }
    allTags.map((item) => {
      if (item.name === tagList.name && !res.includes(item.id)) {
        res.push(item.id);
      }
    });
    if (tagFilter && tagFilter.length > 0) {
      if (isArrayIncluded(tagList.ids, tagFilter)) {
        result = tagFilter.filter((tagId) => {
          return !tagList.ids.includes(tagId);
        });
      } else {
        result = [...tagFilter, ...res];
      }
    } else {
      result = res;
    }
    if (result && result.length === 0) result = null;
    return result;
  };

  const getUserNameForId = (_id) => {
    return 'Selected User';
  };

  const getProjectNameForId = (_id) => {
    return 'Selected Project';
  };

  const [createdByFilterValue, setCreatedByFilterValue] = useState('');
  const filteredCreatedBy = useCallback(() => {
    const projectUsers: UserFieldType[] | undefined =
      stateType === 'RECURRING' ? allUsers : getProjectUsers();
    let filteredUsers: UserFieldType[] = [];
    const filter = createdByFilterValue.trim().toUpperCase();

    projectUsers?.forEach((user) => {
      const name = user.name?.toUpperCase();

      if (name?.includes(filter)) filteredUsers.push(user);
    });
    if (createdByFilter !== null && createdByFilter !== undefined && createdByFilter.length > 0) {
      const seletectedUsers = filteredUsers.filter((item) => createdByFilter.includes(item.id));
      const unSelectedUsers = filteredUsers.filter((item) => !createdByFilter.includes(item.id));
      filteredUsers = [...seletectedUsers, ...unSelectedUsers];
    }
    return filteredUsers;
  }, [createdByFilterValue, getProjectUsers(), stateType, createdByFilter]);

  const [assigneeFilterValue, setAssigneeFilterValue] = useState('');
  const filteredUsers = useCallback(() => {
    const projectUsers =
      stateType === 'RECURRING'
        ? allUsers
        : stateType === 'TEMPLATE'
          ? getTemplateUsers()
          : getProjectUsers();
    let filteredUsers: UserFieldType[] = [];
    const filter = assigneeFilterValue.trim().toUpperCase();

    projectUsers?.forEach((user) => {
      const name = user.name?.toUpperCase();

      if (name?.includes(filter)) filteredUsers.push(user);
    });
    if (assigneeFilter !== null && assigneeFilter.length > 0) {
      const seletectedUsers = filteredUsers.filter((item) => assigneeFilter.includes(item.id));
      const unSelectedUsers = filteredUsers.filter((item) => !assigneeFilter.includes(item.id));
      filteredUsers = [...seletectedUsers, ...unSelectedUsers];
    }
    return filteredUsers;
  }, [assigneeFilterValue, getProjectUsers(), getTemplateUsers(), stateType]);

  const renderAssigneeFilter = () => {
    return (
      <div
        id='assignee-filter-con'
        className='item-container assignee-container filter-control'
        key='assignee-filter'
      >
        <button
          id='assignee-filter-menu-button'
          className='item-btn assignee-btn'
          ref={assigneeFilterRef}
          onClick={() => !skipAssigneeClick.current && setAssigneeFilterOpen(true)}
          key='assignee-filter-btn'
        >
          <span className='item-selected'>
            {assigneeFilter && assigneeFilter.length > 0
              ? getUserNameForId(assigneeFilter)
              : Strings.filter_assignee}
          </span>
          {!isAssigneeFilterOpen ? (
            <FontAwesomeIcon
              id='assignee-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='assignee-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={assigneeFilterRef}
          state={isAssigneeFilterOpen ? 'open' : 'closed'}
          key='assignee-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipAssigneeClick.current = true;
              setTimeout(() => (skipAssigneeClick.current = false), 300);
            }
            if (reason !== 'click') {
              setAssigneeFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='assignee-filter-menu-item-input-con' className='item-input'>
            {({ ref }) => (
              <input
                id='assignee-filter-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Assignee'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={assigneeFilterValue}
                onChange={(e) => setAssigneeFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredUsers().map((userList, _index) => (
            <MenuItem
              id={`assignee-filter-menu-item-${userList.id}`}
              key={`assignee-filter-menu-item-${userList.id}`}
              onClick={() => updateFilter('assigneeFilter', userList.id)}
              value={userList.id}
              className='assignee-filter-menu-item'
            >
              {renderCheckbox(userList, assigneeFilter)}
              {userList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='assignee-filter-menu-item-unassigned'
            className='assignee-filter-menu-item unassigned-item'
            key='assignee-filter-unassigned'
            onClick={() => updateFilter('assigneeFilter', 'UNASSIGNED')}
            value={null}
          >
            {renderCheckbox({ id: 'UNASSIGNED' }, assigneeFilter)}
            Unassigned
          </MenuItem>
          <MenuItem
            id='assignee-filter-menu-item-remove'
            className='remove-item'
            key='assignee-filter-all'
            onClick={() => updateFilter('assigneeFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const renderCreatedByFilter = () => {
    return (
      <div
        id='created-by-filter-con'
        className='item-container assignee-container filter-control'
        key='created-by-filter'
      >
        <button
          id='created-by-filter-menu-button'
          className='item-btn assignee-btn'
          ref={createdByFilterRef}
          onClick={() => !skipCreatedByClick.current && setCreatedByFilterOpen(true)}
          key='created-by-filter-btn'
        >
          <span className='item-selected'>
            {createdByFilter && createdByFilter.length > 0
              ? getUserNameForId(createdByFilter)
              : 'Filter by Created by'}
          </span>
          {!isCreatedByFilterOpen ? (
            <FontAwesomeIcon
              id='created-by-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='created-by-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={createdByFilterRef}
          state={isCreatedByFilterOpen ? 'open' : 'closed'}
          key='assignee-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipCreatedByClick.current = true;
              setTimeout(() => (skipCreatedByClick.current = false), 300);
            }
            if (reason !== 'click') {
              setCreatedByFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='assignee-filter-menu-item-input-con' className='item-input'>
            {({ ref }) => (
              <input
                id='created-by-filter-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Assignee'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={createdByFilterValue}
                onChange={(e) => setCreatedByFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredCreatedBy().map((userList, _index) => (
            <MenuItem
              id={`created-by-filter-menu-item-${userList.id}`}
              key={`created-by-${userList.id}`}
              onClick={() => updateFilter('createdByFilter', userList.id)}
              value={userList.id}
              className='assignee-filter-menu-item'
            >
              {renderCheckbox(userList, createdByFilter)}
              {userList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='assignee-filter-menu-item-remove'
            className='remove-item'
            key='all'
            onClick={() => updateFilter('createdByFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const [projectFilterValue, setProjectFilterValue] = useState('');
  const filteredProjects = useCallback(() => {
    const projects = allProjects ? allProjects.filter((proj) => proj.archived !== true) : [];
    let filteredProjects: ProjectObjectType[] = [];
    const filter = projectFilterValue.trim().toUpperCase().replace('!', '');

    projects.forEach((proj) => {
      const name = proj.name?.toUpperCase();

      if (name?.includes(filter)) filteredProjects.push(proj);
    });
    if (projectFilter !== null && projectFilter.length > 0) {
      const seletectedProjects = filteredProjects.filter((item) => projectFilter.includes(item.id));
      const unSelectedProjects = filteredProjects.filter(
        (item) => !projectFilter.includes(item.id),
      );
      filteredProjects = [...seletectedProjects, ...unSelectedProjects];
    }
    return filteredProjects;
  }, [projectFilterValue, allProjects, projectFilter]);

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = (item: UserFieldType | ProjectObjectType, filter?: string[] | null) => {
    return (
      <div className='checkbox-con' key={`checkbox-con-${item.name}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          checked={filter && filter.length > 0 ? filter.includes(item.id) : false}
          className='inp-cbx'
          id={`checkbox-${item.name}`}
          type='checkbox'
          onChange={() => {}}
          style={{ display: 'none' }}
        />

        <label className='cbx'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    );
  };

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderTagsCheckbox = (item: TagFilterType, filter?: string[] | null) => {
    return (
      <div className='checkbox-con' key={`checkbox-con-${item.name}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          checked={filter && filter.length > 0 ? isArrayIncluded(item.ids, filter) : false}
          className='inp-cbx'
          id={`checkbox-${item.name}`}
          type='checkbox'
          onChange={() => {}}
          style={{ display: 'none' }}
        />
        <label className='cbx'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    );
  };

  const renderProjectFilter = () => {
    return (
      <div
        id='project-filter-con'
        className='item-container project-container filter-control'
        key='project-filter'
      >
        <button
          id='project-filter-menu-button'
          className='item-btn project-btn'
          ref={projectFilterRef}
          onClick={() => !skipProjectClick.current && setProjectFilterOpen(true)}
          key='project-filter-btn'
        >
          <span className='item-selected'>
            {projectFilter && projectFilter.length > 0
              ? getProjectNameForId(projectFilter)
              : Strings.filter_project}
          </span>
          {!isProjectFilterOpen ? (
            <FontAwesomeIcon
              id='project-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='project-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={projectFilterRef}
          key='project-filter-menu'
          state={isProjectFilterOpen ? 'open' : 'closed'}
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipProjectClick.current = true;
              setTimeout(() => (skipProjectClick.current = false), 300);
            }
            if (reason !== 'click') {
              setProjectFilterOpen(false);
            }
          }}
        >
          <FocusableItem id='project-filter-menu-item-input-con' className='item-input'>
            {({ ref }) => (
              <input
                id='project-filter-menu-input'
                ref={(input) => input !== null && input.focus()}
                type='text'
                placeholder='Project'
                autoComplete='off'
                data-lpignore='true'
                data-form-type='other'
                value={projectFilterValue}
                onChange={(e) => setProjectFilterValue((e.target as HTMLInputElement).value)}
                onKeyDown={highlightText}
              />
            )}
          </FocusableItem>
          {filteredProjects().map((projectList, _index) => (
            <MenuItem
              id={`project-filter-menu-item-${projectList.id}`}
              className='project-filter-menu-item'
              key={`project-filter-menu-item-${projectList.id}`}
              onClick={() => updateFilter('projectFilter', projectList.id)}
              value={projectList.id}
            >
              {renderCheckbox(projectList, projectFilter)}
              {projectList.name}
            </MenuItem>
          ))}
          <MenuItem
            id='project-filter-menu-item-remove'
            className='remove-item'
            key='project-filter-menu-item-all'
            onClick={() => updateFilter('projectFilter', null)}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const getDateNameForId = useCallback(() => {
    const index = modifiedDateFilterData.findIndex((date) => date.id === dateFilter);

    if (index !== -1) return modifiedDateFilterData[index].name;

    const dateInFilter = moment(dateFilter, 'MM-DD-YYYY');

    if (!dateInFilter.isValid()) return 'Filter by Date';

    return dateFilter;
  }, [dateFilter]);

  const getDateNameForIdCompletedTask = useCallback(() => {
    const index = modifiedDateFilterData.findIndex((date) => date.id === completedTasksFilter);

    if (index !== -1) return modifiedDateFilterData[index].name;

    const dateInFilter = moment(completedTasksFilter, 'MM-DD-YYYY');

    if (!dateInFilter.isValid()) return 'Filter by Date';

    return completedTasksFilter;
  }, [completedTasksFilter]);

  const getTagNameForSelected = useCallback(() => {
    return 'Selected Tag';
  }, [tagFilter]);

  const renderDateFilter = () => {
    return (
      <div
        id='date-filter-con'
        className='item-container date-filter-container filter-control'
        key='date-filter'
      >
        <button
          id='date-filter-btn'
          className='item-btn date-btn'
          ref={dateFilterRef}
          onClick={() => !skipDateClick.current && setDateFilterOpen(true)}
          key='date-filter-btn'
        >
          <span id='date-filter-selection' className='item-selected'>
            {dateFilter ? getDateNameForId() : 'Filter by Due Date'}
          </span>
          {isDateFilterOpen ? (
            <FontAwesomeIcon id='date-filter-up-icon' className='drop-icon up' icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon
              id='date-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          )}
        </button>

        <ControlledMenu
          id='date-filter-controlled-menu'
          className='date-menu'
          anchorRef={dateFilterRef}
          key='date-filter-menu'
          state={isDateFilterOpen ? 'open' : 'closed'}
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipDateClick.current = true;
              setTimeout(() => (skipDateClick.current = false), 300);
            }
            if (reason !== 'click') {
              setDateFilterOpen(false);
            }
          }}
        >
          {modifiedDateFilterData.map((dateList, _index) => (
            <MenuItem
              id={`date-filter-menu-item-${dateList.id}`}
              key={`date-filter-menu-item-${dateList.id}`}
              onClick={() => {
                updateFilter('dateFilter', dateList.id);
                setDateFilterOpen(false);
              }}
              disabled={dateList.id === dateFilter}
              value={dateList.id}
            >
              <span>{dateList.name}</span>
              <span className='sub-title'>Filter by {dateList.name}</span>
            </MenuItem>
          ))}

          <MenuItem
            id='date-filter-menu-item-custom'
            className='custom-item'
            key='custom-date'
            value={null}
          >
            {renderCustomDate()}
          </MenuItem>
          <MenuItem
            id='date-filter-menu-item-remove'
            className='remove-item'
            key='remove-all'
            onClick={() => {
              updateFilter('dateFilter', null);
              setDateFilterOpen(false);
            }}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const [startDueDateFilter, setStartDueDateFilter] = useState<Date | null>(null);
  const [endDueDateFilter, setEndDueDateFilter] = useState<Date | null>(null);
  const onChangeDueDateFilter = (dates: Date[] | null[]) => {
    const [start, end] = dates;

    if (start && end) {
      setStartDueDateFilter(null);
      setEndDueDateFilter(null);
      setDateFilterOpen(false);

      const date = `${formatDateForView(start)}~${formatDateForView(end)}`;
      updateFilter('dateFilter', date);
    } else {
      setStartDueDateFilter(start);
      setEndDueDateFilter(end);
    }
  };
  const renderCustomDate = () => {
    return (
      <div
        id='date-filter-custom-date-con'
        className='item-container due-date-container'
        key='custom-date-con'
      >
        <div id='date-filter-date-picker'>
          <DatePicker
            selected={startDueDateFilter}
            onChange={onChangeDueDateFilter}
            startDate={startDueDateFilter}
            endDate={endDueDateFilter}
            selectsRange
            selectsEnd={true}
            selectsStart={true}
            shouldCloseOnSelect={false}
            id='custom-date-id'
            popperPlacement='bottom-end'
            calendarClassName='date-range-calendar-con'
            wrapperClassName='custom-date'
            customInput={
              <div>
                <FontAwesomeIcon size='lg' className='calendar-icon' icon={faCalendarPlus} /> Custom
                Date
              </div>
            }
          />
        </div>
      </div>
    );
  };

  const updateCompletedTaskFilter = (filter: string, custom?: boolean) => {
    log('updateCompletedTaskFilter', filter, custom);

    let id: string;

    if (stateType === 'PROJECT') id = currentProjectId ? currentProjectId : '';
    else if (stateType === 'INDIVIDUAL') id = currentUserId ? currentUserId : '';
    else id = loggedInUserId;

    getCompletedTasks(filter, true, id, stateType, custom);
  };

  const renderCompletedTaskFilter = () => {
    return (
      <div
        id='completed-date-filter-con'
        className='item-container date-filter-container filter-control'
        key='completed-date-filter'
      >
        <button
          id='completed-date-filter-btn'
          className='item-btn completed-date-btn'
          ref={completedTaskFilterDateRef}
          onClick={() => !skipCompletedDateClick.current && setCompletedTaskFilterOpen(true)}
          key='completed-date-filter-btn'
        >
          <span id='completed-date-filter-selection' className='item-selected'>
            {getDateNameForIdCompletedTask()}
          </span>
          {!isCompletedTaskFilterOpen ? (
            <FontAwesomeIcon
              id='completed-date-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='completed-date-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>

        <ControlledMenu
          id='completed-date-filter-controlled-menu'
          className='date-menu'
          anchorRef={completedTaskFilterDateRef}
          state={isCompletedTaskFilterOpen ? 'open' : 'closed'}
          key='completed-date-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipCompletedDateClick.current = true;
              setTimeout(() => (skipCompletedDateClick.current = false), 300);
            }
            if (reason !== 'click') {
              setCompletedTaskFilterOpen(false);
            }
          }}
        >
          {modifiedDateFilterData.map((dateList, _index) => (
            <MenuItem
              id={`completed-date-filter-menu-item-${dateList.id}`}
              key={`completed-date-filter-menu-item${dateList.id}`}
              onClick={() => {
                updateCompletedTaskFilter(dateList.id);
                setCompletedTaskFilterOpen(false);
              }}
              disabled={dateList.id === completedTasksFilter}
              value={dateList.id}
            >
              <span>{dateList.name}</span>
              <span className='sub-title'>Filter by {dateList.name}</span>
            </MenuItem>
          ))}

          <MenuItem
            id='completed-date-filter-menu-item-custom'
            className='custom-item'
            key='completed-custom-date'
            value={null}
          >
            {renderCustomDateForCompletedTasks()}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const onChangeCompletedFilter = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      setStartDateFilter(null);
      setEndDateFilter(null);
      setCompletedTaskFilterOpen(false);

      const date = `${formatDateForView(start)}~${formatDateForView(end)}`;

      updateCompletedTaskFilter(date, true);
    } else {
      setStartDateFilter(start);
      setEndDateFilter(end);
    }
  };
  const renderCustomDateForCompletedTasks = () => {
    return (
      <div
        id='completed-date-filter-custom-date-con'
        className='item-container due-date-container'
        key='custom-date-con'
      >
        <div id='completed-date-filter-date-picker'>
          <DatePicker
            selected={startDateFilter}
            onChange={onChangeCompletedFilter}
            startDate={startDateFilter}
            endDate={endDateFilter}
            selectsRange
            selectsEnd={true}
            selectsStart={true}
            shouldCloseOnSelect={false}
            id='custom-date-id'
            popperPlacement='bottom-end'
            calendarClassName='date-range-calendar-con'
            wrapperClassName='custom-date'
            customInput={
              <div>
                <FontAwesomeIcon size='lg' className='calendar-icon' icon={faCalendarPlus} /> Custom
                Date
              </div>
            }
          />
        </div>
      </div>
    );
  };

  const [searchKey, setSearchText] = useState(searchTextFilter ?? '');

  useEffect(() => {
    if (searchTextFilter !== undefined && searchKey !== searchTextFilter)
      setSearchText(searchTextFilter);
  }, [searchTextFilter]);

  const renderSearchInput = () => {
    return (
      <div className={'search-tasks-input-con'}>
        <SearchInput
          placeholder={Strings.search}
          value={searchKey}
          onBlur={(e) => updateFilter('searchTextFilter', searchKey)}
          onChange={(e) => setSearchText((e.target as HTMLInputElement).value)}
          onSearch={(e) => updateFilter('searchTextFilter', searchKey)}
        />
      </div>
    );
  };

  const renderAllFilters = () => {
    if (!accountSettings.filterVisiblity) return null;
    else if (readOnly) {
      return (
        <Fragment>
          {renderSearchInput()}
          {stateType === 'PROJECT' ? renderAssigneeFilter() : null}
          {stateType !== 'PROJECT' ? renderProjectFilter() : null}
          {renderTagFilter()}
          {renderSizeFilter()}
          {renderCompletedTaskFilter()}
        </Fragment>
      );
    }

    if (stateType === 'PROJECT')
      return (
        <Fragment>
          {renderSearchInput()}
          {renderAssigneeFilter()}
          {renderTagFilter()}
          {renderSizeFilter()}
          {renderDateFilter()}
        </Fragment>
      );

    if (stateType === 'RECURRING')
      return (
        <Fragment>
          {renderSearchInput()}
          {isAdmin() ? renderCreatedByFilter() : null}
          {renderProjectFilter()}
          {renderAssigneeFilter()}
          {renderTagFilter()}
          {renderSizeFilter()}
        </Fragment>
      );

    if (stateType === 'TEMPLATE')
      return (
        <Fragment>
          {renderSearchInput()}
          {renderAssigneeFilter()}
          {renderTagFilter()}
          {renderSizeFilter()}
        </Fragment>
      );

    return (
      <Fragment>
        {renderSearchInput()}
        {renderProjectFilter()}
        {renderTagFilter()}
        {renderSizeFilter()}
        {renderDateFilter()}
      </Fragment>
    );
  };

  const showProjectInfo = () => {
    setShowEditProjectModal(true);
    playModalOpenSound();
  };

  const [showFilters, setShowFilters] = useState(false);
  /**
   * sets the modal status of filter options to true
   */
  const showFilterOptions = () => {
    setShowFilters(true);
  };

  const renderInfoIcon = () => {
    if (stateType === 'PROJECT' || stateType === 'TEMPLATE') {
      return (
        <div id='info-icon-title-bar' className='item-container info-container' key='info-con'>
          <button id='info-btn' className='info-btn' onClick={showProjectInfo}>
            <img id='info-image' src={InfoIcon} alt='info' className='info-image' />
          </button>
        </div>
      );
    }
    return null;
  };

  /**
   * renders the filter icon displayed for smaller screens
   * returns DOM element
   */
  const renderFilterIcon = () => {
    return (
      <div id='filter-container' className='item-container filter-container' key='filter-con'>
        <button id='filter-btn' className='filter-btn' onClick={showFilterOptions}></button>
      </div>
    );
  };

  return (
    <div
      className={`top-title-bar-container ${
        navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
      } ${navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''}`}
      id={idName}
    >
      <div className='left-side' id='left-side'>
        {renderLeftTitle()}
      </div>
      <div className='right-side' id='right-side'>
        <div
          className={`filter-main-container ${!showFilters ? 'hide' : ''}`}
          id='filter-main-container'
          onClick={() => setShowFilters(!showFilters)}
        >
          <div className='filter-sub' id='filter-sub' onClick={(e) => e.stopPropagation()}>
            {renderAllFilters()}
          </div>
        </div>
        {renderFilterIcon()}
        {renderInfoIcon()}
      </div>
    </div>
  );
}
