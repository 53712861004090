import { atom, DefaultValue, selector } from 'recoil';

export const recurringTaskFiltersState = atom<TaskFiltersObjectType>({
  key: 'recurringTaskFilters',
  default: {
    sizeFilter: null,
    tagFilter: null,
    assigneeFilter: null,
    dateFilter: null,
    projectFilter: null,
    createdByFilter: null,
    searchTextFilter: '',
  },
});

export const recurringTaskUnfilteredListState = atom<TaskTagListType>({
  key: 'recurringTaskUnfilteredList',
  default: {
    FOCUS: [],
    BACKLOG: [],
    COMPLETED: [],
  },
});

export const currentHighlightedTaskState = atom<TaskObjectType | null>({
  key: 'currentHighlightedTaskInRecurringTasks',
  default: null,
});

export const recurringFocusTaskDataState = atom<TaskObjectType[] | null>({
  key: 'recurringFocusData',
  default: null,
});

export const recurringFocusTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'recurringFocusFilteredData',
  default: null,
});

export const recurringFocusTaskLoadingState = atom<boolean>({
  key: 'recurringFocusLoading',
  default: false,
});

export const recurringFocusTaskAbortState = atom<AbortController>({
  key: 'recurringFocusAbortController',
  default: new AbortController(),
});

export const recurringFocusTaskSizeState = atom<number>({
  key: 'recurringFocusTaskSize',
  default: 0,
});

export const recurringFocusTaskState = selector<TaskListType>({
  key: 'recurringFocus',
  get: ({ get }) => {
    const data = get(recurringFocusTaskDataState);
    const filteredData = get(recurringFocusTaskFilteredDataState);
    const isLoading = get(recurringFocusTaskLoadingState);
    const abortController = get(recurringFocusTaskAbortState);
    const size = get(recurringFocusTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(recurringFocusTaskDataState, value);
      set(recurringFocusTaskFilteredDataState, value);
      set(recurringFocusTaskLoadingState, value);
      set(recurringFocusTaskAbortState, value);
      set(recurringFocusTaskSizeState, value);
      return;
    }
    set(recurringFocusTaskDataState, value.data);
    set(recurringFocusTaskFilteredDataState, value.filteredData);
    set(recurringFocusTaskLoadingState, value.isLoading);
    set(recurringFocusTaskAbortState, value.abortController);
    set(recurringFocusTaskSizeState, value.size);
  },
});

export const recurringBacklogTaskDataState = atom<TaskObjectType[] | null>({
  key: 'recurringBacklogData',
  default: null,
});

export const recurringBacklogTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'recurringBacklogFilteredData',
  default: null,
});

export const recurringBacklogTaskLoadingState = atom<boolean>({
  key: 'recurringBacklogLoading',
  default: false,
});

export const recurringBacklogTaskAbortState = atom<AbortController>({
  key: 'recurringBacklogAbortController',
  default: new AbortController(),
});

export const recurringBacklogTaskSizeState = atom<number>({
  key: 'recurringBacklogTaskSize',
  default: 0,
});

export const recurringBacklogTaskState = selector<TaskListType>({
  key: 'recurringBacklog',
  get: ({ get }) => {
    const data = get(recurringBacklogTaskDataState);
    const filteredData = get(recurringBacklogTaskFilteredDataState);
    const isLoading = get(recurringBacklogTaskLoadingState);
    const abortController = get(recurringBacklogTaskAbortState);
    const size = get(recurringBacklogTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(recurringBacklogTaskDataState, value);
      set(recurringBacklogTaskFilteredDataState, value);
      set(recurringBacklogTaskLoadingState, value);
      set(recurringBacklogTaskAbortState, value);
      set(recurringBacklogTaskSizeState, value);
      return;
    }
    set(recurringBacklogTaskDataState, value.data);
    set(recurringBacklogTaskFilteredDataState, value.filteredData);
    set(recurringBacklogTaskLoadingState, value.isLoading);
    set(recurringBacklogTaskAbortState, value.abortController);
    set(recurringBacklogTaskSizeState, value.size);
  },
});

export const recurringSelectedFocusTaskState = atom<SelectedTaskIdsType[]>({
  key: 'recurringSelectedFocusTask',
  default: [],
});

export const recurringSelectedBacklogTaskState = atom<SelectedTaskIdsType[]>({
  key: 'recurringSelectedBacklogTask',
  default: [],
});

export const recurringSelectedCompletedTaskState = atom<SelectedTaskIdsType[]>({
  key: 'recurringSelectedCompletedTask',
  default: [],
});

export const recurringSelectedTempIdsTaskState = atom<SelectedTaskIdsType[]>({
  key: 'recurringSelectedTempIdsTask',
  default: [],
});

export const recurringSelectedTaskTypeState = atom<TaskType | null>({
  key: 'recurringSelectedTaskType',
  default: null,
});

export const recurringSelectedTaskDragIdState = atom<string | null>({
  key: 'recurringSelectedTaskDragId',
  default: null,
});

export const recurringSelectedTasksState = selector<SelectedTasksType>({
  key: 'recurringSelectedTasks',
  get: ({ get }) => {
    const FOCUS = get(recurringSelectedFocusTaskState);
    const BACKLOG = get(recurringSelectedBacklogTaskState);
    const COMPLETED = get(recurringSelectedCompletedTaskState);
    const tempIds = get(recurringSelectedTempIdsTaskState);
    const lastSelectedTaskType = get(recurringSelectedTaskTypeState);
    const draggingTaskId = get(recurringSelectedTaskDragIdState);
    return {
      FOCUS,
      BACKLOG,
      COMPLETED,
      tempIds,
      lastSelectedTaskType,
      draggingTaskId,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(recurringSelectedFocusTaskState, value);
      set(recurringSelectedBacklogTaskState, value);
      set(recurringSelectedCompletedTaskState, value);
      set(recurringSelectedTempIdsTaskState, value);
      set(recurringSelectedTaskTypeState, value);
      set(recurringSelectedTaskDragIdState, value);
      return;
    }
    set(recurringSelectedFocusTaskState, value.FOCUS);
    set(recurringSelectedBacklogTaskState, value.BACKLOG);
    set(recurringSelectedCompletedTaskState, value.COMPLETED);
    set(recurringSelectedTempIdsTaskState, value.tempIds);
    set(recurringSelectedTaskTypeState, value.lastSelectedTaskType);
    set(recurringSelectedTaskDragIdState, value.draggingTaskId);
  },
});

export const recurringAutoFocusState = atom<string | null>({
  key: 'recurringAutoFocus',
  default: null,
});

export const selectedRecurringNotificationTaskIdState = atom<string | null>({
  key: 'selectedRecurringNotificationTaskId',
  default: null,
});

export const recurringTaskDataSelector = selector<RecurringDataType>({
  key: 'recurringTaskData',
  get: ({ get }) => {
    const taskFilters = get(recurringTaskFiltersState);
    const unFilteredList = get(recurringTaskUnfilteredListState);
    const currentHighlightedTask = get(currentHighlightedTaskState);
    const FOCUS = get(recurringFocusTaskState);
    const BACKLOG = get(recurringBacklogTaskState);
    const selectedTasks = get(recurringSelectedTasksState);
    const autoFocus = get(recurringAutoFocusState);
    const selectedRecurringNotificationTaskId = get(selectedRecurringNotificationTaskIdState);

    // then combine into desired shape (object) and return:
    return {
      taskFilters,
      unFilteredList,
      currentHighlightedTask,
      FOCUS,
      BACKLOG,
      selectedTasks,
      autoFocus,
      selectedRecurringNotificationTaskId,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(recurringTaskFiltersState, value);
      set(recurringTaskUnfilteredListState, value);
      set(currentHighlightedTaskState, value);
      set(recurringFocusTaskState, value);
      set(recurringBacklogTaskState, value);
      set(recurringSelectedTasksState, value);
      set(recurringAutoFocusState, value);
      set(selectedRecurringNotificationTaskIdState, value);
      return;
    }
    set(recurringTaskFiltersState, value.taskFilters);
    set(recurringTaskUnfilteredListState, value.unFilteredList);
    set(currentHighlightedTaskState, value.currentHighlightedTask);
    set(recurringFocusTaskState, value.FOCUS);
    set(recurringBacklogTaskState, value.BACKLOG);
    set(recurringSelectedTasksState, value.selectedTasks);
    set(recurringAutoFocusState, value.autoFocus);
    set(selectedRecurringNotificationTaskIdState, value.selectedRecurringNotificationTaskId);
  },
});
