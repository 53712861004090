import { useState, useEffect, memo, useRef } from 'preact/compat';
import { KeyboardEvent, ChangeEvent } from 'react';
import Linkify from 'linkify-react';
import getCaretCoordinates from 'textarea-caret';
import TextareaAutosize from 'react-textarea-autosize';
import Menu from './Menu';
import { log } from 'utils/index';
import { hasUpdated } from 'utils/helpers';
import { addNewTag } from 'services/TagService';
import { addNewTemplateTag } from 'services/TemplateService';
import {
  BACKSPACE_KEY_CODE,
  DOWN_KEY_CODE,
  ENTER_KEY_CODE,
  RECURRING_KEY,
  TAB_KEY_CODE,
  UP_KEY_CODE,
} from 'utils/task';

function InputBox(props: InputBoxPropsType) {
  const {
    index,
    itemId,
    tempId,
    placeholder,
    isDisabled,
    parentId,
    parentIndex,
    addNewTask,
    addNewTaskTopBar,
    deleteTheTask,
    itemValue,
    updateValue,
    dataType,
    valueUpdated,
    storeInputRefs,
    sizesData,
    usersData,
    projectsData,
    tagsData,
    updateSize,
    updateProject,
    updateAssignee,
    setTaskTags,
    itemSize, // needed for method returned to take correct size
    itemProject,
    itemAssignee,
    spellChecker,
    forTopBar,
    maxRows,
    showRecurringTaskModal,
    stateType,
    itemTemplate,
    isRecurring,
    readOnly,
    isSubtask,
    assignSelf, // needed for taking the latest assignDefaultToSelf setting after screen reload in addNewTaskFromTopbar
    itemTags,
    defaultSize,
  } = props;

  const allSigns = ['@', '!', '$', '#'];
  const allData = {
    '@': usersData,
    '!': parentId || stateType === 'TEMPLATE' ? [] : projectsData, // Show project data only for tasks
    $: sizesData,
    '#': tagsData,
  };

  const [value, setValue] = useState(itemValue);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 220,
    left: 0,
  });
  const [showMenu, setShowMenu] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [lastSign, setLastSign] = useState('');
  const [selectedData, setSelectedData] = useState<
    UserFieldType[] | SizeObjectType[] | ProjectObjectType[] | TagObjectType[]
  >([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [height, setHeight] = useState(18);
  const [textDeleted, setTextDeleted] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  // log('render components/TaskItems/IndividualItem/InputBox', value);

  useEffect(() => {
    setTextDeleted(true);
  }, [itemTags]);
  /*
   * Item Value Hook
   */
  useEffect(() => {
    setValue(itemValue);
  }, [updateValue]);

  useEffect(() => {
    if (inputRef && inputRef.current) storeInputRefs(dataType + tempId, inputRef.current);
  }, [inputRef]);

  const updateHeight = (newHeight: number) => {
    if (newHeight !== height && textDeleted) {
      setHeight(newHeight);
      setTextDeleted(false);
    }
  };

  useEffect(() => {
    if (inputRef.current && height !== inputRef.current.clientHeight) {
      setHeight(inputRef.current.clientHeight);
    }
  }, [inputRef.current?.clientHeight, inputRef.current?.scrollHeight]);

  const textarea = () => {
    return (
      <TextareaAutosize
        id={`textarea-${tempId}`}
        onHeightChange={updateHeight}
        ref={inputRef}
        className={`text-area ${parentId ? 'subtask' : 'task'} ${!forTopBar ? 'hide-text' : ''}`}
        maxRows={maxRows}
        placeholder={placeholder}
        spellCheck={spellChecker}
        readOnly={isDisabled}
        value={value}
        onBlur={() => onBlur()}
        onClick={(e) => linkClicked((e.target as HTMLTextAreaElement).selectionStart)}
        onChange={(e) => onChange(e as ChangeEvent<HTMLTextAreaElement>)}
        onKeyDown={(e) => onKeyDown(e)}
      />
    );
  };

  const onBlur = () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 200);
  };

  const blurInput = () => {
    inputRef && inputRef.current && inputRef.current.blur();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (stateType === 'RECURRING' && forTopBar && e.key === ENTER_KEY_CODE && shouldShowModal) {
      setShouldShowModal(false);
    } else if (
      (stateType === 'RECURRING' && forTopBar && e.key === ENTER_KEY_CODE) || // Show Modal if user directly taps enter from top bar
      (e.key === RECURRING_KEY && !parentId)
    ) {
      // Show Modal if user types % while editing existing task and it is not a subtask
      if (shouldShowModal) setShouldShowModal(false);

      if (stateType === 'RECURRING' && forTopBar && e.key === RECURRING_KEY) {
        setShouldShowModal(true);
      }
      e.preventDefault();
      if (forTopBar && e.key === ENTER_KEY_CODE) enterPressedRecurringModal(e);
      else if (e.key === RECURRING_KEY && !parentId)
        showRecurringTaskModal && showRecurringTaskModal();
      blurInput();
      return;
    } else if (showMenu && (e.key === UP_KEY_CODE || e.key === DOWN_KEY_CODE)) {
      e.preventDefault();
    } else if ((e.target as HTMLInputElement).selectionEnd !== cursorPosition) {
      log('on change in');
      onChange(e);
    }

    if (e.key === ENTER_KEY_CODE) return enterPressed(e);
    else if (e.key === TAB_KEY_CODE) return tabPressed(e);
    else if (e.key === BACKSPACE_KEY_CODE) return deletePressed(e);
  };

  const enterPressedRecurringModal = (e: KeyboardEvent) => {
    const newValue = (e.target as HTMLTextAreaElement).value;
    const newCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;

    const startText = value.substring(0, newCursorPosition);
    const lastIndexTag = startText.lastIndexOf('#');

    if (lastIndexTag !== -1 && selectedItemIndex < 0) {
      const lastText = startText.substring(lastIndexTag);

      if (lastText.length > 1) {
        const tagFound = lastText.substring(1);
        setShowMenu(false);
        clearText(true, tagFound, newValue, newCursorPosition);

        return;
      }
    } else if (showMenu) {
      const menu = selectedData[selectedItemIndex];

      if (menu) {
        menuSelected(menu);
        return;
      }
    }
    showRecurringTaskModal && showRecurringTaskModal();
  };

  const enterPressed = (e: KeyboardEvent) => {
    if (readOnly) return;
    onChange(e);
    e.preventDefault();

    const newValue = (e.target as HTMLTextAreaElement).value;
    const newCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;

    const startText = value.substring(0, newCursorPosition);
    const lastIndexTag = startText.lastIndexOf('#');

    if (lastIndexTag !== -1 && selectedItemIndex < 0) {
      const lastText = startText.substring(lastIndexTag);

      if (lastText.length > 1) {
        const tagFound = lastText.substring(1);

        setShowMenu(false);
        clearText(true, tagFound, newValue, newCursorPosition);

        return;
      }
    }
    // Show menu
    else if (showMenu) {
      const menu = selectedData[selectedItemIndex];

      if (menu) menuSelected(menu);
    } else {
      if (isDisabled) return;

      if (forTopBar && addNewTaskTopBar)
        addNewTaskTopBar(newValue, assignSelf, itemTags, itemSize, defaultSize);
      else {
        if (addNewTask) {
          // Add new task
          if (!parentId) addNewTask(index + 1, undefined, undefined, isRecurring);
          else addNewTask(index + 1, parentIndex, parentId, isRecurring);
        }
      }
    }
  };

  const createNewTemplateTag = async (name: string, taskName?: string) => {
    const payload = {
      name,
      projectTemplateId: itemTemplate ? itemTemplate : null,
      ownerIdentifier: itemAssignee?.id ? itemAssignee?.id : null,
    };
    const { success, data } = await addNewTemplateTag(payload);

    if (success && data && data.id) {
      // setTimeout(() => {
      setTaskTags(data.id, name, taskName);
      // }, 5);
    }
  };

  const createNewTag = async (name: string, taskName?: string) => {
    if (doesTagAlreadyExist(name)) return;

    if (stateType === 'TEMPLATE') {
      createNewTemplateTag(name, taskName);
      return;
    }

    const payload = {
      name,
      tagIdentifier: itemProject?.id ? itemProject?.id : null,
      ownerIdentifier: itemAssignee?.id ? itemAssignee?.id : null,
    };
    const { success, data } = await addNewTag(payload);

    if (success && data && data.id) {
      // setTimeout(() => {
      setTaskTags(data.id, name, taskName);
      // }, 5);
    }
  };

  const doesTagAlreadyExist = (name: string) => {
    const index =
      tagsData && tagsData.findIndex((tag) => tag.name.toLowerCase() === name.toLowerCase());

    if (index === -1 || tagsData === undefined || index === undefined) return false;

    const item = tagsData[index];
    setTaskTags(item.id, item.name);

    return true;
  };

  const tabPressed = (e: KeyboardEvent) => {
    if (readOnly) return;
    onChange(e);

    if (forTopBar) return;

    e.preventDefault();

    if (addNewTask) {
      if (!parentId) addNewTask(0, index, itemId, isRecurring);
      else addNewTask(index + 1, parentIndex, parentId, isRecurring);
    }
  };

  const deletePressed = (e: KeyboardEvent) => {
    if (readOnly) return;
    const currentValue = (e.target as HTMLTextAreaElement).value;
    const currentCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;
    log('DELETEPRESSED', currentValue.trim());
    if (currentValue.trim() === '' && currentCursorPosition === 0) {
      deleteTheTask && deleteTheTask();
      e.preventDefault();
    } else {
      setTextDeleted(true);
    }
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement> | KeyboardEvent) => {
    if (readOnly) return;

    const newValue = (e.target as HTMLTextAreaElement).value;
    const newCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;
    log('DELETEPRESSED onChange', newValue, newCursorPosition);

    setValue(newValue);
    valueUpdated(newValue);
    setCursorPosition(newCursorPosition);

    const show = canShowMenu(newValue, newCursorPosition);
    setShowMenu(show);

    if (show) {
      updateContextMenuPosition(e as KeyboardEvent);
    }
  };

  const canShowMenu = (newValue: string, newCursorPosition: number) => {
    const startText = newValue.substring(0, newCursorPosition);

    const lastIndexAssignee = startText.lastIndexOf('@');
    const lastIndexProject = startText.lastIndexOf('!');
    const lastIndexSize = startText.lastIndexOf('$');
    const lastIndexTag = startText.lastIndexOf('#');

    const allValues = [lastIndexAssignee, lastIndexProject, lastIndexSize, lastIndexTag];

    const maxVal = Math.max(...allValues);
    const signIndex = allValues.indexOf(maxVal);
    const selectedIndex = allValues[signIndex];

    if (signIndex === -1 || maxVal === -1) return false;

    const selectedSign = allSigns[signIndex];

    if (selectedSign === '!' && isSubtask) return false;

    setLastSign(selectedSign);

    const data = allData[selectedSign];

    if (!data) return false;

    const searchText = startText
      .substring(selectedIndex + 1)
      .toString()
      .toLowerCase();

    let filteredData = data.filter((dat) =>
      dat.name.toLowerCase().startsWith(searchText.toLowerCase()),
    );

    filteredData = addRemoveItem(filteredData, selectedSign);

    if (filteredData.length === 0) return false;

    setSelectedData(filteredData);
    setSelectedItemIndex(selectedSign === '#' ? -1 : 0);

    return true;
  };

  const addRemoveItem = (filteredData: FilterDropdownObjectType[], selectedSign: string) => {
    if (
      (selectedSign === '!' && forTopBar && itemProject && itemProject.id) ||
      (selectedSign === '!' && itemProject && itemProject.id && itemAssignee && itemAssignee.id)
    )
      filteredData = [...filteredData, { id: 'REMOVE', name: 'Remove Project' }];

    if (
      (selectedSign === '@' && forTopBar && itemAssignee && itemAssignee.id) ||
      (selectedSign === '@' && itemAssignee && itemAssignee.id && itemProject && itemProject.id)
    )
      filteredData = [...filteredData, { id: 'REMOVE', name: 'Remove Assignee' }];

    return filteredData;
  };

  const updateContextMenuPosition = (e: KeyboardEvent) => {
    const caret = getCaretCoordinates(e.target, (e.target as HTMLTextAreaElement).selectionEnd);

    setContextMenuPosition({ top: caret.top + 16, left: caret.left });
  };

  const linkClicked = (position: number) => {
    // let expression = /((https?:\/\/)?(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    const expression = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    const matches = value.match(expression);

    matches &&
      matches.forEach((match) => {
        const start = value.indexOf(match);
        const end = start + match.length;

        if (position > start && position < end) {
          if (!match.startsWith('http')) match = `http://${match}`;

          window.open(match, '_blank', 'noopener,noreferrer');
        }

        // log('linkClicked', start, position, end, match.length);
      });
  };

  const menuSelected = (item: SizeObjectType | UserFieldType | string | TagObjectType) => {
    log('menuSelected', item, value);

    setShowMenu(false);

    const currentValue = value;
    const currentCursorPosition = cursorPosition;

    let newTextStart = currentValue.substring(0, currentCursorPosition).trimEnd();
    const lastIndex = newTextStart.lastIndexOf(lastSign);

    newTextStart = newTextStart.substring(0, lastIndex).trimEnd();

    const newTextEnd = currentValue.substring(currentCursorPosition).trimStart();

    let newText;
    if (newTextEnd === '') newText = newTextStart;
    else newText = newTextStart + ' ' + newTextEnd;

    setValue(newText);
    valueUpdated(newText);

    inputRef && inputRef.current && inputRef.current.focus();

    setTimeout(() => {
      inputRef &&
        inputRef.current &&
        inputRef.current.setSelectionRange(newTextStart.length, newTextStart.length);
    }, 0);

    switch (lastSign) {
      case '$':
        updateSize((item as SizeObjectType).id);
        break;
      case '@':
        updateAssignee && updateAssignee((item as UserFieldType).id, newText);
        break;
      case '!':
        updateProject((item as ProjectObjectType).id, newText);
        break;
      case '#':
        setTaskTags((item as TagObjectType).id, (item as TagObjectType).name, newText);
        break;
      default:
    }
  };

  const clearText = (
    createTag: boolean = false,
    tagFound?: string,
    newValue?: string,
    newCursorPosition?: number,
  ) => {
    const currentValue = newValue ? newValue : value;
    const currentCursorPosition = newCursorPosition ? newCursorPosition : cursorPosition;

    let newTextStart = currentValue.substring(0, currentCursorPosition).trimEnd();
    const lastIndex = newTextStart.lastIndexOf(lastSign);

    newTextStart = newTextStart.substring(0, lastIndex).trimEnd();

    const newTextEnd = currentValue.substring(currentCursorPosition).trimStart();

    let newText;
    if (newTextEnd === '') newText = newTextStart;
    else newText = newTextStart + ' ' + newTextEnd;

    setValue(newText);
    valueUpdated(newText);

    if (createTag && tagFound) {
      createNewTag(tagFound, newText);
    }

    inputRef && inputRef.current && inputRef.current.focus();

    setTimeout(() => {
      inputRef &&
        inputRef.current &&
        inputRef.current.setSelectionRange(newTextStart.length, newTextStart.length);
    }, 0);
  };

  const renderContextMenu = () => {
    if (!showMenu) return null;
    if (selectedData.length === 1 && selectedData[0].id === 'REMOVE') return null;
    return (
      <Menu
        forTopBar={forTopBar}
        onClick={(item) => menuSelected(item)}
        selectedItemIndex={selectedItemIndex}
        goUp={() => goUp()}
        goDown={() => goDown()}
        setSelectedItem={(index) => setSelectedItem(index)}
        contextMenuPosition={contextMenuPosition}
        data={selectedData}
      />
    );
  };

  const setSelectedItem = (index: number) => {
    setSelectedItemIndex(index);
  };

  const goUp = () => {
    setSelectedItemIndex((index) => {
      return index === 0 ? selectedData.length - 1 : index - 1;
    });
  };

  const goDown = () => {
    setSelectedItemIndex((index) => {
      return index === selectedData.length - 1 ? 0 : index + 1;
    });
  };

  const renderLinkifyDiv = () => {
    if (forTopBar) return null;

    return (
      <Linkify tagName='div' style={{ height }} className='content-div'>
        {value}
      </Linkify>
    );
  };

  return (
    <div className='input-box-con' style={{ height }}>
      {textarea()}
      {renderLinkifyDiv()}
      {renderContextMenu()}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: InputBoxPropsType, nextProps: InputBoxPropsType) {
  const fields = [
    { name: 'itemId', type: 'prime' },
    { name: 'index', type: 'prime' },
    { name: 'parentId', type: 'prime' },
    { name: 'parentIndex', type: 'prime' },
    { name: 'isDisabled', type: 'prime' },
    { name: 'spellChecker', type: 'prime' },
    { name: 'updateValue', type: 'prime' },
    { name: 'itemSize', type: 'prime' },
    { name: 'itemProject', type: 'object' },
    { name: 'itemAssignee', type: 'object' },
    { name: 'usersData', type: 'object' },
    { name: 'projectsData', type: 'object' },
    { name: 'sizesData', type: 'object' },
    { name: 'tagsData', type: 'object' },
    { name: 'stateType', type: 'prime' },
    { name: 'itemTemplate', type: 'prime' },
    { name: 'isSubtask', type: 'prime' },
    { name: 'assignSelf', type: 'prime' },
    { name: 'itemTags', type: 'object' },
    { name: 'itemSize', type: 'prime' },
    { name: 'defaultSize', type: 'prime' },
  ];
  return hasUpdated(prevProps, nextProps, fields);
}

export default memo(InputBox, areEqual);
