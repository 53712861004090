import appConfig from 'config/config';

export const log = (...data) => {
  /* istanbul ignore else */
  if (appConfig.environment === 'local' || appConfig.environment === 'development') {
    console.log(...data);
  } else {
    // will log to a third party service in production
  }
};
