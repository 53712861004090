import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  workspacesAbortControllerState,
  workspacesDataState,
  workspacesHasMoreState,
  workspacesLoadingState,
  workspacesPageState,
  workspacesSortByState,
  workspacesSortOrderState,
  workspacesState,
  workspacesStatusState,
} from '.';
import { getWorkspaces as getWorkspacesFromAPI } from 'services/SuperAdminService';
import { parseWorkspace } from 'utils/task';

export function setSortBy(name: SortByType) {
  const workspaces = getRecoil(workspacesState);
  let { sortBy, sortOrder } = workspaces;
  if (sortBy !== name) {
    sortBy = name;
    sortOrder = 'asc';
  } else {
    if (sortOrder === 'asc') sortOrder = 'desc';
    else sortOrder = 'asc';
  }
  setRecoil(workspacesSortByState, sortBy);
  setRecoil(workspacesSortOrderState, sortOrder);
}

export function addWorkspaces(data: WorkspaceObjectType[], hasMore: boolean, page: number) {
  setRecoil(workspacesDataState, data);
  setRecoil(workspacesHasMoreState, hasMore);
  setRecoil(workspacesPageState, page);
}
/*
 * Get workspaces
 * reset Boolean
 *
 * return null
 */
export async function getWorkspaces(reset: boolean, sortBy?: SortByType, status?: string) {
  let workspaces = getRecoil(workspacesState);

  // set filter in redux
  if (sortBy) {
    setSortBy(sortBy);
    // Get updated workspaces state
    workspaces = getRecoil(workspacesState);
  } else {
    sortBy = workspaces.sortBy;
  }

  if (status) {
    setRecoil(workspacesStatusState, status);
  } else {
    status = workspaces.status;
  }

  const newAbortSource = new AbortController();
  if (reset) {
    const abortC = workspaces.abortController;
    if (abortC) abortC.abort();

    setRecoil(workspacesAbortControllerState, newAbortSource);

    addWorkspaces([], true, 0);
  }

  setRecoil(workspacesLoadingState, true);

  let { page } = workspaces;
  const { limit, abortController, sortOrder } = workspaces;

  if (reset) page = 0;

  const payload = {
    pageNumber: page,
    size: limit,
    sortBy: sortBy,
    status: status,
    ascending: sortOrder === 'asc' ? true : false,
  };
  const { data, success } = await getWorkspacesFromAPI(
    payload,
    reset ? newAbortSource : abortController,
  );

  if (success && data && Array.isArray(data) && data.length) {
    if (page === 0) {
      addWorkspaces(mergeWorkspaceData([], data), data.length ? true : false, page + 1);
    }
    // let workspaces = parseWorkspaces(data);
    else
      addWorkspaces(
        mergeWorkspaceData(workspaces.data, data),
        data.length ? true : false,
        page + 1,
      );
  } else {
    setRecoil(workspacesHasMoreState, false);
  }
  setRecoil(workspacesLoadingState, false);
}

/*
 * Merge old data with new data, after checking if it does not exist already
 * oldData Array
 * newData Array
 *
 * return Array
 */
function mergeWorkspaceData(
  oldData: WorkspaceObjectType[],
  newData: ResponseWorkspaceObjectType[],
) {
  const tempData = [...oldData];
  newData.forEach((newDat, _index) => {
    if (newDat.workspaceId) {
      const index = oldData.findIndex((data) => data.id === newDat.workspaceId);

      if (index === -1) tempData.push(parseWorkspace(newDat));
    }
  });
  return tempData;
}
