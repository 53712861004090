export const pricingPlans: {
  feature: string;
  free: string;
  elite: string;
}[] = [
  {
    feature: 'Lightning fast UI',
    free: 'true',
    elite: 'true',
  },
  {
    feature: 'Themes',
    free: 'true',
    elite: 'true',
  },
  {
    feature: 'Earn Points / Level Up',
    free: 'true',
    elite: 'true',
  },
  {
    feature: 'Compete on Leaderboard',
    free: 'true',
    elite: 'true',
  },
  {
    feature: 'Workspace',
    free: 'Unlimited',
    elite: 'Unlimited',
  },
  {
    feature: 'Attachments',
    free: '50 MB Limit',
    elite: '1 GB Limit',
  },
  {
    feature: 'Recurring Tasks',
    free: '5',
    elite: 'Unlimited',
  },
  {
    feature: 'Projects',
    free: '1',
    elite: 'Unlimited',
  },
  {
    feature: 'Project Folders',
    free: 'false',
    elite: 'true',
  },
  {
    feature: 'Project Templates',
    free: 'false',
    elite: 'true',
  },
  {
    feature: 'Team',
    free: 'false',
    elite: 'true',
  },
  {
    feature: 'Search',
    free: 'false',
    elite: 'true',
  },
  {
    feature: 'Analytics',
    free: 'false',
    elite: 'true',
  },
  {
    feature: 'Pomodoro Timer',
    free: 'false',
    elite: 'Coming Soon',
  },
  {
    feature: 'Plan My Day',
    free: 'false',
    elite: 'Coming Soon',
  },
  {
    feature: 'Calendar',
    free: 'false',
    elite: 'Coming Soon',
  },
];
