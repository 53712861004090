import { useEffect, useState } from 'preact/hooks';
import { setRecoil } from 'recoil-nexus';
import { enableDesktopNotificationState, notificationPayloadItemState } from 'recoil/AccountState';

type Permission = 'granted' | 'denied' | 'default';
const isSupported = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
export const useWebNotification = () => {
  const [permission, setPermission] = useState<Permission>('default');

  useEffect(() => {
    if (!('Notification' in window)) {
      console.error('This browser does not support web notifications.');
    } else {
      if (isSupported()) setPermission(Notification.permission as Permission);
    }
  }, []);

  const requestPermission = async () => {
    if (!isSupported()) return;
    console.log('asking permission 2', permission);

    if (permission !== 'default' && permission !== 'denied') return;
    console.log('asking permission 3');

    const newPermission = await Notification.requestPermission();
    console.log('got permission 4', newPermission);

    setPermission(newPermission as Permission);
  };

  const showNotification = (title: string, options?: NotificationOptions) => {
    if (!isSupported()) return;

    if (permission === 'granted') {
      new Notification(title, options);
    }
    setRecoil(enableDesktopNotificationState, false);
    setRecoil(notificationPayloadItemState, null);
  };

  return { permission, requestPermission, showNotification };
};
