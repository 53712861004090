import { Fragment, memo, useMemo } from 'preact/compat';
import { hasUpdated } from 'utils/helpers';
import { formatDateForMeeting } from 'utils/task';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

type TaskTagsPropsType = {
  itemTags: TagObjectType[];
  tagInfo: TagInfoType[];
  itemTagsLength: number;
  removeTagFromTask: (tagId: string, tagName: string) => void;
  isDisabled?: boolean;
  overlayClass?: string;
  stateType?: StateType;
  isMultiSelected?: boolean;
  bulkUpdateTasksTag?: (tagId: string, tagName: string, add: boolean) => void;
  tempId: string;
  selectedCount?: number;
};
function TaskTags(props: TaskTagsPropsType) {
  const {
    itemTags,
    tagInfo,
    itemTagsLength,
    removeTagFromTask,
    isDisabled,
    overlayClass,
    stateType,
    isMultiSelected,
    bulkUpdateTasksTag,
    tempId,
  } = props;

  // log('render components/TaskItems/IndividualItem/TaskTags');
  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };
  return (
    <div className='item-container item-tags-container' key={itemTagsLength}>
      {itemTags.map((tag, index) => (
        <Tag
          tempId={tempId}
          tag={tag}
          key={index}
          index={index}
          removeTagFromTask={(id, name) => !isDisabled && removeTagFromTask(id, name)}
          tagInfo={tagInfo}
          isMultiSelected={isMultiSelected}
          bulkUpdateTasksTag={(id, name, add) =>
            !isDisabled && bulkUpdateTasksTag && bulkUpdateTasksTag(id, name, add)
          }
        />
      ))}
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

type TagPropsType = {
  tag: TagObjectType;
  index: number;
  tagInfo: TagInfoType[];
  removeTagFromTask: (tagId: string, tagName: string) => void;
  isMultiSelected?: boolean;
  bulkUpdateTasksTag: (tagId: string, tagName: string, add: boolean) => void;
  tempId: string;
};
function Tag(props: TagPropsType) {
  const { tag, index, tagInfo, removeTagFromTask, isMultiSelected, bulkUpdateTasksTag, tempId } =
    props;

  const currentTagInfo = () => {
    let creator, time;

    const tagIndex = tagInfo.findIndex((tagInf) => tagInf.tagId === tag.id);

    if (tagIndex === -1) return { creator, time };

    return {
      creator: tagInfo[tagIndex].addedName,
      time: formatDateForMeeting(tagInfo[tagIndex].addedAtString),
    };
  };

  const renderTagEntry = () => {
    return (
      <Fragment>
        <span
          className='tag-entry'
          style={{ position: 'relative' }}
          key={index + tag.id + tempId}
          data-tooltip-id={`tooltip-${tag.id + tempId}`}
          data-class='tag-tooltip'
          data-background-color='#332a7c'
          data-tip
        >
          <span className='tag-text'>{tag.name}</span>{' '}
          <span
            className='close-icon'
            onClick={(_e) => {
              removeTagFromTask(tag.id, tag.name);
            }}
          >
            x
          </span>
        </span>
        <ReactTooltip className='tag-tooltip' id={`tooltip-${tag.id + tempId}`}>
          {/** @ts-ignore */}
          {tagTooltipContent}
        </ReactTooltip>
      </Fragment>
    );
  };

  const renderMultiSelectTagEntry = () => {
    return (
      <div className='tag-entry' style={{ position: 'relative' }}>
        <Menu
          style={{ width: '50px' }}
          className='item-menu'
          menuButton={
            <MenuButton className='item-btn tag-btn'>
              <span className='tag-text'>{tag.name}</span>{' '}
            </MenuButton>
          }
        >
          <MenuItem
            key='add-tags-to-all'
            onClick={() => bulkUpdateTasksTag(tag.id, tag.name, true)}
            value='copy to all'
          >
            Copy to All
          </MenuItem>
        </Menu>
        <span
          className='close-icon'
          onClick={(_e) => {
            bulkUpdateTasksTag(tag.id, tag.name, false);
          }}
        >
          x
        </span>
      </div>
    );
  };

  const tagTooltipContent = useMemo(() => {
    const currentTag = currentTagInfo();

    if (!currentTag.creator) return <div>N/A</div>;

    return (
      <div>
        <div>Added by: {currentTag.creator}</div>
        <div>On: {currentTag.time}</div>
      </div>
    );
  }, [tagInfo, tag.id, index]);

  return <Fragment>{isMultiSelected ? renderMultiSelectTagEntry() : renderTagEntry()}</Fragment>;
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: TaskTagsPropsType, nextProps: TaskTagsPropsType) {
  const fields = [
    { name: 'itemTagsLength', type: 'prime' },
    { name: 'isDisabled', type: 'prime' },
    { name: 'itemTags', type: 'object' },
    { name: 'tagInfo', type: 'object' },
    { name: 'overlayClass', type: 'prime' },
    { name: 'stateType', type: 'prime' },
    { name: 'isMultiSelected', type: 'prime' },
    { name: 'tempId', type: 'prime' },
    { name: 'selectedCount', type: 'prime' },
  ];

  return hasUpdated(prevProps, nextProps, fields);
}

export default memo(TaskTags, areEqual);
