import { useEffect, useState } from 'preact/compat';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import ManageUsers from 'components/ManageUsers';
import Portfolio from 'components/Portfolio';
import SearchInput from 'components/UI/SearchInput';
import ProjectTemplates from 'components/ProjectTemplates';
import Analytics from 'components/Analytics';
import Subscription from 'components/Subscription';

import {
  getUserEmail,
  getUserWorkspaceId,
  inviteEmail as inviteEmailAPI,
} from 'services/AuthService';
import { navigationDataSelector, showUpgradeModalState } from 'recoil/NavigationState';
import { accountState } from 'recoil/AccountState';

import { Strings } from 'resources';
import { Validation, log } from 'utils';
import '@szhsin/react-menu/dist/index.css';

export default function Admin() {
  const navigationData = useRecoilValue(navigationDataSelector);
  const alert = useAlert();
  const navigate = useNavigate();
  const { page } = useParams();
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [inviteEmail, updateInviteEmail] = useState('');
  const [isLoading, setLoadingState] = useState(false);
  // const [refreshUsers, setRefreshUsers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const account = useRecoilValue(accountState);
  // const accountProfileImage = account?.profileImage;
  const setShowUpgradeModal = useSetRecoilState(showUpgradeModalState);

  const userRole = account?.role;

  useEffect(() => {
    if (
      userRole &&
      userRole !== 'admin' &&
      userRole !== 'superadmin' &&
      navigationData.currentRoute.includes('admin')
    )
      navigate('/');
  }, [userRole]);

  const renderProjectsList = () => {
    return <Portfolio />;
  };

  const renderPageControls = () => {
    return (
      <div className='page-controls-con'>
        <div
          className={`portfolio page-controls ${page === 'portfolio' ? 'active' : ''}`}
          onClick={() => navigate('/admin/portfolio')}
        >
          <svg
            className='case'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.5 2.25H10.5C11.3284 2.25 12 2.92157 12 3.75V4.5H15C15.8284 4.5 16.5 5.17157 16.5 6V14.25C16.5 15.0784 15.8284 15.75 15 15.75H3C2.17157 15.75 1.5 15.0784 1.5 14.25V6C1.5 5.17157 2.17157 4.5 3 4.5H6V3.75C6 2.92157 6.67157 2.25 7.5 2.25ZM3 6H6H12H15V9.75H9.75H8.25H3V6ZM3 14.25V11.25H8.25V12H9.75V11.25H15V14.25H3ZM10.5 3.75V4.5H7.5V3.75H10.5Z'
              fill='#8E8C8C'
            />
          </svg>
          <span className='page-control-title'>{Strings.portfolio}</span>
        </div>
        <div
          className={`manage-user page-controls ${page === 'manage-user' ? 'active' : ''}`}
          onClick={() => navigate('/admin/manage-user')}
        >
          <svg
            className='users'
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0)'>
              <path
                d='M12.0418 14.875V13.4583C12.0418 12.7069 11.7433 11.9862 11.212 11.4549C10.6806 10.9235 9.95994 10.625 9.2085 10.625H3.54183C2.79038 10.625 2.06971 10.9235 1.53836 11.4549C1.00701 11.9862 0.708496 12.7069 0.708496 13.4583V14.875'
                stroke='#8E8C8C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M6.37484 7.79167C7.93964 7.79167 9.20817 6.52314 9.20817 4.95833C9.20817 3.39353 7.93964 2.125 6.37484 2.125C4.81003 2.125 3.5415 3.39353 3.5415 4.95833C3.5415 6.52314 4.81003 7.79167 6.37484 7.79167Z'
                stroke='#8E8C8C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.2915 14.8752V13.4585C16.291 12.8308 16.0821 12.2209 15.6975 11.7248C15.3129 11.2286 14.7743 10.8742 14.1665 10.7173'
                stroke='#8E8C8C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M11.3335 2.21729C11.943 2.37333 12.4831 2.72778 12.8689 3.22475C13.2547 3.72173 13.4641 4.33296 13.4641 4.96208C13.4641 5.5912 13.2547 6.20242 12.8689 6.6994C12.4831 7.19637 11.943 7.55082 11.3335 7.70687'
                stroke='#8E8C8C'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0'>
                <rect width='17' height='17' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <span className='page-control-title'>{Strings.manage_user}</span>
        </div>

        <div
          className={`project-templates page-controls ${
            page === 'project-templates' ? 'active' : ''
          }`}
          onClick={() => navigate('/admin/project-templates')}
        >
          <svg
            className='case'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.5 2.25H10.5C11.3284 2.25 12 2.92157 12 3.75V4.5H15C15.8284 4.5 16.5 5.17157 16.5 6V14.25C16.5 15.0784 15.8284 15.75 15 15.75H3C2.17157 15.75 1.5 15.0784 1.5 14.25V6C1.5 5.17157 2.17157 4.5 3 4.5H6V3.75C6 2.92157 6.67157 2.25 7.5 2.25ZM3 6H6H12H15V9.75H9.75H8.25H3V6ZM3 14.25V11.25H8.25V12H9.75V11.25H15V14.25H3ZM10.5 3.75V4.5H7.5V3.75H10.5Z'
              fill='#8E8C8C'
            />
          </svg>
          <span className='page-control-title'>{Strings.project_templates}</span>
        </div>
        {account?.activeThemeSlug?.toLowerCase() === 'clean' ? (
          <div
            className={`analytics page-controls ${page === 'analytics' ? 'active' : ''}`}
            onClick={() => {
              if (
                account.workspace?.premiumStatus === 'FREE' ||
                account.workspace?.premiumStatus === 'PENDING_PAYMENT'
              )
                setShowUpgradeModal('ANALYTICS');
              else navigate('/admin/analytics');
            }}
          >
            <svg
              className='case'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.5 2.25H10.5C11.3284 2.25 12 2.92157 12 3.75V4.5H15C15.8284 4.5 16.5 5.17157 16.5 6V14.25C16.5 15.0784 15.8284 15.75 15 15.75H3C2.17157 15.75 1.5 15.0784 1.5 14.25V6C1.5 5.17157 2.17157 4.5 3 4.5H6V3.75C6 2.92157 6.67157 2.25 7.5 2.25ZM3 6H6H12H15V9.75H9.75H8.25H3V6ZM3 14.25V11.25H8.25V12H9.75V11.25H15V14.25H3ZM10.5 3.75V4.5H7.5V3.75H10.5Z'
                fill='#8E8C8C'
              />
            </svg>
            <span className='page-control-title'>{Strings.analytics}</span>
          </div>
        ) : null}
        {account?.activeThemeSlug?.toLowerCase() === 'clean' &&
        account?.workspace?.premiumStatus !== 'GRANDFATHERED' ? (
          <div
            className={`subscription page-controls ${page === 'subscription' ? 'active' : ''}`}
            onClick={() => navigate('/admin/subscription')}
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_187_12792)'>
                <path
                  d='M19.5 5.25H4.5C3.67157 5.25 3 5.92157 3 6.75V17.25C3 18.0784 3.67157 18.75 4.5 18.75H19.5C20.3284 18.75 21 18.0784 21 17.25V6.75C21 5.92157 20.3284 5.25 19.5 5.25Z'
                  stroke='#666666'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M3 11.25H9C9 12.0456 9.31607 12.8087 9.87868 13.3713C10.4413 13.9339 11.2044 14.25 12 14.25C12.7956 14.25 13.5587 13.9339 14.1213 13.3713C14.6839 12.8087 15 12.0456 15 11.25H21'
                  stroke='#666666'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M3 8.25H21'
                  stroke='#666666'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_187_12792'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <span className='page-control-title'>{Strings.subscription}</span>
          </div>
        ) : null}
      </div>
    );
  };

  const renderManageUser = () => {
    return <ManageUsers searchText={searchText} />;
  };

  const renderProjectTemplates = () => {
    return <ProjectTemplates />;
  };

  const renderAnalytics = () => {
    return <Analytics />;
  };

  const renderSubsciption = () => {
    return <Subscription />;
  };

  const renderSubContainer = () => {
    switch (page) {
      case 'portfolio':
        return renderProjectsList();
      case 'manage-user':
        return renderManageUser();
      case 'project-templates':
        return renderProjectTemplates();
      case 'analytics':
        return (account &&
          account.activeThemeSlug &&
          account.activeThemeSlug?.toLowerCase() === 'clean') ||
          account === undefined
          ? renderAnalytics()
          : navigate('/admin/portfolio');
      case 'subscription':
        return account &&
          account.activeThemeSlug &&
          account.activeThemeSlug?.toLowerCase() === 'clean' &&
          account.workspace?.premiumStatus !== 'GRANDFATHERED'
          ? renderSubsciption()
          : navigate('/admin/portfolio');
      default:
        break;
    }
  };

  const renderInviteUserCon = () => {
    return (
      <div className='invite-user-input-con'>
        <input
          className='invite-user-input'
          placeholder={Strings.enter_email.toLowerCase()}
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          onChange={(event) => updateInviteEmail((event.target as HTMLInputElement).value)}
          onBlur={(event) => updateInviteEmail((event.target as HTMLInputElement).value)}
          key={0}
          value={inviteEmail}
        />
        <button
          className='send-invite-btn'
          onClick={(event) => {
            sendInviteClicked(event);
          }}
        >
          {Strings.send}
          {renderLoadingIcon()}
        </button>
        <button className='cancel-invite-btn' onClick={() => setShowInviteUser(false)}>
          {Strings.cancel}
        </button>
      </div>
    );
  };

  const renderLoadingIcon = () => {
    if (isLoading) {
      return (
        <span className='loading-con'>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      );
    }
    return null;
  };

  const sendInviteClicked = (event: MouseEvent) => {
    event.preventDefault();

    if (hasEmailErrors() || isLoading) return;

    sendInvite();
  };

  const hasEmailErrors = () => {
    const field = {
      name: 'Email',
      value: inviteEmail,
      type: 'text',
      rules: 'required|email|max:50',
    };
    const fieldsWithError = Validation.validate([field]);
    const fieldWithError = fieldsWithError[0];

    if (fieldWithError.errors && fieldWithError.errors.length) {
      alert.error(fieldWithError.errors[0]);
      return true;
    } else {
      if (areYouInvitingYourself()) {
        alert.error(Strings.cant_invite_yourself);
        return true;
      } else return false;
    }
  };

  const areYouInvitingYourself = () => {
    const userEmail = getUserEmail().toLowerCase().trim();

    if (userEmail === inviteEmail) return true;
    else return false;
  };

  const getInviteMailPayload = (email: string) => {
    const userList = {};
    const tempEmail = email.toLowerCase().trim();
    userList[tempEmail] = 'Standard';

    const payload = {
      baseUrl: document.location.origin + '/',
      users: userList,
      invitedBy: getUserEmail(),
      company: getUserWorkspaceId(),
    };

    return payload;
  };

  const sendInvite = async () => {
    const email = inviteEmail.toLowerCase().trim();
    const payload = getInviteMailPayload(email);
    updateInviteEmail('');
    setLoadingState(true);
    const { success, message, status } = await inviteEmailAPI(payload);
    setLoadingState(false);

    if (!success) {
      if (status === 402) setShowUpgradeModal('TEAM');
      else alert.error(message);
      return log(message);
    }

    // setRefreshUsers(true);
    setShowInviteUser(false);
    return alert.success(`User: ${email} invited successfully.`);
  };

  const renderInviteUserBtn = () => {
    return (
      <button className={`invite-user-btn ${page}`} onClick={() => setShowInviteUser(true)}>
        <span className='invite-btn-text'>{Strings.invite_user}</span>
        <svg
          width='17'
          height='17'
          viewBox='0 0 17 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0)'>
            <path
              d='M11.3335 14.875V13.4583C11.3335 12.7069 11.035 11.9862 10.5036 11.4549C9.97228 10.9235 9.25161 10.625 8.50016 10.625H3.54183C2.79038 10.625 2.06971 10.9235 1.53836 11.4549C1.00701 11.9862 0.708496 12.7069 0.708496 13.4583V14.875'
              stroke='#332A7C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M6.02083 7.79167C7.58564 7.79167 8.85417 6.52314 8.85417 4.95833C8.85417 3.39353 7.58564 2.125 6.02083 2.125C4.45603 2.125 3.1875 3.39353 3.1875 4.95833C3.1875 6.52314 4.45603 7.79167 6.02083 7.79167Z'
              stroke='#332A7C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M14.1665 5.6665V9.9165'
              stroke='#332A7C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16.2915 7.7915H12.0415'
              stroke='#332A7C'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0'>
              <rect width='17' height='17' fill='white' />
            </clipPath>
          </defs>
        </svg>
      </button>
    );
  };

  const renderField = () => {
    return (
      <SearchInput
        containerClassname={showInviteUser ? 'hide' : ''}
        placeholder='Search'
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value)}
        value={searchText}
      />
    );
  };

  const fieldUpdated = (text: string) => {
    setSearchText(text);
  };

  const renderRightContainer = () => {
    if (page === 'manage-user') {
      return (
        <div className='right-con manage-user'>
          {renderField()}
          {showInviteUser ? renderInviteUserCon() : renderInviteUserBtn()}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`projects-screen ${page} ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div className='title-container'>
        <div className='left-con'>
          <div className={`page-title ${page}`}>{Strings.admin}</div>
          {renderPageControls()}
        </div>
        {renderRightContainer()}
      </div>
      {renderSubContainer()}
    </div>
  );
}
