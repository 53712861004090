import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';
import { PaymentMethod } from '@stripe/stripe-js';

/**
 * setup intent api
 * @params
 * @returns {customer: "", id: "", client_secret: ""}
 */
export async function intentSetup() {
  try {
    const response = await appClient.get(apiConfig.subscription.intent_setup);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * called after payment method is created
 * @param {payment_id: "", customer_id: ""} payload
 * @returns
 */
export async function subscribePayment(payload: {
  payment_id: string | PaymentMethod;
  customer_id: string;
}) {
  try {
    const response = await appClient.post(apiConfig.subscription.subscribe, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get subscription detail and billing history
 * @returns Object {data, error, status}
 */
export async function getSubscriptionDetail() {
  try {
    const response = await appClient.get(apiConfig.subscription.get);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * cancel subscription plan
 * @param {subscriptionId: ""} payload
 * @returns Object {data, error, status}
 */
export async function cancelSubscription(payload: { subscriptionId: string }) {
  try {
    const response = await appClient.put(apiConfig.subscription.cancel, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
