import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/*
 * Add whats new
 *
 * returns Object {data, error, status}
 */
export async function addWhatsNew(payload: any): Promise<AsyncResponseType> {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.whats_new.add, payload, { headers });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function getWhatsNew(): Promise<AsyncResponseType> {
  try {
    const response = await appClient.get(apiConfig.whats_new.get);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get whats new features
 *
 * returns Object {data, error, status}
 */
export async function getWhatsNewFeatues(isAscending: boolean): Promise<AsyncResponseType> {
  try {
    const response = await appClient.get(`${apiConfig.whats_new.latest_features}/${isAscending}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
