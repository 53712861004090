import appConfig from 'config/config';
import { useRecoilValue } from 'recoil';
import { navigationDataSelector } from 'recoil/NavigationState';
import ActiveUsersChart from './ActiveUsersChart';
import UsersChart from './UsersChart';

export default function SuperAdminAnalytics() {
  const navigationData = useRecoilValue(navigationDataSelector);

  const renderActiveUsersChart = () => {
    return (
      <div id='super-admin-analytics-active-users-graph-card-con' className='analytics-card-con'>
        <ActiveUsersChart chartId={appConfig.chartIds.superAdminActiveUsers} isMonthly={false} />
      </div>
    );
  };

  const renderMonthlyActiveUsersChart = () => {
    return (
      <div id='super-admin-analytics-active-users-graph-card-con' className='analytics-card-con'>
        <ActiveUsersChart
          chartId={appConfig.chartIds.superAdminMonthlyActiveUsers}
          isMonthly={true}
        />
      </div>
    );
  };

  const renderUsersChart = () => {
    return (
      <div id='super-admin-analytics-active-users-graph-card-con' className='analytics-card-con'>
        <UsersChart chartId={appConfig.chartIds.superAdminUsers} isMonthly={false} />
      </div>
    );
  };

  const renderMonthlyUsersChart = () => {
    return (
      <div id='super-admin-analytics-active-users-graph-card-con' className='analytics-card-con'>
        <UsersChart chartId={appConfig.chartIds.superAdminMonthlyUsers} isMonthly={true} />
      </div>
    );
  };
  return (
    <div
      id='super-admin-analytics-con'
      className={`super-admin-analytics-con analytics-con  ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div id='analytics-con-row-1' className='row'>
        <div id='analytics-con-row-1-col-1' className='column'>
          {renderActiveUsersChart()}
        </div>
        <div id='analytics-con-row-1-col-2' className='column'>
          {renderMonthlyActiveUsersChart()}
        </div>
      </div>

      <div id='analytics-con-row-2' className='row'>
        <div id='analytics-con-row-2-col-1' className='column'>
          {renderUsersChart()}
        </div>
        <div id='analytics-con-row-2-col-2' className='column'>
          {renderMonthlyUsersChart()}
        </div>
      </div>
    </div>
  );
}
