import { Fragment, useRef, useState } from 'preact/compat';
import { MenuItem, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Input from 'components/UI/Input';
import SmallButton from 'components/UI/SmallButton';
import { Strings } from 'resources';

type SuperAdminLevelBuilderPropsType = {
  levelIndex: number;
  fields: ValidationFieldType[];
  loading: boolean;
  innerRef: (element: any) => number;
  fieldUpdated: (value: string, index: number, levelIndex: number) => void;
  checkedFieldUpdated: (checkedStatus: boolean, levelIndex: number, id: string) => void;
  removeLevel: (levelIndex: number) => void;
};

export default function SuperAdminLevelBuilder(props: SuperAdminLevelBuilderPropsType) {
  const triggerFilterRef = useRef(null);
  const skipTriggerClick = useRef(false);
  const [isTriggerFilterOpen, setTriggerFilterOpen] = useState(false);

  const renderInput = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`input-title-field-${index}`} className='input-title'>
          {field.name}
        </div>
        <Input
          id={`input-${index}`}
          placeholder={field.title ? field.title : ''}
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          onChange={(event) =>
            props.fieldUpdated(
              (event.target as HTMLInputElement).value,
              index,
              props.levelIndex - 1,
            )
          }
          onBlur={(event) =>
            props.fieldUpdated(
              (event.target as HTMLInputElement).value,
              index,
              props.levelIndex - 1,
            )
          }
          key={index}
          touched={field.touched}
          value={field.value as string}
          errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
          icon={field.icon}
          secure={field.secure}
        />
      </Fragment>
    );
  };

  const renderTextarea = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`text-area-con-${index}`} className='input-title'>
          {field.name}
        </div>
        <textarea
          id={`text-area-${index}`}
          className='text-area'
          value={field.value as string}
          placeholder={field.title}
          onChange={(event) =>
            props.fieldUpdated(
              (event.target as HTMLInputElement).value,
              index,
              props.levelIndex - 1,
            )
          }
          onBlur={(event) =>
            props.fieldUpdated(
              (event.target as HTMLInputElement).value,
              index,
              props.levelIndex - 1,
            )
          }
          key={index}
          rows={6}
        />
      </Fragment>
    );
  };

  const renderDropDown = (field: ValidationFieldType, index: number) => {
    return (
      <Fragment>
        <div id={`input-title-field-${index}`} className='input-title'>
          {field.name}
        </div>
        <Fragment>
          <button
            id='tag-filter-menu-button'
            className='item-btn tag-btn'
            ref={triggerFilterRef}
            onClick={() => !skipTriggerClick.current && setTriggerFilterOpen(true)}
            key='tag-filter-btn'
          >
            <span className={(field.value as number) > 0 ? 'text-change-color' : ''}>
              {field.value === 0 ? field.title : Strings.selected_trigger}
            </span>
            {!isTriggerFilterOpen ? (
              <FontAwesomeIcon
                id='tag-filter-down-icon'
                className='drop-icon down'
                icon={faChevronDown}
              />
            ) : (
              <FontAwesomeIcon
                id='tag-filter-up-icon'
                className='drop-icon up'
                icon={faChevronUp}
              />
            )}
          </button>
          <ControlledMenu
            placeholder={field.title}
            className={field.value !== null ? 'text-change-color' : ''}
            anchorRef={triggerFilterRef}
            state={isTriggerFilterOpen ? 'open' : 'closed'}
            key='tag-filter-menu'
            onClose={({ reason }) => {
              if (reason === 'blur') {
                skipTriggerClick.current = true;
                setTimeout(() => (skipTriggerClick.current = false), 300);
              }
              if (reason !== 'click') {
                setTriggerFilterOpen(false);
              }
            }}
          >
            {field.options &&
              field.options.map((option, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={option.description}
                    onClick={(_e) => {
                      props.checkedFieldUpdated(
                        field.selectedOptions
                          ? option && !field.selectedOptions.includes(option)
                          : false,
                        props.levelIndex - 1,
                        option.id,
                      );
                    }}
                  >
                    {renderCheckbox(field.selectedOptions, option)}
                    {option.description}
                  </MenuItem>
                );
              })}
          </ControlledMenu>
        </Fragment>
      </Fragment>
    );
  };

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = (item: TriggerObjectType[] | undefined, option: TriggerObjectType) => {
    if (item)
      return (
        <div className='checkbox-con' key={`checkbox-con-${option.id}`}>
          <input
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            checked={item && item.length > 0 ? item.includes(option) : false}
            className='inp-cbx'
            id={`checkbox-${option.id}`}
            type='checkbox'
            onChange={() => {}}
            style={{ display: 'none' }}
          />

          <label className='cbx'>
            <span>
              <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
                <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
              </svg>
            </span>
            <span></span>
          </label>
        </div>
      );
  };

  const renderFields = () => {
    const { fields } = props;
    return (
      <div id='create-level-builder-fields-container' className='level-builder-fields-container'>
        <div id='level-builder-input-con' className='input-main-con'>
          {renderInput(fields[0], 0)}
        </div>
        <div id='level-builder-input-con' className='input-main-con'>
          {renderDropDown(fields[1], 1)}
        </div>
        <div id='level-builder-input-con' className='input-main-con'>
          {renderTextarea(fields[2], 2)}
        </div>
        <div id='remove-level-btn' className='btn-container'>
          <SmallButton clicked={() => props.removeLevel(props.levelIndex)} disabled={props.loading}>
            {Strings.remove_level}
          </SmallButton>
        </div>
      </div>
    );
  };

  return (
    <div
      id='create-level-builder-main-container'
      className='level-builder-main-container'
      ref={props.innerRef}
    >
      <h3>
        {Strings.level}
        {props.levelIndex}
      </h3>
      {renderFields()}
    </div>
  );
}
