import { useRive, Layout, Alignment, Fit, useRiveFile } from '@rive-app/react-canvas-lite';

import { getCurrentTheme } from 'utils/theme';
import config from 'config/config';

const LoadRiveAnimation = ({ riveFile, status, className }) => {
  if (status !== 'success') return null;

  const { RiveComponent } = useRive(
    {
      riveFile,
      stateMachines: 'state_machine',
      autoplay: true,
      layout: new Layout({
        fit: Fit.Fill,
        alignment: Alignment.Center,
      }),
      animations: ['idle', 'sun', 'car', 'road', 'mountain'],
    },
    { shouldResizeCanvasToContainer: true },
  );

  return <RiveComponent className={className} />;
};

const BgAnimation = ({ className = '' }) => {
  const currentTheme = getCurrentTheme();

  const { riveFile, status } = useRiveFile({
    src: `/themes/${currentTheme.id}/rive/bg-animation.riv?v=${config.version}`,
  });

  return <LoadRiveAnimation riveFile={riveFile} status={status} className={className} />;
};

export default BgAnimation;
