import { isUserLoggedIn } from 'services/AuthService';
import appConfig from 'config/config';
import apiConfig from 'config/api';
import { Client } from '@stomp/stompjs';
import { log } from 'utils';

import {
  updateSubTaskSequence,
  updateTaskSequence,
  addSubTask,
  addTask,
  updateTaskName,
  updateTaskSize,
  removeTask,
  updateTaskAssignee,
  addTaskTags,
  addTemplateTag,
  addRecurringTask,
  updateRecurringTask,
  updateCollapseTaskStatus,
} from 'websocket/activities';
import { getCompletedTasks, getTaskData, resetTaskData } from 'recoil/TaskState/update';
import { setRecoil } from 'recoil-nexus';
import { templateWebsocketStatusState } from 'recoil/TemplateState';
import { getAllTemplateTags } from 'recoil/TemplateState/update';

let templateClient: Client | null = null;
let noDataFetch: boolean | undefined = undefined;
let heartbeatTimer: any = null;
const HEARTBEAT_INTERVAL = 15000;
let templateTagId: string;
const stateType: StateType = 'TEMPLATE';
let isConnected = false;

export const open = (templateId: string, fetchData?: boolean) => {
  resetTaskData('FOCUS', stateType);
  resetTaskData('BACKLOG', stateType);

  const { status, token } = isUserLoggedIn();
  if (!status || !token) return;

  log(templateId, 'template id 4040');
  templateTagId = templateId;
  noDataFetch = fetchData;
  const url = `${appConfig.webSocketUrl}`;

  templateClient = new Client({
    brokerURL: url,
    connectHeaders: {
      'X-Authorization': token,
      templateId: templateTagId,
    },
    debug: function (str) {
      log('template debug', str);
      if (str.includes('did not receive server activity')) {
        isConnected = false;
      }
      if (str.includes('Web Socket Opened')) {
        isConnected = true;
      }
    },
    reconnectDelay: 2000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    connectionTimeout: 3000,
    discardWebsocketOnCommFailure: true,
  });

  templateClient.discardWebsocketOnCommFailure = true;

  templateClient.onConnect = function () {
    const { status } = isUserLoggedIn();

    if (!status) return;
    const channel = `/${apiConfig.websocket.templates}/${templateTagId}`;

    log(templateClient, 'channel to connect');

    if (templateClient) {
      templateClient.subscribe(channel, onMessage);
      isConnected = true;
      setHeartBeat();

      if (!noDataFetch) {
        // Reset tasklist data
        getTaskData('FOCUS', true, templateTagId, stateType);
        getTaskData('BACKLOG', true, templateTagId, stateType);
        getCompletedTasks(undefined, true, templateTagId, stateType);
        getAllTemplateTags(templateTagId);
      }
    }
  };

  templateClient.onDisconnect = function () {
    setRecoil(templateWebsocketStatusState, 'DISCONNECTED');
  };

  templateClient.onStompError = function (frame) {
    log('Broker reported error: ' + frame.headers['message']);
    log('Additional details: ' + frame.body);
    setRecoil(templateWebsocketStatusState, 'DISCONNECTED');
  };

  templateClient.activate();
};

export const close = async () => {
  log('close connection template channel');
  setRecoil(templateWebsocketStatusState, 'DISCONNECTED');
  clearTimeout(heartbeatTimer);

  if (templateClient) await templateClient.deactivate();
};

const setHeartBeat = () => {
  setRecoil(templateWebsocketStatusState, 'CONNECTED');
  clearTimeout(heartbeatTimer);
  heartbeatTimer = setTimeout(() => {
    if (isConnected) {
      setHeartBeat();
    } else {
      resetConnection(templateTagId);
    }
  }, HEARTBEAT_INTERVAL);
};

export const resetConnection = async (templateId: string) => {
  templateTagId = templateId;
  clearTimeout(heartbeatTimer);
  setRecoil(templateWebsocketStatusState, 'DISCONNECTED');
  if (templateClient) await templateClient.deactivate();

  open(templateId, noDataFetch);
};

const onMessage = (message) => {
  const body = message.body ? JSON.parse(message.body) : undefined;

  log('template channel event', body?.event);

  if (body?.event !== 'NOTIFICATION') {
    log('template channel body', body);
  }

  if (body && body.event) handleEvent(body);
};

const handleEvent = (body) => {
  log('----- Template ------');
  log('------ Template -----');
  log(body);
  log('------ X -----');
  log('------ X -----');

  switch (body.event) {
    case 'TEMPLATE_TASK_ADDED':
      body.parenttaskid
        ? addSubTask(
            body.taskid ? body.taskid : null,
            body.parenttaskid ? body.parenttaskid : null,
            body.TEMPLATE_TASK ? body.TEMPLATE_TASK : {},
            body.tempid ? body.tempid : null,
            stateType,
          )
        : addTask(body.TEMPLATE_TASK ? body.TEMPLATE_TASK : {}, stateType);
      break;
    case 'TEMPLATE_TASK_NAME_CHANGED': {
      let bodyTaskName = body.name ? body.name : '';
      const searchRegExp = /:c/gi;
      const replaceWith = '';
      if (bodyTaskName !== '') {
        bodyTaskName = bodyTaskName.replace(searchRegExp, replaceWith);
      }

      updateTaskName(
        body.id ? body.id : null,
        body.tempid ? body.tempid : null,
        bodyTaskName,
        body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    }
    case 'TEMPLATE_PROJECT_TASK_ASSIGNEE_CHANGED':
      updateTaskAssignee(
        body.taskid ? body.taskid : null,
        body.assigneeid ? body.assigneeid : null,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TEMPLATE_TASK_SIZE_CHANGED':
      updateTaskSize(
        body.taskid ? body.taskid : null,
        body.SIZE ? body.SIZE : 'M',
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TEMPLATE_TASK_DELETED':
      removeTask(
        body.TASK_TEMPLATE_ID ? body.TASK_TEMPLATE_ID : null,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TAG_ASSIGNED':
      addTaskTags(
        body.task && body.task.id ? body.task.id : undefined,
        body?.task?.tags ? body?.task?.tags : [],
        body?.task?.tagInfo ? body?.task?.tagInfo : [],
        true,
        body.parenttaskid ? body.parenttaskid : null,
        body?.tag ? body?.tag : undefined,
        stateType,
        body.task?.taskType ? body?.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body?.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'TAG_UNASSIGNED':
      addTaskTags(
        body.task && body.task.id ? body.task.id : undefined,
        body?.task?.tags ? body?.task?.tags : [],
        body?.task?.tagInfo ? body?.task?.tagInfo : [],
        false,
        body.parenttaskid ? body.parenttaskid : null,
        body?.tag ? body?.tag : undefined,
        stateType,
        body.task?.taskType ? body?.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body?.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'PROJECT_TEMPLATE_TAG_ADDED':
      addTemplateTag(body.TAG_TEMPLATE ? body.TAG_TEMPLATE : undefined, true);
      break;
    case 'PROJECT_TEMPLATE_TAG_REMOVED':
      addTemplateTag(body.TAG_TEMPLATE ? body.TAG_TEMPLATE : undefined, false);
      break;
    case 'RECURRING_TASK_ADD':
      addRecurringTask(body.RECURRING_TASK, stateType);
      break;
    case 'RECURRING_TASK_UPDATED':
      updateRecurringTask(body.RECURRING_TASK, stateType);
      break;
    case 'TEMPLATE_TASK_SEQUENCE_CHANGED':
      body.parenttaskid
        ? updateSubTaskSequence(
            body.taskid ? body.taskid : null,
            body.sequence ? parseFloat(body.sequence) : 0,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.parenttaskid ? body.parenttaskid : null,
            stateType,
          )
        : updateTaskSequence(
            body.taskid ? body.taskid : null,
            body.sequence ? parseFloat(body.sequence) : 0,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            stateType,
          );
      break;
    case 'TEMPLATE_TASK_COLLAPSE_CHANGED':
      updateCollapseTaskStatus(
        body.taskid ? body.taskid : null,
        body.collapsedSubTasks,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    default:
  }
};
