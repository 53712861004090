import config from 'config/config';
import { Rive, RiveFile, Fit, Alignment, Layout } from '@rive-app/canvas';

import { getCurrentTheme } from 'utils/theme';
import { canBePlayed } from 'triggers/utils/rive';
import { log } from 'utils';

// const STATE_MACHINE = 'state_machine';
let riveInstances: Rive[] = [];

function loadRiveFile(src, onSuccess, onError) {
  const file = new RiveFile({
    src: src,
    onLoad: () => onSuccess(file),
    onLoadError: onError,
  });
  file.init().catch(onError);
}

function setupRiveInstance(loadedRiveFile, canvasId) {
  if (!loadedRiveFile) return null
  const riveInstance = new Rive({
    src: loadedRiveFile?.src,
    canvas: canvasId,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.Center,
    }),
    onLoad: () => {
      riveInstance.resizeDrawingSurfaceToCanvas();
    },
  });

  riveInstances.push(riveInstance);
}

export function playRiveCanvas(id: string, duration: number = 0, props) {
  removeElement(id);
  if (!canBePlayed(id)) return;
  const currentTheme = getCurrentTheme();
  const element = document.getElementById(`task-con-${props.tempId}`);
  if (!element) return;
  const rect = element.getBoundingClientRect();
  const top = rect.top + rect.height / 2 - 13;
  const left = rect.left + 68;

  const div = createCanvas(id, top, left);
  const url = buildRiveUrl(id, currentTheme.id);

  loadRiveFile(
    url,
    (file: any) => {
      setupRiveInstance(file, div);
    },
    (error: any) => {
      log('Failed to load Rive file:', error);
    },
  );

  let span: any;

  if (id === 'complete-task-animation') {
    span = document.createElement('span');
    span.id = `task-${props.tempId}`;
    span.style.cssText = 'position:absolute;inset:0;z-index:298;';
    document.body.appendChild(span);
  }

  setTimeout(() => {
    riveInstances.forEach((instance) => {
      if (instance) {
        instance.cleanup();
      }
    });
    removeElement(id);
    if (span) span.remove();
  }, duration || 1000);
}

window.addEventListener(
  'resize',
  () => {
    riveInstances.forEach((instance) => {
      if (instance) {
        instance.resizeDrawingSurfaceToCanvas();
      }
    });
  },
  false,
);

function createCanvas(id: string, top, left) {
  const div = document.createElement('canvas');
  div.id = getRiveId(id);

  div.style.cssText =
    id === 'complete-task-animation'
      ? `position:fixed;width: calc(100% - 300px);height:500px;bottom:calc(100% - calc(${top}px + 100px));left:300px;z-index:300;background:transparent;`
      : `position:fixed;width: calc(100% - ${left}px - 200px);height:${top - 50}px;top:70px;left:${left}px;z-index:300;background:transparent;`;

  document.body.appendChild(div);

  return div;
}

function getRiveId(id: string) {
  return id + '-rive';
}

function buildRiveUrl(fileName: string, themeId: string) {
  return `/themes/${themeId}/rive/${fileName}.riv?v=${config.version}`;
}

function removeElement(id: string) {
  const link = document.getElementById(getRiveId(id));

  if (link) link.remove();
}
