import { Fragment, useEffect, useRef, useState } from 'preact/compat';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { log } from 'utils';
import { MenuItem, ControlledMenu } from '@szhsin/react-menu';
import { useRecoilState } from 'recoil';
import { faCalendarPlus, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chartLogin as chartLoginAPI } from 'services/AuthService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import moment from 'moment';
import { formatForAnalytics, formatDateForView } from 'utils/task';
import {
  superAdminAnalyticsActiveUsersFilterSelector,
  superAdminAnalyticsMonthlyActiveUsersFilterSelector,
} from 'recoil/SuperAdminAnalyticsState';
import appConfig from 'config/config';
import { dateFilterData, monthlyDateFilterData } from 'utils/analytics';
import { Strings } from 'resources';
type ActiveUsersChartFiltersType = {
  active: {
    $in: boolean[];
  };
  lastLogInDate?: { $gte: { $date: string }; $lt: { $date: string } };
  platform?: { $in: PlatformType[] };
};
export default function ActiveUsersChart(props: { chartId: string; isMonthly: boolean }) {
  const { chartId, isMonthly } = props;
  const dateFilterRef = useRef(null);
  const skipDateClick = useRef(false);
  const [isDateFilterOpen, setDateFilterOpen] = useState(false);

  const platformFilterRef = useRef(null);
  const skipPlatformClick = useRef(false);
  const [isPlatformFilterOpen, setPlatformFilterOpen] = useState(false);

  const [activeUsersFilter, setSuperAdminActiveUsersFilter] = useRecoilState(
    superAdminAnalyticsActiveUsersFilterSelector,
  );

  const [monthlyActiveUsersFilter, setSuperAdminMonthlyActiveUsersFilter] = useRecoilState(
    superAdminAnalyticsMonthlyActiveUsersFilterSelector,
  );

  const chartLogin = async () => {
    const { data, success } = await chartLoginAPI();
    if (success) {
      return data;
    }
    return 'invalid';
  };

  const sdk = new ChartsEmbedSDK({
    baseUrl: appConfig.chartUrl,
    getUserToken: async function () {
      return await chartLogin();
    },
  });

  const activeUsersChartDiv: any = useRef(null);

  const [rendered, setRendered] = useState(false);
  const [activeUsersChart] = useState(
    sdk.createChart({
      chartId: chartId,
      theme: 'light',
      filter: {
        active: {
          $in: [true],
        },
      },
      showAttribution: false,
      autoRefresh: false,
    }),
  );

  useEffect(() => {
    if (activeUsersChart !== null)
      activeUsersChart
        .render(activeUsersChartDiv.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during Charts rendering.', err));
  }, [activeUsersChart]);

  useEffect(() => {
    if (isMonthly) return;
    let filters: ActiveUsersChartFiltersType = {
      active: {
        $in: [true],
      },
    };
    if (rendered && activeUsersFilter) {
      filters = {
        ...filters,
      };

      if (
        activeUsersFilter.lastLogInDate.value !== 'allTime' &&
        activeUsersFilter.lastLogInDate.start &&
        activeUsersFilter.lastLogInDate.end
      ) {
        filters = {
          ...filters,
          lastLogInDate: {
            $gte: {
              $date: activeUsersFilter.lastLogInDate.start,
            },
            $lt: {
              $date: activeUsersFilter.lastLogInDate.end,
            },
          },
        };
      }

      if (activeUsersFilter.platform && activeUsersFilter.platform.length > 0) {
        filters = {
          ...filters,
          platform: {
            $in: activeUsersFilter.platform,
          },
        };
      }

      activeUsersChart
        .setFilter(filters)
        .catch((err) => console.log('Error while filtering.', err));
    }
  }, [activeUsersChart, activeUsersFilter, rendered]);

  useEffect(() => {
    if (!isMonthly) return;
    let filters: ActiveUsersChartFiltersType = {
      active: {
        $in: [true],
      },
    };
    if (rendered && monthlyActiveUsersFilter) {
      filters = {
        ...filters,
      };

      if (
        monthlyActiveUsersFilter.lastLogInDate.start &&
        monthlyActiveUsersFilter.lastLogInDate.end
      ) {
        filters = {
          ...filters,
          lastLogInDate: {
            $gte: {
              $date: monthlyActiveUsersFilter.lastLogInDate.start,
            },
            $lt: {
              $date: monthlyActiveUsersFilter.lastLogInDate.end,
            },
          },
        };
      }

      if (monthlyActiveUsersFilter.platform && monthlyActiveUsersFilter.platform.length > 0) {
        filters = {
          ...filters,
          platform: {
            $in: monthlyActiveUsersFilter.platform,
          },
        };
      }

      activeUsersChart
        .setFilter(filters)
        .catch((err) => console.log('Error while filtering.', err));
    }
  }, [activeUsersChart, monthlyActiveUsersFilter, rendered]);
  const getDateNameForId = () => {
    if (!isMonthly && activeUsersFilter.lastLogInDate.value === 'custom') {
      if (activeUsersFilter.lastLogInDate.start && activeUsersFilter.lastLogInDate.end) {
        return `${formatDateForView(activeUsersFilter.lastLogInDate.start)}~${formatDateForView(
          activeUsersFilter.lastLogInDate.end,
        )}`;
      }
      return 'Custom';
    } else {
      const data = getDateFilterData().find((item) =>
        isMonthly
          ? item.id === monthlyActiveUsersFilter.lastLogInDate.value
          : item.id === activeUsersFilter.lastLogInDate.value,
      );
      return data === undefined ? Strings.selected_date : data.name;
    }
  };

  const getDateFilterData = () => {
    if (isMonthly) return monthlyDateFilterData;
    return dateFilterData;
  };

  const getPlatformFilterData = () => {
    const platformFilter = isMonthly
      ? monthlyActiveUsersFilter.platform
      : activeUsersFilter.platform;

    return platformFilter && platformFilter.length > 0 ? platformFilter : undefined;
  };

  const renderDateFilter = () => {
    return (
      <div
        id='active-users-date-filter-con'
        className='analytics-item-container date-filter-container filter-control'
        key='active-users-date-filter'
      >
        <button
          id='active-users-date-filter-btn'
          className='item-btn active-users-date-btn'
          ref={dateFilterRef}
          onClick={() => !skipDateClick.current && setDateFilterOpen(true)}
          key='active-users-date-filter-btn'
        >
          <span id='active-users-date-filter-selection' className='item-selected'>
            {getDateNameForId()}
          </span>
          {!isDateFilterOpen ? (
            <FontAwesomeIcon
              id='active-users-date-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='active-users-date-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>

        <ControlledMenu
          id='active-users-date-filter-controlled-menu'
          className='date-menu'
          anchorRef={dateFilterRef}
          state={isDateFilterOpen ? 'open' : 'closed'}
          key='active-users-date-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipDateClick.current = true;
              setTimeout(() => (skipDateClick.current = false), 300);
            }
            if (reason !== 'click') {
              setDateFilterOpen(false);
            }
          }}
        >
          {getDateFilterData().map((dateList, _index) => (
            <MenuItem
              id={`active-users-date-filter-menu-item-${dateList.id}`}
              key={`active-users-date-filter-menu-item${dateList.id}`}
              className='date-filter-item'
              onClick={() => {
                isMonthly ? updateMonthlyDateFilter(dateList.id) : updateDateFilter(dateList.id);
                setDateFilterOpen(false);
              }}
              disabled={dateList.id === activeUsersFilter.lastLogInDate.value}
              value={dateList.id}
            >
              <span>{dateList.name}</span>
              <span className='sub-title'>Filter by {dateList.name}</span>
            </MenuItem>
          ))}

          <MenuItem
            id='active-users-date-filter-menu-item-custom'
            className='custom-item'
            key='active-users-custom-date'
            value={null}
          >
            {renderCustomDateForActiveUsers()}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const onChangeDateFilter = (dates) => {
    const [start, end] = dates;

    if (start && end) {
      setStartDateFilter(null);
      setEndDateFilter(null);
      setDateFilterOpen(false);
      isMonthly
        ? updateMonthlyDateFilter('custom', start, end)
        : updateDateFilter('custom', start, end);
    } else {
      setStartDateFilter(start);
      setEndDateFilter(end);
    }
  };

  const updateMonthlyDateFilter = (
    dateString: TimePeriodFilterType | 'custom',
    startDate?: Date,
    endDate?: Date,
  ) => {
    let filter: SuperAdminAnalyticsMonthlyActiveUsersFilterType = { ...monthlyActiveUsersFilter };

    if (dateString === 'last6Months') {
      const firstDay = moment().subtract(5, 'months').startOf('month');
      const lastDay = moment().endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...filter,
        lastLogInDate: {
          value: 'last6Months',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'last12Months') {
      const firstDay = moment().subtract(11, 'months').startOf('month');
      const lastDay = moment().endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...filter,
        lastLogInDate: {
          value: 'last12Months',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'custom' && startDate && endDate) {
      log('date', formatForAnalytics(startDate), formatForAnalytics(endDate));
      filter = {
        ...filter,
        lastLogInDate: {
          value: 'custom',
          start: formatForAnalytics(startDate),
          end: formatForAnalytics(endDate),
        },
      };
    }
    setSuperAdminMonthlyActiveUsersFilter(filter);
  };
  const updateDateFilter = (
    dateString: DateFilterType | 'allTime' | 'custom',
    startDate?: Date,
    endDate?: Date,
  ) => {
    let filter: SuperAdminAnalyticsActiveUsersFilterType = { ...activeUsersFilter };

    if (dateString === 'today') {
      const firstDay = moment().startOf('day');
      const lastDay = moment().endOf('day');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'today',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'yesterday') {
      const firstDay = moment().subtract(1, 'day').startOf('day');
      const lastDay = moment().subtract(1, 'day').endOf('day');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'yesterday',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'lastMonth') {
      const firstDay = moment().add(-1, 'months').startOf('month');
      const lastDay = moment().add(-1, 'months').endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'lastMonth',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'currentMonth') {
      const firstDay = moment().startOf('month');
      const lastDay = moment().endOf('month');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'currentMonth',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'yearToDate') {
      const firstDay = moment().startOf('year');
      const lastDay = moment();
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'yearToDate',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'previousYear') {
      const firstDay = moment().add(-1, 'years').startOf('year');
      const lastDay = moment().add(-1, 'years').endOf('year');
      log('date', formatForAnalytics(firstDay), formatForAnalytics(lastDay));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'previousYear',
          start: formatForAnalytics(firstDay),
          end: formatForAnalytics(lastDay),
        },
      };
    } else if (dateString === 'allTime') {
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'allTime',
        },
      };
    } else if (dateString === 'custom' && startDate && endDate) {
      log('date', formatForAnalytics(startDate), formatForAnalytics(endDate));
      filter = {
        ...activeUsersFilter,
        lastLogInDate: {
          value: 'custom',
          start: formatForAnalytics(startDate),
          end: formatForAnalytics(endDate),
        },
      };
    }
    setSuperAdminActiveUsersFilter(filter);
  };
  const renderCustomDateForActiveUsers = () => {
    return (
      <div
        id='active-users-date-filter-custom-date-con'
        className='analytics-item-container custom-date-container'
        key='custom-date-con'
      >
        <div id='active-users-date-filter-date-picker'>
          <DatePicker
            selected={startDateFilter}
            onChange={onChangeDateFilter}
            startDate={startDateFilter}
            endDate={endDateFilter}
            selectsRange
            selectsEnd={true}
            selectsStart={true}
            shouldCloseOnSelect={false}
            id='custom-date-id'
            popperPlacement='bottom-end'
            calendarClassName='date-range-calendar-con'
            wrapperClassName='custom-date'
            customInput={
              <div>
                <FontAwesomeIcon size='lg' className='calendar-icon' icon={faCalendarPlus} /> Custom
                {Strings.date}
              </div>
            }
          />
        </div>
      </div>
    );
  };

  const updateFilter = (name: string, value?: PlatformType) => {
    if (name === 'platformFilter') {
      let platformList: PlatformType[] = [];
      if (value !== undefined) {
        platformList = [value];
        const platformFilter = getPlatformFilterData();
        if (platformFilter) {
          const index = platformFilter.findIndex((item) => item === value);
          if (index === -1) {
            platformList = [...platformFilter, ...platformList];
          } else {
            platformList = platformFilter.filter((item) => item !== value);
          }
        }
      }
      isMonthly
        ? setSuperAdminMonthlyActiveUsersFilter({
            ...monthlyActiveUsersFilter,
            platform: platformList.length > 0 ? platformList : undefined,
          })
        : setSuperAdminActiveUsersFilter({
            ...activeUsersFilter,
            platform: platformList.length > 0 ? platformList : undefined,
          });
      return;
    }
  };

  /*
   * Render checkbox
   *
   * returns React.DOM
   */
  const renderCheckbox = (item: PlatformType, filter?: PlatformType[]) => {
    return (
      <div className='checkbox-con' key={`checkbox-con-${item}`}>
        <input
          autoComplete='off'
          data-lpignore='true'
          data-form-type='other'
          checked={filter && filter.length > 0 ? filter.includes(item) : false}
          className='inp-cbx'
          id={`checkbox-${item}`}
          type='checkbox'
          onChange={() => {}}
          style={{ display: 'none' }}
        />

        <label className='cbx'>
          <span>
            <svg width='12px' height='10px' viewBox='0 0 12 10' fill='#000000'>
              <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
            </svg>
          </span>
          <span></span>
        </label>
      </div>
    );
  };

  const renderPlatformFilter = () => {
    return (
      <div
        id='platform-filter-con'
        className='analytics-item-container platform-container filter-control'
        key='platform-filter'
      >
        <button
          id='platform-filter-menu-button'
          className='item-btn platform-btn'
          ref={platformFilterRef}
          onClick={() => !skipPlatformClick.current && setPlatformFilterOpen(true)}
          key='platform-filter-btn'
        >
          <span className='item-selected'>
            {getPlatformFilterData() ? Strings.platform_selected : Strings.filter_by_device}
          </span>
          {!isPlatformFilterOpen ? (
            <FontAwesomeIcon
              id='assignee-filter-down-icon'
              className='drop-icon down'
              icon={faChevronDown}
            />
          ) : (
            <FontAwesomeIcon
              id='assignee-filter-up-icon'
              className='drop-icon up'
              icon={faChevronUp}
            />
          )}
        </button>
        <ControlledMenu
          className='item-menu'
          anchorRef={platformFilterRef}
          state={isPlatformFilterOpen ? 'open' : 'closed'}
          key='assignee-filter-menu'
          onClose={({ reason }) => {
            if (reason === 'blur') {
              skipPlatformClick.current = true;
              setTimeout(() => (skipPlatformClick.current = false), 300);
            }
            if (reason !== 'click') {
              setPlatformFilterOpen(false);
            }
          }}
        >
          <MenuItem
            id={`platform-filter-menu-item-android`}
            key={`platform-filter-menu-item-android`}
            onClick={() => updateFilter('platformFilter', 'android')}
            value={'android'}
            className='platform-filter-menu-item'
          >
            {renderCheckbox('android', getPlatformFilterData())}
            {Strings.android}
          </MenuItem>
          <MenuItem
            id={`platform-filter-menu-item-ios`}
            key={`platform-filter-menu-item-ios`}
            onClick={() => updateFilter('platformFilter', 'ios')}
            value={'ios'}
            className='platform-filter-menu-item'
          >
            {renderCheckbox('ios', getPlatformFilterData())}
            {Strings.ios}
          </MenuItem>
          <MenuItem
            id={`platform-filter-menu-item-web`}
            key={`platform-filter-menu-item-web`}
            onClick={() => updateFilter('platformFilter', 'web')}
            value={'web'}
            className='platform-filter-menu-item'
          >
            {renderCheckbox('web', getPlatformFilterData())}
            {Strings.web}
          </MenuItem>
          <MenuItem
            id='platform-filter-menu-item-remove'
            className='remove-item'
            key='platform-filter-all'
            onClick={() => updateFilter('platformFilter')}
            value={null}
          >
            {Strings.reset_filter}
          </MenuItem>
        </ControlledMenu>
      </div>
    );
  };

  return (
    <Fragment>
      <div id='super-admin-analytics-active-users-graph-heading-con' className='graph-heading'>
        {renderDateFilter()}
        {renderPlatformFilter()}
      </div>
      <div id='super-admin-analytics-active-users-graph-con' className='graph-con'>
        <div className={`chart `} ref={activeUsersChartDiv} />
      </div>
    </Fragment>
  );
}
