import { useState } from 'preact/compat';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { subscribePayment as subscribePaymentAPI } from 'services/SubscriptionService';
import { useSetRecoilState } from 'recoil';

import Spinner from 'components/UI/Spinner';
import { showUpgradeModalState, showPricingModalState } from 'recoil/NavigationState';
import { getSubscriptionDetail } from 'recoil/AccountState/update';
import {
  reFetchUserSession,
} from 'services/AuthService';

import '../CheckoutForm/_checkout-form.scss';
const SubscribeForm = ({ setupIntentData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState(false);
  const [isPayLoading, setIsPayLoading] = useState(true);

  const setShowUpgradeModal = useSetRecoilState(showUpgradeModalState);
  const setShowPricingModal = useSetRecoilState(showPricingModalState);

  const handleSubscribe = async () => {
    if (!stripe || !elements || !setupIntentData.clientSecret) return;

    setLoading(true);
    setMessage('');

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) throw new Error(submitError.message);

      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        clientSecret: setupIntentData.clientSecret,
        confirmParams: { return_url: window.location.origin },
        redirect: 'if_required',
      });
      if (error) throw new Error(error.message);

      if (setupIntent && setupIntent.status === 'succeeded' && setupIntent.payment_method) {
        const { success } = await subscribePaymentAPI({
          payment_id: setupIntent.payment_method,
          customer_id: setupIntentData.customerId,
        });

        if (!success) {
          throw new Error('Subscription failed. Please try again.');
        } else {
          setShowPricingModal(false);
          await reFetchUserSession();
          setShowUpgradeModal('SUCCESS');
        }
      } else {
        throw new Error('An unexpected error occured.');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.';
      setMessage(errorMessage);
      setShowUpgradeModal('FAILED');
    } finally {
      await getSubscriptionDetail();
      setLoading(false);
    }
  };

  return (
    <form id='subscribe-form' className='payment-form' onSubmit={handleSubscribe}>
      {isPayLoading ? <Spinner /> : null}
      {/* @ts-ignore*/}
      <PaymentElement
        id='payment-element'
        className='payment-element'
        onReady={() => setIsPayLoading(false)}
      />
      <button
        className='payment-button'
        onClick={handleSubscribe}
        disabled={loading || isPayLoading || !stripe || !elements}
      >
        {loading ? 'Subscribing...' : 'Subscribe'}
      </button>
      {message && (
        <div id='payment-message' className='payment-message'>
          {message}
        </div>
      )}
    </form>
  );
};

export default SubscribeForm;
