import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/**
 * get all workspaces for super admin
 */
export async function getWorkspaces(payload: any, abortController: AbortController) {
  try {
    const response = await appClient.post(apiConfig.super_admin.get_workspaces, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get workspace user for super admin
 */
export async function getSuperAdminWorkspaceUsers(workspaceId: string, payload: any) {
  const url = `${apiConfig.super_admin.get_workspace_user}/${workspaceId}`;
  try {
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Activate deactivate workspace
 * workspace id String
 * active Boolean
 *
 * returns Object {data, error, status}
 */
export async function activateDeactivateWorkspace(
  workspaceId: string,
  active: boolean,
): Promise<AsyncResponseType> {
  const url = `${apiConfig.super_admin.activate_deactivate_workspace}/${workspaceId}/status/${active}`;

  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Export Companies
 *
 * returns void
 */
export async function exportCompanies() {
  const url = `${apiConfig.super_admin.export_companies}`;
  appClient.defaults.timeout = 400000;
  try {
    const response = await appClient.get(url);
    const disposition = response.headers?.get('Content-Disposition');
    let filename = 'companies.csv';
    if (disposition && disposition.includes('filename=')) {
      const filenameMatch = disposition.match(/filename="(.+)"/);
      if (filenameMatch.length > 1) {
        filename = filenameMatch[1];
      }
    }
    const { success, data } = hasSuccess(response.data);
    if (success) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      link.remove();
    }
  } catch (error) {
    const { message } = hasError(error);
    console.log(message);
  }
}
