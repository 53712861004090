// import { useCallback } from 'preact/compat';
// import { Strings } from 'resources';
// import { Menu, MenuButton } from '@szhsin/react-menu';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { MenuItem } from '@szhsin/react-menu';
import CompletedMonthlyChart from './CompletedMonthlyChart';
import CompletedUserChart from './CompletedUserChart';
import appConfig from 'config/config';

export default function Analytics() {
  // const tasksCompletedFilter = { type: '' };
  // const dataType = [
  //   { id: 'task', name: 'Task' },
  //   { id: 'points', name: 'Points' },
  // ];
  // const getTypeNameForId = useCallback(() => {
  //   const index = dataType.findIndex((date) => date.id === tasksCompletedFilter.type);
  //   if (index !== -1) return dataType[index].name;
  //   return tasksCompletedFilter.type;
  // }, [tasksCompletedFilter]);
  // const updateTypeFilter = (name, value, filter) => {
  //   log('updateTypeFilter', name, value, filter);
  // let intervalFilter = filter;
  // intervalFilter = { ...intervalFilter, type: value };
  // dispatch(AccountActions.setAnalyticsFilter(name, intervalFilter));
  // if (
  //   name === 'tasksCompleted' &&
  //   tasksCompletedFilter.interval !== 'total' &&
  //   taskCompletedData
  // ) {
  //   saveLineData(taskCompletedData, getTimeInterval(tasksCompletedFilter.interval), value, false);
  // }
  // };
  // const getTypeName = (filterName: string) => {
  //   switch (filterName) {
  //     case 'tasksCompleted':
  //       return getTypeNameForId();
  //     // case 'avgTasksCompleted':
  //     //   return getNameForAvg();
  //     // case 'grpAvgTasksCompleted':
  //     //   return getNameForGrpAvg();
  //     default:
  //       return;
  //   }
  // };
  // const renderDataTypeMenu = (filter, filterName) => {
  //   return (
  //     <div
  //       id={`analytics-filter-menu-con-${filterName}`}
  //       className='analytics-item-container graph-type-container'
  //     >
  //       <Menu
  //         id={`analytics-filter-menu-${filterName}`}
  //         className='type-menu'
  //         key='type-filter-menu'
  //         menuButton={
  //           <MenuButton
  //             id={`analytics-filter-menu-button-${filterName}`}
  //             className='item-btn graph-type-btn filter-btn'
  //           >
  //             <span
  //               id={`analytics-filter-btn-titile-${filterName}`}
  //               className='item-selected filter-btn-title'
  //             >
  //               {filter && filter.type ? getTypeName(filterName) : Strings.task}
  //             </span>
  //             <FontAwesomeIcon
  //               id={`analytics-filter-chevron-icon-${filterName}`}
  //               className='drop-icon'
  //               icon={faChevronDown}
  //             />
  //           </MenuButton>
  //         }
  //       >
  //         {dataType.map((typeList, index) => (
  //           <MenuItem
  //             id={`analytics-filter-item-${index}`}
  //             key={typeList.id}
  //             onClick={() => {
  //               updateTypeFilter(filterName, typeList.id, filter);
  //             }}
  //             disabled={typeList.id === filter.type}
  //             value={typeList.id}
  //           >
  //             <div id={`analytics-filter-item-con-${index}`} className='menu-con'>
  //               <span id={`analytics-filter-item-title-${index}`} className='menu-title'>
  //                 {typeList.name}
  //               </span>
  //             </div>
  //           </MenuItem>
  //         ))}
  //       </Menu>
  //     </div>
  //   );
  // };

  const renderUserCompleted = () => {
    return (
      <div id='analytics-task-completed-graph-card-con' className='analytics-card-con'>
        <CompletedUserChart
          pointsChartId={appConfig.chartIds.completedUserPoints}
          tasksChartId={appConfig.chartIds.completedUserTasks}
        />
      </div>
    );
  };

  const renderMonthlyCompleted = () => {
    return (
      <div id='analytics-task-completed-graph-card-con' className='analytics-card-con'>
        <CompletedMonthlyChart
          pointsChartId={appConfig.chartIds.completedMonthlyPoints}
          tasksChartId={appConfig.chartIds.completedMonthlyTasks}
        />
      </div>
    );
  };
  return (
    <div id='analytics-con' className='analytics-con'>
      <div id='analytics-con-row-1' className='row'>
        <div id='analytics-con-row-1-col-1' className='column'>
          {renderUserCompleted()}
        </div>
        <div id='analytics-con-row-1-col-2' className='column'>
          {renderMonthlyCompleted()}
        </div>
      </div>
    </div>
  );
}
