import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import SmallButton from 'components/UI/SmallButton';

import { showPricingModalState, showUpgradeModalState } from 'recoil/NavigationState';
import { UPGRADE_MODAL } from 'utils/constants';

import { Strings } from 'resources';
import './_upgrade-modal.scss';

type UpgradeModalPropsTypes = {
  modalType: UpgradeModalType;
};

const UpgradeModal = ({ modalType }: UpgradeModalPropsTypes) => {
  const data = UPGRADE_MODAL[modalType];
  const setShowPricingModal = useSetRecoilState(showPricingModalState);
  const setShowUpgradeModal = useSetRecoilState(showUpgradeModalState);
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowUpgradeModal(null);
    setShowPricingModal(true);
  };

  const renderButtons = () => {
    switch (modalType) {
      case 'SUCCESS':
        return (
          <SmallButton
            btnType='um-btn um-upgrade-btn'
            clicked={() => {
              setShowUpgradeModal(null);
              navigate('/admin/subscription');
            }}
          >
            {Strings.go_to_dashboard}
          </SmallButton>
        );
      case 'FAILED':
        return (
          <SmallButton btnType='um-btn um-upgrade-btn' clicked={() => toggleModal()}>
            {Strings.retry_subscription}
          </SmallButton>
        );
      default:
        return (
          <SmallButton btnType='um-btn um-upgrade-btn' clicked={() => toggleModal()}>
            {Strings.learn}
          </SmallButton>
        );
    }
  };

  return (
    <>
      <div className='upgrade-modal-container'>
        <div className='main-container'>
          <div className='um-close-con'>
            <button
              className='close-btn'
              onClick={() => {
                setShowUpgradeModal(null);
              }}
            ></button>
          </div>
          <div className='um-featured-container'>
            <img src={data?.featuredImage} alt='modal featured' className='um-featured-image' />
          </div>
          <div className='um-content'>
            <h2
              className='um-title'
              dangerouslySetInnerHTML={{
                __html: data?.title,
              }}
            />
            <p className='um-description'>{data?.description}</p>
          </div>
          <div className='um-benefits-container'>
            <p className='um-benefits-title'>{data?.benefits?.title}</p>
            <ul className='um-benefits-list'>
              {data?.benefits?.list.map((benefit, i) => (
                <li key={`umb${i}`} className='um-benefit'>
                  <p className='um-benefit-text'>{benefit}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className='um-button-container'>
            {renderButtons()}
            {/* <button className='um-btn um-upgrade-btn'>{Strings.upgrade}</button> */}
          </div>
        </div>
      </div>
      <div className='upgrade-modal-bg' onClick={() => setShowUpgradeModal(null)}></div>
    </>
  );
};

export default UpgradeModal;
