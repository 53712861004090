import { getCurrentTheme } from 'utils/theme';
import { log } from 'utils';

export const playAnimation = async (triggerId: string, props?) => {
  try {
    const currentTheme = getCurrentTheme();
    const canvas = await import(`./../themes/${currentTheme.id}/canvas.ts`);

    if (canvas && canvas.runAnimation) canvas.runAnimation(triggerId, props);
  } catch (error) {
    log(error);
  }
};

export const loadCanvasAnimations = async (themeId: string) => {
  try {
    await import(`./../themes/${themeId}/canvas.js`);
  } catch (error) {
    log(error);
  }
};
