import { TargetedEvent, useState } from 'preact/compat';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Validation, log } from 'utils';
import Button from 'components/UI/Button';
import { Strings } from 'resources';
import Input from 'components/UI/Input';
import { resetPassword as resetPasswordAPI } from 'services/AuthService';

export default function ResetPassword() {
  const navigate = useNavigate();
  const alert = useAlert();
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const location = useLocation();
  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      name: Strings.new_password,
      value: '',
      type: 'text',
      touched: false,
      secure: true,
      maxLength: 256,
      key: 'newPassword',
      rules: 'required|no_space|min:8|max:256',
      placeholder: Strings.new_password,
    },
    {
      name: Strings.confirm_password,
      value: '',
      type: 'text',
      secure: true,
      touched: false,
      maxLength: 256,
      key: 'confirm-password',
      rules: 'required|no_space|match_index:0|min:8|max:256',
      placeholder: Strings.confirm_password,
    },
  ]);

  const submit = (event: TargetedEvent) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;

    callResetPasswordApi(getPayload());
  };

  const getQuery = () => {
    return new URLSearchParams(location.search);
  };

  const getPayload = () => {
    const payload = {};
    const query = getQuery();
    payload['newPassword'] = fields[0].value;
    payload['userName'] = query.get('userName');
    payload['passwordResetToken'] = query.get('token');
    return payload;
  };

  const callResetPasswordApi = async (payload: any) => {
    setLoadingState(true);
    const { data, success, message } = await resetPasswordAPI(payload);
    setLoadingState(false);
    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return resetPassSuccess(data);
    }
  };

  const resetPassSuccess = (data: any) => {
    log('response data', data);
    alert.success(data.responseMessage);
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  const fieldUpdated = (text: string, index: number) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const renderField = (field: ValidationFieldType, index: number) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder ? field.placeholder : ''}
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={field.touched}
        value={field.value as string}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        secure={field.secure}
      />
    );
  };

  return (
    <div className='reset-pass-screen-container'>
      <div className='forgot-pass-form'>
        <div className='magic-task-logo' alt='Logo' />
        <form
          className='forgot-pass-form-con reset-pass-container'
          onSubmit={(event) => submit(event)}
        >
          <h1 className='forgot-pass-header'>{Strings.reset_password}</h1>
          {fields.map((field, index) => renderField(field, index))}

          <div className='btn'>
            <Button
              idName='reset-password-screen-reset-btn'
              disabled={hasErrors}
              loading={isLoading}
            >
              {' '}
              {Strings.reset}{' '}
            </Button>
          </div>
        </form>
      </div>
      <div className='forgot-pass-img-con'></div>
    </div>
  );
}
