import { Suspense, useEffect, Fragment } from 'preact/compat';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  allProjectsState,
  currentProjectIdState,
  projectDataSelector,
  searchProjectUsersState,
} from 'recoil/ProjectState/index';
import { triggerDataSelector } from 'recoil/TriggerState';
import BgAnimation from 'animations/BgAnimation';
import TopTitleBar from 'components/TopTitleBar/index';
import Spinner from 'components/UI/Spinner/index';
import TaskList from './TaskList';
import { navigationDataSelector } from 'recoil/NavigationState';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { open as openProjectSocket, close as closeProjectSocket } from 'websocket/project';
import { open as openUserSocket, close as closeUserSocket } from 'websocket/user';
import { initializeTaskFilter } from 'recoil/TaskState/update';
import BottomBar from 'components/BottomBar';
import { log, setPageTitle } from 'utils';
import { currentUserIdState } from 'recoil/UserState';
import { accountState, allUsersState, accountDataSelector } from 'recoil/AccountState';
import { currentTemplateIdState, templateDataSelector } from 'recoil/TemplateState';
import { open as openTemplateSocket, close as closeTemplateSocket } from 'websocket/template';
import { getActiveProjects } from 'utils/task';
import { isAdmin } from 'services/AuthService';
import { getItem } from 'utils/storage';
import { getMemberProjects } from 'recoil/ProjectState/update';

function Tasks() {
  const props: TasksPropsType = useOutletContext();
  const { stateType, readOnly } = props;
  const { projectId, userId, templateId } = useParams();
  const projectData = useRecoilValue(projectDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const templateData = useRecoilValue(templateDataSelector);
  const triggerData = useRecoilValue(triggerDataSelector);
  const accountData = useRecoilValue(accountDataSelector);
  const setCurrentProjectId = useSetRecoilState(currentProjectIdState);
  const setUserInProjectSearch = useSetRecoilState(searchProjectUsersState);
  const setCurrentUserId = useSetRecoilState(currentUserIdState);
  const setCurrentTemplateId = useSetRecoilState(currentTemplateIdState);
  const allUsers = useRecoilValue(allUsersState);
  const allProjects = useRecoilValue(allProjectsState);

  const navigate = useNavigate();
  const location = useLocation();
  const account = useRecoilValue(accountState);
  const userRole = account?.role;

  useEffect(() => {
    if (
      userRole &&
      !isAdmin() &&
      (navigationData.currentRoute.includes('project-templates') ||
        navigationData.currentRoute.includes('user'))
    )
      navigate('/');
  }, [userRole]);

  // user
  useEffect(() => {
    log('^^^ USER WS CONNECT ^^^');
    log('connect user 66', userId);

    if (userId !== null && userId !== undefined) {
      setCurrentUserId(userId);
      initializeTaskFilter('tagFilter', stateType, userId);
      openUserSocket(userId, readOnly); // open websocket connection
      getMemberProjects(userId);
    }

    return () => {
      log('disconnect user 66', userId);
      setCurrentUserId(null);
      closeUserSocket();
    };
  }, [userId]);

  useEffect(() => {
    if (userId !== null && userId !== undefined && allUsers) {
      if (allUsers.findIndex((item) => item.id === userId) === -1) {
        navigate('/');
        return;
      }
    }
  }, [userId, allUsers]);

  const getUserName = () => {
    if (allUsers === null) return '';
    const index = allUsers.findIndex((item) => item.id === userId);

    if (index === -1) return '';

    return allUsers[index].display ?? '';
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'INDIVIDUAL') setPageTitle(getUserName());
  }, [getUserName()]);

  // Project id
  useEffect(() => {
    if (projectId !== null && projectId !== undefined) {
      setCurrentProjectId(projectId);
      initializeTaskFilter('assigneeFilter', stateType, projectId);
      initializeTaskFilter('tagFilter', stateType, projectId);
      openProjectSocket(projectId, readOnly); // open websocket connection
    }
    return () => {
      setCurrentProjectId(null);
      closeProjectSocket();
      setUserInProjectSearch([]);
    };
  }, [projectId]);

  useEffect(() => {
    if (projectId !== null && projectId !== undefined && projectData.allProjects !== null) {
      const projects = getActiveProjects(projectData.allProjects).filter(
        (proj) => proj.id === projectId,
      );
      const { data, error } = getItem('addedProject');

      if (projects.length === 0) {
        if (!error && data && data === projectId) {
          return;
        }
        navigate('/');
        return;
      }
    }
  }, [projectId, projectData.allProjects]);

  useEffect(() => {
    if (templateId !== null && templateId !== undefined) {
      setCurrentTemplateId(templateId);
      openTemplateSocket(templateId, readOnly); // open websocket connection
    }
    return () => {
      setCurrentTemplateId(null);
      closeTemplateSocket();
    };
  }, [templateId]);

  useEffect(() => {
    if (templateId !== null && templateId !== undefined && templateData.allTemplates !== null) {
      if (templateData.allTemplates.findIndex((item) => item.id === templateId) === -1) {
        navigate('/');
        return;
      }
    }
  }, [templateId, templateData.allTemplates]);

  const getProjectName = () => {
    const index = allProjects ? allProjects.findIndex((item) => item.id === projectId) : -1;

    if (index === -1) return '';

    return allProjects ? allProjects[index].display ?? '' : '';
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'PROJECT') setPageTitle(getProjectName());
  }, [getProjectName()]);

  const findElementAndScroll = (task: string) => {
    const ele = document.getElementById(task);
    if (ele) {
      log('found ele');
      ele.scrollIntoView({ block: 'center' });
      ele.className = 'search-item';

      setTimeout(() => {
        document.addEventListener('scroll', function (_e) {
          navigate('', { replace: true, state: '' });
          if (ele) ele.className = '';
          navigate('', { replace: true, state: '' });
          document.removeEventListener('scroll', function (_e) {});
        });
      }, 2000);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.taskId) {
      log('task received in Tasks');
      setTimeout(() => {
        if (!findElementAndScroll(location.state.taskId)) {
          setTimeout(() => {
            findElementAndScroll(location.state.taskId);
          }, 7000);
        }
      }, 5000);
    }
  }, [location]);

  const renderContent = () => {
    if (stateType === 'PROJECT' && projectData && projectData.error) {
      return (
        <div className='task-fetch-error' id='task-fetch-error'>
          <h2>{projectData.error}</h2>
        </div>
      );
    }

    return (
      <Fragment>
        {triggerData?.riveTriggers?.bgAnimationHigh &&
          accountData?.accountSettings?.userAnimationSettings?.bgAnimationHigh && (
            <BgAnimation className='rive-bg-animation' />
          )}
        <TopTitleBar stateType={stateType} idName='tasks-top-title' readOnly={false} />
        <Suspense
          fallback={
            <div className='tasks-loader-con' id='tasks-loader-con'>
              <Spinner />
            </div>
          }
        >
          <TaskList stateType={stateType} readOnly={readOnly} />
        </Suspense>
        <BottomBar idName='tasks-bottom-bar' />
      </Fragment>
    );
  };

  return (
    <div
      className={`tasks-screen  ${navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''} ${
        navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
      }`}
      id='tasks-screen'
    >
      {renderContent()}
    </div>
  );
}

export default Tasks;
