import { useState, useEffect } from 'preact/hooks';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  isUserLoggedIn,
  updateUserIntegrationId,
  userLogin as userLoginAPI,
  loginUserInApp,
} from 'services/AuthService';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { getThemeSlug, setTheme } from 'utils/theme';
import { Validation, log } from 'utils';
import { parseUserObject } from 'utils/user';
import { Strings } from 'resources';
import { useAlert } from 'react-alert';
// import rg4js from 'raygun4js';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function Login() {
  const history = useNavigate();
  const alert = useAlert();
  const location = useLocation();

  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      name: Strings.email_address,
      key: 'username',
      value: '',
      type: 'text',
      maxLength: 50,
      rules: 'required|email|max:50',
      touched: false,
      placeholder: Strings.account_email_placeholder,
    },
    {
      name: Strings.password,
      key: 'password',
      value: '',
      type: 'text',
      maxLength: 256,
      rules: 'required|no_space|min:8|max:256',
      touched: false,
      secure: true,
      placeholder: Strings.password,
    },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);

  useEffect(() => {
    async function checkStatus() {
      const { status } = isUserLoggedIn();
      // user logged in
      if (status) {
        await updateIntegrationId();
        if (
          location &&
          location.state &&
          location.state.path &&
          location.state.path.startsWith('/personal-info')
        ) {
          history('/', location.state);
        } else {
          history('/');
        }
      }
    }
    checkStatus();
  });

  const getQuery = () => {
    return new URLSearchParams(location.search);
  };

  const getIntegrationId = () => {
    const query = getQuery();
    return query.get('id');
  };

  const updateIntegrationId = async () => {
    const integrationId = getIntegrationId();

    if (integrationId) await updateUserIntegrationId(integrationId);
  };

  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const renderField = (field: ValidationFieldType, index: number) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder ? field.placeholder : ''}
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={field.touched}
        value={field.value as string}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        secure={field.secure}
      />
    );
  };

  const submit = (event) => {
    event.preventDefault();

    if (hasErrors || isLoading) return;

    userLogin(getPayload(fields));
  };

  /*
   * User login by calling backend
   * payload object {email, password}
   *
   * returns null
   */
  const userLogin = async (payload) => {
    setLoadingState(true);

    const { data, success, message } = await userLoginAPI(payload);

    setLoadingState(false);

    if (!success) {
      if (data && data.responseData && data.responseData.navigateToCreateWorkspace) {
        routeChange(getPayloadWorkspaceCreation());
        return log(message);
      }
      alert.error(message);
      return log(message);
    } else {
      setTheme(getThemeSlug(data.user.activeThemeSlug), 'login');
      return userLoginSuccess(data);
    }
  };

  /*
   * Get payload for account creation
   * fields Array
   *
   * returns Object
   */
  const getPayloadWorkspaceCreation = () => {
    const payload = {};

    fields.forEach((field, _index) => {
      if (field.key === 'fullName') {
        if ((field.value as string).trim().split(' ').length > 1) {
          const firstName = (field.value as string).trim().split(' ').slice(0, -1).join(' ');
          const lastName = (field.value as string).trim().split(' ').slice(-1).join(' ');
          payload['firstName'] = firstName.trim();
          payload['lastName'] = lastName.trim();
        } else {
          payload['firstName'] = (field.value as string).trim();
          payload['lastName'] = '';
        }
      } else if (field.key === 'username') {
        payload['email'] = field.value;
      } else if (field.key !== undefined) {
        payload[field.key] = field.value;
      }
    });
    payload['active'] = true;

    return payload;
  };

  /**
   * Push new screen to stack
   * on successfull account creation
   */
  const routeChange = (data: any) => {
    history('/workspace-creation', { state: { payload: data } });
  };

  /*
   * User login was successful
   * userData
   * token
   *
   * returns null
   */
  const userLoginSuccess = (userData: ResponseUserObjectType) => {
    const user = parseUserObject(userData);
    log(userData, user);
    loginUser(user);
  };

  /*
   * Login user into app
   * data object {screenToShow, role, token}
   *
   * returns null
   */
  const loginUser = (user: UserObjectType) => {
    const { error } = loginUserInApp(user);

    // rg4js('setUser', {
    //   identifier: user.user ? user.user.id : '',
    //   isAnonymous: false,
    //   email: user.user ? user.user.email : '',
    // });

    // sending userid for google analytics
    if (document.location.origin === 'https://app.magictask.io' && user.user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: user.user.id,
      });
    }
    if (error) {
      return alert.error(error);
    }

    history('/');

    alert.success(Strings.login_success);
  };

  /*
   * Get payload for verify email api
   * fields Array
   *
   * returns Object
   */
  const getPayload = (fields: ValidationFieldType[]) => {
    let payload = {};

    fields.forEach((field, _index) => {
      if (field.key) payload[field.key] = field.value;
    });

    payload = addIntegrationIdToPayload(payload);

    return payload;
  };

  /*
   * Add integration id to payload if available
   * payload Object
   *
   * returns Object
   */
  const addIntegrationIdToPayload = (payload: any) => {
    const integrationId = getIntegrationId();

    if (integrationId) payload['integrationId'] = integrationId;

    return payload;
  };

  return (
    <div className='login-screen-container'>
      <div className='login-form'>
        <div className='magic-task-logo' alt='Logo' />
        <form className='form-input-con' onSubmit={(event) => submit(event)}>
          {fields.map((field, index) => renderField(field, index))}
          <Button idName='login-screen-sign-in-btn' disabled={hasErrors} loading={isLoading}>
            {Strings.sign_in}
          </Button>
        </form>
        <div className='nav-link-container'>
          <span>
            {Strings.dont_have_account}
            {/** @ts-ignore */}
            <NavLink className='nav-link-subcontainer' to='/sign-up'>
              {Strings.create_new_account}
            </NavLink>
          </span>
          <div className='nav-sublink'>
            {Strings.cant_login}
            {/** @ts-ignore */}
            <NavLink className='nav-link-subcontainer' to='/forgot-password'>
              {Strings.reset_your_password}
            </NavLink>
          </div>
        </div>
      </div>
      <div className='login-img'></div>
    </div>
  );
}
