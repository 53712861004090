import { usePagination, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import { Strings } from 'resources';
import { JSXInternal } from 'preact/src/jsx';
type TablePropsType = {
  data: any;
  columns: (
    | {
        Header: string;
        accessor: string;
        Cell?: undefined;
      }
    | {
        Header: string;
        Cell: ({ row }: { row: any }) => JSXInternal.Element;
        accessor?: undefined;
      }
  )[];
  cellSelected?: (id: string, index: number) => void;
  headContainerClass?: string;
  bodyContainerClass?: string;
  elementsClass?: string;
  sorting?: boolean;
  sortBy?: (sortType: SortByType) => void;
  sortName?: string;
  sortOrder?: SortOrderType;
  clickable: boolean;
  decorateLastRow?: boolean;
  lastRowClass?: string;
};
export default function Table(props: TablePropsType) {
  const {
    data,
    columns,
    cellSelected,
    headContainerClass,
    bodyContainerClass,
    elementsClass,
    sorting,
    sortBy,
    sortName,
    sortOrder,
    clickable,
    decorateLastRow = false,
    lastRowClass,
  } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  const getSortIcon = (name: string) => {
    // let { sortBy, sortOrder } = accountData;
    if (sortName === name) {
      if (sortOrder === 'asc') return faSortAlphaDown;
      else return faSortAlphaUp;
    }

    return faSortAmountDown;
  };

  const isSelected = (name: string) => {
    if (sortName === name) return 'selected';

    return '';
  };

  const getColumnNames = (name: string) => {
    switch (name) {
      case Strings.member_invited:
        return 'display';
      case Strings.status:
        return 'invitationStatus';
      case Strings.invited_date:
        return 'invitedOn';
      case Strings.joined_date:
        return 'joiningDate';
      case Strings.last_login:
        return 'lastLogInDate';
      case Strings.first_login:
        return 'firstLogInDate';
      case Strings.time_logged_in:
        return 'totalLoggedIn';
      case Strings.role:
        return 'role';
      case Strings.active:
        return 'active';
      default:
        return 'display';
    }
  };

  return (
    <table {...getTableProps()} className='table-component'>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className={headContainerClass}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={`${column.id} ${
                  column.id === Strings.workspace_name ? Strings.username_placeholder : ''
                } ${elementsClass} ${column.id === Strings.time_logged_in ? 'center-align' : ''} ${
                  column.id === Strings.member_invited ? 'name-item' : ''
                }`}
              >
                {sorting ? (
                  <span
                    className={`sort-icon ${
                      isSelected(getColumnNames(column.id)) ? 'selected' : ''
                    }`}
                    onClick={() => sortBy && sortBy(getColumnNames(column.id))}
                  >
                    <FontAwesomeIcon icon={getSortIcon(getColumnNames(column.id))} />
                  </span>
                ) : null}
                <span></span>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={
                decorateLastRow && index === rows.length - 1 ? lastRowClass : bodyContainerClass
              }
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${cell.column.id} ${elementsClass} ${
                      cell.column.id === Strings.time_logged_in
                        ? 'total-login-time center-align'
                        : ''
                    } ${cell.column.id === Strings.member_invited ? 'name-item' : ''}`}
                  >
                    <span
                      onClick={
                        clickable
                          ? () => cellSelected && cellSelected(cell.column.id, cell.row.index)
                          : undefined
                      }
                    >
                      {cell.render('Cell')}
                    </span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
