import { Fragment } from 'preact/compat';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationDataSelector, showTaskCreationBoxState } from 'recoil/NavigationState';
import PlusIcon from 'assets/images/plus.png';
type BottomBarPropsType = {
  idName: string;
};
export default function BottomBar(props: BottomBarPropsType) {
  const { idName } = props;
  const navigationData = useRecoilValue(navigationDataSelector);

  const setShowTaskCreationBox = useSetRecoilState(showTaskCreationBoxState);
  const toggleCreateTask = () => {
    setShowTaskCreationBox(!navigationData.showTaskCreationBox);
  };

  const renderTopContainer = () => {
    return null;
  };

  const renderBottomContainer = () => {
    return (
      <div id={`bottom-bar-btn-list-${idName}`} className='btn-list'>
        <button
          id={`bottom-bar-add-btn-${idName}`}
          className='item-btn add-btn'
          onClick={toggleCreateTask}
        >
          <img id={`bottom-bar-plus-icon-${idName}`} src={PlusIcon} alt='Plus' />
        </button>
      </div>
    );
  };
  return (
    <Fragment>
      <div id={`bottom-bar-bg-${idName}`} className='bottom-bar-bg'></div>
      <div id={`bottom-bar-container-${idName}`} className='bottom-bar-container'>
        {renderTopContainer()}
        {renderBottomContainer()}
      </div>
    </Fragment>
  );
}
