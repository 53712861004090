import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  addTaskData,
  removeTask,
  saveTaskAssignee,
  saveTaskCollapsableState,
  saveTaskName,
  saveTaskSize,
  updateTaskTags,
  updateTaskTagsAndTagInfo,
} from 'recoil/TaskState/update';
import { getRecurringTaskPayload, isAnySubtaskWithoutID, updateTaskField } from 'utils/helpers';
import { log } from 'utils';
import {
  templateDataSelector,
  templateLoadingState,
  allTemplatesState,
  allTemplateTagsState,
} from '.';
import { updateTaskTags as updateTaskTagsAPI } from 'services/TagService';
import {
  updateTemplateRecurringTask as updateTemplateRecurringTaskFromAPI,
  deleteTemplateRecurringTask as deleteTemplateRecurringTaskFromAPI,
  getProjectTemplates,
  getAllTemplateTags as getAllTemplateTagsFromAPI,
} from 'services/TemplateService';
import { parseProjectTemplates, parseTags } from 'utils/task';

const stateType: StateType = 'TEMPLATE';

export async function updateTask(
  property: string,
  value:
    | string
    | SizeOptionsType
    | TagObjectType[]
    | boolean
    | { id: string }
    | Date
    | Date[]
    | null,
  taskIndex: number,
  taskId: string,
  parentIndex: number | undefined,
  parentId: string | undefined,
  dataType: TaskType,
  instant: boolean,
  taskName: string | null,
  tagIds?: string[],
  add?: boolean,
  tempId?: string,
) {
  const id = typeof parentId !== 'undefined' ? parentId : taskId;
  const index = typeof parentIndex !== 'undefined' ? parentIndex : taskIndex;
  const isSubtask = typeof parentIndex !== 'undefined' ? true : false;

  const templateData = getRecoil(templateDataSelector);

  const { data } = dataType ? templateData[dataType] : templateData.BACKLOG;

  if (data === null) return;
  log('updateTask', property, id, index);

  let task = data[index];
  if (task.id !== id && task.tempId !== id) return;

  if (task.recurringTaskMeta === null || task.recurringTaskMeta === undefined) {
    if (property === 'name') {
      saveTaskName(
        value as string,
        dataType,
        taskIndex,
        property,
        parentIndex,
        parentId,
        false,
        instant,
        stateType,
      );
    } else if (property === 'size') {
      saveTaskSize(
        taskIndex,
        taskId,
        value as SizeOptionsType,
        parentIndex,
        parentId,
        dataType,
        stateType,
      );
    } else if (property === 'tags') {
      updateTaskTags(dataType, taskIndex, value as TagObjectType[], parentIndex, true, 'TEMPLATE');
      const { success, data } = await updateTaskTagsAPI(
        taskId,
        tagIds,
        add ? add : false,
        tempId,
        taskName,
        true,
      );

      if (success && add && data && data.tags && data.tagInfo) {
        updateTaskTagsAndTagInfo(
          dataType,
          taskIndex,
          parseTags(data.tags ? data.tags : []) as TagObjectType[],
          data.tagInfo ? data.tagInfo : [],
          parentIndex,
          'TEMPLATE',
        );
      }
    } else if (property === 'assignee') {
      saveTaskAssignee(
        value ? (value as string) : null,
        dataType,
        taskId,
        parentIndex,
        parentId,
        taskName ? taskName : undefined,
        'TEMPLATE',
      );
    } else if (property === 'collapsedSubTasks') {
      saveTaskCollapsableState(
        taskId,
        value ? (value as boolean) : false,
        parentIndex,
        parentId,
        dataType,
        'TEMPLATE',
      );
    }
    return;
  }

  if (property === 'assignee') {
    value = value ? { id: value as string } : null;
  }

  if (isSubtask) {
    if (task['subTasks'] && task['subTasks'][taskIndex]) {
      let subTask = task['subTasks'][taskIndex];

      subTask = updateTaskField(subTask, property, value);

      task['subTasks'][taskIndex] = subTask;
    }
  } else {
    task = updateTaskField(task, property, value);
  }

  data[index] = task;
  addTaskData(dataType ? dataType : 'BACKLOG', data, 'TEMPLATE');

  if (isAnySubtaskWithoutID(task.subTasks)) return;

  const payload = getRecurringTaskPayload(task);

  if (property === 'name') {
    return;
  }

  // call api
  updateTemplateRecurringTaskFromAPI(payload);

  log(
    'updateTask',
    payload,
    id,
    index,
    task,
    isSubtask,
    property,
    value,
    taskIndex,
    taskId,
    parentIndex,
    parentId,
  );
}

export function deleteTemplateRecurringTask(
  index: number,
  id: string,
  dataType: TaskType,
  parentIndex: number | undefined,
  parentId: string | undefined,
  stateType: StateType,
) {
  removeTask(id, dataType, parentId, stateType);

  deleteTemplateRecurringTaskFromAPI(id);
}

/**
 * get the list of project templates
 * @returns Array data
 */
export async function getAllProjectTemplates() {
  setRecoil(templateLoadingState, true);
  const { data, success } = await getProjectTemplates();
  if (success && data) {
    setRecoil(allTemplatesState, parseProjectTemplates(data));
  }
  setRecoil(templateLoadingState, false);
}

/**
 * get the list of labels/hashtags for project template
 * @param {String} templateId
 * @returns
 */
export async function getAllTemplateTags(templateId: string) {
  const { data, success } = await getAllTemplateTagsFromAPI(templateId);
  if (success && data) {
    setRecoil(allTemplateTagsState, parseTags(data) as TagObjectType[]);
  }
}
