import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';
import { parseToDateObjectFromTimestamp, parseUser } from 'utils/task';

export const parseUserObject = (userData: ResponseUserObjectType) => {
  return {
    token: userData.authToken ? userData.authToken : '',
    created: userData.created ? parseToDateObjectFromTimestamp(userData.created) : null,
    signupDate: userData.signupDate ? parseToDateObjectFromTimestamp(userData.signupDate) : null,
    user: userData.user ? parseUser(userData.user) : {},
    role: userData.userRole ? userData.userRole : '',
    workspace: userData.workspace ? userData.workspace : {},
    status: userData.status ? userData.status : false,
  } as UserObjectType;
};

export const createUserInitials = (user: ResponseUserType | UserFieldType) => {
  const name = extractUserName(user).split(' ');
  return createAvatar(initials, {
    seed: `${name[0]}-${name[1] || ''}`,
  }).toDataUriSync();
};

export const extractUserNameFromUserField = (user: UserFieldType) => {
  if (!user) return '';

  let name = user.name ? user.name : '';
  name = name.trim();

  if (!user.name || name === '') {
    const str = user.email ? user.email : '';
    const nameParts = str.split('@');
    name = nameParts.length === 2 ? nameParts[0] : 'No Name';
  }

  return name;
};

export const extractUserName = (user: ResponseUserType) => {
  if (!user) return '';

  let name = `${user.firstName} ${user.lastName ? user.lastName : ''}`;
  name = name.trim();

  if (!user.firstName || name === '') {
    const str = user.email ? user.email : '';
    const nameParts = str.split('@');
    name = nameParts.length === 2 ? nameParts[0] : 'No Name';
  }

  return name;
};
