import { log } from 'utils';
import {
  getOtherDataType,
  parseSubTask,
  parseProjectsForMentions,
  parseUsers,
  isProjectTaskScreen,
  isProjectCompletedScreen,
  isHomeScreen,
  parseTask,
  parseToDateObjectFromString,
  parseTags,
  parseTaskComment,
  parseThemes,
  parseRecurringTask,
  parseProjectTemplate,
  currentDay,
  parseTag,
  parseUser,
} from 'utils/task';
import {
  getUserId,
  updateUserWorkspace,
  logOutUser,
  reFetchUserSession,
} from 'services/AuthService';
import moment from 'moment';
import { playTheSound } from 'triggers/utils/sound';
import { sortStringData, sortData } from 'utils/helpers';
import {
  addTaskData,
  checkTask,
  getStateTypeMain,
  getTaskListData,
  storeTagFilterList,
  updateDueDate,
  updateStartEndDates,
  updateTaskCollapseStatus,
  addTaskComment,
  updateTaskComment,
  updateTaskSize as updateTaskSizeInTaskRecoil,
  updateTaskTagsAndTagInfo,
  updateTaskName as updateTaskNameInTaskState,
  updateTaskProject as updateTaskProjectInTaskState,
  updateTaskAssignee as updateTaskAssigneeInTaskState,
  removeTask as removeTaskInTaskState,
  updateTaskDeletionComment,
} from 'recoil/TaskState/update';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { combinedSelector } from 'recoil/CombinedState';
import { allUserTagsState, currentUserIdState } from 'recoil/UserState';
import {
  accountDataSelector,
  accountSettingsState,
  accountState,
  allTagsState,
  allUsersState,
  enableDesktopNotificationState,
  invitedMembersPaginatedDataState,
  invitedMembersState,
  notificationPayloadItemState,
  notificationsState,
  purchasedThemesState,
} from 'recoil/AccountState';
import { allProjectsState } from 'recoil/ProjectState';
import { addCompletedTask, addTaskToBacklog, unCheckTask } from 'recoil/CompletedTasksState/update';
import {
  goToUrlState,
  messageModalState,
  showThemesLevelUpModalState,
} from 'recoil/NavigationState';
import {
  addAllProjects,
  updateProjectCounters as updateProjectCountersInProjectState,
  getFoldersForUser,
  removeDeletedMemberFromProject,
} from 'recoil/ProjectState/update';
import {
  getNewNotifications,
  removeUser,
  setUserCounters,
  updateAllNotificationList,
  updateUnreadNotificationList,
  updateUsersCounters as updateUserCountersInAccountState,
} from 'recoil/AccountState/update';
import { allTemplateTagsState, allTemplatesState } from 'recoil/TemplateState';
import { workspacesDataState } from 'recoil/SuperAdminState';
import { setTheme } from 'utils/theme';

export const findSubtaskWithKey = (parent: TaskObjectType | null, id: string, key: string) => {
  return parent && parent.subTasks ? parent.subTasks.findIndex((item) => item[key] === id) : -1;
};

export const findParentTaskWithId = (
  parentId: string,
  taskData:
    | TaskDataType
    | ProjectDataType
    | UserDataType
    | RecurringDataType
    | TemplateDataType
    | TutorialDataType,
  dataType: TaskType,
) => {
  log('findParentTaskWithId', parentId, taskData, dataType);
  let index: number | null = -1;
  index =
    taskData[dataType].data && taskData[dataType].data!.findIndex((item) => item.id === parentId);
  let task: TaskObjectType | null = null;

  // found in current type
  if (index !== -1 && index !== null) {
    task =
      taskData[dataType] && taskData[dataType].data && taskData[dataType].data!.length > 0
        ? taskData[dataType].data![index]
        : null;
  } else if (taskData[getOtherDataType(dataType)]) {
    dataType = getOtherDataType(dataType);
    index =
      taskData[dataType] &&
      taskData[dataType].data &&
      taskData[dataType].data!.findIndex((item) => item.id === parentId);
    if (index !== -1 && index !== null)
      task = taskData[dataType].data && taskData[dataType].data![index];
  }

  return { dataType, parentIndex: index, parent: task };
};

export const switchWorkspace = (workspace?: WorkspaceType, token?: string) => {
  if (!workspace || !workspace.id || !token) return;

  updateUserWorkspace(workspace, token);
};

export const addAccountUserTag = (
  newTag: TagObjectType | undefined,
  add: boolean,
  type: string,
  projectId: string | null,
  stateType: StateType,
  taskType: TaskType,
  projectTaskType: TaskType,
) => {
  log('addAccountUserTag', newTag, add, type, stateType);

  if (!newTag || !newTag.id || !newTag.name) return;

  newTag = parseTag(newTag);
  let data: TagType[] = [];
  const taskData = getStateTypeMain(stateType);
  addOrRemoveTagFromMainList(
    taskData.unFilteredList,
    add,
    newTag,
    stateType,
    taskType,
    projectTaskType,
  );

  const state = getRecoil(combinedSelector);

  if (stateType === 'INDIVIDUAL') {
    data = state.userData.allUserTags;
    data = addOrRemoveTag(data, newTag, add, type === 'allProjectTags' ? true : false, projectId);

    setRecoil(allUserTagsState, data);
  } else {
    data = state.accountData['allTags'];
    data = addOrRemoveTag(data, newTag, add, type === 'allProjectTags' ? true : false, projectId);
    setRecoil(allTagsState, data);
  }
};

const addOrRemoveTagFromMainList = (
  unFilteredList: TaskTagListType,
  add: boolean,
  newTag: TagObjectType,
  stateType: StateType,
  taskType: TaskType,
  projectTaskType: TaskType,
) => {
  let tempUnFilteredTest = { ...unFilteredList };
  const unFilteredTagIndex = tempUnFilteredTest.FOCUS.findIndex((tag) => tag.id === newTag.id);
  const unFilteredTagIndexBacklog = tempUnFilteredTest.BACKLOG.findIndex(
    (tag) => tag.id === newTag.id,
  );

  if (add) {
    if (unFilteredTagIndexBacklog === -1 && unFilteredTagIndex === -1) {
      if (!stateType || stateType === 'INDIVIDUAL') {
        taskType
          ? (tempUnFilteredTest = {
              ...tempUnFilteredTest,
              [taskType]: [...tempUnFilteredTest[taskType], newTag],
            })
          : (tempUnFilteredTest = {
              ...tempUnFilteredTest,
              BACKLOG: [...tempUnFilteredTest.BACKLOG, newTag],
            });
      } else {
        projectTaskType
          ? (tempUnFilteredTest = {
              ...tempUnFilteredTest,
              [projectTaskType]: [...tempUnFilteredTest[projectTaskType], newTag],
            })
          : (tempUnFilteredTest = {
              ...tempUnFilteredTest,
              BACKLOG: [...tempUnFilteredTest.BACKLOG, newTag],
            });
      }
    }
  } else {
    if (unFilteredTagIndex !== -1) {
      tempUnFilteredTest.FOCUS = tempUnFilteredTest.FOCUS.slice(0, unFilteredTagIndex).concat(
        tempUnFilteredTest.FOCUS.slice(unFilteredTagIndex + 1, tempUnFilteredTest.FOCUS.length),
      );
    }

    if (unFilteredTagIndexBacklog !== -1) {
      tempUnFilteredTest.BACKLOG = tempUnFilteredTest.BACKLOG.slice(
        0,
        unFilteredTagIndexBacklog,
      ).concat(
        tempUnFilteredTest.BACKLOG.slice(
          unFilteredTagIndexBacklog + 1,
          tempUnFilteredTest.BACKLOG.length,
        ),
      );
    }
  }
  storeTagFilterList(sortStringData(tempUnFilteredTest.FOCUS, true), 'FOCUS', stateType);
  storeTagFilterList(sortStringData(tempUnFilteredTest.BACKLOG, true), 'BACKLOG', stateType);
};

const addOrRemoveTag = (
  data: TagType[],
  newTag: TagObjectType,
  add: boolean,
  isProjectExist: boolean,
  projectId: string | null,
) => {
  let tags: TagObjectType[] = [];
  let tempData = [...data];
  let index;
  if (isProjectExist) {
    index = tempData.findIndex((item) => item.projectId === projectId);
    if (index === -1) {
      tags.push(newTag);
      const item = { projectId: projectId, tags: tags };
      tempData.unshift(item);
    } else {
      tags = getTagList(tempData[index].tags, newTag, add);
      tempData = tempData.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            tags: tags,
          };
        }
        return item;
      });
    }
  } else {
    index = tempData.findIndex((item) => item.projectId === null);
    if (index >= 0) {
      tags = getTagList(tempData[index].tags, newTag, add);
      tempData = tempData.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            tags: tags,
          };
        }
        return item;
      });
    }
  }
  return tempData;
};

const addOrRemoveTagInTemplate = (data: TagObjectType[], newTag: TagObjectType, add: boolean) => {
  const tags: TagObjectType[] = [];
  let tempData = [...data];
  const index = tempData.findIndex((item) => item.id === newTag.id);
  if (index === -1 && add) {
    tags.push(newTag);
    tempData = [...tempData, newTag];
  } else if (index >= 0 && !add) {
    tempData = tempData.filter((item) => item.id !== newTag.id);
  }

  return tempData;
};

const getTagList = (tags: TagObjectType[], newTag: TagObjectType, add: boolean) => {
  let tempTags = [...tags];
  // check if it doesn't already exist
  const tagIndex = tempTags.findIndex((tag) => tag.id === newTag.id);

  // log("addTaskTags",2, + new Date(), tagIndex, tags);

  if (add) {
    if (tagIndex === -1) {
      tempTags.push(newTag);
      tempTags = sortStringData(tempTags, true);
    }
  } else {
    if (tagIndex !== -1)
      tempTags = tempTags.slice(0, tagIndex).concat(tempTags.slice(tagIndex + 1, tempTags.length));
  }
  return tempTags;
};

export const addTaskTags = (
  taskId: string | undefined,
  tags: TagObjectType[],
  tagInfo: TagInfoType[],
  add: boolean,
  parentId: string | null,
  tag: TagObjectType | undefined,
  stateType: StateType,
  taskType: TaskType,
  projectTaskType: TaskType,
) => {
  log('addTaskTags', 1, +new Date(), taskId, tags, add, stateType);
  if (!taskId) return;
  const taskData = getStateTypeMain(stateType);

  if (tag) {
    tag = parseTag(tag);
    addOrRemoveTagFromMainList(
      taskData.unFilteredList,
      add,
      tag,
      stateType,
      taskType,
      projectTaskType,
    );
  }

  if (parentId) {
    const { dataType, parentIndex, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');
    if (parentIndex === -1) return;
    const index = findSubtaskWithKey(parent, taskId, 'id');
    if (index === -1) return;

    updateTaskTagsAndTagInfo(
      dataType,
      index,
      parseTags(tags) as TagObjectType[],
      tagInfo,
      parentIndex !== null ? parentIndex : undefined,
      stateType,
    );
  } else {
    const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');
    if (index === -1) return;

    updateTaskTagsAndTagInfo(
      dataType,
      index,
      parseTags(tags) as TagObjectType[],
      tagInfo,
      undefined,
      stateType,
    );
  }
};

const getDataTypeFromTutorial = (type: string): TaskType => {
  if (type.toLowerCase() === 'tutorialbacklog') {
    return 'BACKLOG';
  } else if (type.toLowerCase() === 'tutorialfocus') {
    return 'FOCUS';
  }
  return 'BACKLOG';
};

export const updateSubTaskSequence = (
  taskId: string,
  sequence: number,
  ogDataType: TaskType,
  parentId: string,
  stateType?: StateType,
) => {
  const taskData = getStateTypeMain(stateType);

  // find parent
  const { dataType, parentIndex, parent } = findParentTaskWithId(
    parentId,
    taskData,
    getDataTypeFromTutorial(ogDataType),
  );

  if (parentIndex === -1) return;

  const index = findSubtaskWithKey(parent, taskId, 'id');

  if (index === -1 || parent === null) return;

  const { subTasks } = parent;

  if (subTasks === undefined) return;
  const updatedSubTask = { ...subTasks[index] };
  updatedSubTask.sequence = sequence;

  let updatedSubTasks = [...subTasks.slice(0, index), updatedSubTask, ...subTasks.slice(index + 1)];

  updatedSubTasks = sortData(updatedSubTasks, 'sequence');
  const updatedParent = { ...parent, subTasks: updatedSubTasks };

  const updatedTaskData = {
    ...taskData,
    [dataType]: {
      ...taskData[dataType],
      data: [...taskData[dataType].data],
    },
  };

  updatedTaskData[dataType].data[parentIndex] = updatedParent;
  addTaskData(dataType, updatedTaskData[dataType].data, stateType);
};

export const updateTaskSequence = (
  taskId: string,
  sequence: number,
  newDataType: TaskType,
  stateType?: StateType,
) => {
  log('taskSequenceUpdated', taskId, sequence, newDataType, stateType);

  const targetData = getStateTypeMain(stateType);
  const mainData = { ...targetData };
  // find parent
  const { dataType, index } = findTaskWithKey(taskId, mainData, newDataType, 'id');
  const targetStateAtom = getTaskListData(stateType, dataType);
  const targetFromStateAtom = getTaskListData(stateType, newDataType);
  const taskDataAtom = getRecoil(targetStateAtom);
  const taskFromDataAtom = getRecoil(targetFromStateAtom);
  if (index === -1 || taskDataAtom === null || taskFromDataAtom === null) return;
  let taskData = [...taskDataAtom];
  let taskFromData = [...taskFromDataAtom];

  // Task not found or data is null, then return

  if (dataType !== newDataType) {
    // remove from old
    let [removed] = taskData.splice(index, 1);
    removed = {
      ...removed,
      sequence: sequence,
    };
    // add to new
    taskFromData.push(removed);
    taskFromData = sortData(taskFromData, 'sequence');

    addTaskData(dataType, taskData, stateType);
    addTaskData(newDataType, taskFromData, stateType);
  } else {
    taskData = taskData.map((item, ind) => {
      if (ind === index)
        return {
          ...item,
          sequence: sequence,
        };
      return item;
    });
    taskData = sortData(taskData, 'sequence');

    addTaskData(dataType, taskData, stateType);
  }
};

export const updateTutorialTaskSequence = (
  taskId: string,
  sequence: number,
  newDataType: TaskType,
  stateType?: StateType,
) => {
  log('tutorialTaskSequenceUpdated', taskId, sequence, newDataType, stateType);

  const taskData = getStateTypeMain(stateType);

  // find parent
  const { dataType, index } = findTaskWithKey(
    taskId,
    taskData,
    getDataTypeFromTutorial(newDataType),
    'id',
  );

  newDataType = getTutorialDataType(newDataType);

  // Task not found or data is null, then return
  if (index === -1 || taskData[dataType].data === null || taskData[newDataType].data === null)
    return;

  if (dataType !== newDataType && taskData[newDataType].data) {
    // remove from old
    const [removed] = taskData[dataType].data!.splice(index, 1);
    removed.sequence = sequence;
    // // add to new
    taskData[newDataType].data!.push(removed);
    taskData[newDataType].data = sortData(taskData[newDataType].data!, 'sequence');

    addTaskData(dataType, taskData[dataType].data, stateType);
    addTaskData(newDataType, taskData[newDataType].data, stateType);
  } else {
    taskData[dataType].data![index].sequence = sequence;
    taskData[dataType].data = sortData(taskData[dataType].data!, 'sequence');

    addTaskData(dataType, taskData[dataType].data, stateType);
  }
};

const getTutorialDataType = (dataType: string): TaskType => {
  if (dataType.toLowerCase() === 'tutorialfocus') {
    return 'FOCUS';
  } else {
    return 'BACKLOG';
  }
};

const itemDoesNotHaveRequiredSize = (
  sizeFilter: SizeOptionsType | null,
  size?: SizeOptionsType,
) => {
  if (!sizeFilter) return false;

  if (size !== sizeFilter) return true;

  return false;
};

const itemDoesNotHaveRequiredTag = (tagFilter: string[] | null, tags?: TagObjectType[] | null) => {
  if (!tagFilter) return false;

  if (!tags || (tags && tags.length === 0)) return true;

  let hide = true;

  for (let i = 0; i < tags.length; i++) {
    const tagId = tags[i].id;

    if (tagFilter.includes(tagId)) {
      hide = false;
      break;
    }
  }

  return hide;
};

const itemDoesNotHaveRequiredDate = (dateFilter: null | DateFilterType, dueDate?: Date | null) => {
  if (!dateFilter) return false;

  if (!dueDate) return true;

  const tempDate = moment(dueDate);
  if (!tempDate.isValid()) return true;

  let current = moment(currentDay());

  switch (dateFilter) {
    case 'yesterday': {
      const diff = tempDate.startOf('day').diff(current.startOf('day'), 'days', true);

      if (diff !== -1) return true;

      return false;
    }
    case 'today': {
      const same = tempDate.startOf('day').isSame(current.startOf('day'), 'day');

      if (!same) return true;

      return false;
    }
    case 'currentMonth': {
      const same = tempDate.startOf('day').isSame(current.startOf('day'), 'month');

      if (!same) return true;

      return false;
    }
    case 'lastMonth': {
      current = current.subtract(1, 'months');

      const same = tempDate.startOf('day').isSame(current.startOf('day'), 'month');

      if (!same) return true;

      return false;
    }
    case 'yearToDate': {
      const sameYear = tempDate.startOf('day').isSame(current.startOf('day'), 'year');

      const diff = tempDate.startOf('day').diff(current.startOf('day'), 'day', true);

      if (!sameYear || diff >= 1) return true;

      return false;
    }
    case 'previousYear': {
      current = current.subtract(1, 'years');

      const same = tempDate.startOf('day').isSame(current.startOf('day'), 'year');

      if (!same) return true;

      return false;
    }
    default: {
      const dateInFilter = moment(dateFilter, 'MM-DD-YYYY');

      if (!dateInFilter.isValid()) return false;

      const same = tempDate.startOf('day').isSame(dateInFilter.startOf('day'), 'day');

      if (!same) return true;
    }
  }

  return false;
};

const itemDoesNotHaveRequiredAssignee = (assigneeFilter?: string[] | null, assigneeId?: string) => {
  if (!assigneeFilter) return false;

  if (assigneeId === undefined || assigneeId === null) assigneeId = 'UNASSIGNED';

  if (!assigneeFilter.includes(assigneeId)) return true;

  return false;
};

const itemDoesNotHaveRequiredProject = (projectFilter: string[] | null, projectId?: string) => {
  if (!projectFilter || !projectId) return false;

  if (!projectFilter.includes(projectId)) return true;

  return false;
};

const itemDoesNotHaveRequiredCreatedBy = (createdByFilter, createdId) => {
  if (!createdByFilter) return false;
  if (!createdByFilter.includes(createdId)) return true;
  return false;
};

export const itemShouldBeFilteredOutBeforeStoring = (
  taskData:
    | TaskDataType
    | UserDataType
    | TutorialDataType
    | ProjectDataType
    | TemplateDataType
    | RecurringDataType,
  newTask: ResponseTaskInterface | TaskObjectType,
  stateType,
) => {
  const accountSettings = getRecoil(accountSettingsState);
  if (!accountSettings.filterVisiblity) return false;

  if (itemDoesNotHaveRequiredSize(taskData.taskFilters?.sizeFilter, newTask.size)) return true;

  if (itemDoesNotHaveRequiredTag(taskData.taskFilters?.tagFilter, newTask.tags)) return true;

  if (itemDoesNotHaveRequiredDate(taskData.taskFilters?.dateFilter, newTask.dueDate)) return true;

  // Filter by assignee
  if (
    (stateType === 'PROJECT' || stateType === 'RECURRING' || stateType === 'TEMPLATE') &&
    itemDoesNotHaveRequiredAssignee(
      taskData.taskFilters?.assigneeFilter,
      newTask.assignee ? newTask?.assignee?.id : undefined,
    )
  )
    return true;

  // Filter by project
  if (
    stateType !== 'PROJECT' &&
    itemDoesNotHaveRequiredProject(taskData.taskFilters?.projectFilter, newTask?.project?.id)
  )
    return true;

  if (
    stateType === 'RECURRING' &&
    itemDoesNotHaveRequiredCreatedBy(taskData.taskFilters?.createdByFilter, newTask?.createdBy?.id)
  )
    return true;

  return false;
};

export const addTask = (newTask: ResponseTaskInterface, stateType?: StateType) => {
  log('addTask', newTask, stateType);

  let taskData = getStateTypeMain(stateType);

  if (itemShouldBeFilteredOutBeforeStoring(taskData, newTask, stateType)) {
    return;
  }

  const taskType =
    stateType === 'PROJECT' || stateType === 'TEMPLATE'
      ? newTask.projecttaskType
      : newTask.taskType;
  const dataType = taskType ? taskType : 'BACKLOG';

  // find parent
  const { index } = findTaskWithKey(newTask.tempId, taskData, dataType, 'tempId');

  // found task already or data is null, then return
  if (index !== -1 || taskData[dataType].data === null) return;

  // check if task already exists using id
  const findWithId = findTaskWithKey(newTask.id, taskData, dataType, 'id');
  // Task id found, return
  if (findWithId.index !== -1) return;

  // Task not found, so time to add it and reorder
  const { data } = taskData[dataType];
  let tempData: TaskObjectType[] = [...data];
  const task = parseTask(newTask, stateType, false, true);
  task.animation = 'ADD_TASK_NEW';
  tempData.push(task);
  tempData = sortData(tempData, 'sequence');
  taskData = {
    ...taskData,
    [dataType]: {
      ...taskData[dataType],
      data: tempData,
    },
  };

  const { triggerData, navigationData } = getRecoil(combinedSelector);
  let playSound = false;

  if (!(typeof stateType === 'undefined') && triggerData.soundTriggers.createTask) playSound = true;
  else if (
    typeof stateType === 'undefined' &&
    isHomeScreen(navigationData.currentRoute) &&
    triggerData.soundTriggers.createTask
  )
    playSound = true;

  if (playSound && triggerData.soundTriggers.createTask) {
    log('play sound from event', stateType);
    playTheSound(triggerData.soundTriggers.createTask);
  }

  addTaskData(dataType, taskData[dataType].data, stateType);
};

export const addSubTask = (
  taskId: string,
  parentId: string,
  parentTask: ResponseTaskInterface,
  tempId: string,
  stateType?: StateType,
) => {
  log('addSubTask', taskId, parentId, parentTask, tempId, stateType);

  let taskData = getStateTypeMain(stateType);
  const taskType =
    stateType === 'PROJECT' || stateType === 'TEMPLATE'
      ? parentTask.projecttaskType
      : parentTask.taskType;

  // find parent
  const { dataType, parentIndex, parent } = findParentTaskWithId(
    parentId,
    taskData,
    taskType ? getDataTypeFromTutorial(taskType) : 'BACKLOG',
  );

  // Add parent itself and return
  if (parentIndex === -1) {
    addTask(parentTask, stateType);
    log('addSubTask parent not found');
    return;
  }

  log('addSubTask parent found');
  const index = findSubtaskWithKey(parent, tempId, 'tempId');

  // found subtask already, so return
  if (index !== -1) return;

  // check if subtask already exists using id
  const findWithId = findSubtaskWithKey(parent, taskId, 'id');
  // Task id found, return
  if (findWithId !== -1) return;

  // Subtask not found, so time to add it to parent and reorder
  const subTaskIndex = parentTask?.subTasks?.findIndex((subTask) => subTask.id === taskId);
  if (
    subTaskIndex === -1 ||
    subTaskIndex === undefined ||
    typeof parentTask === 'undefined' ||
    typeof parentTask.subTasks === 'undefined'
  )
    return;
  const subTask: ResponseTaskInterface = parentTask.subTasks[subTaskIndex];
  if (!parent) return;
  let subTasks: TaskObjectType[] | undefined = parent.subTasks ? [...parent.subTasks] : [];
  if (!subTasks) return;
  subTasks.push(parseSubTask(subTask, stateType));
  subTasks = sortData(subTasks, 'sequence');
  taskData = {
    ...taskData,
    [dataType]: {
      ...taskData[dataType],
      data: taskData[dataType].data.map((item, ind) => {
        if (ind === parentIndex)
          return {
            ...parent,
            subTasks: subTasks,
          };
        return item;
      }),
    },
  };
  addTaskData(dataType, taskData[dataType].data, stateType);
};

const findTaskWithKey = (
  id: string,
  taskData: TaskDataType | TemplateDataType | UserDataType | TutorialDataType | ProjectDataType,
  dataType: TaskType,
  key: string,
) => {
  log('findTaskWithKey', id, taskData, dataType, key);
  let index = -1;

  if (taskData[dataType].data)
    index = taskData[dataType].data.findIndex(
      (item) => typeof item[key] !== 'undefined' && item[key] === id,
    );
  if (index === -1 && taskData[dataType] && taskData[dataType].data) {
    dataType = getOtherDataType(dataType);
    if (taskData[dataType] && taskData[dataType].data)
      index = taskData[dataType].data!.findIndex(
        (item) => typeof item[key] !== 'undefined' && item[key] === id,
      );
  }

  return { dataType, index };
};

export const updateTaskName = (
  taskId: string,
  tempId: string,
  name: string,
  ogDataType: TaskType,
  parentId?: string | null,
  stateType?: StateType,
) => {
  log('updateTaskName', taskId, tempId, name, ogDataType, parentId, stateType);

  let newName = name ? name : '';
  const searchRegExp = /:c/gi;
  const replaceWith = '';
  if (newName !== '') {
    newName = newName.replace(searchRegExp, replaceWith);
  }

  const taskData = getStateTypeMain(stateType);

  if (parentId !== undefined && parentId !== null) {
    // find parent
    const { dataType, parentIndex, parent } = findParentTaskWithId(
      parentId,
      taskData,
      getDataTypeFromTutorial(ogDataType),
    );

    if (parentIndex === -1 || parentIndex === null) return;

    const index = findSubtaskWithKey(parent, taskId, 'id');

    if (index === -1 || parent === null) return;

    if (tempId && parent.subTasks && tempId === parent.subTasks[index].tempId) {
      log('Subtask name updated from this instance');
      return;
    }

    updateTaskNameInTaskState(dataType, index, newName, 'name', parentIndex, true, stateType);
  } else {
    const { dataType, index } = findTaskWithKey(
      taskId,
      taskData,
      getDataTypeFromTutorial(ogDataType),
      'id',
    );

    if (index === -1) return;

    const tempData = taskData[dataType];
    if (tempData.data === null) return;
    const task = tempData.data[index];

    if (tempId && tempId === task.tempId) {
      log('task name updated from this instance');
      return;
    }

    log('task name updated from another instance', tempId, task.tempId, newName);

    updateTaskNameInTaskState(dataType, index, newName, 'name', undefined, true, stateType);
  }
};

export const updateTaskProject = (
  taskId: string,
  projectId: string | null,
  parentId: string | undefined,
  stateType?: StateType,
  taskNumber?: number,
) => {
  log('updateTaskProject', taskId, projectId, parentId, stateType);
  const taskData = getStateTypeMain(stateType);

  if (parentId) {
    const { dataType, parentIndex, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');
    if (parentIndex === -1 || parentIndex === null) return;
    const index = findSubtaskWithKey(parent, taskId, 'id');
    if (index === -1) return;

    updateTaskProjectInTaskState(dataType, index, projectId, parentIndex, stateType, taskNumber);
  } else {
    const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');
    if (index === -1) return;

    updateTaskProjectInTaskState(dataType, index, projectId, undefined, stateType, taskNumber);
  }
};

export const updateTaskSize = (
  taskId: string,
  size: SizeOptionsType,
  parentId?: string,
  stateType?: StateType,
) => {
  log('updateTaskSize', taskId, size, parentId, stateType);
  const taskData = getStateTypeMain(stateType);

  if (parentId) {
    const { dataType, parentIndex, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');
    if (parentIndex === -1) return;
    const index = findSubtaskWithKey(parent, taskId, 'id');
    if (index === -1) return;
    updateTaskSizeInTaskRecoil(
      index,
      size,
      parentIndex !== null && parentIndex > -1 ? parentIndex : undefined,
      dataType,
      stateType,
    );
  } else {
    const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');

    if (index === -1) return;

    updateTaskSizeInTaskRecoil(index, size, undefined, dataType, stateType);
  }
};

export const updateCollapseTaskStatus = (
  taskId: string,
  collapseStatus: boolean,
  parentId?: string,
  stateType?: StateType,
) => {
  log('updateCollapseTaskStatus', taskId, collapseStatus, parentId, stateType);
  const taskData = getStateTypeMain(stateType);

  const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');

  if (index === -1) return;

  updateTaskCollapseStatus(index, collapseStatus, undefined, dataType, stateType);
};

export const updateTaskStartEndDates = (
  taskId: string,
  startDate: Date | null,
  endDate: Date | null,
  dueDate: Date | null,
  parentId?: string,
  stateType?: StateType,
) => {
  log('updateTaskStartEndDates', taskId, startDate, endDate, dueDate, parentId, stateType);

  const taskData = getStateTypeMain(stateType);

  startDate = startDate ? parseToDateObjectFromString(startDate) : null;
  endDate = endDate ? parseToDateObjectFromString(endDate) : null;
  dueDate = dueDate ? parseToDateObjectFromString(dueDate) : null;

  if (parentId) {
    const { dataType, parentIndex, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');
    if (parentIndex === -1 || parentIndex === null) return;
    const index = findSubtaskWithKey(parent, taskId, 'id');
    if (index === -1) return;

    updateStartEndDates(dataType, index, startDate, endDate, parentIndex, stateType);

    if (dueDate) updateDueDate(dataType, index, dueDate, parentIndex, stateType);
  } else {
    const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');
    if (index === -1) return;

    updateStartEndDates(dataType, index, startDate, endDate, undefined, stateType);
    if (dueDate) updateDueDate(dataType, index, dueDate, undefined, stateType);
  }
};

export const updateProjectName = (projectId: string, name: string) => {
  const allProjects = getRecoil(allProjectsState);
  if (allProjects === null) return;

  const tempData = allProjects.map((item) => {
    if (item.id === projectId) return { ...item, display: name };
    return item;
  });
  setRecoil(allProjectsState, tempData);
};

export const updateProjectArchiveStatus = (projectId: string, archived: boolean) => {
  const allProjects = getRecoil(allProjectsState);
  if (allProjects === null) return;
  const tempData = allProjects.map((item) => {
    if (item.id === projectId) return { ...item, archived: archived };
    return item;
  });
  setRecoil(allProjectsState, tempData);
};

export const updateTaskAssignee = (
  taskId: string,
  assigneeId: string | null,
  parentId?: string,
  stateType?: StateType,
) => {
  log('updateTaskAssignee', taskId, assigneeId, parentId, stateType);

  const taskData = getStateTypeMain(stateType);

  if (parentId) {
    const { dataType, parentIndex, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');
    if (parentIndex === -1 || parentIndex === null) return;
    const index = findSubtaskWithKey(parent, taskId, 'id');
    if (index === -1) return;

    updateTaskAssigneeInTaskState(dataType, index, assigneeId, parentIndex, stateType);
  } else {
    const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');
    if (index === -1) return;

    updateTaskAssigneeInTaskState(dataType, index, assigneeId, undefined, stateType);
  }
};

export const updateTaskStatus = (
  taskId: string,
  status: boolean,
  parentId: string | undefined,
  task: ResponseTaskInterface,
  stateType?: StateType,
) => {
  log('updateTaskStatus', taskId, status, parentId, stateType);

  // sub tasks can be updated from main screen only, not from completed screen
  if (parentId !== null && parentId !== undefined) {
    log('is subtaks', status);
    updateSubTaskStatus(taskId, parentId, status, task, stateType);
  } else {
    log('is main task', status);

    if (status) updateMainTaskCompletedStatus(taskId, task, stateType);
    else updateMainTaskFOCUSStatus(taskId, task, stateType);
  }
};

const updateSubTaskStatus = (
  taskId: string,
  parentId: string,
  status: boolean,
  task: ResponseTaskInterface,
  stateType?: StateType,
) => {
  log('updateSubTaskStatus', taskId, parentId, status, stateType);
  const taskData = getStateTypeMain(stateType);

  const tempTask = parseSubTask(task, stateType);
  // find parent
  const { dataType, parentIndex, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');

  if (parentIndex === -1 || parentIndex === null) return;

  const index = findSubtaskWithKey(parent, taskId, 'id');

  if (index === -1) return;

  checkTask(index, taskId, dataType, parentIndex, parentId, true, stateType, status, tempTask);
};

const updateMainTaskCompletedStatus = (
  taskId: string,
  task: ResponseTaskInterface,
  stateType?: StateType,
) => {
  const tempTask = parseTask(task, stateType, false, true);

  // 1. Add task to completed list expect project
  addCompletedTask(tempTask, stateType);

  //   // 2. Remove task from focus/Backlog
  const taskData = getStateTypeMain(stateType);

  const { dataType, index } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');

  if (index === -1) return;

  log(dataType, index, 'ok check', taskData);

  checkTask(index, taskId, dataType, undefined, undefined, true, stateType);
};

const updateMainTaskFOCUSStatus = (
  taskId: string,
  task: ResponseTaskInterface,
  stateType?: StateType,
) => {
  const tempTask = parseTask(task, stateType, false, true);

  log('updateMainTaskFOCUSStatus', 'found task', task);

  // 1. add task to top of backlog
  addTaskToBacklog(tempTask, stateType);

  // 2. Remove task from completed list expect project
  if (stateType === 'TEMPLATE') return;
  const { COMPLETED } = getStateTypeMain(stateType) as
    | TaskDataType
    | UserDataType
    | ProjectDataType
    | TutorialDataType;
  const { data } = COMPLETED;

  if (!data) return;

  const index = data && data.findIndex((item) => item.id === taskId);
  if (index === -1) return;

  unCheckTask(index, taskId, undefined, undefined, true, stateType);
};

const getCurrentUserId = (stateType: StateType) => {
  if (stateType === 'INDIVIDUAL') {
    const currentUserId = getRecoil(currentUserIdState);
    return currentUserId;
  } else if (stateType !== 'PROJECT' && stateType !== 'TEMPLATE') {
    const userId = getUserId();
    return userId;
  }
  return null;
};

export const fromAnotherUserOrProject = (stateType: StateType, assigneeId: string) => {
  if (stateType === 'INDIVIDUAL') {
    const currentUserId = getRecoil(currentUserIdState);
    if (assigneeId !== currentUserId) return true;
    else return false;
  } else if (stateType !== 'PROJECT' && stateType !== 'TEMPLATE') {
    const userId = getUserId();
    if (userId && assigneeId !== userId) return true;
    else return false;
  }
};
export const removeTask = (taskId: string, parentId: string, stateType?: StateType) => {
  log('removeTask', taskId, parentId);
  const taskData = getStateTypeMain(stateType);
  if (parentId !== null) {
    // find parent
    const { dataType, parent } = findParentTaskWithId(parentId, taskData, 'BACKLOG');
    if (parent && parent?.assignee && fromAnotherUserOrProject(stateType, parent.assignee.id)) {
      const currentId = getCurrentUserId(stateType);
      if (currentId && parent.subTasks && parent.subTasks.length > 0) {
        const subItems = parent.subTasks?.filter(
          (item) => item.assignee && item.assignee.id === currentId,
        );
        if (subItems && subItems?.length === 0) {
          removeTaskInTaskState(parentId, dataType, undefined, stateType);
          return;
        }
      } else {
        removeTaskInTaskState(parentId, dataType, undefined, stateType);
        return;
      }
    }

    // if (parentIndex === -1 || parentIndex === null) return;

    // const index = findSubtaskWithKey(parent, taskId, 'id');

    // if (index === -1) return;

    removeTaskInTaskState(taskId, dataType, parentId, stateType);
  } else {
    const { dataType } = findTaskWithKey(taskId, taskData, 'BACKLOG', 'id');

    // log('removeTask 2', index, taskId, parentId, taskData, dataType);

    // if (index === -1) return;

    removeTaskInTaskState(taskId, dataType, undefined, stateType);
  }
};

export const foldersUpdated = () => {
  getFoldersForUser(getUserId());
};

export const updateThemeItem = (
  themeId: string,
  points: number,
  level: number,
  progress: number,
  levelChanged: string,
) => {
  const { accountData, navigationData } = getRecoil(combinedSelector);

  let purchasedThemes = [...accountData.purchasedThemes];
  const themeIndex = purchasedThemes.findIndex((theme) => theme.themeId === themeId);

  if (themeIndex !== -1) {
    let theme = { ...purchasedThemes[themeIndex] };
    theme.points = points;
    theme.level = level;
    theme.progress = progress;

    if (levelChanged === 'LEVELEDUP') {
      if (
        theme.theme &&
        theme.theme.pointLevelsWithInactiveTriggers &&
        theme.theme.pointLevelsWithInactiveTriggers[level]
      ) {
        let pointLevels = { ...theme.theme.pointLevels };
        const pointLevelsWithInactiveTriggers = { ...theme.theme.pointLevelsWithInactiveTriggers };
        pointLevels = {
          ...pointLevels,
          [level]: theme.theme.pointLevelsWithInactiveTriggers[level],
        };
        delete pointLevelsWithInactiveTriggers[level];
        theme = {
          ...theme,
          theme: {
            ...theme.theme,
            pointLevels,
            pointLevelsWithInactiveTriggers,
          },
        };
      }
    }

    purchasedThemes = purchasedThemes.map((item) => {
      if (item.themeId === themeId) return theme;
      return item;
    });

    setRecoil(purchasedThemesState, parseThemes(purchasedThemes, true) as ThemeInstanceInterface[]);

    if (levelChanged === 'LEVELEDUP') {
      setRecoil(showThemesLevelUpModalState, !navigationData.showThemesLevelUpModal);
    }
  }
};

export const projectUserAdded = (
  newProjectComplete: ResponseProjectType,
  newUser: UserFieldType,
) => {
  const newProject = newProjectComplete;
  const { projectData } = getRecoil(combinedSelector);

  getFoldersForUser(getUserId());
  if (projectData.allProjects === null) return;
  const projectIndex = projectData.allProjects.findIndex((project) => project.id === newProject.id);

  // project not found, lets add it
  if (projectIndex === -1) {
    const project = parseProjectsForMentions([newProjectComplete]);

    const allProjects = [...projectData.allProjects, ...project];

    addAllProjects(allProjects);
  }
  // project found, lets add user if it does not exist
  else {
    const project = projectData.allProjects[projectIndex];

    const userIndex = project.users
      ? project.users.findIndex((user) => user.id === newUser.id)
      : -1;

    if (userIndex !== -1 || project.users === undefined) return;

    const user = parseUsers([newUser]);
    project.users = [...project.users, ...user];

    projectData.allProjects[projectIndex] = project;

    addAllProjects(projectData.allProjects);
  }
};

export const projectUserRemoved = (projectId: string, userId: string) => {
  const { projectData, navigationData } = getRecoil(combinedSelector);

  log('projectUserRemoved', projectId, userId, projectData, navigationData);
  getFoldersForUser(getUserId());
  if (projectData.allProjects === null) return;
  const projectIndex = projectData.allProjects.findIndex((project) => project.id === projectId);

  if (projectIndex === -1) {
    return;
  }
  // project found, lets remove user if it exists
  else {
    const project = projectData.allProjects[projectIndex];

    // logged in user, remove project
    if (userId === getUserId()) {
      projectData.allProjects.splice(projectIndex, 1);

      // check if the screen is open right now, shift to home, if thats the case
      if (
        (isProjectTaskScreen(navigationData.currentRoute) ||
          isProjectCompletedScreen(navigationData.currentRoute)) &&
        projectData.currentProjectId === projectId
      ) {
        setRecoil(goToUrlState, '/');
        log('yes new url');
      }
    }
    // if not, remove just the user
    else {
      const userIndex = project.users ? project.users.findIndex((user) => user.id === userId) : -1;

      if (userIndex === -1 || project.users === undefined) {
        return;
      }

      project.users.splice(userIndex, 1);
      projectData.allProjects[projectIndex] = project;
    }

    addAllProjects(projectData.allProjects);
  }
};

export const updateProjectCounters = (
  projectId: string,
  focusCount: number,
  completedCount: number,
  focusPoints: number,
  backlogPoints: number,
  remainingPoints: number,
  completedPoints: number,
) => {
  if (projectId)
    updateProjectCountersInProjectState(
      projectId,
      focusCount,
      completedCount,
      focusPoints,
      backlogPoints,
      remainingPoints,
      completedPoints,
    );
};

export const updateUserCounters = (
  userId: string,
  focusCount: number,
  completedCount: number,
  focusPoints: number,
  backlogPoints: number,
) => {
  if (!userId) return;

  if (getUserId() === userId) {
    setUserCounters(focusCount, completedCount, focusPoints, backlogPoints);
  } else {
    updateUserCountersInAccountState(
      userId,
      focusCount,
      completedCount,
      focusPoints,
      backlogPoints,
    );
  }
};

export const updateNotificationStatus = (
  unreadCounts: number,
  notifications: NotificationObjectType[],
) => {
  const userNotifications = getRecoil(notificationsState);
  const tempData: NotificationType = {
    ...userNotifications,
    unreadCounts,
    unreadNotifications: notifications,
  };
  setRecoil(notificationsState, tempData);
};

export const addSubtaskCommentsData = (
  parentId: string,
  taskId: string,
  taskTypeValue: TaskType,
  commentData: ResponseCommentType,
  stateType?: StateType,
  projecttaskType?: TaskType,
) => {
  log('addSubtaskCommentsData', commentData);

  const taskData = getStateTypeMain(stateType);

  const taskType =
    stateType === 'PROJECT' || stateType === 'TEMPLATE' ? projecttaskType : taskTypeValue;

  // find parent
  const { parentIndex, parent } = findParentTaskWithId(
    parentId,
    taskData,
    taskType ? getDataTypeFromTutorial(taskType) : 'BACKLOG',
  );

  // Add parent itself and return
  if (parentIndex === -1 || parentIndex === null) {
    log('addSubtaskCommentsData parent not found');
    return;
  }

  log('addSubtaskCommentsData parent found');
  // check if subtask already exists using id
  const subTaskIndex = findSubtaskWithKey(parent, taskId, 'id');
  // sub task not found, return
  if (subTaskIndex === -1) return;

  let commentIndex = -1;
  if (
    taskData &&
    taskType &&
    taskData[taskType] &&
    taskData[taskType].data &&
    taskData[taskType].data.length > parentIndex &&
    taskData[taskType].data[parentIndex] &&
    taskData[taskType].data[parentIndex].subTasks &&
    taskData[taskType].data[parentIndex].subTasks.length > subTaskIndex &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex] &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.length > 0
  ) {
    commentIndex = taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.findIndex(
      (item) => item['tempId'] === commentData.tempId,
    );
  }
  if (commentIndex >= 0) return;
  if (
    taskData &&
    taskType &&
    taskData[taskType] &&
    taskData[taskType].data &&
    taskData[taskType].data.length > parentIndex &&
    taskData[taskType].data[parentIndex] &&
    taskData[taskType].data[parentIndex].subTasks &&
    taskData[taskType].data[parentIndex].subTasks.length > subTaskIndex &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex] &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.length > 0
  ) {
    commentIndex = taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.findIndex(
      (item) => item['id'] === commentData.id,
    );
  }
  if (commentIndex >= 0) return;

  addTaskComment(
    parseTaskComment(commentData, undefined),
    stateType,
    taskType!,
    subTaskIndex,
    parentIndex,
  );
};

export const updateSubtaskCommentsData = (
  parentId: string,
  taskId: string,
  taskTypeValue: TaskType,
  commentData: ResponseCommentType,
  stateType?: StateType,
  projecttaskType?: TaskType,
) => {
  log('updateSubtaskCommentsData', commentData);

  const taskData = getStateTypeMain(stateType);

  const taskType =
    stateType === 'PROJECT' || stateType === 'TEMPLATE' ? projecttaskType : taskTypeValue;

  // find parent
  const { parentIndex, parent } = findParentTaskWithId(
    parentId,
    taskData,
    taskType ? getDataTypeFromTutorial(taskType) : 'BACKLOG',
  );

  // Add parent itself and return
  if (parentIndex === -1 || parentIndex === null) {
    log('updateSubtaskCommentsData parent not found');
    return;
  }

  log('updateSubtaskCommentsData parent found');
  // check if subtask already exists using id
  const subTaskIndex = findSubtaskWithKey(parent, taskId, 'id');
  // sub task not found, return
  if (subTaskIndex === -1) return;

  let commentIndex = -1;
  if (
    taskData &&
    taskType &&
    taskData[taskType] &&
    taskData[taskType].data &&
    taskData[taskType].data.length > parentIndex &&
    taskData[taskType].data[parentIndex] &&
    taskData[taskType].data[parentIndex].subTasks &&
    taskData[taskType].data[parentIndex].subTasks.length > subTaskIndex &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex] &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.length > 0
  ) {
    commentIndex = taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.findIndex(
      (item) => item['tempId'] === commentData.tempId,
    );
  }
  if (commentIndex >= 0) return;
  if (
    taskData &&
    taskType &&
    taskData[taskType] &&
    taskData[taskType].data &&
    taskData[taskType].data.length > parentIndex &&
    taskData[taskType].data[parentIndex] &&
    taskData[taskType].data[parentIndex].subTasks &&
    taskData[taskType].data[parentIndex].subTasks.length > subTaskIndex &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex] &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.length > 0
  ) {
    commentIndex = taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.findIndex(
      (item) => item['id'] === commentData.id,
    );
  }
  // if (commentIndex >= 0) return;

  updateTaskComment(
    parseTaskComment(commentData, undefined),
    stateType,
    taskType!,
    subTaskIndex,
    commentIndex,
    parentIndex,
  );
};

export const deleteSubtaskCommentsData = (
  parentId: string,
  taskId: string,
  taskTypeValue: TaskType,
  commentData: ResponseCommentType,
  stateType?: StateType,
  projecttaskType?: TaskType,
) => {
  log('deleteSubtaskCommentsData', commentData);

  const taskData = getStateTypeMain(stateType);

  const taskType =
    stateType === 'PROJECT' || stateType === 'TEMPLATE' ? projecttaskType : taskTypeValue;

  // find parent
  const { parentIndex, parent } = findParentTaskWithId(
    parentId,
    taskData,
    taskType ? getDataTypeFromTutorial(taskType) : 'BACKLOG',
  );

  // Add parent itself and return
  if (parentIndex === -1 || parentIndex === null) {
    log('deleteSubtaskCommentsData parent not found');
    return;
  }

  log('deleteSubtaskCommentsData parent found');
  // check if subtask already exists using id
  const subTaskIndex = findSubtaskWithKey(parent, taskId, 'id');
  // sub task not found, return
  if (subTaskIndex === -1) return;

  let commentIndex = -1;
  // if (
  //   taskData &&
  //   taskData[taskType] &&
  //   taskData[taskType].data &&
  //   taskData[taskType].data.length > parentIndex &&
  //   taskData[taskType].data[parentIndex] &&
  //   taskData[taskType].data[parentIndex].subTasks &&
  //   taskData[taskType].data[parentIndex].subTasks.length > subTaskIndex &&
  //   taskData[taskType].data[parentIndex].subTasks[subTaskIndex] &&
  //   taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments &&
  //   taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.length > 0
  // ) {
  //   commentIndex = taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.findIndex(
  //     (item) => item['tempId'] === commentData.tempId,
  //   );
  // }
  if (commentIndex >= 0) return;
  if (
    taskData &&
    taskType &&
    taskData[taskType] &&
    taskData[taskType].data &&
    taskData[taskType].data.length > parentIndex &&
    taskData[taskType].data[parentIndex] &&
    taskData[taskType].data[parentIndex].subTasks &&
    taskData[taskType].data[parentIndex].subTasks.length > subTaskIndex &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex] &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments &&
    taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.length > 0
  ) {
    commentIndex = taskData[taskType].data[parentIndex].subTasks[subTaskIndex].comments.findIndex(
      (item) => item['id'] === commentData.id,
    );
  }
  if (commentIndex === -1) return;

  updateTaskDeletionComment(
    parseTaskComment(commentData, undefined),
    stateType,
    taskType!,
    subTaskIndex,
    parentIndex,
    commentIndex,
  );
};

/**
 * addTaskCommentsData
 * @param {string} taskId
 * @param {string} taskType
 * @param {object} commentData
 * @param {string} stateType
 * @description Update Comments of the Task
 */
export const addTaskCommentsData = (
  taskId: string,
  taskType: TaskType,
  commentData: ResponseCommentType,
  stateType?: StateType,
) => {
  log('addTaskCommentsData', commentData);
  const taskData = getStateTypeMain(stateType);
  const { index, dataType } = findTaskWithKey(
    taskId,
    taskData,
    getDataTypeFromTutorial(taskType),
    'id',
  );

  if (index === -1) return;

  let commentIndex = -1;
  if (
    taskData[dataType].data &&
    taskData[dataType].data.length > index &&
    taskData[dataType].data[index].comments.length > 0
  ) {
    commentIndex = taskData[dataType].data[index].comments.findIndex(
      (item) => item['tempId'] === commentData.tempId,
    );
  }
  if (commentIndex >= 0) return;

  if (
    taskData[dataType].data &&
    taskData[dataType].data.length > index &&
    taskData[dataType].data[index].comments.length > 0
  ) {
    commentIndex = taskData[dataType].data[index].comments.findIndex(
      (item) => item['id'] === commentData.id,
    );
  }
  if (commentIndex >= 0) return;

  addTaskComment(parseTaskComment(commentData, undefined), stateType, dataType, index);
};

/**
 * updateTaskCommentsData
 * @param {string} taskId
 * @param {string} taskType
 * @param {object} commentData
 * @param {string} stateType
 * @description Update Comments of the Task
 */
export const updateTaskCommentsData = (
  taskId: string,
  taskType: TaskType,
  commentData: ResponseCommentType,
  stateType?: StateType,
) => {
  log('updateTaskCommentsData', commentData);
  const taskData = getStateTypeMain(stateType);
  const { index, dataType } = findTaskWithKey(
    taskId,
    taskData,
    getDataTypeFromTutorial(taskType),
    'id',
  );

  if (index === -1) return;

  let commentIndex = -1;
  if (
    taskData[dataType].data &&
    taskData[dataType].data.length > index &&
    taskData[dataType].data[index].comments.length > 0
  ) {
    commentIndex = taskData[dataType].data[index].comments.findIndex(
      (item) => item['tempId'] === commentData.tempId,
    );
  }
  if (commentIndex >= 0) return;

  if (
    taskData[dataType].data &&
    taskData[dataType].data.length > index &&
    taskData[dataType].data[index].comments.length > 0
  ) {
    commentIndex = taskData[dataType].data[index].comments.findIndex(
      (item) => item['id'] === commentData.id,
    );
  }
  // if (commentIndex >= 0) return;

  updateTaskComment(
    parseTaskComment(commentData, undefined),
    stateType,
    dataType,
    index,
    commentIndex,
  );
};
/**
 * deleteTaskCommentsData
 * @param {string} taskId
 * @param {string} taskType
 * @param {object} commentData
 * @param {string} stateType
 * @description Delete Comments of the Task
 */
export const deleteTaskCommentsData = (
  taskId: string,
  taskType: TaskType,
  commentData: ResponseCommentType,
  stateType?: StateType,
) => {
  log('deleteTaskCommentsData', commentData);
  const taskData = getStateTypeMain(stateType);
  const { index, dataType } = findTaskWithKey(
    taskId,
    taskData,
    getDataTypeFromTutorial(taskType),
    'id',
  );
  if (index === -1) return;

  let commentIndex = -1;
  // if (
  //   taskData[dataType].data &&
  //   taskData[dataType].data.length > index &&
  //   taskData[dataType].data[index].comments.length > 0
  // ) {
  //   commentIndex = taskData[dataType].data[index].comments.findIndex(
  //     (item) => item['tempId'] === commentData.tempId,
  //   );
  // }
  // if (commentIndex >= 0) return;

  if (
    taskData[dataType].data &&
    taskData[dataType].data.length > index &&
    taskData[dataType].data[index].comments.length > 0
  ) {
    commentIndex = taskData[dataType].data[index].comments.findIndex(
      (item) => item['id'] === commentData.id,
    );
  }
  if (commentIndex === -1) return;

  updateTaskDeletionComment(
    parseTaskComment(commentData, undefined),
    stateType,
    dataType,
    index,
    -1,
    commentIndex,
  );
};

export const addRecurringTask = (
  task: ResponseTaskInterface,
  stateType: StateType = 'RECURRING',
) => {
  log('addRecurringTask', task);

  if (task && task.id) {
    const { recurringTaskData, templateData } = getRecoil(combinedSelector);
    const { data } =
      stateType === 'TEMPLATE'
        ? task.projecttaskType
          ? templateData[task.projecttaskType]
          : templateData.BACKLOG
        : recurringTaskData.FOCUS;

    const index = data.findIndex((dat) => dat.id === task.id);

    if (index !== -1) return;

    const newTask = parseRecurringTask(task);

    if (
      itemShouldBeFilteredOutBeforeStoring(
        stateType === 'TEMPLATE' ? templateData : recurringTaskData,
        task,
        stateType,
      )
    )
      return;

    addTaskData(
      stateType === 'TEMPLATE'
        ? task.projecttaskType
          ? task.projecttaskType
          : 'BACKLOG'
        : 'FOCUS',
      [newTask].concat(data),
      stateType,
    );
  }
};

export const updateRecurringTask = (task: ResponseTaskInterface, stateType?: StateType) => {
  log('updateRecurringTask', task);

  if (task && task.id) {
    const { recurringTaskData, templateData } = getRecoil(combinedSelector);
    const { data } =
      stateType === 'TEMPLATE'
        ? task.taskType
          ? templateData[task.taskType]
          : templateData.BACKLOG
        : recurringTaskData.FOCUS;

    const index = data.findIndex((dat) => dat.id === task.id);

    if (index === -1) return;

    const foundTask = data[index];
    if (foundTask.tempId === task.tempId) {
      log('updateRecurringTask tempId same');
      return;
    }

    const newTask = parseRecurringTask(task);
    data[index] = newTask;

    addTaskData(
      stateType === 'TEMPLATE' ? (task.taskType ? task.taskType : 'BACKLOG') : 'FOCUS',
      data,
      stateType,
    );
  }
};

export const updateRecurringTaskStatus = (task: TaskObjectType) => {
  if (task && task.id) {
    const { recurringTaskData } = getRecoil(combinedSelector);
    const { data } = recurringTaskData.FOCUS;

    if (data === null) return;
    const index = data.findIndex((dat) => dat.id === task.id);

    if (index === -1) return;

    // Delete
    if (!task.active) {
      addTaskData(
        'FOCUS',
        data.filter((item, idx) => idx !== index),
        'RECURRING',
      );
    }
  }
};

export const addProjectTemplate = (template: ResponseTemplateType) => {
  const allTemplates = getRecoil(allTemplatesState);
  const data = allTemplates
    ? [...allTemplates, parseProjectTemplate(template)]
    : [parseProjectTemplate(template)];
  setRecoil(allTemplatesState, data);
};

export const removeProjectTemplate = (templateId: string) => {
  const allTemplates = getRecoil(allTemplatesState);
  if (allTemplates === null) return;
  const data = allTemplates.filter((item) => item.id !== templateId);
  setRecoil(allTemplatesState, data);
};

export const addTemplateTag = (tag: TagObjectType | undefined, add: boolean) => {
  const { templateData } = getRecoil(combinedSelector);
  if (
    tag?.projectTemplateId &&
    templateData.currentTemplateId &&
    tag?.projectTemplateId === templateData.currentTemplateId
  ) {
    const data = addOrRemoveTagInTemplate(templateData.allTemplateTags, tag, add);
    setRecoil(allTemplateTagsState, data);
  }
};

export const updateTemplateCounters = (
  id: string,
  points: number,
  taskCount: number,
  projectCount: number,
) => {
  const allTemplates = getRecoil(allTemplatesState);
  if (id && allTemplates) {
    const data = updateCounters(allTemplates, id, points, taskCount, projectCount);
    setRecoil(allTemplatesState, data ? data : []);
  }
};

const updateCounters = (
  list: TemplateType[],
  id: string,
  points: number,
  taskCount: number,
  projectCount: number,
) => {
  const tempList = list.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        points: points,
        tasksCount: taskCount,
        projectsCount: projectCount,
      };
    }
    return item;
  });

  return tempList;
};

export const addInvitedUser = (user: ResponseUserType) => {
  const invitedMembers = getRecoil(invitedMembersState);
  const userId = user.userId ? user.userId : user.id;
  let newList: UserFieldType[] = [];
  const userIndex = invitedMembers.findIndex((item) => item.id === userId);
  if (userIndex !== -1) {
    const tempData = [...invitedMembers];
    tempData[userIndex] = parseUser(user);
    newList = tempData;
  } else {
    newList = [...invitedMembers, parseUser(user)];
  }

  setRecoil(invitedMembersState, newList);

  const paginated = getRecoil(invitedMembersPaginatedDataState);
  let newList1: UserFieldType[] = [];
  const userIndex1 = paginated.findIndex((item) => item.id === userId);
  if (userIndex1 !== -1) {
    const tempData = [...paginated];
    tempData[userIndex] = parseUser(user);
    newList1 = tempData;
  } else {
    newList1 = [...paginated, parseUser(user)];
  }

  setRecoil(invitedMembersPaginatedDataState, newList1);
};

export const updateTemplateName = (templateId: string, newName: string) => {
  const allTemplates = getRecoil(allTemplatesState);
  if (allTemplates === null) return;
  const newList = allTemplates.map((item) => {
    if (item.id === templateId) return { ...item, name: newName };
    return item;
  });
  setRecoil(allTemplatesState, newList);
};

export const updateTemplateMembers = (templateId: string, usersList: UserFieldType[]) => {
  const allTemplates = getRecoil(allTemplatesState);
  if (allTemplates === null) return;
  const newList = allTemplates.map((item) => {
    if (item.id === templateId)
      return {
        ...item,
        users: parseUsers(usersList),
        membersCount: usersList.length,
      };
    return item;
  });
  setRecoil(allTemplatesState, newList);
};

export const updateWorkspaceStatus = (workspaceId: string, status: boolean, message: string) => {
  const { superAdminData, accountData } = getRecoil(combinedSelector);

  if (accountData.account?.workspaceId === workspaceId && !status) {
    return message ? workspaceDeleted(message) : logOutUser();
  }
  const index =
    accountData.account && accountData.account.workspaceIds
      ? accountData.account.workspaceIds.findIndex((item) => item === workspaceId)
      : -1;
  if (index > -1) {
    reFetchUserSession();
  }

  const tempData: WorkspaceObjectType[] = superAdminData.workspaces.data.map((item) => {
    if (item.id === workspaceId) return { ...item, active: status };
    return item;
  });
  setRecoil(workspacesDataState, tempData);
};

export const workspaceDeleted = (message: string, authToken?: string) => {
  setRecoil(messageModalState, {
    visible: message && message.length > 0 ? true : false,
    message: message,
    authToken: authToken,
  });
};

export const removeWorkspace = (workspaceId?: string) => {
  if (workspaceId && workspaceId.length > 0) {
    const account = getRecoil(accountState);
    if (!account) return;
    const index = account.workspaces
      ? account.workspaces.findIndex((item) => item.id === workspaceId)
      : -1;

    if (index > -1) {
      const tempWorkspaces = account.workspaces
        ? account.workspaces.filter((item) => item.id !== workspaceId)
        : [];
      const tempWorkspaceIds = account.workspaceIds
        ? account.workspaceIds.filter((item) => item !== workspaceId)
        : [];

      setRecoil(accountState, {
        ...account,
        workspaces: tempWorkspaces,
        workspaceIds: tempWorkspaceIds,
      });
    }
  }
};

export const handleUserActivationStatus = (
  status: boolean,
  message: string | undefined,
  workspaceId: string | undefined,
  user: ResponseUserType | null,
  userId: string | null,
) => {
  const account = getRecoil(accountState);

  if (account && account.workspaceId === workspaceId && !status && userId === getUserId()) {
    return message ? workspaceDeleted(message) : logOutUser();
  }
  if (!status) {
    const index =
      account && account.workspaceIds
        ? account.workspaceIds.findIndex((item) => item === workspaceId)
        : -1;
    if (index > -1) {
      reFetchUserSession();
    }
  }

  if (status && user) {
    const allUsers = getRecoil(allUsersState);
    const parsedUser = parseUser(user);
    if (allUsers && allUsers.length > 0) {
      const index = allUsers.findIndex((user) => user.id === parsedUser.id);
      if (index === -1) {
        const tempData: UserFieldType[] = [...allUsers, parsedUser];
        setRecoil(allUsersState, tempData);
      }
    }
  } else {
    if (userId) removeDeletedUser(userId);
  }
};

export const removeDeletedUser = (userId: string) => {
  removeProjectUser(userId);
  removeUser(userId);
};

const removeProjectUser = (userId: string) => {
  const allProjects = getRecoil(allProjectsState);
  if (allProjects === null) return;
  const allProjectsWithUser = allProjects.filter(
    (item) =>
      item.users !== undefined && item.users.filter((user) => user.id === userId).length > 0,
  );
  if (allProjectsWithUser.length > 0) {
    for (let i = 0; i < allProjectsWithUser.length; i++) {
      const element = allProjectsWithUser[i];
      removeDeletedMemberFromProject(allProjects, element.id, userId);
    }
  }
};

export const handleNotifications = (
  unreadCounts: number,
  notificationItem: NotificationObjectType,
) => {
  const accountData = getRecoil(accountDataSelector);
  const notificationSetting = accountData.accountSettings.notificationSetting;
  let unreadNotificationsList: NotificationObjectType[] = [];
  let allNotificationsList: NotificationObjectType[] = [];

  unreadNotificationsList = addNotificationToList(
    accountData.notifications.unreadNotifications,
    notificationItem,
    true,
  );

  allNotificationsList = addNotificationToList(
    accountData.notifications.allNotifications,
    notificationItem,
    false,
  );

  updateUnreadNotificationList(unreadNotificationsList, unreadCounts);
  updateAllNotificationList(allNotificationsList, unreadCounts);
  if (!notificationItem.read && notificationSetting.pushNotifications)
    setRecoil(enableDesktopNotificationState, true);
  setRecoil(notificationPayloadItemState, notificationItem);
  if (
    unreadCounts > 0 &&
    accountData.notifications.unreadNotifications.length < 10 &&
    !accountData.notifications.isUnreadLoading
  ) {
    getNewNotifications('Un-read', 0);
  }
};

const addNotificationToList = (
  list: NotificationObjectType[],
  notificationItem: NotificationObjectType,
  isUnreadOption: boolean,
) => {
  let temp = [...list];
  const found = temp.findIndex((item) => item.id === notificationItem.id);
  if (found === -1 && !notificationItem.read) {
    temp.unshift(notificationItem);
  } else if (found !== -1) {
    if (isUnreadOption) {
      temp.splice(found, 1);
    } else {
      temp = temp.map((item) => {
        if (item.id === notificationItem.id) {
          return {
            ...item,
            read: true,
          };
        }
        return item;
      });
    }
  }
  return temp;
};

export const handleReadNotification = () => {
  getNewNotifications('Un-read', 0);
  getNewNotifications('All', 0);
};

export const changeTheme = (themeId?: string) => {
  if (!themeId) return;
  const purchasedThemes = getRecoil(purchasedThemesState);
  const currentTheme = purchasedThemes.filter((item) => item.themeId === themeId);
  if (currentTheme.length === 0) return;
  const theme = currentTheme[0].theme;
  if (theme && !window.location.pathname.includes('/payment-completion/')) setTheme(theme.name);
};
