import { getRecoil, setRecoil } from 'recoil-nexus';
import { recurringTaskFiltersState, recurringTaskDataSelector } from '.';
import {
  activateDeactivateTask,
  getRecurringTasks as getRecurringTasksFromAPI,
  updateRecurringTask,
} from 'services/TaskService';
import { updateTemplateRecurringTask as updateTemplateRecurringTaskFromAPI } from 'services/TemplateService';
import {
  SEQUENCE_LARGE,
  getNewSequence,
  parseRecurringTask,
  parseRecurringTasks,
} from 'utils/task';
import { log } from 'utils';
import {
  getRecurringTaskPayload,
  insertOnIndex,
  isAnySubtaskWithoutID,
  randomNumber,
  updateTaskField,
  swapIndex,
} from 'utils/helpers';
import { addTaskData, addTaskSizeData, setAutoFocusItem } from 'recoil/TaskState/update';
import { templateDataSelector } from 'recoil/TemplateState';
import { getUserId, isAdmin } from 'services/AuthService';
import { combinedSelector } from 'recoil/CombinedState';
const stateType: StateType = 'RECURRING';

export function setTaskFilter(key: TaskFiltersType, value: string | string[] | null) {
  const taskFilterData = getRecoil(recurringTaskFiltersState);
  const tempData = {
    ...taskFilterData,
    [key]: value,
  };
  setRecoil(recurringTaskFiltersState, tempData);
}

export async function getRecurringTasks() {
  const taskFilters = getRecoil(recurringTaskFiltersState);
  const { data } = await getRecurringTasksFromAPI(taskFilters);

  const finalData = parseRecurringTasks(data.recurringTasks);
  addTaskData('FOCUS', finalData, 'RECURRING');
  addTaskSizeData('FOCUS', data.size, 'RECURRING');
}

export function updateTask(
  property: string,
  value:
    | Date
    | string
    | Date[]
    | boolean
    | { id: string }
    | TagObjectType[]
    | (Date | null)[]
    | null,
  taskIndex: number,
  taskId: string,
  parentIndex?: number,
  parentId?: string,
  taskName?: string,
) {
  const id = typeof parentId !== 'undefined' ? parentId : taskId;
  const index = typeof parentIndex !== 'undefined' ? parentIndex : taskIndex;
  const isSubtask = typeof parentIndex !== 'undefined' ? true : false;

  const recurringTaskData = getRecoil(recurringTaskDataSelector);

  const { data } = recurringTaskData.FOCUS;

  log('updateTask', property, id, index);

  if (data === null) return;
  let task = { ...data[index] };

  if (task.id !== id) return;

  if (isSubtask) {
    if (task['subTasks'] && task['subTasks'][taskIndex]) {
      let subTask = task['subTasks'][taskIndex];

      if (property === 'startAndEndDate') {
        subTask = updateTaskField(subTask, 'startDate', value ? value[0] : null);
        subTask = updateTaskField(subTask, 'endDate', value ? value[1] : null);
      } else subTask = updateTaskField(subTask, property, value);

      if (taskName !== undefined) subTask = updateTaskField(subTask, 'name', taskName);

      task = {
        ...task,
        subTasks: task.subTasks.map((item, ind) => {
          if (ind === taskIndex) return subTask;
          return item;
        }),
      };
    }
  } else {
    if (property === 'startAndEndDate') {
      task = updateTaskField(task, 'startDate', value ? value[0] : null);
      task = updateTaskField(task, 'endDate', value ? value[1] : null);
    } else task = updateTaskField(task, property, value);

    if (taskName !== undefined) task = updateTaskField(task, 'name', taskName);
  }

  const tempData = data.map((item, ind) => {
    if (ind === index) return task;
    return item;
  });

  addTaskData('FOCUS', tempData, stateType);

  if (property === 'startAndEndDate' && (!task['startDate'] || !task['endDate'])) return;

  if (isAnySubtaskWithoutID(task.subTasks)) return;

  const payload = getRecurringTaskPayload(task);

  if (property === 'name') return;

  // call api
  updateRecurringTask(payload);

  log(
    'updateTask',
    payload,
    id,
    index,
    task,
    isSubtask,
    property,
    value,
    taskIndex,
    taskId,
    parentIndex,
    parentId,
  );
}

export function updateTaskName(id: string, dataType: TaskType, stateType: StateType) {
  const recurringTaskData = getRecoil(recurringTaskDataSelector);
  const templateData = getRecoil(templateDataSelector);

  const { data } =
    stateType === 'TEMPLATE'
      ? dataType
        ? templateData[dataType]
        : templateData.BACKLOG
      : recurringTaskData.FOCUS;
  const index = data.findIndex((dat) => dat.id === id);

  if (index === -1) return;

  const task = data[index];

  if (stateType === 'TEMPLATE' && !task.recurringTaskMeta) {
    return;
  }

  if (isAnySubtaskWithoutID(task.subTasks)) return;

  const payload = getRecurringTaskPayload(task);

  // call api
  if (stateType === 'TEMPLATE') {
    updateTemplateRecurringTaskFromAPI(payload);
  } else {
    updateRecurringTask(payload);
  }
}

export async function addNewSubtask(
  parentIndex: number,
  subTaskIndex: number,
  size: SizeOptionsType,
  dataType: TaskType,
  stateType,
) {
  const recurringTaskData = getRecoil(recurringTaskDataSelector);
  const templateData = getRecoil(templateDataSelector);

  let focusData: TaskObjectType[] | null =
    stateType === 'TEMPLATE'
      ? dataType
        ? templateData[dataType].data
        : templateData.BACKLOG.data
      : recurringTaskData.FOCUS.data;

  if (!focusData || !focusData[parentIndex]) return;

  let parent = { ...focusData[parentIndex] };

  const assigneeId = isAdmin() ? null : getUserId();
  const newSequence =
    parent.subTasks && parent.subTasks.length > 1
      ? getNewSequence(subTaskIndex, parent.subTasks)
      : SEQUENCE_LARGE * 20;
  const subtask = getEmptyTaskModel(size, assigneeId, newSequence);
  const subTaskIndx = subTaskIndex ? subTaskIndex : 0;
  parent = {
    ...parent,
    subTasks: [...insertOnIndex(parent.subTasks ? parent.subTasks : [], subTaskIndx, subtask)],
  };

  focusData = focusData.map((item, ind) => {
    if (ind === parentIndex) return parent;
    return item;
  });

  addTaskData(
    stateType === 'TEMPLATE' ? (dataType ? dataType : 'BACKLOG') : 'FOCUS',
    focusData,
    stateType,
  );

  const autoFocus =
    stateType === 'TEMPLATE'
      ? dataType
        ? `${dataType}${subtask.tempId}`
        : `BACKLOG${subtask.tempId}`
      : `FOCUS${subtask.tempId}`;
  setAutoFocusItem(autoFocus, stateType);

  const parentPayload = getRecurringTaskPayload(parent);
  const { data } =
    stateType === 'TEMPLATE'
      ? await updateTemplateRecurringTaskFromAPI(parentPayload)
      : await updateRecurringTask(parentPayload);

  if (data && data.subTasks && Array.isArray(data.subTasks)) {
    const subTaskIndex = data.subTasks.findIndex((sub) => sub.tempId === subtask.tempId);

    if (subTaskIndex === -1) return;

    updateSubTaskId(
      parentIndex,
      subtask.tempId,
      data.subTasks[subTaskIndex].id,
      dataType,
      stateType,
    );
  }
}

const getEmptyTaskModel = (
  size: SizeOptionsType,
  assigneeId: string | null,
  newSequence: number,
) => {
  const tempId = randomNumber() + '';
  return {
    id: tempId,
    sequence: newSequence,
    tempId: tempId,
    name: '',
    tempName: '',
    taskNumber: 0,
    createdUser: '',
    createdDate: null,
    modifiedUser: '',
    modifiedDate: null,
    points: 0,
    size: size,
    tags: [],
    assignee: assigneeId
      ? {
          id: assigneeId,
        }
      : null,
    checked: false,
    taskType: 'BACKLOG',
    startDate: null,
    endDate: null,
    dueDate: null,
  } as TaskObjectType;
};

export function updateSubTaskId(
  parentIndex: number,
  subTaskTempId: string,
  subTaskId: string,
  dataType: TaskType,
  stateType: StateType,
) {
  const recurringTaskData = getRecoil(recurringTaskDataSelector);
  const templateData = getRecoil(templateDataSelector);

  let focusData =
    stateType === 'TEMPLATE'
      ? dataType
        ? templateData[dataType].data
        : templateData.BACKLOG.data
      : recurringTaskData.FOCUS.data;

  if (!focusData[parentIndex]) return;

  let parent = focusData[parentIndex];
  const index = parent.subTasks.findIndex((sub) => sub.tempId === subTaskTempId);

  if (index === -1) return;

  parent = {
    ...parent,
    subTasks: parent.subTasks.map((subItem, ind) => {
      if (ind === index)
        return {
          ...subItem,
          id: subTaskId,
        };
      return subItem;
    }),
  };
  focusData = focusData.map((item, ind) => {
    if (ind === parentIndex) return parent;
    return item;
  });
  addTaskData(
    stateType === 'TEMPLATE' ? (dataType ? dataType : 'BACKLOG') : 'FOCUS',
    focusData,
    stateType,
  );
}

export function deleteTask(
  index: number,
  parentIndex: number | undefined,
  dataType: TaskType,
  stateType: StateType,
) {
  const recurringTaskData = getRecoil(recurringTaskDataSelector);
  const templateData = getRecoil(templateDataSelector);
  let { data } =
    stateType === 'TEMPLATE'
      ? dataType
        ? templateData[dataType]
        : templateData.BACKLOG
      : recurringTaskData.FOCUS;
  let task;

  if (typeof parentIndex !== 'undefined') {
    task = data[parentIndex];
    task = {
      ...data[parentIndex],
      subTasks: data[parentIndex].subTasks.filter((item, ind) => ind !== index),
    };
    data = data.map((item, ind) => {
      if (ind === parentIndex) return task;
      return item;
    });
  } else {
    // delete task
    checkTask(index, undefined, true);

    return;
  }

  addTaskData(
    stateType === 'TEMPLATE' ? (dataType ? dataType : 'BACKLOG') : 'FOCUS',
    data,
    stateType,
  );

  const payload = getRecurringTaskPayload(task);
  if (stateType === 'TEMPLATE') {
    updateTemplateRecurringTaskFromAPI(payload);
  } else {
    updateRecurringTask(payload);
  }
}

export function checkTask(index: number, parentIndex: number | undefined, checked: boolean) {
  log('checkTask', index, parentIndex, checked);

  if (typeof parentIndex === 'undefined') {
    const recurringTaskData = getRecoil(recurringTaskDataSelector);
    const { data } = recurringTaskData.FOCUS;
    if (data === null) return;
    const task = data[index];
    const tempData = data.filter((item, ind) => ind !== index);

    addTaskData('FOCUS', tempData, stateType);

    activateDeactivateTask(task.id, false);
  }
}

export async function updateTaskMeta(
  taskId: string,
  metaData: RecurringTaskPayloadType,
  dataType: TaskType,
  statesType: StateType,
) {
  const recurringTaskData = getRecoil(recurringTaskDataSelector);
  const templateData = getRecoil(templateDataSelector);
  let tempData =
    statesType === 'TEMPLATE'
      ? dataType
        ? templateData[dataType].data
        : templateData.BACKLOG.data
      : recurringTaskData.FOCUS.data;

  const index = tempData.findIndex((dat) => dat.id === taskId);

  if (index === -1) return;

  let task: TaskObjectType = tempData[index];
  task = {
    ...task,
    recurringTaskMeta: {
      ...task.recurringTaskMeta,
      id: task.recurringTaskMeta ? task.recurringTaskMeta.id : '',
      repeatFrequency: metaData.repeatFrequency,
      repeatType: metaData.repeatType,
      repeatDays: metaData.repeatDays,
      executionTime: metaData.executionTime ? metaData.executionTime : undefined,
    },
  };
  tempData = tempData.map((item, ind) => {
    if (ind === index) return task;
    return item;
  });

  log('updateTaskMeta', tempData, metaData);

  const payload = getRecurringTaskPayload(task);
  if (statesType === 'TEMPLATE') {
    const { data, success } = await updateTemplateRecurringTaskFromAPI(payload);
    if (success) {
      tempData[index] = parseRecurringTask(data);
    }
  } else {
    const { data, success } = await updateRecurringTask(payload);
    if (success) {
      tempData[index] = parseRecurringTask(data);
    }
  }

  addTaskData(
    statesType === 'TEMPLATE' ? (dataType ? dataType : 'BACKLOG') : 'FOCUS',
    tempData,
    statesType,
  );
}

export function reOrderSubtasks(
  parentId: string,
  from: number,
  to: number,
  dataType: TaskType,
  statesType?: StateType,
) {
  const { recurringTaskData, templateData } = getRecoil(combinedSelector);
  const { data } =
    statesType === 'TEMPLATE'
      ? dataType
        ? templateData[dataType]
        : templateData.BACKLOG
      : recurringTaskData.FOCUS;
  const index = data.findIndex((dat) => dat.id === parentId);

  if (index === -1) return;

  const parent = data[index];

  parent.subTasks = swapIndex(parent.subTasks, from, to);

  data[index] = parent;

  addTaskData(
    statesType === 'TEMPLATE' ? (dataType ? dataType : 'BACKLOG') : 'FOCUS',
    data,
    statesType,
  );

  if (isAnySubtaskWithoutID(parent.subTasks)) return;

  const payload = getRecurringTaskPayload(parent);
  if (statesType === 'TEMPLATE') {
    updateTemplateRecurringTaskFromAPI(payload);
  } else {
    updateRecurringTask(payload);
  }
}
