import { useEffect, useState, Fragment, useMemo, Suspense, useRef } from 'preact/compat';
import { Strings } from 'resources';
import handleViewport from 'react-in-viewport';
import {
  getUserId,
  getUserWorkspace,
  getUserEmail,
  updateUserRole,
  activateDeactivateUser as activateDeactivateUserAPI,
  getUserWorkspaceId,
} from 'services/AuthService';
import Switch from 'react-switch';
import { formatDateForMeeting, getName } from 'utils/task';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { log } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import Table from 'components/Table';
import Spinner from 'components/UI/Spinner';
import { useRecoilValue } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import { addInvitedMembersList, getInvitedMembersList } from 'recoil/AccountState/update';
import AvatarIcon from 'assets/images/Avatar.png';

const OwnerTransfer = lazyWithRetry(() => import('modals/OwnerTransfer'));

type ManageUsersPropsType = {
  searchText: string;
};
export default function ManageUsers(props: ManageUsersPropsType) {
  const { searchText } = props;
  const accountData = useRecoilValue(accountDataSelector);
  const [filteredUsers, setFilteredUsers] = useState<UserFieldType[]>([]);
  const [showInactive, setShowInactive] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserFieldType | null>(null);
  const [showOwnerTransferModal, setShowOwnerTransferModal] = useState(false);

  const [isOwnerUpdated, setIsOwnerUpdated] = useState(false);
  const isOwnerAccount = useRef(false);

  const userId = getUserId();
  const userWorkspace = getUserWorkspace();
  const columns = useMemo(
    () => [
      {
        Header: Strings.member_invited,
        Cell: ({ row }) => renderUser(row.original),
      },
      {
        Header: Strings.status,
        Cell: ({ row }) => renderInvitationStatus(row.original),
      },
      {
        Header: Strings.invited_date,
        Cell: ({ row }) => renderInvitedDate(row.original),
      },
      {
        Header: Strings.joined_date,
        Cell: ({ row }) => renderJoinedDate(row.original),
      },
      {
        Header: Strings.last_login,
        Cell: ({ row }) => renderLastLoginDate(row.original),
      },
      {
        Header: Strings.first_login,
        Cell: ({ row }) => renderFirstLoginDate(row.original),
      },
      {
        Header: Strings.time_logged_in,
        Cell: ({ row }) => renderTotalLoginTime(row.original),
      },
      {
        Header: Strings.role,
        Cell: ({ row }) => renderUserRole(row.original, row.index),
      },
      {
        Header: Strings.active,
        Cell: ({ row }) => renderUserActive(row.original),
      },
    ],
    [JSON.stringify(accountData.invitedMembersPaginatedList.data)],
  );

  useEffect(() => {
    setFilteredUsers(accountData.invitedMembersPaginatedList.data);
    setShowInactive(!accountData.invitedMembersPaginatedList.active);
  }, [accountData.invitedMembersPaginatedList]);

  useEffect(() => {
    getInvitedMembersList(true, null, searchText, null);
  }, [searchText]);

  const renderUser = (user: UserFieldType) => {
    return (
      <span className='user-name'>
        <img src={user.profileImage ? user.profileImage : AvatarIcon} alt='profile' />
        {`${getName(user)} (${user.email})`}
      </span>
    );
  };

  const getInvitationStatusClass = (user: UserFieldType) => {
    if (user.invitationStatus) {
      switch (user.invitationStatus.toLowerCase()) {
        case 'invited':
          return 'success';
        default:
          return 'archived';
      }
    }
    return '';
  };

  const renderInvitationStatus = (user: UserFieldType) => {
    return (
      <Fragment>
        <span
          className={`user-status ${user.invitationStatus ? '' : 'hide'} ${getInvitationStatusClass(
            user,
          )}`}
        >
          {user.invitationStatus}
        </span>
      </Fragment>
    );
  };

  const renderInvitedDate = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.invitedOn ? '' : 'hide'}`}>
          {user.invitedOn ? formatDateForMeeting(user.invitedOn) : ''}
        </span>
      </Fragment>
    );
  };

  const renderJoinedDate = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.joiningDate ? '' : 'hide'}`}>
          {user.joiningDate ? formatDateForMeeting(user.joiningDate) : ''}
        </span>
      </Fragment>
    );
  };

  const renderLastLoginDate = (user) => {
    return (
      <Fragment>
        {/* <span className="user-item-title">{Strings.last_login}</span> */}
        <span className={`user-dates ${user.lastLogInDate ? '' : 'hide'}`}>
          {formatDateForMeeting(user.lastLogInDate)}
        </span>
      </Fragment>
    );
  };

  const renderFirstLoginDate = (user) => {
    return (
      <Fragment>
        {/* <span className="user-item-title">{Strings.first_login}</span> */}
        <span className={`user-dates ${user.firstLogInDate ? '' : 'hide'}`}>
          {formatDateForMeeting(user.firstLogInDate)}
        </span>
      </Fragment>
    );
  };

  const renderTotalLoginTime = (user) => {
    return (
      <Fragment>
        {/* <span className="user-item-title">{Strings.time_logged_in}</span> */}
        <div className={`user-total-login user-count-con ${user.totalLoggedIn ? '' : 'hide'}`}>
          <span className='count'>{user.totalLoggedIn}</span>
        </div>
      </Fragment>
    );
  };

  const renderRoleMenuButton = (user) => {
    return (
      <MenuButton className='user-role-btn'>
        <div className='user-role-con'>
          <span className={`user-role ${user.role ? '' : 'no-role'}`}>
            {user.role ? user.role.toLowerCase() : Strings.no_role}
          </span>
          <span className='edit-role'>{Strings.edit_role}</span>
        </div>
      </MenuButton>
    );
  };

  const userRoleUpdated = async (index: number, userId: string, role: UserRoleType) => {
    const cancelToken = getCancelToken(userId);
    log(
      'userRoleUpdated',
      accountData.invitedMembersPaginatedList.data.length,
      index,
      userId,
      role,
    );

    const users = [...accountData.invitedMembersPaginatedList.data];

    const newIndex = users && users.findIndex((item) => item.id === userId);

    if (newIndex === -1) return;

    if (role === users[newIndex].role) return;

    const { success } = await updateUserRole(userId, role, cancelToken);

    if (success) updateUserRoleUsingId(userId, role);
  };

  const updateUserRoleUsingId = (userId: string, role: UserRoleType) => {
    const users = [...accountData.invitedMembersPaginatedList.data];
    const index = users && users.findIndex((item) => item.id === userId);

    if (index !== -1) {
      users[index] = { ...users[index], role };
      addInvitedMembersList(users);
    }
  };

  const userRoleSelected = (index: number, user: UserFieldType, role: UserRoleType) => {
    log(
      'userRoleSelected',
      accountData.invitedMembersPaginatedList.data.length,
      index,
      user.id,
      role,
    );
    if (role === 'owner') {
      setSelectedUser(user);
      setShowOwnerTransferModal(true);
    } else {
      if (user.id) userRoleUpdated(index, user.id, role);
    }
  };

  useEffect(() => {
    isOwnerAccount.current = isWorkspaceOwner();
  }, [isOwnerUpdated]);

  const updateOwner = () => {
    setIsOwnerUpdated(!isOwnerUpdated);
  };

  const isWorkspaceOwner = () => {
    if (typeof userWorkspace === 'boolean') return false;
    const userEmail = getUserEmail();
    if (userEmail === userWorkspace.owner) {
      return true;
    }
    return false;
  };

  const renderUserRole = (user: UserFieldType, index: number) => {
    return (
      <Fragment>
        <Menu className='user-role-menu' menuButton={renderRoleMenuButton(user)}>
          {isOwnerAccount.current &&
            typeof userWorkspace !== 'boolean' &&
            user.email !== userWorkspace.owner && (
              <MenuItem
                disabled={!user.active}
                onClick={() => userRoleSelected(index, user, 'owner')}
              >
                {Strings.owner}
              </MenuItem>
            )}
          {isOwnerAccount.current &&
            typeof userWorkspace !== 'boolean' &&
            user.email !== userWorkspace.owner && <MenuDivider />}

          <MenuItem disabled={!user.active} onClick={() => userRoleSelected(index, user, 'admin')}>
            {Strings.admin}
          </MenuItem>
          <MenuDivider />
          <MenuItem
            disabled={!user.active}
            onClick={() => userRoleSelected(index, user, 'manager')}
          >
            {Strings.manager}
          </MenuItem>
          <MenuDivider />
          <MenuItem
            disabled={!user.active}
            onClick={() => userRoleSelected(index, user, 'standard')}
          >
            {Strings.standard}
          </MenuItem>
          <MenuDivider />
          <MenuItem disabled={!user.active} onClick={() => userRoleSelected(index, user, 'guest')}>
            {Strings.guest}
          </MenuItem>
        </Menu>
      </Fragment>
    );
  };

  const userActivationUpdated = async (userId: string, active: boolean, workspaceId: string) => {
    const abortC = getCancelToken(userId);
    log('activateDeactivateUser', accountData.invitedMembersPaginatedList.data);
    return activateDeactivateUser(userId, active, workspaceId, abortC);
  };

  const activateDeactivateUser = async (
    userId: string,
    active: boolean,
    workspaceId: string,
    abortC: AbortController,
  ) => {
    updateUserActiveStatusUsingId(userId);
    const { success } = await activateDeactivateUserAPI(userId, active, workspaceId, abortC);

    if (success) updateUserActiveStatusUsingId(userId);
  };

  const updateUserActiveStatusUsingId = (userId: string) => {
    const users = [...accountData.invitedMembersPaginatedList.data];
    const index = users && users.findIndex((item) => item.id === userId);

    if (index !== -1) {
      addInvitedMembersList(users.filter((item) => item.id !== userId));
    }
  };

  const getCancelToken = (userId: string) => {
    const newToken = new AbortController();
    const users = accountData.invitedMembersPaginatedList.data;
    const listIndex = accountData.invitedMembersPaginatedList.data.findIndex(
      (item) => item.id === userId,
    );
    if (listIndex === -1) {
      log('not found');
      return newToken;
    }
    const cancelToken = users[listIndex].abortController;
    if (cancelToken) cancelToken.abort();

    const tempUsers = users.map((item, index) => {
      if (listIndex === index)
        return {
          ...item,
          abortController: newToken,
        };
      return item;
    });

    addInvitedMembersList(tempUsers);

    return newToken;
  };

  const renderUserActive = (user: UserFieldType) => {
    return (
      <Fragment>
        <span className='user-active'>
          {/** @ts-ignore */}
          <Switch
            onChange={(nextChecked) =>
              userActivationUpdated(user.id ? user.id : '', nextChecked, getUserWorkspaceId())
            }
            checked={user.active ? user.active : false}
            height={24}
            handleDiameter={14}
            onColor='#6666ff'
            offColor='#e0e0ff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={user.id === userId}
          />
        </span>
      </Fragment>
    );
  };

  const sortBy = (name: SortByType) => {
    log('sort by', name);
    getInvitedMembersList(true, name, searchText, null);
  };

  const renderLoading = () => {
    const { isLoading, hasMore } = accountData.invitedMembersPaginatedList;
    if (isLoading && hasMore)
      return (
        <div style={{ paddingLeft: '30px' }}>
          <Spinner />
        </div>
      );

    return null;
  };

  const loadMoreData = async () => {
    const { isLoading, hasMore } = accountData.invitedMembersPaginatedList;

    if (isLoading || !hasMore) return;

    getInvitedMembersList(false, null, searchText, null);
  };

  const Block = (props: any) => {
    const { inViewport, forwardedRef } = props;

    if (inViewport) loadMoreData();
    return (
      <div className='viewport-block' ref={forwardedRef}>
        <div style={{ paddingBottom: '0px' }}>{inViewport ? renderLoading() : ''}</div>
      </div>
    );
  };

  const ViewportBlock = handleViewport(Block, { rootMargin: '300px' });

  const inactiveCheckboxClicked = () => {
    getInvitedMembersList(true, null, searchText, showInactive);
  };
  return (
    <Fragment>
      <div className='title-container title-container-manage-user'>
        <div className='list-title'>{Strings.manage_users}</div>
        <div className='checkbox-container'>
          <input
            type='checkbox'
            id='inactive'
            name='inactive'
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            checked={showInactive}
            onChange={() => inactiveCheckboxClicked()}
          />
          <label htmlFor='inactive'>{Strings.show_inactive}</label>
        </div>
      </div>
      <div className='manage-user-con'>
        <Table
          columns={columns}
          data={filteredUsers}
          headContainerClass='user-container-head'
          elementsClass='user-item'
          bodyContainerClass='user-in-container'
          sortBy={(name) => sortBy(name)}
          sorting={true}
          sortName={accountData.invitedMembersPaginatedList.sortBy}
          sortOrder={accountData.invitedMembersPaginatedList.sortOrder}
          clickable={false}
        />
      </div>
      <Suspense fallback={null}>
        {showOwnerTransferModal ? (
          <OwnerTransfer
            closeModal={() => setShowOwnerTransferModal(false)}
            user={selectedUser}
            updateOwner={() => updateOwner()}
            searchText={searchText}
          />
        ) : null}
      </Suspense>
      <ViewportBlock key='load-next' />
    </Fragment>
  );
}
