import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { TargetedEvent } from 'preact/compat';

import Crown from '../../../assets/images/payment/crown.svg';

type SmallButtonPropsType = {
  btnType?: string;
  clicked?: (e: TargetedEvent) => void;
  disabled?: boolean;
  loading?: boolean;
  children: preact.ComponentChildren;
  limit?: boolean;
};
const SmallButton = (props: SmallButtonPropsType) => {
  const { btnType, clicked, disabled, loading, limit } = props;

  const renderLoadingIcon = () => {
    if (loading) {
      return (
        <span className='loading-con'>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      );
    } else if (limit) {
      return (
        <span className='loading-con'>
          <img src={Crown} alt={'crown'} />
        </span>
      );
    }

    return null;
  };

  return (
    <button
      disabled={disabled || loading}
      className={['small-button', btnType].join(' ')}
      onClick={clicked}
    >
      {props.children}
      {renderLoadingIcon()}
    </button>
  );
};

export default SmallButton;
