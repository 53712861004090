import { useEffect, useState, useRef } from 'preact/compat';
import {
  faBars,
  faQuestion,
  faTimes,
  faEllipsisV,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import { log } from 'utils';
import { isAdminScreen } from 'utils/task';
import AvatarIcon from 'assets/images/Avatar.png';
import LogoutIcon from 'assets/images/logout.png';
import MoreIcon from 'assets/images/more.png';
import FeedbackIcon from 'assets/images/feedback.png';
import SettingsIcon from 'assets/images/settings.png';
import SearchIcon from 'assets/images/search.png';
import SuperAdminIcon from 'assets/images/super-admin-icon.svg';
import Logo from 'assets/images/Logo-Icon.png';

import { Tooltip } from 'react-tooltip';
import {
  getUserWorkspace,
  getUserWorkspaces,
  logOutUser,
  isAdmin,
  isSuperAdmin,
  updateWorkspaceName,
  getUserEmail,
} from 'services/AuthService';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Strings } from 'resources';
import TutorialTopBarInput from 'components/TutorialTopBarInput';
import { useAlert } from 'react-alert';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import {
  currentRouteState,
  goToUrlState,
  hideLeftSidebarState,
  hideWorkspaceSwitchState,
  navigationDataSelector,
  showFeedbackModalState,
  showSearchModalState,
  showUserProfileModalState,
} from 'recoil/NavigationState/index';
import { tutorialDataSelector } from 'recoil/TutorialState';
import { setItem } from 'utils/storage';
import { updateWorkspace } from 'recoil/AccountState/update';
import { updateFlag } from 'recoil/TutorialState/update';

export default function TutorialTopBar(props: TopBarPropsType) {
  const { currentRoute } = props;
  const accountData = useRecoilValue(accountDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const tutorialData = useRecoilValue(tutorialDataSelector);
  const stateRef = useRef<boolean>();
  stateRef.current = tutorialData.flags.createTask || tutorialData.flags.activateProjectTask;
  const navigate = useNavigate();
  const alert = useAlert();
  // const [showNotification, setShowNotification] = useState(false);
  const [userWorkspace, setUserWorkspace] = useState(getUserWorkspace());
  const [userWorkspaces, setUserWorkspaces] = useState<WorkspaceType[]>(getUserWorkspaces());

  const [renameWorkspaceId, setRenameWorkspaceId] = useState(null);
  const [renameWorkspaceValue, setRenameWorkspaceValue] = useState('');

  const setShowUserProfileModal = useSetRecoilState(showUserProfileModalState);

  const setCurrentRoute = useSetRecoilState(currentRouteState);
  const setHideLeftSidebar = useSetRecoilState(hideLeftSidebarState);
  const setHideWorkspaceSwitch = useSetRecoilState(hideWorkspaceSwitchState);
  const setSearchModalState = useSetRecoilState(showSearchModalState);
  const setFeedbackModalStatus = useSetRecoilState(showFeedbackModalState);
  const setGoToUrl = useSetRecoilState(goToUrlState);

  useEffect(() => {
    if (accountData.account && accountData.account.workspace && accountData.account.workspaces) {
      setUserWorkspace(accountData.account.workspace);
      setUserWorkspaces(accountData.account.workspaces);
    }
  }, [accountData]);

  useEffect(() => {
    log('navigate object', navigate);
    if (typeof navigationData.goToUrl !== 'undefined') {
      const url = navigationData.goToUrl;
      setGoToUrl(undefined);
      log('SET NEW URL');
      setTimeout(() => {
        if (url === '/') navigate(url, { replace: true });
        else navigate(url);
      }, 20);
    }
  }, [navigationData.goToUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentRoute(currentRoute);
  }, [currentRoute]);

  const toggleLeftSidebar = (status?: boolean) => {
    setHideLeftSidebar(status ? status : !navigationData.hideLeftSidebar);
  };

  const isThisProjectsScreen = () => {
    const { currentRoute } = navigationData;

    return isAdminScreen(currentRoute);
  };

  const toggleNotificationList = () => {
    // setShowNotification(true);
  };

  const renderNotificationBadge = () => {
    if (accountData.notifications.unreadCounts > 0) {
      return (
        <span
          id='notif-badge'
          className='notif-badge'
          onClick={() => toggleNotificationList()}
          data-place='bottom'
          data-class='tool-tip'
          data-background-color='#3c3c5b'
          data-tooltip-id={`tool-tip-my-pending-notifications`}
          data-tip={`You have ${accountData.notifications.unreadCounts} unread notifications`}
        >
          <p id='notif-count'>{accountData.notifications.unreadCounts}</p>
        </span>
      );
    }
    return;
  };

  const toggleWorkspaceSwitch = (status?: boolean) => {
    setItem(
      'hideWorkspaceSwitch',
      status ? status.toString() : (!navigationData.hideWorkspaceSwitch).toString(),
    );
    setHideWorkspaceSwitch(status ? status : !navigationData.hideWorkspaceSwitch);
  };

  const setNewWorkspaceName = async (id: string, name: string) => {
    if (typeof userWorkspace === 'boolean') return;
    resetRenameWorkspace();
    const initialWorkspace = userWorkspace;
    const tempWorkspace = userWorkspace;
    tempWorkspace.id = id;
    tempWorkspace.name = name;
    // Update name in redux
    updateWorkspace(tempWorkspace);

    // Call api to update in backend
    const { data, success } = await updateWorkspaceName(id, name);

    if (!success) {
      updateWorkspace(initialWorkspace);
    } else {
      // Update name in redux
      updateWorkspace(data);
    }
  };

  /**
   * render workspace name as dropdown if multiple workspaces exist and text if only one workspace in user's profile
   */
  const renderWorkspaceName = () => {
    if (typeof userWorkspace === 'boolean') return;
    if (renameWorkspaceId) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setNewWorkspaceName(userWorkspace.id, renameWorkspaceValue);
          }}
        >
          <span className='rename-folder-input-con'>
            <input
              className='rename-input'
              value={renameWorkspaceValue}
              onChange={(e) => setRenameWorkspaceValue((e.target as HTMLInputElement).value)}
              placeholder='Workspace name'
            />
          </span>
        </form>
      );
    }

    return (
      <div className='user-workspace-name-con'>
        <div
          id='user-workspace-option'
          className='user-workspace user-workspace-option'
          title={userWorkspace && userWorkspace.name ? userWorkspace.name : ''}
          onClick={() => toggleWorkspaceSwitch()}
        >
          {userWorkspace && userWorkspace.name ? userWorkspace.name : ''}
        </div>
        {renderOverlay()}
      </div>
    );
  };

  const openProfileModal = () => {
    setShowUserProfileModal(true);
  };

  const getProfileImage = () => {
    if (accountData.account && accountData.account.profileImage) {
      return accountData.account.profileImage;
    }

    return AvatarIcon;
  };

  const searchClicked = () => {
    setSearchModalState(true);
  };

  const showFeedbackModal = () => {
    setFeedbackModalStatus(true);
  };

  const leaveWorkspace = async () => {
    if (tutorialData.flags.showAdminButton) {
      alert.error('Please select Workspace Administration to continue.');
      return;
    }
  };

  const renameWorkspace = () => {
    if (tutorialData.flags.showAdminButton) {
      alert.error('Please select Workspace Administration to continue.');
      return;
    }
  };

  const keyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetRenameWorkspace();
    }
  };

  const resetRenameWorkspace = () => {
    setRenameWorkspaceId(null);
    setRenameWorkspaceValue('');

    document.removeEventListener('keydown', keyDown, false);
  };

  const isWorkspaceOwner = () => {
    if (typeof userWorkspace === 'boolean') return false;
    const userEmail = getUserEmail();
    if (userEmail === userWorkspace.owner) {
      return true;
    }
    return false;
  };

  const renderWorkspaceNameUpdateMenu = () => {
    if (!isAdmin() && !isWorkspaceOwner()) {
      return <MenuItem className='hidden'>hidden</MenuItem>;
    }

    return (
      <MenuItem id='rename-workspace-menu-item' onClick={() => renameWorkspace()}>
        {Strings.rename_workspace}
      </MenuItem>
    );
  };

  const showWorspaceCreationModal = () => {
    if (tutorialData.flags.showAdminButton) {
      alert.error('Please select Workspace Administration to continue.');
      return;
    }
  };

  const renderCreateWorkspaceMenu = () => {
    return (
      <MenuItem id='create-workspace-menu-item' onClick={() => showWorspaceCreationModal()}>
        {Strings.create_workspace}
      </MenuItem>
    );
  };

  const renderLeaveWorkspaceMenu = () => {
    if (userWorkspaces.length <= 1 || isWorkspaceOwner()) {
      return <MenuItem className='hidden'>hidden</MenuItem>;
    }

    return (
      <MenuItem id='leave-workspace-menu-item' onClick={() => leaveWorkspace()} key={2}>
        {Strings.leave_workspace}
      </MenuItem>
    );
  };

  const renderAdminOption = () => {
    // if (!isAdmin()) return null;
    return (
      <span
        id='admin-icon-con'
        className='top-icon admin-icon'
        onClick={() => showAdmin()}
        data-place='bottom'
        data-class='tool-tip'
        data-background-color='#3c3c5b'
        data-tooltip-id={`tool-tip-admin`}
        data-tooltip-content={Strings.workspace_administration}
      >
        <button id='admin-btn' className='admin-btn' onClick={showAdmin}>
          <FontAwesomeIcon icon={faScrewdriverWrench} />
        </button>
        {renderOverlay(tutorialData.flags.showAdminButton ? 'hide-overlay' : '')}
      </span>
    );
  };

  const renderWorkspaceMenu = () => {
    return (
      <div className='workspace-options-container'>
        <Menu
          className='workspace-menu'
          menuButton={
            <MenuButton id='workspace-menu-btn' className='workspace-menu-btn'>
              <FontAwesomeIcon
                id='workspace-menu-icon'
                className='workspace-menu-icon icon'
                icon={faEllipsisV}
                size='sm'
              />
            </MenuButton>
          }
          direction='bottom'
          align='end'
        >
          {renderCreateWorkspaceMenu()}
          {renderLeaveWorkspaceMenu()}
          {renderWorkspaceNameUpdateMenu()}
        </Menu>
        {renderOverlay()}
      </div>
    );
  };

  const renderOverlay = (styleClass?: string) => {
    return <div className={`tutorial-overlay ${styleClass}`}></div>;
  };

  const renderLeftContainer = () => {
    return (
      <div id='top-bar-left-side' className='left-side'>
        <div id='left-bar-toggle' className='left-bar-toggle' onClick={() => toggleLeftSidebar()}>
          {navigationData.hideLeftSidebar ? (
            <FontAwesomeIcon id='bars-icon' icon={faBars} size='lg' />
          ) : (
            <FontAwesomeIcon id='times-icon' icon={faTimes} size='lg' />
          )}
          {renderOverlay()}
        </div>
        <div id='site-logo-con' className='site-logo'>
          <Link to='/'>
            {/* @ts-ignore */}
            <img src={Logo} alt='Logo' />
          </Link>
          {renderOverlay()}
        </div>
        {renderWorkspaceName()}
        {renderWorkspaceMenu()}
      </div>
    );
  };

  const renderSuperAdminMenuLink = () => {
    if (!isSuperAdmin())
      return (
        <MenuItem id='super-admin-hidden-menu-item' className='hidden'>
          hide
        </MenuItem>
      );

    return [
      <MenuItem
        id='super-admin-menu-item'
        onClick={() => navigate('/super-admin/themes')}
        key='workspaces-link'
      >
        <img id='super-admin-menu-img' src={SuperAdminIcon} alt='Super Admin' />

        {Strings.super_admin}
      </MenuItem>,
      <MenuDivider key='workspaces-divider' />,
    ];
  };

  const renderSettings = () => {
    return (
      <span id='more-icon-con' className='top-icon more-icon'>
        <Menu
          id='more-menu'
          menuButton={
            <MenuButton>
              <img id='more-menu-img' src={MoreIcon} alt='More' />
            </MenuButton>
          }
          align='end'
        >
          <MenuDivider />
          <MenuItem id='feedback-menu-item' onClick={showFeedbackModal}>
            <img src={FeedbackIcon} alt='Submit feedback' />
            {Strings.submit_feedback}
          </MenuItem>
          <MenuDivider />

          {renderSuperAdminMenuLink()}
          <MenuItem id='settings-menu-item' onClick={() => navigate('/settings')}>
            <img src={SettingsIcon} alt='Settings' />
            {Strings.settings}
          </MenuItem>
          <MenuDivider />
          <MenuItem id='tutorial-menu-item'>
            <FontAwesomeIcon id='question-icon' icon={faQuestion} size='sm' />
            {Strings.user_tutorial}
          </MenuItem>
          <MenuDivider />
          <MenuItem id='logout-menu-item' onClick={logOutUser}>
            <img src={LogoutIcon} alt='Logout' />
            {Strings.logout}
          </MenuItem>
        </Menu>
        {renderOverlay()}
      </span>
    );
  };

  const showAdmin = () => {
    navigate('/admin/portfolio/user-tutorial');
    updateFlag('showArchiveCheckbox', true);
    setHideLeftSidebar(true);
  };

  const renderNotificationOption = () => {
    return (
      <div id='notification-section-con' className='notification-section'>
        <div id='notification-icon-sec' className='iconSection'>
          <span
            id='notif-icon-con'
            className={`top-icon notif-icon ${isThisProjectsScreen() ? 'selected' : ''}`}
            onClick={() => toggleNotificationList()}
            data-place='bottom'
            data-class='tool-tip'
            data-background-color='#3c3c5b'
            data-tooltip-id={`tool-tip-my-notifications`}
            data-tip={`View my notifications`}
          />
          <Tooltip className='tool-tip' id={`tool-tip-my-notifications`} />
          {renderNotificationBadge()}
          <Tooltip className='tool-tip' id={`tool-tip-my-pending-notifications`} />
        </div>
        {renderOverlay()}
      </div>
    );
  };

  const renderSearchOption = () => {
    return (
      <span
        id='search-icon'
        className='top-icon search-icon'
        data-place='bottom'
        data-class='tool-tip'
        data-background-color='#3c3c5b'
        data-tooltip-id={`tool-tip-search`}
        data-tip={`Search`}
      >
        <button id='search-btn' className='search-btn' onClick={searchClicked}>
          <img id='search-img' src={SearchIcon} alt='search' />
        </button>
        {renderOverlay()}
      </span>
    );
  };

  const onFocusTaskBox = () => {
    if (stateRef.current) {
      updateFlag('topTaskBoxActive', true);
    }
  };

  const onEnterTask = () => {
    updateFlag('taskCreationCompletion', true);
  };

  const renderBorder = () => {
    return <div className='task-input-border'></div>;
  };

  return (
    <div
      id='tutorial-top-bar-container'
      className={`tutorial-top-bar-container ${
        navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
      }`}
    >
      {renderLeftContainer()}
      <div
        id='task-input-container'
        className={`task-input-container ${navigationData.showTaskCreationBox ? 'show' : 'hide'}`}
      >
        <div id='task-input' className='task-input'>
          <TutorialTopBarInput
            currentRoute={currentRoute}
            onEnter={onEnterTask}
            onFocusTaskBox={onFocusTaskBox}
          />
        </div>
        {renderOverlay(
          stateRef.current || tutorialData.flags.inputTask || tutorialData.flags.createProjectTask
            ? ''
            : 'show-overlay',
        )}
        {stateRef.current ||
        tutorialData.flags.inputTask ||
        tutorialData.flags.createProjectTask ? (
          <div className='task-input-border-con'></div>
        ) : null}
        {stateRef.current || tutorialData.flags.inputTask || tutorialData.flags.createProjectTask
          ? renderBorder()
          : null}
      </div>
      <div
        id='top-bar-right-con'
        className={`right-side ${!navigationData.hideLeftSidebar ? 'left-menu-open' : ''}`}
      >
        {renderSearchOption()}
        {renderAdminOption()}
        {renderNotificationOption()}
        <div className='profile-btn-con'>
          <span
            id='profile-icon-con'
            className='top-icon profile-icon'
            onClick={() => openProfileModal()}
            data-place='bottom'
            data-class='tool-tip'
            data-background-color='#3c3c5b'
            data-tooltip-id={`tool-tip-profile`}
            data-tip={`My Profile`}
          >
            <img id='profile-img' src={getProfileImage()} alt='user' />
          </span>
          <Tooltip className='tool-tip' id={`tool-tip-profile`} />
          {renderOverlay()}
        </div>
        {renderSettings()}
      </div>
    </div>
  );
}
