import { Fragment, useEffect } from 'preact/compat';
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SmallButton from 'components/UI/SmallButton';
import { ordinalSuffix } from 'utils/helpers';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { weekDays } from 'utils/task';

type RecurringTaskPropsType = {
  closeModal: () => void;
  isLoading?: boolean;
  recurrenceRepeatNumber: number;
  setRecurrenceRepeatNumber: (val: number) => void;
  recurrenceRepeatType: string;
  setRecurrenceRepeatType: (e: string) => void;
  recurrenceTime: string | null;
  setRecurrenceTime: (date: string) => void;
  recurrenceWeekDaysIndex: number[];
  weekDaySelected: (index: number) => void;
  finish: () => void;
};

export default function RecurringTask(props: RecurringTaskPropsType) {
  const {
    closeModal,
    isLoading,
    recurrenceRepeatNumber,
    setRecurrenceRepeatNumber,
    recurrenceRepeatType,
    setRecurrenceRepeatType,
    recurrenceTime,
    setRecurrenceTime,
    recurrenceWeekDaysIndex,
    weekDaySelected,
    finish,
  } = props;

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [closeModal]);

  const renderRecurrenceRepeatNumber = () => {
    let numbers = 365;

    const recurrenceType = recurrenceRepeatType.toLowerCase();

    if (recurrenceType === 'week') numbers = 52;
    else if (recurrenceType === 'month') numbers = 31;

    const items = [...Array(numbers)];
    return (
      <Menu
        menuButton={
          <MenuButton>
            <span className='menu-title'>
              {recurrenceRepeatType.toLowerCase() === 'month'
                ? ordinalSuffix(recurrenceRepeatNumber)
                : recurrenceRepeatNumber}
            </span>
            <FontAwesomeIcon className='drop-icon down' icon={faChevronDown} />
          </MenuButton>
        }
      >
        {items.map((e, i) => {
          return (
            <MenuItem value={i + 1} key={i} onClick={() => setRecurrenceRepeatNumber(i + 1)}>
              {recurrenceRepeatType.toLowerCase() === 'month' ? ordinalSuffix(i + 1) : i + 1}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderRecurrenceRepeatType = () => {
    const recurrenceTypes = ['Day', 'Week', 'Month'];
    return (
      <Menu
        menuButton={
          <MenuButton>
            <span className='menu-title'>{recurrenceRepeatType}</span>
            <FontAwesomeIcon className='drop-icon down' icon={faChevronDown} />
          </MenuButton>
        }
      >
        {recurrenceTypes.map((e, i) => {
          return (
            <MenuItem value={e} key={i} onClick={() => setRecurrenceRepeatType(e)}>
              {e}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderTime = () => {
    return (
      <div id={`time-in-con`} className='time-in-con'>
        <DatePicker
          id={`datepicker-recurring-time`}
          selected={recurrenceTime}
          className='calender-input'
          placeholderText={'Select Time'}
          calendarClassName='theme-calendar-con'
          onChange={(date) => setRecurrenceTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption='Time'
          dateFormat='hh:mm a'
          timeFormat='hh:mm a'
        />
        <span id={`input-icon-con-recurring`} className='input-icon-con'>
          <FontAwesomeIcon id={`calendar-icon-recurring`} icon={faChevronDown} />
        </span>
      </div>
    );
  };

  const isDaySelected = (index: number) => {
    const findIndex = recurrenceWeekDaysIndex.findIndex((dayIndex) => dayIndex === index);

    if (findIndex !== -1) return true;

    return false;
  };

  const renderWeekDays = () => {
    if (recurrenceRepeatType.toLowerCase() !== 'week') return;

    return (
      <div className='inner-con no-right-padding'>
        <div className='week-days-con'>
          <h5 className='in-title'>Choose Days</h5>
          <div className='week-days-in'>
            {weekDays.map((day, i) => {
              return (
                <span
                  key={i}
                  className={`${isDaySelected(i) ? 'selected' : ''}`}
                  onClick={() => weekDaySelected(i)}
                >
                  {day.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderMonthChooseDay = () => {
    if (recurrenceRepeatType.toLowerCase() !== 'month') return;

    return (
      <div className='inner-con'>
        <h5 className='in-title'>Choose Day</h5>
        {renderRecurrenceRepeatNumber()}
      </div>
    );
  };

  const renderDayAndWeekRepeatType = () => {
    if (recurrenceRepeatType.toLowerCase() === 'month') return;

    return (
      <div className='inner-con'>
        <h5 className='in-title'>Repeat Every</h5>
        {renderRecurrenceRepeatNumber()}
      </div>
    );
  };

  return (
    <Fragment>
      <div className={`recurring-task-popup-container`}>
        <div className='main-con'>
          <span onClick={closeModal} className='cross-icon'>
            <FontAwesomeIcon icon={faTimes} />
          </span>

          <h3 className='title'>Set Recurrence</h3>

          <div className='main-in-con'>
            {renderDayAndWeekRepeatType()}
            <div className='inner-con'>
              <h5 className='in-title'>
                {recurrenceRepeatType.toLowerCase() === 'month' ? 'Repeat Every' : null}
              </h5>
              {renderRecurrenceRepeatType()}
            </div>

            {renderMonthChooseDay()}

            {renderWeekDays()}

            <div className='inner-con'>
              <h5 className='in-title'>Time (Optional)</h5>
              {renderTime()}
            </div>

            <div id='recurring-finish-btn' className='btn-container'>
              <SmallButton clicked={() => finish()} disabled={isLoading} loading={isLoading}>
                Finish
              </SmallButton>
            </div>
          </div>
        </div>
      </div>
      <div className={`recurring-task-popup-bg`}></div>
    </Fragment>
  );
}
