import { isUserLoggedIn } from 'services/AuthService';
import appConfig from 'config/config';
import apiConfig from 'config/api';
import { Client } from '@stomp/stompjs';
import { log } from 'utils';

import {
  updateSubTaskSequence,
  updateTaskSequence,
  addSubTask,
  addTask,
  updateTaskName,
  updateTaskProject,
  updateTaskSize,
  updateTaskStatus,
  removeTask,
  updateTaskStartEndDates,
  addTaskCommentsData,
  addSubtaskCommentsData,
  addTaskTags,
  addAccountUserTag,
  updateTaskAssignee,
  updateUserCounters,
  updateCollapseTaskStatus,
  handleUserActivationStatus,
  deleteTaskCommentsData,
  deleteSubtaskCommentsData,
  updateTaskCommentsData,
  updateSubtaskCommentsData,
} from 'websocket/activities';
import { getCompletedTasks, getTaskData } from 'recoil/TaskState/update';
import { getAllUserTags } from 'recoil/AccountState/update';
import { setRecoil } from 'recoil-nexus';
import { userWebsocketStatusState } from 'recoil/UserState';
let userClient: Client | null = null;
let noDataFetch: boolean | undefined = false;
let userHeartbeatTimer: any = null;
const HEARTBEAT_INTERVAL = 15000;
let selectedUserId: string | null = null;
const stateType: StateType = 'INDIVIDUAL';

let isConnected = false;

export const open = (userId: string, fetchData?: boolean) => {
  // resetTaskData('FOCUS', stateType);
  // resetTaskData('BACKLOG', stateType);

  const { status, token } = isUserLoggedIn();
  if (!status || !token) return;

  selectedUserId = userId;
  noDataFetch = fetchData;
  const url = `${appConfig.webSocketUrl}`;

  userClient = new Client({
    brokerURL: url,
    connectHeaders: {
      'X-Authorization': token,
      userId: selectedUserId,
    },
    debug: function (str) {
      log('user debug', str);
      if (str.includes('did not receive server activity')) {
        isConnected = false;
      }
      if (str.includes('Web Socket Opened')) {
        isConnected = true;
      }
    },
    reconnectDelay: 2000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    connectionTimeout: 3000,
    discardWebsocketOnCommFailure: true,
  });

  userClient.discardWebsocketOnCommFailure = true;

  userClient.onConnect = function () {
    const { status } = isUserLoggedIn();

    if (!status) return;
    const channel = `/${apiConfig.websocket.user}/${selectedUserId}`;

    log('^^^^^^^ USER CHANNEL ^^^^^');
    log(userClient, channel, 'channel to connect');

    if (userClient) {
      userClient.subscribe(channel, onMessage);
      isConnected = true;
      setHeartBeat();

      if (!noDataFetch) {
        // Reset tasklist data
        getTaskData('FOCUS', true, selectedUserId ? selectedUserId : undefined, stateType);
        getTaskData('BACKLOG', true, selectedUserId ? selectedUserId : undefined, stateType);
        getCompletedTasks(undefined, true, selectedUserId ? selectedUserId : undefined, stateType);
        if (selectedUserId) getAllUserTags(selectedUserId, true);
      }
    }
  };

  userClient.onDisconnect = function () {
    setRecoil(userWebsocketStatusState, 'DISCONNECTED');
  };

  userClient.onStompError = function (frame) {
    log('Broker reported error: ' + frame.headers['message']);
    log('Additional details: ' + frame.body);

    setRecoil(userWebsocketStatusState, 'DISCONNECTED');
  };

  userClient.activate();
};

export const close = async () => {
  log('close connection user channel');
  setRecoil(userWebsocketStatusState, 'DISCONNECTED');

  clearTimeout(userHeartbeatTimer);

  if (userClient) {
    log('close connection success');
    await userClient.deactivate();
  }
};

const setHeartBeat = () => {
  setRecoil(userWebsocketStatusState, 'CONNECTED');

  clearTimeout(userHeartbeatTimer);
  userHeartbeatTimer = setTimeout(() => {
    if (isConnected) {
      setHeartBeat();
    } else {
      resetConnection(selectedUserId);
    }
  }, HEARTBEAT_INTERVAL);
};

export const resetConnection = async (userId) => {
  selectedUserId = userId;

  clearTimeout(userHeartbeatTimer);
  setRecoil(userWebsocketStatusState, 'DISCONNECTED');
  if (userClient) await userClient.deactivate();

  open(userId, noDataFetch);
};

const onMessage = (message) => {
  const body = message.body ? JSON.parse(message.body) : undefined;

  log('user channel event', body?.event);

  if (body?.event !== 'NOTIFICATION') {
    log('user channel body', body);
  }

  if (body && body.event) handleEvent(body);
};

const handleEvent = (body) => {
  log('----- User ------');
  log('------ User -----');
  log(body);
  log('------ X -----');
  log('------ X -----');

  switch (body.event) {
    case 'TASK_UPDATE_SEQUENCE':
      body.parenttaskid
        ? updateSubTaskSequence(
            body.taskid ? body.taskid : null,
            body.sequence ? parseFloat(body.sequence) : 0,
            body.taskType ? body.taskType : 'BACKLOG',
            body.parenttaskid ? body.parenttaskid : null,
            stateType,
          )
        : updateTaskSequence(
            body.taskid ? body.taskid : null,
            body.sequence ? parseFloat(body.sequence) : 0,
            body.taskType ? body.taskType : 'BACKLOG',
            stateType,
          );
      break;
    case 'TASK_ADD':
      body.parenttaskid
        ? addSubTask(
            body.taskid ? body.taskid : null,
            body.parenttaskid ? body.parenttaskid : null,
            body.task ? body.task : {},
            body.tempid ? body.tempid : null,
            stateType,
          )
        : addTask(body.task ? body.task : {}, stateType);
      break;
    case 'TASK_UPDATE': {
      let bodyTaskName = body.task && body.task.name ? body.task.name : '';
      const searchRegExp = /:c/gi;
      const replaceWith = '';
      if (bodyTaskName !== '') {
        bodyTaskName = bodyTaskName.replace(searchRegExp, replaceWith);
      }

      updateTaskName(
        body.task && body.task.id ? body.task.id : null,
        body.tempid ? body.tempid : null,
        bodyTaskName,
        body.task && body.task.taskType ? body.task.taskType : 'BACKLOG',
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    }
    case 'TASK_UPDATE_PROJECT':
      updateTaskProject(
        body.taskid ? body.taskid : null,
        body.projectid ? body.projectid : null,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
        body.TASK_NUMBER ? body.TASK_NUMBER : null,
      );
      break;
    case 'TASK_UPDATE_SIZE':
      updateTaskSize(
        body.taskid ? body.taskid : null,
        body.sizeid ? body.sizeid : 'M',
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TASK_UPDATE_DATE':
      updateTaskStartEndDates(
        body.taskid ? body.taskid : null,
        body.startdate,
        body.enddate,
        body.duedate,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TASK_UPDATE_STATUS':
      updateTaskStatus(
        body.taskid ? body.taskid : null,
        body.status && body.status === 'COMPLETED' ? true : false,
        body.parenttaskid ? body.parenttaskid : null,
        body.task,
        stateType,
      );
      break;
    case 'TASK_UPDATE_ASSIGNEE':
      updateTaskAssignee(
        body.taskid ? body.taskid : null,
        body.assigneeid ? body.assigneeid : null,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TASK_DELETE':
      removeTask(
        body.taskid ? body.taskid : null,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'TASK_COUNT':
      updateUserCounters(
        body.userid ? body.userid : undefined,
        body.todocounts ? body.todocounts : 0,
        body.recentCompletedCount ? body.recentCompletedCount : 0,
        body.focusPoints ? body.focusPoints : 0,
        body.backlogPoints ? body.backlogPoints : 0,
      );
      break;
    case 'TASK_COLLAPSED':
      updateCollapseTaskStatus(
        body.taskid ? body.taskid : null,
        body.collapsedSubTasks,
        body.parenttaskid ? body.parenttaskid : null,
        stateType,
      );
      break;
    case 'COMMENT_ADDED':
      body.parenttaskid
        ? addSubtaskCommentsData(
            body.parenttaskid ? body.parenttaskid : null,
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
            stateType,
          )
        : addTaskCommentsData(
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
            stateType,
          );
      break;
    case 'COMMENT_UPDATED':
      body.parenttaskid
        ? updateSubtaskCommentsData(
            body.parenttaskid ? body.parenttaskid : null,
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
            stateType,
            body.PROJECTTASKTYPE ? body.PROJECTTASKTYPE : 'BACKLOG',
          )
        : updateTaskCommentsData(
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
            stateType,
          );
      break;
    case 'COMMENT_DELETED':
      body.parenttaskid
        ? deleteSubtaskCommentsData(
            body.parenttaskid ? body.parenttaskid : null,
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
            stateType,
          )
        : deleteTaskCommentsData(
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
            stateType,
          );
      break;
    case 'TAG_ASSIGNED':
      addTaskTags(
        body.task && body.task.id ? body.task.id : undefined,
        body?.task?.tags ? body?.task?.tags : [],
        body?.task?.tagInfo ? body?.task?.tagInfo : [],
        true,
        body.parenttaskid ? body.parenttaskid : null,
        body?.tag ? body?.tag : undefined,
        stateType,
        body.task?.taskType ? body?.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body?.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'TAG_UNASSIGNED':
      addTaskTags(
        body.task && body.task.id ? body.task.id : undefined,
        body?.task?.tags ? body?.task?.tags : [],
        body?.task?.tagInfo ? body?.task?.tagInfo : [],
        false,
        body.parenttaskid ? body.parenttaskid : null,
        body?.tag ? body?.tag : undefined,
        stateType,
        body.task?.taskType ? body?.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body?.task?.projectTaskType : 'BACKLOG',
      );
      break;

    case 'TAG_ADDED':
      addAccountUserTag(
        body.tag ? body.tag : undefined,
        true,
        body?.tag?.project ? 'allProjectTags' : 'allUserTags',
        body?.projectId ? body.projectId : null,
        stateType,
        body.task?.taskType ? body.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'TAG_REMOVED':
      addAccountUserTag(
        body.tag ? body.tag : undefined,
        false,
        body?.tag?.project ? 'allProjectTags' : 'allUserTags',
        null,
        stateType,
        body.task?.taskType ? body.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'USER_ACTIVATION_STATUS_CHANGED':
      handleUserActivationStatus(
        body.ActivationStatus,
        body.ForceLogOutMessage,
        body.workspaceId,
        body.user ? body.user : null,
        body.userId ? body.userId : null,
      );
      break;

    default:
      break;
  }
};
