import StartDateIcon from '../../assets/images/payment/start-date-icon.svg';
import EndDateIcon from '../../assets/images/payment/end-date-icon.svg';
import InvoiceIcon from '../../assets/images/payment/invoice-icon.svg';
import CardIcon from '../../assets/images/payment/card-detail-icon.svg';
import SmallButton from 'components/UI/SmallButton';

import { formatTimestampToDate } from 'utils/task';
import { Strings } from 'resources';

const SubscriptionDetails = (
  props: SubscriptionDataType & {
    subscribe: () => void;
  },
) => {
  return (
    <div className='subscription-details-con'>
      <div className='subscription-details'>
        <img src={StartDateIcon} alt='icon' className='sd-icon' />
        <p className='sd-title'>{Strings.subscription_start_date}</p>
        <p className='sd-content'>{formatTimestampToDate(props.startDate)}</p>
      </div>
      <div className='subscription-details'>
        <img src={EndDateIcon} alt='icon' className='sd-icon' />
        <p className='sd-title'>{Strings.subscription_end_date}</p>
        <p className='sd-content'>{formatTimestampToDate(props.endDate)}</p>
      </div>
      <div className='subscription-details'>
        <img src={InvoiceIcon} alt='icon' className='sd-icon' />
        <p className='sd-title'>{Strings.subscription_invoice_date}</p>
        <p className='sd-content'>{formatTimestampToDate(props.nextInvoiceDate)}</p>
      </div>
      <div className='subscription-details'>
        <img src={CardIcon} alt='icon' className='sd-icon' />
        <p className='sd-content'>{`**** ${props.cardLast4 || ''}`}</p>
        <SmallButton btnType='sd-link' clicked={() => props.subscribe()}>
          {Strings.add_new_card}
        </SmallButton>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
