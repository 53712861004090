import { useState, useEffect } from 'preact/compat';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ProgressIndicator from 'animations/ProgressBar';
import { navigationDataSelector, showThemesPopupModalState } from 'recoil/NavigationState';
import { accountDataSelector } from 'recoil/AccountState';
import { triggerDataSelector } from 'recoil/TriggerState';
import { playModalCloseSound, playModalOpenSound } from 'triggers/utils/sound';
import { Strings } from 'resources';

type ProgressBarPropsType = {
  themeName: string;
  level: number;
  progress: number;
};

export default function ProgressBar(props: ProgressBarPropsType) {
  const [shouldPlay, setShouldPlay] = useState(false);
  const navigationData = useRecoilValue(navigationDataSelector);
  const setThemePopupModalStatus = useSetRecoilState(showThemesPopupModalState);
  const accountData = useRecoilValue(accountDataSelector);
  const triggerData = useRecoilValue(triggerDataSelector);

  const { themeName, level, progress } = props;

  const showThemes = () => {
    setThemePopupModalStatus(!navigationData.showThemesPopupModal);
  };

  useEffect(() => {
    if (navigationData.showThemesPopupModal) {
      playModalOpenSound();
    } else {
      playModalCloseSound();
    }
  }, [navigationData.showThemesPopupModal]);

  useEffect(() => {
    if (
      triggerData?.riveTriggers?.bgAnimationLow &&
      accountData?.accountSettings?.userAnimationSettings?.bgAnimationLow
    ) {
      setShouldPlay(true);
    } else {
      setShouldPlay(false);
    }
  }, [
    triggerData?.riveTriggers?.bgAnimationLow,
    accountData?.accountSettings?.userAnimationSettings?.bgAnimationLow,
  ]);

  return (
    <div
      className={`progress-bar-btn-container ${
        navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
      } ${themeName === 'Futuristic City 2' ? 'progress-rive-con' : ''}`}
    >
      <div
        className={themeName === 'Futuristic City 2' ? 'progress-rive' : 'progress-bar-btn'}
        onClick={showThemes}
      >
        <span className='border-btn'></span>
        {themeName === 'Futuristic City 2' ? (
          <ProgressIndicator
            className='progress-indicator'
            fileName='progressbar'
            move={progress}
            shouldAnimate={shouldPlay}
          />
        ) : (
          <div className='progress-indicator' style={{ width: `calc(${progress}% - 4px)` }}></div>
        )}

        <div className='left-con'>
          <div className='theme-name-con'>
            <span className='theme-name'>{themeName}</span>
          </div>
        </div>
        <div className='right-con'>
          <div className='level-name-con'>
            <span className='current-level'>
              {themeName.toLowerCase().includes('clean') ? '' : `${Strings.level} ${level}`}
            </span>
          </div>
          <div className='drop-down-arrow'></div>
        </div>
      </div>
    </div>
  );
}
