import { memo, useState } from 'preact/compat';

type CollapseIconPropsType = {
  tempId: string;
  hasSubtasks: boolean | undefined;
  updateTaskCollapse: (status: boolean) => void;
  collapsedSubTasks: boolean | undefined;
};
function CollapseIcon(props: CollapseIconPropsType) {
  const { tempId, hasSubtasks, updateTaskCollapse, collapsedSubTasks } = props;
  const [isExpand, setExapnd] = useState(collapsedSubTasks);

  // log('render components/TaskItems/IndividualItem/CollapseIcon');

  const className = !hasSubtasks
    ? `no-icon`
    : isExpand
      ? 'collapse-btn expand-btn'
      : `collapse-btn`;
  return (
    <div className='collapse-container' key={`collapse-con-${tempId}`}>
      <button
        id={`collapse-btn`}
        className={className}
        onClick={() => {
          updateTaskCollapse(!collapsedSubTasks);
          setExapnd(!collapsedSubTasks);
        }}
      />
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: CollapseIconPropsType, nextProps: CollapseIconPropsType) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.hasSubtasks !== nextProps.hasSubtasks) return false;

  if (prevProps.collapsedSubTasks !== nextProps.collapsedSubTasks) return false;

  if (prevProps.updateTaskCollapse !== nextProps.updateTaskCollapse) return false;

  return true;
}

export default memo(CollapseIcon, areEqual);
