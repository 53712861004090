import { atom, DefaultValue, selector } from 'recoil';

export const browserSoundEnabledState = atom<boolean>({
  key: 'browserSoundEnabled',
  default: false,
});

export const lottieTriggersState = atom<LottieTriggersType>({
  key: 'lottieTriggers',
  default: {
    completeTask: null,
  },
});

export const playRiveTriggerState = atom<PlayRiveAnimationType>({
  key: 'playRiveAnimation',
  default: {
    reOrderTaskAnimation: false,
    createTaskAnimation: false,
    completeTaskAnimation: false,
  },
});

export const completeTaskRiveTriggerState = atom<string | null>({
  key: 'completeTaskRiveTrigger',
  default: null,
});

export const createTaskRiveTriggerState = atom<string | null>({
  key: 'createTaskRiveTrigger',
  default: null,
});

export const reOrderTaskRiveTriggerState = atom<string | null>({
  key: 'reOrderTaskRiveTrigger',
  default: null,
});

export const bgAnimationLowRiveTriggerState = atom<string | null>({
  key: 'bgAnimationLowRiveTrigger',
  default: null,
});

export const bgAnimationMediumRiveTriggerState = atom<string | null>({
  key: 'bgAnimationMediumRiveTrigger',
  default: null,
});

export const bgAnimationHighRiveTriggerState = atom<string | null>({
  key: 'bgAnimationHighRiveTrigger',
  default: null,
});

export const riveTriggersState = selector<RiveTriggersType>({
  key: 'riveTriggers',
  get: ({ get }) => {
    const completeTask = get(completeTaskRiveTriggerState);
    const createTask = get(createTaskRiveTriggerState);
    const reOrderTask = get(reOrderTaskRiveTriggerState);
    const bgAnimationLow = get(bgAnimationLowRiveTriggerState);
    const bgAnimationMedium = get(bgAnimationMediumRiveTriggerState);
    const bgAnimationHigh = get(bgAnimationHighRiveTriggerState);
    return {
      completeTask,
      createTask,
      reOrderTask,
      bgAnimationLow,
      bgAnimationMedium,
      bgAnimationHigh,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(completeTaskRiveTriggerState, value);
      set(createTaskRiveTriggerState, value);
      set(reOrderTaskRiveTriggerState, value);
      set(bgAnimationLowRiveTriggerState, value);
      set(bgAnimationMediumRiveTriggerState, value);
      set(bgAnimationHighRiveTriggerState, value);
      return;
    } else {
      set(completeTaskRiveTriggerState, value.completeTask);
      set(createTaskRiveTriggerState, value.createTask);
      set(reOrderTaskRiveTriggerState, value.reOrderTask);
      set(bgAnimationLowRiveTriggerState, value.bgAnimationLow);
      set(bgAnimationMediumRiveTriggerState, value.bgAnimationLow);
      set(bgAnimationHighRiveTriggerState, value.bgAnimationLow);
    }
  },
});

export const completeTaskCanvasTriggerState = atom<string | null>({
  key: 'completeTaskCanvasTrigger',
  default: null,
});

export const createTaskCanvasTriggerState = atom<string | null>({
  key: 'createTaskCanvasTrigger',
  default: null,
});

export const reOrderTaskCanvasTriggerState = atom<string | null>({
  key: 'reOrderTaskCanvasTrigger',
  default: null,
});

export const canvasTriggersState = selector<CanvasTriggersType>({
  key: 'canvasTriggers',
  get: ({ get }) => {
    const completeTask = get(completeTaskCanvasTriggerState);
    const createTask = get(createTaskCanvasTriggerState);
    const reOrderTask = get(reOrderTaskCanvasTriggerState);
    return { completeTask, createTask, reOrderTask };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(completeTaskCanvasTriggerState, value);
      set(createTaskCanvasTriggerState, value);
      set(reOrderTaskCanvasTriggerState, value);
      return;
    } else {
      set(completeTaskCanvasTriggerState, value.completeTask);
      set(createTaskCanvasTriggerState, value.createTask);
      set(reOrderTaskCanvasTriggerState, value.reOrderTask);
    }
  },
});

export const createTaskDelayState = atom<number>({
  key: 'createTaskDelay',
  default: 0,
});

export const createSubtaskDelayState = atom<number>({
  key: 'createSubtaskDelay',
  default: 0,
});

export const completTaskDelayState = atom<number>({
  key: 'completeTaskDelay',
  default: 300,
});

export const lottieCompleteTaskDelayState = atom<number>({
  key: 'lottieCompleteTaskDelay',
  default: 0,
});

export const riveCompleteTaskDelayState = atom<number>({
  key: 'riveCompleteTaskDelay',
  default: 0,
});

export const riveCreateTaskDelayState = atom<number>({
  key: 'riveCreateTaskDelay',
  default: 0,
});

export const allTriggersState = atom<TriggerObjectType[]>({
  key: 'allTriggers',
  default: [],
});

export const bgSoundTriggerState = atom<string | null>({
  key: 'bgSoundTrigger',
  default: null,
});

export const completeTaskSoundTriggerState = atom<string | null>({
  key: 'completeTaskSoundTrigger',
  default: null,
});

export const createTaskSoundTriggerState = atom<string | null>({
  key: 'createTaskSoundTrigger',
  default: null,
});

export const reOrderTaskSoundTriggerState = atom<string | null>({
  key: 'reOrderTaskSoundTrigger',
  default: null,
});

export const createSubTaskSoundTriggerState = atom<string | null>({
  key: 'createSubTaskSoundTrigger',
  default: null,
});

export const switchScreenSoundTriggerState = atom<string | null>({
  key: 'switchScreenSoundTrigger',
  default: null,
});

export const modalOpenSoundTriggerState = atom<string | null>({
  key: 'modalOpenSoundTrigger',
  default: null,
});

export const modalCloseSoundTriggerState = atom<string | null>({
  key: 'modalCloseSoundTrigger',
  default: null,
});

export const soundTriggersState = selector<SoundTriggersObjectType>({
  key: 'soundTriggers',
  get: ({ get }) => {
    const bgSound = get(bgSoundTriggerState);
    const completeTask = get(completeTaskSoundTriggerState);
    const createTask = get(createTaskSoundTriggerState);
    const reOrderTask = get(reOrderTaskSoundTriggerState);
    const createSubTask = get(createSubTaskSoundTriggerState);
    const switchScreen = get(switchScreenSoundTriggerState);
    const modalOpen = get(modalOpenSoundTriggerState);
    const modalClose = get(modalCloseSoundTriggerState);
    return {
      bgSound,
      completeTask,
      createTask,
      reOrderTask,
      createSubTask,
      switchScreen,
      modalOpen,
      modalClose,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(bgSoundTriggerState, value);
      set(completeTaskSoundTriggerState, value);
      set(createTaskSoundTriggerState, value);
      set(reOrderTaskSoundTriggerState, value);
      set(createSubTaskSoundTriggerState, value);
      set(switchScreenSoundTriggerState, value);
      set(modalOpenSoundTriggerState, value);
      set(modalCloseSoundTriggerState, value);
      return;
    } else {
      set(bgSoundTriggerState, value.bgSound);
      set(completeTaskSoundTriggerState, value.completeTask);
      set(createTaskSoundTriggerState, value.createTask);
      set(reOrderTaskSoundTriggerState, value.reOrderTask);
      set(createSubTaskSoundTriggerState, value.createSubTask);
      set(switchScreenSoundTriggerState, value.switchScreen);
      set(modalOpenSoundTriggerState, value.modalOpen);
      set(modalCloseSoundTriggerState, value.modalClose);
    }
  },
});

export const triggerDataSelector = selector<TriggerDataSelectorType>({
  key: 'triggerData',
  get: ({ get }) => {
    const browserSoundEnabled = get(browserSoundEnabledState);
    const soundTriggers = get(soundTriggersState);
    const canvasTriggers = get(canvasTriggersState);
    const lottieTriggers = get(lottieTriggersState);
    const riveTriggers = get(riveTriggersState);
    const createTaskDelay = get(createTaskDelayState);
    const createSubtaskDelay = get(createSubtaskDelayState);
    const completeTaskDelay = get(completTaskDelayState);
    const lottieCompleteTaskDelay = get(lottieCompleteTaskDelayState);
    const riveCompleteTaskDelay = get(riveCompleteTaskDelayState);
    const riveCreateTaskDelay = get(riveCreateTaskDelayState);
    const allTriggers = get(allTriggersState);
    return {
      soundTriggers,
      canvasTriggers,
      lottieTriggers,
      riveTriggers,
      createTaskDelay,
      createSubtaskDelay,
      browserSoundEnabled,
      completeTaskDelay,
      lottieCompleteTaskDelay,
      riveCompleteTaskDelay,
      riveCreateTaskDelay,
      allTriggers,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(browserSoundEnabledState, value);
      set(soundTriggersState, value);
      set(lottieTriggersState, value);
      set(riveTriggersState, value);
      set(canvasTriggersState, value);
      set(createTaskDelayState, value);
      set(createSubtaskDelayState, value);
      set(completTaskDelayState, value);
      set(lottieCompleteTaskDelayState, value);
      set(riveCompleteTaskDelayState, value);
      set(riveCreateTaskDelayState, value);
      set(allTriggersState, value);
      return;
    } else {
      set(browserSoundEnabledState, value.browserSoundEnabled);
      set(soundTriggersState, value.soundTriggers);
      set(lottieTriggersState, value.lottieTriggers);
      set(riveTriggersState, value.riveTriggers);
      set(canvasTriggersState, value.canvasTriggers);
      set(createTaskDelayState, value.createTaskDelay);
      set(createSubtaskDelayState, value.createSubtaskDelay);
      set(completTaskDelayState, value.completeTaskDelay);
      set(lottieCompleteTaskDelayState, value.lottieCompleteTaskDelay);
      set(riveCreateTaskDelayState, value.riveCreateTaskDelay);
      set(allTriggersState, value.allTriggers);
    }
  },
});
