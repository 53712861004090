import { useNavigate } from 'react-router-dom';

import Button from 'components/UI/Button';
import { Strings } from 'resources';

export default function BetaPlan(_props) {
  const history = useNavigate();

  const routeChange = () => {
    history('/create-account');
  };

  return (
    <div className='beta-screen-container'>
      <div className='beta-container'>
        <div className='magic-task-logo' alt='Logo' />
        <div className='beta-plan-info'>
          <h1 className='beta-header'>{Strings.join_now}</h1>
          <p className='beta-subheader'>
            {Strings.early_access_message}
            <span className='beta-red'>{Strings.free}</span>
          </p>
          <div className='btn'>
            <Button idName='beta-screen-get-started-btn' clicked={routeChange}>
              {' '}
              {Strings.get_started}{' '}
            </Button>
          </div>
        </div>
      </div>
      <div className='beta-img-con'></div>
    </div>
  );
}
