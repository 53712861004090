import { DefaultValue, atom, selector } from 'recoil';

export const userAnalyticsCompletedFilterSelector = atom<UserAnalyticsCompletedFilterType>({
  key: 'userAnalyticsCompletedFilter',
  default: {
    assignee: [],
    filterType: 'task',
    project: [],
    modified: { value: 'allTime' },
    workspace: [],
  },
});

export const userAnalyticsMonthlyCompletedFilterSelector =
  atom<UserAnalyticsMonthlyCompletedFilterType>({
    key: 'userAnalyticsMonthlyCompletedFilter',
    default: {
      assignee: [],
      filterType: 'task',
      project: [],
      modified: { value: 'last12Months' },
      workspace: [],
    },
  });

export const userAnalyticsFilterSelector = selector<UserAnalyticsFilterType>({
  key: 'userAnalyticsFilter',
  get: ({ get }) => {
    const completedFilter = get(userAnalyticsCompletedFilterSelector);
    const monthlyCompletedFilter = get(userAnalyticsMonthlyCompletedFilterSelector);
    return { completedFilter, monthlyCompletedFilter };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(userAnalyticsCompletedFilterSelector, value);
      set(userAnalyticsMonthlyCompletedFilterSelector, value);
      return;
    }
    set(userAnalyticsCompletedFilterSelector, value.completedFilter);
    set(userAnalyticsMonthlyCompletedFilterSelector, value.monthlyCompletedFilter);
  },
});
