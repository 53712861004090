import { atom, DefaultValue, selector } from 'recoil';

export const tutorialTaskFiltersState = atom<TaskFiltersObjectType>({
  key: 'tutorialTaskFilters',
  default: {
    sizeFilter: null,
    tagFilter: null,
    assigneeFilter: null,
    dateFilter: null,
    projectFilter: null,
    searchTextFilter: '',
  },
});
export const tutorialCompletedTaskDataState = atom<TaskObjectType[]>({
  key: 'tutorialCompletedTaskData',
  default: [],
});
export const tutorialCompletedTaskHasMoreState = atom<boolean>({
  key: 'tutorialCompletedTaskHasMore',
  default: true,
});
export const tutorialCompletedTaskPageState = atom<number>({
  key: 'tutorialCompletedTaskPage',
  default: 0,
});
export const tutorialCompletedTaskLimitState = atom<number>({
  key: 'tutorialCompletedTaskLimit',
  default: 10,
});
export const tutorialCompletedTaskPointsState = atom<number>({
  key: 'tutorialCompletedTaskPoints',
  default: 0,
});
export const tutorialCompletedTaskSizeState = atom<number | null>({
  key: 'tutorialCompletedTaskSize',
  default: null,
});
export const tutorialCompletedTaskLoadingState = atom<boolean>({
  key: 'tutorialCompletedTaskLoading',
  default: false,
});
export const tutorialCompletedTaskFilterState = atom<string>({
  key: 'tutorialCompletedTaskFilter',
  default: 'today',
});
export const tutorialCompletedTaskAbortControllerState = atom<AbortController>({
  key: 'tutorialCompletedTaskAbortController',
  default: new AbortController(),
});
export const tutorialCompletedTasksState = selector<PaginatedTasksType>({
  key: 'tutorialCompletedTasks',
  get: ({ get }) => {
    const data = get(tutorialCompletedTaskDataState);
    const hasMore = get(tutorialCompletedTaskHasMoreState);
    const page = get(tutorialCompletedTaskPageState);
    const limit = get(tutorialCompletedTaskLimitState);
    const points = get(tutorialCompletedTaskPointsState);
    const size = get(tutorialCompletedTaskSizeState);
    const isLoading = get(tutorialCompletedTaskLoadingState);
    const filter = get(tutorialCompletedTaskFilterState);
    const abortController = get(tutorialCompletedTaskAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      points,
      size,
      isLoading,
      filter,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(tutorialCompletedTaskDataState, value);
      set(tutorialCompletedTaskHasMoreState, value);
      set(tutorialCompletedTaskPageState, value);
      set(tutorialCompletedTaskLimitState, value);
      set(tutorialCompletedTaskPointsState, value);
      set(tutorialCompletedTaskSizeState, value);
      set(tutorialCompletedTaskLoadingState, value);
      set(tutorialCompletedTaskFilterState, value);
      set(tutorialCompletedTaskAbortControllerState, value);
      return;
    }

    set(tutorialCompletedTaskDataState, value.data);
    set(tutorialCompletedTaskHasMoreState, value.hasMore);
    set(tutorialCompletedTaskPageState, value.page);
    set(tutorialCompletedTaskLimitState, value.limit);
    set(tutorialCompletedTaskPointsState, value.points);
    set(tutorialCompletedTaskSizeState, value.size);
    set(tutorialCompletedTaskLoadingState, value.isLoading);
    set(tutorialCompletedTaskFilterState, value.filter);
    set(tutorialCompletedTaskAbortControllerState, value.abortController);
  },
});

export const tutorialAllUserTagsState = atom<TagObjectType[]>({
  key: 'tutorialAllUserTags',
  default: [],
});

export const tutorialTaskUnfilteredListState = atom<TaskTagListType>({
  key: 'tutorialTaskUnfilteredList',
  default: {
    FOCUS: [],
    BACKLOG: [],
    COMPLETED: [],
  },
});

export const tutorialCurrentHighlightedTask = atom<TaskObjectType | null>({
  key: 'currentHighlightedTaskInTutorial',
  default: null,
});

export const tutorialWebsocketStatusState = atom<WebsocketStatusType>({
  key: 'tutorialWebsocketStatus',
  default: 'INIT',
});

export const tutorialFocusTaskDataState = atom<TaskObjectType[] | null>({
  key: 'tutorialFocusData',
  default: [],
});

export const tutorialFocusTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'tutorialFocusFilteredData',
  default: [],
});

export const tutorialFocusTaskLoadingState = atom<boolean>({
  key: 'tutorialFocusLoading',
  default: false,
});

export const tutorialFocusTaskAbortState = atom<AbortController>({
  key: 'tutorialFocusAbortController',
  default: new AbortController(),
});

export const tutorialFocusTaskSizeState = atom<number>({
  key: 'tutorialFocusTaskSize',
  default: 0,
});

export const tutorialFocusTasksState = selector<TaskListType>({
  key: 'tutorialFocus',
  get: ({ get }) => {
    const data = get(tutorialFocusTaskDataState);
    const filteredData = get(tutorialFocusTaskFilteredDataState);
    const isLoading = get(tutorialFocusTaskLoadingState);
    const abortController = get(tutorialFocusTaskAbortState);
    const size = get(tutorialFocusTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(tutorialFocusTaskDataState, value);
      set(tutorialFocusTaskFilteredDataState, value);
      set(tutorialFocusTaskLoadingState, value);
      set(tutorialFocusTaskAbortState, value);
      set(tutorialFocusTaskSizeState, value);
      return;
    }
    set(tutorialFocusTaskDataState, value.data);
    set(tutorialFocusTaskFilteredDataState, value.filteredData);
    set(tutorialFocusTaskLoadingState, value.isLoading);
    set(tutorialFocusTaskAbortState, value.abortController);
    set(tutorialFocusTaskSizeState, value.size);
  },
});

export const tutorialBacklogTaskDataState = atom<TaskObjectType[] | null>({
  key: 'tutorialBacklogData',
  default: [],
});

export const tutorialBacklogTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'tutorialBacklogFilteredData',
  default: [],
});

export const tutorialBacklogTaskLoadingState = atom<boolean>({
  key: 'tutorialBacklogLoading',
  default: false,
});

export const tutorialBacklogTaskAbortState = atom<AbortController>({
  key: 'tutorialBacklogAbortController',
  default: new AbortController(),
});

export const tutorialBacklogTaskSizeState = atom<number>({
  key: 'tutorialBacklogTaskSize',
  default: 0,
});

export const tutorialBacklogTaskState = selector<TaskListType>({
  key: 'tutorialBacklog',
  get: ({ get }) => {
    const data = get(tutorialBacklogTaskDataState);
    const filteredData = get(tutorialBacklogTaskFilteredDataState);
    const isLoading = get(tutorialBacklogTaskLoadingState);
    const abortController = get(tutorialBacklogTaskAbortState);
    const size = get(tutorialBacklogTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(tutorialBacklogTaskDataState, value);
      set(tutorialBacklogTaskFilteredDataState, value);
      set(tutorialBacklogTaskLoadingState, value);
      set(tutorialBacklogTaskAbortState, value);
      set(tutorialBacklogTaskSizeState, value);
      return;
    }
    set(tutorialBacklogTaskDataState, value.data);
    set(tutorialBacklogTaskFilteredDataState, value.filteredData);
    set(tutorialBacklogTaskLoadingState, value.isLoading);
    set(tutorialBacklogTaskAbortState, value.abortController);
    set(tutorialBacklogTaskSizeState, value.size);
  },
});

export const allTutorialUsersState = atom<UserFieldType[]>({
  key: 'allTutorialUsers',
  default: [],
});

export const tutorialSelectedFocusTaskState = atom<SelectedTaskIdsType[]>({
  key: 'tutorialSelectedFocusTask',
  default: [],
});

export const tutorialSelectedBacklogTaskState = atom<SelectedTaskIdsType[]>({
  key: 'tutorialSelectedBacklogTask',
  default: [],
});

export const tutorialSelectedCompletedTaskState = atom<SelectedTaskIdsType[]>({
  key: 'tutorialSelectedCompletedTask',
  default: [],
});

export const tutorialSelectedTempIdsTaskState = atom<SelectedTaskIdsType[]>({
  key: 'tutorialSelectedTempIdsTask',
  default: [],
});

export const tutorialSelectedTaskTypeState = atom<TaskType | null>({
  key: 'tutorialSelectedTaskType',
  default: null,
});

export const tutorialSelectedTaskDragIdState = atom<string | null>({
  key: 'tutorialSelectedTaskDragId',
  default: null,
});

export const tutorialSelectedTasksState = selector<SelectedTasksType>({
  key: 'tutorialSelectedTasks',
  get: ({ get }) => {
    const FOCUS = get(tutorialSelectedFocusTaskState);
    const BACKLOG = get(tutorialSelectedBacklogTaskState);
    const COMPLETED = get(tutorialSelectedCompletedTaskState);
    const tempIds = get(tutorialSelectedTempIdsTaskState);
    const lastSelectedTaskType = get(tutorialSelectedTaskTypeState);
    const draggingTaskId = get(tutorialSelectedTaskDragIdState);
    return {
      FOCUS,
      BACKLOG,
      COMPLETED,
      tempIds,
      lastSelectedTaskType,
      draggingTaskId,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(tutorialSelectedFocusTaskState, value);
      set(tutorialSelectedBacklogTaskState, value);
      set(tutorialSelectedCompletedTaskState, value);
      set(tutorialSelectedTempIdsTaskState, value);
      set(tutorialSelectedTaskTypeState, value);
      set(tutorialSelectedTaskDragIdState, value);
      return;
    }
    set(tutorialSelectedFocusTaskState, value.FOCUS);
    set(tutorialSelectedBacklogTaskState, value.BACKLOG);
    set(tutorialSelectedCompletedTaskState, value.COMPLETED);
    set(tutorialSelectedTempIdsTaskState, value.tempIds);
    set(tutorialSelectedTaskTypeState, value.lastSelectedTaskType);
    set(tutorialSelectedTaskDragIdState, value.draggingTaskId);
  },
});

export const tutorialFlagsState = atom<TutorialFlagsType>({
  key: 'tutorialFlags',
  default: {},
});

export const tutorialAutoFocusState = atom<string | null>({
  key: 'tutorialAutoFocus',
  default: null,
});

export const tutorialTaskFetchErrorState = atom<string | null>({
  key: 'tutorialTaskFetchError',
  default: null,
});

export const allTutorialProjectsState = atom<ProjectObjectType[]>({
  key: 'allTutorialProjects',
  default: [],
});

export const tutorialFoldersState = atom<FolderType[]>({
  key: 'tutorialFolders',
  default: [],
});

export const tutorialCurrentProjectIdState = atom<string | null>({
  key: 'tutorialCurrentProjectId',
  default: null,
});

export const hideTutorialState = atom<boolean>({
  key: 'hideTutorial',
  default: false,
});

export const tutorialSortByProjectState = atom<SortByType>({
  key: 'tutorialSortByProject',
  default: 'display',
});

export const tutorialSortOrderProjectState = atom<SortOrderType>({
  key: 'tutorialSortOrderProject',
  default: 'asc',
});

export const selectedTutorialNotificationTaskIdState = atom<string | null>({
  key: 'selectedTutorialNotificationTaskId',
  default: null,
});

export const tutorialDataSelector = selector<TutorialDataType>({
  key: 'tutorialData',
  get: ({ get }) => {
    const taskFilters = get(tutorialTaskFiltersState);
    const COMPLETED = get(tutorialCompletedTasksState);
    const unFilteredList = get(tutorialTaskUnfilteredListState);
    const currentHighlightedTask = get(tutorialCurrentHighlightedTask);
    const websocketStatus = get(tutorialWebsocketStatusState);
    const FOCUS = get(tutorialFocusTasksState);
    const BACKLOG = get(tutorialBacklogTaskState);
    const allUsers = get(allTutorialUsersState);
    const selectedTasks = get(tutorialSelectedTasksState);
    const flags = get(tutorialFlagsState);
    const autoFocus = get(tutorialAutoFocusState);
    const error = get(tutorialTaskFetchErrorState);
    const allProjects = get(allTutorialProjectsState);
    const folders = get(tutorialFoldersState);
    const currentProjectId = get(tutorialCurrentProjectIdState);
    const hideTutorial = get(hideTutorialState);
    const allUserTags = get(tutorialAllUserTagsState);
    const sortByProject = get(tutorialSortByProjectState);
    const sortOrderProject = get(tutorialSortOrderProjectState);
    const selectedTutorialNotificationTaskId = get(selectedTutorialNotificationTaskIdState);

    // then combine into desired shape (object) and return:
    return {
      taskFilters,
      COMPLETED,
      unFilteredList,
      currentHighlightedTask,
      websocketStatus,
      FOCUS,
      BACKLOG,
      allUsers,
      selectedTasks,
      flags,
      autoFocus,
      error,
      allProjects,
      folders,
      currentProjectId,
      hideTutorial,
      allUserTags,
      sortByProject,
      sortOrderProject,
      selectedTutorialNotificationTaskId,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(tutorialTaskFiltersState, value);
      set(tutorialCompletedTasksState, value);
      set(tutorialTaskUnfilteredListState, value);
      set(tutorialCurrentHighlightedTask, value);
      set(tutorialWebsocketStatusState, value);
      set(tutorialFocusTasksState, value);
      set(tutorialBacklogTaskState, value);
      set(allTutorialUsersState, value);
      set(tutorialSelectedTasksState, value);
      set(tutorialFlagsState, value);
      set(tutorialAutoFocusState, value);
      set(tutorialTaskFetchErrorState, value);
      set(allTutorialProjectsState, value);
      set(tutorialFoldersState, value);
      set(tutorialCurrentProjectIdState, value);
      set(hideTutorialState, value);
      set(tutorialAllUserTagsState, value);
      set(tutorialSortByProjectState, value);
      set(tutorialSortOrderProjectState, value);
      set(selectedTutorialNotificationTaskIdState, value);
      return;
    }
    set(tutorialTaskFiltersState, value.taskFilters);
    set(tutorialCompletedTasksState, value.COMPLETED);
    set(tutorialTaskUnfilteredListState, value.unFilteredList);
    set(tutorialCurrentHighlightedTask, value.currentHighlightedTask);
    set(tutorialWebsocketStatusState, value.websocketStatus);
    set(tutorialFocusTasksState, value.FOCUS);
    set(tutorialBacklogTaskState, value.BACKLOG);
    set(allTutorialUsersState, value.allUsers);
    set(tutorialSelectedTasksState, value.selectedTasks);
    set(tutorialFlagsState, value.flags);
    set(tutorialAutoFocusState, value.autoFocus);
    set(tutorialTaskFetchErrorState, value.error);
    set(allTutorialProjectsState, value.allProjects);
    set(tutorialFoldersState, value.folders);
    set(tutorialCurrentProjectIdState, value.currentProjectId);
    set(hideTutorialState, value.hideTutorial);
    set(tutorialAllUserTagsState, value.allUserTags);
    set(tutorialSortByProjectState, value.sortByProject);
    set(tutorialSortOrderProjectState, value.sortOrderProject);
    set(selectedTutorialNotificationTaskIdState, value.selectedTutorialNotificationTaskId);
  },
});
