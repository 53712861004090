import { DefaultValue, atom, selector } from 'recoil';

export const superAdminAnalyticsActiveUsersFilterSelector =
  atom<SuperAdminAnalyticsActiveUsersFilterType>({
    key: 'superAdminAnalyticsActiveUsersFilter',
    default: {
      lastLogInDate: { value: 'allTime' },
    },
  });

export const superAdminAnalyticsMonthlyActiveUsersFilterSelector =
  atom<SuperAdminAnalyticsMonthlyActiveUsersFilterType>({
    key: 'superAdminAnalyticsMonthlyActiveUsersFilter',
    default: {
      lastLogInDate: { value: 'last12Months' },
    },
  });

export const superAdminAnalyticsUsersFilterSelector = atom<SuperAdminAnalyticsUsersFilterType>({
  key: 'superAdminAnalyticsUsersFilter',
  default: {
    created: { value: 'allTime' },
  },
});

export const superAdminAnalyticsMonthlyUsersFilterSelector =
  atom<SuperAdminAnalyticsMonthlyUsersFilterType>({
    key: 'superAdminAnalyticsMonthlyUsersFilter',
    default: {
      created: { value: 'last12Months' },
    },
  });

export const superAdminAnalyticsFilterSelector = selector<SuperAdminAnalyticsFilterType>({
  key: 'superAdminAnalyticsFilter',
  get: ({ get }) => {
    const activeUsersFilter = get(superAdminAnalyticsActiveUsersFilterSelector);
    const monthlyActiveUsersFilter = get(superAdminAnalyticsMonthlyActiveUsersFilterSelector);
    const usersFilter = get(superAdminAnalyticsUsersFilterSelector);
    const monthlyUsersFilter = get(superAdminAnalyticsMonthlyUsersFilterSelector);
    return { activeUsersFilter, monthlyActiveUsersFilter, usersFilter, monthlyUsersFilter };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(superAdminAnalyticsActiveUsersFilterSelector, value);
      set(superAdminAnalyticsMonthlyActiveUsersFilterSelector, value);
      set(superAdminAnalyticsUsersFilterSelector, value);
      set(superAdminAnalyticsMonthlyUsersFilterSelector, value);
      return;
    }
    set(superAdminAnalyticsActiveUsersFilterSelector, value.activeUsersFilter);
    set(superAdminAnalyticsMonthlyActiveUsersFilterSelector, value.monthlyActiveUsersFilter);
    set(superAdminAnalyticsUsersFilterSelector, value.usersFilter);
    set(superAdminAnalyticsMonthlyUsersFilterSelector, value.monthlyUsersFilter);
  },
});
