import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';
import { getUserId } from 'services/AuthService';

/*
 * Get all user tags
 *
 * returns Object {data, error, status}
 */
export async function getAllTags(userId: string): Promise<AsyncResponseType> {
  userId = userId ? userId : getUserId();
  const url = `${apiConfig.tags.get_tags}/${userId}`;

  try {
    const response = await appClient.get(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Add new tag
 *
 * returns Object {data, error, status}
 */
export async function addNewTag(payload) {
  try {
    const response = await appClient.post(apiConfig.tags.add_new_tag, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update task tags
 *
 * returns Object {data, error, status}
 */
export async function updateTaskTags(
  taskId: string,
  tagIds: string[] | undefined,
  doInclude: boolean,
  tempId: string | undefined,
  taskName: string | null = null,
  isTemplateTask: boolean = false,
) {
  const payload = {
    taskId,
    doInclude,
    tempId,
    tagIds,
    taskName,
  };
  if (tempId) {
    payload['tempId'] = tempId;
  }

  let url = apiConfig.tags.update_task_tags;
  if (isTemplateTask) url = apiConfig.template.update_task_tags;

  try {
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update multiple task tags
 *
 * returns Object {data, error, status}
 */
export async function bulkUpdateTaskTags(taskIds, tagIds, doInclude, tempId) {
  const payload = {
    taskIds,
    doInclude,
    tempId,
    tagIds: tagIds,
  };

  try {
    const response = await appClient.post(apiConfig.tags.update_task_tags, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
