import { atom, DefaultValue, selector } from 'recoil';
import { getItem } from 'utils/storage';
const hide = getItem('hideWorkspaceSwitch');
const leftMenuHide = getItem('hideLeftSidebarDesktop');
const cardModalPopup = getItem('showThemeCardModal');
const cardData = getItem('themeCardData');

export const currentRouteState = atom({
  key: 'currentRoute',
  default: '',
});

export const hideWorkspaceSwitchState = atom({
  key: 'hideWorkspaceSwitch',
  default: hide.data !== undefined && hide.data !== null ? JSON.parse(hide.data) : true,
});

export const hideLeftSidebarState = atom({
  key: 'hideLeftSidebar',
  default: false,
});

export const hideLeftSidebarDesktopState = atom({
  key: 'hideLeftSidebarDesktop',
  default: leftMenuHide.data !== null ? JSON.parse(leftMenuHide.data) : false,
});

export const showUserProfileModalState = atom({
  key: 'showUserProfileModal',
  default: false,
});

export const showEditProjectModalState = atom({
  key: 'showEditProjectModal',
  default: false,
});

export const showTaskCreationBoxState = atom({
  key: 'showTaskCreationBox',
  default: false,
});

export const showCreateWorkspaceModalState = atom({
  key: 'showCreateWorkspaceModal',
  default: false,
});

export const taskDetailModalState = atom<TaskDetailModalObjectType>({
  key: 'taskDetailModal',
  default: {
    visible: false,
    modalType: null, // task or comment
    taskId: null,
    parentId: null,
    hideComments: false,
    taskObj: null,
    dataType: 'BACKLOG', // FOCUS or BACKLOG
  },
});

export const multimediaModalState = atom<MultimediaModalType>({
  key: 'multimediaModal',
  default: {
    visible: false,
  },
});

export const showThemesPopupModalState = atom({
  key: 'showThemesPopupModal',
  default: false,
});

export const showSearchModalState = atom({
  key: 'showSearchModal',
  default: false,
});

export const showCreateNewProjectModalState = atom({
  key: 'showCreateNewProjectModal',
  default: false,
});

export const folderForCreateNewProjectModalState = atom<FolderType | null>({
  key: 'folderForCreateNewProjectModal',
  default: null,
});

export const showCreateFolderModalState = atom({
  key: 'showCreateFolderModal',
  default: false,
});

export const showInviteMembersModalState = atom({
  key: 'showInviteMembersModal',
  default: false,
});

export const showSearchUsersModalState = atom({
  key: 'showSearchUsersModal',
  default: false,
});

export const showFeedbackModalState = atom({
  key: 'showFeedbackModal',
  default: false,
});

export const showWhatsNewModalState = atom({
  key: 'showWhatsNewModal',
  default: false,
});

export const showCreateProjectTemplateModalState = atom({
  key: 'showCreateProjectTemplateModal',
  default: false,
});

export const projectForCreateProjectTemplateModalState = atom<ProjectObjectType | null>({
  key: 'projectForCreateProjectTemplateModal',
  default: null,
});

export const goToUrlState = atom<string | undefined>({
  key: 'goToUrl',
  default: undefined,
});

export const showThemeCardModalState = atom({
  key: 'showThemeCardModal',
  default: cardModalPopup.data !== null ? JSON.parse(cardModalPopup.data) : false,
});

export const showPricingModalState = atom({
  key: 'showPricingModal',
  default: false,
});

export const showUpgradeModalState = atom<UpgradeModalType | null>({
  key: 'showUpgradeModal',
  default: null,
});

export const showThemesLevelUpModalState = atom({
  key: 'showThemesLevelUpModal',
  default: false,
});

export const themeCardModalDataState = atom<ThemeInstanceInterface | ThemeObjectInterface | null>({
  key: 'themeCardModalData',
  default: cardData.data !== null ? JSON.parse(cardData.data) : null,
});

export const isWorkspaceSwitchingState = atom({
  key: 'isWorkspaceSwitching',
  default: false,
});

export const showLeaderboardModalState = atom({
  key: 'showLeaderboardModal',
  default: false,
});

export const userEnrolledState = atom({
  key: 'userEnrolled',
  default: false,
});

export const popupTabPositionState = atom({
  key: 'popupTabPosition',
  default: 0,
});

export const messageModalState = atom<MessageModalType>({
  key: 'messageModal',
  default: {
    visible: false,
    message: '',
  },
});

export const showNotificationPermissionModalState = atom<boolean>({
  key: 'showNotificationPermission',
  default: false,
});

export const showNotificationPopupState = atom<boolean>({
  key: 'showNotificationPopup',
  default: false,
});

export const navigationDataSelector = selector<NavigationDataType>({
  key: 'navigationData',
  get: ({ get }) => {
    const currentRoute = get(currentRouteState);
    const hideWorkspaceSwitch = get(hideWorkspaceSwitchState);
    const hideLeftSidebarDesktop = get(hideLeftSidebarDesktopState);
    const showUserProfileModal = get(showUserProfileModalState);
    const showEditProjectModal = get(showEditProjectModalState);
    const hideLeftSidebar = get(hideLeftSidebarState);
    const showTaskCreationBox = get(showTaskCreationBoxState);
    const showCreateWorkspaceModal = get(showCreateWorkspaceModalState);
    const taskDetailModal = get(taskDetailModalState);
    const showThemesPopupModal = get(showThemesPopupModalState);
    const showSearchModal = get(showSearchModalState);
    const showCreateNewProjectModal = get(showCreateNewProjectModalState);
    const folderForCreateNewProjectModal = get(folderForCreateNewProjectModalState);
    const showCreateFolderModal = get(showCreateFolderModalState);
    const showInviteMembersModal = get(showInviteMembersModalState);
    const showSearchUsersModal = get(showSearchUsersModalState);
    const showFeedbackModal = get(showFeedbackModalState);
    const showWhatsNewModal = get(showWhatsNewModalState);
    const showCreateProjectTemplateModal = get(showCreateProjectTemplateModalState);
    const projectForCreateProjectTemplateModal = get(projectForCreateProjectTemplateModalState);
    const goToUrl = get(goToUrlState);
    const multimediaModal = get(multimediaModalState);
    const showThemeCardModal = get(showThemeCardModalState);
    const showPricingModal = get(showPricingModalState);
    const showUpgradeModal = get(showUpgradeModalState);
    const showThemesLevelUpModal = get(showThemesLevelUpModalState);
    const themeCardData = get(themeCardModalDataState);
    const isWorkspaceSwitching = get(isWorkspaceSwitchingState);
    const showLeaderboardModal = get(showLeaderboardModalState);
    const userEnrolled = get(userEnrolledState);
    const popupTabPosition = get(popupTabPositionState);
    const messageModal = get(messageModalState);
    const showNotificationPermission = get(showNotificationPermissionModalState);
    const showNotificationPopup = get(showNotificationPopupState);
    // then combine into desired shape (object) and return:
    return {
      currentRoute,
      hideWorkspaceSwitch,
      hideLeftSidebarDesktop,
      showUserProfileModal,
      showEditProjectModal,
      hideLeftSidebar,
      showTaskCreationBox,
      showCreateWorkspaceModal,
      taskDetailModal,
      showThemesPopupModal,
      showSearchModal,
      showCreateNewProjectModal,
      folderForCreateNewProjectModal,
      showCreateFolderModal,
      showInviteMembersModal,
      showSearchUsersModal,
      showFeedbackModal,
      showWhatsNewModal,
      showCreateProjectTemplateModal,
      projectForCreateProjectTemplateModal,
      goToUrl,
      multimediaModal,
      showThemeCardModal,
      showPricingModal,
      showUpgradeModal,
      showThemesLevelUpModal,
      themeCardData,
      isWorkspaceSwitching,
      showLeaderboardModal,
      userEnrolled,
      popupTabPosition,
      messageModal,
      showNotificationPermission,
      showNotificationPopup,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(currentRouteState, value);
      set(hideWorkspaceSwitchState, value);
      set(hideLeftSidebarDesktopState, value);
      set(showUserProfileModalState, value);
      set(showEditProjectModalState, value);
      set(hideLeftSidebarState, value);
      set(showTaskCreationBoxState, value);
      set(showCreateWorkspaceModalState, value);
      set(taskDetailModalState, value);
      set(showThemesPopupModalState, value);
      set(showSearchModalState, value);
      set(showCreateNewProjectModalState, value);
      set(folderForCreateNewProjectModalState, value);
      set(showCreateFolderModalState, value);
      set(showInviteMembersModalState, value);
      set(showSearchUsersModalState, value);
      set(showFeedbackModalState, value);
      set(showWhatsNewModalState, value);
      set(showCreateProjectTemplateModalState, value);
      set(projectForCreateProjectTemplateModalState, value);
      set(goToUrlState, value);
      set(multimediaModalState, value);
      set(showThemeCardModalState, value);
      set(showPricingModalState, value);
      set(showUpgradeModalState, value);
      set(showThemesLevelUpModalState, value);
      set(themeCardModalDataState, value);
      set(isWorkspaceSwitchingState, value);
      set(showLeaderboardModalState, value);
      set(userEnrolledState, value);
      set(popupTabPositionState, value);
      set(messageModalState, value);
      set(showNotificationPermissionModalState, value);
      set(showNotificationPopupState, value);
      return;
    }
    set(currentRouteState, value.currentRoute);
    set(hideWorkspaceSwitchState, value.hideWorkspaceSwitch);
    set(hideLeftSidebarDesktopState, value.hideLeftSidebarDesktop);
    set(showUserProfileModalState, value.showUserProfileModal);
    set(showEditProjectModalState, value.showEditProjectModal);
    set(hideLeftSidebarState, value.hideLeftSidebar);
    set(showTaskCreationBoxState, value.showTaskCreationBox);
    set(showCreateWorkspaceModalState, value.showCreateWorkspaceModal);
    set(taskDetailModalState, value.taskDetailModal);
    set(showThemesPopupModalState, value.showThemesPopupModal);
    set(showSearchModalState, value.showSearchModal);
    set(showCreateNewProjectModalState, value.showCreateNewProjectModal);
    set(folderForCreateNewProjectModalState, value.folderForCreateNewProjectModal);
    set(showCreateFolderModalState, value.showCreateFolderModal);
    set(showInviteMembersModalState, value.showInviteMembersModal);
    set(showSearchUsersModalState, value.showSearchUsersModal);
    set(showFeedbackModalState, value.showFeedbackModal);
    set(showWhatsNewModalState, value.showWhatsNewModal);
    set(showCreateProjectTemplateModalState, value.showCreateProjectTemplateModal);
    set(projectForCreateProjectTemplateModalState, value.projectForCreateProjectTemplateModal);
    set(goToUrlState, value.goToUrl);
    set(multimediaModalState, value.multimediaModal);
    set(showThemeCardModalState, value.showThemeCardModal);
    set(showPricingModalState, value.showPricingModal);
    set(showUpgradeModalState, value.showUpgradeModal);
    set(showThemesLevelUpModalState, value.showThemesLevelUpModal);
    set(themeCardModalDataState, value.themeCardData);
    set(isWorkspaceSwitchingState, value.isWorkspaceSwitching);
    set(showLeaderboardModalState, value.showLeaderboardModal);
    set(userEnrolledState, value.userEnrolled);
    set(popupTabPositionState, value.popupTabPosition);
    set(messageModalState, value.messageModal);
    set(showNotificationPermissionModalState, value.showNotificationPermission);
    set(showNotificationPopupState, value.showNotificationPopup);
  },
});
