import { TargetedEvent } from 'preact/compat';
import { Strings } from 'resources';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import CloseIcon from 'assets/images/auth/close.png';
type ClearInputPropsType = {
  autoCapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  placeholder: string;
  value: string;
  onChange: (e: TargetedEvent) => void;
  onBlur: (e: FocusEvent) => void;
  errorMessage: string;
  touched?: boolean;
  secure?: boolean;
  disabled?: boolean;
  onClear: (e: MouseEvent) => void;
  role?: string;
  roleUpdated: (newRole: string) => void;
  menuClicked: () => void;
};
export default function ClearInput(props: ClearInputPropsType) {
  const {
    autoCapitalize,
    placeholder,
    value,
    onChange,
    onBlur,
    errorMessage,
    touched,
    secure,
    disabled,
    onClear,
    role,
    roleUpdated,
    menuClicked,
  } = props;

  const renderError = () => {
    if (errorMessage && touched) return <div className='input-error'>{errorMessage}</div>;

    return null;
  };

  const renderElement = () => {
    const inputClasses = ['input'];

    if (errorMessage && touched) {
      inputClasses.push('invalid');
    }
    if (disabled) {
      inputClasses.push('disabled');
    }
    return (
      <input
        autoCapitalize={autoCapitalize}
        className={inputClasses.join(' ')}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={secure ? 'password' : 'input'}
        autoComplete='off'
        data-lpignore='true'
        data-form-type='other'
        disabled={disabled}
      />
    );
  };

  const renderRoleMenuButton = () => {
    return (
      <MenuButton className='user-role-btn' onClick={menuClicked}>
        <div className='user-role-con'>
          <span className={`user-role ${role ? '' : 'no-role'}`}>
            {role ? role.toLowerCase() : Strings.no_role}
          </span>
          <span className='edit-role'>{Strings.edit_role}</span>
        </div>
      </MenuButton>
    );
  };

  const renderUserRole = () => {
    return (
      <Menu className='user-role-menu' menuButton={renderRoleMenuButton()}>
        <MenuItem onClick={() => roleUpdated(Strings.admin)}>{Strings.admin}</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => roleUpdated(Strings.manager)}>{Strings.manager}</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => roleUpdated(Strings.standard)}>{Strings.standard}</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => roleUpdated(Strings.guest)}>{Strings.guest}</MenuItem>
      </Menu>
    );
  };

  return (
    <div className={`clear-input-container`}>
      <div className={`text-input ${disabled ? 'disabled' : ''}`}>
        <span className='text-input-wrapper'>
          {renderElement()}
          <span className='user-role-input'>{renderUserRole()}</span>
          <span className='close-icon-con' title='Clear' onClick={onClear}>
            <img src={CloseIcon} alt='clear' />
          </span>
        </span>
      </div>
      {renderError()}
    </div>
  );
}
