import { atom, DefaultValue, selector } from 'recoil';

export const currentWorkspaceState = atom<string | null>({
  key: 'currentWorkspace',
  default: null,
});

export const themeSortByState = atom<SortByType>({
  key: 'themeSortBy',
  default: 'name',
});

export const themeSortOrderState = atom<SortOrderType>({
  key: 'themeSortOrder',
  default: 'asc',
});

export const workspacesDataState = atom<WorkspaceObjectType[]>({
  key: 'workspacesData',
  default: [],
});

export const workspacesHasMoreState = atom<boolean>({
  key: 'workspacesHasMore',
  default: true,
});

export const workspacesPageState = atom<number>({
  key: 'workspacesPage',
  default: 0,
});

export const workspacesPageSizeState = atom<number>({
  key: 'workspacesPageSize',
  default: 100,
});

export const workspacesPointsState = atom<number>({
  key: 'workspacesPoints',
  default: 0,
});

export const workspacesLoadingState = atom<boolean>({
  key: 'workspacesLoading',
  default: false,
});

export const workspacesSortByState = atom<SortByType>({
  key: 'workspacesSortBy',
  default: 'joiningDate',
});

export const workspacesSortOrderState = atom<SortOrderType>({
  key: 'workspacesSortOrder',
  default: 'desc',
});

export const workspacesStatusState = atom<string>({
  key: 'workspacesStatus',
  default: 'all',
});

export const workspacesAbortControllerState = atom<AbortController>({
  key: 'workspacesAbortController',
  default: new AbortController(),
});

export const workspacesState = selector<PaginatedWorkspacesType>({
  key: 'workspaces',
  get: ({ get }) => {
    const data = get(workspacesDataState);
    const hasMore = get(workspacesHasMoreState);
    const page = get(workspacesPageState);
    const limit = get(workspacesPageSizeState);
    const points = get(workspacesPointsState);
    const isLoading = get(workspacesLoadingState);
    const sortBy = get(workspacesSortByState);
    const sortOrder = get(workspacesSortOrderState);
    const status = get(workspacesStatusState);
    const abortController = get(workspacesAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      points,
      isLoading,
      sortBy,
      sortOrder,
      status,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(workspacesDataState, value);
      set(workspacesHasMoreState, value);
      set(workspacesPageState, value);
      set(workspacesPageSizeState, value);
      set(workspacesPointsState, value);
      set(workspacesLoadingState, value);
      set(workspacesSortByState, value);
      set(workspacesSortOrderState, value);
      set(workspacesStatusState, value);
      set(workspacesAbortControllerState, value);
      return;
    }
    set(workspacesDataState, value.data);
    set(workspacesHasMoreState, value.hasMore);
    set(workspacesPageState, value.page);
    set(workspacesPageSizeState, value.limit);
    set(workspacesPointsState, value.points);
    set(workspacesLoadingState, value.isLoading);
    set(workspacesSortByState, value.sortBy);
    set(workspacesSortOrderState, value.sortOrder);
    set(workspacesStatusState, value.status);
    set(workspacesAbortControllerState, value.abortController);
  },
});

export const superAdminDataSelector = selector<SuperAdminDataType>({
  key: 'superAdminData',
  get: ({ get }) => {
    const currentWorkspace = get(currentWorkspaceState);
    const themeSortBy = get(themeSortByState);
    const themeSortOrder = get(themeSortOrderState);
    const workspaces = get(workspacesState);
    return { currentWorkspace, themeSortBy, themeSortOrder, workspaces };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(themeSortByState, value);
      set(themeSortOrderState, value);
      set(workspacesState, value);
      return;
    }
    set(themeSortByState, value.themeSortBy);
    set(themeSortOrderState, value.themeSortOrder);
    set(workspacesState, value.workspaces);
  },
});
