import { Suspense, useEffect, Fragment } from 'preact/compat';
import { setPageTitle } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import Spinner from 'components/UI/Spinner';
import handleViewport from 'react-in-viewport';
import TutorialTopTitleBar from 'components/TutorialTopTitleBar';
import BottomBar from 'components/BottomBar';
import { useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { projectDataSelector } from 'recoil/ProjectState';
import { accountDataSelector } from 'recoil/AccountState';
import { navigationDataSelector } from 'recoil/NavigationState';
import { tutorialDataSelector } from 'recoil/TutorialState';

const TutorialTaskList = lazyWithRetry(() => import('./TutorialTaskList'));

type TutorialCompletedTasksPropsType = {
  stateType: StateType;
};
export default function TutorialCompletedTasks() {
  const props: TutorialCompletedTasksPropsType = useOutletContext();

  const { stateType } = props;

  const projectData = useRecoilValue(projectDataSelector);
  const accountData = useRecoilValue(accountDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const tutorialData = useRecoilValue(tutorialDataSelector);

  const { userId, projectId } = useParams();

  const getUserName = () => {
    if (accountData.allUsers === null) return '';

    const index = accountData.allUsers.findIndex((item) => item.id === userId);

    if (index === -1) return '';

    return accountData.allUsers[index].display;
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'INDIVIDUAL') {
      const title = getUserName() !== '' ? getUserName() + ' - Completed tasks' : 'Completed tasks';
      setPageTitle(title);
    }
  }, [getUserName()]);

  const getProjectName = () => {
    if (projectData.allProjects === null) return '';

    const index = projectData.allProjects.findIndex((item) => item.id === projectId);

    if (index === -1) return '';

    return projectData.allProjects[index].display;
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'PROJECT') {
      const title =
        getProjectName() !== '' ? getProjectName() + ' - Completed tasks' : 'Completed tasks';
      setPageTitle(title);
    }
  }, [getProjectName()]);

  const getStateData = () => {
    return tutorialData;
  };

  const Block = (props: any) => {
    const { inViewport, forwardedRef } = props;

    if (inViewport) loadMoreData();
    return (
      <div
        id='completed-tasks-viewport-block'
        className='viewport-block tutorial'
        ref={forwardedRef}
      >
        <div id='completed-tasks-viewport-block-loader-con' style={{ paddingBottom: '20px' }}>
          {inViewport ? renderLoading() : ''}
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    const { isLoading, hasMore } = getStateData().COMPLETED;

    if (isLoading && hasMore)
      return (
        <div id='completed-tasks-viewport-block-loader' style={{ paddingLeft: '30px' }}>
          <Spinner />
        </div>
      );

    return null;
  };

  const ViewportBlock = handleViewport(Block, { rootMargin: '300px' });

  const loadMoreData = () => {
    const { isLoading, hasMore } = getStateData().COMPLETED;

    if (isLoading || !hasMore) return;
  };

  /*
   * Render the component
   *
   * returns React.DOM
   */
  return (
    <Fragment>
      <div
        id='completed-tasks-screen'
        className={`tasks-screen completed-tasks-screen ${
          navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
        }`}
      >
        <TutorialTopTitleBar
          idName='top-title-bar-completed'
          stateType={stateType}
          readOnly={true}
        />
        <Suspense
          fallback={
            <div id='completed-tasks-loader-con' className='tasks-loader-con'>
              <Spinner />
            </div>
          }
        >
          <TutorialTaskList idName='completed-task-list' key='task-list' stateType={stateType} />
          <ViewportBlock key='load-next' />
        </Suspense>
        <BottomBar idName='completed-tasks-bottom-bar' />
        <div className='tutorial-overlay'></div>
      </div>
    </Fragment>
  );
}
