import { Suspense, useEffect, Fragment } from 'preact/compat';
import { lazyWithRetry } from 'utils/helpers';
import { log, setPageTitle } from 'utils';
import Spinner from 'components/UI/Spinner';
import handleViewport from 'react-in-viewport';
import TopTitleBar from 'components/TopTitleBar';
import BottomBar from 'components/BottomBar';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { open as openUserSocket, close as closeUserSocket } from 'websocket/user';
import { open as openProjectSocket, close as closeProjectSocket } from 'websocket/project';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  completedTasksState,
  openTaskCountSelector,
  taskDataStateSelector,
} from 'recoil/TaskState';
import {
  currentProjectIdState,
  projectDataSelector,
  projectCompletedTasksState,
} from 'recoil/ProjectState';
import { accountDataSelector } from 'recoil/AccountState';
import { currentUserIdState, userCompletedTasksState } from 'recoil/UserState';
import { navigationDataSelector } from 'recoil/NavigationState';
import { getCompletedTasks, getTaskData } from 'recoil/TaskState/update';
import { getActiveProjects } from 'utils/task';

const TaskList = lazyWithRetry(() => import('./TaskList'));

export default function CompletedTasks() {
  const props: TasksPropsType = useOutletContext();
  const { stateType } = props;
  const navigate = useNavigate();
  const taskData = useRecoilValue(taskDataStateSelector);
  const projectData = useRecoilValue(projectDataSelector);
  const accountData = useRecoilValue(accountDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);
  const setCurrentUserId = useSetRecoilState(currentUserIdState);
  const setCurrentProjectId = useSetRecoilState(currentProjectIdState);
  const { userId, projectId } = useParams();

  const getUserName = () => {
    if (accountData.allUsers === null) return '';
    const index = accountData.allUsers.findIndex((item) => item.id === userId);

    if (index === -1) return '';

    return accountData.allUsers[index].display;
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'INDIVIDUAL') {
      const title = getUserName() !== '' ? getUserName() + ' - Completed tasks' : 'Completed tasks';
      setPageTitle(title);
    }
  }, [getUserName()]);

  const getProjectName = () => {
    if (projectData.allProjects === null) return '';
    const index = projectData.allProjects.findIndex((item) => item.id === projectId);

    if (index === -1) return '';

    return projectData.allProjects[index].display;
  };

  // component did mount
  useEffect(() => {
    if (stateType === 'PROJECT') {
      const title =
        getProjectName() !== '' ? getProjectName() + ' - Completed tasks' : 'Completed tasks';
      setPageTitle(title);
    }
  }, [getProjectName()]);

  const getRecoilSelector = () => {
    if (stateType === 'INDIVIDUAL') return userCompletedTasksState;
    else if (stateType === 'PROJECT') return projectCompletedTasksState;
    return completedTasksState;
  };

  const openCount = useRecoilValue(openTaskCountSelector(stateType));

  // user
  useEffect(() => {
    log('connect user 66', userId);

    if (userId !== null && userId !== undefined && accountData.allUsers) {
      setCurrentUserId(userId);
      openUserSocket(userId, true); // open websocket connection
      resetData();
    }

    return () => {
      log('disconnect user 66', userId);
      setCurrentUserId(null);
      closeUserSocket();
    };
  }, [userId, taskData.COMPLETED.filter]);

  useEffect(() => {
    if (userId !== null && userId !== undefined && accountData.allUsers) {
      if (accountData.allUsers.findIndex((item) => item.id === userId) === -1) {
        navigate('/');
        return;
      }
    }
  }, [userId, taskData.COMPLETED.filter, accountData.allUsers]);

  // Project/ tag id
  useEffect(() => {
    if (projectId !== null && projectId !== undefined) {
      setCurrentProjectId(projectId);
      openProjectSocket(projectId, true); // open websocket connection
      resetData();
    }
    return () => {
      setCurrentProjectId(null);
      closeProjectSocket();
    };
  }, [projectId]);

  useEffect(() => {
    if (projectId !== null && projectId !== undefined && projectData.allProjects !== null) {
      const projects = getActiveProjects(projectData.allProjects).filter(
        (proj) => proj.id === projectId,
      );

      if (projects.length === 0) {
        navigate('/');
        return;
      }
    }
  }, [projectId, projectData.allProjects]);

  const Block = (props: any) => {
    const { inViewport, forwardedRef } = props;

    if (inViewport) loadMoreData();
    return (
      <div id='completed-tasks-viewport-block' className='viewport-block' ref={forwardedRef}>
        <div id='completed-tasks-viewport-block-loader-con' style={{ paddingBottom: '20px' }}>
          {inViewport ? renderLoading() : ''}
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    const targetStateAtom = getRecoilSelector();
    const data = useRecoilValue(targetStateAtom);
    const { isLoading, hasMore } = data;

    if (isLoading && hasMore)
      return (
        <div
          id='completed-tasks-viewport-block-loader'
          className='completed-tasks-block-loader'
          style={{ paddingLeft: '30px' }}
        >
          <Spinner />
        </div>
      );

    return null;
  };

  const ViewportBlock = handleViewport(Block, { rootMargin: '300px' });

  const loadMoreData = () => {
    const targetStateAtom = getRecoilSelector();
    const data = useRecoilValue(targetStateAtom);
    const { isLoading, hasMore } = data;

    if (isLoading || !hasMore) return;

    getCompletedTasks(null, false, stateType === 'PROJECT' ? projectId : userId, stateType);
  };

  const resetData = (filter?: string) => {
    if (openCount === 0) {
      getTaskData('FOCUS', true, stateType === 'PROJECT' ? projectId : userId, stateType);
      getTaskData('BACKLOG', true, stateType === 'PROJECT' ? projectId : userId, stateType);
    }

    getCompletedTasks(filter, true, stateType === 'PROJECT' ? projectId : userId, stateType);
  };

  /*
   * Render the component
   *
   * returns React.DOM
   */
  return (
    <Fragment>
      <div
        id='completed-tasks-screen'
        className={`tasks-screen completed-tasks-screen
        ${navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''}
        ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
      >
        <TopTitleBar idName='top-title-bar-completed' stateType={stateType} readOnly={true} />
        <Suspense
          fallback={
            <div id='completed-tasks-loader-con' className='tasks-loader-con'>
              <Spinner />
            </div>
          }
        >
          <div className='completed-task-con'>
            <TaskList idName='completed-task-list' key='task-list' stateType={stateType} />
            <ViewportBlock key='load-next' />
          </div>
        </Suspense>
        <BottomBar idName='completed-tasks-bottom-bar' />
      </div>
    </Fragment>
  );
}
