import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  allTriggersState,
  bgSoundTriggerState,
  browserSoundEnabledState,
  completTaskDelayState,
  completeTaskCanvasTriggerState,
  completeTaskSoundTriggerState,
  createSubTaskSoundTriggerState,
  createSubtaskDelayState,
  createTaskCanvasTriggerState,
  createTaskDelayState,
  createTaskSoundTriggerState,
  lottieCompleteTaskDelayState,
  riveCompleteTaskDelayState,
  lottieTriggersState,
  reOrderTaskRiveTriggerState,
  completeTaskRiveTriggerState,
  bgAnimationLowRiveTriggerState,
  bgAnimationMediumRiveTriggerState,
  bgAnimationHighRiveTriggerState,
  createTaskRiveTriggerState,
  reOrderTaskCanvasTriggerState,
  reOrderTaskSoundTriggerState,
  switchScreenSoundTriggerState,
  triggerDataSelector,
  modalOpenSoundTriggerState,
  modalCloseSoundTriggerState,
  playRiveTriggerState,
} from '.';
import { playTheSound } from 'triggers/utils/sound';
import { getAllTriggers as getAllTriggersFromAPI } from 'services/ThemeService';
import { parseTriggers } from 'utils/task';

export function enableBrowserSound() {
  const triggerData = getRecoil(triggerDataSelector);

  if (triggerData.soundTriggers.bgSound) playTheSound(triggerData.soundTriggers.bgSound);

  setRecoil(browserSoundEnabledState, true);
}

export async function getAllTriggers() {
  const { data, success } = await getAllTriggersFromAPI();

  if (success && data) setRecoil(allTriggersState, parseTriggers(data));
}

export async function setTriggerProp(key: CSSDelayType, value: number) {
  if (key === 'createTaskDelay') {
    setRecoil(createTaskDelayState, value);
  } else if (key === 'createSubtaskDelay') {
    setRecoil(createSubtaskDelayState, value);
  } else if (key === 'completeTaskDelay') {
    setRecoil(completTaskDelayState, value);
  } else if (key === 'lottieCompleteTaskDelay') {
    setRecoil(lottieCompleteTaskDelayState, value);
  } else if (key === 'riveCompleteTaskDelay') {
    setRecoil(riveCompleteTaskDelayState, value);
  }
}

export async function setSoundTrigger(key: TriggerNameType, id: TriggersType) {
  switch (key) {
    case 'bgSound':
      setRecoil(bgSoundTriggerState, id);
      break;
    case 'completeTask':
      setRecoil(completeTaskSoundTriggerState, id);
      break;
    case 'createSubTask':
      setRecoil(createSubTaskSoundTriggerState, id);
      break;
    case 'createTask':
      setRecoil(createTaskSoundTriggerState, id);
      break;
    case 'reOrderTask':
      setRecoil(reOrderTaskSoundTriggerState, id);
      break;
    case 'switchScreen':
      setRecoil(switchScreenSoundTriggerState, id);
      break;
    case 'modalOpen':
      setRecoil(modalOpenSoundTriggerState, id);
      break;
    case 'modalClose':
      setRecoil(modalCloseSoundTriggerState, id);
      break;
  }
}

export async function setLottieTrigger(key: TriggerNameType, id: TriggersType) {
  const lottieTriggers = getRecoil(lottieTriggersState);
  const temp = {
    ...lottieTriggers,
    [key]: id,
  };
  setRecoil(lottieTriggersState, temp);
}

export async function setRiveTrigger(key: TriggerNameType, id: TriggersType) {
  switch (key) {
    case 'completeTask':
      setRecoil(completeTaskRiveTriggerState, id);
      break;
    case 'createTask':
      setRecoil(createTaskRiveTriggerState, id);
      break;
    case 'reOrderTask':
      setRecoil(reOrderTaskRiveTriggerState, id);
      break;
    case 'bgAnimationLow':
      setRecoil(bgAnimationLowRiveTriggerState, id);
      break;
    case 'bgAnimationMedium':
      setRecoil(bgAnimationMediumRiveTriggerState, id);
      break;
    case 'bgAnimationHigh':
      setRecoil(bgAnimationHighRiveTriggerState, id);
      break;
  }
}

export function setPlayRiveTrigger(key: keyof PlayRiveAnimationType, value: boolean) {
  setRecoil(playRiveTriggerState, (prevState) => ({
    ...prevState,
    [key]: value,
  }));
}

export async function setCanvasTrigger(key: TriggerNameType, id: TriggersType) {
  switch (key) {
    case 'completeTask':
      setRecoil(completeTaskCanvasTriggerState, id);
      break;
    case 'createTask':
      setRecoil(createTaskCanvasTriggerState, id);
      break;
    case 'reOrderTask':
      setRecoil(reOrderTaskCanvasTriggerState, id);
      break;
  }
}
