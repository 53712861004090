/**
 * config
 * application Configuration
 */
let config = {
  appName: 'Magic Task',
  version: Date.now(),
  siteUrl: 'https://magictask.dev-imaginovation.net/',
  apiUrl: 'https://magictask-api.dev-imaginovation.net/magictask-api/',
  webSocketUrl: 'wss://magictask-api.dev-imaginovation.net/magictask-api/websocket',
  environment: 'local',
  TermsURL:
    'https://app.termly.io/document/terms-of-use-for-saas/b2e4d5f9-992b-4103-a2b2-e44d199fa6d8',
  PrivacyPolicyURL:
    'https://app.termly.io/document/privacy-policy/8d935898-76ec-4e42-ba9b-d839099bab54',
  jitsiDomain: 'meet.imaginovation.net',
  // raygun_key: 'UPMoPimyageMlxglQJ3Q', // "meet.jit.si",
  stripe_key:
    'pk_test_51JNP5LGCbA3tWAhgoJF9dgAvV7QjqYvNFv3YPcvdO7sRaJvyZSkygTxuP7OzKm2029X6wNyN5ZtH0GRMAV58zRVh00citOwi2l',
  discordLink: 'https://discord.com/invite/VgpRh3EcK5',
  chartIds: {
    completedUserTasks: '64f03916-f45a-4b31-8472-d8dec9cc64d0',
    completedUserPoints: '64edfa6f-4d1f-46c3-8601-08d74158cad2',
    completedMonthlyTasks: '64f72fcb-cd93-45f1-8778-198706a3d6c8',
    completedMonthlyPoints: '643333cb-3adf-40c4-bc85-7114e5625540',
    superAdminActiveUsers: '653a83c2-a809-4955-84de-310239974ea4',
    superAdminMonthlyActiveUsers: '653a8480-af93-4221-8b4d-427fc3a2035d',
    superAdminUsers: 'b7eb3d6d-7a19-407c-994f-e5d602358386',
    superAdminMonthlyUsers: 'df96a8a9-9e24-4bde-88ae-19098a49ae8a',
  },
  chartUrl: 'https://charts.mongodb.com/charts-magictask-kwmnq',
};
const deploymentType = import.meta.env.VITE_ENVIRONMENT;

if (deploymentType === 'production') {
  config = {
    ...config,
    siteUrl: 'https://app.magictask.io',
    apiUrl: 'https://api.magictask.io/magictask-api/',
    webSocketUrl: 'wss://api.magictask.io/magictask-api/websocket',
    environment: 'production',
    // raygun_key: 'VO8JCkPoC747xHxInjKGg',
    stripe_key:
      'pk_live_51JNP5LGCbA3tWAhgOQMHcgxzvVvhTJNorHe2OEcKYBOjBVGRw2zsNI4BHmwAm89Vfdo13e4xSB3zCs9iDpF7mYs4004BL7KO0Z',
    chartIds: {
      completedUserTasks: '64e4b1a1-523b-4c4b-85f6-95807a0b80c3',
      completedUserPoints: '64f9c38b-2f38-4a4c-82e8-2825aaaae0b0',
      completedMonthlyTasks: '0cfac6e1-7592-45e2-a433-5c76dcba349d',
      completedMonthlyPoints: 'd0a2b7e5-1ede-4350-a2c2-96aebd8b341a',
      superAdminActiveUsers: 'c92ddc8a-4a9d-4e07-a44c-800419f2610a',
      superAdminMonthlyActiveUsers: '9e896891-8dcf-4216-b67e-bd08accb550a',
      superAdminUsers: '64e38def-4c49-4acb-8038-1d31cbd641a4',
      superAdminMonthlyUsers: '4b329872-1059-41ba-a52b-4c05664f57ca',
    },
  };
} else if (deploymentType === 'staging') {
  config = {
    ...config,
    siteUrl: 'http://staging-taskmanager.dev-imaginovation.net/',
    apiUrl: 'http://staging-taskmanagerapi.dev-imaginovation.net:8080/task-manager-api/',
    webSocketUrl: 'ws://taskmanagerapi.dev-imaginovation.net:8080/task-manager-api/websocket',
    environment: 'staging',
    // raygun_key: 'UPMoPimyageMlxglQJ3Q',
  };
} else if (deploymentType === 'qa') {
  config = {
    ...config,
    siteUrl: 'https://magictask-qa.dev-imaginovation.net',
    apiUrl: 'https://magictask-qa-api.dev-imaginovation.net/magictask-api/',
    webSocketUrl: 'wss://magictask-qa-api.dev-imaginovation.net/magictask-api/websocket',
    environment: 'qa',
  };
} else if (deploymentType === 'development') {
  config = {
    ...config,
    environment: 'development',
  };
}

export default config;
