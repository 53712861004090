// import { useRouteError } from "react-router-dom";

import { useEffect } from 'preact/hooks';

// type ErrorResponse = {
//   data: any;
//   status: number;
//   statusText: string;
//   message?: string;
// };

// const errorCheck = (error: any): error is ErrorResponse => {
//   return "data" in error && "status" in error && "statusText" in error;
// };
export default function PageNotFound() {
  // const error:any = useRouteError();
  // if (error instanceof ErrorEvent)
  // console.error("er",error);

  useEffect(() => {
    const mainElement = document.getElementById('root');
    if (mainElement) mainElement.style.cssText = `display:block;`;
  });

  // if (errorCheck(error)) {
  return (
    <div
      id='error-page'
      style={{
        margin: 'auto',
        alignItems: 'center',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1>Oops! Page not found</h1>
      <p>Sorry the route you are looking for does not exist.</p>
      {/* <p>
          <i>{error.statusText || error.message}</i>
        </p> */}
    </div>
  );
  // } else {
  //   return <div id="error-page">404</div>;
  // }
}
