import limitProjectsImage from '../assets/images/payment/limit-projects.png';
import limitFolderImage from '../assets/images/payment/limit-folder.png';
import limitRecurringImage from '../assets/images/payment/limit-recurring.png';
import limitAttachmentImage from '../assets/images/payment/limit-attachment.png';
import limitTemplateImage from '../assets/images/payment/limit-template.png';
import limitAnalyticsImage from '../assets/images/payment/limit-analytics.png';
import limitTasksImage from '../assets/images/payment/limit-tasks.png';
import limitSearchImage from '../assets/images/payment/limit-search.png';
import limitTeamImage from '../assets/images/payment/limit-invite.png';
import limitWorkSpaceImage from '../assets/images/payment/limit-workspace.png';
import successImage from '../assets/images/payment/subscription-success.png';
import failedImage from '../assets/images/payment/subscription-failed.png';

export const UPGRADE_MODAL: UpgradeModalDataType = {
  PROJECT: {
    featuredImage: limitProjectsImage,
    title: "Project Creation <span class='um-highlight'>Limit Reached!</span>",
    description:
      'Your current plan includes 1 project. Upgrade to create unlimited projects in your workspace!',
    benefits: {
      title: 'What you get',
      list: [
        'Manage multiple projects seamlessly.',
        'Keep all your projects in one place for easy access.',
        'Unlock additional features to enhance productivity.',
      ],
    },
  },
  FOLDER: {
    featuredImage: limitFolderImage,
    title: "Stay Organised & <span class='um-highlight'>Project Folders</span>",
    description:
      'Group related tasks and projects into folders for easy navigation and streamlined organization. Keep everything you need in one place!',
    benefits: {
      title: 'What you get',
      list: [
        'Keep all related projects and tasks neatly grouped for quick access.',
        'Share folders with team members to ensure everyone stays aligned.',
        'Easily locate and manage multiple projects with a clear folder structure.',
      ],
    },
  },
  RECURRING: {
    featuredImage: limitRecurringImage,
    title: "Recurring Task <span class='um-highlight'>Limit Reached</span>",
    description:
      'Your plan includes 5 recurring tasks. Upgrade for unlimited and streamline your workflow!',
    benefits: {
      title: 'What you get',
      list: [
        'Automate tasks as often as needed, without limitations.',
        'Save time with flexible scheduling for ongoing tasks.',
        'Get more tools to help you stay organized',
      ],
    },
  },
  ATTACHMENT: {
    featuredImage: limitAttachmentImage,
    title: "Enhance Your <span class='um-highlight'>Attachment Limit</span>",
    description:
      'You can upload files up to 50 MB for free. To upload larger files, please upgrade your plan.',
    benefits: {
      title: 'What you get',
      list: [
        'Upload Larger Files – Share files greater than 50 MB effortlessly.',
        'Get more storage capacity for all your important documents.',
        'Collaborate easily with your team by sharing large files.',
      ],
    },
  },
  TASKS: {
    featuredImage: limitTasksImage,
    title: "Unlock Unlimited <span class='um-highlight'>Task Creation!</span>",
    description:
      'You’ve reached your 100-task limit. Upgrade now to create unlimited tasks and supercharge your productivity!',
    benefits: {
      title: 'What you get',
      list: [
        'Break free from limits and manage as many tasks as you need.',
        'Access premium features designed to enhance your workflow.',
        'Unlock premium features to optimize your workflow.',
      ],
    },
  },
  TEMPLATE: {
    featuredImage: limitTemplateImage,
    title: "Unlock Unlimited <span class='um-highlight'>Template Creation!</span>",
    description:
      'You’ve reached your 100-task limit. Upgrade now to create unlimited tasks and supercharge your productivity!',
    benefits: {
      title: 'What you get',
      list: [
        'Break free from limits and manage as many tasks as you need.',
        'Access premium features designed to enhance your workflow.',
        'Unlock premium features to optimize your workflow.',
      ],
    },
  },
  SEARCH: {
    featuredImage: limitSearchImage,
    title: "Find What You <span class='um-highlight'>Need, Fast</span>",
    description:
      'Effortlessly locate tasks, projects, or team members with the powerful search feature. Save time and stay focused on what matters.',
    benefits: {
      title: 'What you get',
      list: [
        'Instantly Create tasks, projects, assign, recurrence, tag.',
        'Search team, tasks, and projects easily.',
        'Track recent searches for quick access.',
      ],
    },
  },
  TEAM: {
    featuredImage: limitTeamImage,
    title: "Collaborate effortlessly <span class='um-highlight'>with team</span>",
    description:
      'Bring your team together, streamline communication, and accomplish more with collaborative tools built for productivity.',
    benefits: {
      title: 'What you get',
      list: [
        'Collaborate on tasks, share updates, and get instant notifications.',
        'Assign roles to secure data and ensure proper access.',
        'Store all project details in one place.',
      ],
    },
  },
  ANALYTICS: {
    featuredImage: limitAnalyticsImage,
    title: "Track success with <span class='um-highlight'>analytics</span>",
    description:
      "Track your team's performance and project progress with powerful analytics tools. Make data-driven decisions and optimize your  workflow.",
    benefits: {
      title: 'What you get',
      list: [
        'Get detailed insights into task progress, completion rates, and bottlenecks.',
        "Monitor project timelines and deadlines to ensure you're on track.",
        'Generate reports tailored to your needs whether by team, project.',
      ],
    },
  },
  SUCCESS: {
    featuredImage: successImage,
    title: "Subscription <span class='um-highlight'>Successfully! 🎉</span>",
    description:
      'Thank you for joining us! Your subscription is now active, and you’re all set to enjoy full access to premium features.Explore your upgraded tools and resources, and let’s make this journey productive and inspiring!',
  },
  FAILED: {
    featuredImage: failedImage,
    title: "Subscription <span class='um-highlight'>Failed ⚠️</span>",
    description:
      'We couldn’t complete your subscription payment. Please check your payment details and try again to access all premium features. If you need assistance, feel free to reach out to our support team. Let’s get you back on track!',
  },
  WORKSPACE: {
    featuredImage: limitWorkSpaceImage,
    title: "Upgrade to <span class='uw-highlight'>Elite Workspace</span>",
    description:
      'Effortlessly locate tasks, projects, or team members with the powerful search feature. Save time and stay focused on what matters.',
  },
};
