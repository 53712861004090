import { useState, Suspense } from 'preact/compat';
import { faPlus, faChevronUp, faChevronDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  isProjectTaskScreen,
  isRecurringTasksScreen,
  getActiveProjects,
  isTutorialsScreen,
} from 'utils/task';
import { getUserId } from 'services/AuthService';
import {
  setFolderOpenStatus as setFolderOpenStatusInAPI,
  updateFolderName,
} from 'services/ProjectService';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Strings } from 'resources';
import { log } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  hideTutorialState,
  tutorialCurrentProjectIdState,
  tutorialDataSelector,
  tutorialFoldersState,
} from 'recoil/TutorialState';
import { userDataSelector } from 'recoil/UserState';
import {
  navigationDataSelector,
  showCreateNewProjectModalState,
  folderForCreateNewProjectModalState,
  showWhatsNewModalState,
  hideLeftSidebarState,
  showEditProjectModalState,
  showSearchUsersModalState,
  showCreateFolderModalState,
  showInviteMembersModalState,
} from 'recoil/NavigationState';
import {
  getFoldersForUser,
  setFolderName,
  setFolderOpenStatus as setFolderOpenStatusInRecoil,
} from 'recoil/ProjectState/update';
import { updateFlag } from 'recoil/TutorialState/update';
const ConfirmationPopup = lazyWithRetry(() => import('modals/ConfirmationPopup'));

export default function TutorialLeftBar() {
  const navigationData = useRecoilValue(navigationDataSelector);
  const tutorialData = useRecoilValue(tutorialDataSelector);
  const userData = useRecoilValue(userDataSelector);
  const navigate = useNavigate();
  const alert = useAlert();

  const [renameFolderId, setRenameFolderId] = useState<string | null>(null);
  const [renameFolderValue, setRenameFolderValue] = useState('');

  const [deleteFolderValue, setDeleteFolderValue] = useState('');

  const [showConfirmationPopup, setConfirmationPopup] = useState(false);

  const userId = getUserId();

  const setCreateNewProjectModalStatus = useSetRecoilState(showCreateNewProjectModalState);
  const setFolderForCreateNewProjectModal = useSetRecoilState(folderForCreateNewProjectModalState);
  const setWhatsNewModalStatus = useSetRecoilState(showWhatsNewModalState);
  const setHideLeftSidebar = useSetRecoilState(hideLeftSidebarState);
  const setEditProjectModalStatus = useSetRecoilState(showEditProjectModalState);
  const setSearchUsersModalStatus = useSetRecoilState(showSearchUsersModalState);
  const setCreateFolderModalStatus = useSetRecoilState(showCreateFolderModalState);
  const setInviteMembersModalStatus = useSetRecoilState(showInviteMembersModalState);
  const setTutorialCurrentProjectId = useSetRecoilState(tutorialCurrentProjectIdState);
  const hideUserTutorial = useSetRecoilState(hideTutorialState);
  const addFolders = useSetRecoilState(tutorialFoldersState);

  // log('render components/TutorialLeftBar');

  const showWhatsNewModal = () => {
    setWhatsNewModalStatus(true);
  };

  const renderOverlay = (styleClass?: string) => {
    return <div className={`tutorial-overlay ${styleClass}`}></div>;
  };

  const renderBorder = () => {
    return <div className='my-tasks-border'></div>;
  };

  const renderMyTasks = () => {
    return (
      <div className='main-heading-con my-tasks-con'>
        <div
          id='left-bar-my-tasks-heading'
          className={`section-in my-tasks ${
            isTutorialsScreen(navigationData.currentRoute) ? 'selected' : ''
          }`}
          onClick={() => openMyTasks()}
        >
          <span>{Strings.my_tasks}</span>
        </div>
        {renderOverlay(
          tutorialData.flags.myTasks || tutorialData.flags.inputProjectTaskAssigneeCompletion
            ? ''
            : 'show-overlay',
        )}
        {tutorialData.flags.myTasks || tutorialData.flags.inputProjectTaskAssigneeCompletion
          ? renderBorder()
          : ''}
      </div>
    );
  };

  const renderRecurringTasks = () => {
    return (
      <div className='main-heading-con my-tasks-con'>
        <div
          id='left-bar-recurring-tasks-heading'
          className={`section-in my-tasks ${
            isRecurringTasksScreen(navigationData.currentRoute)
              ? 'selected menu-navigation-animation'
              : ''
          } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
          onClick={() => openRecurringTasks()}
        >
          <span className='arrow-right-sides'></span>
          <span className='arrow-right-side-second'></span>
          <span>{Strings.recurring_tasks}</span>
        </div>
        {renderOverlay('show-overlay')}
      </div>
    );
  };

  const renderTasks = () => {
    return (
      <div id='left-bar-tasks-con' className='section-con' key='render-tasks'>
        <h6 id='left-bar-tasks-heading' className='main-heading tasks'>
          <span>{Strings.tasks}</span>
          {renderOverlay()}
        </h6>
        <div id='tasks-list' className='section-in-con'>
          {renderMyTasks()}
          {renderRecurringTasks()}
        </div>
      </div>
    );
  };

  const renderChangeLog = () => {
    return (
      <div className='main-heading-con'>
        <div id='change-log-con' className={`main-heading`} onClick={() => showWhatsNewModal()}>
          <h6>{Strings.change_log}</h6>
        </div>
        {renderOverlay()}
      </div>
    );
  };

  const isSelectedProject = (projectId: string) => {
    if (tutorialData.currentProjectId === projectId) return true;

    return false;
  };

  const isSelectedMember = (memberId: string) => {
    if (userData.currentUserId === memberId) return true;

    return false;
  };

  const toggleLeftSidebar = (status?: boolean) => {
    setHideLeftSidebar(status ? status : !navigationData.hideLeftSidebar);
  };

  const hideLeftSidebar = () => {
    toggleLeftSidebar(true);
  };

  const renderMyProjects = () => {
    const folders =
      tutorialData.folders.length > 0 ? tutorialData.folders : tutorialData.allProjects;

    return (
      <div id='left-bar-projects-con' className='section-con' key='render-my-projects'>
        <div className='main-heading-con'>
          <h6 id='left-bar-my-projects-heading' className='main-heading projects'>
            <span className='leftmenu-heading-tutorial'>
              {Strings.projects}
              {renderOverlay()}
            </span>
            <div className='add-folder-and-project'>
              {renderAddFolder()}
              {renderAddProject()}
            </div>
          </h6>
        </div>

        <div id='projects-list' className='section-in-con'>
          {folders.map((item, index) =>
            item.isFolder
              ? renderFolder(item, index)
              : item.archived !== true
                ? renderProject(item, index, () => openProjectScreen(item.id))
                : null,
          )}
        </div>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderFolder = (folder: FolderType, index: number) => {
    return (
      <div
        id={`folder-con-${index}`}
        key={index}
        className={`folder-con ${folder.isOpen ? 'open' : ''}`}
      >
        <h6 id='folder-name-con' key='folder-in' className='folder-in'>
          {renderFolderName(folder)}{' '}
          <span className='folder-menu-options'>
            {renderFolderMenu(folder)}{' '}
            <span
              className='folder-drop-arrow-con'
              onClick={() => setFolderOpenStatus(folder.id, !folder.isOpen)}
            >
              <FontAwesomeIcon
                id={`folder-chevron-${index}`}
                className='icon'
                icon={folder.isOpen ? faChevronUp : faChevronDown}
              />
              {renderOverlay()}
            </span>{' '}
          </span>
        </h6>
        {folder.projects &&
          getActiveProjects(folder.projects).map((project, index) =>
            renderProject(project, index, () => openProjectScreen(project.id), true),
          )}
      </div>
    );
  };

  const setFolderOpenStatus = (folderId: string, status: boolean) => {
    setFolderOpenStatusInRecoil(folderId, status);
    setFolderOpenStatusInAPI(folderId, status);
  };

  const renderProject = (
    project: ProjectObjectType,
    index: number,
    openProject: (e: MouseEvent) => void,
    inside?: boolean,
  ) => {
    return (
      <div className='project-con'>
        <div
          id={`project-name-${index}`}
          key={project.id}
          onClick={openProject}
          className={`section-in ${inside ? 'inside' : ''} ${
            isSelectedProject(project.id) ? 'selected' : ''
          }`}
        >
          <span>{project.display}</span>
        </div>
        {renderOverlay()}
      </div>
    );
  };

  const openProjectScreen = async (projectId: string) => {
    if (
      tutorialData.currentProjectId === projectId &&
      isProjectTaskScreen(navigationData.currentRoute)
    )
      return;

    log('one98', 1);

    log('one98', 3);

    setEditProjectModalStatus(false);
    setSearchUsersModalStatus(false);

    hideLeftSidebar();
    navigate(`/project/${projectId}`);
  };

  const openMyTasks = () => {
    if (tutorialData.flags.inputProjectTaskAssigneeCompletion) {
      setTutorialCurrentProjectId(null);
      updateFlag('createAndAssignProjectTaskCompleted', true);
      navigate('/user-tutorial');
    }
  };

  const openRecurringTasks = () => {};

  const showAddProjectModal = (folder: FolderType | null) => {
    log('Add new project');
    hideLeftSidebar();
    updateFlag('showCreateProjectModal', true);
    setCreateNewProjectModalStatus(true);
    setFolderForCreateNewProjectModal(folder);
  };

  const renderAddFolder = () => {
    return (
      <div className='folder-menu-overlay-con' style={{ position: 'relative' }}>
        <Menu
          className='folder-menu'
          direction='bottom'
          align='end'
          menuButton={
            <MenuButton id='folder-menu-btn' className='folder-menu-btn project-menu-btn'>
              <FontAwesomeIcon id='add-folder-icon' className='icon' icon={faEllipsisV} size='sm' />
            </MenuButton>
          }
        >
          <MenuItem id='create-folder-menu-item' onClick={() => showAddFolder()}>
            {Strings.create_folder}
          </MenuItem>
        </Menu>
        {renderOverlay(tutorialData.flags.showAddFolderIcon ? 'hide-overlay' : '')}
      </div>
    );
  };

  const renderFolderMenu = (folder: FolderType) => {
    return (
      <div className='folder-option-con'>
        <Menu
          className='folder-menu'
          menuButton={
            <MenuButton id='folder-menu-btn' className='folder-menu-btn'>
              <FontAwesomeIcon
                id='folder-menu-icon'
                className='folder-menu-icon icon'
                icon={faEllipsisV}
                size='sm'
              />
            </MenuButton>
          }
          direction='bottom'
          align='end'
        >
          <MenuItem id='new-project-menu-item' onClick={() => createNewProject()}>
            {Strings.new_project}
          </MenuItem>
          <MenuItem id='rename-folder-menu-item' onClick={() => renameFolder()}>
            {Strings.rename_folder}
          </MenuItem>
          <MenuItem id='delete-folder-menu-item' onClick={(_e) => deleteFolderSelected(folder)}>
            {Strings.delete_folder}
          </MenuItem>
        </Menu>
        {renderOverlay(tutorialData.flags.showDeleteFolderMenuIcon ? 'hide-overlay' : '')}
      </div>
    );
  };

  const createNewProject = () => {
    if (tutorialData.flags.showDeleteFolderMenuIcon) {
      alert.error('Please select Delete Folder to continue.');
      return;
    }
  };

  const renameFolder = () => {
    if (tutorialData.flags.showDeleteFolderMenuIcon) {
      alert.error('Please select Delete Folder to continue.');
      return;
    }
  };

  const keyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetRenameFolder();
    }
  };

  const resetRenameFolder = () => {
    setRenameFolderId(null);
    setRenameFolderValue('');

    document.removeEventListener('keydown', keyDown, false);
  };

  const setNewFolderName = async (id: string, name: string) => {
    resetRenameFolder();

    // Update name in redux
    setFolderName(id, name);

    // Call api to update in backend
    const { success } = await updateFolderName(id, name);

    if (!success) getFoldersForUser(getUserId());
  };

  const deleteFolderSelected = (folder: FolderType) => {
    if (tutorialData.flags.showDeleteFolderMenuIcon) {
      hideUserTutorial(true);

      setDeleteFolderValue(folder.name ? folder.name : '');

      setConfirmationPopup(true);
    }
  };

  const deleteFolder = async () => {
    addFolders([]);
    hideUserTutorial(false);
    updateFlag('deleteFolderCompletion', true);
    setConfirmationPopup(false);
  };

  const renderFolderName = (folder: FolderType) => {
    if (folder.id === renameFolderId) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setNewFolderName(folder.id, renameFolderValue);
          }}
        >
          <span className='rename-folder-input-con'>
            <input
              className='rename-input'
              value={renameFolderValue}
              onChange={(e) => setRenameFolderValue((e.target as HTMLInputElement).value)}
              placeholder='Folder name'
            />
          </span>
          {renderOverlay()}
        </form>
      );
    }

    return (
      <span className='folder-name' onClick={() => setFolderOpenStatus(folder.id, !folder.isOpen)}>
        {folder.name}
        {renderOverlay()}
      </span>
    );
  };

  const showAddFolder = () => {
    hideLeftSidebar();
    updateFlag('showCreateNewFolderModal', true);
    setCreateFolderModalStatus(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderTeamMember = (member: UserFieldType, index: number) => {
    if (member.id === userId) return null;

    return (
      <div
        id={`team-member-name-${index}`}
        key={member.id}
        className={`section-in team-member ${isSelectedMember(member.id) ? 'selected' : ''}`}
      >
        {member.display}
        {renderOverlay()}
      </div>
    );
  };

  const showInviteMembersModal = () => {
    hideLeftSidebar();
    updateFlag('showInviteMemberModal', true);
    setInviteMembersModalStatus(true);
  };

  const renderAddTeam = () => {
    // if (!isAdmin()) return null;

    return (
      <div id='add-item-con' className='section-in add-item' key='add-team-member'>
        <span onClick={() => showInviteMembersModal()}>
          <FontAwesomeIcon id='plus-icon-add-item' className='plus-icon' icon={faPlus} size='sm' />
        </span>
        {renderOverlay(tutorialData.flags.showAddMemberIcon ? 'hide-overlay' : '')}
      </div>
    );
  };

  const renderAddProject = () => {
    // if (!isAdmin()) return null;

    return (
      <div
        id='add-project-con'
        className='section-in add-item'
        key='add-project'
        style={{ position: 'relative' }}
      >
        <span onClick={() => showAddProjectModal(null)}>
          <FontAwesomeIcon
            id='plus-icon-add-project'
            className='plus-icon'
            icon={faPlus}
            size='sm'
          />
          {/* {Strings.add_project} */}
        </span>
        {renderOverlay(tutorialData.flags.showAddProjectIcon ? 'hide-overlay' : '')}
      </div>
    );
  };

  const renderMyTeam = () => {
    return (
      <div id='left-bar-team-section' className='section-con' key='render-my-team'>
        <div className='main-heading-con'>
          <h6 id='left-bar-my-team-heading-con' className='main-heading team'>
            <span className='leftmenu-heading-tutorial'>
              {Strings.team}
              {renderOverlay()}
            </span>
            {renderAddTeam()}
          </h6>
        </div>
        <div id='team-list-con' className='section-in-con'>
          {tutorialData &&
            tutorialData.allUsers.map((project, index) => renderTeamMember(project, index))}
        </div>
      </div>
    );
  };

  return (
    <div
      id='tutorial-left-bar-container'
      className={`tutorial-left-bar-container ${navigationData.hideLeftSidebar ? 'hide' : ''} ${
        navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
      }`}
    >
      {/*  ${isThemesOrShopScreen(navigationData.currentRoute) ? "shop-visible" : ""} */}
      {renderTasks()}
      {/* {renderMyTasks()} */}
      {/* {renderMeetings()} */}
      {renderMyProjects()}
      {renderMyTeam()}
      {renderChangeLog()}
      {renderOverlay()}
      <Suspense fallback={null}>
        {showConfirmationPopup ? (
          <ConfirmationPopup
            title={`${Strings.delete_folder_confirm_text} "${deleteFolderValue}"?`}
            noBtnTitle={Strings.cancel}
            yesBtnTitle={Strings.confirm}
            closeModal={() => alert.error('Please select confirm to continue.')}
            yesClicked={deleteFolder}
            isLoading={false}
          />
        ) : null}
      </Suspense>
    </div>
  );
}
