import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  getFolders as getFoldersFromAPI,
  archiveProject as archiveProjectFromProjectAPI,
  addMemberstoProject,
  removeMembersFromProject as removeMembersFromProjectAPI,
  getUserProjects as getUserProjectsFromAPI,
  getProjectNameFormAPI,
  deleteFormAPI,
  createFormAPI,
} from 'services/ProjectService';
import { parseFolders, parseUserProjects } from 'utils/task';
import {
  allProjectsState,
  foldersState,
  projectDataSelector,
  projectFormErrorState,
  projectFormNameState,
  projectErrorState,
  projectFormLoadState,
} from '.';
import { removeItem } from 'utils/storage';
import { allUserProjectsState } from 'recoil/UserState';

export async function getFoldersForUser(userId: string) {
  const response = await getFoldersFromAPI(userId);
  if (response && response.data && response.success) {
    const { folders, projects } = parseFolders(response.data);
    addFolders(folders);
    addAllProjects(projects);
    removeItem('addedProject');
  }
}

export function addTaskFetchError(error: string | null) {
  setRecoil(projectErrorState, error);
}

export function closeAllProjects() {
  const projects = getRecoil(allProjectsState);
  const tempData: ProjectObjectType[] = projects
    ? projects.map((data) => {
        return { ...data, isOpen: false };
      })
    : [];
  setRecoil(allProjectsState, tempData);
}

export function closeAllFolders() {
  const folders = getRecoil(foldersState);
  const tempData: FolderType[] = [...folders].map((folder) => {
    if (folder.isFolder) {
      return {
        ...folder,
        isOpen: false,
      };
    } else return folder;
  });
  setRecoil(foldersState, tempData);
}

export function addFolders(folders: FolderType[]) {
  setRecoil(foldersState, folders);
}

export function addAllProjects(projects: ProjectObjectType[]) {
  setRecoil(allProjectsState, projects);
}

export function setFolderOpenStatus(folderId: string, status: boolean) {
  const folders = getRecoil(foldersState);
  const tempData = folders.map((folder) => {
    if (folder.id === folderId) {
      return {
        ...folder,
        isOpen: status,
      };
    }
    return folder;
  });

  setRecoil(foldersState, tempData);
}

export function setFolderName(folderId: string, folderName: string) {
  const folders = getRecoil(foldersState);
  const tempData = folders.map((folder) => {
    if (folder.id === folderId) {
      return {
        ...folder,
        name: folderName,
      };
    }
    return folder;
  });
  setRecoil(foldersState, tempData);
}

export function setProjectName(projectId: string, projectName: string) {
  const folders = getRecoil(foldersState);
  let isFolder = false;
  let tempData = folders.map((folder) => {
    if (folder.id === projectId) {
      isFolder = true;
      return {
        ...folder,
        name: projectName,
      };
    }
    return folder;
  });
  if (!isFolder) {
    tempData = folders.map((folder) => {
      if (folder.isFolder) {
        return {
          ...folder,
          projects: folder.projects?.map((project) => {
            if (project.id === projectId) {
              return {
                ...project,
                display: projectName,
              };
            }
            return project;
          }),
        };
      }
      return folder;
    });
  }
  setRecoil(foldersState, tempData);
}

export async function archiveProject(projectId: string, archive: boolean, userId: string) {
  const { success } = await archiveProjectFromProjectAPI(projectId, archive);

  if (success) {
    getFoldersForUser(userId);
  }
}

export async function shareProject(projectId: string, userId: string) {
  const { success } = await createFormAPI({ projectId: projectId });

  if (success) {
    getFoldersForUser(userId);
  }
}

export async function deleteProjectForm(userId: string, formId: string) {
  const { success } = await deleteFormAPI(formId);

  if (success) {
    getFoldersForUser(userId);
  }
}

export async function addProjectMember(projectId: string, member: UserFieldType) {
  const projectData = getRecoil(projectDataSelector);
  const { allProjects } = projectData;

  if (allProjects === null) return;
  const projectIndex = allProjects.findIndex((item) => item.id === projectId);
  if (projectIndex === -1) return;
  member = { ...member, isLoading: true };
  let tempData: ProjectObjectType[] = allProjects.map((proj, ind) => {
    if (ind === projectIndex) {
      if (proj.users) {
        return {
          ...proj,
          users: [...proj.users, member],
        };
      } else {
        return {
          ...proj,
          users: [member],
        };
      }
    }
    return proj;
  });
  addAllProjects(tempData);

  const { success } = await addMemberstoProject(projectId, [member.id]);

  if (!success && projectData.allProjects) {
    tempData = removeMemberFromProject(projectData.allProjects, projectId, member.id);

    addAllProjects(tempData);
  } else {
    setProjectMemberLoadingState(projectId, member.id, false);
  }
}

export function setProjectMemberLoadingState(projectId: string, memberId: string, status: boolean) {
  const projectData = getRecoil(projectDataSelector);
  const { allProjects } = projectData;
  if (allProjects === null) return;
  const tempData = allProjects.map((proj) => {
    if (proj.users && proj.id === projectId) {
      return {
        ...proj,
        users: proj.users.map((mem) => {
          if (mem.id === memberId) {
            return {
              ...mem,
              isLoading: status,
            };
          }
          return mem;
        }),
      };
    }
    return proj;
  });
  addAllProjects(tempData);
}

export const removeDeletedMemberFromProject = (
  allProjects: ProjectObjectType[],
  projectId: string,
  memberId: string,
) => {
  const projectIndex = allProjects.findIndex((item) => item.id === projectId);
  if (projectIndex === -1) return;

  const project = allProjects[projectIndex];

  const index = project.users?.findIndex((item) => item.id === memberId) ?? -1;
  if (index === -1 || project.users === undefined) return;

  const tempProject = {
    ...project,
    users: project.users.filter((item) => item.id !== memberId),
  };

  const tempAllProjects = allProjects.map((proj, ind) => {
    if (ind === projectIndex) return tempProject;
    return proj;
  });
  addAllProjects(tempAllProjects);
};

const removeMemberFromProject = (
  allProjects: ProjectObjectType[],
  projectId: string,
  memberId: string,
) => {
  const projectIndex = allProjects.findIndex((item) => item.id === projectId);
  if (projectIndex === -1) return allProjects;

  const project = allProjects[projectIndex];

  const index = project.users?.findIndex((item) => item.id === memberId) ?? -1;
  if (index === -1 || project.users === undefined) return allProjects;

  const tempProject = {
    ...project,
    users: project.users.filter((item) => item.id !== memberId),
  };

  const tempAllProjects = allProjects.map((proj, ind) => {
    if (ind === projectIndex) return tempProject;
    return proj;
  });
  return tempAllProjects;
};

export async function removeProjectMember(projectId: string, memberId: string) {
  setProjectMemberLoadingState(projectId, memberId, true);

  const { success } = await removeMembersFromProjectAPI(projectId, [memberId]);

  if (!success) {
    setProjectMemberLoadingState(projectId, memberId, false);
  } else {
    const allProjects = getRecoil(allProjectsState);
    if (allProjects === null) return;
    const tempData = removeMemberFromProject(allProjects, projectId, memberId);

    addAllProjects(tempData);
  }
}

export async function updateProjectCounters(
  projectId: string,
  focusCount: number,
  completedCount: number,
  focusPoints: number,
  backlogPoints: number,
  remainingPoints: number,
  completedPoints: number,
) {
  const allProjects = getRecoil(allProjectsState);
  if (allProjects === null) return;
  const tempData = allProjects.map((item) => {
    if (item.id === projectId)
      return {
        ...item,
        focusCount,
        completedCount,
        focusPoints,
        backlogPoints,
        remainingPoints,
        completedPoints,
      };
    return item;
  });
  setRecoil(allProjectsState, tempData);
}

export async function getMemberProjects(userId: string) {
  const { data, success } = await getUserProjectsFromAPI(userId);
  if (success) {
    setRecoil(allUserProjectsState, parseUserProjects(data));
  }
}

export async function getProjectFormName(formId: string) {
  setRecoil(projectFormLoadState, true);
  const { data, success, message } = await getProjectNameFormAPI(formId);
  setRecoil(projectFormLoadState, false);
  if (success) {
    setRecoil(projectFormNameState, data);
    setRecoil(projectFormErrorState, undefined);
  } else {
    setRecoil(projectFormNameState, undefined);
    setRecoil(projectFormErrorState, message);
  }
}
