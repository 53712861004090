import { useEffect, Suspense, Fragment } from 'preact/compat';
import { lazyWithRetry } from 'utils/helpers';
import { getThemeSlug, loadTheme } from 'utils/theme';
import { setPageTitle, log } from 'utils';
import { Navigate, Outlet } from 'react-router-dom';
import TopBar from 'components/TopBar';
import SuperAdminLeftBar from 'components/SuperAdminLeftBar';
import {
  isSuperAdmin,
  getUserId,
  getUserWorkspaceId,
  // getUserEmail,
  // getUserName,
  reFetchUserSession,
} from 'services/AuthService';
// import rg4js from 'raygun4js';
import { open, close } from 'websocket/account';
import { useRecoilValue } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import { navigationDataSelector } from 'recoil/NavigationState';
import { getAllThemes, getNewNotifications, getPurchasedThemes } from 'recoil/AccountState/update';

const UserProfile = lazyWithRetry(() => import('modals/UserProfile'));
const Search = lazyWithRetry(() => import('modals/Search'));
const WorkspaceSwitch = lazyWithRetry(() => import('components/WorkspaceSwitch'));
const Feedback = lazyWithRetry(() => import('modals/Feedback'));
const CreateWorkspace = lazyWithRetry(() => import('modals/CreateWorkspace'));

type SuperAdminLayoutPropsType = {
  pageTitle?: string;
  stateType: StateType;
  path: string;
};
export default function SuperAdminLayout(props: SuperAdminLayoutPropsType) {
  const accountData = useRecoilValue(accountDataSelector);
  const userId = getUserId();
  const userWorkspaceId = getUserWorkspaceId();

  useEffect(() => {
    loadTheme(true);
  }, []);

  useEffect(() => {
    open();
    return () => {
      close();
    };
  }, [userWorkspaceId, userId]);

  useEffect(() => {
    if (accountData.allThemes.length > 0) {
      accountData.allThemes.forEach(async (element) => {
        try {
          await import(`../../assets/${getThemeSlug(element.name)}/styles/theme-card-front.css`);
        } catch {
          log('css file not found');
        }
        try {
          await import(`../../assets/${getThemeSlug(element.name)}/styles/theme-card-back.css`);
        } catch {
          log('css file not found');
        }
      });
    }
  }, [accountData.allThemes]);

  const navigationData = useRecoilValue(navigationDataSelector);

  useEffect(() => {
    // rg4js('setUser', {
    //   identifier: getUserId(),
    //   isAnonymous: false,
    //   email: getUserEmail(),
    //   fullName: getUserName(),
    // });

    // sending userid for google analytics
    if (document.location.origin === 'https://app.magictask.io') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: getUserId(),
      });
    }
    getInitialData();
  }, []);

  const getInitialData = async () => {
    reFetchUserSession();

    getNewNotifications('Un-read', 0);

    getAllThemes();
    getPurchasedThemes();
  };

  // component did mount
  useEffect(() => {
    if (props.pageTitle) setPageTitle(props.pageTitle);

    // rg4js('trackEvent', { type: 'pageView', path: '/' + window.location.pathname });
  }, [props.pageTitle]);

  const haveAccess = isSuperAdmin();

  return (
    <Fragment>
      {haveAccess ? (
        <div className='super-admin-layout-container' id='super-admin-layout-container'>
          <TopBar currentRoute={props.path} />
          <SuperAdminLeftBar />
          <Suspense fallback={null}>
            <WorkspaceSwitch />
          </Suspense>
          <Suspense fallback={null}>
            {navigationData.showUserProfileModal ? <UserProfile /> : null}
          </Suspense>
          <Suspense fallback={null}>{navigationData.showSearchModal ? <Search /> : null}</Suspense>
          <Suspense fallback={null}>
            {navigationData.showFeedbackModal ? <Feedback /> : null}
          </Suspense>
          <Suspense fallback={null}>
            {navigationData.showCreateWorkspaceModal ? <CreateWorkspace /> : null}
          </Suspense>
          <div id='main-page-container-super-admin' className='main-page-container'>
            {/** @ts-ignore */}
            <Outlet context={props} />
          </div>
        </div>
      ) : (
        <Navigate to='/' />
      )}
    </Fragment>
  );
}
