import { memo, forwardRef } from 'preact/compat';
import moment from 'moment';
import { formatDateForView, currentDay } from 'utils/task';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type DueDateSelectionPropsType = {
  tempId: string;
  dataType: TaskType;
  itemDueDate: Date | null | undefined;
  readOnly: boolean | undefined;
  showDueDate: boolean;
  isDisabled: boolean;
  onChangeDueDate: (date: Date | null) => void;
  overlayClass?: string;
  stateType?: StateType;
};

function DueDateSelection(props: DueDateSelectionPropsType) {
  const {
    tempId,
    dataType,
    itemDueDate,
    readOnly,
    showDueDate,
    isDisabled,
    onChangeDueDate,
    overlayClass,
    stateType,
  } = props;

  // const dueDateRef = useRef(null);
  // const skipDueDateClick = useRef(false);
  // const [isDueDateOpen, setDueDateOpen] = useState(false);
  /*
   * Get due date status
   *
   * dueDate Date object
   *
   * returns String
   */
  const getDueDateStatus = (dueDate: Date) => {
    const tempDate = moment(dueDate);
    const current = moment(currentDay());
    const diff = tempDate.startOf('day').diff(current.startOf('day'), 'days', true);

    if (diff === 0) return 'due-today';
    else if (diff < 0) return 'off-track';

    return 'on-track';
  };

  const updateDueDate = (date: Date | null) => {
    // setDueDateOpen(false);
    onChangeDueDate(date);
  };

  /*
   * Render date
   *
   * returns React.DOM
   */
  // eslint-disable-next-line
  interface RenderDateProps {
    value?: string | null;
    onClick?: () => void;
  }

  const RenderDate = forwardRef<HTMLElement, RenderDateProps>(({ value, onClick }, ref) => (
    <span
      className={`item-btn due-date-btn ${
        !itemDueDate ? 'no-date' : dataType === 'FOCUS' ? getDueDateStatus(itemDueDate) : ''
      }`}
      onClick={onClick}
      ref={ref}
    >
      {value ? `Due date: ${formatDateForView(value)}` : 'No due date'}
    </span>
  ));

  // log('render components/TaskItems/IndividualItem/DueDateSelection');

  if (!showDueDate) return null;
  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };
  return (
    <div className='item-container due-date-container' key={`due-date-con-${tempId}`}>
      <div disabled={isDisabled}>
        <DatePicker
          isClearable={!isDisabled}
          selected={itemDueDate}
          onChange={(date) => updateDueDate(date)}
          id={tempId}
          minDate={new Date()}
          disabled={isDisabled || readOnly}
          popperPlacement='bottom-end'
          calendarClassName='date-range-calendar-con'
          customInput={<RenderDate />}
        />
      </div>
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps, nextProps) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.dataType !== nextProps.dataType) return false;

  if (prevProps.itemDueDate !== nextProps.itemDueDate) return false;

  if (prevProps.readOnly !== nextProps.readOnly) return false;

  if (prevProps.showDueDate !== nextProps.showDueDate) return false;

  if (prevProps.isDisabled !== nextProps.isDisabled) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  return true;
}

export default memo(DueDateSelection, areEqual);
