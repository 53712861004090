import { useState, useEffect, memo, useRef, Fragment } from 'preact/compat';
import { KeyboardEvent, ChangeEvent } from 'react';
import Linkify from 'linkify-react';
import getCaretCoordinates from 'textarea-caret';
import TextareaAutosize from 'react-textarea-autosize';
import Menu from '../../../TaskItems/IndividualItem/InputBox/Menu';
import { log } from 'utils';
import { hasUpdated } from 'utils/helpers';
import { useAlert } from 'react-alert';
import { BACKSPACE_KEY_CODE, ENTER_KEY_CODE, TAB_KEY_CODE } from 'utils/task';

interface TutorialInputBoxPropsInterface extends InputBoxPropsType {
  overlayClass?: string;
  isCreateSubTask?: boolean;
  isDeleteTask?: boolean;
  isSubTaskCreationCompletion?: boolean;
  isUpdateTask?: boolean;
  isInputAssignee?: boolean;
  focusEvent?: (e: any) => void;
  isInputProject?: boolean;
  isInputTags?: boolean;
  isInputSize?: boolean;
  isInputProjectTaskAssignee?: boolean;
  updateTutorialAssignee: (assignee: string | null, isAssigneeInput?: boolean) => void;
}

function TutorialInputBox(props: TutorialInputBoxPropsInterface) {
  const alert = useAlert();

  const {
    index,
    itemId,
    tempId,
    placeholder,
    isDisabled,
    parentId,
    parentIndex,
    addNewTask,
    addNewTaskTopBar,
    deleteTheTask,
    itemValue,
    updateValue,
    dataType,
    valueUpdated,
    storeInputRefs,
    sizesData,
    usersData,
    projectsData,
    tagsData,
    updateSize,
    updateProject,
    updateTutorialAssignee,
    setTaskTags,
    itemProject,
    itemAssignee,
    spellChecker,
    forTopBar,
    maxRows,
    overlayClass,
    isCreateSubTask,
    isDeleteTask,
    isSubTaskCreationCompletion,
    isUpdateTask,
    isInputAssignee,
    focusEvent,
    isInputProject,
    isInputTags,
    isInputSize,
    isInputProjectTaskAssignee,
  } = props;

  // log('render components/TutorialTaskItems/IndividualItem/TutorialInputBox', itemValue);

  const allSigns = ['@', '!', '$', '#'];
  const allData = {
    '@': usersData,
    '!': parentId ? [] : projectsData, // Show project data only for tasks
    $: sizesData,
    '#': tagsData,
  };

  const [value, setValue] = useState('');
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 220,
    left: 0,
  });
  const [showMenu, setShowMenu] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [lastSign, setLastSign] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [height, setHeight] = useState(18);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  /*
   * Item Value Hook
   */
  useEffect(() => {
    setValue(itemValue);
  }, [updateValue]);

  useEffect(() => {
    if (inputRef && inputRef.current) storeInputRefs(dataType + tempId, inputRef.current);
  }, [inputRef]);

  const textarea = () => {
    return (
      <Fragment>
        {/** @ts-ignore */}
        <TextareaAutosize
          id={`textarea-${tempId}`}
          onFocus={(e) => onFocus(e)}
          onHeightChange={(newHeight) => {
            setHeight(newHeight);
          }}
          ref={inputRef}
          className={`text-area ${parentId ? 'subtask' : 'task'} ${!forTopBar ? 'hide-text' : ''}`}
          maxRows={maxRows}
          placeholder={placeholder}
          spellCheck={spellChecker}
          readOnly={isDisabled}
          value={value}
          onBlur={() => onBlur()}
          onClick={(e) => linkClicked((e.target as HTMLTextAreaElement).selectionStart)}
          onChange={(e) => onChange(e as ChangeEvent<HTMLTextAreaElement>)}
          onKeyDown={(e) => onKeyDown(e)}
        />
      </Fragment>
    );
  };

  const onFocus = (e: any) => {
    if (forTopBar && focusEvent) focusEvent(e);
  };

  const onBlur = () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 200);
  };

  const blurInput = () => {
    inputRef && inputRef.current && inputRef.current.blur();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    log('onKeyDown');

    if ((e.target as HTMLTextAreaElement).selectionEnd !== cursorPosition) {
      log('on change in');
      onChange(e);
    }

    if (e.key === ENTER_KEY_CODE) {
      blurInput();
      return enterPressed(e);
    } else if (e.key === TAB_KEY_CODE) return tabPressed(e);
    else if (e.key === BACKSPACE_KEY_CODE) return deletePressed(e);
  };

  const enterPressed = (e: KeyboardEvent) => {
    onChange(e);
    e.preventDefault();

    const newValue = (e.target as HTMLTextAreaElement).value;
    const newCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;

    const startText = value.substring(0, newCursorPosition);
    const lastIndexTag = startText.lastIndexOf('#');

    if (lastIndexTag !== -1 && selectedItemIndex < 0) {
      const lastText = startText.substring(lastIndexTag);

      if (lastText.length > 1) {
        const tagFound = lastText.substring(1);

        createNewTag(tagFound);
        clearText(newValue, newCursorPosition);

        return;
      }
    }
    // Show menu
    else if (showMenu) {
      const menu = selectedData[selectedItemIndex];

      if (menu) menuSelected(menu);
    } else {
      if (isDisabled) return;

      if (forTopBar && addNewTaskTopBar) addNewTaskTopBar(newValue);
    }
  };

  const createNewTag = async (name: string) => {
    if (doesTagAlreadyExist(name)) return;

    setTimeout(() => {
      setTaskTags('tagid1', name);
    }, 5);
  };

  const doesTagAlreadyExist = (name: string) => {
    const index =
      tagsData && tagsData.findIndex((tag) => tag.name.toLowerCase() === name.toLowerCase());

    if (index === -1 || tagsData === undefined || index === undefined) return false;

    const item = tagsData[index];
    setTaskTags(item.id, item.name);

    return true;
  };

  const tabPressed = (e: KeyboardEvent) => {
    if (!isCreateSubTask) return;

    onChange(e);

    if (isCreateSubTask) {
      if (e.key === TAB_KEY_CODE) {
        if (addNewTask) {
          if (!parentId)
            addNewTask(0, index, itemId, false); // , 'My first sub-task');
          else addNewTask(index + 1, parentIndex, parentId, false); // , 'My first sub-task');
        }
      }
    }
    e.preventDefault();
  };

  const deletePressed = (e: KeyboardEvent) => {
    if (!isDeleteTask) return;

    const currentValue = (e.target as HTMLTextAreaElement).value;
    const currentCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;

    if (currentValue.trim() === '' && currentCursorPosition === 0 && isDeleteTask) {
      deleteTheTask && deleteTheTask();
      e.preventDefault();
    }
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement> | KeyboardEvent) => {
    log('onChange', (e.target as HTMLTextAreaElement).value.toLowerCase().trim());

    if (isCreateSubTask) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }

    if (isDeleteTask && (e.target as HTMLTextAreaElement).value > value) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }

    if (
      isInputAssignee &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to change assignee using hotkeys@' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to change assignee using hotkeys' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim().slice(-1) !== '@'
    ) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }

    if (
      isInputProjectTaskAssignee &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !== 'my first task@' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !== 'my first task' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim().slice(-1) !== '@'
    ) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }

    if (
      isInputProject &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to change projects using hotkeys!' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to change projects using hotkeys' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim().slice(-1) !== '!'
    ) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }
    if (
      isInputTags &&
      (e.target as HTMLTextAreaElement).value.toLowerCase() !== 'learn how to create tags' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase() !== 'learn how to create tags ' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase() !== 'learn how to create tags #' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #h' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #hi' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #hig' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase() !== 'learn how to create tags #high' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase() !== 'learn how to create tags #high ' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high p' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high pr' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high pri' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high prio' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high prior' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high priori' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to create tags #high priorit' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase() !==
        'learn how to create tags #high priority'
    ) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }

    if (
      isInputSize &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to change task size using hotkeys$' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim() !==
        'learn how to change task size using hotkeys' &&
      (e.target as HTMLTextAreaElement).value.toLowerCase().trim().slice(-1) !== '$'
    ) {
      (e.target as HTMLTextAreaElement).value = value;
      return;
    }

    const newValue = (e.target as HTMLTextAreaElement).value;
    const newCursorPosition = (e.target as HTMLTextAreaElement).selectionEnd;

    setValue(newValue);
    valueUpdated(newValue);
    setCursorPosition(newCursorPosition);

    const show =
      forTopBar ||
      isSubTaskCreationCompletion ||
      isUpdateTask ||
      isDeleteTask ||
      isCreateSubTask ||
      isInputTags
        ? false
        : canShowMenu(newValue, newCursorPosition);
    setShowMenu(show);

    if (show) {
      updateContextMenuPosition(e as KeyboardEvent);
    }
  };

  const canShowMenu = (newValue: string, newCursorPosition: number) => {
    const startText = newValue.substring(0, newCursorPosition);
    let allValues: number[] = [];
    if (
      isInputAssignee ||
      isInputProject ||
      isInputTags ||
      isInputSize ||
      isInputProjectTaskAssignee
    ) {
      const lastIndexAssignee = startText.lastIndexOf('@');
      const lastIndexProject = startText.lastIndexOf('!');
      const lastIndexSize = startText.lastIndexOf('$');
      const lastIndexTag = startText.lastIndexOf('#');
      allValues = [lastIndexAssignee, lastIndexProject, lastIndexSize, lastIndexTag];
    }

    const maxVal = Math.max(...allValues);
    const signIndex = allValues.indexOf(maxVal);
    const selectedIndex = allValues[signIndex];

    if (signIndex === -1 || maxVal === -1) return false;

    const selectedSign = allSigns[signIndex];

    setLastSign(selectedSign);

    const data = allData[selectedSign];

    const searchText = startText
      .substring(selectedIndex + 1)
      .toString()
      .toLowerCase();

    let filteredData = data.filter((dat) =>
      dat.name.toLowerCase().startsWith(searchText.toLowerCase()),
    );

    filteredData = addRemoveItem(filteredData, selectedSign);

    if (filteredData.length === 0) return false;

    setSelectedData(filteredData);
    setSelectedItemIndex(selectedSign === '#' ? -1 : 0);

    return true;
  };

  const addRemoveItem = (filteredData: FilterDropdownObjectType[], selectedSign: string) => {
    if (
      (selectedSign === '!' && forTopBar && itemProject && itemProject.id) ||
      (selectedSign === '!' && itemProject && itemProject.id && itemAssignee && itemAssignee.id)
    )
      filteredData = [...filteredData, { id: 'REMOVE', name: 'Remove Project' }];

    if (
      (selectedSign === '@' && forTopBar && itemAssignee && itemAssignee.id) ||
      (selectedSign === '@' && itemAssignee && itemAssignee.id && itemProject && itemProject.id)
    )
      filteredData = [...filteredData, { id: 'REMOVE', name: 'Remove Assignee' }];

    return filteredData;
  };

  const updateContextMenuPosition = (e: KeyboardEvent) => {
    const caret = getCaretCoordinates(e.target, (e.target as HTMLTextAreaElement).selectionEnd);

    setContextMenuPosition({ top: caret.top + 16, left: caret.left });
  };

  const linkClicked = (position: number) => {
    // eslint-disable-next-line no-useless-escape
    const expression =
      /((https?:\/\/)?(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    const matches = value.match(expression);

    matches &&
      matches.forEach((match) => {
        const start = value.indexOf(match);
        const end = start + match.length;

        if (position > start && position < end) {
          if (!match.startsWith('http')) match = `http://${match}`;

          window.open(match, '_blank', 'noopener,noreferrer');
        }
      });
  };

  const menuSelected = (
    item: SizeObjectType | UserFieldType | string | TagObjectType | ProjectObjectType,
  ) => {
    if (isInputAssignee && lastSign === '@' && (item as UserFieldType).id !== '01') {
      alert.error('Please select Sudo Innominate from the options');
      return;
    } else if (isInputProject && lastSign === '!' && (item as ProjectObjectType).id !== '02') {
      alert.error('Please select MagicTask Tutorial from the options');
      return;
    } else if (isInputSize && lastSign === '$' && (item as SizeObjectType).id !== 'L') {
      alert.error("Please select 'L' from the options");
      return;
    }

    log('menuSelected', item, value);

    setShowMenu(false);
    clearText();

    switch (lastSign) {
      case '$':
        updateSize((item as SizeObjectType).id);
        break;
      case '@':
        updateTutorialAssignee((item as UserFieldType).id, isInputProjectTaskAssignee);
        break;
      case '!':
        updateProject((item as ProjectObjectType).id);
        break;
      case '#':
        setTaskTags((item as TagObjectType).id, (item as TagObjectType).name);
        break;
      default:
    }
  };

  const clearText = (newValue?: string, newCursorPosition?: number) => {
    const currentValue = newValue ? newValue : value;
    const currentCursorPosition = newCursorPosition ? newCursorPosition : cursorPosition;

    let newTextStart = currentValue.substring(0, currentCursorPosition).trimRight();
    const lastIndex = newTextStart.lastIndexOf(lastSign);

    newTextStart = newTextStart.substring(0, lastIndex).trimRight();

    const newTextEnd = currentValue.substring(currentCursorPosition).trimLeft();

    let newText;
    if (newTextEnd === '') newText = newTextStart;
    else newText = newTextStart + ' ' + newTextEnd;
    if (isInputTags) {
      newText = 'Learn how to create tags';
    }
    setValue(newText);
    valueUpdated(newText);

    inputRef && inputRef.current && inputRef.current.focus();

    setTimeout(() => {
      inputRef &&
        inputRef.current &&
        inputRef.current.setSelectionRange(newTextStart.length, newTextStart.length);
    }, 0);
  };

  const renderContextMenu = () => {
    if (!showMenu) return null;

    return (
      <Menu
        forTopBar={forTopBar}
        onClick={(item) => menuSelected(item)}
        selectedItemIndex={selectedItemIndex}
        goUp={() => goUp()}
        goDown={() => goDown()}
        setSelectedItem={(index) => setSelectedItem(index)}
        contextMenuPosition={contextMenuPosition}
        data={selectedData}
      />
    );
  };

  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };

  const setSelectedItem = (index: number) => {
    setSelectedItemIndex(index);
  };

  const goUp = () => {
    setSelectedItemIndex((index) => {
      return index === 0 ? selectedData.length - 1 : index - 1;
    });
  };

  const goDown = () => {
    setSelectedItemIndex((index) => {
      return index === selectedData.length - 1 ? 0 : index + 1;
    });
  };

  const renderLinkifyDiv = () => {
    if (forTopBar) return null;

    return (
      <Fragment>
        {/** @ts-ignore */}
        <Linkify tagName='div' style={{ height: height }} className='content-div'>
          {value}
        </Linkify>
      </Fragment>
    );
  };

  return (
    <div className='input-box-con input-box-con-tutorial' style={{ height }}>
      {textarea()}
      {renderLinkifyDiv()}
      {renderContextMenu()}
      {renderOverlay()}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(
  prevProps: TutorialInputBoxPropsInterface,
  nextProps: TutorialInputBoxPropsInterface,
) {
  const fields = [
    { name: 'itemId', type: 'prime' },
    { name: 'index', type: 'prime' },
    { name: 'parentId', type: 'prime' },
    { name: 'parentIndex', type: 'prime' },
    { name: 'isDisabled', type: 'prime' },
    { name: 'spellChecker', type: 'prime' },
    { name: 'updateValue', type: 'prime' },
    { name: 'itemSize', type: 'prime' },
    { name: 'itemProject', type: 'object' },
    { name: 'itemAssignee', type: 'object' },
    { name: 'itemTags', type: 'object' },
    { name: 'usersData', type: 'object' },
    { name: 'projectsData', type: 'object' },
    { name: 'sizesData', type: 'object' },
    { name: 'tagsData', type: 'object' },
    { name: 'stateType', type: 'prime' },
    { name: 'itemTemplate', type: 'prime' },
    { name: 'overlayClass', type: 'prime' },
  ];
  return hasUpdated(prevProps, nextProps, fields);
}

export default memo(TutorialInputBox, areEqual);
