export const dateFilterData: { id: DateFilterType | 'allTime'; name: string }[] = [
  { id: 'today', name: 'Today' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'currentMonth', name: 'Current Month' },
  { id: 'lastMonth', name: 'Last Month' },
  { id: 'yearToDate', name: 'Year to Date' },
  { id: 'previousYear', name: 'Previous Year' },
  { id: 'allTime', name: 'All Time' },
];

export const monthlyDateFilterData: { id: TimePeriodFilterType; name: string }[] = [
  { id: 'last6Months', name: 'Last 6 Months' },
  { id: 'last12Months', name: 'Last 12 Months' },
];
