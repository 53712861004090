import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/**
 * Get user folders
 * @param {number} userId
 *
 * returns Object {data, error, status}
 */
export async function getFolders(userId: string) {
  const url = `${apiConfig.projects.get_user_folders}/${userId}`;
  try {
    const response = await appClient.get(url);
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (error !== 'canceled') {
      return hasError(error);
    }
  }
}

/*
 * Create new project
 * payload Object
 *
 * returns Object {data, error, status}
 */
export async function createNewProject(payload) {
  try {
    const response = await appClient.post(apiConfig.projects.create_new_project, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Create New Folder
 * @param {boolean} isArchived
 * @param {string} id
 *
 * returns Object {data, error, status}
 */
export async function deleteFolder(id: string) {
  try {
    const url = `${apiConfig.projects.delete_folder}/${id}`;
    const response = await appClient.delete(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Set folder open status
 * folderId String
 * status Boolean
 *
 * returns Object {data, error, status}
 */
export async function setFolderOpenStatus(folderId: string, status: boolean) {
  const url = `${apiConfig.projects.set_folder_open_status}/${folderId}/open/${status}`;

  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Update folder name
 * @param {string} id
 * @param {string} name
 *
 * returns Object {data, error, status}
 */
export async function updateFolderName(id: string, name: string) {
  try {
    const payload = {
      id,
      name,
    };
    const url = `${apiConfig.projects.update_folder}`;
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Update project name
 * @param {string} id
 * @param {string} name
 *
 * returns Object {data, error, status}
 */
export async function updateProjectName(id: string, name: string) {
  try {
    const payload = {
      id,
      name,
    };
    const url = `${apiConfig.projects.update_project}`;
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function createFolder(folderName: string) {
  try {
    const payload = {
      archived: false,
      name: folderName,
    };
    const url = `${apiConfig.projects.create_folder}`;
    const response = await appClient.post(url, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function updateProjectFolder(projectId: string, folderId: string | null) {
  const url = `${apiConfig.projects.update_project_folder}/${projectId}/update-folder/folder/${folderId}`;
  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function archiveProject(projectId: string, archive: boolean) {
  const url = `${apiConfig.projects.archive_project}/${projectId}/archive/${archive}`;

  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function addMemberstoProject(projectId: string, members: string[]) {
  const url = `${apiConfig.projects.add_members_to_project}/${projectId}/assign-users`;

  try {
    const response = await appClient.put(url, members);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function removeMembersFromProject(projectId, members) {
  const url = `${apiConfig.projects.remove_members_from_project}/${projectId}/un-assign-users`;

  try {
    const response = await appClient.put(url, members);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get team member's projects
 *
 * returns Object {data, error, status}
 */
export async function getUserProjects(userId: string) {
  const url = `${apiConfig.projects.get_user_projects}/${userId}`;

  try {
    const response = await appClient.get(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Create form for project task creation
 *
 * returns Object {data, error, status}
 */
export async function createFormAPI(payload: { projectId: string }) {
  try {
    const response = await appClient.post(apiConfig.projects.create_form, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Delete form for project task creation
 *
 * returns Object {data, error, status}
 */
export async function deleteFormAPI(formId: string) {
  try {
    const response = await appClient.delete(apiConfig.projects.delete_form + `/${formId}`);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function getProjectNameFormAPI(formId: string) {
  try {
    const response = await appClient.get(
      apiConfig.projects.get_project_name + '/' + formId + '/project-name',
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function createFormTaskAPI(payload: any) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await appClient.post(apiConfig.projects.create_form_task, payload, {
      headers,
      timeout: 2000000000,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
