import { Fragment, useEffect, useState } from 'preact/compat';
import { useSetRecoilState } from 'recoil';
import Spinner from 'components/UI/Spinner';
import {
  purchaseTheme as purchaseThemeAPI,
  equipTheme as equipThemeAPI,
} from 'services/ThemeService';
import { getUserId } from 'services/AuthService';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Strings } from 'resources';
import queryString from 'query-string';
import { log } from 'utils';
import { getThemeSlug, setTheme } from 'utils/theme';
import './_payment-completion.scss';
import ThemeCardFront from 'components/UI/ThemeCardFront';
import { showThemeCardModalState, themeCardModalDataState } from 'recoil/NavigationState';
import { getAllThemes, getPurchasedThemes } from 'recoil/AccountState/update';
import FaceIcon from 'assets/images/payment/face.svg';
import MTLogoIcon from 'assets/images/payment/logo.svg';

export default function PaymentCompletion() {
  const navigate = useNavigate();
  const alert = useAlert();
  const { themeId } = useParams();
  const [payStatus, setPayStatus] = useState('loading');
  const [themeData, setThemeData] = useState<ThemeInstanceInterface | null>(null);
  const location = useLocation();
  const setThemeCardModalStatus = useSetRecoilState(showThemeCardModalState);
  const setThemeCardModalData = useSetRecoilState(themeCardModalDataState);
  const equipTheme = async () => {
    const payload = {
      userId: getUserId(),
      themeId: themeId,
    };

    const { success, message } = await equipThemeAPI(payload);

    if (!success) {
      return alert.error(message);
    }

    if (themeData && themeData.theme) {
      setThemeCardModalStatus(true);
      const tempData = themeData;
      tempData.equipped = true;
      setThemeCardModalData(tempData);
      setTheme(getThemeSlug(themeData.theme.name), '/');
    } else {
      navigate('/');
    }
  };

  const loadCardStyle = async (data: ThemeInstanceInterface) => {
    if (data && data.theme && data.theme.name) {
      try {
        await import(`../../assets/${getThemeSlug(data.theme.name)}/styles/theme-card-front.css`);
      } catch {
        log('css file not found');
      }
      try {
        log(`assets/${getThemeSlug(data.theme.name)}/styles/theme-card-back.css`);
        await import(`../../assets/${getThemeSlug(data.theme.name)}/styles/theme-card-back.css`);
      } catch {
        log('css file not found');
      }
    }
  };

  const purchaseTheme = async () => {
    if (themeId !== undefined || themeId !== null) {
      const payload = {
        userId: getUserId(),
        themeId: themeId,
      };

      const { data, success } = await purchaseThemeAPI(payload);

      if (success) {
        const tempData = data;
        if (
          data.theme &&
          data.theme.pointLevels &&
          (data.theme.pointLevelsWithInactiveTriggers === null ||
            data.theme.pointLevelsWithInactiveTriggers === undefined)
        ) {
          tempData.theme.pointLevelsWithInactiveTriggers = data.theme.pointLevels;
          tempData.theme.pointLevels = null;
        }
        setThemeData(tempData);
        loadCardStyle(tempData);
        setPayStatus('success');
        getAllThemes();
        getPurchasedThemes();
      } else {
        setPayStatus('failure');
      }
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params && params['redirect_status']) {
      if (params['redirect_status'] === 'succeeded') {
        setTimeout(() => {
          purchaseTheme();
        }, 200);
        return;
      }
    }
    setPayStatus('failure');
  }, []);

  const renderLoaderScreen = () => {
    return (
      <Fragment>
        <div className='message-con'>{Strings.processing_payment}</div>
        <Spinner />
      </Fragment>
    );
  };

  const backClicked = () => {
    if (payStatus === 'success') {
      equipTheme();
      return;
    }
    navigate('/');
  };

  const renderBackButton = () => {
    return (
      <div className='back-btn-con'>
        <button
          className='back-btn'
          onClick={() => {
            backClicked();
          }}
        >
          {Strings.back_to_magictask}
        </button>
      </div>
    );
  };

  const renderFailureScreen = () => {
    return (
      <div className='failure-main-con'>
        <div className='details-con'>
          <div className='title'>{Strings.oops}</div>
          <div className='sub-title'>{Strings.something_went_wrong}</div>
          <div className='desc'>{Strings.working_to_resolve}</div>
        </div>
        <img src={FaceIcon} alt='face' />
        {renderBackButton()}
      </div>
    );
  };

  const getPaymentIntent = () => {
    const params = queryString.parse(location.search);
    if (params && params['payment_intent']) {
      return params['payment_intent'];
    }
    return '';
  };

  const renderOrderConfirmation = () => {
    const paymentIntent = getPaymentIntent();
    if (paymentIntent.length === 0) return null;
    return (
      <div className='order-con'>
        <span className='order-msg'>{Strings.order_confirm_msg}</span>
        <br />
        <br />
        <span className='order-id-text'>
          {`${Strings.order_confirm} #: `}
          <span className='order-id'>{paymentIntent}</span>
        </span>
      </div>
    );
  };

  const renderSuccessScreen = () => {
    if (themeData && themeData.theme)
      return (
        <div className='payment-success-con'>
          <div className='left-con'>
            <div className='success-msg-con'>
              <span>
                {`You have successfully purchased the `}
                <span className='theme-name'>{`${themeData.theme.name} theme!`}</span>
              </span>
            </div>
            {renderOrderConfirmation()}

            {renderBackButton()}
          </div>
          <div className='right-con'>
            <ThemeCardFront data={themeData} index={0} />
          </div>
        </div>
      );
    else return <div></div>;
  };

  return (
    <div className='payment-completion-con'>
      <div className='logo-con'>
        <img src={MTLogoIcon} alt='logo' />
      </div>
      {payStatus === 'loading'
        ? renderLoaderScreen()
        : payStatus === 'success' && themeData && themeData.theme
          ? renderSuccessScreen()
          : renderFailureScreen()}
    </div>
  );
}
