import { atom, DefaultValue, selector } from 'recoil';

export const projectFocusTaskDataState = atom<TaskObjectType[] | null>({
  key: 'projectFocusData',
  default: null,
});

export const projectFocusTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'projectFocusFilteredData',
  default: null,
});

export const projectFocusTaskLoadingState = atom<boolean>({
  key: 'projectFocusLoading',
  default: false,
});

export const projectFocusTaskAbortState = atom<AbortController>({
  key: 'projectFocusAbortController',
  default: new AbortController(),
});

export const projectFocusTaskSizeState = atom<number>({
  key: 'projectFocusTaskSize',
  default: 0,
});

export const projectFocusTasksState = selector<TaskListType>({
  key: 'projectFocus',
  get: ({ get }) => {
    const data = get(projectFocusTaskDataState);
    const filteredData = get(projectFocusTaskFilteredDataState);
    const isLoading = get(projectFocusTaskLoadingState);
    const abortController = get(projectFocusTaskAbortState);
    const size = get(projectFocusTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(projectFocusTaskDataState, value);
      set(projectFocusTaskFilteredDataState, value);
      set(projectFocusTaskLoadingState, value);
      set(projectFocusTaskAbortState, value);
      set(projectFocusTaskSizeState, value);
      return;
    }
    set(projectFocusTaskDataState, value.data);
    set(projectFocusTaskFilteredDataState, value.filteredData);
    set(projectFocusTaskLoadingState, value.isLoading);
    set(projectFocusTaskAbortState, value.abortController);
    set(projectFocusTaskSizeState, value.size);
  },
});

export const projectBacklogTaskDataState = atom<TaskObjectType[] | null>({
  key: 'projectBacklogData',
  default: null,
});

export const projectBacklogTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'projectBacklogFilteredData',
  default: null,
});

export const projectBacklogTaskLoadingState = atom<boolean>({
  key: 'projectBacklogLoading',
  default: false,
});

export const projectBacklogTaskAbortState = atom<AbortController>({
  key: 'projectBacklogAbortController',
  default: new AbortController(),
});

export const projectBacklogTaskSizeState = atom<number>({
  key: 'projectBacklogTaskSize',
  default: 0,
});

export const projectBacklogTasksState = selector<TaskListType>({
  key: 'projectBacklog',
  get: ({ get }) => {
    const data = get(projectBacklogTaskDataState);
    const filteredData = get(projectBacklogTaskFilteredDataState);
    const isLoading = get(projectBacklogTaskLoadingState);
    const abortController = get(projectBacklogTaskAbortState);
    const size = get(projectBacklogTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(projectBacklogTaskDataState, value);
      set(projectBacklogTaskFilteredDataState, value);
      set(projectBacklogTaskLoadingState, value);
      set(projectBacklogTaskAbortState, value);
      set(projectBacklogTaskSizeState, value);
      return;
    }
    set(projectBacklogTaskDataState, value.data);
    set(projectBacklogTaskFilteredDataState, value.filteredData);
    set(projectBacklogTaskLoadingState, value.isLoading);
    set(projectBacklogTaskAbortState, value.abortController);
    set(projectBacklogTaskSizeState, value.size);
  },
});
export const allProjectsState = atom<ProjectObjectType[] | null>({
  key: 'allProjects',
  default: null,
});

export const currentProjectIdState = atom<string | null>({
  key: 'currentProjectId',
  default: null,
});

const projectSortByState = atom<SortByType>({
  key: 'projectSortBy',
  default: 'display',
});

const projectSortOrderState = atom<SortOrderType>({
  key: 'projectSortOrder',
  default: 'asc',
});

export const projectTaskFiltersState = atom<TaskFiltersObjectType>({
  key: 'projectTaskFilters',
  default: {
    sizeFilter: null,
    tagFilter: null,
    assigneeFilter: null,
    dateFilter: null,
    projectFilter: null,
    searchTextFilter: '',
  },
});

export const projectCompletedTaskDataState = atom<TaskObjectType[]>({
  key: 'projectCompletedTaskData',
  default: [],
});
export const projectCompletedTaskHasMoreState = atom<boolean>({
  key: 'projectCompletedTaskHasMore',
  default: true,
});
export const projectCompletedTaskPageState = atom<number>({
  key: 'projectCompletedTaskPage',
  default: 0,
});
export const projectCompletedTaskLimitState = atom<number>({
  key: 'projectCompletedTaskLimit',
  default: 10,
});
export const projectCompletedTaskPointsState = atom<number>({
  key: 'projectCompletedTaskPoints',
  default: 0,
});

export const projectCompletedTaskSizeState = atom<number | null>({
  key: 'projectCompletedTaskSize',
  default: null,
});
export const projectCompletedTaskLoadingState = atom<boolean>({
  key: 'projectCompletedTaskLoading',
  default: false,
});
export const projectCompletedTaskFilterState = atom<string>({
  key: 'projectCompletedTaskFilter',
  default: 'today',
});
export const projectCompletedTaskAbortControllerState = atom<AbortController>({
  key: 'projectCompletedTaskAbortController',
  default: new AbortController(),
});
export const projectCompletedTasksState = selector<PaginatedTasksType>({
  key: 'projectCompletedTasks',
  get: ({ get }) => {
    const data = get(projectCompletedTaskDataState);
    const hasMore = get(projectCompletedTaskHasMoreState);
    const page = get(projectCompletedTaskPageState);
    const limit = get(projectCompletedTaskLimitState);
    const points = get(projectCompletedTaskPointsState);
    const size = get(projectCompletedTaskSizeState);
    const isLoading = get(projectCompletedTaskLoadingState);
    const filter = get(projectCompletedTaskFilterState);
    const abortController = get(projectCompletedTaskAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      points,
      size,
      isLoading,
      filter,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(projectCompletedTaskDataState, value);
      set(projectCompletedTaskHasMoreState, value);
      set(projectCompletedTaskPageState, value);
      set(projectCompletedTaskLimitState, value);
      set(projectCompletedTaskPointsState, value);
      set(projectCompletedTaskSizeState, value);
      set(projectCompletedTaskLoadingState, value);
      set(projectCompletedTaskFilterState, value);
      set(projectCompletedTaskAbortControllerState, value);
      return;
    }

    set(projectCompletedTaskDataState, value.data);
    set(projectCompletedTaskHasMoreState, value.hasMore);
    set(projectCompletedTaskPageState, value.page);
    set(projectCompletedTaskLimitState, value.limit);
    set(projectCompletedTaskPointsState, value.points);
    set(projectCompletedTaskSizeState, value.size);
    set(projectCompletedTaskLoadingState, value.isLoading);
    set(projectCompletedTaskFilterState, value.filter);
    set(projectCompletedTaskAbortControllerState, value.abortController);
  },
});

export const projectTaskUnfilteredListState = atom<TaskTagListType>({
  key: 'projectTaskUnfilteredList',
  default: {
    FOCUS: [],
    BACKLOG: [],
    COMPLETED: [],
  },
});

export const projectErrorState = atom<string | null>({
  key: 'projectError',
  default: null,
});

export const foldersState = atom<FolderType[]>({
  key: 'folders',
  default: [],
});

export const currentHighlightedTaskState = atom<TaskObjectType | null>({
  key: 'currentHighlightedTaskInProject',
  default: null,
});

export const projectWebsocketStatusState = atom<WebsocketStatusType>({
  key: 'projectWebsocketStatus',
  default: 'INIT',
});

export const projectSelectedFocusTaskState = atom<SelectedTaskIdsType[]>({
  key: 'projectSelectedFocusTask',
  default: [],
});

export const projectSelectedBacklogTaskState = atom<SelectedTaskIdsType[]>({
  key: 'projectSelectedBacklogTask',
  default: [],
});

export const projectSelectedCompletedTaskState = atom<SelectedTaskIdsType[]>({
  key: 'projectSelectedCompletedTask',
  default: [],
});

export const projectSelectedTempIdsTaskState = atom<SelectedTaskIdsType[]>({
  key: 'projectSelectedTempIdsTask',
  default: [],
});

export const projectSelectedTaskTypeState = atom<TaskType | null>({
  key: 'projectSelectedTaskType',
  default: null,
});

export const projectSelectedTaskDragIdState = atom<string | null>({
  key: 'projectSelectedTaskDragId',
  default: null,
});

export const projectSelectedTasksState = selector<SelectedTasksType>({
  key: 'projectSelectedTasks',
  get: ({ get }) => {
    const FOCUS = get(projectSelectedFocusTaskState);
    const BACKLOG = get(projectSelectedBacklogTaskState);
    const COMPLETED = get(projectSelectedCompletedTaskState);
    const tempIds = get(projectSelectedTempIdsTaskState);
    const lastSelectedTaskType = get(projectSelectedTaskTypeState);
    const draggingTaskId = get(projectSelectedTaskDragIdState);
    return {
      FOCUS,
      BACKLOG,
      COMPLETED,
      tempIds,
      lastSelectedTaskType,
      draggingTaskId,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(projectSelectedFocusTaskState, value);
      set(projectSelectedBacklogTaskState, value);
      set(projectSelectedCompletedTaskState, value);
      set(projectSelectedTempIdsTaskState, value);
      set(projectSelectedTaskTypeState, value);
      set(projectSelectedTaskDragIdState, value);
      return;
    }
    set(projectSelectedFocusTaskState, value.FOCUS);
    set(projectSelectedBacklogTaskState, value.BACKLOG);
    set(projectSelectedCompletedTaskState, value.COMPLETED);
    set(projectSelectedTempIdsTaskState, value.tempIds);
    set(projectSelectedTaskTypeState, value.lastSelectedTaskType);
    set(projectSelectedTaskDragIdState, value.draggingTaskId);
  },
});

export const projectAutoFocusState = atom<string | null>({
  key: 'projectAutoFocus',
  default: null,
});

export const searchProjectUsersState = atom<UserFieldType[]>({
  key: 'searchProjectUsers',
  default: [],
});

export const projectFormNameState = atom<string | undefined>({
  key: 'projectFormName',
  default: undefined,
});

export const projectFormErrorState = atom<string | undefined>({
  key: 'projectFormError',
  default: undefined,
});

export const projectFormLoadState = atom<boolean>({
  key: 'projectFormLoading',
  default: true,
});

export const selectedProjectNotificationTaskIdState = atom<string | null>({
  key: 'selectedProjectNotificationTaskId',
  default: null,
});

export const projectDataSelector = selector<ProjectDataType>({
  key: 'projectData',
  get: ({ get }) => {
    const allProjects = get(allProjectsState);
    const currentProjectId = get(currentProjectIdState);
    const taskFilters = get(projectTaskFiltersState);
    const COMPLETED = get(projectCompletedTasksState);
    const FOCUS = get(projectFocusTasksState);
    const BACKLOG = get(projectBacklogTasksState);
    const unFilteredList = get(projectTaskUnfilteredListState);
    const error = get(projectErrorState);
    const folders = get(foldersState);
    const currentHighlightedTask = get(currentHighlightedTaskState);
    const websocketStatus = get(projectWebsocketStatusState);
    const selectedTasks = get(projectSelectedTasksState);
    const autoFocus = get(projectAutoFocusState);
    const sortBy = get(projectSortByState);
    const sortOrder = get(projectSortOrderState);
    const searchProjectUsers = get(searchProjectUsersState);
    const projectFormName = get(projectFormNameState);
    const projectFormError = get(projectFormErrorState);
    const projectFormLoading = get(projectFormLoadState);
    const selectedProjectNotificationTaskId = get(selectedProjectNotificationTaskIdState);

    // then combine into desired shape (object) and return:
    return {
      allProjects,
      currentProjectId,
      taskFilters,
      COMPLETED,
      FOCUS,
      BACKLOG,
      unFilteredList,
      error,
      folders,
      currentHighlightedTask,
      websocketStatus,
      selectedTasks,
      autoFocus,
      sortBy,
      sortOrder,
      searchProjectUsers,
      projectFormName,
      projectFormError,
      projectFormLoading,
      selectedProjectNotificationTaskId,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(allProjectsState, value);
      set(currentProjectIdState, value);
      set(projectTaskFiltersState, value);
      set(projectCompletedTasksState, value);
      set(projectFocusTasksState, value);
      set(projectBacklogTasksState, value);
      set(projectTaskUnfilteredListState, value);
      set(projectErrorState, value);
      set(foldersState, value);
      set(currentHighlightedTaskState, value);
      set(projectWebsocketStatusState, value);
      set(projectSelectedTasksState, value);
      set(projectAutoFocusState, value);
      set(projectSortByState, value);
      set(projectSortOrderState, value);
      set(searchProjectUsersState, value);
      set(projectFormNameState, value);
      set(projectFormErrorState, value);
      set(projectFormLoadState, value);
      set(selectedProjectNotificationTaskIdState, value);
      return;
    }
    set(allProjectsState, value.allProjects);
    set(currentProjectIdState, value.currentProjectId);
    set(projectTaskFiltersState, value.taskFilters);
    set(projectCompletedTasksState, value.COMPLETED);
    set(projectFocusTasksState, value.FOCUS);
    set(projectBacklogTasksState, value.BACKLOG);
    set(projectTaskUnfilteredListState, value.unFilteredList);
    set(projectErrorState, value.error);
    set(foldersState, value.folders);
    set(currentHighlightedTaskState, value.currentHighlightedTask);
    set(projectWebsocketStatusState, value.websocketStatus);
    set(projectSelectedTasksState, value.selectedTasks);
    set(projectAutoFocusState, value.autoFocus);
    set(projectSortByState, value.sortBy);
    set(projectSortOrderState, value.sortOrder);
    set(searchProjectUsersState, value.searchProjectUsers);
    set(projectFormNameState, value.projectFormName);
    set(projectFormErrorState, value.projectFormError);
    set(projectFormLoadState, value.projectFormLoading);
    set(selectedProjectNotificationTaskIdState, value.selectedProjectNotificationTaskId);
  },
});
