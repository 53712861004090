import _ from 'underscore';
import { lazy } from 'preact/compat';
import { formatDateForAPI, formatTimeToUTC } from 'utils/task';

export const TASK_INPUT_WAIT_INTERVAL = 300;

export const ltrim = (str: string) => {
  const rgxtrim = new RegExp('^\\s+'); // remove any space from start
  return str.replace(rgxtrim, '');
};

export const removeSpace = (str: string) => {
  str = str.replace(/\r?\n|\r/g, ''); // Remove
  str = str.replace(/\s\s+$/g, ' ');
  return str;
};

export const randomNumber = () => {
  const min = 1;
  const max = 100;
  return min + Math.random() * (max - min) + '';
};

export const sortData = <T>(data: Array<T>, key: string, asc?: boolean) => {
  const tempData = [...data];
  return tempData.sort(function (a, b) {
    if (asc) return a[key] - b[key];
    else return b[key] - a[key];
  });
};

export const sortStringData = (data: TagObjectType[], asc: boolean) => {
  const tempData = [...data];
  return tempData.sort(function (a, b) {
    const valueA = a.name;
    const valueB = b.name;
    if (asc) {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};

export const sortTagFilterData = (data: TagFilterType[], asc: boolean) => {
  const tempData = [...data];
  return tempData.sort(function (a, b) {
    const valueA = a.name;
    const valueB = b.name;
    if (asc) {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  });
};

export const capitalizeFirstLetter = (stringText: string) => {
  stringText = stringText.toLowerCase();
  return stringText.charAt(0).toUpperCase() + stringText.slice(1);
};

export const insertOnIndex = <T>(arr: Array<T>, index: number, newItem: T) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const getFileExtension = (url: string) => {
  const fileSplit = url.split('?')[0];
  const fileIndex = fileSplit.substr(fileSplit.lastIndexOf('.') + 1);
  return fileIndex;
};

export const hasUpdated = <T extends Record<string, any>>(
  oldData: T,
  newData: T,
  fields: Array<{ name: string; type: string }>,
): boolean => {
  let isSameValue = true;

  fields.forEach((field) => {
    const oldField = oldData[field.name];
    const newField = newData[field.name];

    const isSame = field.type === 'prime' ? oldField === newField : _.isEqual(oldField, newField);

    if (!isSame) {
      isSameValue = false;
      return;
    }
  });

  return isSameValue;
};

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export const checkIfItsACustomFilter = (newFilter: DateFilterType | string) => {
  let newCustom = false;
  let newFilterArr: DateFilterType[] | string[] = [];
  const data = [
    'today',
    'yesterday',
    'currentMonth',
    'lastMonth',
    'yearToDate',
    'previousYear',
    'all',
  ]; // ['ALLTIME', 'MONTH', 'WEEK', 'DAY', 'RECENT'];
  const index = data.findIndex((dat) => dat === newFilter);

  if (index === -1) {
    newCustom = true;
    newFilterArr = newFilter.split('~');
    return { newCustom, newFilterArr };
  }

  return { newCustom, newFilter };
};

export const highlightText = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'Home':
      e.preventDefault();
      if (e.shiftKey) (e.target as HTMLInputElement).selectionStart = 0;
      else (e.target as HTMLInputElement).setSelectionRange(0, 0);
      break;
    case 'End':
      e.preventDefault();
      const len = (e.target as HTMLInputElement).value.length;
      if (e.shiftKey) (e.target as HTMLInputElement).selectionEnd = len;
      else (e.target as HTMLInputElement).setSelectionRange(len, len);
      break;
    default:
      return;
  }
};

export const swapIndex = <T>(array: Array<T>, from: number, to: number) =>
  from < to
    ? [
        ...array.slice(0, from),
        ...array.slice(from + 1, to + 1),
        array[from],
        ...array.slice(to + 1),
      ]
    : [...array.slice(0, to), array[from], ...array.slice(to, from), ...array.slice(from + 1)];

export const ordinalSuffix = (i: number) => {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const isAnySubtaskWithoutID = (subTasks?: TaskObjectType[]) => {
  const index = subTasks?.findIndex((subTask) => subTask.id === null);

  if (index !== -1) return true;

  return false;
};

export const updateTaskField = (
  task: TaskObjectType,
  property: string,
  value:
    | string
    | SizeOptionsType
    | TagObjectType[]
    | boolean
    | { id: string }
    | Date
    | Date[]
    | (Date | null)[]
    | null,
) => {
  return { ...task, [property]: value };
};

export const getRecurringTaskPayload = (task: TaskObjectType) => {
  let payload = getTaskModelPayload(task);
  const recurringPayload = task.recurringTaskMeta
    ? getRecurringMetaPayload(task.recurringTaskMeta)
    : {};

  payload = { ...payload, ...recurringPayload };
  return payload;
};

export const getRecurringMetaPayload = (task: RecurringTaskMetaType) => {
  return {
    id: task.id,
    repeatFrequency: task.repeatFrequency,
    repeatType: task.repeatType,
    executionTime: task.executionTime ? formatTimeToUTC(task.executionTime) : null,
    repeatDays: task.repeatDays ? task.repeatDays : [],
  };
};

type RecurringTaskDataPayloadType = {
  id: string | null;
  assigneeId: string | null;
  tagIds: string[];
  name?: string;
  parentTaskId?: string | null;
  size: SizeOptionsType;
  projectId: string | null;
  // taskStatusId: number;
  taskType: TaskType;
  tempId: string;
  dueDate: string | null;
  startDate: string | null;
  subTasks: RecurringTaskDataPayloadType[] | null;
  collapsedSubTasks: boolean;
  sequence?: number;
};

export const getTaskModelPayload = (task: TaskObjectType, parentId?: string) => {
  return {
    id: task.id,
    assigneeId: task.assignee && task.assignee.id ? task.assignee.id : null,
    tagIds: task.tags?.map((tag) => tag.id),
    name: task.name,
    parentTaskId: parentId ? parentId : null,
    size: task.size,
    projectId:
      task.project && task.project.id ? task.project.id : task.projectId ? task.projectId : null,
    // taskStatusId: 1,
    taskType: task.taskType ? task.taskType.toUpperCase() : 'BACKLOG',
    tempId: task.tempId,
    dueDate: task.dueDate ? formatDateForAPI(task.dueDate) : null,
    startDate: task.startDate ? formatDateForAPI(task.startDate) : null,
    endDate: task.endDate ? formatDateForAPI(task.endDate) : null,
    subTasks: task.subTasks ? parseRecurringSubTasks(task.subTasks, task.id) : null,
    collapsedSubTasks: task.collapsedSubTasks ? task.collapsedSubTasks : false,
  } as RecurringTaskDataPayloadType;
};

const parseRecurringSubTasks = (
  data: TaskObjectType[],
  parentId?: string,
): RecurringTaskDataPayloadType[] => {
  const newData: any = [];
  data.forEach((dat) => {
    if (dat) {
      let tempSubTask = getTaskModelPayload(dat, parentId);
      if (dat.tempId === dat.id) tempSubTask = { ...tempSubTask, id: null };
      if (dat.sequence) tempSubTask = { ...tempSubTask, sequence: dat.sequence };
      newData.push(tempSubTask);
    }
  });

  return newData;
};

export const findLastIndex = <T>(array: Array<T>, searchKey: string, searchValue: boolean) => {
  const index = array
    .slice()
    .reverse()
    .findIndex((x) => x[searchKey] === searchValue);
  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
};

/**
 * Get home column
 */
export const getDataType = (focusArr: TaskObjectType[], tempId: string) => {
  const index = focusArr.findIndex((item) => item.tempId === tempId);
  if (index >= 0) return 'FOCUS';
  return 'BACKLOG';
};

export const getUniqueListBy = <T>(arr: Array<T>, key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const getUniqueTags = (arr: TagObjectType[]) => {
  const result = arr.reduce(
    (acc, curr) => {
      acc[curr.name] ? acc[curr.name].push(curr.id) : (acc[curr.name] = [curr.id]);
      return acc;
    },
    {} as { [key: string]: string[] },
  );

  // Convert array values to Set and back to array for uniqueness
  for (const key in result) {
    result[key] = Array.from(new Set(result[key]));
  }
  const transformedResult = Object.keys(result).map((key) => ({
    name: key,
    ids: result[key],
  }));
  return transformedResult;
};

export function isThemeObjectInterface(obj: any): obj is ThemeObjectInterface {
  // Add your conditions to check for ThemeObjectInterface properties
  return 'name' in obj;
}

export function isThemeInstanceInterface(obj: any): obj is ThemeInstanceInterface {
  // Add your conditions to check for ThemeInstanceInterface properties
  return 'purchasedOn' in obj;
}

export function isMacOS() {
  const userAgent = navigator.userAgent;
  return userAgent.indexOf('Macintosh') !== -1 || userAgent.indexOf('Mac OS X') !== -1;
}

export function isProjectObjectType(obj: any): obj is ProjectObjectType {
  return 'isOpen' in obj;
}

export function isTaskObjectType(obj: any): obj is TaskObjectType {
  return 'assignee' in obj;
}

export function isCommentType(obj: any): obj is CommentType {
  return 'taggedUsers' in obj;
}

export function isArrayIncluded(array1: string[], array2: string[]): boolean {
  return array1.every((value) => array2.includes(value));
}
