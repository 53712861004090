import config from 'config/config';
import { canBeAdded } from 'triggers/utils/css';

export const COMPLETE_TASK_NAME = 'complete-task-animation';

export function attachStylesheet(fileName: string, themeId: string) {
  if (!canBeAdded(fileName)) return;

  removeStylesheet(fileName);

  const url = buildCSSUrl(fileName, themeId);
  appendStylesheet(url, fileName);
}

function appendStylesheet(url: string, fileName: string) {
  const element = document.createElement('link');
  const head = document.getElementsByTagName('head')[0];
  element.rel = 'stylesheet';
  element.id = getCSSId(fileName);
  element.type = 'text/css';
  element.rel = 'stylesheet';
  element.href = url;
  head.appendChild(element);
}

export function removeStylesheet(id: string) {
  const link = document.getElementById(getCSSId(id));

  if (link) link.remove();
}

function getCSSId(id: string) {
  return id + '-css';
}

function buildCSSUrl(fileName: string, themeId: string) {
  return `/themes/${themeId}/styles/triggers/${fileName}.css?v=${config.version}`;
}
