import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import {
  projectAutoFocusState,
  projectBacklogTaskDataState,
  projectDataSelector,
  projectFocusTaskDataState,
} from 'recoil/ProjectState';
import {
  userAutoFocusState,
  userBacklogTaskDataState,
  userDataSelector,
  userFocusTaskDataState,
} from 'recoil/UserState';
import { tutorialAutoFocusState, tutorialDataSelector } from 'recoil/TutorialState';
import {
  templateFocusTaskDataState,
  templateDataSelector,
  templateBacklogTaskDataState,
  templateAutoFocusState,
} from 'recoil/TemplateState';
import {
  recurringFocusTaskDataState,
  recurringBacklogTaskDataState,
  recurringTaskDataSelector,
  recurringAutoFocusState,
} from 'recoil/RecurringTaskState';
import { getCountOfTasks } from 'utils/task';

export const focusTaskDataState = atom<TaskObjectType[] | null>({
  key: 'focusData',
  default: null,
});

export const focusTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'focusFilteredData',
  default: null,
});

export const focusTaskLoadingState = atom<boolean>({
  key: 'focusLoading',
  default: false,
});

export const focusTaskSizeState = atom<number>({
  key: 'focusTaskSize',
  default: 0,
});

export const focusTaskAbortState = atom<AbortController>({
  key: 'focusAbortController',
  default: new AbortController(),
});

export const focusTasksState = selector<TaskListType>({
  key: 'focus',
  get: ({ get }) => {
    const data = get(focusTaskDataState);
    const filteredData = get(focusTaskFilteredDataState);
    const isLoading = get(focusTaskLoadingState);
    const abortController = get(focusTaskAbortState);
    const size = get(focusTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(focusTaskDataState, value);
      set(focusTaskFilteredDataState, value);
      set(focusTaskLoadingState, value);
      set(focusTaskAbortState, value);
      set(focusTaskSizeState, value);
      return;
    }
    set(focusTaskDataState, value.data);
    set(focusTaskFilteredDataState, value.filteredData);
    set(focusTaskLoadingState, value.isLoading);
    set(focusTaskAbortState, value.abortController);
    set(focusTaskSizeState, value.size);
  },
});

export const backlogTaskDataState = atom<TaskObjectType[] | null>({
  key: 'backlogData',
  default: null,
});

export const backlogTaskFilteredDataState = atom<TaskObjectType[] | null>({
  key: 'backlogFilteredData',
  default: null,
});

export const backlogTaskLoadingState = atom<boolean>({
  key: 'backlogLoading',
  default: false,
});

export const backlogTaskAbortState = atom<AbortController>({
  key: 'backlogAbortController',
  default: new AbortController(),
});

export const backlogTaskSizeState = atom<number>({
  key: 'backlogTaskSize',
  default: 0,
});

export const backlogTasksState = selector<TaskListType>({
  key: 'backlog',
  get: ({ get }) => {
    const data = get(backlogTaskDataState);
    const filteredData = get(backlogTaskFilteredDataState);
    const isLoading = get(backlogTaskLoadingState);
    const abortController = get(backlogTaskAbortState);
    const size = get(backlogTaskSizeState);
    return { data, filteredData, isLoading, abortController, size };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(backlogTaskDataState, value);
      set(backlogTaskFilteredDataState, value);
      set(backlogTaskLoadingState, value);
      set(backlogTaskAbortState, value);
      set(backlogTaskSizeState, value);
      return;
    }
    set(backlogTaskDataState, value.data);
    set(backlogTaskFilteredDataState, value.filteredData);
    set(backlogTaskLoadingState, value.isLoading);
    set(backlogTaskAbortState, value.abortController);
    set(backlogTaskSizeState, value.size);
  },
});

export const taskFiltersState = atom<TaskFiltersObjectType>({
  key: 'taskFilters',
  default: {
    sizeFilter: null,
    tagFilter: null,
    assigneeFilter: null,
    dateFilter: null,
    projectFilter: null,
    searchTextFilter: '',
  },
});
export const completedTaskDataState = atom<TaskObjectType[]>({
  key: 'completedTaskData',
  default: [],
});
export const completedTaskHasMoreState = atom<boolean>({
  key: 'completedTaskHasMore',
  default: true,
});
export const completedTaskPageState = atom<number>({
  key: 'completedTaskPage',
  default: 0,
});
export const completedTaskLimitState = atom<number>({
  key: 'completedTaskLimit',
  default: 10,
});
export const completedTaskPointsState = atom<number>({
  key: 'completedTaskPoints',
  default: 0,
});

export const completedTaskSizeState = atom<number | null>({
  key: 'completedTaskSize',
  default: null,
});
export const completedTaskLoadingState = atom<boolean>({
  key: 'completedTaskLoading',
  default: false,
});
export const completedTaskFilterState = atom<DateFilterType | string>({
  key: 'completedTaskFilter',
  default: 'today',
});
export const completedTaskAbortControllerState = atom<AbortController>({
  key: 'completedTaskAbortController',
  default: new AbortController(),
});
export const completedTasksState = selector<PaginatedTasksType>({
  key: 'completedTasks',
  get: ({ get }) => {
    const data = get(completedTaskDataState);
    const hasMore = get(completedTaskHasMoreState);
    const page = get(completedTaskPageState);
    const limit = get(completedTaskLimitState);
    const points = get(completedTaskPointsState);
    const size = get(completedTaskSizeState);
    const isLoading = get(completedTaskLoadingState);
    const filter = get(completedTaskFilterState);
    const abortController = get(completedTaskAbortControllerState);
    return {
      data,
      hasMore,
      page,
      limit,
      points,
      size,
      isLoading,
      filter,
      abortController,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(completedTaskDataState, value);
      set(completedTaskHasMoreState, value);
      set(completedTaskPageState, value);
      set(completedTaskLimitState, value);
      set(completedTaskPointsState, value);
      set(completedTaskSizeState, value);
      set(completedTaskLoadingState, value);
      set(completedTaskFilterState, value);
      set(completedTaskAbortControllerState, value);
      return;
    }

    set(completedTaskDataState, value.data);
    set(completedTaskHasMoreState, value.hasMore);
    set(completedTaskPageState, value.page);
    set(completedTaskLimitState, value.limit);
    set(completedTaskPointsState, value.points);
    set(completedTaskSizeState, value.size);
    set(completedTaskLoadingState, value.isLoading);
    set(completedTaskFilterState, value.filter);
    set(completedTaskAbortControllerState, value.abortController);
  },
});

export const taskUnfilteredListState = atom<TaskTagListType>({
  key: 'taskUnfilteredList',
  default: {
    FOCUS: [],
    BACKLOG: [],
    COMPLETED: [],
  },
});

export const currentHighlightedTaskState = atom<TaskObjectType | null>({
  key: 'currentHighlightedTask',
  default: null,
});

export const websocketStatusState = atom<WebsocketStatusType>({
  key: 'websocketStatus',
  default: 'INIT',
});

export const selectedFocusTaskState = atom<SelectedTaskIdsType[]>({
  key: 'selectedFocusTask',
  default: [],
});

export const selectedBacklogTaskState = atom<SelectedTaskIdsType[]>({
  key: 'selectedBacklogTask',
  default: [],
});

export const selectedCompletedTaskState = atom<SelectedTaskIdsType[]>({
  key: 'selectedCompletedTask',
  default: [],
});

export const selectedTempIdsTaskState = atom<SelectedTaskIdsType[]>({
  key: 'selectedTempIdsTask',
  default: [],
});

export const selectedTaskTypeState = atom<TaskType | null>({
  key: 'selectedTaskType',
  default: null,
});

export const selectedTaskDragIdState = atom<string | null>({
  key: 'selectedTaskDragId',
  default: null,
});

export const selectedTasksState = selector<SelectedTasksType>({
  key: 'selectedTasks',
  get: ({ get }) => {
    const FOCUS = get(selectedFocusTaskState);
    const BACKLOG = get(selectedBacklogTaskState);
    const COMPLETED = get(selectedCompletedTaskState);
    const tempIds = get(selectedTempIdsTaskState);
    const lastSelectedTaskType = get(selectedTaskTypeState);
    const draggingTaskId = get(selectedTaskDragIdState);
    return {
      FOCUS,
      BACKLOG,
      COMPLETED,
      tempIds,
      lastSelectedTaskType,
      draggingTaskId,
    };
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(selectedFocusTaskState, value);
      set(selectedBacklogTaskState, value);
      set(selectedCompletedTaskState, value);
      set(selectedTempIdsTaskState, value);
      set(selectedTaskTypeState, value);
      set(selectedTaskDragIdState, value);
      return;
    }
    set(selectedFocusTaskState, value.FOCUS);
    set(selectedBacklogTaskState, value.BACKLOG);
    set(selectedCompletedTaskState, value.COMPLETED);
    set(selectedTempIdsTaskState, value.tempIds);
    set(selectedTaskTypeState, value.lastSelectedTaskType);
    set(selectedTaskDragIdState, value.draggingTaskId);
  },
});

export const taskFetchErrorState = atom<string | null>({
  key: 'taskFetchError',
  default: null,
});

export const autoFocusState = atom<string | null>({
  key: 'autoFocus',
  default: null,
});

export const selectedNotificationTaskIdState = atom<string | null>({
  key: 'selectedNotificationTaskId',
  default: null,
});

export const taskDataStateSelector = selector<TaskDataType>({
  key: 'taskData',
  get: ({ get }) => {
    const taskFilters = get(taskFiltersState);
    const COMPLETED = get(completedTasksState);
    const FOCUS = get(focusTasksState);
    const BACKLOG = get(backlogTasksState);
    const unFilteredList = get(taskUnfilteredListState);
    const currentHighlightedTask = get(currentHighlightedTaskState);
    const websocketStatus = get(websocketStatusState);
    const selectedTasks = get(selectedTasksState);
    const error = get(taskFetchErrorState);
    const autoFocus = get(autoFocusState);
    const selectedNotificationTaskId = get(selectedNotificationTaskIdState);

    // then combine into desired shape (object) and return:
    return {
      taskFilters,
      COMPLETED,
      FOCUS,
      BACKLOG,
      unFilteredList,
      currentHighlightedTask,
      websocketStatus,
      selectedTasks,
      error,
      autoFocus,
      selectedNotificationTaskId,
    };
  },
  set: ({ set }, value) => {
    // in a Reset action, the value will be DefaultValue (read more in selector docs):
    if (value instanceof DefaultValue) {
      set(taskFiltersState, value);
      set(completedTasksState, value);
      set(backlogTasksState, value);
      set(focusTasksState, value);
      set(taskUnfilteredListState, value);
      set(currentHighlightedTaskState, value);
      set(websocketStatusState, value);
      set(selectedTasksState, value);
      set(taskFetchErrorState, value);
      set(autoFocusState, value);
      set(selectedNotificationTaskIdState, value);
      return;
    }
    set(taskFiltersState, value.taskFilters);
    set(completedTasksState, value.COMPLETED);
    set(backlogTasksState, value.BACKLOG);
    set(focusTasksState, value.FOCUS);
    set(taskUnfilteredListState, value.unFilteredList);
    set(currentHighlightedTaskState, value.currentHighlightedTask);
    set(websocketStatusState, value.websocketStatus);
    set(selectedTasksState, value.selectedTasks);
    set(taskFetchErrorState, value.error);
    set(autoFocusState, value.autoFocus);
    set(selectedNotificationTaskIdState, value.selectedNotificationTaskId);
  },
});

type ItemValueParams = {
  stateType?: StateType;
  dataType: TaskType;
  parentId?: string;
  parentIndex?: number;
  index: number;
};

export const itemValueSelector = selectorFamily({
  key: 'itemValueSelector',
  get:
    (params: ItemValueParams) =>
    ({ get }) => {
      const { index, dataType, stateType, parentId, parentIndex } = params;
      // Get the corresponding state based on reducerType
      let mainData:
        | TaskDataType
        | UserDataType
        | RecurringDataType
        | ProjectDataType
        | TemplateDataType
        | TutorialDataType;

      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      // Calculate and return the value
      if (parentId && parentIndex !== undefined) {
        return mainData[dataType]?.data?.[parentIndex]?.subTasks?.[index]?.name || '';
      } else {
        return mainData[dataType]?.data?.[index]?.name || '';
      }
    },
});

type TaskListParams = {
  stateType?: StateType;
  dataType: TaskType;
};

export const taskListSelector = selectorFamily({
  key: 'taskListSelector',
  get:
    (params: TaskListParams) =>
    ({ get }) => {
      const { dataType, stateType } = params;
      // Get the corresponding state based on reducerType
      let mainData:
        | TaskDataType
        | UserDataType
        | RecurringDataType
        | ProjectDataType
        | TemplateDataType
        | TutorialDataType;

      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData[dataType];
    },
});

export const selectedTasksSelector = selectorFamily({
  key: 'selectedTasksSelector',
  get:
    (params: TaskListParams) =>
    ({ get }) => {
      const { dataType, stateType } = params;
      // Get the corresponding state based on reducerType
      let mainData:
        | TaskDataType
        | UserDataType
        | RecurringDataType
        | ProjectDataType
        | TemplateDataType
        | TutorialDataType;

      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      // Calculate and return the value
      return mainData.selectedTasks[dataType] || [];
    },
});

export const selectedTaskTempIdsSelector = selectorFamily({
  key: 'selectedTaskTempIdsSelector',
  get:
    (params: { stateType?: StateType }) =>
    ({ get }) => {
      const { stateType } = params;
      // Get the corresponding state based on reducerType
      let mainData:
        | TaskDataType
        | UserDataType
        | RecurringDataType
        | ProjectDataType
        | TemplateDataType
        | TutorialDataType;

      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      // Calculate and return the value
      return mainData.selectedTasks.tempIds || [];
    },
});

export const selectedNotificationTaskIdSelector = selectorFamily({
  key: 'selectedNotificationTaskIdSelector',
  get:
    (params: { stateType?: StateType }) =>
    ({ get }) => {
      const { stateType } = params;
      if (stateType === 'PROJECT')
        return get(projectDataSelector).selectedProjectNotificationTaskId || null;
      else if (stateType === 'INDIVIDUAL')
        return get(userDataSelector).selectedUserNotificationTaskId || null;
      else if (stateType === 'RECURRING')
        return get(recurringTaskDataSelector).selectedRecurringNotificationTaskId || null;
      else if (stateType === 'TEMPLATE')
        return get(templateDataSelector).selectedTemplateNotificationTaskId || null;
      else if (stateType === 'TUTORIAL')
        return get(tutorialDataSelector).selectedTutorialNotificationTaskId || null;
      else return get(taskDataStateSelector).selectedNotificationTaskId || null;
    },
});

export const itemTagsSelector = selectorFamily({
  key: 'itemTagsSelector',
  get:
    (params: ItemValueParams) =>
    ({ get }) => {
      const { index, dataType, stateType, parentId, parentIndex } = params;
      // Get the corresponding state based on reducerType
      let mainData:
        | TaskDataType
        | UserDataType
        | RecurringDataType
        | ProjectDataType
        | TemplateDataType
        | TutorialDataType;

      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      // Calculate and return the value
      if (parentId && parentIndex !== undefined) {
        return mainData[dataType]?.data?.[parentIndex]?.subTasks?.[index]?.tags || [];
      } else {
        return mainData[dataType]?.data?.[index]?.tags || [];
      }
    },
});

export const autoFocusSelector = selectorFamily({
  key: 'autoFocusSelector',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData: string | null;
      if (stateType === 'PROJECT') mainData = get(projectAutoFocusState);
      else if (stateType === 'INDIVIDUAL') mainData = get(userAutoFocusState);
      else if (stateType === 'RECURRING') mainData = get(recurringAutoFocusState);
      else if (stateType === 'TEMPLATE') mainData = get(templateAutoFocusState);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialAutoFocusState);
      else mainData = get(autoFocusState);
      return mainData;
    },
});

export const sizeFilterSelector = selectorFamily({
  key: 'sizeFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);
      return mainData?.taskFilters?.sizeFilter;
    },
});

export const currentHighlightedTaskSelector = selectorFamily({
  key: 'currentHighlightedTaskSelector',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);
      return mainData?.currentHighlightedTask;
    },
});

export const dateFilterSelector = selectorFamily({
  key: 'dateFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);
      return mainData?.taskFilters?.dateFilter;
    },
});

export const tagFilterSelector = selectorFamily({
  key: 'tagFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData?.taskFilters?.tagFilter;
    },
});

export const assigneeFilterSelector = selectorFamily({
  key: 'assigneeFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData?.taskFilters?.assigneeFilter;
    },
});

export const projectFilterSelector = selectorFamily({
  key: 'projectFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData?.taskFilters?.projectFilter;
    },
});

export const searchTextFilterSelector = selectorFamily({
  key: 'searchTextFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData?.taskFilters?.searchTextFilter;
    },
});

type TaskLoadingSelectorParam = {
  dataType: TaskType;
  stateType?: StateType;
};

export const taskIsLoadingSelector = selectorFamily({
  key: 'taskIsLoading',
  get:
    (params: TaskLoadingSelectorParam) =>
    ({ get }) => {
      const { dataType, stateType } = params;
      let mainData:
        | TaskDataType
        | UserDataType
        | ProjectDataType
        | TemplateDataType
        | RecurringDataType
        | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'RECURRING') mainData = get(recurringTaskDataSelector);
      else if (stateType === 'TEMPLATE') mainData = get(templateDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData[dataType] ? mainData[dataType].isLoading : false;
    },
});

export const completedTasksFilterSelector = selectorFamily({
  key: 'completedTasksFilter',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let mainData: TaskDataType | UserDataType | ProjectDataType | TutorialDataType;
      if (stateType === 'PROJECT') mainData = get(projectDataSelector);
      else if (stateType === 'INDIVIDUAL') mainData = get(userDataSelector);
      else if (stateType === 'TUTORIAL') mainData = get(tutorialDataSelector);
      else mainData = get(taskDataStateSelector);

      return mainData.COMPLETED.filter;
    },
});

export const openTaskCountSelector = selectorFamily({
  key: 'openTaskCount',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      let allOpenTasks: TaskObjectType[] = [];
      if (stateType === 'PROJECT') {
        const projectFocusTasks = get(projectFocusTaskDataState);
        const projectBacklogTasks = get(projectBacklogTaskDataState);
        if (projectFocusTasks) allOpenTasks = [...allOpenTasks, ...projectFocusTasks];
        if (projectBacklogTasks) allOpenTasks = [...allOpenTasks, ...projectBacklogTasks];
      } else if (stateType === 'INDIVIDUAL') {
        const userFocusTasks = get(userFocusTaskDataState);
        const userBacklogTasks = get(userBacklogTaskDataState);
        if (userFocusTasks) allOpenTasks = [...allOpenTasks, ...userFocusTasks];
        if (userBacklogTasks) allOpenTasks = [...allOpenTasks, ...userBacklogTasks];
      } else if (stateType === 'TEMPLATE') {
        const templateFocusTasks = get(templateFocusTaskDataState);
        const templateBacklogTasks = get(templateBacklogTaskDataState);
        if (templateFocusTasks) allOpenTasks = [...allOpenTasks, ...templateFocusTasks];
        if (templateBacklogTasks) allOpenTasks = [...allOpenTasks, ...templateBacklogTasks];
      } else if (stateType === 'RECURRING') {
        const recurringFocusTasks = get(recurringFocusTaskDataState);
        const recurringBacklogTasks = get(recurringBacklogTaskDataState);
        if (recurringFocusTasks) allOpenTasks = [...allOpenTasks, ...recurringFocusTasks];
        if (recurringBacklogTasks) allOpenTasks = [...allOpenTasks, ...recurringBacklogTasks];
      } else {
        const focusTasks = get(focusTaskDataState);
        const backlogTasks = get(backlogTaskDataState);
        if (focusTasks) allOpenTasks = [...allOpenTasks, ...focusTasks];
        if (backlogTasks) allOpenTasks = [...allOpenTasks, ...backlogTasks];
      }
      return getCountOfTasks(allOpenTasks);
    },
});

export const stateRecoilDataSelector = selectorFamily({
  key: 'stateRecoilData',
  get:
    (stateType?: StateType) =>
    ({ get }) => {
      if (stateType === 'PROJECT') {
        return get(projectDataSelector);
      } else if (stateType === 'INDIVIDUAL') {
        return get(userDataSelector);
      } else if (stateType === 'TEMPLATE') {
        return get(templateDataSelector);
      } else {
        return get(taskDataStateSelector);
      }
    },
});
