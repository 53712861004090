import { hasSuccess, hasError } from 'services/ApiHelpers';
import { appClient } from 'services/NetworkService';
import apiConfig from 'config/api';

/*
 * Get project templates
 *
 * returns Object {data, error, status}
 */
export async function getProjectTemplates() {
  try {
    const response = await appClient.get(apiConfig.template.get_project_templates);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Create project template
 * @param {userIds: [], name: ""} payload
 * @param {string} projectId
 */
export async function createProjectTemplate(payload, projectId) {
  try {
    const response = await appClient.post(
      apiConfig.template.create_project_template + '/' + projectId,
      payload,
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * update project template
 * @param {userIds: [], name: ""} payload
 * @returns
 */
export async function updateTemplate(payload) {
  try {
    const response = await appClient.post(apiConfig.template.update_project_template, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * update existing task
 * payload
 *
 * returns Object {data, error, status}
 */
export async function updateTemplateTask(
  payload: {
    id: string;
    sequence?: number;
    size?: SizeOptionsType;
    taskType?: string;
    tempId?: string;
  },
  abortController: AbortController,
) {
  try {
    const response = await appClient.post(apiConfig.template.update_task, payload, {
      signal: abortController.signal,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * get the list of tags for template
 * @param {String} templateId
 * @returns
 */
export async function getAllTemplateTags(templateId: string) {
  try {
    const response = await appClient.get(apiConfig.template.get_tags + '/' + templateId);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * create new tag for project template
 * @param {"name", "ownerIdentifier", "projectTemplateId"} payload
 * @returns
 */
export async function addNewTemplateTag(payload) {
  try {
    const response = await appClient.post(apiConfig.template.add_new_tag, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Create new recurring task in template
 * payload
 *
 * returns Object {data, error, status}
 */
export async function createNewTemplateRecurringTask(payload) {
  try {
    const response = await appClient.post(apiConfig.template.add_recurring_task, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update recurring task in template
 * payload
 *
 * returns Object {data, error, status}
 */
export async function updateTemplateRecurringTask(payload: any) {
  try {
    const response = await appClient.post(apiConfig.template.update_recurring_task, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * delete the project template with passed template id
 * @param {String} id
 * @returns
 */
export async function deleteProjectTemplate(id) {
  try {
    const response = await appClient.delete(apiConfig.template.delete_project_template + '/' + id);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function deleteTemplateRecurringTask(id) {
  try {
    const response = await appClient.delete(apiConfig.template.delete_recurring_task + '/' + id);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
