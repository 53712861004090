import { Fragment, useEffect, useState } from 'preact/compat';
import { Strings } from 'resources';
import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import SmallButton from 'components/UI/SmallButton';
import { log } from 'utils';
import { findLastIndex } from 'utils/helpers';
import {
  getUserEmail,
  getUserId,
  getUserName,
  skipTutorial as skipTutorialFromAPI,
  updateTutorialStep as updateTutorialStepFromAPI,
  updateTutorialCompleteStatus as updateTutorialCompleteStatusFromAPI,
} from 'services/AuthService';
import { getItem, setItem } from 'utils/storage';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { accountDataSelector, allTutorialsState } from 'recoil/AccountState';
import {
  allTutorialProjectsState,
  allTutorialUsersState,
  tutorialCurrentProjectIdState,
  tutorialDataSelector,
  tutorialFoldersState,
} from 'recoil/TutorialState';
import {
  navigationDataSelector,
  showCreateFolderModalState,
  showCreateNewProjectModalState,
  showEditProjectModalState,
  showInviteMembersModalState,
  taskDetailModalState,
  folderForCreateNewProjectModalState,
} from 'recoil/NavigationState';
import { getTutorialsData } from 'recoil/AccountState/update';
import {
  addTutorialTask,
  addTutorialTaskWithSubTask,
  addTutorialTaskWithoutFocus,
  resetTutorialTask,
  updateFlag,
  getFolderFromTag,
  addCompletedTask as addCompletedTaskInTutorial,
} from 'recoil/TutorialState/update';
import AvatarIcon from 'assets/images/Avatar.png';
import { addTaskData } from 'recoil/TaskState/update';
import { addCompletedTasks } from 'recoil/CompletedTasksState/update';
import defaultSteps from './tutorial.json';
import InnominateIcon from 'assets/images/tutorial/innominate.png';
import SeekerIcon from 'assets/images/tutorial/seeker.png';

export default function UserTutorial() {
  const accountData = useRecoilValue(accountDataSelector);
  const tutorialData = useRecoilValue(tutorialDataSelector);
  const navigationData = useRecoilValue(navigationDataSelector);

  const [currentStepCount, setCurrentStepCount] = useState(0);
  const [isCloseLoading, setCloseLoading] = useState(false);
  const [selectedGuide, setSelectedGuide] = useState(
    getItem('selectedTutorialGuide') ? getItem('selectedTutorialGuide') : 'innominate',
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (tutorialData.flags.topTaskBoxActive) {
      let index = 1;
      if (accountData.allTutorials.length > 3 && accountData.allTutorials[3].currentStep === 6) {
        index = 3;
      }
      const id = accountData.allTutorials[index].id;

      if (accountData.allTutorials[index].currentStep !== 7) {
        updateTutorialStep(7, id);
      }
    }
  }, [tutorialData.flags.topTaskBoxActive]);

  useEffect(() => {
    if (tutorialData.flags.taskCreationCompletion) {
      let index = 1;
      if (accountData.allTutorials.length > 3 && accountData.allTutorials[3].currentStep === 7) {
        index = 3;
      }
      const id = accountData.allTutorials[index].id;
      updateTutorialStep(8, id);
    }
  }, [tutorialData.flags, tutorialData.flags.taskCreationCompletion]);

  useEffect(() => {
    if (tutorialData.flags.subtaskCreationCompletion) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 12) {
        updateTutorialStep(12, id);
      }
    }
  }, [tutorialData.flags.subtaskCreationCompletion]);

  useEffect(() => {
    if (tutorialData.flags.updateTaskCompletion) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 14) {
        updateTutorialStep(14, id);
      }
    }
  }, [tutorialData.flags.updateTaskCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputAssigneeHotkeyCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 5) {
        updateTutorialStep(5, id);
      }
    }
  }, [tutorialData.flags.inputAssigneeHotkeyCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputAssigneeMouseCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 7) {
        updateTutorialStep(7, id);
      }
    }
  }, [tutorialData.flags.inputAssigneeMouseCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputProjectHotkeyCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 15) {
        updateTutorialStep(15, id);
      }
    }
  }, [tutorialData.flags.inputProjectHotkeyCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputProjectMouseCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 17) {
        updateTutorialStep(17, id);
      }
    }
  }, [tutorialData.flags.inputProjectMouseCompletion]);

  useEffect(() => {
    if (tutorialData.flags.deleteTaskCompletion) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 16) {
        updateTutorialStep(16, id);
      }
    }
  }, [tutorialData.flags.deleteTaskCompletion]);

  useEffect(() => {
    if (tutorialData.flags.completeTaskCompletion) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 18) {
        updateTutorialStep(18, id);
      }
    }
  }, [tutorialData.flags.completeTaskCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showCompletedPage) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 19) {
        updateTutorialStep(19, id);
      }
    }
  }, [tutorialData.flags.showCompletedPage]);

  useEffect(() => {
    if (tutorialData.flags.uncompleteTaskCompletion) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 20) {
        updateTutorialStep(20, id);
      }
    }
  }, [tutorialData.flags.uncompleteTaskCompletion]);

  useEffect(() => {
    if (tutorialData.flags.reprioritizeTaskCompletion) {
      const id = accountData.allTutorials[1].id;
      if (accountData.allTutorials[1].currentStep !== 23) {
        updateTutorialStep(23, id);
      }
    }
  }, [tutorialData.flags.reprioritizeTaskCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showTags) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 21) {
        updateTutorialStep(21, id);
      }
    }
  }, [tutorialData.flags.showTags]);

  useEffect(() => {
    if (tutorialData.flags.tagsCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 22) {
        updateTutorialStep(22, id);
      }
    }
  }, [tutorialData.flags.tagsCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showCommentModal) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 25) {
        updateTutorialStep(25, id);
      }
    }
  }, [tutorialData.flags.showCommentModal]);

  useEffect(() => {
    if (tutorialData.flags.showCommentAttachment) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 26) {
        updateTutorialStep(26, id);
      }
    }
  }, [tutorialData.flags.showCommentAttachment]);

  useEffect(() => {
    if (tutorialData.flags.commentCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 27) {
        updateTutorialStep(27, id);
      }
    }
  }, [tutorialData.flags.commentCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputSizeHotkeyCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 38) {
        updateTutorialStep(38, id);
      }
    }
  }, [tutorialData.flags.inputSizeHotkeyCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputSizeMouseCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 40) {
        updateTutorialStep(40, id);
      }
    }
  }, [tutorialData.flags.inputSizeMouseCompletion]);

  useEffect(() => {
    if (tutorialData.flags.dueDateCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 45) {
        updateTutorialStep(45, id);
      }
    }
  }, [tutorialData.flags.dueDateCompletion]);

  useEffect(() => {
    if (tutorialData.flags.startEndDateCompletion) {
      const id = accountData.allTutorials[2].id;
      if (accountData.allTutorials[2].currentStep !== 48) {
        updateTutorialStep(48, id);
      }
    }
  }, [tutorialData.flags.startEndDateCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showCreateProjectModal) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 3) {
        updateTutorialStep(3, id);
      }
    }
  }, [tutorialData.flags.showCreateProjectModal]);

  useEffect(() => {
    if (tutorialData.flags.showCreateButton) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 4) {
        updateTutorialStep(4, id);
      }
    }
  }, [tutorialData.flags.showCreateButton]);

  useEffect(() => {
    if (tutorialData.flags.createProjectCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 5) {
        updateTutorialStep(5, id);
      }
    }
  }, [tutorialData.flags.createProjectCompletion]);

  useEffect(() => {
    if (tutorialData.flags.inputProjectTaskAssigneeCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 9) {
        updateTutorialStep(9, id);
      }
    }
  }, [tutorialData.flags.inputProjectTaskAssigneeCompletion]);

  useEffect(() => {
    if (tutorialData.flags.createAndAssignProjectTaskCompleted) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 10) {
        updateTutorialStep(10, id);
      }
    }
  }, [tutorialData.flags.createAndAssignProjectTaskCompleted]);

  useEffect(() => {
    if (tutorialData.flags.showInviteMemberModal) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 13) {
        updateTutorialStep(13, id);
      }
    }
  }, [tutorialData.flags.showInviteMemberModal]);

  useEffect(() => {
    if (tutorialData.flags.showInviteMemberButton) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 14) {
        updateTutorialStep(14, id);
      }
    }
  }, [tutorialData.flags.showInviteMemberButton]);

  useEffect(() => {
    if (tutorialData.flags.invitationCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 15) {
        updateTutorialStep(15, id);
      }
    }
  }, [tutorialData.flags.invitationCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showProjectModal) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 16) {
        updateTutorialStep(16, id);
      }
    }
  }, [tutorialData.flags.showProjectModal]);

  useEffect(() => {
    if (tutorialData.flags.addPeopleCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 17) {
        updateTutorialStep(17, id);
      }
    }
  }, [tutorialData.flags.addPeopleCompletion]);

  useEffect(() => {
    if (tutorialData.flags.editProjectNameModal) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 19) {
        updateTutorialStep(19, id);
      }
    }
  }, [tutorialData.flags.editProjectNameModal]);

  useEffect(() => {
    if (tutorialData.flags.renameProjectCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 20) {
        updateTutorialStep(20, id);
      }
    }
  }, [tutorialData.flags.renameProjectCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showCreateNewFolderModal) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 24) {
        updateTutorialStep(24, id);
      }
    }
  }, [tutorialData.flags.showCreateNewFolderModal]);

  useEffect(() => {
    if (tutorialData.flags.createFolderCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 25) {
        updateTutorialStep(25, id);
      }
    }
  }, [tutorialData.flags.createFolderCompletion]);

  useEffect(() => {
    if (tutorialData.flags.deleteFolderCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 27) {
        updateTutorialStep(27, id);
      }
    }
  }, [tutorialData.flags.deleteFolderCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showArchiveButton) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 29) {
        updateTutorialStep(29, id);
      }
    }
  }, [tutorialData.flags.showArchiveButton]);

  useEffect(() => {
    if (tutorialData.flags.archiveProjectCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 30) {
        updateTutorialStep(30, id);
      }
    }
  }, [tutorialData.flags.archiveProjectCompletion]);

  useEffect(() => {
    if (tutorialData.flags.showArchiveCheckbox) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 32) {
        updateTutorialStep(32, id);
      }
    }
  }, [tutorialData.flags.showArchiveCheckbox]);

  useEffect(() => {
    if (tutorialData.flags.showAdminPage) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 33) {
        updateTutorialStep(33, id);
      }
    }
  }, [tutorialData.flags.showAdminPage]);

  useEffect(() => {
    if (tutorialData.flags.unarchiveCompletion) {
      const id = accountData.allTutorials[3].id;
      if (accountData.allTutorials[3].currentStep !== 34) {
        updateTutorialStep(34, id);
      }
    }
  }, [tutorialData.flags.unarchiveCompletion]);

  useEffect(() => {
    if (accountData.allTutorials.length === 0) {
      getTutorialsData();
    }
  }, []);

  const getTaskname = (step: number) => {
    switch (step) {
      case 18:
      case 19:
        return 'Offboard client';
      case 21:
      case 23:
      case 24:
        return 'Learn how to complete a task';
      case 25:
      case 26:
      case 27:
      case 28:
        return 'Learn how to re-prioritize a task';
      case 33:
        return 'Learn how to change assignee using hotkeys';
      case 34:
      case 35:
        return 'Learn how to change assignee using mouse';
      case 40:
        return 'Learn how to change projects';
      case 43:
      case 44:
        return 'Learn how to change projects using hotkeys';
      case 45:
        return 'Learn how to change projects using mouse';
      case 49:
      case 50:
        return 'Learn how to create tags';
      case 53:
      case 54:
      case 55:
        return 'Learn how to add comments';
      case 66:
        return 'Learn how to change task size using hotkeys';
      case 68:
        return 'Learn how to change task size using mouse';
      case 73:
      case 74:
        return 'Learn how to change due date';
      case 76:
        return 'Learn how to change start and end date';
      case 87:
      case 88:
      case 89:
        return 'My first task';
      default:
        return 'Onboard new client';
    }
  };

  const createTutorialTask = (
    step: number,
    parentIndex?: number,
    parentId?: string,
    dataType?: TaskType,
  ) => {
    addTutorialTask(
      0,
      typeof parentIndex === 'undefined' ? undefined : parentIndex,
      parentId ? parentId : undefined,
      dataType ? dataType : 'BACKLOG',
      null,
      'TUTORIAL',
      'S',
      typeof parentIndex === 'undefined' ? getTaskname(step) : 'My fist sub-task',
    );
  };

  const createTaskWithoutAutoFocus = (
    step: number,
    parentIndex?: number,
    parentId?: string,
    dataType?: TaskType,
  ) => {
    addTutorialTaskWithoutFocus(
      0,
      parentIndex !== undefined && parentIndex > -1 ? parentIndex : undefined,
      parentId ? parentId : undefined,
      dataType ? dataType : 'BACKLOG',
      null,
      'TUTORIAL',
      'S',
      getTaskname(step),
    );
  };

  const getProfileImage = () => {
    if (accountData.account && accountData.account.profileImage) {
      return accountData.account.profileImage;
    }

    return AvatarIcon;
  };

  const addAllTutorialProjects = useSetRecoilState(allTutorialProjectsState);
  const addTutorialFolders = useSetRecoilState(tutorialFoldersState);
  const setTutorialCurrentProjectId = useSetRecoilState(tutorialCurrentProjectIdState);
  const addProject = (addedUser?: boolean, projectName?: string | null) => {
    let tempUsers: UserFieldType[] = [
      {
        id: getUserId(),
        name: getUserName(),
        profileImage: getProfileImage(),
        display: getUserName(),
      },
    ];
    if (addedUser) {
      tempUsers = [
        {
          id: '01',
          name: 'Sudo Innominate',
          profileImage: InnominateIcon,
          display: 'Sudo Innominate',
          email: 'sudo@magictask.com',
        },
        ...tempUsers,
      ];
    }
    const temp: ProjectObjectType = {
      id: '01',
      name: projectName ? projectName : 'MagicTask Tutorial',
      display: projectName ? projectName : 'MagicTask Tutorial',
      archived: false,
      users: tempUsers,
      createdBy: {
        id: getUserId(),
        name: getUserName(),
        profileImage: getProfileImage(),
        email: getUserEmail(),
      },
      status: 'on track',
      modified: new Date().getTime(),
      remainingPoints: 0,
      completedPoints: 0,
    };

    addAllTutorialProjects([temp]);
    const folder: FolderType = {
      archived: false,
      createdBy: {
        id: getUserId(),
        name: getUserName(),
        profileImage: getProfileImage(),
        email: getUserEmail(),
      },
      display: projectName ? projectName : 'MagicTask Tutorial',
      id: '00',
      isOpen: false,
      modified: new Date().getTime(),
      name: projectName ? projectName : 'MagicTask Tutorial',
      sequence: 0,
      projects: [temp],
    };
    addTutorialFolders([folder]);
  };

  const addAllTutorialUsers = useSetRecoilState(allTutorialUsersState);
  const addUser = () => {
    const user: UserFieldType = {
      id: '01',
      name: 'Sudo Innominate',
      display: 'Sudo Innominate',
      profileImage: InnominateIcon,
      email: 'sudo@magictask.com',
    };
    addAllTutorialUsers([user]);
  };

  const addUsers = () => {
    const user = [
      {
        id: '01',
        name: 'Sudo Innominate',
        display: 'Sudo Innominate',
        profileImage: InnominateIcon,
        email: 'sudo@magictask.com',
      },
      {
        id: '02',
        name: 'Sudo Seeker',
        display: 'Sudo Seeker',
        profileImage: SeekerIcon,
        email: 'sudoseeker@magictask.com',
      },
    ];
    addAllTutorialUsers(user);
  };

  const addTwoProjects = () => {
    const projects: ProjectObjectType[] = [
      {
        id: '01',
        name: 'ProjectName',
        archived: false,
        status: 'on track',
        users: [
          {
            id: '01',
            name: 'Sudo Innominate',
            profileImage: InnominateIcon,
          },
          {
            id: '02',
            name: 'Sudo Seeker',
            profileImage: SeekerIcon,
          },
        ],
      },
      {
        id: '02',
        name: 'MagicTask Tutorial',
        archived: false,
        status: 'on track',
        users: [
          {
            id: '01',
            name: 'Sudo Innominate',
            profileImage: InnominateIcon,
          },
          {
            id: '02',
            name: 'Sudo Seeker',
            profileImage: SeekerIcon,
          },
        ],
      },
    ];
    addAllTutorialProjects(projects);
  };

  const setTaskInfoModalStatus = useSetRecoilState(taskDetailModalState);
  const setCreateNewProjectModalStatus = useSetRecoilState(showCreateNewProjectModalState);
  const setFolderForCreateNewProject = useSetRecoilState(folderForCreateNewProjectModalState);
  const setInviteMembersModalStatus = useSetRecoilState(showInviteMembersModalState);
  const setEditProjectModalStatus = useSetRecoilState(showEditProjectModalState);
  const setCreateFolderModalStatus = useSetRecoilState(showCreateFolderModalState);

  useEffect(() => {
    if (navigationData.taskDetailModal.visible) {
      setTaskInfoModalStatus({
        visible: false,
        taskId: null,
        dataType: 'BACKLOG',
      });
    }
    if (navigationData.showCreateNewProjectModal) {
      setCreateNewProjectModalStatus(false);
      setFolderForCreateNewProject(null);
    }
    if (navigationData.showInviteMembersModal) {
      setInviteMembersModalStatus(false);
    }
    if (navigationData.showEditProjectModal) {
      setEditProjectModalStatus(false);
    }
    if (navigationData.showCreateFolderModal) {
      setCreateFolderModalStatus(false);
    }
    if (accountData.allTutorials && accountData.allTutorials.length > 0) {
      let lastIndex = findLastIndex(accountData.allTutorials, 'isCompleted', true);

      if (lastIndex === accountData.allTutorials.length - 1) {
        lastIndex = lastIndex - 1;
      }
      let stepCount = accountData.allTutorials[lastIndex + 1].currentStep;
      for (let i = 0; i <= lastIndex; i++) {
        stepCount = stepCount + accountData.allTutorials[i].totalSteps;
      }
      if (defaultSteps.length <= stepCount) {
        closeTutorialClicked();
        return;
      }
      setCurrentStepCount(stepCount);

      log('setCurrentStepCount', stepCount);
      addAllTutorialUsers([]);

      if (stepCount === 11) {
        if (tutorialData.BACKLOG.data && tutorialData.BACKLOG.data.length > 0)
          addTaskData('BACKLOG', [], 'TUTORIAL');
      } else if (stepCount > 10 && stepCount <= 15) {
        if (
          tutorialData.BACKLOG.data &&
          tutorialData.BACKLOG.data.length > 0 &&
          tutorialData.BACKLOG.data[0].subTasks &&
          tutorialData.BACKLOG.data[0].subTasks.length > 0
        ) {
          resetTutorialTask(
            0,
            undefined,
            undefined,
            'BACKLOG',
            null,
            'TUTORIAL',
            'S',
            getTaskname(stepCount),
          );
        } else if (
          tutorialData.BACKLOG.data &&
          tutorialData.BACKLOG.data.length > 0 &&
          stepCount === 12
        ) {
          resetTutorialTask(
            0,
            undefined,
            undefined,
            'BACKLOG',
            null,
            'TUTORIAL',
            'S',
            getTaskname(stepCount),
          );
        } else if (tutorialData.BACKLOG.data && tutorialData.BACKLOG.data.length === 0) {
          createTutorialTask(stepCount);
        }
      } else if (stepCount === 16) {
        if (tutorialData.BACKLOG.data && tutorialData.BACKLOG.data.length <= 0) {
          addTutorialTaskWithSubTask(
            0,
            0,
            undefined,
            'BACKLOG',
            null,
            'TUTORIAL',
            'S',
            'Onboard new client',
          );
        } else if (
          tutorialData.BACKLOG.data &&
          tutorialData.BACKLOG.data[0].subTasks &&
          tutorialData.BACKLOG.data[0].subTasks.length === 0
        ) {
          createTutorialTask(stepCount, 0, tutorialData.BACKLOG.data[0].id);
        }
      } else if (
        stepCount === 17 ||
        stepCount === 19 ||
        stepCount === 21 ||
        stepCount === 25 ||
        stepCount === 26
      ) {
        if (stepCount === 26) {
          if (tutorialData.FOCUS.data && tutorialData.FOCUS.data.length > 0)
            addTaskData('FOCUS', [], 'TUTORIAL');
        } else if (stepCount === 21) {
          if (tutorialData.COMPLETED.data.length > 0) {
            addCompletedTasks([], false, 0, 0, 0, 'TUTORIAL');
          }
        }
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'BACKLOG',
          null,
          'TUTORIAL',
          'S',
          getTaskname(stepCount),
        );
      } else if (stepCount === 18) {
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'BACKLOG',
          null,
          'TUTORIAL',
          'S',
          getTaskname(stepCount),
        );
      } else if (
        stepCount > 17 &&
        stepCount < 27 &&
        stepCount !== 20 &&
        stepCount !== 22 &&
        stepCount !== 23
      ) {
        if (tutorialData.BACKLOG.data && tutorialData.BACKLOG.data.length === 0) {
          createTaskWithoutAutoFocus(stepCount);
        }
      } else if (stepCount === 23 || stepCount === 22) {
        if (tutorialData.BACKLOG.data && tutorialData.BACKLOG.data.length > 0)
          addTaskData('BACKLOG', [], 'TUTORIAL');
        if (tutorialData.COMPLETED.data.length === 0) {
          addCompletedTaskInTutorial(
            0,
            undefined,
            undefined,
            'BACKLOG',
            null,
            'TUTORIAL',
            'S',
            'Learn how to complete a task',
          );
        } else if (tutorialData.COMPLETED.data.length > 1) {
          addCompletedTasks([tutorialData.COMPLETED.data[0]], false, 0, 50, 1, 'TUTORIAL');
        }
      } else if (stepCount >= 27 && stepCount < 29) {
        if (tutorialData.FOCUS.data && tutorialData.FOCUS.data.length === 0)
          createTaskWithoutAutoFocus(stepCount, undefined, undefined, 'FOCUS');
      } else if (
        (stepCount >= 33 && stepCount <= 35) ||
        stepCount === 49 ||
        stepCount === 66 ||
        stepCount === 68 ||
        stepCount === 53 ||
        stepCount === 54 ||
        stepCount === 55
      ) {
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'BACKLOG',
          null,
          'TUTORIAL',
          stepCount === 68 ? 'L' : 'S',
          getTaskname(stepCount),
          undefined,
          undefined,
          undefined,
          undefined,
          stepCount === 54 || stepCount === 55 ? true : false,
          stepCount === 55 ? true : false,
        );
        if (stepCount >= 33 && stepCount <= 35) {
          addUsers();
        }
      } else if (stepCount === 40 || stepCount === 43 || stepCount === 45) {
        addTwoProjects();
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'BACKLOG',
          null,
          'TUTORIAL',
          'S',
          getTaskname(stepCount),
          '01',
          14,
        );
      } else if (stepCount === 44) {
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'BACKLOG',
          null,
          'TUTORIAL',
          'S',
          getTaskname(stepCount),
          '02',
          1,
        );
      } else if (stepCount === 50) {
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'BACKLOG',
          null,
          'TUTORIAL',
          'S',
          getTaskname(stepCount),
          undefined,
          undefined,
          'tagid1',
        );
      } else if (stepCount === 73 || stepCount === 76) {
        resetTutorialTask(
          0,
          undefined,
          undefined,
          'FOCUS',
          null,
          'TUTORIAL',
          'S',
          getTaskname(stepCount),
        );
      } else if (stepCount === 74 || stepCount === 77) {
        addTaskData('BACKLOG', [], 'TUTORIAL');
      } else if (stepCount === 82 || stepCount === 83) {
        setCreateNewProjectModalStatus(true);
        setFolderForCreateNewProject(null);
        addAllTutorialProjects([]);
        setTutorialCurrentProjectId(null);
      } else if ((stepCount >= 84 && stepCount <= 99) || stepCount === 107 || stepCount === 108) {
        addProject();
        if (stepCount !== 89) {
          setTutorialCurrentProjectId('01');
        }
        if (stepCount === 87) {
          resetTutorialTask(
            0,
            undefined,
            undefined,
            'BACKLOG',
            null,
            'TUTORIAL',
            'S',
            getTaskname(stepCount),
            '01',
          );
        } else if (stepCount === 88 || stepCount === 89) {
          if (tutorialData.BACKLOG.data && tutorialData.BACKLOG.data.length === 0) {
            resetTutorialTask(
              0,
              undefined,
              undefined,
              'BACKLOG',
              getUserId(),
              'TUTORIAL',
              'S',
              getTaskname(stepCount),
              '01',
            );
          }
        } else if (stepCount === 92 || stepCount === 93) {
          setInviteMembersModalStatus(true);
          addAllTutorialUsers([]);
        } else if ((stepCount >= 94 && stepCount <= 99) || stepCount === 107 || stepCount === 108) {
          addUser();
          addProject(stepCount >= 96, stepCount === 99 ? 'MagicTask Project' : null);
          setTutorialCurrentProjectId('01');

          if (stepCount === 95 || stepCount === 96 || stepCount === 98 || stepCount === 108) {
            setEditProjectModalStatus(true);
          }
        } else {
          addTaskData('BACKLOG', [], 'TUTORIAL');
          addTaskData('FOCUS', [], 'TUTORIAL');
        }
      } else if (stepCount === 103) {
        setCreateFolderModalStatus(true);
      } else if (stepCount === 104 || stepCount === 105 || stepCount === 111 || stepCount === 112) {
        getFolderFromTag(stepCount === 112);
      } else {
        addTaskData('BACKLOG', [], 'TUTORIAL');
        addTaskData('FOCUS', [], 'TUTORIAL');
      }

      if (defaultSteps.length <= stepCount) {
        return;
      }

      const currentStep = defaultSteps[stepCount];
      if (currentStep.flagVar !== undefined && currentStep.flagVar !== null) {
        updateFlag(currentStep.flagVar, currentStep.flagVal);
      }
    }
  }, [JSON.stringify(accountData.allTutorials)]);

  const hideModal = () => {
    navigate('/');
  };

  const addTutorialsToAccount = useSetRecoilState(allTutorialsState);

  const closeTutorialClicked = async () => {
    setTaskInfoModalStatus({
      visible: false,
      taskId: null,
      dataType: 'BACKLOG',
    });
    setCreateNewProjectModalStatus(false);
    setFolderForCreateNewProject(null);
    setInviteMembersModalStatus(false);
    setEditProjectModalStatus(false);

    const index = accountData.allTutorials.findIndex((item) => item.isActive === true);
    if (index === -1) {
      hideModal();
      return;
    }
    setCloseLoading(true);
    const { success } = await skipTutorialFromAPI();

    if (success) {
      const newArr = accountData.allTutorials.map((obj) => {
        return {
          ...obj,
          isActive: false,
        };
      });

      addTutorialsToAccount(newArr);
      getTutorialsData();

      setCloseLoading(false);
      hideModal();
    }
  };

  const getGuideImgUrl = () => {
    if (selectedGuide === 'innominate') {
      return InnominateIcon;
    } else {
      return SeekerIcon;
    }
  };

  const goBack = () => {
    if (currentStepCount > 0) {
      const lastIndex = findLastIndex(accountData.allTutorials, 'isCompleted', true);
      if (lastIndex === accountData.allTutorials.length - 1) {
        updateTutorialCompleteStatus(lastIndex, false);
      } else {
        if (accountData.allTutorials[lastIndex + 1].currentStep === 0) {
          updateTutorialCompleteStatus(lastIndex, false);
        } else {
          const stepCount = accountData.allTutorials[lastIndex + 1].currentStep - 1;
          const id = accountData.allTutorials[lastIndex + 1].id;
          updateTutorialStep(stepCount, id);
        }
        return;
      }
    } else {
      closeTutorialClicked();
    }
  };

  const [allTutorials, setAllTutorials] = useRecoilState(allTutorialsState);
  const updateTutorialStep = (stepCount: number, id: string) => {
    const tempData = allTutorials.map((tutorial) => {
      if (tutorial.id === id)
        return {
          ...tutorial,
          currentStep: stepCount,
        };
      return tutorial;
    });
    setAllTutorials(tempData);
    updateTutorialStepFromAPI(stepCount, id);
  };

  // const updateTutorialCompleteStatusInRecoil = (status: boolean, tutorialId: string) => {
  //   const tempData = allTutorials.map((tutorial) => {
  //     if (tutorial.id === tutorialId)
  //       return {
  //         ...tutorial,
  //         isCompleted: status,
  //       };
  //     return tutorial;
  //   });
  //   setAllTutorials(tempData);
  //   updateTutorialCompleteStatusFromAPI(status, tutorialId);
  // };

  const updateTutorialCompleteStatus = (index: number, status: boolean) => {
    const tutorialId = accountData.allTutorials[index].id;
    updateTutorialCompleteStatusFromAPI(status, tutorialId);
    if (status) {
      if (accountData.allTutorials.length > index + 1) {
        // there are more tutorial parts
        const nextTutorialId = accountData.allTutorials[index + 1].id;
        updateTutorialStepFromAPI(0, nextTutorialId);
        const tempData = allTutorials.map((tutorial) => {
          if (tutorial.id === tutorialId)
            return {
              ...tutorial,
              isCompleted: status,
            };
          else if (tutorial.id === nextTutorialId)
            return {
              ...tutorial,
              currentStep: 0,
            };
          return tutorial;
        });
        setAllTutorials(tempData);
      } else {
        hideModal();
      }
    } else {
      updateTutorialStepFromAPI(accountData.allTutorials[index].currentStep - 1, tutorialId);
      const tempData = allTutorials.map((tutorial) => {
        if (tutorial.id === tutorialId)
          return {
            ...tutorial,
            isCompleted: status,
            currentStep: accountData.allTutorials[index].currentStep - 1,
          };
        return tutorial;
      });
      setAllTutorials(tempData);
    }
  };

  // const updateTutorialCompleteStatus = (index: number, status: boolean) => {
  //   let tutorialId = accountData.allTutorials[index].id;
  // updateTutorialCompleteStatusInRecoil(status, tutorialId);

  // if (status) {
  //   if (accountData.allTutorials.length > index + 1) {
  //     // there are more tutorial parts
  //     let nextTutorialId = accountData.allTutorials[index + 1].id;
  //     updateTutorialStep(0, nextTutorialId);
  //   } else {
  //     hideModal();
  //   }
  // } else {
  //   updateTutorialStep(accountData.allTutorials[index].currentStep - 1, tutorialId);
  // }
  // };

  const continueTutorial = () => {
    if (currentStepCount < defaultSteps.length - 1) {
      const lastIndex = findLastIndex(accountData.allTutorials, 'isCompleted', true); // accountData.allTutorials.findIndex((item) => item.isCompleted === true);
      if (accountData.allTutorials.length > lastIndex + 1) {
        if (
          accountData.allTutorials[lastIndex + 1].currentStep + 1 ===
          accountData.allTutorials[lastIndex + 1].totalSteps
        ) {
          updateTutorialCompleteStatus(lastIndex + 1, true);
        } else {
          const stepCount = accountData.allTutorials[lastIndex + 1].currentStep + 1;
          const id = accountData.allTutorials[lastIndex + 1].id;
          updateTutorialStep(stepCount, id);
        }
      } else if (
        accountData.allTutorials.length === lastIndex + 1 &&
        accountData.allTutorials[lastIndex]?.currentStep + 1 ===
          accountData.allTutorials[lastIndex].totalSteps
      ) {
        closeTutorialClicked();
      }
      return;
    } else if (currentStepCount === defaultSteps.length - 1) {
      // last step
      const lastIndex = findLastIndex(accountData.allTutorials, 'isCompleted', true); // accountData.allTutorials.findIndex((item) => item.isCompleted === true);
      if (lastIndex < accountData.allTutorials.length - 1) {
        updateTutorialCompleteStatus(lastIndex + 1, true);
      }
      closeTutorialClicked();
      return;
    }
    hideModal();
  };

  const renderGoBackBtn = () => {
    return (
      <div className='left-btn-con'>
        <SmallButton clicked={goBack}>{Strings.go_back}</SmallButton>
      </div>
    );
  };

  const renderContinueBtn = () => {
    return (
      <div className='right-btn-con'>
        <SmallButton clicked={continueTutorial}>{Strings.got_it_continue}</SmallButton>
      </div>
    );
  };

  const renderGoBackAndContinueBtns = () => {
    return (
      <Fragment>
        {defaultSteps[currentStepCount].goBackBtn ? renderGoBackBtn() : null}
        {defaultSteps[currentStepCount].continueBtn ? renderContinueBtn() : null}
      </Fragment>
    );
  };

  const renderNoThanks = () => {
    return (
      <button className='no-thanks-btn' onClick={closeTutorialClicked}>
        No thanks, tutorials are for plebs.
      </button>
    );
  };

  const renderTutorialCardBottomCon = () => {
    return (
      <div
        className={`tutorial-card-bottom-con ${
          defaultSteps[currentStepCount].noThanksBtn ? 'no-thanks-btn-con' : ''
        }`}
      >
        {defaultSteps[currentStepCount].goBackBtn || defaultSteps[currentStepCount].continueBtn
          ? renderGoBackAndContinueBtns()
          : null}
        {defaultSteps[currentStepCount].noThanksBtn ? renderNoThanks() : null}
      </div>
    );
  };

  const renderColumnLayout = () => {
    const currentStep = defaultSteps[currentStepCount];
    return (
      <Fragment>
        {defaultSteps[currentStepCount].titleImgUrl ? (
          <img src={getGuideImgUrl()} className='tutorial-img' alt='title-image' />
        ) : null}
        {defaultSteps[currentStepCount].title ? renderTitle() : null}
        <div className='tutorial-description'>
          <div
            dangerouslySetInnerHTML={{
              __html: currentStep.subTitle ? currentStep.subTitle : '',
            }}
          ></div>
        </div>
      </Fragment>
    );
  };

  const renderIntroLayout = () => {
    const currentStep = defaultSteps[currentStepCount];
    return (
      <Fragment>
        {defaultSteps[currentStepCount].title ? renderTitle() : null}
        {defaultSteps[currentStepCount].titleImgUrl ? (
          <img
            src={defaultSteps[currentStepCount].titleImgUrl}
            className='logo-img'
            alt='title-image'
          />
        ) : null}
        <div className='tutorial-description'>
          <div
            dangerouslySetInnerHTML={{
              __html: currentStep.subTitle ? currentStep.subTitle : '',
            }}
          ></div>
        </div>
      </Fragment>
    );
  };

  const renderCompletionLayout = () => {
    return (
      <Fragment>
        {defaultSteps[currentStepCount].titleImgUrl ? (
          <img
            src={getGuideImgUrl()}
            className='completion-tutorial-img tutorial-img'
            alt='title-image'
          />
        ) : null}
        {defaultSteps[currentStepCount].contentImgUrl ? (
          <img
            src={
              defaultSteps[currentStepCount].contentImgUrl
                ? defaultSteps[currentStepCount].contentImgUrl
                : ''
            }
            className='completion-content-image content-image'
            alt='content-image'
          />
        ) : null}
        <div className='tutorial-description completion'>
          {defaultSteps[currentStepCount].title}
        </div>
        <div className='tutorial-heading completion'>{defaultSteps[currentStepCount].subTitle}</div>
      </Fragment>
    );
  };

  const renderAvatarLayout = () => {
    return (
      <Fragment>
        {defaultSteps[currentStepCount].title ? renderTitle() : null}
        <div className='guide-img-con'>
          <div
            className='left-guide-img-con'
            onClick={() => {
              setItem('selectedTutorialGuide', 'seeker');
              setSelectedGuide('seeker');
              continueTutorial();
            }}
          >
            {defaultSteps[currentStepCount].image1Url ? (
              <img
                className='left-guide-img'
                src={defaultSteps[currentStepCount].image1Url}
                alt='seeker-image'
              />
            ) : null}
            <h6>{defaultSteps[currentStepCount].image1Title}</h6>
          </div>
          <div
            className='right-guide-img-con'
            onClick={() => {
              setItem('selectedTutorialGuide', 'innominate');
              setSelectedGuide('innominate');
              continueTutorial();
            }}
          >
            {defaultSteps[currentStepCount].image2Url ? (
              <img
                className='right-guide-img'
                src={defaultSteps[currentStepCount].image2Url}
                alt='innominate-image'
              />
            ) : null}
            <h6>{defaultSteps[currentStepCount].image2Title}</h6>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderTitle = () => {
    return <div className='tutorial-heading'>{defaultSteps[currentStepCount].title}</div>;
  };

  const renderRowLayoutImage = () => {
    return (
      <div className='left-guide-img-con'>
        <img src={getGuideImgUrl()} alt='guide-image' />
      </div>
    );
  };

  const renderContentImage = () => {
    return (
      <div className='tutorial-content-img-con'>
        <img
          src={
            defaultSteps[currentStepCount].contentImgUrl
              ? defaultSteps[currentStepCount].contentImgUrl
              : ''
          }
          alt={defaultSteps[currentStepCount].contentImgAlt}
        />
      </div>
    );
  };

  const renderRowLayout = () => {
    const currentStep = defaultSteps[currentStepCount];
    return (
      <Fragment>
        {defaultSteps[currentStepCount].titleImgUrl ? renderRowLayoutImage() : null}
        <div className='right-con'>
          {defaultSteps[currentStepCount].title ? renderTitle() : null}
          <div className='tutorial-description'>
            <div
              dangerouslySetInnerHTML={{
                __html: currentStep.subTitle ? currentStep.subTitle : '',
              }}
            />
            {defaultSteps[currentStepCount].desc ? (
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentStep.desc ? currentStep.desc : '',
                  }}
                />
              </p>
            ) : null}
          </div>
          {defaultSteps[currentStepCount].contentImgUrl ? renderContentImage() : null}
        </div>
      </Fragment>
    );
  };

  const renderTutorialCardTopCon = () => {
    return (
      <div className={`tutorial-card-top-con ${defaultSteps[currentStepCount].topLayout}`}>
        {defaultSteps[currentStepCount].topLayout === 'introduction' ? renderIntroLayout() : null}
        {defaultSteps[currentStepCount].topLayout === 'column' ? renderColumnLayout() : null}
        {defaultSteps[currentStepCount].topLayout === 'avatar' ? renderAvatarLayout() : null}
        {defaultSteps[currentStepCount].topLayout === 'row' ? renderRowLayout() : null}
        {defaultSteps[currentStepCount].topLayout === 'completion'
          ? renderCompletionLayout()
          : null}
      </div>
    );
  };

  const renderCheckbox = (step) => {
    if (step.checked) {
      return (
        <div className='checkbox checked'>
          <FontAwesomeIcon className='checked-icon' icon={faCheck} size='sm' />
        </div>
      );
    } else {
      return <div className='checkbox' />;
    }
  };

  const renderSectionIcon = (section: { title: string; status: string }, index: number) => {
    if (section.status !== 'active') {
      return (
        <div className={`section-icon-con ${section.status}`}>
          <FontAwesomeIcon
            className={`${section.status}-icon`}
            icon={section.status === 'locked' ? faLock : faCheck}
          />
        </div>
      );
    }
    return (
      <div className='section-icon'>
        <h3 className='section-count'>{index + 1}</h3>
      </div>
    );
  };

  const renderTutorialBottomSection = (
    section: { title: string; status: string },
    index: number,
  ) => {
    return (
      <div className='section-con' key={index}>
        {renderSectionIcon(section, index)}
        <h6 className={`section-title ${section.status}`}>{section.title}</h6>
      </div>
    );
  };

  const renderStep = (step, index) => {
    return (
      <div
        className={`step-con ${
          step.checked ? 'checked' : step.progress && step.progress > 0 ? 'highlight' : ''
        }`}
        key={index}
      >
        {renderCheckbox(step)}
        <p className='tutorial-text'>{step.title}</p>
        {step.progress !== null && step.progress !== undefined && step.total ? (
          <p className='tutorial-progress'>{`${step.progress}/${step.total}`}</p>
        ) : null}
      </div>
    );
  };

  const renderStepsCon = () => {
    const currentStep =
      defaultSteps && defaultSteps.length > currentStepCount
        ? defaultSteps[currentStepCount]
        : null;
    return (
      <div className='tutorial-card-steps-con'>
        <h6 className='steps-heading'>{defaultSteps[currentStepCount].stepsTitle}</h6>
        <div className='steps-con'>
          {currentStep &&
            currentStep.sections &&
            currentStep.sections.map((step, index) => renderStep(step, index))}
        </div>
      </div>
    );
  };

  const renderTutorialCard = () => {
    const currentStep =
      defaultSteps && defaultSteps.length > currentStepCount
        ? defaultSteps[currentStepCount]
        : null;
    return (
      <div
        className={`tutorial-card-main-con ${
          currentStep && currentStep.class ? currentStep.class : ''
        } ${currentStep && currentStep.sections && currentStep.sections.length ? 'steps' : ''} ${
          tutorialData.hideTutorial ? 'hide' : ''
        }`}
      >
        <div
          className={`user-tutorial-card-con ${
            currentStep && currentStep.topLayout ? currentStep.topLayout : ''
          }`}
        >
          {renderTutorialCardTopCon()}
          {renderTutorialCardBottomCon()}
        </div>
        {currentStep && currentStep.sections ? renderStepsCon() : ''}
      </div>
    );
  };

  const renderCloseBtn = () => {
    return (
      <div
        className={`close-tutorial-btn-con ${
          defaultSteps[currentStepCount].bottomSteps ? 'add-margin' : ''
        }`}
      >
        <div className='button-con'>
          <SmallButton clicked={closeTutorialClicked} loading={isCloseLoading}>
            {Strings.close_tutorial}
          </SmallButton>
        </div>
      </div>
    );
  };

  const renderBottomSteps = () => {
    const currentStep =
      defaultSteps && defaultSteps.length > currentStepCount
        ? defaultSteps[currentStepCount]
        : null;

    return (
      <div className={`tutorial-sections-con`}>
        {currentStep &&
          currentStep.bottomSteps &&
          currentStep.bottomSteps.map((section, index) =>
            renderTutorialBottomSection(section, index),
          )}
      </div>
    );
  };

  const renderBottomContainer = () => {
    return (
      <div
        className={`tutorial-bottom-container ${
          defaultSteps &&
          defaultSteps.length > currentStepCount &&
          defaultSteps[currentStepCount] &&
          defaultSteps[currentStepCount].class
            ? defaultSteps[currentStepCount].class
            : ''
        } ${defaultSteps[currentStepCount].bottomSteps ? '' : 'no-steps'}`}
      >
        {defaultSteps[currentStepCount].bottomSteps ? renderBottomSteps() : null}
        {defaultSteps[currentStepCount].closeBtn ? renderCloseBtn() : null}
      </div>
    );
  };

  return (
    <div className={`user-tutorial-container`}>
      {renderTutorialCard()}
      {renderBottomContainer()}
    </div>
  );
}
