import { DragDropContext } from 'react-beautiful-dnd';
import { useCallback, useState, Fragment, useRef, useEffect } from 'preact/compat';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  taskDataStateSelector,
  autoFocusSelector,
  focusTaskDataState,
  backlogTaskDataState,
  taskListSelector,
  selectedTasksSelector,
  selectedTaskTempIdsSelector,
  selectedNotificationTaskIdSelector,
} from 'recoil/TaskState';
import {
  projectBacklogTaskDataState,
  projectDataSelector,
  projectFocusTaskDataState,
} from 'recoil/ProjectState';
import {
  userBacklogTaskDataState,
  userDataSelector,
  userFocusTaskDataState,
} from 'recoil/UserState';
import { recurringTaskDataSelector } from 'recoil/RecurringTaskState';
import {
  templateBacklogTaskDataState,
  templateDataSelector,
  templateFocusTaskDataState,
} from 'recoil/TemplateState';
import { log } from 'utils';
import { getUniqueListBy, TASK_INPUT_WAIT_INTERVAL } from 'utils/helpers';
import { getOtherDataType, parseTags } from 'utils/task';
import {
  checkTask,
  getStateTypeMain,
  saveDueDate,
  saveTaskProject,
  saveTaskSize,
  saveTasksSize,
  saveTaskStartEndDates,
  updateDraggingTaskId,
  updateLastSelectedDataType,
  updateSelectedTasks as updateSelectedTasksInState,
  saveTasksProject as saveTasksProjectInState,
  setAutoFocusItem,
  saveTaskAssignee as saveTaskAssigneeInTaskState,
  bulkSaveTaskAssignee as bulkSaveTaskAssigneeInTaskState,
  bulkUpdateStartEndDate,
  bulkUpdateDueDate,
  bulkUpdateCheckStatus,
  saveSubTaskSequence as saveSubTaskSequenceInTaskState,
  saveTaskSequence,
  bulkSaveTaskSequence,
} from 'recoil/TaskState/update';
import TaskItems from 'components/TaskItems';
import { Strings } from 'resources';
import _ from 'underscore';
import { bulkUpdateTaskTags as bulkUpdateTaskTagsAPI } from 'services/TagService';
import { triggerDataSelector } from 'recoil/TriggerState';
import { playTheSound } from 'triggers/utils/sound';
import { runCanvasAnimation } from 'triggers/utils/canvas';
import { getSubtaskCreationDelay, getTaskCreationDelay } from 'triggers/utils/css';
import { reOrderSubtasks } from 'recoil/RecurringTaskState/update';

export default function TaskList(props: TaskListPropsInterface) {
  const { stateType, readOnly } = props;

  const [hideDragIcon, setHideDragIcon] = useState(false);

  const getStateSelector = () => {
    if (stateType === 'PROJECT') return projectDataSelector;
    else if (stateType === 'INDIVIDUAL') return userDataSelector;
    else if (stateType === 'RECURRING') return recurringTaskDataSelector;
    else if (stateType === 'TEMPLATE') return templateDataSelector;
    return taskDataStateSelector;
  };

  const getStateData = useRecoilCallback(({ snapshot }) => () => {
    const loadable = snapshot.getLoadable(getStateSelector());
    if (loadable.state === 'hasValue') {
      return loadable.contents;
    }
  });

  const focusSelectedTasks = useRecoilValue(
    selectedTasksSelector({ dataType: 'FOCUS', stateType }),
  );

  const backlogSelectedTasks = useRecoilValue(
    selectedTasksSelector({ dataType: 'BACKLOG', stateType }),
  );

  const selectedTempIds = useRecoilValue(selectedTaskTempIdsSelector({ stateType }));

  const selectedNotificationTaskId = useRecoilValue(
    selectedNotificationTaskIdSelector({ stateType }),
  );

  const getDraggingTaskId = () => {
    if (stateType === 'RECURRING' || stateType === 'TEMPLATE') return null;
    else {
      const mainData = getStateDataSelectedTasks();
      return mainData ? mainData.selectedTasks.draggingTaskId : null;
    }
  };

  const resetSelectedTasks = () => {
    updateDraggingTaskId(null, stateType);
    updateLastSelectedDataType(null, stateType);
    updateSelectedTasksInState('FOCUS', [], [], stateType);
    updateSelectedTasksInState('BACKLOG', [], [], stateType);
    setHideDragIcon(false);
  };
  /**
   * On before capture
   */
  const onBeforeCapture = (start) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    )
      return;
    const draggableId = start.draggableId.replace('BACKLOG', '').replace('FOCUS', '');
    const mainData = getStateDataSelectedTasks();
    if (mainData) {
      const selected = mainData.selectedTasks.tempIds.find((taskId) => taskId.id === draggableId);
      // if dragging an item that is not selected - unselect all items
      if (!selected || selected.parentId) {
        resetSelectedTasks();
      }
    }
    updateDraggingTaskId(draggableId, stateType);
  };

  /*
   * On Drag end
   *
   * result Object
   *
   * returns null
   */
  const onDragEnd = async (result) => {
    log('onDragEnd', result);

    const { destination, source, type, reason } = result;

    /* If Destination not Set then Return */
    if (!destination || reason === 'CANCEL') {
      updateDraggingTaskId(null, stateType);
      return;
    }

    const sourceIndex = source.index;
    const destIndex = destination.index;

    if (sourceIndex === destIndex && destination.droppableId === source.droppableId) {
      updateDraggingTaskId(null, stateType);
      return;
    }

    // its a subtask
    if (type !== 'droppableItem') {
      const destinationDroppable = destination.droppableId.split('-');
      const destParentId = destinationDroppable[1];
      const destDataType = destinationDroppable[0];
      const sourceDropable = source.droppableId.split('-');
      const sourceParentId = sourceDropable[1];
      const sourceDataType = sourceDropable[0];
      if (stateType === 'RECURRING') {
        reOrderSubtasks(destParentId, sourceIndex, destIndex, destDataType, stateType);
      }

      saveSubTaskSequenceInTaskState(
        destDataType,
        sourceIndex,
        destIndex,
        destParentId,
        stateType,
        sourceParentId,
        sourceDataType,
      );
    } else {
      if (
        stateType !== 'PROJECT' &&
        stateType !== 'INDIVIDUAL' &&
        stateType !== null &&
        stateType !== undefined
      ) {
        await saveTaskSequence(
          destination.droppableId,
          sourceIndex,
          destIndex,
          source.droppableId !== destination.droppableId ? source.droppableId : null,
          stateType,
        );
        updateDraggingTaskId(null, stateType);
        return;
      }
      const mainDataMulti = getStateDataSelectedTasks();
      if (
        mainDataMulti &&
        (mainDataMulti.selectedTasks.FOCUS.length > 1 ||
          mainDataMulti.selectedTasks.BACKLOG.length > 1)
      ) {
        log('list drag end', destination, source, type, reason);
        bulkSaveTaskSequence(
          destination.droppableId,
          sourceIndex,
          destIndex,
          source.droppableId !== destination.droppableId ? source.droppableId : null,
          stateType,
        );
        resetSelectedTasks();
        return;
      }
      await saveTaskSequence(
        destination.droppableId,
        sourceIndex,
        destIndex,
        source.droppableId !== destination.droppableId ? source.droppableId : null,
        stateType,
      );
    }
    updateDraggingTaskId(null, stateType);
  };
  const renderCompletedTasks = () => {
    return null;
  };

  const focusTasks = useRecoilValue(taskListSelector({ dataType: 'FOCUS', stateType }));

  const backlogTasks = useRecoilValue(taskListSelector({ dataType: 'BACKLOG', stateType }));

  const getFocusTitle = () => {
    if (stateType === 'RECURRING') return 'Tasks';

    return stateType === 'PROJECT' ? 'Focus' : 'My Focus';
  };

  /*
   * State for input refs
   */
  const [inputRefs, setInputRefs] = useState({});
  const isInitialMount = useRef(true);
  const autoFocus = useRecoilValue(autoFocusSelector(stateType));
  const triggerData = useRecoilValue(triggerDataSelector);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (autoFocus) {
        let input = inputRefs[autoFocus];

        const isSubtask =
          input && input.className && input.className.includes('subtask') ? true : false;

        if (isSubtask) {
          if (triggerData.soundTriggers.createSubTask) {
            playTheSound(triggerData.soundTriggers.createSubTask);
          }
        } else {
          if (triggerData.soundTriggers.createTask) {
            playTheSound(triggerData.soundTriggers.createTask);
          }

          if (triggerData.canvasTriggers.createTask && input) {
            const { id } = input;
            const tempId = id.replace('textarea-', '');
            runCanvasAnimation(triggerData.canvasTriggers.createTask, {
              tempId,
            });
          }
        }

        const delay = isSubtask ? getSubtaskCreationDelay() : getTaskCreationDelay();

        setTimeout(() => {
          input = inputRefs[autoFocus];
          if (input) {
            setAutoFocusItem(null, stateType);
            input.focus();
            log('FOCUS INPUT', inputRefs[autoFocus]);
          }
        }, delay);
      }
    }
  }, [autoFocus]);

  /**
   * On window click
   */
  const onWindowClick = useCallback(
    (e) => {
      if (e.defaultPrevented) {
        return;
      }
      resetSelectedTasks();
      // setSelectedTaskIds([]);
    },
    [stateType],
  );

  /**
   * On window key down
   */
  const onWindowKeyDown = useCallback(
    (e) => {
      if (e.defaultPrevented) {
        return;
      }

      if (e.key === 'Escape') {
        // setSelectedTaskIds([]);
        resetSelectedTasks();
      }
    },
    [stateType],
  );

  /**
   * On window touch end
   */
  const onWindowTouchEnd = useCallback(
    (e) => {
      if (e.defaultPrevented) {
        return;
      }

      // setSelectedTaskIds([]);
      resetSelectedTasks();
    },
    [stateType],
  );

  /**
   * Event Listener
   */
  useEffect(() => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    )
      return;
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, [stateType]);
  /*
   * Store input refs
   *
   * id String
   * ref Object
   *
   * returns null
   */
  const storeInputRefs = (id: string, ref: HTMLElement) => {
    inputRefs[id] = ref;
    setInputRefs(inputRefs);
  };

  const toggleSelectionInGroup = (
    id: string,
    tempId: string,
    parentId: string | undefined,
    dataType: TaskType,
  ) => {
    updateSelectedTasks(id, tempId, parentId, dataType);
    log('toggleSelectionInGroup', id, dataType);
  };

  const multiSelectTo = (
    id: string,
    tempId: string,
    parentId: string | undefined,
    dataType: TaskType,
  ) => {
    log('multiSelectTo', id, dataType);
    setHideDragIcon(false);
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined &&
      stateType !== 'USER'
    )
      return;

    const mainData = getStateDataSelectedTasks();
    let newTaskIds: SelectedTaskIdsType[] = [];
    let newTempIds: SelectedTaskIdsType[] = [];
    if (!mainData || !mainData[dataType].data) return;

    if (!mainData.selectedTasks.FOCUS.length && !mainData.selectedTasks.BACKLOG.length) {
      newTaskIds = [{ id: id, parentId: parentId }];
      newTempIds = [{ id: tempId, parentId: parentId }];
      if (parentId) {
        setHideDragIcon(true);
      }
    } else {
      let indexOfNew = -1;
      let indexOfNewSubtask = -1;
      if (parentId) {
        indexOfNew = mainData[dataType].data!.findIndex((item) => item.id === parentId);
        if (indexOfNew > -1 && !mainData[dataType].data![indexOfNew].subTasks) return;
        indexOfNewSubtask =
          indexOfNew !== -1
            ? mainData[dataType].data![indexOfNew].subTasks!.findIndex((item) => item.id === id)
            : -1;
      } else {
        indexOfNew = mainData[dataType].data!.findIndex((item) => item.id === id);
      }
      const lastSelectedDataType = mainData.selectedTasks.lastSelectedTaskType;
      if (!lastSelectedDataType) return;
      const lastSelected = mainData.selectedTasks[lastSelectedDataType].at(-1);
      let lastSelectedIndex = -1;
      let lastSelectedSubtaskIndex = -1;
      if (!lastSelected) return;
      if (lastSelected.parentId) {
        if (!mainData[lastSelectedDataType].data) return;
        lastSelectedIndex = mainData[lastSelectedDataType].data!.findIndex(
          (item) => item.id === lastSelected.parentId,
        );
        if (!mainData[lastSelectedDataType].data![lastSelectedIndex].subTasks) return;
        lastSelectedSubtaskIndex =
          lastSelectedIndex > -1
            ? mainData[lastSelectedDataType].data![lastSelectedIndex].subTasks!.findIndex(
                (item) => item.id === lastSelected.id,
              )
            : -1;
      } else {
        if (!mainData[lastSelectedDataType].data) return;
        lastSelectedIndex = mainData[lastSelectedDataType].data!.findIndex(
          (item) => item.id === lastSelected.id,
        );
      }

      // multi selecting to another column
      // select everything up to the index of the current item
      if (lastSelectedDataType !== dataType) {
        let newIds2: SelectedTaskIdsType[] = [];
        const otherDataType = getOtherDataType(dataType);
        if (lastSelectedDataType === 'FOCUS') {
          const newArr: TaskObjectType[] = mainData[dataType].data!.slice(0, indexOfNew + 1);
          let newIds: SelectedTaskIdsType[] = [];
          let selTempIds: SelectedTaskIdsType[] = [];

          newArr.forEach((item, index) => {
            if (item.subTasks && item.subTasks.length > 0 && index !== indexOfNew) {
              const arr: SelectedTaskIdsType[] = [
                { id: item.id, parentId: undefined },
                ...item.subTasks.map((x) => ({ id: x.id, parentId: item.id })),
              ];
              const arrTemp = [
                { id: item.tempId, parentId: undefined },
                ...item.subTasks.map((x) => ({
                  id: x.tempId,
                  parentId: item.id,
                })),
              ];
              newIds = [...newIds, ...arr];
              selTempIds = [...selTempIds, ...arrTemp];
            } else {
              newIds = [...newIds, { id: item.id, parentId: undefined }];
              selTempIds = [...selTempIds, { id: item.tempId, parentId: undefined }];
            }
          });
          if (indexOfNewSubtask > -1) {
            const parentTask = mainData[dataType].data![indexOfNew];
            if (parentTask.subTasks) {
              newIds = [
                ...newIds,
                ...parentTask.subTasks
                  .slice(0, indexOfNewSubtask + 1)
                  .map((x) => ({ id: x.id, parentId: parentTask.id })),
              ];
              newTempIds = [
                ...newTempIds,
                ...parentTask.subTasks
                  .slice(0, indexOfNewSubtask + 1)
                  .map((x) => ({ id: x.tempId, parentId: parentTask.id })),
              ];
            }
          }
          newTaskIds = [...mainData.selectedTasks[dataType], ...newIds];
          newTempIds = [...mainData.selectedTasks.tempIds, ...selTempIds];
          const otherArr = mainData[otherDataType].data?.slice(
            lastSelectedSubtaskIndex > -1 ? lastSelectedIndex + 1 : lastSelectedIndex,
            mainData[otherDataType].data?.length,
          );
          if (lastSelectedSubtaskIndex > -1) {
            const parentTask = mainData[lastSelectedDataType].data![lastSelectedIndex];
            if (parentTask.subTasks) {
              newIds2 = [
                ...newIds2,
                ...parentTask.subTasks
                  .slice(lastSelectedSubtaskIndex, parentTask.subTasks.length)
                  .map((x) => ({ id: x.id, parentId: parentTask.id })),
              ];
              newTempIds = [
                ...newTempIds,
                ...parentTask.subTasks
                  .slice(lastSelectedSubtaskIndex, parentTask.subTasks.length)
                  .map((x) => ({ id: x.tempId, parentId: parentTask.id })),
              ];
            }
          }
          otherArr &&
            otherArr.forEach((item, index) => {
              if (
                item.subTasks &&
                item.subTasks.length > 0 &&
                (index !== lastSelectedIndex || lastSelectedSubtaskIndex === -1)
              ) {
                const arr = [
                  { id: item.id, parentId: undefined },
                  ...item.subTasks.map((x) => ({
                    id: x.id,
                    parentId: item.id,
                  })),
                ];
                const arrTemp = [
                  { id: item.tempId, parentId: undefined },
                  ...item.subTasks.map((x) => ({
                    id: x.tempId,
                    parentId: item.id,
                  })),
                ];
                newIds2 = [...newIds2, ...arr];
                newTempIds = [...newTempIds, ...arrTemp];
              } else {
                newIds2 = [...newIds2, { id: item.id, parentId: undefined }];
                newTempIds = [...newTempIds, { id: item.tempId, parentId: undefined }];
              }
            });
        } else {
          const newArr = mainData[dataType].data!.slice(
            indexOfNewSubtask > -1 ? indexOfNew + 1 : indexOfNew,
            mainData[dataType].data!.length,
          );
          let newIds: SelectedTaskIdsType[] = [];
          let selTempIds: SelectedTaskIdsType[] = [];

          if (indexOfNewSubtask > -1) {
            const parentTask = mainData[dataType].data![indexOfNew];
            if (parentTask.subTasks) {
              newIds = [
                ...newIds,
                ...parentTask.subTasks
                  .slice(indexOfNewSubtask, parentTask.subTasks.length)
                  .map((x) => ({ id: x.id, parentId: parentTask.id })),
              ];
              selTempIds = [
                ...selTempIds,
                ...parentTask.subTasks
                  .slice(indexOfNewSubtask, parentTask.subTasks.length)
                  .map((x) => ({ id: x.tempId, parentId: parentTask.id })),
              ];
            }
          }

          newArr.forEach((item) => {
            if (item.subTasks && item.subTasks.length > 0) {
              const arr = [
                { id: item.id, parentId: undefined },
                ...item.subTasks.map((x) => ({ id: x.id, parentId: item.id })),
              ];
              const arrTemp = [
                { id: item.tempId, parentId: undefined },
                ...item.subTasks.map((x) => ({
                  id: x.tempId,
                  parentId: item.id,
                })),
              ];
              newIds = [...newIds, ...arr];
              selTempIds = [...selTempIds, ...arrTemp];
            } else {
              newIds = [...newIds, { id: item.id, parentId: undefined }];
              selTempIds = [...selTempIds, { id: item.tempId, parentId: undefined }];
            }
          });

          newTaskIds = [...mainData.selectedTasks[dataType], ...newIds];
          newTempIds = [...mainData.selectedTasks.tempIds, ...selTempIds];
          const otherDataType = getOtherDataType(dataType);
          const otherArr = mainData[otherDataType].data?.slice(
            0,
            lastSelectedSubtaskIndex > -1 ? lastSelectedIndex : lastSelectedIndex + 1,
          );
          otherArr?.forEach((item, index) => {
            if (item.subTasks && item.subTasks.length > 0 && index !== lastSelectedIndex) {
              const arr = [
                { id: item.id, parentId: undefined },
                ...item.subTasks.map((x) => ({ id: x.id, parentId: item.id })),
              ];
              const arrTemp = [
                { id: item.tempId, parentId: undefined },
                ...item.subTasks.map((x) => ({
                  id: x.tempId,
                  parentId: item.id,
                })),
              ];
              newIds2 = [...newIds2, ...arr];
              newTempIds = [...newTempIds, ...arrTemp];
            } else {
              newIds2 = [...newIds2, { id: item.id, parentId: undefined }];
              newTempIds = [...newTempIds, { id: item.tempId, parentId: undefined }];
            }
          });
          if (lastSelectedSubtaskIndex > -1 && mainData[lastSelectedDataType].data) {
            const parentTask = mainData[lastSelectedDataType].data![lastSelectedIndex];
            if (parentTask.subTasks) {
              newIds2 = [
                ...newIds2,
                ...[
                  { id: parentTask.id, parentId: undefined },
                  ...parentTask.subTasks
                    .slice(0, lastSelectedSubtaskIndex + 1)
                    .map((x) => ({ id: x.id, parentId: parentTask.id })),
                ],
              ];
              newTempIds = [
                ...newTempIds,
                ...[
                  { id: parentTask.tempId, parentId: undefined },
                  ...parentTask.subTasks
                    .slice(0, lastSelectedSubtaskIndex + 1)
                    .map((x) => ({ id: x.tempId, parentId: parentTask.id })),
                ],
              ];
            }
          }
        }

        const finalIdArr = getUniqueListBy(
          [...mainData.selectedTasks[otherDataType], ...newIds2],
          'id',
        );
        const finalTempArr = getUniqueListBy(newTempIds, 'id');
        updateSelectedTasksInRecoil(otherDataType, finalIdArr, finalTempArr, mainData);
      } else {
        // multi selecting in the same column
        // need to select everything between the last index and the current index inclusive
        // nothing to do here
        if (
          indexOfNew === lastSelectedIndex &&
          indexOfNewSubtask === -1 &&
          lastSelectedSubtaskIndex === -1
        ) {
          return null;
        }
        let isSelectingForwards = true;
        if (indexOfNew !== lastSelectedIndex) {
          isSelectingForwards = indexOfNew > lastSelectedIndex;
        } else {
          isSelectingForwards = indexOfNewSubtask > lastSelectedSubtaskIndex;
        }
        let start =
          indexOfNew === lastSelectedIndex &&
          indexOfNewSubtask > -1 &&
          lastSelectedSubtaskIndex > -1
            ? -1
            : isSelectingForwards
              ? lastSelectedIndex
              : indexOfNew;
        let end =
          indexOfNew === lastSelectedIndex &&
          indexOfNewSubtask > -1 &&
          lastSelectedSubtaskIndex > -1
            ? -1
            : isSelectingForwards
              ? indexOfNew
              : lastSelectedIndex;
        if (indexOfNew !== lastSelectedIndex) {
          if (isSelectingForwards) {
            if (lastSelectedSubtaskIndex > -1) {
              start = lastSelectedIndex + 1;
            }
            if (indexOfNewSubtask > -1) {
              end = indexOfNew - 1;
            }
          } else {
            if (indexOfNewSubtask > -1) {
              start = indexOfNew + 1;
            }
            if (lastSelectedSubtaskIndex > -1) {
              end = lastSelectedIndex - 1;
            }
          }
        }

        let inBetweenIds: SelectedTaskIdsType[] = [];
        let inBetweenTempIds: SelectedTaskIdsType[] = [];
        let endSubTasksList: TaskObjectType[] = [];
        let startSubTasksList: TaskObjectType[] = [];

        let startParentTask: TaskObjectType | null = null;
        let endParentTask: TaskObjectType | null = null;

        if (
          indexOfNew === lastSelectedIndex &&
          indexOfNewSubtask > -1 &&
          lastSelectedSubtaskIndex > -1
        ) {
          const startSubtask = isSelectingForwards ? lastSelectedSubtaskIndex : indexOfNewSubtask;
          const endSubtask = isSelectingForwards ? indexOfNewSubtask : lastSelectedSubtaskIndex;
          if (!mainData[dataType].data![indexOfNew].subTasks) return;
          const inBetween: TaskObjectType[] = mainData[dataType].data![indexOfNew].subTasks!.slice(
            startSubtask,
            endSubtask + 1,
          );
          inBetween.forEach((item) => {
            inBetweenIds = [
              ...inBetweenIds,
              {
                id: item.id,
                parentId: mainData[dataType].data![indexOfNew].id,
              },
            ];
            inBetweenTempIds = [
              ...inBetweenTempIds,
              {
                id: item.tempId,
                parentId: mainData[dataType].data![indexOfNew].id,
              },
            ];
          });
        } else {
          if (indexOfNewSubtask > -1) {
            const parentTask = mainData[dataType].data![indexOfNew];
            if (!parentTask.subTasks) return;
            const temp = parentTask.subTasks.slice(
              isSelectingForwards ? 0 : indexOfNewSubtask,
              isSelectingForwards ? indexOfNewSubtask + 1 : parentTask.subTasks.length,
            );
            if (isSelectingForwards) {
              endSubTasksList =
                end === -1
                  ? temp
                  : [
                      {
                        id: parentTask.id,
                        parentId: undefined,
                        tempId: parentTask.tempId,
                        sequence: 0,
                      },
                      ...temp,
                    ];
              endParentTask = parentTask;
            } else {
              startSubTasksList = temp;
              startParentTask = parentTask;
            }
          }

          if (lastSelectedSubtaskIndex > -1 && mainData[lastSelectedDataType].data) {
            const parentTask = mainData[lastSelectedDataType].data![lastSelectedIndex];
            if (!parentTask.subTasks) return;
            const temp = parentTask.subTasks.slice(
              isSelectingForwards ? lastSelectedSubtaskIndex : 0,
              isSelectingForwards ? parentTask.subTasks.length : lastSelectedSubtaskIndex + 1,
            );
            if (isSelectingForwards) {
              startSubTasksList = temp;
              startParentTask = parentTask;
            } else {
              endSubTasksList =
                end === -1
                  ? temp
                  : [
                      {
                        id: parentTask.id,
                        parentId: undefined,
                        tempId: parentTask.tempId,
                        sequence: 0,
                      },
                      ...temp,
                    ];
              endParentTask = parentTask;
            }
          }

          inBetweenIds = startSubTasksList.map((item) => ({
            id: item.id,
            parentId: startParentTask ? startParentTask.id : undefined,
          }));
          inBetweenTempIds = startSubTasksList.map((item) => ({
            id: item.tempId,
            parentId: startParentTask ? startParentTask.id : undefined,
          }));

          if (!mainData[dataType].data) return;
          const inBetween = mainData[dataType].data!.slice(start, end + 1);

          inBetween.forEach((item) => {
            if (
              item.subTasks &&
              item.subTasks.length > 0 &&
              !inBetweenIds.find((e) => e.id === item.id) &&
              !endSubTasksList.find((e) => e.id === item.id)
            ) {
              const arr = [
                { id: item.id, parentId: undefined },
                ...item.subTasks.map((x) => ({ id: x.id, parentId: item.id })),
              ];
              const arrTemp = [
                { id: item.tempId, parentId: undefined },
                ...item.subTasks.map((x) => ({
                  id: x.tempId,
                  parentId: item.id,
                })),
              ];
              inBetweenIds = [...inBetweenIds, ...arr];
              inBetweenTempIds = [...inBetweenTempIds, ...arrTemp];
            } else {
              inBetweenIds = [...inBetweenIds, { id: item.id, parentId: undefined }];
              inBetweenTempIds = [...inBetweenTempIds, { id: item.tempId, parentId: undefined }];
            }
          });

          if (endSubTasksList.length > 0) {
            inBetweenIds = [
              ...inBetweenIds,
              ...endSubTasksList.map((item) => ({
                id: item.id,
                parentId:
                  item.comments !== undefined && endParentTask ? endParentTask.id : undefined,
              })),
            ];
            inBetweenTempIds = [
              ...inBetweenTempIds,
              ...endSubTasksList.map((item) => ({
                id: item.tempId,
                parentId:
                  item.comments !== undefined && endParentTask ? endParentTask.id : undefined,
              })),
            ];
          }
        }
        const toAdd = inBetweenIds.filter((task) => {
          // if already selected: then no need to select it again
          if (mainData.selectedTasks[dataType].find((e) => e.id === task.id)) {
            return false;
          }
          return true;
        });

        const toAddTempIds = inBetweenTempIds.filter((task) => {
          // if already selected: then no need to select it again
          if (mainData.selectedTasks.tempIds.find((e) => e.id === task.id)) {
            return false;
          }
          return true;
        });

        const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
        newTaskIds = [...mainData.selectedTasks[dataType], ...sorted];
        const sortedTempIds = isSelectingForwards ? toAddTempIds : [...toAddTempIds].reverse();
        newTempIds = [...mainData.selectedTasks.tempIds, ...sortedTempIds];
      }
    }
    const finalIdArr = getUniqueListBy(newTaskIds, 'id');
    const finalTempArr = getUniqueListBy(newTempIds, 'id');
    updateLastSelectedDataType(dataType, stateType);
    updateSelectedTasksInRecoil(dataType, finalIdArr, finalTempArr, mainData);
  };

  const updateSelectedTasksInRecoil = (
    dataType: TaskType,
    selectedTasksList: SelectedTaskIdsType[],
    selectedTasksTempIdList: SelectedTaskIdsType[],
    data: TaskDataType | ProjectDataType | UserDataType,
  ) => {
    updateSelectedTasksInState(dataType, selectedTasksList, selectedTasksTempIdList, stateType);
    if (!checkIfAllSubTasksSelected(selectedTasksList, dataType, data)) {
      checkIfAllSubTasksSelected(
        data.selectedTasks[getOtherDataType(dataType)],
        getOtherDataType(dataType),
        data,
      );
    }
  };

  const checkIfAllSubTasksSelected = (
    selectedList: SelectedTaskIdsType[],
    dataType: TaskType,
    data: TaskDataType | UserDataType | ProjectDataType,
  ) => {
    if (
      selectedList.length + data.selectedTasks[getOtherDataType(dataType)].length === 1 ||
      !data[dataType].data
    ) {
      setHideDragIcon(false);
      return;
    }
    const subTasks = selectedList.filter((item) => item.parentId !== undefined);
    setHideDragIcon(false);
    if (subTasks.length > 0) {
      const parentIdList = [...new Set(subTasks.map((item) => item.parentId))];
      for (const taskId of parentIdList) {
        if (!selectedList.find((item) => item.id === taskId)) {
          setHideDragIcon(true);
          return true;
        }
        const indexOfParent = data[dataType].data!.findIndex((item) => item.id === taskId);
        if (indexOfParent > -1) {
          const parentTask = data[dataType].data![indexOfParent];
          const selectedSubTasks = selectedList.filter((item) => item.parentId === parentTask.id);
          if (!parentTask.subTasks || selectedSubTasks.length !== parentTask.subTasks.length) {
            setHideDragIcon(true);
            return true;
          }
        }
      }
    }
  };

  const getSelectedTasksList = (
    id: string,
    parentId: string | undefined,
    list: SelectedTaskIdsType[],
  ) => {
    let temp = list;
    const index = temp.findIndex((item) => item.id === id);
    if (index !== -1) {
      temp = list.filter((item) => item.id !== id);
    } else {
      temp = [...list, { id: id, parentId: parentId }];
    }
    return temp;
  };

  const updateSelectedTasks = (
    id: string,
    tempId: string,
    parentId: string | undefined,
    dataType: TaskType,
  ) => {
    const mainData = getStateDataSelectedTasks();
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    )
      return;
    if (mainData)
      updateSelectedTasksInRecoil(
        dataType,
        getSelectedTasksList(id, parentId, mainData.selectedTasks[dataType]),
        getSelectedTasksList(tempId, parentId, mainData.selectedTasks.tempIds),
        mainData,
      );
    updateLastSelectedDataType(dataType, stateType);
  };

  const toggleSelection = (
    id: string,
    tempId: string,
    parentId: string | undefined,
    dataType: TaskType,
  ) => {
    log('toggleSelectionTest', id, dataType);
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    )
      return;

    const mainData = getStateDataSelectedTasks();
    if (!mainData) return;
    const wasSelected =
      mainData.selectedTasks.FOCUS.find((e) => e.id === id) ||
      mainData.selectedTasks.BACKLOG.find((e) => e.id === id);
    const tempWasSelected = mainData.selectedTasks.tempIds.find((e) => e.id === tempId);

    const newTaskTempIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!tempWasSelected) {
        return [{ id: tempId, parentId: parentId }];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (mainData.selectedTasks.tempIds.length > 1) {
        return [{ id: tempId, parentId: parentId }];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();
    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [{ id: id, parentId: parentId }];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (mainData.selectedTasks.FOCUS.length > 1 || mainData.selectedTasks.BACKLOG.length > 1) {
        updateSelectedTasksInState(getOtherDataType(dataType), [], newTaskTempIds, stateType);
        return [{ id: id, parentId: parentId }];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    if (!wasSelected && mainData.selectedTasks[getOtherDataType(dataType)].length > 0) {
      updateSelectedTasksInState(getOtherDataType(dataType), [], newTaskTempIds, stateType);
    }

    updateSelectedTasksInRecoil(
      dataType,
      newTaskIds,
      newTaskIds.length === 0 && mainData.selectedTasks[getOtherDataType(dataType)].length
        ? []
        : newTaskTempIds,
      mainData,
    );
    updateLastSelectedDataType(dataType, stateType);
  };

  const getSelector = () => {
    if (stateType === 'PROJECT') return projectDataSelector;
    else if (stateType === 'INDIVIDUAL') return userDataSelector;
    return taskDataStateSelector;
  };

  const getStateDataSelectedTasks = useRecoilCallback(({ snapshot }) => () => {
    const loadable = snapshot.getLoadable(getSelector());
    if (loadable.state === 'hasValue') {
      return loadable.contents;
    }
  });

  const updateSizeGroup = (
    index: number,
    id: string,
    size: SizeOptionsType,
    parentIndex?: number,
    parentId?: string,
    dataType?: TaskType,
    stateType?: StateType,
  ) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    ) {
      saveTaskSize(index, id, size, parentIndex, parentId, dataType, stateType);

      return;
    }
    const mainData = getStateDataSelectedTasks();

    if (
      mainData &&
      ((dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 0 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === id)) ||
        (dataType === 'BACKLOG' &&
          mainData.selectedTasks.BACKLOG.length > 0 &&
          mainData.selectedTasks.BACKLOG.find((e) => e.id === id)))
    ) {
      saveTasksSize(
        mainData.selectedTasks.FOCUS.map((item) => item.id),
        mainData.selectedTasks.BACKLOG.map((item) => item.id),
        size,
        stateType,
      );
    } else {
      saveTaskSize(index, id, size, parentIndex, parentId, dataType, stateType);
    }
  };

  const saveTaskAssignee = (
    newAssigneeId: string | null,
    dataType: TaskType,
    index: number,
    taskId: string,
    parentIndex?: number,
    parentId?: string,
    taskName?: string,
    stateType?: StateType,
  ) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    ) {
      saveTaskAssigneeInTaskState(
        newAssigneeId,
        dataType,
        taskId,
        parentIndex,
        parentId,
        stateType,
      );
      return;
    }
    const mainData = getStateDataSelectedTasks();
    if (
      mainData &&
      ((dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 1 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === taskId)) ||
        (dataType === 'BACKLOG' &&
          mainData.selectedTasks.BACKLOG.length > 1 &&
          mainData.selectedTasks.BACKLOG.find((e) => e.id === taskId)))
    ) {
      bulkSaveTaskAssigneeInTaskState(
        mainData.selectedTasks.FOCUS.map((item) => item.id),
        mainData.selectedTasks.BACKLOG.map((item) => item.id),
        newAssigneeId,
        stateType,
      );
    } else {
      saveTaskAssigneeInTaskState(
        newAssigneeId,
        dataType,
        taskId,
        parentIndex,
        parentId,
        taskName,
        stateType,
      );
    }
  };

  const saveTasksProject = (
    index: number,
    id: string,
    newProjectId: string | null,
    parentIndex: number | undefined,
    parentId: string | undefined,
    dataType: TaskType,
    stateType?: StateType,
    taskName?: string,
  ) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    ) {
      saveTaskProject(index, id, newProjectId, parentIndex, parentId, dataType, stateType);
      return;
    }
    const mainData = getStateTypeMain(stateType);

    if (
      (dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 1 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === id)) ||
      (dataType === 'BACKLOG' &&
        mainData.selectedTasks.BACKLOG.length > 1 &&
        mainData.selectedTasks.BACKLOG.find((e) => e.id === id))
    ) {
      saveTasksProjectInState(
        mainData.selectedTasks.FOCUS.map((item) => item.id),
        mainData.selectedTasks.BACKLOG.map((item) => item.id),
        newProjectId,
        stateType,
      );
    } else {
      saveTaskProject(
        index,
        id,
        newProjectId,
        parentIndex,
        parentId,
        dataType,
        stateType,
        taskName,
      );
    }
  };

  const saveTasksStartEndDate = (
    dataType: TaskType,
    index: number,
    id: string,
    startDate: Date | null,
    endDate: Date | null,
    parentIndex?: number,
    parentId?: string,
    stateType?: StateType,
  ) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    ) {
      saveTaskStartEndDates(
        dataType,
        index,
        id,
        startDate,
        endDate,
        parentIndex,
        parentId,
        stateType,
      );
      return;
    }
    const mainData = getStateTypeMain(stateType);
    if (
      (dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 0 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === id)) ||
      (dataType === 'BACKLOG' &&
        mainData.selectedTasks.BACKLOG.length > 0 &&
        mainData.selectedTasks.BACKLOG.find((e) => e.id === id))
    ) {
      bulkUpdateStartEndDate(
        mainData.selectedTasks.FOCUS.map((item) => item.id),
        mainData.selectedTasks.BACKLOG.map((item) => item.id),
        startDate,
        endDate,
        stateType,
      );
    } else
      saveTaskStartEndDates(
        dataType,
        index,
        id,
        startDate,
        endDate,
        parentIndex,
        parentId,
        stateType,
      );
  };

  const saveTasksDueDate = (
    dataType: TaskType,
    index: number,
    id: string,
    date: Date,
    parentIndex?: number,
    parentId?: string,
    stateType?: StateType,
  ) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== null &&
      stateType !== undefined
    ) {
      saveDueDate(dataType, index, id, date, parentIndex, parentId, stateType);
      return;
    }
    const mainData = getStateData();
    if (
      mainData &&
      ((dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 0 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === id)) ||
        (dataType === 'BACKLOG' &&
          mainData.selectedTasks.BACKLOG.length > 0 &&
          mainData.selectedTasks.BACKLOG.find((e) => e.id === id)))
    ) {
      bulkUpdateDueDate(
        mainData.selectedTasks.FOCUS.map((item) => item.id),
        mainData.selectedTasks.BACKLOG.map((item) => item.id),
        date,
        stateType,
      );
    } else saveDueDate(dataType, index, id, date, parentIndex, parentId, stateType);
  };

  const checkGroupTask = _.debounce(
    (
      index: number,
      id: string,
      dataType: TaskType,
      parentIndex: number | undefined,
      parentId: string | undefined,
      stateType: StateType | undefined,
      checked: boolean,
    ) => checkTask(index, id, dataType, parentIndex, parentId, undefined, stateType, checked),
    TASK_INPUT_WAIT_INTERVAL,
    false,
  );

  const checkTasks = (
    index: number,
    id: string,
    dataType: TaskType,
    parentIndex: number | undefined,
    parentId: string | undefined,
    stateType: StateType | undefined,
    checked: boolean,
  ) => {
    if (
      stateType !== 'PROJECT' &&
      stateType !== 'INDIVIDUAL' &&
      stateType !== 'USER' &&
      stateType !== null &&
      stateType !== undefined
    ) {
      checkGroupTask(index, id, dataType, parentIndex, parentId, stateType, checked);
      return;
    }
    const mainData = getStateTypeMain(stateType);
    if (
      (dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 0 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === id)) ||
      (dataType === 'BACKLOG' &&
        mainData.selectedTasks.BACKLOG.length > 0 &&
        mainData.selectedTasks.BACKLOG.find((e) => e.id === id))
    ) {
      checkMultipleTasks(mainData, checked, stateType);
    } else {
      checkGroupTask(index, id, dataType, parentIndex, parentId, stateType, checked);
    }
  };

  const checkMultipleTasks = _.debounce(
    (mainData, checked: boolean, stateType?: StateType) =>
      bulkUpdateCheckStatus(
        mainData.selectedTasks.FOCUS.map((item) => item.id),
        mainData.selectedTasks.BACKLOG.map((item) => item.id),
        checked,
        undefined,
        stateType,
      ),
    TASK_INPUT_WAIT_INTERVAL,
    false,
  );

  const setTaskFocusData = useSetRecoilState(focusTaskDataState);
  const setTaskBacklogData = useSetRecoilState(backlogTaskDataState);
  const setProjectFocusData = useSetRecoilState(projectFocusTaskDataState);
  const setProjectBacklogData = useSetRecoilState(projectBacklogTaskDataState);
  const setUserFocusData = useSetRecoilState(userFocusTaskDataState);
  const setUserBacklogData = useSetRecoilState(userBacklogTaskDataState);
  const setTemplateFocusData = useSetRecoilState(templateFocusTaskDataState);
  const setTemplateBacklogData = useSetRecoilState(templateBacklogTaskDataState);

  const updateTagsInRecoil = (
    data: TaskObjectType[],
    idList: SelectedTaskIdsType[],
    dataType: TaskType,
    currentTag: TagObjectType[],
    currentTagInfo: TagInfoType[],
    tagId: string,
  ) => {
    const tempData = data.map((taskItem) => {
      const filtered = idList.findIndex((list) => list.id === taskItem.id);
      if (filtered !== -1) {
        if (taskItem.subTasks) {
          const filteredSub = idList.filter(
            (list) => list.parentId === taskItem.id && list.parentId !== list.id,
          );
          if (filteredSub.length > 0) {
            return {
              ...taskItem,
              tags: taskItem.tags
                ? [...taskItem.tags.filter((it) => it.id !== tagId), ...currentTag]
                : currentTag,
              tagInfo: taskItem.tagInfo
                ? [...taskItem.tagInfo.filter((it) => it.tagId !== tagId), ...currentTagInfo]
                : currentTagInfo,
              updateTags: +new Date(),
              subTasks: taskItem.subTasks.map((subt) => {
                const filteredS = idList.findIndex((list) => list.id === subt.id);
                if (filteredS !== -1)
                  return {
                    ...subt,
                    tags: subt.tags
                      ? [...subt.tags.filter((it) => it.id !== tagId), ...currentTag]
                      : currentTag,
                    tagInfo: subt.tagInfo
                      ? [...subt.tagInfo.filter((it) => it.tagId !== tagId), ...currentTagInfo]
                      : currentTagInfo,
                    updateTags: +new Date(),
                  };
                return subt;
              }),
            };
          }
        }
        return {
          ...taskItem,
          tags: taskItem.tags
            ? [...taskItem.tags.filter((it) => it.id !== tagId), ...currentTag]
            : currentTag,
          tagInfo: taskItem.tagInfo
            ? [...taskItem.tagInfo.filter((it) => it.tagId !== tagId), ...currentTagInfo]
            : currentTagInfo,
          updateTags: +new Date(),
        };
      }
      const filteredSub = idList.filter(
        (list) => list.parentId === taskItem.id && list.parentId !== list.id,
      );
      if (filteredSub.length > 0 && taskItem.subTasks) {
        return {
          ...taskItem,
          subTasks: taskItem.subTasks.map((subt) => {
            const filteredS = idList.findIndex((list) => list.id === subt.id);
            if (filteredS !== -1)
              return {
                ...subt,
                tags: subt.tags
                  ? [...subt.tags.filter((it) => it.id !== tagId), ...currentTag]
                  : currentTag,
                tagInfo: subt.tagInfo
                  ? [...subt.tagInfo.filter((it) => it.tagId !== tagId), ...currentTagInfo]
                  : currentTagInfo,
                updateTags: +new Date(),
              };
            return subt;
          }),
        };
      }
      return taskItem;
    });
    if (stateType === 'PROJECT') {
      dataType === 'FOCUS' ? setProjectFocusData(tempData) : setProjectBacklogData(tempData);
    }
    if (stateType === 'USER') {
      dataType === 'FOCUS' ? setUserFocusData(tempData) : setUserBacklogData(tempData);
    }
    if (stateType === 'TEMPLATE') {
      dataType === 'FOCUS' ? setTemplateFocusData(tempData) : setTemplateBacklogData(tempData);
    } else {
      dataType === 'FOCUS' ? setTaskFocusData(tempData) : setTaskBacklogData(tempData);
    }
  };

  const bulkUpdateTasksTag = async (
    tagId: string | null,
    tagName: string,
    tempId: string,
    id: string,
    add: boolean,
    dataType: TaskType,
  ) => {
    if (!tagId) return;
    const mainData = getStateData();
    if (
      mainData &&
      ((dataType === 'FOCUS' &&
        mainData.selectedTasks.FOCUS.length > 0 &&
        mainData.selectedTasks.FOCUS.find((e) => e.id === id)) ||
        (dataType === 'BACKLOG' &&
          mainData.selectedTasks.BACKLOG.length > 0 &&
          mainData.selectedTasks.BACKLOG.find((e) => e.id === id)))
    ) {
      const selectedTaskIds = [...mainData.selectedTasks.FOCUS, ...mainData.selectedTasks.BACKLOG];
      const { success, data } = await bulkUpdateTaskTagsAPI(
        selectedTaskIds.map((item) => item.id),
        [tagId],
        add,
        tempId,
      );

      if (success && data && data.tags && data.tagInfo) {
        log('bulk removed tags response', data);
        const tagsArr = parseTags(data.tags ? data.tags : []) as TagObjectType[];
        const currentTag = tagsArr.filter((x) => x.id === tagId);
        const currentTagInfo = data.tagInfo ? data.tagInfo.filter((x) => x.id === tagId) : [];
        if (mainData.selectedTasks.FOCUS.length > 0)
          updateTagsInRecoil(
            mainData.FOCUS.data ?? [],
            mainData.selectedTasks.FOCUS,
            'FOCUS',
            currentTag,
            currentTagInfo,
            tagId,
          );
        if (mainData.selectedTasks.BACKLOG.length > 0)
          updateTagsInRecoil(
            mainData.BACKLOG.data ?? [],
            mainData.selectedTasks.BACKLOG,
            'BACKLOG',
            currentTag,
            currentTagInfo,
            tagId,
          );
      }
    }
  };

  const renderBacklog = () => {
    if (stateType === 'RECURRING') return null;

    return (
      <TaskItems
        key='BACKLOG'
        dataType='BACKLOG'
        tasks={backlogTasks}
        title='Backlog'
        stateType={stateType}
        storeInputRefs={(id, input) => storeInputRefs(id, input)}
        toggleSelectionInGroup={(id, tempId, parentId) =>
          toggleSelectionInGroup(id, tempId, parentId, 'BACKLOG')
        }
        multiSelectTo={(id, tempId, parentId) => multiSelectTo(id, tempId, parentId, 'BACKLOG')}
        toggleSelection={(id, tempId, parentId) => toggleSelection(id, tempId, parentId, 'BACKLOG')}
        selectedTasks={backlogSelectedTasks}
        updateSizeGroup={updateSizeGroup}
        saveTaskAssignee={saveTaskAssignee}
        saveTasksProject={saveTasksProject}
        saveTasksStartEndDate={saveTasksStartEndDate}
        saveTasksDueDate={saveTasksDueDate}
        checkTasks={checkTasks}
        selectedTaskIds={selectedTempIds}
        draggingTaskId={getDraggingTaskId()}
        bulkUpdateTasksTag={(id, name, tempId, taskId, add) =>
          bulkUpdateTasksTag(id, name, tempId, taskId, add, 'BACKLOG')
        }
        hideDragIcon={hideDragIcon}
        selectedNotificationTaskId={selectedNotificationTaskId}
      />
    );
  };

  const isFilterSelected = useCallback(() => {
    const data = getStateData();
    if (
      data &&
      data.taskFilters &&
      (data.taskFilters.sizeFilter ||
        data.taskFilters.dateFilter ||
        data.taskFilters.tagFilter ||
        data.taskFilters.assigneeFilter ||
        data.taskFilters.projectFilter)
    )
      return true;
    return false;
  }, [getStateData]);

  const toShowMessage = () => {
    if (stateType === 'RECURRING' && (focusTasks === null || focusTasks.length === 0)) {
      return true;
    } else if (
      isFilterSelected() &&
      (focusTasks === null || focusTasks.length === 0) &&
      (backlogTasks === null || backlogTasks.length === 0)
    )
      return true;
    return false;
  };

  const renderNoDataMessage = () => {
    return (
      <Fragment>
        <p className='no-task-message'>{Strings.no_data_to_display}</p>
      </Fragment>
    );
  };

  const renderTasks = () => {
    if (toShowMessage()) {
      return renderNoDataMessage();
    }

    return (
      <Fragment>
        <TaskItems
          key='FOCUS'
          dataType='FOCUS'
          tasks={focusTasks}
          title={getFocusTitle()}
          stateType={stateType}
          storeInputRefs={(id, input) => storeInputRefs(id, input)}
          toggleSelectionInGroup={(id, tempId, parentId) =>
            toggleSelectionInGroup(id, tempId, parentId, 'FOCUS')
          }
          multiSelectTo={(id, tempId, parentId) => multiSelectTo(id, tempId, parentId, 'FOCUS')}
          toggleSelection={(id, tempId, parentId) => toggleSelection(id, tempId, parentId, 'FOCUS')}
          selectedTasks={focusSelectedTasks}
          updateSizeGroup={updateSizeGroup}
          saveTaskAssignee={saveTaskAssignee}
          saveTasksProject={saveTasksProject}
          saveTasksStartEndDate={saveTasksStartEndDate}
          saveTasksDueDate={saveTasksDueDate}
          checkTasks={checkTasks}
          selectedTaskIds={selectedTempIds}
          draggingTaskId={getDraggingTaskId()}
          bulkUpdateTasksTag={(id, name, tempId, taskId, add) =>
            bulkUpdateTasksTag(id, name, tempId, taskId, add, 'FOCUS')
          }
          hideDragIcon={hideDragIcon}
          selectedNotificationTaskId={selectedNotificationTaskId}
        />

        {renderBacklog()}
      </Fragment>
    );
  };
  /*
   * Render the component
   *
   * returns React.DOM
   */
  return (
    <div className='task-list' id='task-list' style={{ paddingTop: '20px' }}>
      <DragDropContext
        id='drag-drop-context'
        onBeforeCapture={onBeforeCapture}
        onDragEnd={onDragEnd}
      >
        {readOnly ? renderCompletedTasks() : renderTasks()}
      </DragDropContext>
    </div>
  );
}
