import { useEffect, useState } from 'preact/compat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useAlert } from 'react-alert';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  markAllNotificationStatus as markAllNotificationStatusFromAPI,
  updateNotificationStatus as updateNotificationStatusFromAPI,
} from 'services/NotificationsService';
import {
  switchWorkspace as switchWorkspaceAPI,
  reFetchUserSession,
  getUserId,
} from 'services/AuthService';
import { Strings } from 'resources';
import { getCurrentDateTime } from 'utils/task';
import Spinner from 'components/UI/Spinner';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountDataSelector } from 'recoil/AccountState';
import { getNewNotifications, updateUnreadNotificationList } from 'recoil/AccountState/update';
import { showNotificationPopupState } from 'recoil/NavigationState';
import { updateSelectedNotificationTaskId } from 'recoil/TaskState/update';

export default function Notifications() {
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = getUserId();

  const accountData = useRecoilValue(accountDataSelector);
  const [showNotification, setShowNotification] = useRecoilState(showNotificationPopupState);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [groupedUnreadNotificationsList, setGroupedUnreadNotificationsList] = useState<
    NotificationDateGroupedType[]
  >([]);
  const [groupedAllNotificationsList, setGroupedAllNotificationsList] = useState<
    NotificationDateGroupedType[]
  >([]);

  useEffect(() => {
    getNewNotifications('Un-read', accountData.notifications.currentUnreadPage);
    getNewNotifications('All', accountData.notifications.currentAllPage);
  }, []);

  useEffect(() => {
    if (accountData && accountData.notifications && accountData.notifications.unreadNotifications)
      setGroupedUnreadNotificationsList(
        getGroupedList(accountData.notifications.unreadNotifications),
      );
  }, [accountData]);

  useEffect(() => {
    if (accountData.notifications.allNotifications.length > 0)
      setGroupedAllNotificationsList(getGroupedList(accountData.notifications.allNotifications));
  }, [accountData]);

  const getGroupedList = (list: NotificationObjectType[]): NotificationDateGroupedType[] => {
    // this gives an object with dates as keys
    const groups = list.reduce((groups, notfication) => {
      const date = notfication?.createdString?.split('T')[0] || '';
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(notfication);
      return groups;
    }, {});

    let groupArrays: NotificationDateGroupedType[] = [];
    if (Object.keys(groups).length > 0) {
      groupArrays = Object.keys(groups).map((date) => {
        return {
          date: getCurrentDateTime(date),
          notifications: groups[date],
        };
      });
    }
    return groupArrays;
  };

  const renderPageHeader = () => {
    return (
      <div className='notification-header'>
        <h3>{Strings.notifications}</h3>
        <div id='close-modal' className='close-outer-btn'>
          <span onClick={() => setShowNotification(false)} className='cross-icon'>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
      </div>
    );
  };

  const renderPageControls = () => {
    return (
      <div className='page-controls-con'>
        <div
          className={`unread page-controls ${selectedTab === 0 ? 'active' : ''}`}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <span className='page-control-title'>{Strings.unread}</span>
          {accountData.notifications.unreadCounts > 0 && (
            <span className='notif-count'>{accountData.notifications.unreadCounts}</span>
          )}
        </div>
        <div
          className={`all page-controls ${selectedTab === 1 ? 'active' : ''}`}
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          <span className='page-control-title'>{Strings.all}</span>
        </div>
      </div>
    );
  };

  const renderNotificationBody = () => {
    switch (selectedTab) {
      case 0:
        return (accountData.notifications.isUnreadLoading &&
          accountData.notifications.unreadNotifications.length === 0) ||
          isLoading
          ? renderProgressIndicator()
          : renderNotificationContent(
              'Un-read',
              accountData.notifications.currentUnreadPage,
              groupedUnreadNotificationsList,
              accountData.notifications.unreadNotifications.length,
            );
      case 1:
        return (accountData.notifications.isAllLoading &&
          accountData.notifications.allNotifications.length === 0) ||
          isLoading
          ? renderProgressIndicator()
          : renderNotificationContent(
              'All',
              accountData.notifications.currentAllPage,
              groupedAllNotificationsList,
              accountData.notifications.allNotifications.length,
            );

      default:
        return (accountData.notifications.isUnreadLoading &&
          accountData.notifications.unreadNotifications.length === 0) ||
          isLoading
          ? renderProgressIndicator()
          : renderNotificationContent(
              'Un-read',
              accountData.notifications.currentUnreadPage,
              groupedUnreadNotificationsList,
              accountData.notifications.unreadNotifications.length,
            );
    }
  };

  const renderProgressIndicator = () => {
    return (
      <div className='spinner-container'>
        <Spinner />
      </div>
    );
  };

  const renderNotificationContent = (
    status: NotificationStatusType,
    page: number,
    notificationList: NotificationDateGroupedType[],
    dataLength: number,
  ) => {
    return (
      <div className='infinite-scroll'>
        <div id='scrollableDiv' className='infinite-scroll-container'>
          {/** @ts-ignore */}
          <InfiniteScroll
            dataLength={dataLength}
            next={() => getNewNotifications(status, page)}
            hasMore={
              selectedTab === 0
                ? accountData.notifications.hasMoreUnread
                : accountData.notifications.hasMoreAll
            }
            scrollableTarget='scrollableDiv'
            // @ts-ignore
            loader={
              <div>
                <h6>Loading...</h6>
              </div>
            }
          >
            {/* @ts-ignore*/}
            {renderNotificationsList(notificationList)}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  const renderNotificationsList = (notificationList: NotificationDateGroupedType[]) => {
    if (notificationList && notificationList.length > 0) {
      return (
        <div id='notificationList' className='notificationList'>
          {notificationList.map((notif, index) => (
            <div key={index} className='notification-item-container'>
              <p className='section-header'>{notif.date}</p>
              {notif.notifications.map((item, position) => (
                <div key={position}>{renderNotification(item, position)}</div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return (
      <div id='emptyNotification' className='emptyNotification'>
        <p>{selectedTab === 0 ? Strings.noUnreadNotif : Strings.noNotif}</p>
        <p>{Strings.getBack}</p>
      </div>
    );
  };

  const handleItemClick = async (notif: NotificationObjectType) => {
    const path = '/' + notif.path;
    handleWorkspaceSwitch(path, notif);
    markAsRead(notif);
    setShowNotification(false);
  };

  const markAsRead = async (notif: NotificationObjectType) => {
    const list: string[] = [];
    list.push(notif.id);
    const { success } = await updateNotificationStatusFromAPI(list);
    if (!success) {
      alert.error(Strings.markReadFailed);
    }
  };

  const handleWorkspaceSwitch = async (path: string, notif: NotificationObjectType) => {
    if (notif.workspace === accountData.account?.workspaceId) {
      const pathSegments = ('/' + notif.path).split('/');
      const entityType = pathSegments[1];
      const entityId = pathSegments[2];

      updateSelectedNotificationTaskId(
        entityType === 'user' && entityId !== userId
          ? convertToStateType('INDIVIDUAL')
          : convertToStateType(entityType.toUpperCase()),
        notif.taskId || null,
      );

      navigate(path, { replace: false });
    } else {
      await switchWorkspaceAPI(notif.workspace);
      await reFetchUserSession();
      notificationTapped(notif);
    }
  };

  const notificationTapped = (notif: NotificationObjectType) => {
    setShowNotification(false);
    if (location.pathname.includes(notif.path)) {
      const pathSegments = ('/' + notif.path).split('/');
      const entityType = pathSegments[1];
      const entityId = pathSegments[2];

      updateSelectedNotificationTaskId(
        entityType === 'user' && entityId !== userId
          ? convertToStateType('INDIVIDUAL')
          : convertToStateType(entityType.toUpperCase()),
        notif.taskId || null,
      );
      navigate(entityType, { replace: true });
    }
  };

  const onMarkAsReadTap = async () => {
    setIsLoading(true);

    const { success } = await markAllNotificationStatusFromAPI();
    if (!success) {
      alert.error(Strings.markReadFailed);
    } else {
      updateUnreadNotificationList([], 0);
      setGroupedUnreadNotificationsList([]);
    }
    setIsLoading(false);
  };

  const renderNotification = (notif: NotificationObjectType, index: number) => {
    return (
      <div id={`notification-con-${index}`} className={`notification-con`} key={index}>
        <div
          id={`notification=${index}`}
          className='notfication'
          onClick={() => handleItemClick(notif)}
        >
          <p id={`notif-text-${index}`} className='notif-text'>
            <span className='bold'>{notif.userData}</span>
            <span> {notif.heading + ' : '}</span>
            <span className='italic' id={`notif-body-${index}`}>
              {notif.body}
            </span>
          </p>
        </div>
        {!notif.read && (
          <span id='read-icon-con' onClick={() => markAsRead(notif)} className='read-icon' />
        )}
      </div>
    );
  };

  function convertToStateType(value: string | undefined): StateType {
    const validTypes: StateType[] = [
      'PROJECT',
      'INDIVIDUAL',
      'COMPLETED_TASK',
      'TUTORIAL',
      'RECURRING',
      'TEMPLATE',
      'USER',
    ];

    if (value && validTypes.includes(value as StateType)) {
      return value as StateType;
    }

    return undefined;
  }

  const renderMarkRead = () => {
    return (
      <div>
        {selectedTab === 0 && accountData.notifications.unreadCounts > 0 && (
          <p className='markRead' onClick={() => onMarkAsReadTap()}>
            {Strings.markAllRead}
          </p>
        )}
      </div>
    );
  };

  const closeModal = () => {
    setShowNotification(false);
  };

  const innerViewClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`notification-main-con ${!showNotification ? 'hide' : ''}`}
      onClick={closeModal}
    >
      <div className='notification-container' onClick={innerViewClick}>
        {renderPageHeader()}
        {renderPageControls()}
        {renderMarkRead()}
        {renderNotificationBody()}
      </div>
    </div>
  );
}
