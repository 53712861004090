import { memo, useState, useEffect, useMemo, Fragment } from 'preact/compat';
import { formatDateForView, formatTimeForAPI, weekDays, formatTimeToUTC } from 'utils/task';
import { log } from 'utils';
import _ from 'underscore';
import { useSetRecoilState } from 'recoil';
import RecurringTask from 'modals/RecurringTask';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ordinalSuffix, randomNumber } from 'utils/helpers';
import { createNewRecurringTask } from 'services/TaskService';
import { createNewTemplateRecurringTask as createNewTemplateRecurringTaskAPI } from 'services/TemplateService';
import { useAlert } from 'react-alert';
import { isAdmin } from 'services/AuthService';
import { showUpgradeModalState } from 'recoil/NavigationState';


type RecurringIconPropsType = {
  tempId?: string;
  stateType: StateType | null;
  recurringTaskMeta?: RecurringTaskMetaType;
  updateRecurringTaskMeta?: (data: RecurringTaskPayloadType) => void;
  showOnlyModal?: boolean;
  itemValue?: string;
  itemAssignee?: string | null;
  itemProject?: string | null;
  itemSize?: SizeOptionsType | null;
  itemTags?: TagObjectType[];
  toggleRecurringTaskModalDisplay?: () => void;
  assignDefaultToSelf?: boolean;
  resetParentState?: () => void;
  userId?: string;
  subTasks?: TaskObjectType[];
  itemTemplate?: string | null;
  defaultSize?: SizeOptionsType;
};
function RecurringIcon(props: RecurringIconPropsType) {
  const alert = useAlert();

  const {
    tempId,
    stateType,
    recurringTaskMeta,
    updateRecurringTaskMeta,
    showOnlyModal = false,
    itemValue,
    itemAssignee,
    itemProject,
    itemSize,
    itemTags,
    toggleRecurringTaskModalDisplay,
    assignDefaultToSelf,
    resetParentState,
    userId,
    subTasks,
    itemTemplate,
    defaultSize,
  } = props;
  const {
    executionTime = null,
    repeatDays = [],
    repeatFrequency = 1,
    repeatType = 'Daily',
    nextRecurrence,
  } = recurringTaskMeta || {};

  const [processing, setProcessing] = useState(false);

  const [recurrenceRepeatType, setRecurrenceRepeatType] = useState('Day'); // Day, Week, Month
  const [recurrenceRepeatNumber, setRecurrenceRepeatNumber] = useState(1);
  const [recurrenceTime, setRecurrenceTime] = useState<string | null>(null);
  const [recurrenceWeekDaysIndex, setRecurrenceWeekDaysIndex] = useState<number[]>([]);
  const [showRecurringTaskModal, setShowRecurringTaskModal] = useState(false);

  const setShowUpgradeModal = useSetRecoilState(showUpgradeModalState);

  useEffect(() => {
    if (recurringTaskMeta) updateStateFromProp();
  }, [executionTime, repeatDays, repeatFrequency, repeatType, nextRecurrence]);

  const updateStateFromProp = () => {
    setRecurrenceRepeatType(getRecurringRepeatTypeForUI(repeatType));
    setRecurrenceRepeatNumber(repeatFrequency);
    setRecurrenceTime(executionTime);
    setRecurrenceWeekDaysIndex(getWeekDaysIndex(repeatDays));
  };

  const getWeekDaysIndex = (days: string[]) => {
    const indexes: number[] = [];

    days.map((day) => {
      const index = weekDays.findIndex((weekDay) => weekDay.id === day);

      if (index !== -1) indexes.push(index);
    });

    return indexes;
  };

  const weekDaySelected = (index: number) => {
    const recurrenceWeekDays = recurrenceWeekDaysIndex.sort();

    const alreadyExists = recurrenceWeekDays.findIndex((dayIndex) => dayIndex === index);

    if (alreadyExists !== -1) {
      if (recurrenceWeekDays.length > 1) {
        // remove
        setRecurrenceWeekDaysIndex(recurrenceWeekDays.filter((item) => item !== index));
      }
    } else {
      // add
      setRecurrenceWeekDaysIndex([...recurrenceWeekDays, index]);
    }
  };

  const getRecurringTaskPayload = (isEditMode: boolean): RecurringTaskPayloadType => {
    return {
      repeatFrequency: recurrenceRepeatNumber,
      repeatType: getRecurringRepeatTypeForAPI(),
      executionTime:
        isEditMode && recurrenceTime
          ? recurrenceTime
          : recurrenceTime
            ? formatTimeToUTC(recurrenceTime)
            : null,
      repeatDays: getRecurringRepeatDays(),
    };
  };

  const getRecurringRepeatDays = (): DaysType[] => {
    const days: string[] = [];
    recurrenceWeekDaysIndex.sort().map((val) => {
      const day = weekDays[val];
      days.push(day.id);
    });

    return days as DaysType[];
  };

  const getRecurringRepeatTypeForAPI = (): RecurrenceRepeatType => {
    const type = recurrenceRepeatType;
    let recType: RecurrenceRepeatType = 'Daily';

    switch (type) {
      case 'Day':
      case 'Days':
        recType = 'Daily';
        break;
      case 'Week':
        recType = 'Weekly';
        break;
      case 'Month':
        recType = 'Monthly';
        break;
      default:
        break;
    }

    return recType;
  };

  const getRecurringRepeatTypeForUI = (type: RecurrenceRepeatType) => {
    let recType = '';

    switch (type) {
      case 'Daily':
        if (repeatFrequency > 1) {
          recType = 'Days';
        } else {
          recType = 'Day';
        }
        break;
      case 'Weekly':
        recType = 'Week';
        break;
      case 'Monthly':
        recType = 'Month';
        break;
      default:
        break;
    }

    return recType;
  };

  const createRecurringTask = () => {
    log('createRecurringTask');

    if (recurringTaskMeta) {
      updateRecurringTaskMeta && updateRecurringTaskMeta(getRecurringTaskPayload(true));
      setShowRecurringTaskModal(false);
      showOnlyModal && toggleRecurringTaskModalDisplay && toggleRecurringTaskModalDisplay();
    } else {
      createRecurringTaskItem();
    }
  };

  const createRecurringTaskItem = async () => {
    setProcessing(true);

    const taskPayload = getPayload();

    // If not admin, normal user can only assign recurring task to himself
    if (!isAdmin()) taskPayload.assigneeId = userId;

    let payload = getRecurringTaskPayload(false);
    payload = { ...payload, ...taskPayload };

    if (stateType === 'TEMPLATE') {
      const { success, status } = await createNewTemplateRecurringTaskAPI(payload);
      if (!success) {
        alert.error('Could not create recurring task!');
        setProcessing(false);
        if (status === 402) setShowUpgradeModal('RECURRING');
      } else {
        resetState();
        alert.success('Recurring task created Successfully!');
      }
    } else {
      const { success, status } = await createNewRecurringTask(payload);

      if (!success) {
        alert.error('Could not create recurring task!');
        setProcessing(false);
        if (status === 402) setShowUpgradeModal('RECURRING');
      } else {
        resetState();
        alert.success('Recurring task created Successfully!');
      }
    }
    showOnlyModal && toggleRecurringTaskModalDisplay && toggleRecurringTaskModalDisplay();
    resetParentState && resetParentState();
  };

  const getPayload = () => {
    const payload: any = {
      assigneeId: itemAssignee
        ? itemAssignee
        : itemProject
          ? assignDefaultToSelf
            ? userId
            : null
          : userId,
      name: itemValue ? itemValue.trim() : '',
      parentTaskId: 0,
      taskType: 'BACKLOG', // Default is BACKLOG
      projectId: itemProject ? itemProject : null,
      size: itemSize ? itemSize : defaultSize ? defaultSize : 'M',
      tagIds: getItemTags(),
      tempId: randomNumber() + '',
      subTasks: subTasks,
    };

    if (stateType === 'TEMPLATE') {
      payload.assigneeId = itemAssignee ? itemAssignee : null;
      payload.projectId = itemTemplate ? itemTemplate : null;
    }

    // else if (stateType !== 'RECURRING') {
    //   payload.taskStatusId = 1; // fixed value
    // }

    return payload;
  };

  const getItemTags = () => {
    const itemTagIds: string[] = [];

    itemTags &&
      itemTags.forEach((itemTag) => {
        if (itemTag?.id) itemTagIds.push(itemTag.id);
      });

    return itemTagIds;
  };

  const resetState = () => {
    // Recurring task
    setRecurrenceRepeatType('Day');
    setRecurrenceRepeatNumber(1);
    setRecurrenceTime(null);
    setRecurrenceWeekDaysIndex([]);
    setShowRecurringTaskModal(false);
  };
  const updateRecurenceRepeatType = (v: string) => {
    setRecurrenceRepeatType(v);
    setRecurrenceRepeatNumber(1);

    if (v === 'Week' && recurrenceWeekDaysIndex.length === 0) setRecurrenceWeekDaysIndex([1]);
  };

  const updateRecurrenceRepeatNumber = (v: number) => {
    setRecurrenceRepeatNumber(v);
    if (v > 1 && recurrenceRepeatType === 'Day') {
      setRecurrenceRepeatType('Days');
    }
  };

  const renderRecurringTime = () => {
    if (!executionTime) return null;

    return <div>Recurring time: {formatTimeForAPI(executionTime)}</div>;
  };

  const getRecurringDaysForView = () => {
    const recDays: string[] = [];

    repeatDays.map((repDay) => {
      const index = weekDays.findIndex((weekDay) => weekDay.id === repDay);

      if (index !== -1) recDays.push(weekDays[index].name);
    });

    return recDays.join(', ');
  };

  const renderRecurringDays = () => {
    if (repeatType === 'Monthly')
      return <div>Recurring day: {ordinalSuffix(repeatFrequency)} day</div>;
    else if (repeatType !== 'Weekly') return null;

    return <div>Recurring days: {getRecurringDaysForView()}</div>;
  };

  const renderRecurringFrequency = () => {
    if (repeatType === 'Monthly')
      return <div>Recurring every: {getRecurringRepeatTypeForUI(repeatType)}</div>;

    return (
      <div>
        Recurring every: {repeatFrequency} {getRecurringRepeatTypeForUI(repeatType)}
        {repeatType === 'Daily' ? '' : '(s)'}
      </div>
    );
  };

  const tooltipContent = useMemo(() => {
    return (
      <div>
        <div>
          Next recurrence on:{' '}
          {nextRecurrence
            ? `${formatDateForView(nextRecurrence, 'MM-DD-YYYY')} at ${formatDateForView(
                nextRecurrence,
                'hh:mm a',
              )}`
            : 'N/A'}
        </div>
        {renderRecurringFrequency()}
        {renderRecurringDays()}
        {renderRecurringTime()}
      </div>
    );
  }, [executionTime, repeatDays, repeatFrequency, repeatType, nextRecurrence]);

  // log('render components/TaskItems/IndividualItem/RecurringIcon');

  const closeModal = () => {
    setShowRecurringTaskModal(false);
    showOnlyModal && toggleRecurringTaskModalDisplay && toggleRecurringTaskModalDisplay();
    updateStateFromProp();
  };

  return (
    <Fragment>
      {!showOnlyModal && (
        <span
          className='item-container recurring-container'
          style={{ position: 'relative' }}
          key={`recurring-con-${tempId}`}
        >
          <div>
            <button
              className='recurring-btn'
              data-tooltip-id={`tooltip-${tempId}`}
              data-class='recurring-tooltip'
              data-background-color='#332a7c'
              data-tip
              onClick={() =>
                (stateType === 'RECURRING' || stateType === 'TEMPLATE') &&
                setShowRecurringTaskModal(true)
              }
            />
          </div>
          {/** @ts-ignore */}
          <ReactTooltip className='recurring-tooltip' id={`tooltip-${tempId}`} place='top'>
            {/** @ts-ignore */}
            {tooltipContent}
          </ReactTooltip>
        </span>
      )}
      {showRecurringTaskModal || showOnlyModal ? (
        <RecurringTask
          finish={() => {
            !processing && createRecurringTask();
            setShowRecurringTaskModal(false);
          }}
          weekDaySelected={(index) => weekDaySelected(index)}
          recurrenceWeekDaysIndex={recurrenceWeekDaysIndex}
          recurrenceTime={recurrenceTime}
          setRecurrenceTime={(time) => setRecurrenceTime(time)}
          recurrenceRepeatType={recurrenceRepeatType}
          setRecurrenceRepeatType={(v) => updateRecurenceRepeatType(v)}
          recurrenceRepeatNumber={recurrenceRepeatNumber}
          setRecurrenceRepeatNumber={(v) => updateRecurrenceRepeatNumber(v)}
          closeModal={() => closeModal()}
        />
      ) : null}
    </Fragment>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(prevProps: RecurringIconPropsType, nextProps: RecurringIconPropsType) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  return _.isEqual(prevProps.recurringTaskMeta, nextProps.recurringTaskMeta);
}

export default memo(RecurringIcon, areEqual);
