import { TargetedEvent } from 'preact/compat';
import SearchIcon from 'assets/images/search.png';
type SearchInputPropsType = {
  autoCapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  placeholder: string;
  value: string;
  onChange: (e: TargetedEvent) => void;
  onBlur: (e: FocusEvent) => void;
  errorMessage?: string;
  touched?: boolean;
  disabled?: boolean;
  onSearch?: (e: MouseEvent) => void;
  containerClassname?: string;
};
export default function SearchInput(props: SearchInputPropsType) {
  const {
    autoCapitalize,
    placeholder,
    value,
    onChange,
    onBlur,
    errorMessage,
    touched,
    disabled,
    onSearch,
    containerClassname,
  } = props;

  const renderError = () => {
    if (errorMessage && touched) return <div className='input-error'>{errorMessage}</div>;

    return null;
  };

  const renderElement = () => {
    const inputClasses = ['input'];

    if (errorMessage && touched) {
      inputClasses.push('invalid');
    }
    if (disabled) {
      inputClasses.push('disabled');
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        (e.target as HTMLInputElement).blur();
      }
    };
    return (
      <input
        autoCapitalize={autoCapitalize}
        className={inputClasses.join(' ')}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type='input'
        disabled={disabled}
        autoComplete='off'
        data-lpignore='true'
        data-form-type='other'
        onKeyDown={onKeyDown}
      />
    );
  };

  return (
    <div className={`search-input-container ${containerClassname}`}>
      <div className={`text-input ${disabled ? 'disabled' : ''}`}>
        <span className='text-input-wrapper'>
          {renderElement()}
          <span title='Search' onClick={onSearch}>
            <img src={SearchIcon} alt='search' />
          </span>
        </span>
      </div>
      {renderError()}
    </div>
  );
}
