import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  addTaskData,
  getCompletedTaskDataState,
  getCompletedTaskHasMoreState,
  getCompletedTaskPageState,
  getCompletedTaskPointsState,
  getCompletedTaskSizeState,
  getCompletedTaskState,
  getStateTypeMain,
} from 'recoil/TaskState/update';
import { SEQUENCE_SMALL } from 'utils/task';
import { updateTaskStatus } from 'services/TaskService';
export function unCheckTask(
  index: number,
  id: string,
  parentIndex: number | undefined,
  parentId: string | undefined,
  noApiCall: boolean,
  stateType: StateType,
) {
  setCompletedTaskStatus(false, index, parentIndex, stateType);

  setTimeout(() => {
    const targetStateAtom = getCompletedTaskState(stateType);
    const { data } = getRecoil(targetStateAtom);

    if (!data) return;

    if (parentId) {
      parentIndex = data && data.findIndex((item) => item.id === parentId);
      if (parentIndex === -1 || parentIndex === undefined) return;

      index = data[parentIndex].subTasks?.findIndex((item) => item.id === id) ?? -1;
      if (index === -1) return;

      hideCompletedTasks(index, parentIndex, stateType);

      const idx = data[parentIndex].subTasks?.findIndex((item) => item.checked) ?? -1;
      if (idx === -1) {
        if (!data[parentIndex].checked) {
          const task = data[parentIndex];
          removeCompletedTask(parentIndex, stateType, index);
          task.taskType !== 'FOCUS' && addTaskToBacklog(task, stateType);
        } else {
          if (parentIndex && data[parentIndex].subTasks)
            updateCompletedTaskPoints(data[parentIndex].subTasks![index].points ?? 0, stateType);
        }
      } else {
        if (parentIndex && data[parentIndex].subTasks)
          updateCompletedTaskPoints(data[parentIndex].subTasks![index].points ?? 0, stateType);
      }
    } else {
      index = data && data.findIndex((item) => item.id === id);
      if (index === -1) return;

      const task = data[index];

      removeCompletedTask(index, stateType);

      addTaskToBacklog(task, stateType);
    }

    if (!noApiCall) updateTaskStatus(id, 'OPEN');
  }, 500);
}

export function hideCompletedTasks(
  subTaskIndex: number,
  parentIndex: number,
  stateType: StateType,
) {
  const setHideCompletedTasks = (task: TaskObjectType, taskIndex: number) => {
    return taskIndex === subTaskIndex ? { ...task, hidden: true } : task;
  };

  updateCompletedTasksProperties(parentIndex, stateType, setHideCompletedTasks);
}

export function setCompletedTaskStatus(
  status: boolean,
  index: number,
  parentIndex: number | undefined,
  stateType: StateType,
) {
  const setCompletedTaskStatus = (task: TaskObjectType, taskIndex: number) => {
    return index === taskIndex ? { ...task, checked: status } : task;
  };

  updateCompletedTasksProperties(parentIndex, stateType, setCompletedTaskStatus);
}

export function updateCompletedTasksProperties(
  parentIndex: number | undefined,
  stateType: StateType,
  updateMethod: (task: TaskObjectType, taskIdx: number) => TaskObjectType,
) {
  const targetStateAtom = getCompletedTaskDataState(stateType);
  const data = getRecoil(targetStateAtom);
  let tempData: TaskObjectType[];

  if (parentIndex !== undefined && data[parentIndex].subTasks !== undefined) {
    tempData = data.map((task, taskIndex) => {
      if (taskIndex === parentIndex && task.subTasks) {
        return {
          ...task,
          subTasks: task.subTasks.map((subTask, subTaskIndex) => {
            return updateMethod(subTask, subTaskIndex);
          }),
        };
      } else {
        return task;
      }
    });
  } else {
    tempData = data.map((task, taskIndex) => {
      return updateMethod(task, taskIndex);
    });
  }
  setRecoil(targetStateAtom, tempData);
}

export function removeCompletedTask(
  index: number,
  stateType: StateType,
  subTaskIndex: number = -1,
) {
  const targetStateDataAtom = getCompletedTaskDataState(stateType);
  const data = getRecoil(targetStateDataAtom);
  setRecoil(
    targetStateDataAtom,
    data.filter((item, ind) => ind !== index),
  );

  let decreasePoints = data[index].points ?? 0;
  if (subTaskIndex >= 0 && data[index].subTasks)
    decreasePoints = data[index].subTasks![subTaskIndex].points ?? 0;
  const targetStatePointsAtom = getCompletedTaskPointsState(stateType);
  const points = getRecoil(targetStatePointsAtom);
  setRecoil(targetStatePointsAtom, points - decreasePoints);
  const targetStateTaskSizeAtom = getCompletedTaskSizeState(stateType);
  const count = getRecoil(targetStateTaskSizeAtom);
  if (count) {
    let toDecrease = 1;
    const subTasks = data[index].subTasks;
    if (subTasks) {
      toDecrease = toDecrease + subTasks.length;
    }
    setRecoil(targetStateTaskSizeAtom, count - toDecrease);
  }
}

export function addCompletedTasks(
  data: TaskObjectType[],
  hasMore: boolean,
  page: number,
  points: number,
  size: number | null,
  stateType?: StateType | null,
) {
  const targetStateDataAtom = getCompletedTaskDataState(stateType);
  setRecoil(targetStateDataAtom, data);
  const targetStateHasMoreAtom = getCompletedTaskHasMoreState(stateType);
  setRecoil(targetStateHasMoreAtom, hasMore);
  const targetStatePageAtom = getCompletedTaskPageState(stateType);
  setRecoil(targetStatePageAtom, page);
  const targetStatePointsAtom = getCompletedTaskPointsState(stateType);
  setRecoil(targetStatePointsAtom, points);
  const targetStateTaskSizeAtom = getCompletedTaskSizeState(stateType);
  setRecoil(targetStateTaskSizeAtom, size);
}

export function addTaskToBacklog(task: TaskObjectType, stateType: StateType) {
  const { BACKLOG } = getStateTypeMain(stateType);
  let data = BACKLOG.data ? [...BACKLOG.data] : [];
  const tempTask = { ...task };

  if (!data) return;

  const index = data.findIndex((data) => data.id === task.id);

  if (index !== -1) return;

  tempTask.checked = false;
  tempTask.taskType = 'BACKLOG';
  if (stateType === 'PROJECT') tempTask.projectTaskType = 'BACKLOG';
  if (tempTask.subTasks) tempTask.subTasks = setCheckedValueForSubTasks(tempTask.subTasks, false);
  tempTask.sequence = data.length ? data[0].sequence + SEQUENCE_SMALL : SEQUENCE_SMALL;

  data = [tempTask, ...data];

  addTaskData('BACKLOG', data, stateType);
}

function setCheckedValueForSubTasks(data: TaskObjectType[], checked: boolean) {
  const newData: TaskObjectType[] = [];
  data.forEach((dat, _index) => {
    const subTask = { ...dat, checked };
    newData.push(subTask);
  });
  return newData;
}

export function updateCompletedTaskPoints(decreasePoints: number, stateType: StateType) {
  const targetStateAtom = getCompletedTaskPointsState(stateType);
  const points = getRecoil(targetStateAtom);
  setRecoil(targetStateAtom, points - decreasePoints);
}

function getPointsOfSubTasks(data: TaskObjectType[]) {
  let points = 0;
  data.forEach((dat) => {
    if (dat.points) points = points + dat.points;
  });
  return points;
}

export function addCompletedTask(task: TaskObjectType, stateType) {
  const targetStateAtom = getCompletedTaskDataState(stateType);
  const data = getRecoil(targetStateAtom);

  if (!data) return;

  const index = data.findIndex((data) => data.id === task.id);

  if (index !== -1) return;

  let increasePoints = task.points ? task.points : 0;
  if (task.subTasks) increasePoints = increasePoints + getPointsOfSubTasks(task.subTasks);

  const targetPoints = getCompletedTaskPointsState(stateType);
  const points = getRecoil(targetPoints);
  setRecoil(targetPoints, points + increasePoints);

  task.checked = true;
  task.subTasks = task.subTasks ? setCheckedValueForSubTasks(task.subTasks, true) : [];

  const tempData = [task, ...data];

  setRecoil(targetStateAtom, tempData);
}
