import { h } from 'preact';
const BaseIcon = function BaseIcon(_ref) {
  const color = _ref.color,
    _ref$pushRight = _ref.pushRight,
    pushRight = _ref$pushRight === undefined ? true : _ref$pushRight,
    children = _ref.children;
  return h(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
      stroke: color,
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      style: { marginRight: pushRight ? '20px' : '0', minWidth: 24 },
    },
    children,
  );
};

const InfoIcon = function InfoIcon() {
  return h(
    BaseIcon,
    { color: '#2E9AFE' },
    h('circle', { cx: '12', cy: '12', r: '10' }),
    h('line', { x1: '12', y1: '16', x2: '12', y2: '12' }),
    h('line', { x1: '12', y1: '8', x2: '12', y2: '8' }),
  );
};

const SuccessIcon = function SuccessIcon() {
  return h(
    BaseIcon,
    { color: '#31B404' },
    h('path', { d: 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }),
    h('polyline', { points: '22 4 12 14.01 9 11.01' }),
  );
};

const ErrorIcon = function ErrorIcon() {
  return h(
    BaseIcon,
    { color: '#FF0040' },
    h('circle', { cx: '12', cy: '12', r: '10' }),
    h('line', { x1: '12', y1: '8', x2: '12', y2: '12' }),
    h('line', { x1: '12', y1: '16', x2: '12', y2: '16' }),
  );
};

const CloseIcon = function CloseIcon() {
  return h(
    BaseIcon,
    { color: '#FFFFFF', pushRight: false },
    h('line', { x1: '18', y1: '6', x2: '6', y2: '18' }),
    h('line', { x1: '6', y1: '6', x2: '18', y2: '18' }),
  );
};

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];

      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

const alertStyle = {
  backgroundColor: '#151515',
  color: 'white',
  padding: '10px',
  // textTransform: 'uppercase',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  minWidth: '450px',
  maxWidth: '100%',
  boxSizing: 'border-box',
};

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF',
};

const AlertTemplate = function AlertTemplate(_ref) {
  const message = _ref.message,
    options = _ref.options,
    style = _ref.style,
    close = _ref.close;

  return h(
    'div',
    {
      style: _extends({}, alertStyle, style),
      className: 'react-alert-container',
    },
    options.type === 'info' && h(InfoIcon, null),
    options.type === 'success' && h(SuccessIcon, null),
    options.type === 'error' && h(ErrorIcon, null),
    h('span', { style: { flex: 2 } }, message),
    h('button', { onClick: close, style: buttonStyle }, h(CloseIcon, null)),
  );
};

export default AlertTemplate;
