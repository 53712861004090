import { TargetedEvent, useState } from 'preact/compat';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import appConfig from 'config/config';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { Validation, log } from 'utils';
import { Strings } from 'resources';
import { createUser } from 'services/AuthService';

export default function CreateAccount() {
  const navigate = useNavigate();
  const alert = useAlert();

  const [fields, setFields] = useState<ValidationFieldType[]>([
    {
      name: Strings.account_full_name,
      key: 'fullName',
      value: '',
      type: 'text',
      maxLength: 150,
      rules: 'required|max:150',
      touched: false,
      placeholder: Strings.account_full_name,
    },
    {
      name: Strings.email_address,
      key: 'email',
      value: '',
      type: 'email',
      maxLength: 50,
      rules: 'required|email|max:50',
      touched: false,
      placeholder: Strings.account_email_placeholder,
    },
    {
      name: Strings.password,
      key: 'password',
      value: '',
      type: 'text',
      maxLength: 256,
      rules: 'required|no_space|min:8|max:256',
      touched: false,
      secure: true,
      placeholder: Strings.password,
    },
  ]);

  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);

  /**
   * Render Input fields
   * @param {*} field
   * @param {*} index
   */
  const renderField = (field: ValidationFieldType, index: number) => {
    return (
      <Input
        containerClassname='auth-input-container'
        placeholder={field.placeholder ? field.placeholder : ''}
        onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value, index)}
        key={index}
        touched={field.touched}
        value={field.value as string}
        autoCapitalize={field.autoCapitalize}
        errorMessage={field.errors && field.errors.length ? field.errors[0] : ''}
        type={field.type}
        secure={field.secure}
      />
    );
  };

  const fieldUpdated = (text: string, index: number) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);

    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /*
   * Form has errors
   *
   * returns boolean
   */
  const checkErrors = (fields: ValidationFieldType[]) => {
    let hasError = false;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }

    return hasError;
  };

  const submit = (event: TargetedEvent) => {
    event.preventDefault();

    if (hasErrors || isLoading || !isAgreed) return;

    routeChange(getPayload(fields));
    // createAccount(getPayload(fields));
  };

  /*
   * Account creation by calling backend
   * payload object {name, email, password}
   *
   * returns null
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createAccount = async (payload: any) => {
    setLoadingState(true);
    const { data, success, message } = await createUser(payload);
    setLoadingState(false);

    if (!success) {
      alert.error(message);
      return log(message);
    } else {
      return accountCreationSuccess(data);
    }
  };

  /*
   * Account creation was successful
   *
   * returns null
   */
  const accountCreationSuccess = (data: any) => {
    routeChange(data);
    alert.success(Strings.account_success);
  };

  /*
   * Get payload for account creation
   * fields Array
   *
   * returns Object
   */
  const getPayload = (fields: ValidationFieldType[]) => {
    const payload = {};

    fields.forEach((field, _index) => {
      if (field.key === 'fullName' && typeof field.value === 'string') {
        if (field.value.trim().split(' ').length > 1) {
          const firstName = field.value.trim().split(' ').slice(0, -1).join(' ');
          const lastName = field.value.trim().split(' ').slice(-1).join(' ');
          payload['firstName'] = firstName.trim();
          payload['lastName'] = lastName.trim();
        } else {
          payload['firstName'] = field.value.trim();
          payload['lastName'] = '';
        }
      } else if (field.key) {
        payload[field.key] = field.value;
      }
    });
    payload['active'] = true;

    return payload;
  };

  /**
   * Push new screen to stack
   * on successfull account creation
   */
  const routeChange = (data: any) => {
    navigate('/workspace-creation', { state: { payload: data } });
  };

  return (
    <div className='account-screen-container'>
      <div className='magic-task-logo' alt='Logo' />
      <div className='create-account-sub-con'>
        <div className='form-con'>
          <div className='magic-task-logo-div'></div>
          <h3 className='account-title'>{Strings.create_account}</h3>
          <form onSubmit={(event) => submit(event)}>
            {fields.map((field, index) => renderField(field, index))}
            <div className='checkbox-con'>
              <input
                className='checkbox'
                id='terms'
                type='checkbox'
                checked={isAgreed}
                onChange={() => setIsAgreed(!isAgreed)}
              />
              <p>
                {Strings.agree_to}
                <span>
                  <a href={appConfig.TermsURL} target='_blank' rel='noreferrer'>
                    {Strings.terms}
                  </a>
                </span>
                {Strings.and}
                <span>
                  <a href={appConfig.PrivacyPolicyURL} target='_blank' rel='noreferrer'>
                    {Strings.privacy_policy}
                  </a>
                </span>
              </p>
            </div>

            <Button
              idName='create-account-screen-submit-btn'
              disabled={hasErrors || !isAgreed}
              loading={isLoading}
            >
              {Strings.submit}
            </Button>
          </form>
        </div>
        <div className='img-con'></div>
      </div>
    </div>
  );
}
