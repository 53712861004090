import { isUserLoggedIn, getUserId, reFetchUserSession } from 'services/AuthService';
import appConfig from 'config/config';
import apiConfig from 'config/api';
import { Client } from '@stomp/stompjs';
import { log } from 'utils';

import {
  updateSubTaskSequence,
  updateTaskSequence,
  addSubTask,
  addTask,
  updateTaskName,
  updateTaskProject,
  updateTaskSize,
  updateTaskStatus,
  removeTask,
  projectUserAdded,
  projectUserRemoved,
  updateProjectCounters,
  updateUserCounters,
  updateTaskStartEndDates,
  updateTaskAssignee,
  updateProjectName,
  updateProjectArchiveStatus,
  addTaskCommentsData,
  addSubtaskCommentsData,
  addTaskTags,
  addAccountUserTag,
  foldersUpdated,
  switchWorkspace,
  updateThemeItem,
  addRecurringTask,
  updateRecurringTask,
  updateRecurringTaskStatus,
  addProjectTemplate,
  removeProjectTemplate,
  updateTemplateCounters,
  addInvitedUser,
  updateTemplateName,
  updateTemplateMembers,
  updateWorkspaceStatus,
  workspaceDeleted,
  handleUserActivationStatus,
  handleNotifications,
  handleReadNotification,
  removeDeletedUser,
  changeTheme,
  removeWorkspace,
  deleteTaskCommentsData,
  deleteSubtaskCommentsData,
  updateTaskCommentsData,
  updateSubtaskCommentsData,
} from 'websocket/activities';
import { websocketStatusState } from 'recoil/TaskState';
import { getCompletedTasks, getTaskData } from 'recoil/TaskState/update';
import { getRecurringTasks } from 'recoil/RecurringTaskState/update';
import { setRecoil } from 'recoil-nexus';
import { addUser, removeUser } from 'recoil/AccountState/update';
import { getWorkspaces } from 'recoil/SuperAdminState/update';
let client: Client | null = null;
let accountHeartbeatTimer: any = null;
const HEARTBEAT_INTERVAL = 15000;

let isConnected = false;

export const open = () => {
  const { status, token, user } = isUserLoggedIn();
  if (!status || !token || !user) return;
  const userId = user.id;

  const url = `${appConfig.webSocketUrl}`;

  client = new Client({
    brokerURL: url,
    connectHeaders: {
      'X-Authorization': token,
      userId: userId,
    },
    debug: function (str) {
      log('debug', str);
      if (str.includes('did not receive server activity')) {
        isConnected = false;
      }
      if (str.includes('Web Socket Opened')) {
        isConnected = true;
      }
    },
    reconnectDelay: 2000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    connectionTimeout: 3000,
    discardWebsocketOnCommFailure: true,
  });

  client.discardWebsocketOnCommFailure = true;

  client.onConnect = function (frame) {
    const { status } = isUserLoggedIn();

    if (!status || frame?.command !== 'CONNECTED') return;
    const channel = `/${apiConfig.websocket.user}/${getUserId()}`;

    log(client, channel, 'channel to connect');

    if (typeof client !== 'undefined' && client) {
      client.subscribe(channel, onMessage, {});
      isConnected = true;
      setHeartBeat();

      getTaskData('FOCUS', true);
      getTaskData('BACKLOG', true);
      getCompletedTasks(undefined, true, undefined);
      getRecurringTasks();
    }
  };

  client.onDisconnect = function () {
    setRecoil(websocketStatusState, 'DISCONNECTED');
  };

  client.onStompError = function (frame) {
    // Will be invoked in case of error encountered at Broker
    // Bad login/passcode typically will cause an error
    // Complaint brokers will set `message` header with a brief message. Body may contain details.
    // Compliant brokers will terminate the connection after any error
    log('Broker reported error: ' + frame.headers['message']);
    log('Additional details: ' + frame.body);

    setRecoil(websocketStatusState, 'DISCONNECTED');
  };

  client.activate();
};

export const close = async () => {
  clearTimeout(accountHeartbeatTimer);
  setRecoil(websocketStatusState, 'DISCONNECTED');

  if (client) await client.deactivate();
};

const setHeartBeat = () => {
  setRecoil(websocketStatusState, 'CONNECTED');

  clearTimeout(accountHeartbeatTimer);
  accountHeartbeatTimer = setTimeout(() => {
    if (isConnected) {
      setHeartBeat();
    } else {
      resetConnection();
    }
  }, HEARTBEAT_INTERVAL);
};

const resetConnection = async () => {
  clearTimeout(accountHeartbeatTimer);

  setRecoil(websocketStatusState, 'DISCONNECTED');

  if (client) await client.deactivate();

  open();
};

const onMessage = (message) => {
  const body = message.body ? JSON.parse(message.body) : undefined;

  log('main channel event', body?.event);

  if (body?.event !== 'NOTIFICATION') {
    log('Main channel body', body);
  }

  if (body && body.event) handleEvent(body);
};

const handleEvent = (body) => {
  switch (body.event) {
    case 'WORKSPACE_SWITCHED':
      switchWorkspace(body.workspace ? body.workspace : null, body.token ? body.token : null);
      break;
    case 'TASK_UPDATE_SEQUENCE':
      body.parenttaskid
        ? updateSubTaskSequence(
            body.taskid ? body.taskid : null,
            body.sequence ? parseFloat(body.sequence) : 0,
            body.taskType ? body.taskType : 'BACKLOG',
            body.parenttaskid ? body.parenttaskid : null,
          )
        : updateTaskSequence(
            body.taskid ? body.taskid : null,
            body.sequence ? parseFloat(body.sequence) : 0,
            body.taskType ? body.taskType : 'BACKLOG',
          );
      break;
    case 'TASK_ADD':
      body.parenttaskid
        ? addSubTask(
            body.taskid ? body.taskid : null,
            body.parenttaskid ? body.parenttaskid : null,
            body.task ? body.task : {},
            body.tempid ? body.tempid : null,
          )
        : addTask(body.task ? body.task : {});
      break;
    case 'TASK_UPDATE': {
      let bodyTaskName = body.task && body.task.name ? body.task.name : '';
      const searchRegExp = /:c/gi;
      const replaceWith = '';
      if (bodyTaskName !== '') {
        bodyTaskName = bodyTaskName.replace(searchRegExp, replaceWith);
      }

      updateTaskName(
        body.task && body.task.id ? body.task.id : null,
        body.tempid ? body.tempid : null,
        bodyTaskName,
        body.task && body.task.taskType ? body.task.taskType : 'BACKLOG',
        body.parenttaskid ? body.parenttaskid : null,
      );
      break;
    }
    case 'TASK_UPDATE_PROJECT':
      updateTaskProject(
        body.taskid ? body.taskid : null,
        body.projectid ? body.projectid : null,
        body.parenttaskid ? body.parenttaskid : null,
        undefined,
        body.TASK_NUMBER ? body.TASK_NUMBER : null,
      );
      break;
    case 'TASK_UPDATE_SIZE':
      updateTaskSize(
        body.taskid ? body.taskid : null,
        body.sizeid ? body.sizeid : 'M',
        body.parenttaskid ? body.parenttaskid : null,
      );
      break;
    case 'TASK_UPDATE_DATE':
      updateTaskStartEndDates(
        body.taskid ? body.taskid : null,
        body.startdate,
        body.enddate,
        body.duedate,
        body.parenttaskid ? body.parenttaskid : null,
      );
      break;
    case 'TASK_UPDATE_STATUS':
      updateTaskStatus(
        body.taskid ? body.taskid : null,
        body.status && body.status === 'COMPLETED' ? true : false,
        body.parenttaskid ? body.parenttaskid : null,
        body.task,
      );
      break;
    case 'TASK_UPDATE_ASSIGNEE':
      updateTaskAssignee(
        body.taskid ? body.taskid : null,
        body.assigneeid ? body.assigneeid : null,
        body.parenttaskid ? body.parenttaskid : null,
      );
      break;
    case 'TASK_DELETE':
      removeTask(body.taskid ? body.taskid : null, body.parenttaskid ? body.parenttaskid : null);
      break;
    case 'PROJECT_ASSIGNEE_ADDED':
      projectUserAdded(body.project, body.user);
      break;
    case 'PROJECT_ASSIGNEE_REMOVED':
      projectUserRemoved(body.projectid, body.userid);
      break;
    case 'PROJECT_COUNT':
      updateProjectCounters(
        body.projectid ? body.projectid : undefined,
        body.projecttodocounts ? body.projecttodocounts : 0,
        body.recentCompletedCount ? body.recentCompletedCount : 0,
        body.focusPoints ? body.focusPoints : 0,
        body.backlogPoints ? body.backlogPoints : 0,
        body.remainingPoints ? body.remainingPoints : 0,
        body.completedPoints ? body.completedPoints : 0,
      );
      break;
    case 'TASK_COUNT':
      updateUserCounters(
        body.userid ? body.userid : undefined,
        body.todocounts ? body.todocounts : 0,
        body.recentCompletedCount ? body.recentCompletedCount : 0,
        body.focusPoints ? body.focusPoints : 0,
        body.backlogPoints ? body.backlogPoints : 0,
      );
      break;
    case 'PROJECT_RENAMED':
      updateProjectName(body.projectid ? body.projectid : null, body.projectname);
      break;
    case 'PROJECT_ARCHIVED':
      updateProjectArchiveStatus(body.projectid ? body.projectid : null, body.isArchived);
      break;

    case 'COMMENT_ADDED':
      body.parenttaskid
        ? addSubtaskCommentsData(
            body.parenttaskid ? body.parenttaskid : null,
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
          )
        : addTaskCommentsData(
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
          );
      break;
    case 'COMMENT_UPDATED':
      body.parenttaskid
        ? updateSubtaskCommentsData(
            body.parenttaskid ? body.parenttaskid : null,
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
          )
        : updateTaskCommentsData(
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
          );
      break;
    case 'COMMENT_DELETED':
      body.parenttaskid
        ? deleteSubtaskCommentsData(
            body.parenttaskid ? body.parenttaskid : null,
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
          )
        : deleteTaskCommentsData(
            body.taskid ? body.taskid : null,
            body.TASKTYPE ? body.TASKTYPE : 'BACKLOG',
            body.comment,
          );
      break;
    case 'FOLDER_REMOVED':
      foldersUpdated();
      break;
    case 'FOLDER_UPDATED':
      foldersUpdated();
      break;
    case 'TAG_ASSIGNED':
      addTaskTags(
        body.task && body.task.id ? body.task.id : undefined,
        body?.task?.tags ? body?.task?.tags : [],
        body?.task?.tagInfo ? body?.task?.tagInfo : [],
        true,
        body.parenttaskid ? body.parenttaskid : null,
        body?.tag ? body?.tag : undefined,
        undefined,
        body?.task?.taskType ? body?.task?.taskType : 'BACKLOG',
        body?.task?.projectTaskType ? body?.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'TAG_UNASSIGNED':
      addTaskTags(
        body.task && body.task.id ? body.task.id : undefined,
        body?.task?.tags ? body?.task?.tags : [],
        body?.task?.tagInfo ? body?.task?.tagInfo : [],
        false,
        body.parenttaskid ? body.parenttaskid : null,
        body?.tag ? body?.tag : undefined,
        undefined,
        body.task?.taskType ? body?.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body?.task?.projectTaskType : 'BACKLOG',
      );
      break;

    case 'TAG_ADDED':
      addAccountUserTag(
        body.tag ? body.tag : undefined,
        true,
        body?.tag?.project ? 'allProjectTags' : 'allUserTags',
        body?.projectId ? body.projectId : null,
        undefined,
        body.task?.taskType ? body.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'TAG_REMOVED':
      addAccountUserTag(
        body.tag ? body.tag : undefined,
        false,
        body?.tag?.project ? 'allProjectTags' : 'allUserTags',
        null,
        undefined,
        body.task?.taskType ? body.task?.taskType : 'BACKLOG',
        body.task?.projectTaskType ? body.task?.projectTaskType : 'BACKLOG',
      );
      break;
    case 'POINTS_UPDATED':
      updateThemeItem(
        body.THEMEID ? body.THEMEID : undefined,
        body.POINTS,
        body.CURRENT_LEVEL,
        body.PROGRESS,
        body.LEVELCHANGED,
      );
      break;
    case 'RECURRING_TASK_CREATED':
      addRecurringTask(body.RECURRING_TASK);
      break;
    case 'RECURRING_TASK_UPDATED':
      updateRecurringTask(body.RECURRING_TASK, 'RECURRING');
      break;
    case 'RECURRING_TASK_ACTIVE_STATUS_UPDATED':
      updateRecurringTaskStatus(body.RECURRING_TASK);
      break;
    case 'PROJECT_TEMPLATE_CREATED':
      addProjectTemplate(body.PROJECT_TEMPLATE);
      break;
    case 'PROJECT_TEMPLATE_DELETED':
      log('PROJECT_TEMPLATE_DELETED', body);
      removeProjectTemplate(body.PROJECT_TEMPLATE_ID);
      break;
    case 'TEMPLATE_PROJECT_COUNTER_UPDATED':
      updateTemplateCounters(
        body.id,
        body.TEMPLATE_PROJECT_POINTS !== undefined && body.TEMPLATE_PROJECT_POINTS !== null
          ? body.TEMPLATE_PROJECT_POINTS
          : undefined,
        body.TEMPLATE_PROJECT_TASKS_COUNT !== undefined &&
          body.TEMPLATE_PROJECT_TASKS_COUNT !== null
          ? body.TEMPLATE_PROJECT_TASKS_COUNT
          : undefined,
        body.TEMPLATE_PROJECT_PROJECTS_CREATED_COUNT !== undefined &&
          body.TEMPLATE_PROJECT_PROJECTS_CREATED_COUNT !== null
          ? body.TEMPLATE_PROJECT_PROJECTS_CREATED_COUNT
          : undefined,
      );
      break;
    case 'PROJECT_TEMPLATE_NAME_UPDATED':
      updateTemplateName(body.PROJECT_TEMPLATE_ID, body.PROJECT_TEMPLATE_NAME);

      break;
    case 'PROJECT_TEMPLATE_USERS_UPDATED':
      updateTemplateMembers(body.PROJECT_TEMPLATE_ID, body.PROJECT_TEMPLATE_USERS);
      break;
    case 'NEW_USER_ADDED':
      addInvitedUser(body.userlog);
      break;
    case 'NEW_USER_MODIFIED':
      if (body.userlog.invitationStatus.toLowerCase() === 'accepted' && body.userlog.active) {
        addUser(body.userlog);
      } else if (!body.userlog.active) {
        removeUser(body.userlog.userId ? body.userlog.userId : body.userlog.id);
      }
      if (body.userlog.active) addInvitedUser(body.userlog);
      break;
    case 'WORKSPACE_STATUS_CHANGED':
      updateWorkspaceStatus(body.workspace, body.status, body.MESSAGE);
      break;
    case 'WORKSPACE_DELETED':
      if (body.PartOfWorkspace) {
        body.token
          ? body.MESSAGE
            ? workspaceDeleted(body.MESSAGE, body.token) // for other users whose current workspace is deleted show message before refreshing session
            : reFetchUserSession(body.token) // for user who deleted workspace directly refresh session
          : removeWorkspace(body.workspaceId); // remove workspace from user workspace list
      } else {
        getWorkspaces(true); // for super admin refetch the workspaces list to show in super admin
      }
      break;
    case 'USER_ACTIVATION_STATUS_CHANGED':
      handleUserActivationStatus(
        body.ActivationStatus,
        body.ForceLogOutMessage,
        body.workspaceId,
        body.user ? body.user : null,
        body.userId ? body.userId : null,
      );
      break;
    case 'USER_REMOVED':
      removeDeletedUser(body.userid);
      break;
    case 'NOTIFICATION':
      handleNotifications(body.unreadCounts, body.data);
      break;
    case 'EVENT_ALL_NOTIFICATIONS_READ':
      handleReadNotification();
      break;
    case 'USER_ROLE_CHANGED':
      reFetchUserSession();
      break;
    case 'THEME_STATUS_EQUIPPED':
      changeTheme(body.THEMEID);
      break;
    default:
      break;
  }
};
