import { useEffect } from 'preact/hooks';
import { Navigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { setPageTitle } from 'utils';
import { isUserLoggedIn } from 'services/AuthService';
import { loadTheme } from 'utils/theme';
// import rg4js from 'raygun4js';

type AuthLayoutProps = {
  pageTitle: string;
};

export default function AuthLayout(props: AuthLayoutProps) {
  const { pageTitle } = props;

  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    loadTheme(false, true);
  }, []);

  // component did mount
  useEffect(() => {
    setPageTitle(pageTitle ? pageTitle : '');

    // rg4js('trackEvent', {
    //   type: 'pageView',
    //   path: '/' + window.location.pathname,
    // });
  }, [pageTitle]);

  const { status } = isUserLoggedIn();

  return (
    <div className='auth-layout-container'>
      {!status || location.pathname.startsWith('/form') ? (
        // @ts-ignore
        <Outlet />
      ) : location.pathname.startsWith('/personal-info') ? (
        <Navigate to={'/'} state={{ path: location.pathname, params: params }} />
      ) : (
        <Navigate to='/' />
      )}
    </div>
  );
}
