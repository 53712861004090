import { useEffect, useState, Fragment, useMemo } from 'preact/compat';
import { getIndividualBoardList } from 'services/LeaderboardService';
import AvatarIcon from 'assets/images/Avatar.png';
import Spinner from 'components/UI/Spinner';
import Table from 'components/Table';
import Pagination from '../Pagination';
import { Strings } from 'resources';
import { formatDateForView } from 'utils/task';
import { useRecoilState } from 'recoil';
import { userEnrolledState } from 'recoil/NavigationState';

const PAGE_SIZE = 10;

export default function IndividualLeaderboard() {
  const [userEnrolled, setEnrolledStatus] = useRecoilState(userEnrolledState);

  const [page, setPage] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [leaderBoardItems, setLeaderBoardItems] = useState([{ resultList: [], pageNumber: 0 }]);

  useEffect(() => {
    const pos = leaderBoardItems.findIndex((item) => item.pageNumber === page);
    if (pos === -1 || firstLoad || userEnrolled) {
      getIndividualList();
      setFirstLoad(false);
    }
  }, [page, userEnrolled]);

  const getIndividualList = async () => {
    setIsLoading(true);
    const { data } = await getIndividualBoardList(getPayload());
    if (!data?.leaderBoardItems) {
      setIsLoading(false);
      return;
    } else if (userEnrolled) {
      setLeaderBoardItems([{ resultList: data.leaderBoardItems, pageNumber: page }]);
      setEnrolledStatus(false);
    } else {
      const pos = leaderBoardItems.findIndex((item) => item.pageNumber === page);
      if (pos !== -1) {
        const res = leaderBoardItems[pos];
        res.resultList = data.leaderBoardItems;
        leaderBoardItems[pos] = res;
        setLeaderBoardItems(leaderBoardItems);
      } else {
        setLeaderBoardItems([
          ...leaderBoardItems,
          { resultList: data.leaderBoardItems, pageNumber: page },
        ]);
      }
    }
    setTotalPages(data.totalPages);
    setIsLoading(false);
  };

  const getPayload = () => {
    return {
      pageNumber: page,
      size: PAGE_SIZE,
    };
  };

  const columns = useMemo(
    () => [
      {
        Header: Strings.rank,
        Cell: ({ row }) => renderRank(row.original),
      },
      {
        Header: Strings.username_placeholder,
        Cell: ({ row }) => renderUsername(row.original),
      },
      {
        Header: Strings.total_points,
        Cell: ({ row }) => renderTotalPoints(row.original),
      },
      {
        Header: Strings.tasks_completed,
        Cell: ({ row }) => renderTasksCompleted(row.original),
      },
      {
        Header: Strings.themes_owned,
        Cell: ({ row }) => renderThemesOwned(row.original),
      },
      {
        Header: Strings.themes_maxed,
        Cell: ({ row }) => renderThemesMaxed(row.original),
      },
      {
        Header: Strings.date_joined,
        Cell: ({ row }) => renderDateJoined(row.original),
      },
    ],
    [],
  );

  const getResultList = () => {
    const pos = leaderBoardItems.findIndex((item) => item.pageNumber === page);
    return pos === -1 ? [] : leaderBoardItems[pos].resultList;
  };

  const renderRank = (user: LeaderboardItemsType) => {
    return (
      <Fragment>
        <span className='user-rank'>{user.rank}</span>
      </Fragment>
    );
  };

  const renderUsername = (user: LeaderboardItemsType) => {
    return (
      <span className='user-name'>
        <img src={getProfileImage(user)} alt='Display Pic' />
        {user.name}
      </span>
    );
  };

  const getProfileImage = (user: LeaderboardItemsType) => {
    return user.url ? user.url : AvatarIcon;
  };

  const renderTotalPoints = (user: LeaderboardItemsType) => {
    return (
      <Fragment>
        <span className={`user-field ${user.totalPoints ? '' : 'hide'}`}>{user.totalPoints}</span>
      </Fragment>
    );
  };

  const renderTasksCompleted = (user: LeaderboardItemsType) => {
    return (
      <Fragment>
        <span className={`user-field ${user.tasksCompleted ? '' : 'hide'}`}>
          {user.tasksCompleted}
        </span>
      </Fragment>
    );
  };

  const renderThemesOwned = (user: LeaderboardItemsType) => {
    return (
      <Fragment>
        <span className={`user-field ${user.themesOwned ? '' : 'hide'}`}>{user.themesOwned}</span>
      </Fragment>
    );
  };

  const renderThemesMaxed = (user: LeaderboardItemsType) => {
    return (
      <Fragment>
        <span className={`user-field ${user.themesMaxed ? '' : 'hide'}`}>{user.themesMaxed}</span>
      </Fragment>
    );
  };

  const renderDateJoined = (user: LeaderboardItemsType) => {
    return (
      <Fragment>
        <span className={`user-dates ${user.dateJoined ? '' : 'hide'}`}>
          {formatDateForView(user.dateJoined)}
        </span>
      </Fragment>
    );
  };

  const renderPaginationSection = () => {
    return (
      <Pagination
        className='pagination-bar'
        currentPage={page + 1}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => setPage(typeof page === 'number' ? page - 1 : 0)}
      />
    );
  };

  const renderIndividualLeaderboardData = () => {
    return (
      <Fragment>
        <Table
          columns={columns}
          data={getResultList()}
          headContainerClass='user-container-head'
          elementsClass='user-item'
          bodyContainerClass='user-in-container'
          clickable={false}
          lastRowClass='user-in-last-row-container'
          decorateLastRow={totalPages > 1 && totalPages !== page + 1 ? true : false}
        />
        {totalPages > 1 ? renderPaginationSection() : null}
      </Fragment>
    );
  };

  const renderLoader = () => {
    return (
      <div id='individual-leaderboard-loader-con' className='no-results'>
        <Spinner />
      </div>
    );
  };

  return (
    <div className='individual-leaderboard-con'>
      {isLoading ? renderLoader() : renderIndividualLeaderboardData()}
    </div>
  );
}
