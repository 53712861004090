import { useEffect, useState, Fragment } from 'preact/compat';
import { formatDateForView } from 'utils/task';
import {
  faSortAmountDown,
  faSortAlphaDown,
  faSortAlphaUp,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { close as closeProjectSocket } from 'websocket/project';
import { Strings } from 'resources';
import Switch from 'react-switch';
import { getUserId } from 'services/AuthService';
import { useRecoilState } from 'recoil';
import { projectDataSelector } from 'recoil/ProjectState';
import {
  archiveProject,
  closeAllProjects,
  deleteProjectForm,
  shareProject,
} from 'recoil/ProjectState/update';
import ProjectFormSettings from 'modals/ProjectFormSettings';

export default function Portfolio() {
  const [showArchived, setShowArchived] = useState(false);
  const [projectData, setProjectData] = useRecoilState(projectDataSelector);
  const userId = getUserId();
  useEffect(() => {
    closeAllProjects();
    return () => {
      closeProjectSocket();
      closeAllProjects();
    };
  }, []);

  const getStatusPriority = (status?: ProjectStatusType | '') => {
    switch (status) {
      case 'on track':
        return 0;
      case 'at risk':
        return 1;
      case 'off track':
        return 2;
      default:
        return 3;
    }
  };

  const compare = (valueA: any, valueB: any, sortOrder: SortOrderType) => {
    if (sortOrder === 'asc') {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else {
      if (valueB < valueA) return -1;
      if (valueB > valueA) return 1;
    }
    return 0;
  };

  // const getProjectStatusText = (project: ProjectObjectType) => {
  //   if (project.archived) {
  //     return 'archived';
  //   }
  //   return project.status ? project.status.toLowerCase() : '';
  // };

  // const renderProjectStatus = (project: ProjectObjectType) => {
  //   return (
  //     <Fragment>
  //       <span className='project-item-title'>{Strings.status}</span>
  //       <span className={`project-status ${getProjectStatusClass(project)}`}>
  //         {getProjectStatusText(project)}
  //       </span>
  //     </Fragment>
  //   );
  // };

  // const getProjectStatusClass = (project: ProjectObjectType) => {
  //   const status = getProjectStatusText(project);

  //   switch (status) {
  //     case 'on track':
  //       return 'success';
  //     case 'at risk':
  //       return 'warning';
  //     case 'off track':
  //       return 'danger';
  //     case 'archived':
  //       return 'archived';
  //     default:
  //       return '';
  //   }
  // };

  const renderProjectCompletedPoints = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.completed_points}</span>
        <span className='project-points'>
          <span className='project-points-title'>{`${Strings.completed_points}: `}</span>
          {project.completedPoints}
        </span>
      </Fragment>
    );
  };

  const renderProjectInFocusPoints = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.in_focus_points}</span>
        <span className='project-points'>
          <span className='project-points-title'>{`${Strings.in_focus_points}: `}</span>
          {project.focusPoints}
        </span>
      </Fragment>
    );
  };

  const renderProjectRemainingPoints = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.points}</span>
        <span className='project-points'>
          <span className='project-points-title'>{`${Strings.remaining_points}: `}</span>
          {project.remainingPoints}
        </span>
      </Fragment>
    );
  };

  const getProjectDueDatesText = (project: ProjectObjectType) => {
    const startDate = project.startDate ? formatDateForView(project.startDate) : '';
    const dueDate = project.dueDate ? formatDateForView(project.dueDate) : '';

    if (startDate === '' && dueDate === '') {
      return (
        <Fragment>
          <span className='project-item-title'>{Strings.date}</span>
          <span className='no-dates'>{Strings.no_date}</span>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <span className='project-item-title'>{Strings.date}</span>
        <span className='project-due-dates'>
          {`${startDate}${startDate && dueDate ? ' - ' : ''}${dueDate}`}
        </span>
      </Fragment>
    );
  };

  const getSortIcon = (name: SortByType) => {
    const { sortBy, sortOrder } = projectData;
    if (sortBy === name) {
      if (sortOrder === 'asc') return faSortAlphaDown;
      else return faSortAlphaUp;
    }

    return faSortAmountDown;
  };

  const isSelected = (name: SortByType) => {
    const { sortBy } = projectData;

    if (sortBy === name) return 'selected';

    return '';
  };

  const sortByFilter = (name: SortByType) => {
    let { sortBy, sortOrder } = projectData;

    if (sortBy !== name) {
      sortBy = name;
      sortOrder = 'asc';
    } else {
      if (sortOrder === 'asc') sortOrder = 'desc';
      else sortOrder = 'asc';
    }

    setProjectData({ ...projectData, sortBy, sortOrder });
  };

  const getFilteredProjects = () => {
    let filteredProjects: ProjectObjectType[] = [];
    if (showArchived) {
      filteredProjects = projectData.allProjects
        ? projectData.allProjects.filter((project) => {
            return project.archived === true;
          })
        : [];
    } else {
      filteredProjects = projectData.allProjects
        ? projectData.allProjects.filter((project) => {
            return project.archived !== true;
          })
        : [];
    }
    return filteredProjects;
  };
  const getSortedData = () => {
    const { sortBy, sortOrder } = projectData;
    const allProjects = getFilteredProjects();
    return allProjects.sort(function (a, b) {
      let valueA;
      let valueB;
      if (sortBy === 'status') {
        valueA = getStatusPriority(a.status);
        valueB = getStatusPriority(b.status);
      } else {
        valueA = a[sortBy];
        valueB = b[sortBy];
      }

      if (sortBy === 'display') {
        return compare(valueA, valueB, sortOrder);
      } else {
        if (sortOrder === 'asc') return +(valueA > valueB) || -(valueA < valueB);
        else return +(valueB > valueA) || -(valueB < valueA);
      }
    });
  };

  const projectArchiveUpdated = (projectId: string, archived: boolean) => {
    archiveProject(projectId, archived, userId);
  };
  const [projectFormSettings, setProjectFormSettings] = useState<null | ProjectObjectType>(null);

  // const [projectFormSettingsId, setProjectFormSettingsId] = useState<null | string>(null);
  // const [projectFormSettingsFormId, setProjectFormSettingsFormId] = useState<null | string>(null);
  // const [projectFormSettingsDisplay, setProjectFormSettingsDisplay] = useState<null | string>(null);

  const renderProjectInfo = (project: ProjectObjectType) => {
    return (
      <span className={'project-globe'} onClick={() => setProjectFormSettings(project)}>
        <FontAwesomeIcon icon={faGlobe} />
      </span>
    );
  };

  useEffect(() => {
    if (projectFormSettings && projectFormSettings.id) {
      const selProj = projectData.allProjects?.filter((proj) => proj.id === projectFormSettings.id);
      if (selProj && selProj.length > 0) {
        setProjectFormSettings(selProj[0]);
      }
    }
  }, [projectData.allProjects]);

  const renderProjectArchived = (project: ProjectObjectType) => {
    return (
      <Fragment>
        <span className='project-item-title'>{Strings.archived}</span>
        <span className='project-archive'>
          {/** @ts-ignore */}
          <Switch
            onChange={(nextChecked) => projectArchiveUpdated(project.id, nextChecked)}
            checked={project.archived ? project.archived : false}
            height={24}
            handleDiameter={14}
            onColor='#6666ff'
            offColor='#e0e0ff'
            offHandleColor='#aaaaaa'
            className='react-switch'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </span>
      </Fragment>
    );
  };

  const renderProject = (project: ProjectObjectType, index: number) => {
    return (
      <div className='project-main-container' key={index}>
        <div className='project-in-container'>
          <div className='project-item project-name-con'>
            <span className='project-name'>{project.display}</span>
          </div>
          <div className='rightCon'>
            {/* <div className='project-item project-name-right'>
              <span className='project-item-title'>{Strings.project_name}</span>
              <span className='project-name'>{project.display}</span>
            </div> */}
            {/* <div className='project-item right-item'>{renderProjectStatus(project)}</div> */}
            <div className='project-item'>{renderProjectCompletedPoints(project)}</div>
            <div className='project-item right-item'>{renderProjectInFocusPoints(project)}</div>
            <div className='project-item'>{renderProjectRemainingPoints(project)}</div>
            <div className='project-item right-item'>{getProjectDueDatesText(project)}</div>
            <div className='project-item archive-column'>{renderProjectArchived(project)}</div>
            {!project.archived && <div className='project-item'>{renderProjectInfo(project)}</div>}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      <div className='title-container-archive'>
        <div className='checkbox-container'>
          <input
            type='checkbox'
            id='archived'
            name='archived'
            checked={showArchived}
            autoComplete='off'
            data-lpignore='true'
            data-form-type='other'
            onChange={() => setShowArchived(!showArchived)}
          />
          <label htmlFor='archived'>{Strings.show_archived}</label>
        </div>
      </div>
      <div className='projects-details-con'>
        <div className='project-main-container head'>
          <div className='project-container-head'>
            <div className='project-item project-name-con'>
              <span
                className={`sort-icon ${isSelected('display')}`}
                onClick={() => sortByFilter('display')}
              >
                <FontAwesomeIcon icon={getSortIcon('display')} />
              </span>
              <span className='project-name'>{` ${Strings.projects}`}</span>
            </div>
            <div className='rightCon'>
              {/* <div className='project-item project-name-right'>
                <span
                  className={`sort-icon ${isSelected('display')}`}
                  onClick={() => sortByFilter('display')}
                >
                  <FontAwesomeIcon icon={getSortIcon('display')} />
                </span>
                {` ${Strings.projects}`}
              </div> */}
              {/* <div className='project-item'>
                <span className='project-status'>
                  <span
                    className={`sort-icon ${isSelected('status')}`}
                    onClick={() => sortByFilter('status')}
                  >
                    <FontAwesomeIcon icon={getSortIcon('status')} />
                  </span>
                  {` ${Strings.status}`}
                </span>
              </div> */}
              <div className='project-item'>
                <span className='project-points'>
                  <span
                    className={`sort-icon ${isSelected('completedPoints')}`}
                    onClick={() => sortByFilter('completedPoints')}
                  >
                    <FontAwesomeIcon icon={getSortIcon('completedPoints')} />
                  </span>
                  {` ${Strings.points_completed}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-points'>
                  <span
                    className={`sort-icon ${isSelected('focusPoints')}`}
                    onClick={() => sortByFilter('focusPoints')}
                  >
                    <FontAwesomeIcon icon={getSortIcon('focusPoints')} />
                  </span>
                  {` ${Strings.points_in_focus}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-points'>
                  <span
                    className={`sort-icon ${isSelected('remainingPoints')}`}
                    onClick={() => sortByFilter('remainingPoints')}
                  >
                    <FontAwesomeIcon icon={getSortIcon('remainingPoints')} />
                  </span>
                  {` ${Strings.points}`}
                </span>
              </div>
              <div className='project-item'>
                <span className='project-due-dates'>
                  <span
                    className={`sort-icon ${isSelected('startDate')}`}
                    onClick={() => sortByFilter('startDate')}
                  >
                    <FontAwesomeIcon icon={getSortIcon('startDate')} />
                  </span>
                  {` ${Strings.date}`}
                </span>
              </div>
              <div className='project-item archive-column'>
                <span className='project-archive'>
                  <span
                    className={`sort-icon ${isSelected('archived')}`}
                    onClick={() => sortByFilter('archived')}
                  >
                    <FontAwesomeIcon icon={getSortIcon('archived')} />
                  </span>
                  {` ${Strings.archived}`}
                </span>
              </div>
              {!showArchived && <div className='project-item'></div>}
            </div>
          </div>
        </div>
        {getSortedData().map((project, index) => renderProject(project, index))}
      </div>
      {projectFormSettings && (
        <ProjectFormSettings
          project={projectFormSettings}
          closeModal={() => setProjectFormSettings(null)}
          createFormLink={(id: string) => shareProject(id, userId)}
          deleteForm={(formId) => deleteProjectForm(userId, formId)}
        />
      )}
    </Fragment>
  );
}
