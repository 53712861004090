import { Fragment, useMemo, useState } from 'preact/compat';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAlert } from 'react-alert';

import SmallButton from 'components/UI/SmallButton';
// import Spinner from 'components/UI/Spinner';
import Table from 'components/Table';
import SubscribeModal from 'modals/SubscribeModal';
import SubscriptionDetails from './SubscriptionDetails';

import { subscriptionState, accountState } from 'recoil/AccountState';
import { showPricingModalState } from 'recoil/NavigationState';
import {
  cancelSubscription as cancelSubscriptionAPI,
  intentSetup as intentSetupAPI,
} from 'services/SubscriptionService';
import { getSubscriptionDetail } from 'recoil/AccountState/update';

import UserIcon from '../../assets/images/payment/user-icon.svg';
import CrownIcon from '../../assets/images/payment/crown-icon.svg';
import { formatTimestampToDate } from 'utils/task';
import { log } from 'utils';
import { Strings } from 'resources';
import { UPGRADE_MODAL } from 'utils/constants';

export default function Subscription() {
  const [clientSecret, setClientSecret] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [isBuying, setIsBuying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const alert = useAlert();
  const subscriptionDetail = useRecoilValue(subscriptionState);
  const account = useRecoilValue(accountState);
  const setShowPricingModal = useSetRecoilState(showPricingModalState);
  const data = UPGRADE_MODAL['WORKSPACE'];

  const columns = useMemo(
    () => [
      {
        Header: Strings.date,
        Cell: ({ row }) => renderSubsciptionDate(row.original),
      },
      {
        Header: Strings.price,
        Cell: ({ row }) => renderPrice(row.original),
      },
      {
        Header: Strings.status,
        Cell: ({ row }) => renderStatus(row.original),
      },
    ],
    [],
  );

  const renderSubsciptionDate = (billing: BillingHistoryType) => {
    return <span className={`billing-dates`}>{formatTimestampToDate(billing.date)}</span>;
  };

  const renderStatus = (billing: BillingHistoryType) => {
    return <span className={`billing-status ${billing.status}`}>{billing.status}</span>;
  };

  const renderPrice = (billing: BillingHistoryType) => {
    const isValidPrice =
      typeof billing.price === 'number' && !isNaN(billing.price) && billing.price >= 0;
    const formattedPrice = isValidPrice ? (billing.price / 100).toFixed(2) : '0.00';

    return <span className='billing-price'>{`$${formattedPrice}`}</span>;
  };

  const onCloseModal = () => {
    setIsBuying(false);
    setClientSecret('');
    setCustomerId('');
  };

  const subscribe = async () => {
    setIsSubscribing(true);
    const { data } = await intentSetupAPI();
    log('stripe response', data);
    if (data && data.client_secret) {
      setClientSecret(data.client_secret);
    }
    if (data && data.customer) {
      setCustomerId(data.customer);
    }
    setIsBuying(true);
    setIsSubscribing(false);
  };

  const cancelPlan = async () => {
    if (subscriptionDetail?.id) {
      setIsLoading(true);
      const { success, data } = await cancelSubscriptionAPI({
        subscriptionId: subscriptionDetail.id,
      });
      if (success) {
        alert.success(data.message);
        await getSubscriptionDetail();
      }

      setIsLoading(false);
    }
  };

  const renderPlebButton = () => {
    return (
      <div className='pleb-plan-btn'>
        <img src={UserIcon} alt='Pleb Plan Icon' className='plan-icon' />
        {Strings.pleb}
        <SmallButton
          btnType='update-plan-btn'
          loading={isSubscribing}
          clicked={() => setShowPricingModal(true)}
        >
          {Strings.upgrade_plan}
        </SmallButton>
      </div>
    );
  };

  const renderEliteButton = (showCancel = true) => {
    return (
      <>
        <div className='elite-plan-btn'>
          <img src={CrownIcon} alt='Elite Plan Icon' className='plan-icon' />
          {Strings.elite}
          {showCancel && (
            <SmallButton btnType='update-plan-btn' loading={isLoading} clicked={cancelPlan}>
              {Strings.cancel_plan}
            </SmallButton>
          )}
        </div>
        <SmallButton btnType='check-plan-btn' clicked={() => setShowPricingModal(true)}>
          {Strings.check_plans}
        </SmallButton>
      </>
    );
  };

  const renderButtons = () => {
    const premiumStatus = account?.workspace?.premiumStatus;
    if (premiumStatus === 'FREE' || premiumStatus === 'PENDING_PAYMENT') {
      return renderPlebButton();
    }

    if (premiumStatus === 'PREMIUM' && subscriptionDetail?.status === 'canceled') {
      return renderEliteButton(false);
    }

    if (premiumStatus === 'PREMIUM') {
      return renderEliteButton();
    }

    return null;
  };

  return (
    <Fragment>
      {isBuying && clientSecret && customerId && (
        <SubscribeModal
          clientSecret={clientSecret}
          customerId={customerId}
          closeModal={onCloseModal}
        />
      )}
      <div className='title-container title-container-subscription'>
        <div className='left-con'>
          <h3 className='list-title'>{Strings.subscription}</h3>
        </div>

        <div className='right-con'>
          {/* {subscriptionDetail?.status === 'active' && account?.workspace?.premiumStatus !== 'FREE'
            ? renderEliteButton()
            : renderPlebButton()} */}
          {renderButtons()}
        </div>
      </div>
      {subscriptionDetail ? (
        <Fragment>
          <SubscriptionDetails {...subscriptionDetail} subscribe={subscribe} />
          <div className='subscription-main-con'>
            <h4 className='billing-history-title'>{Strings.billing_history_title}</h4>
            <Table columns={columns} data={subscriptionDetail.billingHistory} clickable={false} />
          </div>
        </Fragment>
      ) : (
        <div className='upgrade-workspace-container'>
          <div className='uw-featured-container'>
            <img src={data?.featuredImage} alt='modal featured' className='uw-featured-image' />
          </div>
          <div className='uw-content'>
            <h2
              className='uw-title'
              dangerouslySetInnerHTML={{
                __html: data?.title,
              }}
            />
            <p className='uw-description'>{data?.description}</p>
          </div>
          <div className='uw-button-container'>
            <SmallButton btnType='uw-btn uw-learn-btn' clicked={() => setShowPricingModal(true)}>
              {Strings.learn}
            </SmallButton>
          </div>
        </div>
      )}
    </Fragment>
  );
}
