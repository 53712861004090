import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TargetedEvent, Ref } from 'preact/compat';

type InputPropsType = {
  id?: string;
  autoCapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  autoComplete?: string;
  placeholder: string;
  value: string;
  onChange: (e: TargetedEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  icon?: IconProp;
  errorMessage?: string;
  touched?: boolean;
  secure?: boolean;
  disabled?: boolean;
  containerClassname?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
  type?: string;
  inputRef?: Ref<HTMLInputElement>;
};

export default function Input(props: InputPropsType) {
  const {
    autoCapitalize,
    placeholder,
    value,
    onChange,
    onBlur,
    icon,
    errorMessage,
    touched,
    secure,
    disabled,
    containerClassname,
    onKeyDown,
    type,
    inputRef,
    autoComplete,
  } = props;

  const renderIcon = () => {
    if (icon)
      return (
        <span className='input-icon-con'>
          <FontAwesomeIcon icon={icon} />
        </span>
      );

    return null;
  };

  const renderError = () => {
    if (errorMessage && touched) return <div className='input-error'>{errorMessage}</div>;

    return null;
  };

  const renderElement = () => {
    const inputClasses = ['input'];

    if (errorMessage && touched) {
      inputClasses.push('invalid');
    }
    if (disabled) {
      inputClasses.push('disabled');
    }
    return (
      <input
        ref={inputRef}
        autoCapitalize={autoCapitalize}
        className={inputClasses.join(' ')}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={secure ? 'password' : type ? type : 'input'}
        disabled={disabled}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        data-lpignore='true'
        data-form-type='other'
        autoFocus={containerClassname === 'search-input'}
      />
    );
  };

  return (
    <div className={`input-container ${containerClassname}`}>
      <div className={`text-input ${disabled ? 'disabled' : ''} ${icon ? 'has-icon' : 'ß'}`}>
        {renderElement()}
        {icon ? renderIcon() : null}
      </div>
      {renderError()}
    </div>
  );
}
