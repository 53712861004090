import { Fragment } from 'preact/compat';
import { useSetRecoilState } from 'recoil';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Appearance } from '@stripe/stripe-js';

import SubscribeForm from 'components/SubscribeForm';
import { showPricingModalState } from 'recoil/NavigationState';
import appConfig from 'config/config';

import '../PricingModal/_pricing-modal.scss';

const stripePromise = loadStripe(appConfig.stripe_key);

export default function SubscribeModal({ clientSecret = '', customerId = '', closeModal }) {
  const setShowPricingModal = useSetRecoilState(showPricingModalState);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#332a7c',
      colorText: '#332a7c',
    },
  };

  const renderCloseButton = () => (
    <div className='pricing-close-con'>
      <button className='close-btn' onClick={() => closeModal()}></button>
    </div>
  );

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Fragment>
      <div className='pricing-modal-container'>
        <div className='main-container'>
          {clientSecret && customerId && (
            <div className='payment-form-container'>
              {/* @ts-ignore*/}
              <Elements options={options} stripe={stripePromise}>
                {/* @ts-ignore*/}
                <SubscribeForm
                  setupIntentData={{
                    clientSecret,
                    customerId,
                    appearance,
                  }}
                />
              </Elements>
              {renderCloseButton()}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
