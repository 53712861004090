import { useEffect, useState, Fragment, useMemo } from 'preact/compat';
import { useNavigate } from 'react-router-dom';
import { Strings } from 'resources';
import { deleteProjectTemplate as deleteProjectTemplateAPI } from 'services/TemplateService';
import SmallButton from 'components/UI/SmallButton';
import SearchInput from 'components/UI/SearchInput';
import Spinner from 'components/UI/Spinner';
import Table from 'components/Table';
import { log } from 'utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { allTemplatesState, templateLoadingState } from 'recoil/TemplateState';
import {
  showCreateProjectTemplateModalState,
  projectForCreateProjectTemplateModalState,
} from 'recoil/NavigationState';
import { playModalOpenSound } from 'triggers/utils/sound';

type ProjectTemplatesPropsType = {
  handleClickGroup?: (e: MouseEvent) => void;
};
export default function ProjectTemplates(props: ProjectTemplatesPropsType) {
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<TemplateType[]>([]);
  const navigate = useNavigate();
  const allTemplates = useRecoilValue(allTemplatesState);
  const columns = useMemo(
    () => [
      {
        Header: Strings.name,
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: Strings.created,
        accessor: 'created',
      },
      {
        Header: Strings.created_by,
        accessor: 'createdBy',
      },
      {
        Header: Strings.no_of_tasks,
        accessor: 'tasksCount',
      },
      {
        Header: Strings.no_of_points,
        accessor: 'points',
      },
      {
        Header: Strings.no_of_members,
        accessor: 'membersCount',
      },
      {
        Header: Strings.no_of_projects_created,
        accessor: 'projectsCount',
      },
      {
        Header: Strings.delete,
        Cell: () => <button value={'delete'} onClick={props.handleClickGroup}></button>,
      },
    ],
    [],
  );

  const setCreateProjectTemplateModalStatus = useSetRecoilState(
    showCreateProjectTemplateModalState,
  );
  const setProjectForCreateProjectTemplateModalState = useSetRecoilState(
    projectForCreateProjectTemplateModalState,
  );

  useEffect(() => {
    if (allTemplates === null) return;
    if (searchText.length > 0 && allTemplates.length > 0) {
      const filteredTemplates = allTemplates.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()),
      );
      setData(filteredTemplates);
    } else {
      setData([...allTemplates]);
    }
  }, [JSON.stringify(allTemplates), searchText]);

  const isLoading = useRecoilValue(templateLoadingState);

  const fieldUpdated = (value: string) => {
    setSearchText(value);
  };

  const createNewProjectTemplate = () => {
    setCreateProjectTemplateModalStatus(true);
    setProjectForCreateProjectTemplateModalState(null);
    playModalOpenSound();
  };

  const cellSelected = (id: string, index: number) => {
    log('cell selected', id);
    if (id === 'name') {
      navigate(`/project-templates/${data[index].id}`);
    } else if (id === 'delete') {
      deleteProjectTemplateAPI(data[index].id);
    }
  };

  return (
    <Fragment>
      <div className='title-container title-container-project-templates'>
        <div className='left-con'>
          <h3 className='list-title'>Project Templates</h3>
        </div>

        <div className='right-con project-templates'>
          <SmallButton clicked={() => createNewProjectTemplate()}>
            {Strings.create_new_template}
          </SmallButton>
          <SearchInput
            placeholder='Search'
            onChange={(event) => fieldUpdated((event.target as HTMLInputElement).value)}
            onBlur={(event) => fieldUpdated((event.target as HTMLInputElement).value)}
            value={searchText}
          />
        </div>
      </div>
      <div className='templates-main-con'>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table columns={columns} data={data} cellSelected={cellSelected} clickable={true} />
        )}
      </div>
    </Fragment>
  );
}
