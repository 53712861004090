import { memo, forwardRef } from 'preact/compat';
import { formatDateForView } from 'utils/task';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type StartAndEndDateSelectionPropsType = {
  tempId: string;
  itemStartDate?: Date | null;
  itemEndDate?: Date | null;
  showStartAndEnd: boolean;
  isDisabled: boolean;
  onChangeDate: (date: any) => void;
  storeDateRefs: (id: string, input: any) => void;
  stateType?: StateType;
  overlayClass?: string;
};
function StartAndEndDateSelection(props: StartAndEndDateSelectionPropsType) {
  const {
    tempId,
    itemStartDate,
    itemEndDate,
    showStartAndEnd,
    isDisabled,
    onChangeDate,
    storeDateRefs,
    stateType,
    overlayClass,
  } = props;

  // const startEndDateRef = useRef(null);
  // const skipStartEndDateClick = useRef(false);
  // const [isStartEndDateOpen, setStartEndDateOpen] = useState(false);

  /*
   * Render Date range
   *
   * value Date object
   * onClick function
   * starteDate Date Object
   * endDate Date object
   *
   * returns React.DOM
   */

  interface RenderDateProps {
    startDate?: Date | null;
    endDate?: Date | null;
    onClick?: () => void;
  }
  const RenderDateRange = forwardRef<HTMLElement, RenderDateProps>(
    ({ startDate, endDate, onClick }) => (
      <div className='date-range-input-con'>
        <span
          className={`item-btn start-end-date-btn ${
            !itemStartDate || !itemEndDate ? 'no-date' : ''
          }`}
          onClick={onClick}
        >
          {startDate && endDate
            ? formatDateForView(startDate) + ' - ' + formatDateForView(endDate)
            : 'No start and end date'}
        </span>
      </div>
    ),
  );

  const updateDates = (date: Date[] | null) => {
    onChangeDate(date);
  };

  // log('render components/TaskItems/IndividualItem/StartAndEndDateSelection');

  if (!showStartAndEnd) return null;
  const renderOverlay = () => {
    return <div className={`tutorial-overlay ${overlayClass}`}></div>;
  };
  return (
    <div
      className={`item-container start-end-date-container ${
        !itemStartDate || !itemEndDate ? 'no-date-in-container' : ''
      }`}
      key={`start-end-date-con-${tempId}`}
    >
      <div disabled={isDisabled}>
        <DatePicker
          isClearable={!isDisabled}
          selected={itemStartDate}
          onChange={(dates) => updateDates(dates)}
          startDate={itemStartDate}
          endDate={itemEndDate}
          selectsRange
          selectsEnd={true}
          selectsStart={true}
          id={tempId}
          shouldCloseOnSelect={false}
          ref={(input) => {
            storeDateRefs(tempId, input);
            return false;
          }}
          disabled={isDisabled}
          popperPlacement='bottom-end'
          calendarClassName='date-range-calendar-con'
          customInput={<RenderDateRange startDate={itemStartDate} endDate={itemEndDate} />}
        />
      </div>
      {stateType === 'TUTORIAL' ? renderOverlay() : null}
    </div>
  );
}

/*
 * Check if components props updated or not, do we need to rerender component or not
 *
 * returns Boolean
 */
function areEqual(
  prevProps: StartAndEndDateSelectionPropsType,
  nextProps: StartAndEndDateSelectionPropsType,
) {
  if (prevProps.tempId !== nextProps.tempId) return false;

  if (prevProps.itemStartDate !== nextProps.itemStartDate) return false;

  if (prevProps.itemEndDate !== nextProps.itemEndDate) return false;

  if (prevProps.showStartAndEnd !== nextProps.showStartAndEnd) return false;

  if (prevProps.isDisabled !== nextProps.isDisabled) return false;

  if (prevProps.overlayClass !== nextProps.overlayClass) return false;

  if (prevProps.stateType !== nextProps.stateType) return false;

  return true;
}

export default memo(StartAndEndDateSelection, areEqual);
