// eslint-disable-next-line import/no-extraneous-dependencies
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
const Pagination = (props: PaginationPropsType) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    if (onPageChange) onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (onPageChange) onPageChange(currentPage - 1);
  };

  const renderGoToFirstPage = () => {
    return (
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={() => onPageChange && onPageChange(1)}
      >
        <div className='arrow left' />
        <div className='arrow left' />
      </li>
    );
  };

  const renderGoToPreviousPage = () => {
    return (
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className='arrow left' />
      </li>
    );
  };

  const renderGoToLastPage = () => {
    return (
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={() => onPageChange && onPageChange(lastPage)}
      >
        <div className='arrow right' />
        <div className='arrow right' />
      </li>
    );
  };

  const renderGoToNextPage = () => {
    return (
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className='arrow right' />
      </li>
    );
  };

  const renderPaginationRange = () => {
    return (
      paginationRange &&
      paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className='pagination-item dots'>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange && onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })
    );
  };

  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 0;

  return (
    <ul className={classnames('pagination-container', className ? { [className]: className } : '')}>
      {renderGoToFirstPage()}
      {renderGoToPreviousPage()}
      {renderPaginationRange()}
      {renderGoToNextPage()}
      {renderGoToLastPage()}
    </ul>
  );
};

export default Pagination;
