/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useMemo } from 'preact/compat';
import { useNavigate, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import { log } from 'utils';
import Spinner from 'components/UI/Spinner';
import { Strings } from 'resources';
import handleViewport from 'react-in-viewport';
import { formatDateForAPI, formatDateForTaskInfo, getName } from 'utils/task';
import _ from 'underscore';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Linkify from 'react-linkify';
import SearchTaskItem from './SearchTaskItem';
import {
  updateTaskSize as updateTaskSizeFromAPI,
  updateTaskProject as updateTaskProjectFromAPI,
  updateTaskDates as updateTaskDatesAPI,
  updateTaskAssignee as updateTaskAssigneeAPI,
  updateTaskStatus as updateTaskStatusAPI,
  updateCollapseTaskStatus as updateCollapseTaskStatusAPI,
} from 'services/TaskService';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { multimediaModalState, navigationDataSelector } from 'recoil/NavigationState';
import { searchDataSelector } from 'recoil/SearchState';
import { addSearchData, getSearchData } from 'recoil/SearchState/update';
import { saveSearchTaskName } from 'recoil/TaskState/update';
import { isTaskObjectType, getFileExtension } from 'utils/helpers';
import AvatarIcon from 'assets/images/Avatar.png';
import Thumbnail from 'components/CommentsModal/Thumbnail';
import DownloadLink from 'components/CommentsModal/DownloadLink';

export default function SearchResults() {
  const navigate = useNavigate();
  const { searchText } = useParams();

  const navigationData = useRecoilValue(navigationDataSelector);
  const searchData = useRecoilValue(searchDataSelector);
  const location = useLocation();

  const setMultimediaModalStatus = useSetRecoilState(multimediaModalState);

  const [selectedTab, setTab] = useState<SearchTabType>(
    location.state && location.state.selected ? location.state.selected : 'task',
  );

  const searchItems = useMemo(() => {
    return searchData[selectedTab].data;
  }, [searchData, selectedTab]);

  useEffect(() => {
    resetState();
  }, [selectedTab]);

  useEffect(() => {
    resetSelection();
  }, [location.key]);

  const resetSelection = () => {
    if (searchText && searchText.length > 0) {
      getSearchData('task', true, searchText);
      getSearchData('project', true, searchText);
      getSearchData('comment', true, searchText);
      getSearchData('tag', true, searchText);
    }
    // setTab(location.state && location.state.selected ? location.state.selected : 'task');
  };

  const resetState = () => {
    if (searchText && searchText.length > 0) {
      getSearchData(selectedTab, true, searchText);
    }
  };

  const renderSearchResults = () => {
    if (searchItems.length > 0) {
      return (
        <div id='search-results-con' className='search-results-con'>
          {searchItems.map((searchItem, index) => renderSearchItem(searchItem, index))}
          <ViewportBlock key='load-next' />
        </div>
      );
    } else if (!searchData[selectedTab].isLoading) {
      return (
        <h3 id='search-no-results' className='no-results'>
          {Strings.no_search_results}
        </h3>
      );
    }
    return (
      <div style={{ paddingLeft: '30px' }}>
        <Spinner />
      </div>
    );
  };

  const loadMoreData = () => {
    const { isLoading, hasMore } = searchData[selectedTab];

    if (isLoading || !hasMore) return;

    getSearchData(selectedTab, false, searchText ? searchText : '');
  };

  const renderLoading = () => {
    const { isLoading, hasMore } = searchData[selectedTab];

    if (isLoading && hasMore)
      return (
        <div id='search-results-loader-con' className='no-results'>
          <Spinner />
        </div>
      );

    return null;
  };

  const renderSearchItem = (
    searchItem: TaskObjectType | ProjectObjectType | CommentType,
    index: number,
  ) => {
    if (selectedTab === 'task') {
      return renderSearchItemTasks(searchItem as TaskObjectType, index);
    } else if (selectedTab === 'comment') {
      return renderSearchItemComments(searchItem as CommentType, index);
    } else if (selectedTab === 'project') {
      return renderSearchItemProjects(searchItem as ProjectObjectType, index);
    } else if (selectedTab === 'tag') {
      return renderSearchItemTasks(searchItem as TaskObjectType, index);
    }
    return null;
  };

  const getHighlightedText = (text: string | undefined, highlight: string) => {
    if (text === undefined) return;
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    fontWeight: 'bold',
                    color: '#6666ff',
                    backgroundColor: '#e8e8ff',
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}{' '}
      </span>
    );
  };

  /*
   * State for date refs
   */
  const [dateRefs, setDateRefs] = useState({});

  /*
   * Store date refs
   *
   * id String
   * ref Object
   *
   * returns null
   */
  const storeDateRefs = (id: string, ref: HTMLElement) => {
    dateRefs[id] = ref;
    setDateRefs(dateRefs);
  };

  /*
   * On change date event
   *
   * dates Array
   * tempId String
   *
   * returns React.DOM
   */
  const onChangeDate = (
    index: number,
    taskId: string,
    dates: Date[],
    tempId: string,
    parentIndex?: number,
  ) => {
    const [start, end] = dates;

    if (start && end) {
      dateRefs[tempId] && dateRefs[tempId].setOpen(false);
    }

    log('onChangeDate', dates, start, end, parentIndex);

    updateTaskDates(index, start, end, parentIndex, taskId);
  };

  /*
   * On change due date event
   *
   * dates Array
   * tempId String
   *
   * returns React.DOM
   */
  const onChangeDueDate = (
    index: number,
    taskId: string,
    date: Date,
    tempId: string,
    parentIndex?: number,
    parentId?: string,
  ) => {
    log('onChangeDueDate', date);
    const newRequestSource = new AbortController();
    updateTaskDatesAPI(taskId, { duedate: date ? formatDateForAPI(date) : null }, newRequestSource);
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks)
          testData.subTasks[index].dueDate = date;
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) testData.dueDate = date;
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  /*
   * Update start and end dates
   *
   * index Integer
   * startDate Object
   * endDate String
   *
   * returns null
   */
  const updateTaskDates = (
    index: number,
    startDate: Date,
    endDate: Date,
    parentIndex: number | undefined,
    taskId: string,
  ) => {
    const newRequestSource = new AbortController();
    updateTaskDatesAPI(
      taskId,
      {
        startdate: startDate ? formatDateForAPI(startDate) : null,
        enddate: endDate ? formatDateForAPI(endDate) : null,
      },
      newRequestSource,
    );
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks) {
          testData.subTasks[index].startDate = startDate;
          testData.subTasks[index].endDate = endDate;
        }
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) {
          testData.startDate = startDate;
          testData.endDate = endDate;
        }
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  /*
   * Save task name
   *
   * index Integer
   * item Object
   * dataType String
   * parentIndex Integer
   * parentId Integer
   *
   * returns null
   */
  const saveTaskName = (
    index: number,
    taskId: string,
    value: string,
    name: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    dataType: TaskType | undefined,
    task: TaskObjectType,
  ) => {
    log('saveTaskName', index, taskId, value, name, parentIndex, parentId);

    saveSearchTaskName(value, name, task);
  };

  const addNewTask = () => {};

  /*
   * Update size
   *
   * dataType String
   * index Integer
   * oldSize Object
   * newSizeId String
   *
   *
   * returns null
   */
  const updateTaskSize = (
    size: SizeOptionsType,
    taskId: string,
    index: number,
    parentIndex?: number,
  ) => {
    const newRequestSource = new AbortController();
    updateTaskSizeFromAPI(size, taskId, newRequestSource);
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks) testData.subTasks[index].size = size;
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) testData.size = size;
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  const updateTaskCollapseStatus = (index: number, taskId: string, collapseStatus: boolean) => {
    const newRequestSource = new AbortController();
    updateCollapseTaskStatusAPI(taskId, collapseStatus, false, newRequestSource);
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      const testData = data[index];
      if (isTaskObjectType(testData)) testData.collapsedSubTasks = collapseStatus;
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  /*
   * Update tag
   *
   * dataType String
   * index Integer
   * newTagId String
   *
   *
   * returns null
   */
  const updateTaskProject = (
    newProject: ProjectObjectType | null,
    taskId: string,
    index: number,
    parentIndex?: number,
  ) => {
    const newRequestSource = new AbortController();
    updateTaskProjectFromAPI(newProject ? newProject.id : newProject, taskId, newRequestSource);
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks)
          testData.subTasks[index].project = newProject;
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) testData.project = newProject;
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  /*
   * Update task tags
   *
   * index Integer
   * newAssigneeId String
   *
   *
   * returns null
   */
  const updateTaskTags = (index: number, updatedTags: TagObjectType[], parentIndex?: number) => {
    log('updateTaskTags', updatedTags);
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks)
          testData.subTasks[index].tags = updatedTags;
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) testData.tags = updatedTags;
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  const updateTaskTagsAndTagInfo = (
    updatedTags: TagObjectType[],
    updatedTagsInfo: TagInfoType[],
    index: number,
    parentIndex?: number,
  ) => {
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks) {
          testData.subTasks[index].tags = updatedTags;
          testData.subTasks[index].tagInfo = updatedTagsInfo;
        }
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) {
          testData.tags = updatedTags;
          testData.tagInfo = updatedTagsInfo;
        }
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  /*
   * Update assignee
   *
   * index Integer
   * newAssigneeId String
   *
   *
   * returns null
   */
  const updateTaskAssignee = (
    index: number,
    newAssignee: UserFieldType | null,
    parentIndex: number | undefined,
    taskId: string,
  ) => {
    const newRequestSource = new AbortController();
    updateTaskAssigneeAPI(newAssignee ? newAssignee.id : null, taskId, newRequestSource);
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks)
          testData.subTasks[index].assignee = newAssignee;
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) testData.assignee = newAssignee;
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  /*
   * Delete task
   *
   * index Integer
   * newAssigneeId String
   *
   *
   * returns null
   */
  const deleteTask = (_index, _id, _parentIndex, _dataType) => {
    // dispatch(
    //   TaskActions.deleteTask(index, id, dataType, parentIndex, null)
    // );
  };

  /*
   * Check task
   *
   * index Integer
   * id Integer
   * dataType String
   * parentIndex Integer
   * parentId Integer
   *
   *
   * returns null
   */
  const checkTask = (
    index: number,
    id: string,
    parentIndex: number | undefined,
    parentId: string | undefined,
    checked: boolean,
    item: TaskObjectType,
  ) => {
    log('checkTask', index, id, parentIndex, parentId, checked);

    if (item.checked) {
      if (!parentId) updateTaskStatusAPI(id, 'OPEN');
    } else {
      updateTaskStatusAPI(id, checked ? 'COMPLETED' : 'OPEN');
    }
    if (selectedTab === 'task' || selectedTab === 'tag') {
      const { data, hasMore, page, count } = searchData[selectedTab];
      if (parentIndex !== undefined && parentIndex !== null && parentIndex !== -1) {
        const testData = data[parentIndex];
        if (isTaskObjectType(testData) && testData.subTasks)
          testData.subTasks[index].checked = checked;
        data[parentIndex] = testData;
      } else {
        const testData = data[index];
        if (isTaskObjectType(testData)) testData.checked = checked;
        data[index] = testData;
      }
      addSearchData(selectedTab, data, hasMore, page, count);
    }
  };

  const [inputRefs, setInputRefs] = useState({});

  /*
   * Store input refs
   *
   * id String
   * ref Object
   *
   * returns null
   */
  const storeInputRefs = (id: string, ref: HTMLElement) => {
    inputRefs[id] = ref;
    setInputRefs(inputRefs);
  };

  const itemProps = {
    storeDateRefs,
    onChangeDate,
    onChangeDueDate,
    addNewTask,
    updateTaskTagsAndTagInfo,
    deleteTask,
    storeInputRefs,
    updateTaskCollapseStatus,
  };
  const renderSubTasks = (
    subTasks: TaskObjectType[] | undefined,
    parentIndex: number | undefined,
    parentId: string | undefined,
    parentTempId: string | undefined,
  ) => {
    const subTaskProps = { ...itemProps, parentIndex, parentId, parentTempId };

    return (
      <div
        id={`search-item-subtasks-con-${parentIndex}`}
        style={{ marginLeft: '34px', display: 'block' }}
      >
        <div className='search-item-subtasks-con'>
          {subTasks &&
            subTasks.map((subItem, subIndex) => {
              return (
                <div
                  id={`search-item-subtask-${subItem.tempId}`}
                  key={subItem.tempId}
                  className='search-item-subtask'
                >
                  <SearchTaskItem
                    {...subTaskProps}
                    saveSearchTaskName={saveTaskName}
                    updateSearchTaskProject={updateTaskProject}
                    updateSearchTaskAssignee={updateTaskAssignee}
                    updateSearchTaskSize={updateTaskSize}
                    updateSearchTaskTags={updateTaskTags}
                    checkSearchTask={checkTask}
                    key={subItem.tempId + parentIndex}
                    index={subIndex}
                    itemId={subItem.id}
                    isTemp={subItem.isTemp}
                    tempId={subItem.tempId}
                    item={subItem}
                    itemProject={subItem.project}
                    itemSize={subItem.size ? subItem.size : 'M'}
                    itemChecked={subItem.checked}
                    itemNumber={subItem.taskNumber}
                    itemStartDate={subItem.startDate}
                    itemEndDate={subItem.endDate}
                    itemDueDate={subItem.dueDate}
                    itemAssignee={subItem.assignee}
                    itemValue={subItem.name ? subItem.name : ''}
                    updateValue={subItem.updateValue}
                    dataType={'BACKLOG'}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderSearchItemTasks = (searchItem: TaskObjectType, index: number) => {
    return (
      <div
        id={`search-item-tasks-con-${index}`}
        key={`${searchItem.tempId} + ${index}`}
        className='search-item-container'
      >
        <SearchTaskItem
          {...itemProps}
          hasSubtasks={searchItem.subTasks?.length ? true : false}
          readOnly={searchItem.checked}
          itemProject={searchItem.project}
          dataType={searchItem.taskType ? searchItem.taskType : 'FOCUS'}
          index={index}
          item={searchItem}
          itemId={searchItem.id}
          itemValue={searchItem.name ? searchItem.name : ''}
          isTemp={searchItem.isTemp}
          tempId={searchItem.tempId}
          tagInfo={searchItem.tagInfo}
          parentId={searchItem.parentId ? searchItem.parentId : undefined}
          key={searchItem.tempId + index}
          itemSize={searchItem.size ? searchItem.size : 'M'}
          itemChecked={searchItem.checked}
          itemNumber={searchItem.taskNumber}
          itemStartDate={searchItem.startDate}
          itemEndDate={searchItem.endDate}
          itemDueDate={searchItem.dueDate}
          itemAssignee={searchItem.assignee}
          itemTags={searchItem.tags ? searchItem.tags : undefined}
          updateValue={searchItem.updateValue}
          stateType={undefined}
          saveSearchTaskName={saveTaskName}
          updateSearchTaskProject={updateTaskProject}
          updateSearchTaskAssignee={updateTaskAssignee}
          updateSearchTaskSize={updateTaskSize}
          updateTaskCollapseStatus={updateTaskCollapseStatus}
          addNewTask={addNewTask}
          checkSearchTask={checkTask}
          storeDateRefs={storeDateRefs}
          onChangeDate={onChangeDate}
          onChangeDueDate={onChangeDueDate}
          deleteTask={deleteTask}
          collapsedSubTasks={searchItem.collapsedSubTasks}
        />
        <Collapse isOpened={!searchItem.collapsedSubTasks}>
          {renderSubTasks(searchItem.subTasks, index, searchItem.id, searchItem.tempId)}
        </Collapse>
      </div>
    );
  };

  const getCommentCreationDetails = (item: CommentType) => {
    let details = '';
    if (item && item.createdBy) {
      details = getName(item.createdBy);
    }
    if (item && item.modified) {
      if (details.length > 0) {
        details = `${details} | `;
      }
      details = details + formatDateForTaskInfo(item.modified);
    }
    return details;
  };

  const renderSearchItemComments = (item: CommentType, index: number) => {
    return (
      <div
        id={`searched-comment-con-${index}`}
        className='search-item-con comment-main-con'
        key={index}
      >
        <div id={`searched-comment-container-${index}`} className='comment-container'>
          <div id={`searched-comment-left-con-${index}`} className='left-con'>
            <img
              id={`searched-comment-image-${index}`}
              src={
                item.createdBy && item.createdBy.profileImage
                  ? item.createdBy.profileImage
                  : AvatarIcon
              }
              alt='user-image'
            />
          </div>
          <div id={`searched-comment-right-con`} className='right-con'>
            <div id={`searched-comment-creation-details-${index}`} className='comment-creation-con'>
              <span>{getCommentCreationDetails(item)}</span>
            </div>
            <div id={`searched-comment-content-${index}`} className='comment-content'>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target='blank' href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                <span>{item.name ? item.name : ''}</span>
              </Linkify>
            </div>
            {item.url ? renderAttachment(item, index) : null}
          </div>
        </div>
      </div>
    );
  };

  const showCommentMultimedia = (type: string, url: string | null) => {
    if (url)
      setMultimediaModalStatus({
        visible: true,
        type: type.toLowerCase(),
        url: [url],
      });
  };

  const renderAttachment = (item: CommentType, index: number) => {
    if (!item.url || item.url === 'loading') {
      return (
        <div
          id={`comment-attachment-load-con-${index}`}
          className='comment-attachments'
          key={index}
        >
          <Spinner />
        </div>
      );
    }
    const fileExt = getFileExtension(item.url);
    if (
      fileExt.toLowerCase() === 'jpg' ||
      fileExt.toLowerCase() === 'png' ||
      fileExt.toLowerCase() === 'jpeg' ||
      fileExt.toLowerCase() === 'bmp'
    ) {
      return (
        <div
          id={`searched-comment-attachments-con-${index}`}
          className='comment-attachments'
          key={index}
        >
          <button
            id={`searched-comment-attach-btn-${index}`}
            className='comment-attach-btn'
            onClick={() => showCommentMultimedia(fileExt, item.url)}
          >
            {item.thumbnail ? (
              <Thumbnail index={index} url={item.url} thumbnail={item.thumbnail} />
            ) : null}
          </button>
        </div>
      );
    } else if (
      fileExt.toLowerCase() === 'mov' ||
      fileExt.toLowerCase() === 'mp4' ||
      fileExt.toLowerCase() === 'webm' ||
      fileExt.toLowerCase() === 'ogg'
    ) {
      return (
        <div
          id={`searched-comment-attachments-con-${index}`}
          className='comment-attachments'
          key={index}
        >
          <button
            id={`searched-comment-attach-btn-${index}`}
            className='comment-attach-btn'
            onClick={() => showCommentMultimedia(fileExt, item.url)}
          >
            <FontAwesomeIcon
              id={`searched-comment-attach-img-${index}`}
              className='video-icon'
              icon={faVideo}
            />
          </button>
        </div>
      );
    }
    return <DownloadLink index={index} url={item.url} />;
  };

  const selectedProject = (project: ProjectObjectType) => {
    navigate(`/project/${project.id}`);
  };
  /**
   * render search item projects
   * @param {*} project
   * @param {*} index
   * @returns
   */

  const renderSearchItemProjects = (project: ProjectObjectType, index: number) => {
    return (
      <div
        id={`search-item-project-con-${index}`}
        className='search-item-con project-main-container'
        key={index}
        onClick={() => {
          selectedProject(project);
        }}
      >
        <div id={`search-item-project-in-con-${index}`} className='project-in-container'>
          <div
            id={`search-project-item-name-con-${index}`}
            className='project-item project-name-con'
          >
            <span className='project-name'>
              {getHighlightedText(
                project.name,
                searchText ? searchText.replaceAll('-slash-', '/') : '',
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const Block = (props: any) => {
    const { inViewport, forwardedRef } = props;

    if (inViewport) loadMoreData();
    return (
      <div id='search-viewport-block' className='viewport-block' ref={forwardedRef}>
        <div style={{ paddingBottom: '20px' }}>{inViewport ? renderLoading() : ''}</div>
      </div>
    );
  };

  const ViewportBlock = handleViewport(Block, { rootMargin: '300px' });

  const handleButtonClick = (event: MouseEvent) => {
    setTab((event.target as HTMLButtonElement).value as SearchTabType);
  };

  const renderFilterTabs = () => {
    return (
      <div className='filter-nav' id='filter-nav'>
        <button
          id='search-tasks-btn'
          className={`btn-search-nav ${selectedTab === 'task' ? 'active' : ''}`}
          type='button'
          value='task'
          onClick={handleButtonClick}
        >
          {`${Strings.tasks} (${searchData['task'].count})`}
        </button>
        <button
          id='search-projects-btn'
          className={`btn-search-nav ${selectedTab === 'project' ? 'active' : ''}`}
          type='button'
          value='project'
          onClick={handleButtonClick}
        >
          {`${Strings.projects} (${searchData['project'].count})`}
        </button>
        <button
          id='search-comments-btn'
          className={`btn-search-nav ${selectedTab === 'comment' ? 'active' : ''}`}
          type='button'
          value='comment'
          onClick={handleButtonClick}
        >
          {`${Strings.comments} (${searchData['comment'].count})`}
        </button>
        <button
          id='search-tags-btn'
          className={`btn-search-nav ${selectedTab === 'tag' ? 'active' : ''}`}
          type='button'
          value='tag'
          onClick={handleButtonClick}
        >
          {`${Strings.tags} (${searchData['tag'].count})`}
        </button>
      </div>
    );
  };

  return (
    <div
      id='search-results-main-container'
      className={`search-results-main-container ${
        navigationData.hideLeftSidebarDesktop ? 'hide-leftbar' : ''
      } ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}`}
    >
      <div
        id='search-results-top-title-bar-con'
        className={`top-title-bar-container ${
          navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'
        }`}
      >
        <h3 id='search-results-title' className='big-title'>
          {`${Strings.search_results_for} "${
            searchText ? searchText.replaceAll('-slash-', '/') : ''
          }"`}
        </h3>
      </div>
      {renderFilterTabs()}
      {renderSearchResults()}
    </div>
  );
}
