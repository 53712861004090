import axios from 'axios';
import appConfig from 'config/config';
import { isUserLoggedIn, logOutUser } from 'services/AuthService';
import { log } from 'utils';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

const appClient = axios.create({
  baseURL: appConfig.apiUrl,
  timeout: 40000,
  headers: defaultHeaders,
});

appClient.interceptors.request.use(async function (config) {
  const loggedInUser = isUserLoggedIn();
  const { status, token } = loggedInUser;

  if (status && (config.headers.token === undefined || config.headers.token === null))
    config.headers.token = token;

  config.headers.time = new Date().toUTCString();

  config.headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  log('Starting Request', config);

  return config;
});

appClient.interceptors.response.use(
  function (response) {
    // Do something with response data
    log('response', response);

    // Bad practice Unauthorized being sent with 200 status code, need to update this in future from backend
    if (
      response.data &&
      response.data.exceptionMessage &&
      response.data.exceptionMessage === 'Unauthorized'
    ) {
      log('token expired, logout user');
      logOutUser();
    }

    return response;
  },
  function (error) {
    log('Request Failed:', error.config);

    /* istanbul ignore else */
    if (error.response) {
      if (error.response.status === '401') {
        log('token expired, logout user');
        logOutUser();
      }
      // Request was made but server responded with something
      // other than 2xx
      log('Status:', error.response.status);
      log('Data:', error.response.data);
      log('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      log('Error Message:', error.message);
    }

    /* istanbul ignore next */
    return Promise.reject(error.response || error.message);
  },
);

export { appClient };
